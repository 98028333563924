!
/**
* Highcharts JS v12.1.2 (2024-12-21)
* @module highcharts/highcharts
*
* (c) 2009-2024 Torstein Honsi
*
* License: www.highcharts.com/license
*/
function (t, e) {
  "object" == typeof exports && "object" == typeof module ? (t._Highcharts = e(), module.exports = t._Highcharts) : "function" == typeof define && define.amd ? define("highcharts/highcharts", [], e) : "object" == typeof exports ? (t._Highcharts = e(), exports.highcharts = t._Highcharts) : (t.Highcharts && t.Highcharts.error(16, !0), t.Highcharts = e());
}("undefined" == typeof window ? this : window, () => (() => {
  "use strict";

  let t, e, i, s, o;
  var r,
    n,
    a,
    h,
    l,
    d,
    c,
    p,
    u,
    g,
    f,
    m,
    x,
    y,
    b,
    v,
    M,
    k,
    w,
    S,
    A,
    T,
    P = {};
  P.d = (t, e) => {
    for (var i in e) P.o(e, i) && !P.o(t, i) && Object.defineProperty(t, i, {
      enumerable: !0,
      get: e[i]
    });
  }, P.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e);
  var C = {};
  P.d(C, {
    default: () => gr
  }), function (t) {
    t.SVG_NS = "http://www.w3.org/2000/svg", t.product = "Highcharts", t.version = "12.1.2", t.win = "undefined" != typeof window ? window : {}, t.doc = t.win.document, t.svg = t.doc && t.doc.createElementNS && !!t.doc.createElementNS(t.SVG_NS, "svg").createSVGRect, t.pageLang = t.doc?.documentElement?.closest("[lang]")?.lang, t.userAgent = t.win.navigator && t.win.navigator.userAgent || "", t.isChrome = t.win.chrome, t.isFirefox = -1 !== t.userAgent.indexOf("Firefox"), t.isMS = /(edge|msie|trident)/i.test(t.userAgent) && !t.win.opera, t.isSafari = !t.isChrome && -1 !== t.userAgent.indexOf("Safari"), t.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(t.userAgent), t.isWebKit = -1 !== t.userAgent.indexOf("AppleWebKit"), t.deg2rad = 2 * Math.PI / 360, t.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"], t.noop = function () {}, t.supportsPassiveEvents = function () {
      let e = !1;
      if (!t.isMS) {
        let i = Object.defineProperty({}, "passive", {
          get: function () {
            e = !0;
          }
        });
        t.win.addEventListener && t.win.removeEventListener && (t.win.addEventListener("testPassive", t.noop, i), t.win.removeEventListener("testPassive", t.noop, i));
      }
      return e;
    }(), t.charts = [], t.composed = [], t.dateFormats = {}, t.seriesTypes = {}, t.symbolSizes = {}, t.chartCount = 0;
  }(r || (r = {}));
  let O = r,
    {
      charts: E,
      doc: L,
      win: B
    } = O;
  function I(t, e, i, s) {
    let o = e ? "Highcharts error" : "Highcharts warning";
    32 === t && (t = `${o}: Deprecated member`);
    let r = H(t),
      n = r ? `${o} #${t}: www.highcharts.com/errors/${t}/` : t.toString();
    if (void 0 !== s) {
      let t = "";
      r && (n += "?"), Z(s, function (e, i) {
        t += `
 - ${i}: ${e}`, r && (n += encodeURI(i) + "=" + encodeURI(e));
      }), n += t;
    }
    J(O, "displayError", {
      chart: i,
      code: t,
      message: n,
      params: s
    }, function () {
      if (e) throw Error(n);
      B.console && -1 === I.messages.indexOf(n) && console.warn(n);
    }), I.messages.push(n);
  }
  function D(t, e) {
    return parseInt(t, e || 10);
  }
  function R(t) {
    return "string" == typeof t;
  }
  function N(t) {
    let e = Object.prototype.toString.call(t);
    return "[object Array]" === e || "[object Array Iterator]" === e;
  }
  function z(t, e) {
    return !!t && "object" == typeof t && (!e || !N(t));
  }
  function W(t) {
    return z(t) && "number" == typeof t.nodeType;
  }
  function G(t) {
    let e = t && t.constructor;
    return !!(z(t, !0) && !W(t) && e && e.name && "Object" !== e.name);
  }
  function H(t) {
    return "number" == typeof t && !isNaN(t) && t < 1 / 0 && t > -1 / 0;
  }
  function F(t) {
    return null != t;
  }
  function X(t, e, i) {
    let s;
    let o = R(e) && !F(i),
      r = (e, i) => {
        F(e) ? t.setAttribute(i, e) : o ? (s = t.getAttribute(i)) || "class" !== i || (s = t.getAttribute(i + "Name")) : t.removeAttribute(i);
      };
    return R(e) ? r(i, e) : Z(e, r), s;
  }
  function Y(t) {
    return N(t) ? t : [t];
  }
  function j(t, e) {
    let i;
    for (i in t || (t = {}), e) t[i] = e[i];
    return t;
  }
  function U() {
    let t = arguments,
      e = t.length;
    for (let i = 0; i < e; i++) {
      let e = t[i];
      if (null != e) return e;
    }
  }
  function V(t, e) {
    j(t.style, e);
  }
  function $(t) {
    return Math.pow(10, Math.floor(Math.log(t) / Math.LN10));
  }
  function _(t, e) {
    return t > 1e14 ? t : parseFloat(t.toPrecision(e || 14));
  }
  (I || (I = {})).messages = [], Math.easeInOutSine = function (t) {
    return -.5 * (Math.cos(Math.PI * t) - 1);
  };
  let q = Array.prototype.find ? function (t, e) {
    return t.find(e);
  } : function (t, e) {
    let i;
    let s = t.length;
    for (i = 0; i < s; i++) if (e(t[i], i)) return t[i];
  };
  function Z(t, e, i) {
    for (let s in t) Object.hasOwnProperty.call(t, s) && e.call(i || t[s], t[s], s, t);
  }
  function K(t, e, i) {
    function s(e, i) {
      let s = t.removeEventListener;
      s && s.call(t, e, i, !1);
    }
    function o(i) {
      let o, r;
      t.nodeName && (e ? (o = {})[e] = !0 : o = i, Z(o, function (t, e) {
        if (i[e]) for (r = i[e].length; r--;) s(e, i[e][r].fn);
      }));
    }
    let r = "function" == typeof t && t.prototype || t;
    if (Object.hasOwnProperty.call(r, "hcEvents")) {
      let t = r.hcEvents;
      if (e) {
        let r = t[e] || [];
        i ? (t[e] = r.filter(function (t) {
          return i !== t.fn;
        }), s(e, i)) : (o(t), t[e] = []);
      } else o(t), delete r.hcEvents;
    }
  }
  function J(t, e, i, s) {
    if (i = i || {}, L.createEvent && (t.dispatchEvent || t.fireEvent && t !== O)) {
      let s = L.createEvent("Events");
      s.initEvent(e, !0, !0), i = j(s, i), t.dispatchEvent ? t.dispatchEvent(i) : t.fireEvent(e, i);
    } else if (t.hcEvents) {
      i.target || j(i, {
        preventDefault: function () {
          i.defaultPrevented = !0;
        },
        target: t,
        type: e
      });
      let s = [],
        o = t,
        r = !1;
      for (; o.hcEvents;) Object.hasOwnProperty.call(o, "hcEvents") && o.hcEvents[e] && (s.length && (r = !0), s.unshift.apply(s, o.hcEvents[e])), o = Object.getPrototypeOf(o);
      r && s.sort((t, e) => t.order - e.order), s.forEach(e => {
        !1 === e.fn.call(t, i) && i.preventDefault();
      });
    }
    s && !i.defaultPrevented && s.call(t, i);
  }
  let Q = function () {
    let e = Math.random().toString(36).substring(2, 9) + "-",
      i = 0;
    return function () {
      return "highcharts-" + (t ? "" : e) + i++;
    };
  }();
  B.jQuery && (B.jQuery.fn.highcharts = function () {
    let t = [].slice.call(arguments);
    if (this[0]) return t[0] ? (new O[R(t[0]) ? t.shift() : "Chart"](this[0], t[0], t[1]), this) : E[X(this[0], "data-highcharts-chart")];
  });
  let tt = {
      addEvent: function (t, e, i, s = {}) {
        let o = "function" == typeof t && t.prototype || t;
        Object.hasOwnProperty.call(o, "hcEvents") || (o.hcEvents = {});
        let r = o.hcEvents;
        O.Point && t instanceof O.Point && t.series && t.series.chart && (t.series.chart.runTrackerClick = !0);
        let n = t.addEventListener;
        n && n.call(t, e, i, !!O.supportsPassiveEvents && {
          passive: void 0 === s.passive ? -1 !== e.indexOf("touch") : s.passive,
          capture: !1
        }), r[e] || (r[e] = []);
        let a = {
          fn: i,
          order: "number" == typeof s.order ? s.order : 1 / 0
        };
        return r[e].push(a), r[e].sort((t, e) => t.order - e.order), function () {
          K(t, e, i);
        };
      },
      arrayMax: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] > i && (i = t[e]);
        return i;
      },
      arrayMin: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] < i && (i = t[e]);
        return i;
      },
      attr: X,
      clamp: function (t, e, i) {
        return t > e ? t < i ? t : i : e;
      },
      clearTimeout: function (t) {
        F(t) && clearTimeout(t);
      },
      correctFloat: _,
      createElement: function (t, e, i, s, o) {
        let r = L.createElement(t);
        return e && j(r, e), o && V(r, {
          padding: "0",
          border: "none",
          margin: "0"
        }), i && V(r, i), s && s.appendChild(r), r;
      },
      crisp: function (t, e = 0, i) {
        let s = e % 2 / 2,
          o = i ? -1 : 1;
        return (Math.round(t * o - s) + s) * o;
      },
      css: V,
      defined: F,
      destroyObjectProperties: function (t, e, i) {
        Z(t, function (s, o) {
          s !== e && s?.destroy && s.destroy(), (s?.destroy || !i) && delete t[o];
        });
      },
      diffObjects: function (t, e, i, s) {
        let o = {};
        return !function t(e, o, r, n) {
          let a = i ? o : e;
          Z(e, function (i, h) {
            if (!n && s && s.indexOf(h) > -1 && o[h]) {
              i = Y(i), r[h] = [];
              for (let e = 0; e < Math.max(i.length, o[h].length); e++) o[h][e] && (void 0 === i[e] ? r[h][e] = o[h][e] : (r[h][e] = {}, t(i[e], o[h][e], r[h][e], n + 1)));
            } else z(i, !0) && !i.nodeType ? (r[h] = N(i) ? [] : {}, t(i, o[h] || {}, r[h], n + 1), 0 !== Object.keys(r[h]).length || "colorAxis" === h && 0 === n || delete r[h]) : (e[h] !== o[h] || h in e && !(h in o)) && "__proto__" !== h && "constructor" !== h && (r[h] = a[h]);
          });
        }(t, e, o, 0), o;
      },
      discardElement: function (t) {
        t && t.parentElement && t.parentElement.removeChild(t);
      },
      erase: function (t, e) {
        let i = t.length;
        for (; i--;) if (t[i] === e) {
          t.splice(i, 1);
          break;
        }
      },
      error: I,
      extend: j,
      extendClass: function (t, e) {
        let i = function () {};
        return i.prototype = new t(), j(i.prototype, e), i;
      },
      find: q,
      fireEvent: J,
      getAlignFactor: (t = "") => ({
        center: .5,
        right: 1,
        middle: .5,
        bottom: 1
      })[t] || 0,
      getClosestDistance: function (t, e) {
        let i, s, o, r;
        let n = !e;
        return t.forEach(t => {
          if (t.length > 1) for (r = s = t.length - 1; r > 0; r--) (o = t[r] - t[r - 1]) < 0 && !n ? (e?.(), e = void 0) : o && (void 0 === i || o < i) && (i = o);
        }), i;
      },
      getMagnitude: $,
      getNestedProperty: function (t, e) {
        let i = t.split(".");
        for (; i.length && F(e);) {
          let t = i.shift();
          if (void 0 === t || "__proto__" === t) return;
          if ("this" === t) {
            let t;
            return z(e) && (t = e["@this"]), t ?? e;
          }
          let s = e[t.replace(/[\\'"]/g, "")];
          if (!F(s) || "function" == typeof s || "number" == typeof s.nodeType || s === B) return;
          e = s;
        }
        return e;
      },
      getStyle: function t(e, i, s) {
        let o;
        if ("width" === i) {
          let i = Math.min(e.offsetWidth, e.scrollWidth),
            s = e.getBoundingClientRect && e.getBoundingClientRect().width;
          return s < i && s >= i - 1 && (i = Math.floor(s)), Math.max(0, i - (t(e, "padding-left", !0) || 0) - (t(e, "padding-right", !0) || 0));
        }
        if ("height" === i) return Math.max(0, Math.min(e.offsetHeight, e.scrollHeight) - (t(e, "padding-top", !0) || 0) - (t(e, "padding-bottom", !0) || 0));
        let r = B.getComputedStyle(e, void 0);
        return r && (o = r.getPropertyValue(i), U(s, "opacity" !== i) && (o = D(o))), o;
      },
      insertItem: function (t, e) {
        let i;
        let s = t.options.index,
          o = e.length;
        for (i = t.options.isInternal ? o : 0; i < o + 1; i++) if (!e[i] || H(s) && s < U(e[i].options.index, e[i]._i) || e[i].options.isInternal) {
          e.splice(i, 0, t);
          break;
        }
        return i;
      },
      isArray: N,
      isClass: G,
      isDOMElement: W,
      isFunction: function (t) {
        return "function" == typeof t;
      },
      isNumber: H,
      isObject: z,
      isString: R,
      merge: function (t, ...e) {
        let i,
          s = [t, ...e],
          o = {},
          r = function (t, e) {
            return "object" != typeof t && (t = {}), Z(e, function (i, s) {
              "__proto__" !== s && "constructor" !== s && (!z(i, !0) || G(i) || W(i) ? t[s] = e[s] : t[s] = r(t[s] || {}, i));
            }), t;
          };
        !0 === t && (o = s[1], s = Array.prototype.slice.call(s, 2));
        let n = s.length;
        for (i = 0; i < n; i++) o = r(o, s[i]);
        return o;
      },
      normalizeTickInterval: function (t, e, i, s, o) {
        let r,
          n = t;
        i = U(i, $(t));
        let a = t / i;
        for (!e && (e = o ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === s && (1 === i ? e = e.filter(function (t) {
          return t % 1 == 0;
        }) : i <= .1 && (e = [1 / i]))), r = 0; r < e.length && (n = e[r], (!o || !(n * i >= t)) && (o || !(a <= (e[r] + (e[r + 1] || e[r])) / 2))); r++);
        return _(n * i, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: Z,
      offset: function (t) {
        let e = L.documentElement,
          i = t.parentElement || t.parentNode ? t.getBoundingClientRect() : {
            top: 0,
            left: 0,
            width: 0,
            height: 0
          };
        return {
          top: i.top + (B.pageYOffset || e.scrollTop) - (e.clientTop || 0),
          left: i.left + (B.pageXOffset || e.scrollLeft) - (e.clientLeft || 0),
          width: i.width,
          height: i.height
        };
      },
      pad: function (t, e, i) {
        return Array((e || 2) + 1 - String(t).replace("-", "").length).join(i || "0") + t;
      },
      pick: U,
      pInt: D,
      pushUnique: function (t, e) {
        return 0 > t.indexOf(e) && !!t.push(e);
      },
      relativeLength: function (t, e, i) {
        return /%$/.test(t) ? e * parseFloat(t) / 100 + (i || 0) : parseFloat(t);
      },
      removeEvent: K,
      replaceNested: function (t, ...e) {
        let i, s;
        do for (s of (i = t, e)) t = t.replace(s[0], s[1]); while (t !== i);
        return t;
      },
      splat: Y,
      stableSort: function (t, e) {
        let i, s;
        let o = t.length;
        for (s = 0; s < o; s++) t[s].safeI = s;
        for (t.sort(function (t, s) {
          return 0 === (i = e(t, s)) ? t.safeI - s.safeI : i;
        }), s = 0; s < o; s++) delete t[s].safeI;
      },
      syncTimeout: function (t, e, i) {
        return e > 0 ? setTimeout(t, e, i) : (t.call(0, i), -1);
      },
      timeUnits: {
        millisecond: 1,
        second: 1e3,
        minute: 6e4,
        hour: 36e5,
        day: 864e5,
        week: 6048e5,
        month: 24192e5,
        year: 314496e5
      },
      ucfirst: function (t) {
        return R(t) ? t.substring(0, 1).toUpperCase() + t.substring(1) : String(t);
      },
      uniqueKey: Q,
      useSerialIds: function (e) {
        return t = U(e, t);
      },
      wrap: function (t, e, i) {
        let s = t[e];
        t[e] = function () {
          let t = arguments,
            e = this;
          return i.apply(this, [function () {
            return s.apply(e, arguments.length ? arguments : t);
          }].concat([].slice.call(arguments)));
        };
      }
    },
    {
      pageLang: te,
      win: ti
    } = O,
    {
      defined: ts,
      error: to,
      extend: tr,
      isNumber: tn,
      isObject: ta,
      isString: th,
      merge: tl,
      objectEach: td,
      pad: tc,
      splat: tp,
      timeUnits: tu,
      ucfirst: tg
    } = tt,
    tf = O.isSafari && ti.Intl && !ti.Intl.DateTimeFormat.prototype.formatRange,
    tm = t => void 0 === t.main,
    tx = t => ["D", "L", "M", "X", "J", "V", "S"].indexOf(t),
    ty = class {
      constructor(t) {
        this.options = {
          timezone: "UTC"
        }, this.variableTimezone = !1, this.Date = ti.Date, this.update(t);
      }
      update(t = {}) {
        this.dTLCache = {}, this.options = t = tl(!0, this.options, t);
        let {
          timezoneOffset: e,
          useUTC: i
        } = t;
        this.Date = t.Date || ti.Date || Date;
        let s = t.timezone;
        ts(i) && (s = i ? "UTC" : void 0), e && e % 60 == 0 && (s = "Etc/GMT" + (e > 0 ? "+" : "") + e / 60), this.variableTimezone = "UTC" !== s && s?.indexOf("Etc/GMT") !== 0, this.timezone = s, ["months", "shortMonths", "weekdays", "shortWeekdays"].forEach(t => {
          let e = /months/i.test(t),
            i = /short/.test(t),
            s = {
              timeZone: "UTC"
            };
          s[e ? "month" : "weekday"] = i ? "short" : "long", this[t] = (e ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : [3, 4, 5, 6, 7, 8, 9]).map(t => this.dateFormat(s, (e ? 31 : 1) * 24 * 36e5 * t));
        });
      }
      toParts(t) {
        let [e, i, s, o, r, n, a] = this.dateTimeFormat({
          weekday: "narrow",
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        }, t, "es").split(/(?:, |\/|:)/g);
        return [o, +s - 1, i, r, n, a, Math.floor(Number(t) || 0) % 1e3, tx(e)].map(Number);
      }
      dateTimeFormat(t, e, i = this.options.locale || te) {
        let s = JSON.stringify(t) + i;
        th(t) && (t = this.str2dtf(t));
        let o = this.dTLCache[s];
        if (!o) {
          t.timeZone ?? (t.timeZone = this.timezone);
          try {
            o = new Intl.DateTimeFormat(i, t);
          } catch (e) {
            /Invalid time zone/i.test(e.message) ? (to(34), t.timeZone = "UTC", o = new Intl.DateTimeFormat(i, t)) : to(e.message, !1);
          }
        }
        return this.dTLCache[s] = o, o?.format(e) || "";
      }
      str2dtf(t, e = {}) {
        let i = {
          L: {
            fractionalSecondDigits: 3
          },
          S: {
            second: "2-digit"
          },
          M: {
            minute: "numeric"
          },
          H: {
            hour: "2-digit"
          },
          k: {
            hour: "numeric"
          },
          E: {
            weekday: "narrow"
          },
          a: {
            weekday: "short"
          },
          A: {
            weekday: "long"
          },
          d: {
            day: "2-digit"
          },
          e: {
            day: "numeric"
          },
          b: {
            month: "short"
          },
          B: {
            month: "long"
          },
          m: {
            month: "2-digit"
          },
          o: {
            month: "numeric"
          },
          y: {
            year: "2-digit"
          },
          Y: {
            year: "numeric"
          }
        };
        return Object.keys(i).forEach(s => {
          -1 !== t.indexOf(s) && tr(e, i[s]);
        }), e;
      }
      makeTime(t, e, i = 1, s = 0, o, r, n) {
        let a = this.Date.UTC(t, e, i, s, o || 0, r || 0, n || 0);
        if ("UTC" !== this.timezone) {
          let t = this.getTimezoneOffset(a);
          if (a += t, -1 !== [2, 3, 8, 9, 10, 11].indexOf(e) && (s < 5 || s > 20)) {
            let e = this.getTimezoneOffset(a);
            t !== e ? a += e - t : t - 36e5 !== this.getTimezoneOffset(a - 36e5) || tf || (a -= 36e5);
          }
        }
        return a;
      }
      parse(t) {
        if (!th(t)) return t ?? void 0;
        let e = (t = t.replace(/\//g, "-").replace(/(GMT|UTC)/, "")).indexOf("Z") > -1 || /([+-][0-9]{2}):?[0-9]{2}$/.test(t),
          i = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(t);
        e || i || (t += "Z");
        let s = Date.parse(t);
        if (tn(s)) return s + (!e || i ? this.getTimezoneOffset(s) : 0);
      }
      getTimezoneOffset(t) {
        if ("UTC" !== this.timezone) {
          let [e, i, s, o, r = 0] = this.dateTimeFormat({
              timeZoneName: "shortOffset"
            }, t, "en").split(/(GMT|:)/).map(Number),
            n = -(36e5 * (s + r / 60));
          if (tn(n)) return n;
        }
        return 0;
      }
      dateFormat(t, e, i) {
        let s = O.defaultOptions?.lang;
        if (!ts(e) || isNaN(e)) return s?.invalidDate || "";
        if (th(t = t ?? "%Y-%m-%d %H:%M:%S")) {
          let i;
          let s = /%\[([a-zA-Z]+)\]/g;
          for (; i = s.exec(t);) t = t.replace(i[0], this.dateTimeFormat(i[1], e));
        }
        if (th(t) && -1 !== t.indexOf("%")) {
          let i = this,
            [o, r, n, a, h, l, d, c] = this.toParts(e),
            p = s?.weekdays || this.weekdays,
            u = s?.shortWeekdays || this.shortWeekdays,
            g = s?.months || this.months,
            f = s?.shortMonths || this.shortMonths;
          td(tr({
            a: u ? u[c] : p[c].substr(0, 3),
            A: p[c],
            d: tc(n),
            e: tc(n, 2, " "),
            w: c,
            b: f[r],
            B: g[r],
            m: tc(r + 1),
            o: r + 1,
            y: o.toString().substr(2, 2),
            Y: o,
            H: tc(a),
            k: a,
            I: tc(a % 12 || 12),
            l: a % 12 || 12,
            M: tc(h),
            p: a < 12 ? "AM" : "PM",
            P: a < 12 ? "am" : "pm",
            S: tc(l),
            L: tc(d, 3)
          }, O.dateFormats), function (s, o) {
            if (th(t)) for (; -1 !== t.indexOf("%" + o);) t = t.replace("%" + o, "function" == typeof s ? s.call(i, e) : s);
          });
        } else if (ta(t)) {
          let i = (this.getTimezoneOffset(e) || 0) / 36e5,
            s = this.timezone || "Etc/GMT" + (i >= 0 ? "+" : "") + i,
            {
              prefix: o = "",
              suffix: r = ""
            } = t;
          t = o + this.dateTimeFormat(tr({
            timeZone: s
          }, t), e) + r;
        }
        return i ? tg(t) : t;
      }
      resolveDTLFormat(t) {
        return ta(t, !0) ? ta(t, !0) && tm(t) ? {
          main: t
        } : t : {
          main: (t = tp(t))[0],
          from: t[1],
          to: t[2]
        };
      }
      getTimeTicks(t, e, i, s) {
        let o = this,
          r = [],
          n = {},
          {
            count: a = 1,
            unitRange: h
          } = t,
          [l, d, c, p, u, g] = o.toParts(e),
          f = (e || 0) % 1e3,
          m;
        if (s ?? (s = 1), ts(e)) {
          if (f = h >= tu.second ? 0 : a * Math.floor(f / a), h >= tu.second && (g = h >= tu.minute ? 0 : a * Math.floor(g / a)), h >= tu.minute && (u = h >= tu.hour ? 0 : a * Math.floor(u / a)), h >= tu.hour && (p = h >= tu.day ? 0 : a * Math.floor(p / a)), h >= tu.day && (c = h >= tu.month ? 1 : Math.max(1, a * Math.floor(c / a))), h >= tu.month && (d = h >= tu.year ? 0 : a * Math.floor(d / a)), h >= tu.year && (l -= l % a), h === tu.week) {
            a && (e = o.makeTime(l, d, c, p, u, g, f));
            let t = tx(this.dateTimeFormat({
              timeZone: this.timezone,
              weekday: "narrow"
            }, e, "es"));
            c += -t + s + (t < s ? -7 : 0);
          }
          e = o.makeTime(l, d, c, p, u, g, f), o.variableTimezone && ts(i) && (m = i - e > 4 * tu.month || o.getTimezoneOffset(e) !== o.getTimezoneOffset(i));
          let t = e,
            x = 1;
          for (; t < i;) r.push(t), h === tu.year ? t = o.makeTime(l + x * a, 0) : h === tu.month ? t = o.makeTime(l, d + x * a) : m && (h === tu.day || h === tu.week) ? t = o.makeTime(l, d, c + x * a * (h === tu.day ? 1 : 7)) : m && h === tu.hour && a > 1 ? t = o.makeTime(l, d, c, p + x * a) : t += h * a, x++;
          r.push(t), h <= tu.hour && r.length < 1e4 && r.forEach(t => {
            t % 18e5 == 0 && "000000000" === o.dateFormat("%H%M%S%L", t) && (n[t] = "day");
          });
        }
        return r.info = tr(t, {
          higherRanks: n,
          totalRange: h * a
        }), r;
      }
      getDateFormat(t, e, i, s) {
        let o = this.dateFormat("%m-%d %H:%M:%S.%L", e),
          r = "01-01 00:00:00.000",
          n = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          a = "millisecond",
          h = a;
        for (a in tu) {
          if (t === tu.week && +this.dateFormat("%w", e) === i && o.substr(6) === r.substr(6)) {
            a = "week";
            break;
          }
          if (tu[a] > t) {
            a = h;
            break;
          }
          if (n[a] && o.substr(n[a]) !== r.substr(n[a])) break;
          "week" !== a && (h = a);
        }
        return this.resolveDTLFormat(s[a]).main;
      }
    },
    {
      isTouchDevice: tb
    } = O,
    {
      fireEvent: tv,
      merge: tM
    } = tt,
    tk = {
      colors: ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"],
      symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
      lang: {
        locale: void 0,
        loading: "Loading...",
        months: void 0,
        shortMonths: void 0,
        weekdays: void 0,
        numericSymbols: ["k", "M", "G", "T", "P", "E"],
        resetZoom: "Reset zoom",
        resetZoomTitle: "Reset zoom level 1:1"
      },
      global: {
        buttonTheme: {
          fill: "#f7f7f7",
          padding: 8,
          r: 2,
          stroke: "#cccccc",
          "stroke-width": 1,
          style: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "0.8em",
            fontWeight: "normal"
          },
          states: {
            hover: {
              fill: "#e6e6e6"
            },
            select: {
              fill: "#e6e9ff",
              style: {
                color: "#000000",
                fontWeight: "bold"
              }
            },
            disabled: {
              style: {
                color: "#cccccc"
              }
            }
          }
        }
      },
      time: {
        Date: void 0,
        timezone: "UTC",
        timezoneOffset: 0,
        useUTC: void 0
      },
      chart: {
        alignThresholds: !1,
        panning: {
          enabled: !1,
          type: "x"
        },
        styledMode: !1,
        borderRadius: 0,
        colorCount: 10,
        allowMutatingData: !0,
        ignoreHiddenSeries: !0,
        spacing: [10, 10, 15, 10],
        resetZoomButton: {
          theme: {},
          position: {}
        },
        reflow: !0,
        type: "line",
        zooming: {
          singleTouch: !1,
          resetButton: {
            theme: {
              zIndex: 6
            },
            position: {
              align: "right",
              x: -10,
              y: 10
            }
          }
        },
        width: null,
        height: null,
        borderColor: "#334eff",
        backgroundColor: "#ffffff",
        plotBorderColor: "#cccccc"
      },
      title: {
        style: {
          color: "#333333",
          fontWeight: "bold"
        },
        text: "Chart title",
        margin: 15,
        minScale: .67
      },
      subtitle: {
        style: {
          color: "#666666",
          fontSize: "0.8em"
        },
        text: ""
      },
      caption: {
        margin: 15,
        style: {
          color: "#666666",
          fontSize: "0.8em"
        },
        text: "",
        align: "left",
        verticalAlign: "bottom"
      },
      plotOptions: {},
      legend: {
        enabled: !0,
        align: "center",
        alignColumns: !0,
        className: "highcharts-no-tooltip",
        events: {},
        layout: "horizontal",
        itemMarginBottom: 2,
        itemMarginTop: 2,
        labelFormatter: function () {
          return this.name;
        },
        borderColor: "#999999",
        borderRadius: 0,
        navigation: {
          style: {
            fontSize: "0.8em"
          },
          activeColor: "#0022ff",
          inactiveColor: "#cccccc"
        },
        itemStyle: {
          color: "#333333",
          cursor: "pointer",
          fontSize: "0.8em",
          textDecoration: "none",
          textOverflow: "ellipsis"
        },
        itemHoverStyle: {
          color: "#000000"
        },
        itemHiddenStyle: {
          color: "#666666",
          textDecoration: "line-through"
        },
        shadow: !1,
        itemCheckboxStyle: {
          position: "absolute",
          width: "13px",
          height: "13px"
        },
        squareSymbol: !0,
        symbolPadding: 5,
        verticalAlign: "bottom",
        x: 0,
        y: 0,
        title: {
          style: {
            fontSize: "0.8em",
            fontWeight: "bold"
          }
        }
      },
      loading: {
        labelStyle: {
          fontWeight: "bold",
          position: "relative",
          top: "45%"
        },
        style: {
          position: "absolute",
          backgroundColor: "#ffffff",
          opacity: .5,
          textAlign: "center"
        }
      },
      tooltip: {
        enabled: !0,
        animation: {
          duration: 300,
          easing: t => Math.sqrt(1 - Math.pow(t - 1, 2))
        },
        borderRadius: 3,
        dateTimeLabelFormats: {
          millisecond: "%[AebHMSL]",
          second: "%[AebHMS]",
          minute: "%[AebHM]",
          hour: "%[AebHM]",
          day: "%[AebY]",
          week: "Week from %[AebY]",
          month: "%[BY]",
          year: "%Y"
        },
        footerFormat: "",
        headerShape: "callout",
        hideDelay: 500,
        padding: 8,
        shape: "callout",
        shared: !1,
        snap: tb ? 25 : 10,
        headerFormat: '<span style="font-size: 0.8em">{ucfirst point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
        backgroundColor: "#ffffff",
        borderWidth: void 0,
        shadow: !0,
        stickOnContact: !1,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "0.8em"
        },
        useHTML: !1
      },
      credits: {
        enabled: !0,
        href: "https://www.highcharts.com?credits",
        position: {
          align: "right",
          x: -10,
          verticalAlign: "bottom",
          y: -5
        },
        style: {
          cursor: "pointer",
          color: "#999999",
          fontSize: "0.6em"
        },
        text: "Highcharts.com"
      }
    },
    tw = new ty(tk.time),
    tS = {
      defaultOptions: tk,
      defaultTime: tw,
      getOptions: function () {
        return tk;
      },
      setOptions: function (t) {
        return tv(O, "setOptions", {
          options: t
        }), tM(!0, tk, t), t.time && tw.update(tk.time), t.lang && "locale" in t.lang && tw.update({
          locale: t.lang.locale
        }), tk;
      }
    },
    {
      isNumber: tA,
      merge: tT,
      pInt: tP,
      defined: tC
    } = tt;
  class tO {
    static parse(t) {
      return t ? new tO(t) : tO.None;
    }
    constructor(t) {
      let e, i, s, o;
      this.rgba = [NaN, NaN, NaN, NaN], this.input = t;
      let r = O.Color;
      if (r && r !== tO) return new r(t);
      if ("object" == typeof t && void 0 !== t.stops) this.stops = t.stops.map(t => new tO(t[1]));else if ("string" == typeof t) for (this.input = t = tO.names[t.toLowerCase()] || t, s = tO.parsers.length; s-- && !i;) (e = (o = tO.parsers[s]).regex.exec(t)) && (i = o.parse(e));
      i && (this.rgba = i);
    }
    get(t) {
      let e = this.input,
        i = this.rgba;
      if ("object" == typeof e && void 0 !== this.stops) {
        let i = tT(e);
        return i.stops = [].slice.call(i.stops), this.stops.forEach((e, s) => {
          i.stops[s] = [i.stops[s][0], e.get(t)];
        }), i;
      }
      return i && tA(i[0]) ? "rgb" !== t && (t || 1 !== i[3]) ? "a" === t ? `${i[3]}` : "rgba(" + i.join(",") + ")" : "rgb(" + i[0] + "," + i[1] + "," + i[2] + ")" : e;
    }
    brighten(t) {
      let e = this.rgba;
      if (this.stops) this.stops.forEach(function (e) {
        e.brighten(t);
      });else if (tA(t) && 0 !== t) for (let i = 0; i < 3; i++) e[i] += tP(255 * t), e[i] < 0 && (e[i] = 0), e[i] > 255 && (e[i] = 255);
      return this;
    }
    setOpacity(t) {
      return this.rgba[3] = t, this;
    }
    tweenTo(t, e) {
      let i = this.rgba,
        s = t.rgba;
      if (!tA(i[0]) || !tA(s[0])) return t.input || "none";
      let o = 1 !== s[3] || 1 !== i[3],
        r = (t, s) => t + (i[s] - t) * (1 - e),
        n = s.slice(0, 3).map(r).map(Math.round);
      return o && n.push(r(s[3], 3)), (o ? "rgba(" : "rgb(") + n.join(",") + ")";
    }
  }
  tO.names = {
    white: "#ffffff",
    black: "#000000"
  }, tO.parsers = [{
    regex: /rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d?(?:\.\d+)?)\s*\)/,
    parse: function (t) {
      return [tP(t[1]), tP(t[2]), tP(t[3]), parseFloat(t[4], 10)];
    }
  }, {
    regex: /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/,
    parse: function (t) {
      return [tP(t[1]), tP(t[2]), tP(t[3]), 1];
    }
  }, {
    regex: /^#([a-f0-9])([a-f0-9])([a-f0-9])([a-f0-9])?$/i,
    parse: function (t) {
      return [tP(t[1] + t[1], 16), tP(t[2] + t[2], 16), tP(t[3] + t[3], 16), tC(t[4]) ? tP(t[4] + t[4], 16) / 255 : 1];
    }
  }, {
    regex: /^#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})?$/i,
    parse: function (t) {
      return [tP(t[1], 16), tP(t[2], 16), tP(t[3], 16), tC(t[4]) ? tP(t[4], 16) / 255 : 1];
    }
  }], tO.None = new tO("");
  let {
      parse: tE
    } = tO,
    {
      win: tL
    } = O,
    {
      isNumber: tB,
      objectEach: tI
    } = tt;
  class tD {
    constructor(t, e, i) {
      this.pos = NaN, this.options = e, this.elem = t, this.prop = i;
    }
    dSetter() {
      let t = this.paths,
        e = t && t[0],
        i = t && t[1],
        s = this.now || 0,
        o = [];
      if (1 !== s && e && i) {
        if (e.length === i.length && s < 1) for (let t = 0; t < i.length; t++) {
          let r = e[t],
            n = i[t],
            a = [];
          for (let t = 0; t < n.length; t++) {
            let e = r[t],
              i = n[t];
            tB(e) && tB(i) && !("A" === n[0] && (4 === t || 5 === t)) ? a[t] = e + s * (i - e) : a[t] = i;
          }
          o.push(a);
        } else o = i;
      } else o = this.toD || [];
      this.elem.attr("d", o, void 0, !0);
    }
    update() {
      let t = this.elem,
        e = this.prop,
        i = this.now,
        s = this.options.step;
      this[e + "Setter"] ? this[e + "Setter"]() : t.attr ? t.element && t.attr(e, i, null, !0) : t.style[e] = i + this.unit, s && s.call(t, i, this);
    }
    run(t, e, i) {
      let s = this,
        o = s.options,
        r = function (t) {
          return !r.stopped && s.step(t);
        },
        n = tL.requestAnimationFrame || function (t) {
          setTimeout(t, 13);
        },
        a = function () {
          for (let t = 0; t < tD.timers.length; t++) tD.timers[t]() || tD.timers.splice(t--, 1);
          tD.timers.length && n(a);
        };
      t !== e || this.elem["forceAnimate:" + this.prop] ? (this.startTime = +new Date(), this.start = t, this.end = e, this.unit = i, this.now = this.start, this.pos = 0, r.elem = this.elem, r.prop = this.prop, r() && 1 === tD.timers.push(r) && n(a)) : (delete o.curAnim[this.prop], o.complete && 0 === Object.keys(o.curAnim).length && o.complete.call(this.elem));
    }
    step(t) {
      let e, i;
      let s = +new Date(),
        o = this.options,
        r = this.elem,
        n = o.complete,
        a = o.duration,
        h = o.curAnim;
      return r.attr && !r.element ? e = !1 : t || s >= a + this.startTime ? (this.now = this.end, this.pos = 1, this.update(), h[this.prop] = !0, i = !0, tI(h, function (t) {
        !0 !== t && (i = !1);
      }), i && n && n.call(r), e = !1) : (this.pos = o.easing((s - this.startTime) / a), this.now = this.start + (this.end - this.start) * this.pos, this.update(), e = !0), e;
    }
    initPath(t, e, i) {
      let s = t.startX,
        o = t.endX,
        r = i.slice(),
        n = t.isArea,
        a = n ? 2 : 1,
        h = e && i.length > e.length && i.hasStackedCliffs,
        l,
        d,
        c,
        p,
        u = e && e.slice();
      if (!u || h) return [r, r];
      function g(t, e) {
        for (; t.length < d;) {
          let i = t[0],
            s = e[d - t.length];
          if (s && "M" === i[0] && ("C" === s[0] ? t[0] = ["C", i[1], i[2], i[1], i[2], i[1], i[2]] : t[0] = ["L", i[1], i[2]]), t.unshift(i), n) {
            let e = t.pop();
            t.push(t[t.length - 1], e);
          }
        }
      }
      function f(t) {
        for (; t.length < d;) {
          let e = t[Math.floor(t.length / a) - 1].slice();
          if ("C" === e[0] && (e[1] = e[5], e[2] = e[6]), n) {
            let i = t[Math.floor(t.length / a)].slice();
            t.splice(t.length / 2, 0, e, i);
          } else t.push(e);
        }
      }
      if (s && o && o.length) {
        for (c = 0; c < s.length; c++) {
          if (s[c] === o[0]) {
            l = c;
            break;
          }
          if (s[0] === o[o.length - s.length + c]) {
            l = c, p = !0;
            break;
          }
          if (s[s.length - 1] === o[o.length - s.length + c]) {
            l = s.length - c;
            break;
          }
        }
        void 0 === l && (u = []);
      }
      return u.length && tB(l) && (d = r.length + l * a, p ? (g(u, r), f(r)) : (g(r, u), f(u))), [u, r];
    }
    fillSetter() {
      tD.prototype.strokeSetter.apply(this, arguments);
    }
    strokeSetter() {
      this.elem.attr(this.prop, tE(this.start).tweenTo(tE(this.end), this.pos), void 0, !0);
    }
  }
  tD.timers = [];
  let {
    defined: tR,
    getStyle: tN,
    isArray: tz,
    isNumber: tW,
    isObject: tG,
    merge: tH,
    objectEach: tF,
    pick: tX
  } = tt;
  function tY(t) {
    return tG(t) ? tH({
      duration: 500,
      defer: 0
    }, t) : {
      duration: t ? 500 : 0,
      defer: 0
    };
  }
  function tj(t, e) {
    let i = tD.timers.length;
    for (; i--;) tD.timers[i].elem !== t || e && e !== tD.timers[i].prop || (tD.timers[i].stopped = !0);
  }
  let tU = {
      animate: function (t, e, i) {
        let s,
          o = "",
          r,
          n,
          a;
        tG(i) || (a = arguments, i = {
          duration: a[2],
          easing: a[3],
          complete: a[4]
        }), tW(i.duration) || (i.duration = 400), i.easing = "function" == typeof i.easing ? i.easing : Math[i.easing] || Math.easeInOutSine, i.curAnim = tH(e), tF(e, function (a, h) {
          tj(t, h), n = new tD(t, i, h), r = void 0, "d" === h && tz(e.d) ? (n.paths = n.initPath(t, t.pathArray, e.d), n.toD = e.d, s = 0, r = 1) : t.attr ? s = t.attr(h) : (s = parseFloat(tN(t, h)) || 0, "opacity" !== h && (o = "px")), r || (r = a), "string" == typeof r && r.match("px") && (r = r.replace(/px/g, "")), n.run(s, r, o);
        });
      },
      animObject: tY,
      getDeferredAnimation: function (t, e, i) {
        let s = tY(e),
          o = i ? [i] : t.series,
          r = 0,
          n = 0;
        return o.forEach(t => {
          let i = tY(t.options.animation);
          r = tG(e) && tR(e.defer) ? s.defer : Math.max(r, i.duration + i.defer), n = Math.min(s.duration, i.duration);
        }), t.renderer.forExport && (r = 0), {
          defer: Math.max(0, r - n),
          duration: Math.min(r, n)
        };
      },
      setAnimation: function (t, e) {
        e.renderer.globalAnimation = tX(t, e.options.chart.animation, !0);
      },
      stop: tj
    },
    {
      SVG_NS: tV,
      win: t$
    } = O,
    {
      attr: t_,
      createElement: tq,
      css: tZ,
      error: tK,
      isFunction: tJ,
      isString: tQ,
      objectEach: t0,
      splat: t1
    } = tt,
    {
      trustedTypes: t2
    } = t$,
    t3 = t2 && tJ(t2.createPolicy) && t2.createPolicy("highcharts", {
      createHTML: t => t
    }),
    t5 = t3 ? t3.createHTML("") : "";
  class t6 {
    static filterUserAttributes(t) {
      return t0(t, (e, i) => {
        let s = !0;
        -1 === t6.allowedAttributes.indexOf(i) && (s = !1), -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(i) && (s = tQ(e) && t6.allowedReferences.some(t => 0 === e.indexOf(t))), s || (tK(33, !1, void 0, {
          "Invalid attribute in config": `${i}`
        }), delete t[i]), tQ(e) && t[i] && (t[i] = e.replace(/</g, "&lt;"));
      }), t;
    }
    static parseStyle(t) {
      return t.split(";").reduce((t, e) => {
        let i = e.split(":").map(t => t.trim()),
          s = i.shift();
        return s && i.length && (t[s.replace(/-([a-z])/g, t => t[1].toUpperCase())] = i.join(":")), t;
      }, {});
    }
    static setElementHTML(t, e) {
      t.innerHTML = t6.emptyHTML, e && new t6(e).addToDOM(t);
    }
    constructor(t) {
      this.nodes = "string" == typeof t ? this.parseMarkup(t) : t;
    }
    addToDOM(t) {
      return function t(e, i) {
        let s;
        return t1(e).forEach(function (e) {
          let o;
          let r = e.tagName,
            n = e.textContent ? O.doc.createTextNode(e.textContent) : void 0,
            a = t6.bypassHTMLFiltering;
          if (r) {
            if ("#text" === r) o = n;else if (-1 !== t6.allowedTags.indexOf(r) || a) {
              let s = "svg" === r ? tV : i.namespaceURI || tV,
                h = O.doc.createElementNS(s, r),
                l = e.attributes || {};
              t0(e, function (t, e) {
                "tagName" !== e && "attributes" !== e && "children" !== e && "style" !== e && "textContent" !== e && (l[e] = t);
              }), t_(h, a ? l : t6.filterUserAttributes(l)), e.style && tZ(h, e.style), n && h.appendChild(n), t(e.children || [], h), o = h;
            } else tK(33, !1, void 0, {
              "Invalid tagName in config": r
            });
          }
          o && i.appendChild(o), s = o;
        }), s;
      }(this.nodes, t);
    }
    parseMarkup(t) {
      let e;
      let i = [];
      t = t.trim().replace(/ style=(["'])/g, " data-style=$1");
      try {
        e = new DOMParser().parseFromString(t3 ? t3.createHTML(t) : t, "text/html");
      } catch (t) {}
      if (!e) {
        let i = tq("div");
        i.innerHTML = t, e = {
          body: i
        };
      }
      let s = (t, e) => {
        let i = t.nodeName.toLowerCase(),
          o = {
            tagName: i
          };
        "#text" === i && (o.textContent = t.textContent || "");
        let r = t.attributes;
        if (r) {
          let t = {};
          [].forEach.call(r, e => {
            "data-style" === e.name ? o.style = t6.parseStyle(e.value) : t[e.name] = e.value;
          }), o.attributes = t;
        }
        if (t.childNodes.length) {
          let e = [];
          [].forEach.call(t.childNodes, t => {
            s(t, e);
          }), e.length && (o.children = e);
        }
        e.push(o);
      };
      return [].forEach.call(e.body.childNodes, t => s(t, i)), i;
    }
  }
  t6.allowedAttributes = ["alt", "aria-controls", "aria-describedby", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-label", "aria-labelledby", "aria-live", "aria-pressed", "aria-readonly", "aria-roledescription", "aria-selected", "class", "clip-path", "color", "colspan", "cx", "cy", "d", "dx", "dy", "disabled", "fill", "filterUnits", "flood-color", "flood-opacity", "height", "href", "id", "in", "in2", "markerHeight", "markerWidth", "offset", "opacity", "operator", "orient", "padding", "paddingLeft", "paddingRight", "patternUnits", "r", "radius", "refX", "refY", "role", "scope", "slope", "src", "startOffset", "stdDeviation", "stroke", "stroke-linecap", "stroke-width", "style", "tableValues", "result", "rowspan", "summary", "target", "tabindex", "text-align", "text-anchor", "textAnchor", "textLength", "title", "type", "valign", "width", "x", "x1", "x2", "xlink:href", "y", "y1", "y2", "zIndex"], t6.allowedReferences = ["https://", "http://", "mailto:", "/", "../", "./", "#"], t6.allowedTags = ["a", "abbr", "b", "br", "button", "caption", "circle", "clipPath", "code", "dd", "defs", "div", "dl", "dt", "em", "feComponentTransfer", "feComposite", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feMorphology", "feOffset", "feMerge", "feMergeNode", "filter", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "li", "linearGradient", "marker", "ol", "p", "path", "pattern", "pre", "rect", "small", "span", "stop", "strong", "style", "sub", "sup", "svg", "table", "text", "textPath", "thead", "title", "tbody", "tspan", "td", "th", "tr", "u", "ul", "#text"], t6.emptyHTML = t5, t6.bypassHTMLFiltering = !1;
  let {
      defaultOptions: t9,
      defaultTime: t4
    } = tS,
    {
      pageLang: t8
    } = O,
    {
      extend: t7,
      getNestedProperty: et,
      isArray: ee,
      isNumber: ei,
      isObject: es,
      isString: eo,
      pick: er,
      ucfirst: en
    } = tt,
    ea = {
      add: (t, e) => t + e,
      divide: (t, e) => 0 !== e ? t / e : "",
      eq: (t, e) => t == e,
      each: function (t) {
        let e = arguments[arguments.length - 1];
        return !!ee(t) && t.map((i, s) => ed(e.body, t7(es(i) ? i : {
          "@this": i
        }, {
          "@index": s,
          "@first": 0 === s,
          "@last": s === t.length - 1
        }))).join("");
      },
      ge: (t, e) => t >= e,
      gt: (t, e) => t > e,
      if: t => !!t,
      le: (t, e) => t <= e,
      lt: (t, e) => t < e,
      multiply: (t, e) => t * e,
      ne: (t, e) => t != e,
      subtract: (t, e) => t - e,
      ucfirst: en,
      unless: t => !t
    },
    eh = {},
    el = t => /^["'].+["']$/.test(t);
  function ed(t = "", e, i) {
    let s = /\{([\p{L}\d:\.,;\-\/<>\[\]%_@+"'’= #\(\)]+)\}/gu,
      o = /\(([\p{L}\d:\.,;\-\/<>\[\]%_@+"'= ]+)\)/gu,
      r = [],
      n = /f$/,
      a = /\.(\d)/,
      h = i?.options.lang || t9.lang,
      l = i && i.time || t4,
      d = i && i.numberFormatter || ec,
      c = (t = "") => {
        let i;
        return "true" === t || "false" !== t && ((i = Number(t)).toString() === t ? i : el(t) ? t.slice(1, -1) : et(t, e));
      },
      p,
      u,
      g = 0,
      f;
    for (; null !== (p = s.exec(t));) {
      let i = p,
        s = o.exec(p[1]);
      s && (p = s, f = !0), u && u.isBlock || (u = {
        ctx: e,
        expression: p[1],
        find: p[0],
        isBlock: "#" === p[1].charAt(0),
        start: p.index,
        startInner: p.index + p[0].length,
        length: p[0].length
      });
      let n = (u.isBlock ? i : p)[1].split(" ")[0].replace("#", "");
      ea[n] && (u.isBlock && n === u.fn && g++, u.fn || (u.fn = n));
      let a = "else" === p[1];
      if (u.isBlock && u.fn && (p[1] === `/${u.fn}` || a)) {
        if (g) !a && g--;else {
          let e = u.startInner,
            i = t.substr(e, p.index - e);
          void 0 === u.body ? (u.body = i, u.startInner = p.index + p[0].length) : u.elseBody = i, u.find += i + p[0], a || (r.push(u), u = void 0);
        }
      } else u.isBlock || r.push(u);
      if (s && !u?.isBlock) break;
    }
    return r.forEach(s => {
      let r, p;
      let {
        body: u,
        elseBody: g,
        expression: f,
        fn: m
      } = s;
      if (m) {
        let t = [s],
          o = [],
          n = f.length,
          a = 0,
          h;
        for (p = 0; p <= n; p++) {
          let t = f.charAt(p);
          h || '"' !== t && "'" !== t ? h === t && (h = "") : h = t, h || " " !== t && p !== n || (o.push(f.substr(a, p - a)), a = p + 1);
        }
        for (p = ea[m].length; p--;) t.unshift(c(o[p + 1]));
        r = ea[m].apply(e, t), s.isBlock && "boolean" == typeof r && (r = ed(r ? u : g, e, i));
      } else {
        let t = el(f) ? [f] : f.split(":");
        if (r = c(t.shift() || ""), t.length && "number" == typeof r) {
          let e = t.join(":");
          if (n.test(e)) {
            let t = parseInt((e.match(a) || ["", "-1"])[1], 10);
            null !== r && (r = d(r, t, h.decimalPoint, e.indexOf(",") > -1 ? h.thousandsSep : ""));
          } else r = l.dateFormat(e, r);
        }
        o.lastIndex = 0, o.test(s.find) && eo(r) && (r = `"${r}"`);
      }
      t = t.replace(s.find, er(r, ""));
    }), f ? ed(t, e, i) : t;
  }
  function ec(t, e, i, s) {
    e = +e;
    let o,
      r,
      [n, a] = (t = +t || 0).toString().split("e").map(Number),
      h = this?.options?.lang || t9.lang,
      l = (t.toString().split(".")[1] || "").split("e")[0].length,
      d = e,
      c = {};
    i ?? (i = h.decimalPoint), s ?? (s = h.thousandsSep), -1 === e ? e = Math.min(l, 20) : ei(e) ? e && a < 0 && ((r = e + a) >= 0 ? (n = +n.toExponential(r).split("e")[0], e = r) : (n = Math.floor(n), t = e < 20 ? +(n * Math.pow(10, a)).toFixed(e) : 0, a = 0)) : e = 2, a && (e ?? (e = 2), t = n), ei(e) && e >= 0 && (c.minimumFractionDigits = e, c.maximumFractionDigits = e), "" === s && (c.useGrouping = !1);
    let p = s || i,
      u = p ? "en" : this?.locale || h.locale || t8,
      g = JSON.stringify(c) + u;
    return o = (eh[g] ?? (eh[g] = new Intl.NumberFormat(u, c))).format(t), p && (o = o.replace(/([,\.])/g, "_$1").replace(/_\,/g, s ?? ",").replace("_.", i ?? ".")), (e || 0 != +o) && (!(a < 0) || d) || (o = "0"), a && 0 != +o && (o += "e" + (a < 0 ? "" : "+") + a), o;
  }
  let ep = {
    dateFormat: function (t, e, i) {
      return t4.dateFormat(t, e, i);
    },
    format: ed,
    helpers: ea,
    numberFormat: ec
  };
  !function (t) {
    let e;
    t.rendererTypes = {}, t.getRendererType = function (i = e) {
      return t.rendererTypes[i] || t.rendererTypes[e];
    }, t.registerRendererType = function (i, s, o) {
      t.rendererTypes[i] = s, (!e || o) && (e = i, O.Renderer = s);
    };
  }(n || (n = {}));
  let eu = n,
    {
      clamp: eg,
      pick: ef,
      pushUnique: em,
      stableSort: ex
    } = tt;
  (a || (a = {})).distribute = function t(e, i, s) {
    let o = e,
      r = o.reducedLen || i,
      n = (t, e) => t.target - e.target,
      a = [],
      h = e.length,
      l = [],
      d = a.push,
      c,
      p,
      u,
      g = !0,
      f,
      m,
      x = 0,
      y;
    for (c = h; c--;) x += e[c].size;
    if (x > r) {
      for (ex(e, (t, e) => (e.rank || 0) - (t.rank || 0)), u = (y = e[0].rank === e[e.length - 1].rank) ? h / 2 : -1, p = y ? u : h - 1; u && x > r;) f = e[c = Math.floor(p)], em(l, c) && (x -= f.size), p += u, y && p >= e.length && (u /= 2, p = u);
      l.sort((t, e) => e - t).forEach(t => d.apply(a, e.splice(t, 1)));
    }
    for (ex(e, n), e = e.map(t => ({
      size: t.size,
      targets: [t.target],
      align: ef(t.align, .5)
    })); g;) {
      for (c = e.length; c--;) f = e[c], m = (Math.min.apply(0, f.targets) + Math.max.apply(0, f.targets)) / 2, f.pos = eg(m - f.size * f.align, 0, i - f.size);
      for (c = e.length, g = !1; c--;) c > 0 && e[c - 1].pos + e[c - 1].size > e[c].pos && (e[c - 1].size += e[c].size, e[c - 1].targets = e[c - 1].targets.concat(e[c].targets), e[c - 1].align = .5, e[c - 1].pos + e[c - 1].size > i && (e[c - 1].pos = i - e[c - 1].size), e.splice(c, 1), g = !0);
    }
    return d.apply(o, a), c = 0, e.some(e => {
      let r = 0;
      return (e.targets || []).some(() => (o[c].pos = e.pos + r, void 0 !== s && Math.abs(o[c].pos - o[c].target) > s) ? (o.slice(0, c + 1).forEach(t => delete t.pos), o.reducedLen = (o.reducedLen || i) - .1 * i, o.reducedLen > .1 * i && t(o, i, s), !0) : (r += o[c].size, c++, !1));
    }), ex(o, n), o;
  };
  let ey = a,
    {
      animate: eb,
      animObject: ev,
      stop: eM
    } = tU,
    {
      deg2rad: ek,
      doc: ew,
      svg: eS,
      SVG_NS: eA,
      win: eT,
      isFirefox: eP
    } = O,
    {
      addEvent: eC,
      attr: eO,
      createElement: eE,
      crisp: eL,
      css: eB,
      defined: eI,
      erase: eD,
      extend: eR,
      fireEvent: eN,
      getAlignFactor: ez,
      isArray: eW,
      isFunction: eG,
      isNumber: eH,
      isObject: eF,
      isString: eX,
      merge: eY,
      objectEach: ej,
      pick: eU,
      pInt: eV,
      pushUnique: e$,
      replaceNested: e_,
      syncTimeout: eq,
      uniqueKey: eZ
    } = tt;
  class eK {
    _defaultGetter(t) {
      let e = eU(this[t + "Value"], this[t], this.element ? this.element.getAttribute(t) : null, 0);
      return /^-?[\d\.]+$/.test(e) && (e = parseFloat(e)), e;
    }
    _defaultSetter(t, e, i) {
      i.setAttribute(e, t);
    }
    add(t) {
      let e;
      let i = this.renderer,
        s = this.element;
      return t && (this.parentGroup = t), void 0 !== this.textStr && "text" === this.element.nodeName && i.buildText(this), this.added = !0, (!t || t.handleZ || this.zIndex) && (e = this.zIndexSetter()), e || (t ? t.element : i.box).appendChild(s), this.onAdd && this.onAdd(), this;
    }
    addClass(t, e) {
      let i = e ? "" : this.attr("class") || "";
      return (t = (t || "").split(/ /g).reduce(function (t, e) {
        return -1 === i.indexOf(e) && t.push(e), t;
      }, i ? [i] : []).join(" ")) !== i && this.attr("class", t), this;
    }
    afterSetters() {
      this.doTransform && (this.updateTransform(), this.doTransform = !1);
    }
    align(t, e, i, s = !0) {
      let o = this.renderer,
        r = o.alignedObjects,
        n = !!t;
      t ? (this.alignOptions = t, this.alignByTranslate = e, this.alignTo = i) : (t = this.alignOptions || {}, e = this.alignByTranslate, i = this.alignTo);
      let a = !i || eX(i) ? i || "renderer" : void 0;
      a && (n && e$(r, this), i = void 0);
      let h = eU(i, o[a], o),
        l = (h.x || 0) + (t.x || 0) + ((h.width || 0) - (t.width || 0)) * ez(t.align),
        d = (h.y || 0) + (t.y || 0) + ((h.height || 0) - (t.height || 0)) * ez(t.verticalAlign),
        c = {
          "text-align": t?.align
        };
      return c[e ? "translateX" : "x"] = Math.round(l), c[e ? "translateY" : "y"] = Math.round(d), s && (this[this.placed ? "animate" : "attr"](c), this.placed = !0), this.alignAttr = c, this;
    }
    alignSetter(t) {
      let e = {
        left: "start",
        center: "middle",
        right: "end"
      };
      e[t] && (this.alignValue = t, this.element.setAttribute("text-anchor", e[t]));
    }
    animate(t, e, i) {
      let s = ev(eU(e, this.renderer.globalAnimation, !0)),
        o = s.defer;
      return ew.hidden && (s.duration = 0), 0 !== s.duration ? (i && (s.complete = i), eq(() => {
        this.element && eb(this, t, s);
      }, o)) : (this.attr(t, void 0, i || s.complete), ej(t, function (t, e) {
        s.step && s.step.call(this, t, {
          prop: e,
          pos: 1,
          elem: this
        });
      }, this)), this;
    }
    applyTextOutline(t) {
      let e = this.element;
      -1 !== t.indexOf("contrast") && (t = t.replace(/contrast/g, this.renderer.getContrast(e.style.fill)));
      let i = t.split(" "),
        s = i[i.length - 1],
        o = i[0];
      if (o && "none" !== o && O.svg) {
        this.fakeTS = !0, o = o.replace(/(^[\d\.]+)(.*?)$/g, function (t, e, i) {
          return 2 * Number(e) + i;
        }), this.removeTextOutline();
        let t = ew.createElementNS(eA, "tspan");
        eO(t, {
          class: "highcharts-text-outline",
          fill: s,
          stroke: s,
          "stroke-width": o,
          "stroke-linejoin": "round"
        });
        let i = e.querySelector("textPath") || e;
        [].forEach.call(i.childNodes, e => {
          let i = e.cloneNode(!0);
          i.removeAttribute && ["fill", "stroke", "stroke-width", "stroke"].forEach(t => i.removeAttribute(t)), t.appendChild(i);
        });
        let r = 0;
        [].forEach.call(i.querySelectorAll("text tspan"), t => {
          r += Number(t.getAttribute("dy"));
        });
        let n = ew.createElementNS(eA, "tspan");
        n.textContent = "​", eO(n, {
          x: Number(e.getAttribute("x")),
          dy: -r
        }), t.appendChild(n), i.insertBefore(t, i.firstChild);
      }
    }
    attr(t, e, i, s) {
      let {
          element: o
        } = this,
        r = eK.symbolCustomAttribs,
        n,
        a,
        h = this,
        l;
      return "string" == typeof t && void 0 !== e && (n = t, (t = {})[n] = e), "string" == typeof t ? h = (this[t + "Getter"] || this._defaultGetter).call(this, t, o) : (ej(t, function (e, i) {
        l = !1, s || eM(this, i), this.symbolName && -1 !== r.indexOf(i) && (a || (this.symbolAttr(t), a = !0), l = !0), this.rotation && ("x" === i || "y" === i) && (this.doTransform = !0), l || (this[i + "Setter"] || this._defaultSetter).call(this, e, i, o);
      }, this), this.afterSetters()), i && i.call(this), h;
    }
    clip(t) {
      if (t && !t.clipPath) {
        let e = eZ() + "-",
          i = this.renderer.createElement("clipPath").attr({
            id: e
          }).add(this.renderer.defs);
        eR(t, {
          clipPath: i,
          id: e,
          count: 0
        }), t.add(i);
      }
      return this.attr("clip-path", t ? `url(${this.renderer.url}#${t.id})` : "none");
    }
    crisp(t, e) {
      e = Math.round(e || t.strokeWidth || 0);
      let i = t.x || this.x || 0,
        s = t.y || this.y || 0,
        o = (t.width || this.width || 0) + i,
        r = (t.height || this.height || 0) + s,
        n = eL(i, e),
        a = eL(s, e);
      return eR(t, {
        x: n,
        y: a,
        width: eL(o, e) - n,
        height: eL(r, e) - a
      }), eI(t.strokeWidth) && (t.strokeWidth = e), t;
    }
    complexColor(t, e, i) {
      let s = this.renderer,
        o,
        r,
        n,
        a,
        h,
        l,
        d,
        c,
        p,
        u,
        g = [],
        f;
      eN(this.renderer, "complexColor", {
        args: arguments
      }, function () {
        if (t.radialGradient ? r = "radialGradient" : t.linearGradient && (r = "linearGradient"), r) {
          if (n = t[r], h = s.gradients, l = t.stops, p = i.radialReference, eW(n) && (t[r] = n = {
            x1: n[0],
            y1: n[1],
            x2: n[2],
            y2: n[3],
            gradientUnits: "userSpaceOnUse"
          }), "radialGradient" === r && p && !eI(n.gradientUnits) && (a = n, n = eY(n, s.getRadialAttr(p, a), {
            gradientUnits: "userSpaceOnUse"
          })), ej(n, function (t, e) {
            "id" !== e && g.push(e, t);
          }), ej(l, function (t) {
            g.push(t);
          }), h[g = g.join(",")]) u = h[g].attr("id");else {
            n.id = u = eZ();
            let t = h[g] = s.createElement(r).attr(n).add(s.defs);
            t.radAttr = a, t.stops = [], l.forEach(function (e) {
              0 === e[1].indexOf("rgba") ? (d = (o = tO.parse(e[1])).get("rgb"), c = o.get("a")) : (d = e[1], c = 1);
              let i = s.createElement("stop").attr({
                offset: e[0],
                "stop-color": d,
                "stop-opacity": c
              }).add(t);
              t.stops.push(i);
            });
          }
          f = "url(" + s.url + "#" + u + ")", i.setAttribute(e, f), i.gradient = g, t.toString = function () {
            return f;
          };
        }
      });
    }
    css(t) {
      let e = this.styles,
        i = {},
        s = this.element,
        o,
        r = !e;
      if (e && ej(t, function (t, s) {
        e && e[s] !== t && (i[s] = t, r = !0);
      }), r) {
        e && (t = eR(e, i)), null === t.width || "auto" === t.width ? delete this.textWidth : "text" === s.nodeName.toLowerCase() && t.width && (o = this.textWidth = eV(t.width)), eR(this.styles, t), o && !eS && this.renderer.forExport && delete t.width;
        let r = eP && t.fontSize || null;
        r && (eH(r) || /^\d+$/.test(r)) && (t.fontSize += "px");
        let n = eY(t);
        s.namespaceURI === this.SVG_NS && (["textOutline", "textOverflow", "whiteSpace", "width"].forEach(t => n && delete n[t]), n.color && (n.fill = n.color)), eB(s, n);
      }
      return this.added && ("text" === this.element.nodeName && this.renderer.buildText(this), t.textOutline && this.applyTextOutline(t.textOutline)), this;
    }
    dashstyleSetter(t) {
      let e,
        i = this["stroke-width"];
      if ("inherit" === i && (i = 1), t = t && t.toLowerCase()) {
        let s = t.replace("shortdashdotdot", "3,1,1,1,1,1,").replace("shortdashdot", "3,1,1,1").replace("shortdot", "1,1,").replace("shortdash", "3,1,").replace("longdash", "8,3,").replace(/dot/g, "1,3,").replace("dash", "4,3,").replace(/,$/, "").split(",");
        for (e = s.length; e--;) s[e] = "" + eV(s[e]) * eU(i, NaN);
        t = s.join(",").replace(/NaN/g, "none"), this.element.setAttribute("stroke-dasharray", t);
      }
    }
    destroy() {
      let t = this,
        e = t.element || {},
        i = t.renderer,
        s = e.ownerSVGElement,
        o = "SPAN" === e.nodeName && t.parentGroup || void 0,
        r,
        n;
      if (e.onclick = e.onmouseout = e.onmouseover = e.onmousemove = e.point = null, eM(t), t.clipPath && s) {
        let e = t.clipPath;
        [].forEach.call(s.querySelectorAll("[clip-path],[CLIP-PATH]"), function (t) {
          t.getAttribute("clip-path").indexOf(e.element.id) > -1 && t.removeAttribute("clip-path");
        }), t.clipPath = e.destroy();
      }
      if (t.connector = t.connector?.destroy(), t.stops) {
        for (n = 0; n < t.stops.length; n++) t.stops[n].destroy();
        t.stops.length = 0, t.stops = void 0;
      }
      for (t.safeRemoveChild(e); o && o.div && 0 === o.div.childNodes.length;) r = o.parentGroup, t.safeRemoveChild(o.div), delete o.div, o = r;
      t.alignOptions && eD(i.alignedObjects, t), ej(t, function (e, i) {
        t[i] && t[i].parentGroup === t && t[i].destroy && t[i].destroy(), delete t[i];
      });
    }
    dSetter(t, e, i) {
      eW(t) && ("string" == typeof t[0] && (t = this.renderer.pathToSegments(t)), this.pathArray = t, t = t.reduce((t, e, i) => e && e.join ? (i ? t + " " : "") + e.join(" ") : (e || "").toString(), "")), /(NaN| {2}|^$)/.test(t) && (t = "M 0 0"), this[e] !== t && (i.setAttribute(e, t), this[e] = t);
    }
    fillSetter(t, e, i) {
      "string" == typeof t ? i.setAttribute(e, t) : t && this.complexColor(t, e, i);
    }
    hrefSetter(t, e, i) {
      i.setAttributeNS("http://www.w3.org/1999/xlink", e, t);
    }
    getBBox(t, e) {
      let i, s, o, r;
      let {
          alignValue: n,
          element: a,
          renderer: h,
          styles: l,
          textStr: d
        } = this,
        {
          cache: c,
          cacheKeys: p
        } = h,
        u = a.namespaceURI === this.SVG_NS,
        g = eU(e, this.rotation, 0),
        f = h.styledMode ? a && eK.prototype.getStyle.call(a, "font-size") : l.fontSize;
      if (eI(d) && (-1 === (r = d.toString()).indexOf("<") && (r = r.replace(/\d/g, "0")), r += ["", h.rootFontSize, f, g, this.textWidth, n, l.lineClamp, l.textOverflow, l.fontWeight].join(",")), r && !t && (i = c[r]), !i || i.polygon) {
        if (u || h.forExport) {
          try {
            o = this.fakeTS && function (t) {
              let e = a.querySelector(".highcharts-text-outline");
              e && eB(e, {
                display: t
              });
            }, eG(o) && o("none"), i = a.getBBox ? eR({}, a.getBBox()) : {
              width: a.offsetWidth,
              height: a.offsetHeight,
              x: 0,
              y: 0
            }, eG(o) && o("");
          } catch (t) {}
          (!i || i.width < 0) && (i = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          });
        } else i = this.htmlGetBBox();
        s = i.height, u && (i.height = s = {
          "11px,17": 14,
          "13px,20": 16
        }[`${f || ""},${Math.round(s)}`] || s), g && (i = this.getRotatedBox(i, g));
        let t = {
          bBox: i
        };
        eN(this, "afterGetBBox", t), i = t.bBox;
      }
      if (r && ("" === d || i.height > 0)) {
        for (; p.length > 250;) delete c[p.shift()];
        c[r] || p.push(r), c[r] = i;
      }
      return i;
    }
    getRotatedBox(t, e) {
      let {
          x: i,
          y: s,
          width: o,
          height: r
        } = t,
        {
          alignValue: n,
          translateY: a,
          rotationOriginX: h = 0,
          rotationOriginY: l = 0
        } = this,
        d = ez(n),
        c = Number(this.element.getAttribute("y") || 0) - (a ? 0 : s),
        p = e * ek,
        u = (e - 90) * ek,
        g = Math.cos(p),
        f = Math.sin(p),
        m = o * g,
        x = o * f,
        y = Math.cos(u),
        b = Math.sin(u),
        [[v, M], [k, w]] = [h, l].map(t => [t - t * g, t * f]),
        S = i + d * (o - m) + v + w + c * y,
        A = S + m,
        T = A - r * y,
        P = T - m,
        C = s + c - d * x - M + k + c * b,
        O = C + x,
        E = O - r * b,
        L = E - x,
        B = Math.min(S, A, T, P),
        I = Math.min(C, O, E, L),
        D = Math.max(S, A, T, P) - B,
        R = Math.max(C, O, E, L) - I;
      return {
        x: B,
        y: I,
        width: D,
        height: R,
        polygon: [[S, C], [A, O], [T, E], [P, L]]
      };
    }
    getStyle(t) {
      return eT.getComputedStyle(this.element || this, "").getPropertyValue(t);
    }
    hasClass(t) {
      return -1 !== ("" + this.attr("class")).split(" ").indexOf(t);
    }
    hide() {
      return this.attr({
        visibility: "hidden"
      });
    }
    htmlGetBBox() {
      return {
        height: 0,
        width: 0,
        x: 0,
        y: 0
      };
    }
    constructor(t, e) {
      this.onEvents = {}, this.opacity = 1, this.SVG_NS = eA, this.element = "span" === e || "body" === e ? eE(e) : ew.createElementNS(this.SVG_NS, e), this.renderer = t, this.styles = {}, eN(this, "afterInit");
    }
    on(t, e) {
      let {
        onEvents: i
      } = this;
      return i[t] && i[t](), i[t] = eC(this.element, t, e), this;
    }
    opacitySetter(t, e, i) {
      let s = Number(Number(t).toFixed(3));
      this.opacity = s, i.setAttribute(e, s);
    }
    reAlign() {
      this.alignOptions?.width && "left" !== this.alignOptions.align && (this.alignOptions.width = this.getBBox().width, this.placed = !1, this.align());
    }
    removeClass(t) {
      return this.attr("class", ("" + this.attr("class")).replace(eX(t) ? RegExp(`(^| )${t}( |$)`) : t, " ").replace(/ +/g, " ").trim());
    }
    removeTextOutline() {
      let t = this.element.querySelector("tspan.highcharts-text-outline");
      t && this.safeRemoveChild(t);
    }
    safeRemoveChild(t) {
      let e = t.parentNode;
      e && e.removeChild(t);
    }
    setRadialReference(t) {
      let e = this.element.gradient && this.renderer.gradients[this.element.gradient];
      return this.element.radialReference = t, e && e.radAttr && e.animate(this.renderer.getRadialAttr(t, e.radAttr)), this;
    }
    shadow(t) {
      let {
          renderer: e
        } = this,
        i = eY(this.parentGroup?.rotation === 90 ? {
          offsetX: -1,
          offsetY: -1
        } : {}, eF(t) ? t : {}),
        s = e.shadowDefinition(i);
      return this.attr({
        filter: t ? `url(${e.url}#${s})` : "none"
      });
    }
    show(t = !0) {
      return this.attr({
        visibility: t ? "inherit" : "visible"
      });
    }
    "stroke-widthSetter"(t, e, i) {
      this[e] = t, i.setAttribute(e, t);
    }
    strokeWidth() {
      if (!this.renderer.styledMode) return this["stroke-width"] || 0;
      let t = this.getStyle("stroke-width"),
        e = 0,
        i;
      return /px$/.test(t) ? e = eV(t) : "" !== t && (eO(i = ew.createElementNS(eA, "rect"), {
        width: t,
        "stroke-width": 0
      }), this.element.parentNode.appendChild(i), e = i.getBBox().width, i.parentNode.removeChild(i)), e;
    }
    symbolAttr(t) {
      let e = this;
      eK.symbolCustomAttribs.forEach(function (i) {
        e[i] = eU(t[i], e[i]);
      }), e.attr({
        d: e.renderer.symbols[e.symbolName](e.x, e.y, e.width, e.height, e)
      });
    }
    textSetter(t) {
      t !== this.textStr && (delete this.textPxLength, this.textStr = t, this.added && this.renderer.buildText(this), this.reAlign());
    }
    titleSetter(t) {
      let e = this.element,
        i = e.getElementsByTagName("title")[0] || ew.createElementNS(this.SVG_NS, "title");
      e.insertBefore ? e.insertBefore(i, e.firstChild) : e.appendChild(i), i.textContent = e_(eU(t, ""), [/<[^>]*>/g, ""]).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }
    toFront() {
      let t = this.element;
      return t.parentNode.appendChild(t), this;
    }
    translate(t, e) {
      return this.attr({
        translateX: t,
        translateY: e
      });
    }
    updateTransform(t = "transform") {
      let {
          element: e,
          matrix: i,
          rotation: s = 0,
          rotationOriginX: o,
          rotationOriginY: r,
          scaleX: n,
          scaleY: a,
          translateX: h = 0,
          translateY: l = 0
        } = this,
        d = ["translate(" + h + "," + l + ")"];
      eI(i) && d.push("matrix(" + i.join(",") + ")"), s && (d.push("rotate(" + s + " " + eU(o, e.getAttribute("x"), 0) + " " + eU(r, e.getAttribute("y") || 0) + ")"), this.text?.element.tagName === "SPAN" && this.text.attr({
        rotation: s,
        rotationOriginX: (o || 0) - this.padding,
        rotationOriginY: (r || 0) - this.padding
      })), (eI(n) || eI(a)) && d.push("scale(" + eU(n, 1) + " " + eU(a, 1) + ")"), d.length && !(this.text || this).textPath && e.setAttribute(t, d.join(" "));
    }
    visibilitySetter(t, e, i) {
      "inherit" === t ? i.removeAttribute(e) : this[e] !== t && i.setAttribute(e, t), this[e] = t;
    }
    xGetter(t) {
      return "circle" === this.element.nodeName && ("x" === t ? t = "cx" : "y" === t && (t = "cy")), this._defaultGetter(t);
    }
    zIndexSetter(t, e) {
      let i = this.renderer,
        s = this.parentGroup,
        o = (s || i).element || i.box,
        r = this.element,
        n = o === i.box,
        a,
        h,
        l,
        d = !1,
        c,
        p = this.added,
        u;
      if (eI(t) ? (r.setAttribute("data-z-index", t), t = +t, this[e] === t && (p = !1)) : eI(this[e]) && r.removeAttribute("data-z-index"), this[e] = t, p) {
        for ((t = this.zIndex) && s && (s.handleZ = !0), u = (a = o.childNodes).length - 1; u >= 0 && !d; u--) c = !eI(l = (h = a[u]).getAttribute("data-z-index")), h !== r && (t < 0 && c && !n && !u ? (o.insertBefore(r, a[u]), d = !0) : (eV(l) <= t || c && (!eI(t) || t >= 0)) && (o.insertBefore(r, a[u + 1]), d = !0));
        d || (o.insertBefore(r, a[n ? 3 : 0]), d = !0);
      }
      return d;
    }
  }
  eK.symbolCustomAttribs = ["anchorX", "anchorY", "clockwise", "end", "height", "innerR", "r", "start", "width", "x", "y"], eK.prototype.strokeSetter = eK.prototype.fillSetter, eK.prototype.yGetter = eK.prototype.xGetter, eK.prototype.matrixSetter = eK.prototype.rotationOriginXSetter = eK.prototype.rotationOriginYSetter = eK.prototype.rotationSetter = eK.prototype.scaleXSetter = eK.prototype.scaleYSetter = eK.prototype.translateXSetter = eK.prototype.translateYSetter = eK.prototype.verticalAlignSetter = function (t, e) {
    this[e] = t, this.doTransform = !0;
  };
  let eJ = eK,
    {
      defined: eQ,
      extend: e0,
      getAlignFactor: e1,
      isNumber: e2,
      merge: e3,
      pick: e5,
      removeEvent: e6
    } = tt;
  class e9 extends eJ {
    constructor(t, e, i, s, o, r, n, a, h, l) {
      let d;
      super(t, "g"), this.paddingLeftSetter = this.paddingSetter, this.paddingRightSetter = this.paddingSetter, this.doUpdate = !1, this.textStr = e, this.x = i, this.y = s, this.anchorX = r, this.anchorY = n, this.baseline = h, this.className = l, this.addClass("button" === l ? "highcharts-no-tooltip" : "highcharts-label"), l && this.addClass("highcharts-" + l), this.text = t.text(void 0, 0, 0, a).attr({
        zIndex: 1
      }), "string" == typeof o && ((d = /^url\((.*?)\)$/.test(o)) || this.renderer.symbols[o]) && (this.symbolKey = o), this.bBox = e9.emptyBBox, this.padding = 3, this.baselineOffset = 0, this.needsBox = t.styledMode || d, this.deferredAttr = {}, this.alignFactor = 0;
    }
    alignSetter(t) {
      let e = e1(t);
      this.textAlign = t, e !== this.alignFactor && (this.alignFactor = e, this.bBox && e2(this.xSetting) && this.attr({
        x: this.xSetting
      }));
    }
    anchorXSetter(t, e) {
      this.anchorX = t, this.boxAttr(e, Math.round(t) - this.getCrispAdjust() - this.xSetting);
    }
    anchorYSetter(t, e) {
      this.anchorY = t, this.boxAttr(e, t - this.ySetting);
    }
    boxAttr(t, e) {
      this.box ? this.box.attr(t, e) : this.deferredAttr[t] = e;
    }
    css(t) {
      if (t) {
        let e = {};
        t = e3(t), e9.textProps.forEach(i => {
          void 0 !== t[i] && (e[i] = t[i], delete t[i]);
        }), this.text.css(e), "fontSize" in e || "fontWeight" in e ? this.updateTextPadding() : ("width" in e || "textOverflow" in e) && this.updateBoxSize();
      }
      return eJ.prototype.css.call(this, t);
    }
    destroy() {
      e6(this.element, "mouseenter"), e6(this.element, "mouseleave"), this.text && this.text.destroy(), this.box && (this.box = this.box.destroy()), eJ.prototype.destroy.call(this);
    }
    fillSetter(t, e) {
      t && (this.needsBox = !0), this.fill = t, this.boxAttr(e, t);
    }
    getBBox(t, e) {
      this.textStr && 0 === this.bBox.width && 0 === this.bBox.height && this.updateBoxSize();
      let {
          padding: i,
          height: s = 0,
          translateX: o = 0,
          translateY: r = 0,
          width: n = 0
        } = this,
        a = e5(this.paddingLeft, i),
        h = e ?? (this.rotation || 0),
        l = {
          width: n,
          height: s,
          x: o + this.bBox.x - a,
          y: r + this.bBox.y - i + this.baselineOffset
        };
      return h && (l = this.getRotatedBox(l, h)), l;
    }
    getCrispAdjust() {
      return (this.renderer.styledMode && this.box ? this.box.strokeWidth() : this["stroke-width"] ? parseInt(this["stroke-width"], 10) : 0) % 2 / 2;
    }
    heightSetter(t) {
      this.heightSetting = t, this.doUpdate = !0;
    }
    afterSetters() {
      super.afterSetters(), this.doUpdate && (this.updateBoxSize(), this.doUpdate = !1);
    }
    onAdd() {
      this.text.add(this), this.attr({
        text: e5(this.textStr, ""),
        x: this.x || 0,
        y: this.y || 0
      }), this.box && eQ(this.anchorX) && this.attr({
        anchorX: this.anchorX,
        anchorY: this.anchorY
      });
    }
    paddingSetter(t, e) {
      e2(t) ? t !== this[e] && (this[e] = t, this.updateTextPadding()) : this[e] = void 0;
    }
    rSetter(t, e) {
      this.boxAttr(e, t);
    }
    strokeSetter(t, e) {
      this.stroke = t, this.boxAttr(e, t);
    }
    "stroke-widthSetter"(t, e) {
      t && (this.needsBox = !0), this["stroke-width"] = t, this.boxAttr(e, t);
    }
    "text-alignSetter"(t) {
      this.textAlign = this["text-align"] = t, this.updateTextPadding();
    }
    textSetter(t) {
      void 0 !== t && this.text.attr({
        text: t
      }), this.updateTextPadding(), this.reAlign();
    }
    updateBoxSize() {
      let t;
      let e = this.text,
        i = {},
        s = this.padding,
        o = this.bBox = (!e2(this.widthSetting) || !e2(this.heightSetting) || this.textAlign) && eQ(e.textStr) ? e.getBBox(void 0, 0) : e9.emptyBBox;
      this.width = this.getPaddedWidth(), this.height = (this.heightSetting || o.height || 0) + 2 * s;
      let r = this.renderer.fontMetrics(e);
      if (this.baselineOffset = s + Math.min((this.text.firstLineMetrics || r).b, o.height || 1 / 0), this.heightSetting && (this.baselineOffset += (this.heightSetting - r.h) / 2), this.needsBox && !e.textPath) {
        if (!this.box) {
          let t = this.box = this.symbolKey ? this.renderer.symbol(this.symbolKey) : this.renderer.rect();
          t.addClass(("button" === this.className ? "" : "highcharts-label-box") + (this.className ? " highcharts-" + this.className + "-box" : "")), t.add(this);
        }
        t = this.getCrispAdjust(), i.x = t, i.y = (this.baseline ? -this.baselineOffset : 0) + t, i.width = Math.round(this.width), i.height = Math.round(this.height), this.box.attr(e0(i, this.deferredAttr)), this.deferredAttr = {};
      }
    }
    updateTextPadding() {
      let t = this.text,
        e = t.styles.textAlign || this.textAlign;
      if (!t.textPath) {
        this.updateBoxSize();
        let i = this.baseline ? 0 : this.baselineOffset,
          s = (this.paddingLeft ?? this.padding) + e1(e) * (this.widthSetting ?? this.bBox.width);
        (s !== t.x || i !== t.y) && (t.attr({
          align: e,
          x: s
        }), void 0 !== i && t.attr("y", i)), t.x = s, t.y = i;
      }
    }
    widthSetter(t) {
      this.widthSetting = e2(t) ? t : void 0, this.doUpdate = !0;
    }
    getPaddedWidth() {
      let t = this.padding,
        e = e5(this.paddingLeft, t),
        i = e5(this.paddingRight, t);
      return (this.widthSetting || this.bBox.width || 0) + e + i;
    }
    xSetter(t) {
      this.x = t, this.alignFactor && (t -= this.alignFactor * this.getPaddedWidth(), this["forceAnimate:x"] = !0), this.xSetting = Math.round(t), this.attr("translateX", this.xSetting);
    }
    ySetter(t) {
      this.ySetting = this.y = Math.round(t), this.attr("translateY", this.ySetting);
    }
  }
  e9.emptyBBox = {
    width: 0,
    height: 0,
    x: 0,
    y: 0
  }, e9.textProps = ["color", "direction", "fontFamily", "fontSize", "fontStyle", "fontWeight", "lineClamp", "lineHeight", "textAlign", "textDecoration", "textOutline", "textOverflow", "whiteSpace", "width"];
  let {
    defined: e4,
    isNumber: e8,
    pick: e7
  } = tt;
  function it(t, e, i, s, o) {
    let r = [];
    if (o) {
      let n = o.start || 0,
        a = e7(o.r, i),
        h = e7(o.r, s || i),
        l = 2e-4 / (o.borderRadius ? 1 : Math.max(a, 1)),
        d = Math.abs((o.end || 0) - n - 2 * Math.PI) < l,
        c = (o.end || 0) - (d ? l : 0),
        p = o.innerR,
        u = e7(o.open, d),
        g = Math.cos(n),
        f = Math.sin(n),
        m = Math.cos(c),
        x = Math.sin(c),
        y = e7(o.longArc, c - n - Math.PI < l ? 0 : 1),
        b = ["A", a, h, 0, y, e7(o.clockwise, 1), t + a * m, e + h * x];
      b.params = {
        start: n,
        end: c,
        cx: t,
        cy: e
      }, r.push(["M", t + a * g, e + h * f], b), e4(p) && ((b = ["A", p, p, 0, y, e4(o.clockwise) ? 1 - o.clockwise : 0, t + p * g, e + p * f]).params = {
        start: c,
        end: n,
        cx: t,
        cy: e
      }, r.push(u ? ["M", t + p * m, e + p * x] : ["L", t + p * m, e + p * x], b)), u || r.push(["Z"]);
    }
    return r;
  }
  function ie(t, e, i, s, o) {
    return o && o.r ? ii(t, e, i, s, o) : [["M", t, e], ["L", t + i, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
  }
  function ii(t, e, i, s, o) {
    let r = o?.r || 0;
    return [["M", t + r, e], ["L", t + i - r, e], ["A", r, r, 0, 0, 1, t + i, e + r], ["L", t + i, e + s - r], ["A", r, r, 0, 0, 1, t + i - r, e + s], ["L", t + r, e + s], ["A", r, r, 0, 0, 1, t, e + s - r], ["L", t, e + r], ["A", r, r, 0, 0, 1, t + r, e], ["Z"]];
  }
  let is = {
      arc: it,
      callout: function (t, e, i, s, o) {
        let r = Math.min(o && o.r || 0, i, s),
          n = r + 6,
          a = o && o.anchorX,
          h = o && o.anchorY || 0,
          l = ii(t, e, i, s, {
            r
          });
        if (!e8(a) || a < i && a > 0 && h < s && h > 0) return l;
        if (t + a > i - n) {
          if (h > e + n && h < e + s - n) l.splice(3, 1, ["L", t + i, h - 6], ["L", t + i + 6, h], ["L", t + i, h + 6], ["L", t + i, e + s - r]);else if (a < i) {
            let o = h < e + n,
              d = o ? e : e + s;
            l.splice(o ? 2 : 5, 0, ["L", a, h], ["L", t + i - r, d]);
          } else l.splice(3, 1, ["L", t + i, s / 2], ["L", a, h], ["L", t + i, s / 2], ["L", t + i, e + s - r]);
        } else if (t + a < n) {
          if (h > e + n && h < e + s - n) l.splice(7, 1, ["L", t, h + 6], ["L", t - 6, h], ["L", t, h - 6], ["L", t, e + r]);else if (a > 0) {
            let i = h < e + n,
              o = i ? e : e + s;
            l.splice(i ? 1 : 6, 0, ["L", a, h], ["L", t + r, o]);
          } else l.splice(7, 1, ["L", t, s / 2], ["L", a, h], ["L", t, s / 2], ["L", t, e + r]);
        } else h > s && a < i - n ? l.splice(5, 1, ["L", a + 6, e + s], ["L", a, e + s + 6], ["L", a - 6, e + s], ["L", t + r, e + s]) : h < 0 && a > n && l.splice(1, 1, ["L", a - 6, e], ["L", a, e - 6], ["L", a + 6, e], ["L", i - r, e]);
        return l;
      },
      circle: function (t, e, i, s) {
        return it(t + i / 2, e + s / 2, i / 2, s / 2, {
          start: .5 * Math.PI,
          end: 2.5 * Math.PI,
          open: !1
        });
      },
      diamond: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s / 2], ["L", t + i / 2, e + s], ["L", t, e + s / 2], ["Z"]];
      },
      rect: ie,
      roundedRect: ii,
      square: ie,
      triangle: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
      },
      "triangle-down": function (t, e, i, s) {
        return [["M", t, e], ["L", t + i, e], ["L", t + i / 2, e + s], ["Z"]];
      }
    },
    {
      doc: io,
      SVG_NS: ir,
      win: ia
    } = O,
    {
      attr: ih,
      extend: il,
      fireEvent: id,
      isString: ic,
      objectEach: ip,
      pick: iu
    } = tt,
    ig = (t, e) => t.substring(0, e) + "…",
    im = class {
      constructor(t) {
        let e = t.styles;
        this.renderer = t.renderer, this.svgElement = t, this.width = t.textWidth, this.textLineHeight = e && e.lineHeight, this.textOutline = e && e.textOutline, this.ellipsis = !!(e && "ellipsis" === e.textOverflow), this.lineClamp = e?.lineClamp, this.noWrap = !!(e && "nowrap" === e.whiteSpace);
      }
      buildSVG() {
        let t = this.svgElement,
          e = t.element,
          i = t.renderer,
          s = iu(t.textStr, "").toString(),
          o = -1 !== s.indexOf("<"),
          r = e.childNodes,
          n = !t.added && i.box,
          a = [s, this.ellipsis, this.noWrap, this.textLineHeight, this.textOutline, t.getStyle("font-size"), t.styles.lineClamp, this.width].join(",");
        if (a !== t.textCache) {
          t.textCache = a, delete t.actualWidth;
          for (let t = r.length; t--;) e.removeChild(r[t]);
          if (o || this.ellipsis || this.width || t.textPath || -1 !== s.indexOf(" ") && (!this.noWrap || /<br.*?>/g.test(s))) {
            if ("" !== s) {
              n && n.appendChild(e);
              let i = new t6(s);
              this.modifyTree(i.nodes), i.addToDOM(e), this.modifyDOM(), this.ellipsis && -1 !== (e.textContent || "").indexOf("…") && t.attr("title", this.unescapeEntities(t.textStr || "", ["&lt;", "&gt;"])), n && n.removeChild(e);
            }
          } else e.appendChild(io.createTextNode(this.unescapeEntities(s)));
          ic(this.textOutline) && t.applyTextOutline && t.applyTextOutline(this.textOutline);
        }
      }
      modifyDOM() {
        let t;
        let e = this.svgElement,
          i = ih(e.element, "x");
        for (e.firstLineMetrics = void 0; t = e.element.firstChild;) if (/^[\s\u200B]*$/.test(t.textContent || " ")) e.element.removeChild(t);else break;
        [].forEach.call(e.element.querySelectorAll("tspan.highcharts-br"), (t, s) => {
          t.nextSibling && t.previousSibling && (0 === s && 1 === t.previousSibling.nodeType && (e.firstLineMetrics = e.renderer.fontMetrics(t.previousSibling)), ih(t, {
            dy: this.getLineHeight(t.nextSibling),
            x: i
          }));
        });
        let s = this.width || 0;
        if (!s) return;
        let o = (t, o) => {
            let r = t.textContent || "",
              n = r.replace(/([^\^])-/g, "$1- ").split(" "),
              a = !this.noWrap && (n.length > 1 || e.element.childNodes.length > 1),
              h = this.getLineHeight(o),
              l = Math.max(0, s - .8 * h),
              d = 0,
              c = e.actualWidth;
            if (a) {
              let r = [],
                a = [];
              for (; o.firstChild && o.firstChild !== t;) a.push(o.firstChild), o.removeChild(o.firstChild);
              for (; n.length;) if (n.length && !this.noWrap && d > 0 && (r.push(t.textContent || ""), t.textContent = n.join(" ").replace(/- /g, "-")), this.truncate(t, void 0, n, 0 === d && c || 0, s, l, (t, e) => n.slice(0, e).join(" ").replace(/- /g, "-")), c = e.actualWidth, d++, this.lineClamp && d >= this.lineClamp) {
                n.length && (this.truncate(t, t.textContent || "", void 0, 0, s, l, ig), t.textContent = t.textContent?.replace("…", "") + "…");
                break;
              }
              a.forEach(e => {
                o.insertBefore(e, t);
              }), r.forEach(e => {
                o.insertBefore(io.createTextNode(e), t);
                let s = io.createElementNS(ir, "tspan");
                s.textContent = "​", ih(s, {
                  dy: h,
                  x: i
                }), o.insertBefore(s, t);
              });
            } else this.ellipsis && r && this.truncate(t, r, void 0, 0, s, l, ig);
          },
          r = t => {
            [].slice.call(t.childNodes).forEach(i => {
              i.nodeType === ia.Node.TEXT_NODE ? o(i, t) : (-1 !== i.className.baseVal.indexOf("highcharts-br") && (e.actualWidth = 0), r(i));
            });
          };
        r(e.element);
      }
      getLineHeight(t) {
        let e = t.nodeType === ia.Node.TEXT_NODE ? t.parentElement : t;
        return this.textLineHeight ? parseInt(this.textLineHeight.toString(), 10) : this.renderer.fontMetrics(e || this.svgElement.element).h;
      }
      modifyTree(t) {
        let e = (i, s) => {
          let {
              attributes: o = {},
              children: r,
              style: n = {},
              tagName: a
            } = i,
            h = this.renderer.styledMode;
          if ("b" === a || "strong" === a ? h ? o.class = "highcharts-strong" : n.fontWeight = "bold" : ("i" === a || "em" === a) && (h ? o.class = "highcharts-emphasized" : n.fontStyle = "italic"), n && n.color && (n.fill = n.color), "br" === a) {
            o.class = "highcharts-br", i.textContent = "​";
            let e = t[s + 1];
            e && e.textContent && (e.textContent = e.textContent.replace(/^ +/gm, ""));
          } else "a" === a && r && r.some(t => "#text" === t.tagName) && (i.children = [{
            children: r,
            tagName: "tspan"
          }]);
          "#text" !== a && "a" !== a && (i.tagName = "tspan"), il(i, {
            attributes: o,
            style: n
          }), r && r.filter(t => "#text" !== t.tagName).forEach(e);
        };
        t.forEach(e), id(this.svgElement, "afterModifyTree", {
          nodes: t
        });
      }
      truncate(t, e, i, s, o, r, n) {
        let a, h;
        let l = this.svgElement,
          {
            rotation: d
          } = l,
          c = [],
          p = i && !s ? 1 : 0,
          u = (e || i || "").length,
          g = u;
        i || (o = r);
        let f = function (e, o) {
          let r = o || e,
            n = t.parentNode;
          if (n && void 0 === c[r] && n.getSubStringLength) try {
            c[r] = s + n.getSubStringLength(0, i ? r + 1 : r);
          } catch (t) {}
          return c[r];
        };
        if (l.rotation = 0, s + (h = f(t.textContent.length)) > o) {
          for (; p <= u;) g = Math.ceil((p + u) / 2), i && (a = n(i, g)), h = f(g, a && a.length - 1), p === u ? p = u + 1 : h > o ? u = g - 1 : p = g;
          0 === u ? t.textContent = "" : e && u === e.length - 1 || (t.textContent = a || n(e || i, g)), this.ellipsis && h > o && this.truncate(t, t.textContent || "", void 0, 0, o, r, ig);
        }
        i && i.splice(0, g), l.actualWidth = h, l.rotation = d;
      }
      unescapeEntities(t, e) {
        return ip(this.renderer.escapes, function (i, s) {
          e && -1 !== e.indexOf(i) || (t = t.toString().replace(RegExp(i, "g"), s));
        }), t;
      }
    },
    {
      defaultOptions: ix
    } = tS,
    {
      charts: iy,
      deg2rad: ib,
      doc: iv,
      isFirefox: iM,
      isMS: ik,
      isWebKit: iw,
      noop: iS,
      SVG_NS: iA,
      symbolSizes: iT,
      win: iP
    } = O,
    {
      addEvent: iC,
      attr: iO,
      createElement: iE,
      crisp: iL,
      css: iB,
      defined: iI,
      destroyObjectProperties: iD,
      extend: iR,
      isArray: iN,
      isNumber: iz,
      isObject: iW,
      isString: iG,
      merge: iH,
      pick: iF,
      pInt: iX,
      replaceNested: iY,
      uniqueKey: ij
    } = tt;
  class iU {
    constructor(t, e, i, s, o, r, n) {
      let a, h;
      let l = this.createElement("svg").attr({
          version: "1.1",
          class: "highcharts-root"
        }),
        d = l.element;
      n || l.css(this.getStyle(s || {})), t.appendChild(d), iO(t, "dir", "ltr"), -1 === t.innerHTML.indexOf("xmlns") && iO(d, "xmlns", this.SVG_NS), this.box = d, this.boxWrapper = l, this.alignedObjects = [], this.url = this.getReferenceURL(), this.createElement("desc").add().element.appendChild(iv.createTextNode("Created with Highcharts 12.1.2")), this.defs = this.createElement("defs").add(), this.allowHTML = r, this.forExport = o, this.styledMode = n, this.gradients = {}, this.cache = {}, this.cacheKeys = [], this.imgCount = 0, this.rootFontSize = l.getStyle("font-size"), this.setSize(e, i, !1), iM && t.getBoundingClientRect && ((a = function () {
        iB(t, {
          left: 0,
          top: 0
        }), h = t.getBoundingClientRect(), iB(t, {
          left: Math.ceil(h.left) - h.left + "px",
          top: Math.ceil(h.top) - h.top + "px"
        });
      })(), this.unSubPixelFix = iC(iP, "resize", a));
    }
    definition(t) {
      return new t6([t]).addToDOM(this.defs.element);
    }
    getReferenceURL() {
      if ((iM || iw) && iv.getElementsByTagName("base").length) {
        if (!iI(e)) {
          let t = ij(),
            i = new t6([{
              tagName: "svg",
              attributes: {
                width: 8,
                height: 8
              },
              children: [{
                tagName: "defs",
                children: [{
                  tagName: "clipPath",
                  attributes: {
                    id: t
                  },
                  children: [{
                    tagName: "rect",
                    attributes: {
                      width: 4,
                      height: 4
                    }
                  }]
                }]
              }, {
                tagName: "rect",
                attributes: {
                  id: "hitme",
                  width: 8,
                  height: 8,
                  "clip-path": `url(#${t})`,
                  fill: "rgba(0,0,0,0.001)"
                }
              }]
            }]).addToDOM(iv.body);
          iB(i, {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9e5
          });
          let s = iv.elementFromPoint(6, 6);
          e = "hitme" === (s && s.id), iv.body.removeChild(i);
        }
        if (e) return iY(iP.location.href.split("#")[0], [/<[^>]*>/g, ""], [/([\('\)])/g, "\\$1"], [/ /g, "%20"]);
      }
      return "";
    }
    getStyle(t) {
      return this.style = iR({
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
        fontSize: "1rem"
      }, t), this.style;
    }
    setStyle(t) {
      this.boxWrapper.css(this.getStyle(t));
    }
    isHidden() {
      return !this.boxWrapper.getBBox().width;
    }
    destroy() {
      let t = this.defs;
      return this.box = null, this.boxWrapper = this.boxWrapper.destroy(), iD(this.gradients || {}), this.gradients = null, this.defs = t.destroy(), this.unSubPixelFix && this.unSubPixelFix(), this.alignedObjects = null, null;
    }
    createElement(t) {
      return new this.Element(this, t);
    }
    getRadialAttr(t, e) {
      return {
        cx: t[0] - t[2] / 2 + (e.cx || 0) * t[2],
        cy: t[1] - t[2] / 2 + (e.cy || 0) * t[2],
        r: (e.r || 0) * t[2]
      };
    }
    shadowDefinition(t) {
      let e = [`highcharts-drop-shadow-${this.chartIndex}`, ...Object.keys(t).map(e => `${e}-${t[e]}`)].join("-").toLowerCase().replace(/[^a-z\d\-]/g, ""),
        i = iH({
          color: "#000000",
          offsetX: 1,
          offsetY: 1,
          opacity: .15,
          width: 5
        }, t);
      return this.defs.element.querySelector(`#${e}`) || this.definition({
        tagName: "filter",
        attributes: {
          id: e,
          filterUnits: i.filterUnits
        },
        children: this.getShadowFilterContent(i)
      }), e;
    }
    getShadowFilterContent(t) {
      return [{
        tagName: "feDropShadow",
        attributes: {
          dx: t.offsetX,
          dy: t.offsetY,
          "flood-color": t.color,
          "flood-opacity": Math.min(5 * t.opacity, 1),
          stdDeviation: t.width / 2
        }
      }];
    }
    buildText(t) {
      new im(t).buildSVG();
    }
    getContrast(t) {
      let e = tO.parse(t).rgba.map(t => {
          let e = t / 255;
          return e <= .03928 ? e / 12.92 : Math.pow((e + .055) / 1.055, 2.4);
        }),
        i = .2126 * e[0] + .7152 * e[1] + .0722 * e[2];
      return 1.05 / (i + .05) > (i + .05) / .05 ? "#FFFFFF" : "#000000";
    }
    button(t, e, i, s, o = {}, r, n, a, h, l) {
      let d = this.label(t, e, i, h, void 0, void 0, l, void 0, "button"),
        c = this.styledMode,
        p = arguments,
        u = 0;
      o = iH(ix.global.buttonTheme, o), c && (delete o.fill, delete o.stroke, delete o["stroke-width"]);
      let g = o.states || {},
        f = o.style || {};
      delete o.states, delete o.style;
      let m = [t6.filterUserAttributes(o)],
        x = [f];
      return c || ["hover", "select", "disabled"].forEach((t, e) => {
        m.push(iH(m[0], t6.filterUserAttributes(p[e + 5] || g[t] || {}))), x.push(m[e + 1].style), delete m[e + 1].style;
      }), iC(d.element, ik ? "mouseover" : "mouseenter", function () {
        3 !== u && d.setState(1);
      }), iC(d.element, ik ? "mouseout" : "mouseleave", function () {
        3 !== u && d.setState(u);
      }), d.setState = (t = 0) => {
        if (1 !== t && (d.state = u = t), d.removeClass(/highcharts-button-(normal|hover|pressed|disabled)/).addClass("highcharts-button-" + ["normal", "hover", "pressed", "disabled"][t]), !c) {
          d.attr(m[t]);
          let e = x[t];
          iW(e) && d.css(e);
        }
      }, d.attr(m[0]), !c && (d.css(iR({
        cursor: "default"
      }, f)), l && d.text.css({
        pointerEvents: "none"
      })), d.on("touchstart", t => t.stopPropagation()).on("click", function (t) {
        3 !== u && s.call(d, t);
      });
    }
    crispLine(t, e) {
      let [i, s] = t;
      return iI(i[1]) && i[1] === s[1] && (i[1] = s[1] = iL(i[1], e)), iI(i[2]) && i[2] === s[2] && (i[2] = s[2] = iL(i[2], e)), t;
    }
    path(t) {
      let e = this.styledMode ? {} : {
        fill: "none"
      };
      return iN(t) ? e.d = t : iW(t) && iR(e, t), this.createElement("path").attr(e);
    }
    circle(t, e, i) {
      let s = iW(t) ? t : void 0 === t ? {} : {
          x: t,
          y: e,
          r: i
        },
        o = this.createElement("circle");
      return o.xSetter = o.ySetter = function (t, e, i) {
        i.setAttribute("c" + e, t);
      }, o.attr(s);
    }
    arc(t, e, i, s, o, r) {
      let n;
      iW(t) ? (e = (n = t).y, i = n.r, s = n.innerR, o = n.start, r = n.end, t = n.x) : n = {
        innerR: s,
        start: o,
        end: r
      };
      let a = this.symbol("arc", t, e, i, i, n);
      return a.r = i, a;
    }
    rect(t, e, i, s, o, r) {
      let n = iW(t) ? t : void 0 === t ? {} : {
          x: t,
          y: e,
          r: o,
          width: Math.max(i || 0, 0),
          height: Math.max(s || 0, 0)
        },
        a = this.createElement("rect");
      return this.styledMode || (void 0 !== r && (n["stroke-width"] = r, iR(n, a.crisp(n))), n.fill = "none"), a.rSetter = function (t, e, i) {
        a.r = t, iO(i, {
          rx: t,
          ry: t
        });
      }, a.rGetter = function () {
        return a.r || 0;
      }, a.attr(n);
    }
    roundedRect(t) {
      return this.symbol("roundedRect").attr(t);
    }
    setSize(t, e, i) {
      this.width = t, this.height = e, this.boxWrapper.animate({
        width: t,
        height: e
      }, {
        step: function () {
          this.attr({
            viewBox: "0 0 " + this.attr("width") + " " + this.attr("height")
          });
        },
        duration: iF(i, !0) ? void 0 : 0
      }), this.alignElements();
    }
    g(t) {
      let e = this.createElement("g");
      return t ? e.attr({
        class: "highcharts-" + t
      }) : e;
    }
    image(t, e, i, s, o, r) {
      let n = {
        preserveAspectRatio: "none"
      };
      iz(e) && (n.x = e), iz(i) && (n.y = i), iz(s) && (n.width = s), iz(o) && (n.height = o);
      let a = this.createElement("image").attr(n),
        h = function (e) {
          a.attr({
            href: t
          }), r.call(a, e);
        };
      if (r) {
        a.attr({
          href: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        });
        let e = new iP.Image();
        iC(e, "load", h), e.src = t, e.complete && h({});
      } else a.attr({
        href: t
      });
      return a;
    }
    symbol(t, e, i, s, o, r) {
      let n, a, h, l;
      let d = this,
        c = /^url\((.*?)\)$/,
        p = c.test(t),
        u = !p && (this.symbols[t] ? t : "circle"),
        g = u && this.symbols[u];
      if (g) "number" == typeof e && (a = g.call(this.symbols, e || 0, i || 0, s || 0, o || 0, r)), n = this.path(a), d.styledMode || n.attr("fill", "none"), iR(n, {
        symbolName: u || void 0,
        x: e,
        y: i,
        width: s,
        height: o
      }), r && iR(n, r);else if (p) {
        h = t.match(c)[1];
        let s = n = this.image(h);
        s.imgwidth = iF(r && r.width, iT[h] && iT[h].width), s.imgheight = iF(r && r.height, iT[h] && iT[h].height), l = t => t.attr({
          width: t.width,
          height: t.height
        }), ["width", "height"].forEach(t => {
          s[`${t}Setter`] = function (t, e) {
            this[e] = t;
            let {
                alignByTranslate: i,
                element: s,
                width: o,
                height: n,
                imgwidth: a,
                imgheight: h
              } = this,
              l = "width" === e ? a : h,
              d = 1;
            r && "within" === r.backgroundSize && o && n && a && h ? (d = Math.min(o / a, n / h), iO(s, {
              width: Math.round(a * d),
              height: Math.round(h * d)
            })) : s && l && s.setAttribute(e, l), !i && a && h && this.translate(((o || 0) - a * d) / 2, ((n || 0) - h * d) / 2);
          };
        }), iI(e) && s.attr({
          x: e,
          y: i
        }), s.isImg = !0, s.symbolUrl = t, iI(s.imgwidth) && iI(s.imgheight) ? l(s) : (s.attr({
          width: 0,
          height: 0
        }), iE("img", {
          onload: function () {
            let t = iy[d.chartIndex];
            0 === this.width && (iB(this, {
              position: "absolute",
              top: "-999em"
            }), iv.body.appendChild(this)), iT[h] = {
              width: this.width,
              height: this.height
            }, s.imgwidth = this.width, s.imgheight = this.height, s.element && l(s), this.parentNode && this.parentNode.removeChild(this), d.imgCount--, d.imgCount || !t || t.hasLoaded || t.onload();
          },
          src: h
        }), this.imgCount++);
      }
      return n;
    }
    clipRect(t, e, i, s) {
      return this.rect(t, e, i, s, 0);
    }
    text(t, e, i, s) {
      let o = {};
      if (s && (this.allowHTML || !this.forExport)) return this.html(t, e, i);
      o.x = Math.round(e || 0), i && (o.y = Math.round(i)), iI(t) && (o.text = t);
      let r = this.createElement("text").attr(o);
      return s && (!this.forExport || this.allowHTML) || (r.xSetter = function (t, e, i) {
        let s = i.getElementsByTagName("tspan"),
          o = i.getAttribute(e);
        for (let i = 0, r; i < s.length; i++) (r = s[i]).getAttribute(e) === o && r.setAttribute(e, t);
        i.setAttribute(e, t);
      }), r;
    }
    fontMetrics(t) {
      let e = iX(eJ.prototype.getStyle.call(t, "font-size") || 0),
        i = e < 24 ? e + 3 : Math.round(1.2 * e),
        s = Math.round(.8 * i);
      return {
        h: i,
        b: s,
        f: e
      };
    }
    rotCorr(t, e, i) {
      let s = t;
      return e && i && (s = Math.max(s * Math.cos(e * ib), 4)), {
        x: -t / 3 * Math.sin(e * ib),
        y: s
      };
    }
    pathToSegments(t) {
      let e = [],
        i = [],
        s = {
          A: 8,
          C: 7,
          H: 2,
          L: 3,
          M: 3,
          Q: 5,
          S: 5,
          T: 3,
          V: 2
        };
      for (let o = 0; o < t.length; o++) iG(i[0]) && iz(t[o]) && i.length === s[i[0].toUpperCase()] && t.splice(o, 0, i[0].replace("M", "L").replace("m", "l")), "string" == typeof t[o] && (i.length && e.push(i.slice(0)), i.length = 0), i.push(t[o]);
      return e.push(i.slice(0)), e;
    }
    label(t, e, i, s, o, r, n, a, h) {
      return new e9(this, t, e, i, s, o, r, n, a, h);
    }
    alignElements() {
      this.alignedObjects.forEach(t => t.align());
    }
  }
  iR(iU.prototype, {
    Element: eJ,
    SVG_NS: iA,
    escapes: {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      "'": "&#39;",
      '"': "&quot;"
    },
    symbols: is,
    draw: iS
  }), eu.registerRendererType("svg", iU, !0);
  let {
      composed: iV
    } = O,
    {
      attr: i$,
      css: i_,
      createElement: iq,
      defined: iZ,
      extend: iK,
      getAlignFactor: iJ,
      isNumber: iQ,
      pInt: i0,
      pushUnique: i1
    } = tt;
  function i2(t, e, i) {
    let s = this.div?.style || i.style;
    eJ.prototype[`${e}Setter`].call(this, t, e, i), s && (s[e] = t);
  }
  let i3 = (t, e) => {
    if (!t.div) {
      let i = i$(t.element, "class"),
        s = t.css,
        o = iq("div", i ? {
          className: i
        } : void 0, {
          position: "absolute",
          left: `${t.translateX || 0}px`,
          top: `${t.translateY || 0}px`,
          ...t.styles,
          display: t.display,
          opacity: t.opacity,
          visibility: t.visibility
        }, t.parentGroup?.div || e);
      t.classSetter = (t, e, i) => {
        i.setAttribute("class", t), o.className = t;
      }, t.translateXSetter = t.translateYSetter = (e, i) => {
        t[i] = e, o.style["translateX" === i ? "left" : "top"] = `${e}px`, t.doTransform = !0;
      }, t.opacitySetter = t.visibilitySetter = i2, t.css = e => (s.call(t, e), e.cursor && (o.style.cursor = e.cursor), e.pointerEvents && (o.style.pointerEvents = e.pointerEvents), t), t.on = function () {
        return eJ.prototype.on.apply({
          element: o,
          onEvents: t.onEvents
        }, arguments), t;
      }, t.div = o;
    }
    return t.div;
  };
  class i5 extends eJ {
    static compose(t) {
      i1(iV, this.compose) && (t.prototype.html = function (t, e, i) {
        return new i5(this, "span").attr({
          text: t,
          x: Math.round(e),
          y: Math.round(i)
        });
      });
    }
    constructor(t, e) {
      super(t, e), this.css({
        position: "absolute",
        ...(t.styledMode ? {} : {
          fontFamily: t.style.fontFamily,
          fontSize: t.style.fontSize
        })
      });
    }
    getSpanCorrection(t, e, i) {
      this.xCorr = -t * i, this.yCorr = -e;
    }
    css(t) {
      let e;
      let {
          element: i
        } = this,
        s = "SPAN" === i.tagName && t && "width" in t,
        o = s && t.width;
      return s && (delete t.width, this.textWidth = i0(o) || void 0, e = !0), t?.textOverflow === "ellipsis" && (t.overflow = "hidden"), t?.lineClamp && (t.display = "-webkit-box", t.WebkitLineClamp = t.lineClamp, t.WebkitBoxOrient = "vertical", t.overflow = "hidden"), iQ(Number(t?.fontSize)) && (t.fontSize = t.fontSize + "px"), iK(this.styles, t), i_(i, t), e && this.updateTransform(), this;
    }
    htmlGetBBox() {
      let {
        element: t
      } = this;
      return {
        x: t.offsetLeft,
        y: t.offsetTop,
        width: t.offsetWidth,
        height: t.offsetHeight
      };
    }
    updateTransform() {
      if (!this.added) {
        this.alignOnAdd = !0;
        return;
      }
      let {
          element: t,
          renderer: e,
          rotation: i,
          rotationOriginX: s,
          rotationOriginY: o,
          scaleX: r,
          scaleY: n,
          styles: a,
          textAlign: h = "left",
          textWidth: l,
          translateX: d = 0,
          translateY: c = 0,
          x: p = 0,
          y: u = 0
        } = this,
        {
          display: g = "block",
          whiteSpace: f
        } = a;
      if (i_(t, {
        marginLeft: `${d}px`,
        marginTop: `${c}px`
      }), "SPAN" === t.tagName) {
        let a;
        let d = [i, h, t.innerHTML, l, this.textAlign].join(","),
          c = -(this.parentGroup?.padding * 1) || 0;
        if (l !== this.oldTextWidth) {
          let e = this.textPxLength ? this.textPxLength : (i_(t, {
              width: "",
              whiteSpace: f || "nowrap"
            }), t.offsetWidth),
            s = l || 0;
          (s > this.oldTextWidth || e > s) && (/[ \-]/.test(t.textContent || t.innerText) || "ellipsis" === t.style.textOverflow) && (i_(t, {
            width: e > s || i || r ? l + "px" : "auto",
            display: g,
            whiteSpace: f || "normal"
          }), this.oldTextWidth = l);
        }
        d !== this.cTT && (a = e.fontMetrics(t).b, iZ(i) && (i !== (this.oldRotation || 0) || h !== this.oldAlign) && this.setSpanRotation(i, c, c), this.getSpanCorrection(!iZ(i) && !this.textWidth && this.textPxLength || t.offsetWidth, a, iJ(h)));
        let {
            xCorr: m = 0,
            yCorr: x = 0
          } = this,
          y = {
            left: `${p + m}px`,
            top: `${u + x}px`,
            textAlign: h,
            transformOrigin: `${(s ?? p) - m - p - c}px ${(o ?? u) - x - u - c}px`
          };
        (r || n) && (y.transform = `scale(${r ?? 1},${n ?? 1})`), i_(t, y), this.cTT = d, this.oldRotation = i, this.oldAlign = h;
      }
    }
    setSpanRotation(t, e, i) {
      i_(this.element, {
        transform: `rotate(${t}deg)`,
        transformOrigin: `${e}% ${i}px`
      });
    }
    add(t) {
      let e;
      let i = this.renderer.box.parentNode,
        s = [];
      if (this.parentGroup = t, t && !(e = t.div)) {
        let o = t;
        for (; o;) s.push(o), o = o.parentGroup;
        for (let t of s.reverse()) e = i3(t, i);
      }
      return (e || i).appendChild(this.element), this.added = !0, this.alignOnAdd && this.updateTransform(), this;
    }
    textSetter(t) {
      t !== this.textStr && (delete this.bBox, delete this.oldTextWidth, t6.setElementHTML(this.element, t ?? ""), this.textStr = t, this.doTransform = !0);
    }
    alignSetter(t) {
      this.alignValue = this.textAlign = t, this.doTransform = !0;
    }
    xSetter(t, e) {
      this[e] = t, this.doTransform = !0;
    }
  }
  let i6 = i5.prototype;
  i6.visibilitySetter = i6.opacitySetter = i2, i6.ySetter = i6.rotationSetter = i6.rotationOriginXSetter = i6.rotationOriginYSetter = i6.xSetter, function (t) {
    t.xAxis = {
      alignTicks: !0,
      allowDecimals: void 0,
      panningEnabled: !0,
      zIndex: 2,
      zoomEnabled: !0,
      dateTimeLabelFormats: {
        millisecond: {
          main: "%[HMSL]",
          range: !1
        },
        second: {
          main: "%[HMS]",
          range: !1
        },
        minute: {
          main: "%[HM]",
          range: !1
        },
        hour: {
          main: "%[HM]",
          range: !1
        },
        day: {
          main: "%[eb]"
        },
        week: {
          main: "%[eb]"
        },
        month: {
          main: "%[bY]"
        },
        year: {
          main: "%Y"
        }
      },
      endOnTick: !1,
      gridLineDashStyle: "Solid",
      gridZIndex: 1,
      labels: {
        autoRotationLimit: 80,
        distance: 15,
        enabled: !0,
        indentation: 10,
        overflow: "justify",
        reserveSpace: void 0,
        rotation: void 0,
        staggerLines: 0,
        step: 0,
        useHTML: !1,
        zIndex: 7,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "0.8em",
          textOverflow: "ellipsis"
        }
      },
      maxPadding: .01,
      minorGridLineDashStyle: "Solid",
      minorTickLength: 2,
      minorTickPosition: "outside",
      minorTicksPerMajor: 5,
      minPadding: .01,
      offset: void 0,
      reversed: void 0,
      reversedStacks: !1,
      showEmpty: !0,
      showFirstLabel: !0,
      showLastLabel: !0,
      startOfWeek: 1,
      startOnTick: !1,
      tickLength: 10,
      tickPixelInterval: 100,
      tickmarkPlacement: "between",
      tickPosition: "outside",
      title: {
        align: "middle",
        useHTML: !1,
        x: 0,
        y: 0,
        style: {
          color: "#666666",
          fontSize: "0.8em"
        }
      },
      visible: !0,
      minorGridLineColor: "#f2f2f2",
      minorGridLineWidth: 1,
      minorTickColor: "#999999",
      lineColor: "#333333",
      lineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineWidth: void 0,
      tickColor: "#333333"
    }, t.yAxis = {
      reversedStacks: !0,
      endOnTick: !0,
      maxPadding: .05,
      minPadding: .05,
      tickPixelInterval: 72,
      showLastLabel: !0,
      labels: {
        x: void 0
      },
      startOnTick: !0,
      title: {
        text: "Values"
      },
      stackLabels: {
        animation: {},
        allowOverlap: !1,
        enabled: !1,
        crop: !0,
        overflow: "justify",
        formatter: function () {
          let {
            numberFormatter: t
          } = this.axis.chart;
          return t(this.total || 0, -1);
        },
        style: {
          color: "#000000",
          fontSize: "0.7em",
          fontWeight: "bold",
          textOutline: "1px contrast"
        }
      },
      gridLineWidth: 1,
      lineWidth: 0
    };
  }(h || (h = {}));
  let i9 = h,
    {
      addEvent: i4,
      isFunction: i8,
      objectEach: i7,
      removeEvent: st
    } = tt;
  (l || (l = {})).registerEventOptions = function (t, e) {
    t.eventOptions = t.eventOptions || {}, i7(e.events, function (e, i) {
      t.eventOptions[i] !== e && (t.eventOptions[i] && (st(t, i, t.eventOptions[i]), delete t.eventOptions[i]), i8(e) && (t.eventOptions[i] = e, i4(t, i, e, {
        order: 0
      })));
    });
  };
  let se = l,
    {
      deg2rad: si
    } = O,
    {
      clamp: ss,
      correctFloat: so,
      defined: sr,
      destroyObjectProperties: sn,
      extend: sa,
      fireEvent: sh,
      getAlignFactor: sl,
      isNumber: sd,
      merge: sc,
      objectEach: sp,
      pick: su
    } = tt,
    sg = class {
      constructor(t, e, i, s, o) {
        this.isNew = !0, this.isNewLabel = !0, this.axis = t, this.pos = e, this.type = i || "", this.parameters = o || {}, this.tickmarkOffset = this.parameters.tickmarkOffset, this.options = this.parameters.options, sh(this, "init"), i || s || this.addLabel();
      }
      addLabel() {
        let t = this,
          e = t.axis,
          i = e.options,
          s = e.chart,
          o = e.categories,
          r = e.logarithmic,
          n = e.names,
          a = t.pos,
          h = su(t.options && t.options.labels, i.labels),
          l = e.tickPositions,
          d = a === l[0],
          c = a === l[l.length - 1],
          p = (!h.step || 1 === h.step) && 1 === e.tickInterval,
          u = l.info,
          g = t.label,
          f,
          m,
          x,
          y = this.parameters.category || (o ? su(o[a], n[a], a) : a);
        r && sd(y) && (y = so(r.lin2log(y))), e.dateTime && (u ? f = (m = s.time.resolveDTLFormat(i.dateTimeLabelFormats[!i.grid && u.higherRanks[a] || u.unitName])).main : sd(y) && (f = e.dateTime.getXDateFormat(y, i.dateTimeLabelFormats || {}))), t.isFirst = d, t.isLast = c;
        let b = {
          axis: e,
          chart: s,
          dateTimeLabelFormat: f,
          isFirst: d,
          isLast: c,
          pos: a,
          tick: t,
          tickPositionInfo: u,
          value: y
        };
        sh(this, "labelFormat", b);
        let v = t => h.formatter ? h.formatter.call(t, t) : h.format ? (t.text = e.defaultLabelFormatter.call(t), ep.format(h.format, t, s)) : e.defaultLabelFormatter.call(t),
          M = v.call(b, b),
          k = m && m.list;
        k ? t.shortenLabel = function () {
          for (x = 0; x < k.length; x++) if (sa(b, {
            dateTimeLabelFormat: k[x]
          }), g.attr({
            text: v.call(b, b)
          }), g.getBBox().width < e.getSlotWidth(t) - 2 * (h.padding || 0)) return;
          g.attr({
            text: ""
          });
        } : t.shortenLabel = void 0, p && e._addedPlotLB && t.moveLabel(M, h), sr(g) || t.movedLabel ? g && g.textStr !== M && !p && (!g.textWidth || h.style.width || g.styles.width || g.css({
          width: null
        }), g.attr({
          text: M
        }), g.textPxLength = g.getBBox().width) : (t.label = g = t.createLabel(M, h), t.rotation = 0);
      }
      createLabel(t, e, i) {
        let s = this.axis,
          {
            renderer: o,
            styledMode: r
          } = s.chart,
          n = sr(t) && e.enabled ? o.text(t, i?.x, i?.y, e.useHTML).add(s.labelGroup) : void 0;
        if (n) {
          let t = e.style.whiteSpace || "normal";
          r || n.css(sc(e.style, {
            whiteSpace: "nowrap"
          })), n.textPxLength = n.getBBox().width, r || n.css({
            whiteSpace: t
          });
        }
        return n;
      }
      destroy() {
        sn(this, this.axis);
      }
      getPosition(t, e, i, s) {
        let o = this.axis,
          r = o.chart,
          n = s && r.oldChartHeight || r.chartHeight,
          a = {
            x: t ? so(o.translate(e + i, void 0, void 0, s) + o.transB) : o.left + o.offset + (o.opposite ? (s && r.oldChartWidth || r.chartWidth) - o.right - o.left : 0),
            y: t ? n - o.bottom + o.offset - (o.opposite ? o.height : 0) : so(n - o.translate(e + i, void 0, void 0, s) - o.transB)
          };
        return a.y = ss(a.y, -1e9, 1e9), sh(this, "afterGetPosition", {
          pos: a
        }), a;
      }
      getLabelPosition(t, e, i, s, o, r, n, a) {
        let h, l;
        let d = this.axis,
          c = d.transA,
          p = d.isLinked && d.linkedParent ? d.linkedParent.reversed : d.reversed,
          u = d.staggerLines,
          g = d.tickRotCorr || {
            x: 0,
            y: 0
          },
          f = s || d.reserveSpaceDefault ? 0 : -d.labelOffset * ("center" === d.labelAlign ? .5 : 1),
          m = o.distance,
          x = {};
        return h = 0 === d.side ? i.rotation ? -m : -i.getBBox().height : 2 === d.side ? g.y + m : Math.cos(i.rotation * si) * (g.y - i.getBBox(!1, 0).height / 2), sr(o.y) && (h = 0 === d.side && d.horiz ? o.y + h : o.y), t = t + su(o.x, [0, 1, 0, -1][d.side] * m) + f + g.x - (r && s ? r * c * (p ? -1 : 1) : 0), e = e + h - (r && !s ? r * c * (p ? 1 : -1) : 0), u && (l = n / (a || 1) % u, d.opposite && (l = u - l - 1), e += l * (d.labelOffset / u)), x.x = t, x.y = Math.round(e), sh(this, "afterGetLabelPosition", {
          pos: x,
          tickmarkOffset: r,
          index: n
        }), x;
      }
      getLabelSize() {
        return this.label ? this.label.getBBox()[this.axis.horiz ? "height" : "width"] : 0;
      }
      getMarkPath(t, e, i, s, o = !1, r) {
        return r.crispLine([["M", t, e], ["L", t + (o ? 0 : -i), e + (o ? i : 0)]], s);
      }
      handleOverflow(t) {
        let e = this.axis,
          i = e.options.labels,
          s = t.x,
          o = e.chart.chartWidth,
          r = e.chart.spacing,
          n = su(e.labelLeft, Math.min(e.pos, r[3])),
          a = su(e.labelRight, Math.max(e.isRadial ? 0 : e.pos + e.len, o - r[1])),
          h = this.label,
          l = this.rotation,
          d = sl(e.labelAlign || h.attr("align")),
          c = h.getBBox().width,
          p = e.getSlotWidth(this),
          u = p,
          g = 1,
          f,
          m,
          x;
        l || "justify" !== i.overflow ? l < 0 && s - d * c < n ? x = Math.round(s / Math.cos(l * si) - n) : l > 0 && s + d * c > a && (x = Math.round((o - s) / Math.cos(l * si))) : (f = s - d * c, m = s + (1 - d) * c, f < n ? u = t.x + u * (1 - d) - n : m > a && (u = a - t.x + u * d, g = -1), (u = Math.min(p, u)) < p && "center" === e.labelAlign && (t.x += g * (p - u - d * (p - Math.min(c, u)))), (c > u || e.autoRotation && (h.styles || {}).width) && (x = u)), x && h && (this.shortenLabel ? this.shortenLabel() : h.css(sa({}, {
          width: Math.floor(x) + "px",
          lineClamp: e.isRadial ? 0 : 1
        })));
      }
      moveLabel(t, e) {
        let i = this,
          s = i.label,
          o = i.axis,
          r = !1,
          n;
        s && s.textStr === t ? (i.movedLabel = s, r = !0, delete i.label) : sp(o.ticks, function (e) {
          r || e.isNew || e === i || !e.label || e.label.textStr !== t || (i.movedLabel = e.label, r = !0, e.labelPos = i.movedLabel.xy, delete e.label);
        }), !r && (i.labelPos || s) && (n = i.labelPos || s.xy, i.movedLabel = i.createLabel(t, e, n), i.movedLabel && i.movedLabel.attr({
          opacity: 0
        }));
      }
      render(t, e, i) {
        let s = this.axis,
          o = s.horiz,
          r = this.pos,
          n = su(this.tickmarkOffset, s.tickmarkOffset),
          a = this.getPosition(o, r, n, e),
          h = a.x,
          l = a.y,
          d = s.pos,
          c = d + s.len,
          p = o ? h : l;
        !s.chart.polar && this.isNew && (so(p) < d || p > c) && (i = 0);
        let u = su(i, this.label && this.label.newOpacity, 1);
        i = su(i, 1), this.isActive = !0, this.renderGridLine(e, i), this.renderMark(a, i), this.renderLabel(a, e, u, t), this.isNew = !1, sh(this, "afterRender");
      }
      renderGridLine(t, e) {
        let i = this.axis,
          s = i.options,
          o = {},
          r = this.pos,
          n = this.type,
          a = su(this.tickmarkOffset, i.tickmarkOffset),
          h = i.chart.renderer,
          l = this.gridLine,
          d,
          c = s.gridLineWidth,
          p = s.gridLineColor,
          u = s.gridLineDashStyle;
        "minor" === this.type && (c = s.minorGridLineWidth, p = s.minorGridLineColor, u = s.minorGridLineDashStyle), l || (i.chart.styledMode || (o.stroke = p, o["stroke-width"] = c || 0, o.dashstyle = u), n || (o.zIndex = 1), t && (e = 0), this.gridLine = l = h.path().attr(o).addClass("highcharts-" + (n ? n + "-" : "") + "grid-line").add(i.gridGroup)), l && (d = i.getPlotLinePath({
          value: r + a,
          lineWidth: l.strokeWidth(),
          force: "pass",
          old: t,
          acrossPanes: !1
        })) && l[t || this.isNew ? "attr" : "animate"]({
          d: d,
          opacity: e
        });
      }
      renderMark(t, e) {
        let i = this.axis,
          s = i.options,
          o = i.chart.renderer,
          r = this.type,
          n = i.tickSize(r ? r + "Tick" : "tick"),
          a = t.x,
          h = t.y,
          l = su(s["minor" !== r ? "tickWidth" : "minorTickWidth"], !r && i.isXAxis ? 1 : 0),
          d = s["minor" !== r ? "tickColor" : "minorTickColor"],
          c = this.mark,
          p = !c;
        n && (i.opposite && (n[0] = -n[0]), c || (this.mark = c = o.path().addClass("highcharts-" + (r ? r + "-" : "") + "tick").add(i.axisGroup), i.chart.styledMode || c.attr({
          stroke: d,
          "stroke-width": l
        })), c[p ? "attr" : "animate"]({
          d: this.getMarkPath(a, h, n[0], c.strokeWidth(), i.horiz, o),
          opacity: e
        }));
      }
      renderLabel(t, e, i, s) {
        let o = this.axis,
          r = o.horiz,
          n = o.options,
          a = this.label,
          h = n.labels,
          l = h.step,
          d = su(this.tickmarkOffset, o.tickmarkOffset),
          c = t.x,
          p = t.y,
          u = !0;
        a && sd(c) && (a.xy = t = this.getLabelPosition(c, p, a, r, h, d, s, l), (!this.isFirst || this.isLast || n.showFirstLabel) && (!this.isLast || this.isFirst || n.showLastLabel) ? !r || h.step || h.rotation || e || 0 === i || this.handleOverflow(t) : u = !1, l && s % l && (u = !1), u && sd(t.y) ? (t.opacity = i, a[this.isNewLabel ? "attr" : "animate"](t).show(!0), this.isNewLabel = !1) : (a.hide(), this.isNewLabel = !0));
      }
      replaceMovedLabel() {
        let t = this.label,
          e = this.axis;
        t && !this.isNew && (t.animate({
          opacity: 0
        }, void 0, t.destroy), delete this.label), e.isDirty = !0, this.label = this.movedLabel, delete this.movedLabel;
      }
    },
    {
      animObject: sf
    } = tU,
    {
      xAxis: sm,
      yAxis: sx
    } = i9,
    {
      defaultOptions: sy
    } = tS,
    {
      registerEventOptions: sb
    } = se,
    {
      deg2rad: sv
    } = O,
    {
      arrayMax: sM,
      arrayMin: sk,
      clamp: sw,
      correctFloat: sS,
      defined: sA,
      destroyObjectProperties: sT,
      erase: sP,
      error: sC,
      extend: sO,
      fireEvent: sE,
      getClosestDistance: sL,
      insertItem: sB,
      isArray: sI,
      isNumber: sD,
      isString: sR,
      merge: sN,
      normalizeTickInterval: sz,
      objectEach: sW,
      pick: sG,
      relativeLength: sH,
      removeEvent: sF,
      splat: sX,
      syncTimeout: sY
    } = tt,
    sj = (t, e) => sz(e, void 0, void 0, sG(t.options.allowDecimals, e < .5 || void 0 !== t.tickAmount), !!t.tickAmount);
  sO(sy, {
    xAxis: sm,
    yAxis: sN(sm, sx)
  });
  class sU {
    constructor(t, e, i) {
      this.init(t, e, i);
    }
    init(t, e, i = this.coll) {
      let s = "xAxis" === i,
        o = this.isZAxis || (t.inverted ? !s : s);
      this.chart = t, this.horiz = o, this.isXAxis = s, this.coll = i, sE(this, "init", {
        userOptions: e
      }), this.opposite = sG(e.opposite, this.opposite), this.side = sG(e.side, this.side, o ? this.opposite ? 0 : 2 : this.opposite ? 1 : 3), this.setOptions(e);
      let r = this.options,
        n = r.labels;
      this.type ?? (this.type = r.type || "linear"), this.uniqueNames ?? (this.uniqueNames = r.uniqueNames ?? !0), sE(this, "afterSetType"), this.userOptions = e, this.minPixelPadding = 0, this.reversed = sG(r.reversed, this.reversed), this.visible = r.visible, this.zoomEnabled = r.zoomEnabled, this.hasNames = "category" === this.type || !0 === r.categories, this.categories = sI(r.categories) && r.categories || (this.hasNames ? [] : void 0), this.names || (this.names = [], this.names.keys = {}), this.plotLinesAndBandsGroups = {}, this.positiveValuesOnly = !!this.logarithmic, this.isLinked = sA(r.linkedTo), this.ticks = {}, this.labelEdge = [], this.minorTicks = {}, this.plotLinesAndBands = [], this.alternateBands = {}, this.len ?? (this.len = 0), this.minRange = this.userMinRange = r.minRange || r.maxZoom, this.range = r.range, this.offset = r.offset || 0, this.max = void 0, this.min = void 0;
      let a = sG(r.crosshair, sX(t.options.tooltip.crosshairs)[s ? 0 : 1]);
      this.crosshair = !0 === a ? {} : a, -1 === t.axes.indexOf(this) && (s ? t.axes.splice(t.xAxis.length, 0, this) : t.axes.push(this), sB(this, t[this.coll])), t.orderItems(this.coll), this.series = this.series || [], t.inverted && !this.isZAxis && s && !sA(this.reversed) && (this.reversed = !0), this.labelRotation = sD(n.rotation) ? n.rotation : void 0, sb(this, r), sE(this, "afterInit");
    }
    setOptions(t) {
      let e = this.horiz ? {
        labels: {
          autoRotation: [-45],
          padding: 3
        },
        margin: 15
      } : {
        labels: {
          padding: 1
        },
        title: {
          rotation: 90 * this.side
        }
      };
      this.options = sN(e, sy[this.coll], t), sE(this, "afterSetOptions", {
        userOptions: t
      });
    }
    defaultLabelFormatter() {
      let t = this.axis,
        {
          numberFormatter: e
        } = this.chart,
        i = sD(this.value) ? this.value : NaN,
        s = t.chart.time,
        o = t.categories,
        r = this.dateTimeLabelFormat,
        n = sy.lang,
        a = n.numericSymbols,
        h = n.numericSymbolMagnitude || 1e3,
        l = t.logarithmic ? Math.abs(i) : t.tickInterval,
        d = a && a.length,
        c,
        p;
      if (o) p = `${this.value}`;else if (r) p = s.dateFormat(r, i, !0);else if (d && a && l >= 1e3) for (; d-- && void 0 === p;) l >= (c = Math.pow(h, d + 1)) && 10 * i % c == 0 && null !== a[d] && 0 !== i && (p = e(i / c, -1) + a[d]);
      return void 0 === p && (p = Math.abs(i) >= 1e4 ? e(i, -1) : e(i, -1, void 0, "")), p;
    }
    getSeriesExtremes() {
      let t;
      let e = this;
      sE(this, "getSeriesExtremes", null, function () {
        e.hasVisibleSeries = !1, e.dataMin = e.dataMax = e.threshold = void 0, e.softThreshold = !e.isXAxis, e.series.forEach(i => {
          if (i.reserveSpace()) {
            let s = i.options,
              o,
              r = s.threshold,
              n,
              a;
            if (e.hasVisibleSeries = !0, e.positiveValuesOnly && 0 >= (r || 0) && (r = void 0), e.isXAxis) (o = i.getColumn("x")).length && (o = e.logarithmic ? o.filter(t => t > 0) : o, n = (t = i.getXExtremes(o)).min, a = t.max, sD(n) || n instanceof Date || (o = o.filter(sD), n = (t = i.getXExtremes(o)).min, a = t.max), o.length && (e.dataMin = Math.min(sG(e.dataMin, n), n), e.dataMax = Math.max(sG(e.dataMax, a), a)));else {
              let t = i.applyExtremes();
              sD(t.dataMin) && (n = t.dataMin, e.dataMin = Math.min(sG(e.dataMin, n), n)), sD(t.dataMax) && (a = t.dataMax, e.dataMax = Math.max(sG(e.dataMax, a), a)), sA(r) && (e.threshold = r), (!s.softThreshold || e.positiveValuesOnly) && (e.softThreshold = !1);
            }
          }
        });
      }), sE(this, "afterGetSeriesExtremes");
    }
    translate(t, e, i, s, o, r) {
      let n = this.linkedParent || this,
        a = s && n.old ? n.old.min : n.min;
      if (!sD(a)) return NaN;
      let h = n.minPixelPadding,
        l = (n.isOrdinal || n.brokenAxis?.hasBreaks || n.logarithmic && o) && n.lin2val,
        d = 1,
        c = 0,
        p = s && n.old ? n.old.transA : n.transA,
        u = 0;
      return p || (p = n.transA), i && (d *= -1, c = n.len), n.reversed && (d *= -1, c -= d * (n.sector || n.len)), e ? (u = (t = t * d + c - h) / p + a, l && (u = n.lin2val(u))) : (l && (t = n.val2lin(t)), u = d * (t - a) * p + c + d * h + (sD(r) ? p * r : 0), n.isRadial || (u = sS(u))), u;
    }
    toPixels(t, e) {
      return this.translate(this.chart?.time.parse(t) ?? NaN, !1, !this.horiz, void 0, !0) + (e ? 0 : this.pos);
    }
    toValue(t, e) {
      return this.translate(t - (e ? 0 : this.pos), !0, !this.horiz, void 0, !0);
    }
    getPlotLinePath(t) {
      let e = this,
        i = e.chart,
        s = e.left,
        o = e.top,
        r = t.old,
        n = t.value,
        a = t.lineWidth,
        h = r && i.oldChartHeight || i.chartHeight,
        l = r && i.oldChartWidth || i.chartWidth,
        d = e.transB,
        c = t.translatedValue,
        p = t.force,
        u,
        g,
        f,
        m,
        x;
      function y(t, e, i) {
        return "pass" !== p && (t < e || t > i) && (p ? t = sw(t, e, i) : x = !0), t;
      }
      let b = {
        value: n,
        lineWidth: a,
        old: r,
        force: p,
        acrossPanes: t.acrossPanes,
        translatedValue: c
      };
      return sE(this, "getPlotLinePath", b, function (t) {
        u = f = (c = sw(c = sG(c, e.translate(n, void 0, void 0, r)), -1e9, 1e9)) + d, g = m = h - c - d, sD(c) ? e.horiz ? (g = o, m = h - e.bottom + (e.options.isInternal ? 0 : i.scrollablePixelsY || 0), u = f = y(u, s, s + e.width)) : (u = s, f = l - e.right + (i.scrollablePixelsX || 0), g = m = y(g, o, o + e.height)) : (x = !0, p = !1), t.path = x && !p ? void 0 : i.renderer.crispLine([["M", u, g], ["L", f, m]], a || 1);
      }), b.path;
    }
    getLinearTickPositions(t, e, i) {
      let s, o, r;
      let n = sS(Math.floor(e / t) * t),
        a = sS(Math.ceil(i / t) * t),
        h = [];
      if (sS(n + t) === n && (r = 20), this.single) return [e];
      for (s = n; s <= a && (h.push(s), (s = sS(s + t, r)) !== o);) o = s;
      return h;
    }
    getMinorTickInterval() {
      let {
        minorTicks: t,
        minorTickInterval: e
      } = this.options;
      return !0 === t ? sG(e, "auto") : !1 !== t ? e : void 0;
    }
    getMinorTickPositions() {
      let t = this.options,
        e = this.tickPositions,
        i = this.minorTickInterval,
        s = this.pointRangePadding || 0,
        o = (this.min || 0) - s,
        r = (this.max || 0) + s,
        n = this.brokenAxis?.hasBreaks ? this.brokenAxis.unitLength : r - o,
        a = [],
        h;
      if (n && n / i < this.len / 3) {
        let s = this.logarithmic;
        if (s) this.paddedTicks.forEach(function (t, e, o) {
          e && a.push.apply(a, s.getLogTickPositions(i, o[e - 1], o[e], !0));
        });else if (this.dateTime && "auto" === this.getMinorTickInterval()) a = a.concat(this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(i), o, r, t.startOfWeek));else for (h = o + (e[0] - o) % i; h <= r && h !== a[0]; h += i) a.push(h);
      }
      return 0 !== a.length && this.trimTicks(a), a;
    }
    adjustForMinRange() {
      let t = this.options,
        e = this.logarithmic,
        i = this.chart.time,
        {
          max: s,
          min: o,
          minRange: r
        } = this,
        n,
        a,
        h,
        l;
      this.isXAxis && void 0 === r && !e && (r = sA(t.min) || sA(t.max) || sA(t.floor) || sA(t.ceiling) ? null : Math.min(5 * (sL(this.series.map(t => {
        let e = t.getColumn("x");
        return t.xIncrement ? e.slice(0, 2) : e;
      })) || 0), this.dataMax - this.dataMin)), sD(s) && sD(o) && sD(r) && s - o < r && (a = this.dataMax - this.dataMin >= r, n = (r - s + o) / 2, h = [o - n, i.parse(t.min) ?? o - n], a && (h[2] = e ? e.log2lin(this.dataMin) : this.dataMin), l = [(o = sM(h)) + r, i.parse(t.max) ?? o + r], a && (l[2] = e ? e.log2lin(this.dataMax) : this.dataMax), (s = sk(l)) - o < r && (h[0] = s - r, h[1] = i.parse(t.min) ?? s - r, o = sM(h))), this.minRange = r, this.min = o, this.max = s;
    }
    getClosest() {
      let t, e;
      if (this.categories) e = 1;else {
        let i = [];
        this.series.forEach(function (t) {
          let s = t.closestPointRange,
            o = t.getColumn("x");
          1 === o.length ? i.push(o[0]) : t.sorted && sA(s) && t.reserveSpace() && (e = sA(e) ? Math.min(e, s) : s);
        }), i.length && (i.sort((t, e) => t - e), t = sL([i]));
      }
      return t && e ? Math.min(t, e) : t || e;
    }
    nameToX(t) {
      let e = sI(this.options.categories),
        i = e ? this.categories : this.names,
        s = t.options.x,
        o;
      return t.series.requireSorting = !1, sA(s) || (s = this.uniqueNames && i ? e ? i.indexOf(t.name) : sG(i.keys[t.name], -1) : t.series.autoIncrement()), -1 === s ? !e && i && (o = i.length) : sD(s) && (o = s), void 0 !== o ? (this.names[o] = t.name, this.names.keys[t.name] = o) : t.x && (o = t.x), o;
    }
    updateNames() {
      let t = this,
        e = this.names;
      e.length > 0 && (Object.keys(e.keys).forEach(function (t) {
        delete e.keys[t];
      }), e.length = 0, this.minRange = this.userMinRange, (this.series || []).forEach(e => {
        e.xIncrement = null, (!e.points || e.isDirtyData) && (t.max = Math.max(t.max || 0, e.dataTable.rowCount - 1), e.processData(), e.generatePoints());
        let i = e.getColumn("x").slice();
        e.data.forEach((e, s) => {
          let o = i[s];
          e?.options && void 0 !== e.name && void 0 !== (o = t.nameToX(e)) && o !== e.x && (i[s] = e.x = o);
        }), e.dataTable.setColumn("x", i);
      }));
    }
    setAxisTranslation() {
      let t = this,
        e = t.max - t.min,
        i = t.linkedParent,
        s = !!t.categories,
        o = t.isXAxis,
        r = t.axisPointRange || 0,
        n,
        a = 0,
        h = 0,
        l,
        d = t.transA;
      (o || s || r) && (n = t.getClosest(), i ? (a = i.minPointOffset, h = i.pointRangePadding) : t.series.forEach(function (e) {
        let i = s ? 1 : o ? sG(e.options.pointRange, n, 0) : t.axisPointRange || 0,
          l = e.options.pointPlacement;
        if (r = Math.max(r, i), !t.single || s) {
          let t = e.is("xrange") ? !o : o;
          a = Math.max(a, t && sR(l) ? 0 : i / 2), h = Math.max(h, t && "on" === l ? 0 : i);
        }
      }), l = t.ordinal && t.ordinal.slope && n ? t.ordinal.slope / n : 1, t.minPointOffset = a *= l, t.pointRangePadding = h *= l, t.pointRange = Math.min(r, t.single && s ? 1 : e), o && n && (t.closestPointRange = n)), t.translationSlope = t.transA = d = t.staticScale || t.len / (e + h || 1), t.transB = t.horiz ? t.left : t.bottom, t.minPixelPadding = d * a, sE(this, "afterSetAxisTranslation");
    }
    minFromRange() {
      let {
        max: t,
        min: e
      } = this;
      return sD(t) && sD(e) && t - e || void 0;
    }
    setTickInterval(t) {
      let {
          categories: e,
          chart: i,
          dataMax: s,
          dataMin: o,
          dateTime: r,
          isXAxis: n,
          logarithmic: a,
          options: h,
          softThreshold: l
        } = this,
        d = i.time,
        c = sD(this.threshold) ? this.threshold : void 0,
        p = this.minRange || 0,
        {
          ceiling: u,
          floor: g,
          linkedTo: f,
          softMax: m,
          softMin: x
        } = h,
        y = sD(f) && i[this.coll]?.[f],
        b = h.tickPixelInterval,
        v = h.maxPadding,
        M = h.minPadding,
        k = 0,
        w,
        S = sD(h.tickInterval) && h.tickInterval >= 0 ? h.tickInterval : void 0,
        A,
        T,
        P,
        C;
      if (r || e || y || this.getTickAmount(), P = sG(this.userMin, d.parse(h.min)), C = sG(this.userMax, d.parse(h.max)), y ? (this.linkedParent = y, w = y.getExtremes(), this.min = sG(w.min, w.dataMin), this.max = sG(w.max, w.dataMax), this.type !== y.type && sC(11, !0, i)) : (l && sA(c) && sD(s) && sD(o) && (o >= c ? (A = c, M = 0) : s <= c && (T = c, v = 0)), this.min = sG(P, A, o), this.max = sG(C, T, s)), sD(this.max) && sD(this.min) && (a && (this.positiveValuesOnly && !t && 0 >= Math.min(this.min, sG(o, this.min)) && sC(10, !0, i), this.min = sS(a.log2lin(this.min), 16), this.max = sS(a.log2lin(this.max), 16)), this.range && sD(o) && (this.userMin = this.min = P = Math.max(o, this.minFromRange() || 0), this.userMax = C = this.max, this.range = void 0)), sE(this, "foundExtremes"), this.adjustForMinRange(), sD(this.min) && sD(this.max)) {
        if (!sD(this.userMin) && sD(x) && x < this.min && (this.min = P = x), !sD(this.userMax) && sD(m) && m > this.max && (this.max = C = m), e || this.axisPointRange || this.stacking?.usePercentage || y || !(k = this.max - this.min) || (!sA(P) && M && (this.min -= k * M), sA(C) || !v || (this.max += k * v)), !sD(this.userMin) && sD(g) && (this.min = Math.max(this.min, g)), !sD(this.userMax) && sD(u) && (this.max = Math.min(this.max, u)), l && sD(o) && sD(s)) {
          let t = c || 0;
          !sA(P) && this.min < t && o >= t ? this.min = h.minRange ? Math.min(t, this.max - p) : t : !sA(C) && this.max > t && s <= t && (this.max = h.minRange ? Math.max(t, this.min + p) : t);
        }
        !i.polar && this.min > this.max && (sA(h.min) ? this.max = this.min : sA(h.max) && (this.min = this.max)), k = this.max - this.min;
      }
      if (this.min !== this.max && sD(this.min) && sD(this.max) ? y && !S && b === y.options.tickPixelInterval ? this.tickInterval = S = y.tickInterval : this.tickInterval = sG(S, this.tickAmount ? k / Math.max(this.tickAmount - 1, 1) : void 0, e ? 1 : k * b / Math.max(this.len, b)) : this.tickInterval = 1, n && !t) {
        let t = this.min !== this.old?.min || this.max !== this.old?.max;
        this.series.forEach(function (e) {
          e.forceCrop = e.forceCropping?.(), e.processData(t);
        }), sE(this, "postProcessData", {
          hasExtremesChanged: t
        });
      }
      this.setAxisTranslation(), sE(this, "initialAxisTranslation"), this.pointRange && !S && (this.tickInterval = Math.max(this.pointRange, this.tickInterval));
      let O = sG(h.minTickInterval, r && !this.series.some(t => !t.sorted) ? this.closestPointRange : 0);
      !S && this.tickInterval < O && (this.tickInterval = O), r || a || S || (this.tickInterval = sj(this, this.tickInterval)), this.tickAmount || (this.tickInterval = this.unsquish()), this.setTickPositions();
    }
    setTickPositions() {
      let t = this.options,
        e = t.tickPositions,
        i = t.tickPositioner,
        s = this.getMinorTickInterval(),
        o = !this.isPanning,
        r = o && t.startOnTick,
        n = o && t.endOnTick,
        a = [],
        h;
      if (this.tickmarkOffset = this.categories && "between" === t.tickmarkPlacement && 1 === this.tickInterval ? .5 : 0, this.single = this.min === this.max && sA(this.min) && !this.tickAmount && (this.min % 1 == 0 || !1 !== t.allowDecimals), e) a = e.slice();else if (sD(this.min) && sD(this.max)) {
        if (!this.ordinal?.positions && (this.max - this.min) / this.tickInterval > Math.max(2 * this.len, 200)) a = [this.min, this.max], sC(19, !1, this.chart);else if (this.dateTime) a = this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(this.tickInterval, t.units), this.min, this.max, t.startOfWeek, this.ordinal?.positions, this.closestPointRange, !0);else if (this.logarithmic) a = this.logarithmic.getLogTickPositions(this.tickInterval, this.min, this.max);else {
          let t = this.tickInterval,
            e = t;
          for (; e <= 2 * t;) if (a = this.getLinearTickPositions(this.tickInterval, this.min, this.max), this.tickAmount && a.length > this.tickAmount) this.tickInterval = sj(this, e *= 1.1);else break;
        }
        a.length > this.len && (a = [a[0], a[a.length - 1]])[0] === a[1] && (a.length = 1), i && (this.tickPositions = a, (h = i.apply(this, [this.min, this.max])) && (a = h));
      }
      this.tickPositions = a, this.minorTickInterval = "auto" === s && this.tickInterval ? this.tickInterval / t.minorTicksPerMajor : s, this.paddedTicks = a.slice(0), this.trimTicks(a, r, n), !this.isLinked && sD(this.min) && sD(this.max) && (this.single && a.length < 2 && !this.categories && !this.series.some(t => t.is("heatmap") && "between" === t.options.pointPlacement) && (this.min -= .5, this.max += .5), e || h || this.adjustTickAmount()), sE(this, "afterSetTickPositions");
    }
    trimTicks(t, e, i) {
      let s = t[0],
        o = t[t.length - 1],
        r = !this.isOrdinal && this.minPointOffset || 0;
      if (sE(this, "trimTicks"), !this.isLinked) {
        if (e && s !== -1 / 0) this.min = s;else for (; this.min - r > t[0];) t.shift();
        if (i) this.max = o;else for (; this.max + r < t[t.length - 1];) t.pop();
        0 === t.length && sA(s) && !this.options.tickPositions && t.push((o + s) / 2);
      }
    }
    alignToOthers() {
      let t;
      let e = this,
        i = e.chart,
        s = [this],
        o = e.options,
        r = i.options.chart,
        n = "yAxis" === this.coll && r.alignThresholds,
        a = [];
      if (e.thresholdAlignment = void 0, (!1 !== r.alignTicks && o.alignTicks || n) && !1 !== o.startOnTick && !1 !== o.endOnTick && !e.logarithmic) {
        let o = t => {
            let {
              horiz: e,
              options: i
            } = t;
            return [e ? i.left : i.top, i.width, i.height, i.pane].join(",");
          },
          r = o(this);
        i[this.coll].forEach(function (i) {
          let {
            series: n
          } = i;
          n.length && n.some(t => t.visible) && i !== e && o(i) === r && (t = !0, s.push(i));
        });
      }
      if (t && n) {
        s.forEach(t => {
          let i = t.getThresholdAlignment(e);
          sD(i) && a.push(i);
        });
        let t = a.length > 1 ? a.reduce((t, e) => t += e, 0) / a.length : void 0;
        s.forEach(e => {
          e.thresholdAlignment = t;
        });
      }
      return t;
    }
    getThresholdAlignment(t) {
      if ((!sD(this.dataMin) || this !== t && this.series.some(t => t.isDirty || t.isDirtyData)) && this.getSeriesExtremes(), sD(this.threshold)) {
        let t = sw((this.threshold - (this.dataMin || 0)) / ((this.dataMax || 0) - (this.dataMin || 0)), 0, 1);
        return this.options.reversed && (t = 1 - t), t;
      }
    }
    getTickAmount() {
      let t = this.options,
        e = t.tickPixelInterval,
        i = t.tickAmount;
      sA(t.tickInterval) || i || !(this.len < e) || this.isRadial || this.logarithmic || !t.startOnTick || !t.endOnTick || (i = 2), !i && this.alignToOthers() && (i = Math.ceil(this.len / e) + 1), i < 4 && (this.finalTickAmt = i, i = 5), this.tickAmount = i;
    }
    adjustTickAmount() {
      let t = this,
        {
          finalTickAmt: e,
          max: i,
          min: s,
          options: o,
          tickPositions: r,
          tickAmount: n,
          thresholdAlignment: a
        } = t,
        h = r?.length,
        l = sG(t.threshold, t.softThreshold ? 0 : null),
        d,
        c,
        p = t.tickInterval,
        u,
        g = () => r.push(sS(r[r.length - 1] + p)),
        f = () => r.unshift(sS(r[0] - p));
      if (sD(a) && (u = a < .5 ? Math.ceil(a * (n - 1)) : Math.floor(a * (n - 1)), o.reversed && (u = n - 1 - u)), t.hasData() && sD(s) && sD(i)) {
        let a = () => {
          t.transA *= (h - 1) / (n - 1), t.min = o.startOnTick ? r[0] : Math.min(s, r[0]), t.max = o.endOnTick ? r[r.length - 1] : Math.max(i, r[r.length - 1]);
        };
        if (sD(u) && sD(t.threshold)) {
          for (; r[u] !== l || r.length !== n || r[0] > s || r[r.length - 1] < i;) {
            for (r.length = 0, r.push(t.threshold); r.length < n;) void 0 === r[u] || r[u] > t.threshold ? f() : g();
            if (p > 8 * t.tickInterval) break;
            p *= 2;
          }
          a();
        } else if (h < n) {
          for (; r.length < n;) r.length % 2 || s === l ? g() : f();
          a();
        }
        if (sA(e)) {
          for (c = d = r.length; c--;) (3 === e && c % 2 == 1 || e <= 2 && c > 0 && c < d - 1) && r.splice(c, 1);
          t.finalTickAmt = void 0;
        }
      }
    }
    setScale() {
      let {
          coll: t,
          stacking: e
        } = this,
        i = !1,
        s = !1;
      this.series.forEach(t => {
        i = i || t.isDirtyData || t.isDirty, s = s || t.xAxis && t.xAxis.isDirty || !1;
      }), this.setAxisSize();
      let o = this.len !== (this.old && this.old.len);
      o || i || s || this.isLinked || this.forceRedraw || this.userMin !== (this.old && this.old.userMin) || this.userMax !== (this.old && this.old.userMax) || this.alignToOthers() ? (e && "yAxis" === t && e.buildStacks(), this.forceRedraw = !1, this.userMinRange || (this.minRange = void 0), this.getSeriesExtremes(), this.setTickInterval(), e && "xAxis" === t && e.buildStacks(), this.isDirty || (this.isDirty = o || this.min !== this.old?.min || this.max !== this.old?.max)) : e && e.cleanStacks(), i && delete this.allExtremes, sE(this, "afterSetScale");
    }
    setExtremes(t, e, i = !0, s, o) {
      let r = this.chart;
      this.series.forEach(t => {
        delete t.kdTree;
      }), t = r.time.parse(t), e = r.time.parse(e), sE(this, "setExtremes", o = sO(o, {
        min: t,
        max: e
      }), t => {
        this.userMin = t.min, this.userMax = t.max, this.eventArgs = t, i && r.redraw(s);
      });
    }
    setAxisSize() {
      let t = this.chart,
        e = this.options,
        i = e.offsets || [0, 0, 0, 0],
        s = this.horiz,
        o = this.width = Math.round(sH(sG(e.width, t.plotWidth - i[3] + i[1]), t.plotWidth)),
        r = this.height = Math.round(sH(sG(e.height, t.plotHeight - i[0] + i[2]), t.plotHeight)),
        n = this.top = Math.round(sH(sG(e.top, t.plotTop + i[0]), t.plotHeight, t.plotTop)),
        a = this.left = Math.round(sH(sG(e.left, t.plotLeft + i[3]), t.plotWidth, t.plotLeft));
      this.bottom = t.chartHeight - r - n, this.right = t.chartWidth - o - a, this.len = Math.max(s ? o : r, 0), this.pos = s ? a : n;
    }
    getExtremes() {
      let t = this.logarithmic;
      return {
        min: t ? sS(t.lin2log(this.min)) : this.min,
        max: t ? sS(t.lin2log(this.max)) : this.max,
        dataMin: this.dataMin,
        dataMax: this.dataMax,
        userMin: this.userMin,
        userMax: this.userMax
      };
    }
    getThreshold(t) {
      let e = this.logarithmic,
        i = e ? e.lin2log(this.min) : this.min,
        s = e ? e.lin2log(this.max) : this.max;
      return null === t || t === -1 / 0 ? t = i : t === 1 / 0 ? t = s : i > t ? t = i : s < t && (t = s), this.translate(t, 0, 1, 0, 1);
    }
    autoLabelAlign(t) {
      let e = (sG(t, 0) - 90 * this.side + 720) % 360,
        i = {
          align: "center"
        };
      return sE(this, "autoLabelAlign", i, function (t) {
        e > 15 && e < 165 ? t.align = "right" : e > 195 && e < 345 && (t.align = "left");
      }), i.align;
    }
    tickSize(t) {
      let e = this.options,
        i = sG(e["tick" === t ? "tickWidth" : "minorTickWidth"], "tick" === t && this.isXAxis && !this.categories ? 1 : 0),
        s = e["tick" === t ? "tickLength" : "minorTickLength"],
        o;
      i && s && ("inside" === e[t + "Position"] && (s = -s), o = [s, i]);
      let r = {
        tickSize: o
      };
      return sE(this, "afterTickSize", r), r.tickSize;
    }
    labelMetrics() {
      let t = this.chart.renderer,
        e = this.ticks,
        i = e[Object.keys(e)[0]] || {};
      return this.chart.renderer.fontMetrics(i.label || i.movedLabel || t.box);
    }
    unsquish() {
      let t = this.options.labels,
        e = t.padding || 0,
        i = this.horiz,
        s = this.tickInterval,
        o = this.len / (((this.categories ? 1 : 0) + this.max - this.min) / s),
        r = t.rotation,
        n = sS(.8 * this.labelMetrics().h),
        a = Math.max(this.max - this.min, 0),
        h = function (t) {
          let i = (t + 2 * e) / (o || 1);
          return (i = i > 1 ? Math.ceil(i) : 1) * s > a && t !== 1 / 0 && o !== 1 / 0 && a && (i = Math.ceil(a / s)), sS(i * s);
        },
        l = s,
        d,
        c = Number.MAX_VALUE,
        p;
      if (i) {
        if (!t.staggerLines && (sD(r) ? p = [r] : o < t.autoRotationLimit && (p = t.autoRotation)), p) {
          let t, e;
          for (let i of p) (i === r || i && i >= -90 && i <= 90) && (e = (t = h(Math.abs(n / Math.sin(sv * i)))) + Math.abs(i / 360)) < c && (c = e, d = i, l = t);
        }
      } else l = h(.75 * n);
      return this.autoRotation = p, this.labelRotation = sG(d, sD(r) ? r : 0), t.step ? s : l;
    }
    getSlotWidth(t) {
      let e = this.chart,
        i = this.horiz,
        s = this.options.labels,
        o = Math.max(this.tickPositions.length - (this.categories ? 0 : 1), 1),
        r = e.margin[3];
      if (t && sD(t.slotWidth)) return t.slotWidth;
      if (i && s.step < 2 && !this.isRadial) return s.rotation ? 0 : (this.staggerLines || 1) * this.len / o;
      if (!i) {
        let t = s.style.width;
        if (void 0 !== t) return parseInt(String(t), 10);
        if (r) return r - e.spacing[3];
      }
      return .33 * e.chartWidth;
    }
    renderUnsquish() {
      let t = this.chart,
        e = t.renderer,
        i = this.tickPositions,
        s = this.ticks,
        o = this.options.labels,
        r = o.style,
        n = this.horiz,
        a = this.getSlotWidth(),
        h = Math.max(1, Math.round(a - (n ? 2 * (o.padding || 0) : o.distance || 0))),
        l = {},
        d = this.labelMetrics(),
        c = r.lineClamp,
        p,
        u = c ?? (Math.floor(this.len / (i.length * d.h)) || 1),
        g = 0;
      sR(o.rotation) || (l.rotation = o.rotation || 0), i.forEach(function (t) {
        let e = s[t];
        e.movedLabel && e.replaceMovedLabel();
        let i = e.label?.textPxLength || 0;
        i > g && (g = i);
      }), this.maxLabelLength = g, this.autoRotation ? g > h && g > d.h ? l.rotation = this.labelRotation : this.labelRotation = 0 : a && (p = h), l.rotation && (p = g > .5 * t.chartHeight ? .33 * t.chartHeight : g, c || (u = 1)), this.labelAlign = o.align || this.autoLabelAlign(this.labelRotation), this.labelAlign && (l.align = this.labelAlign), i.forEach(function (t) {
        let e = s[t],
          i = e && e.label,
          o = r.width,
          n = {};
        i && (i.attr(l), e.shortenLabel ? e.shortenLabel() : p && !o && "nowrap" !== r.whiteSpace && (p < (i.textPxLength || 0) || "SPAN" === i.element.tagName) ? i.css(sO(n, {
          width: `${p}px`,
          lineClamp: u
        })) : !i.styles.width || n.width || o || i.css({
          width: "auto"
        }), e.rotation = l.rotation);
      }, this), this.tickRotCorr = e.rotCorr(d.b, this.labelRotation || 0, 0 !== this.side);
    }
    hasData() {
      return this.series.some(function (t) {
        return t.hasData();
      }) || this.options.showEmpty && sA(this.min) && sA(this.max);
    }
    addTitle(t) {
      let e;
      let i = this.chart.renderer,
        s = this.horiz,
        o = this.opposite,
        r = this.options.title,
        n = this.chart.styledMode;
      this.axisTitle || ((e = r.textAlign) || (e = (s ? {
        low: "left",
        middle: "center",
        high: "right"
      } : {
        low: o ? "right" : "left",
        middle: "center",
        high: o ? "left" : "right"
      })[r.align]), this.axisTitle = i.text(r.text || "", 0, 0, r.useHTML).attr({
        zIndex: 7,
        rotation: r.rotation || 0,
        align: e
      }).addClass("highcharts-axis-title"), n || this.axisTitle.css(sN(r.style)), this.axisTitle.add(this.axisGroup), this.axisTitle.isNew = !0), n || r.style.width || this.isRadial || this.axisTitle.css({
        width: this.len + "px"
      }), this.axisTitle[t ? "show" : "hide"](t);
    }
    generateTick(t) {
      let e = this.ticks;
      e[t] ? e[t].addLabel() : e[t] = new sg(this, t);
    }
    createGroups() {
      let {
          axisParent: t,
          chart: e,
          coll: i,
          options: s
        } = this,
        o = e.renderer,
        r = (e, r, n) => o.g(e).attr({
          zIndex: n
        }).addClass(`highcharts-${i.toLowerCase()}${r} ` + (this.isRadial ? `highcharts-radial-axis${r} ` : "") + (s.className || "")).add(t);
      this.axisGroup || (this.gridGroup = r("grid", "-grid", s.gridZIndex), this.axisGroup = r("axis", "", s.zIndex), this.labelGroup = r("axis-labels", "-labels", s.labels.zIndex));
    }
    getOffset() {
      let t = this,
        {
          chart: e,
          horiz: i,
          options: s,
          side: o,
          ticks: r,
          tickPositions: n,
          coll: a
        } = t,
        h = e.inverted && !t.isZAxis ? [1, 0, 3, 2][o] : o,
        l = t.hasData(),
        d = s.title,
        c = s.labels,
        p = sD(s.crossing),
        u = e.axisOffset,
        g = e.clipOffset,
        f = [-1, 1, 1, -1][o],
        m,
        x = 0,
        y,
        b = 0,
        v = 0,
        M,
        k;
      if (t.showAxis = m = l || s.showEmpty, t.staggerLines = t.horiz && c.staggerLines || void 0, t.createGroups(), l || t.isLinked ? (n.forEach(function (e) {
        t.generateTick(e);
      }), t.renderUnsquish(), t.reserveSpaceDefault = 0 === o || 2 === o || {
        1: "left",
        3: "right"
      }[o] === t.labelAlign, sG(c.reserveSpace, !p && null, "center" === t.labelAlign || null, t.reserveSpaceDefault) && n.forEach(function (t) {
        v = Math.max(r[t].getLabelSize(), v);
      }), t.staggerLines && (v *= t.staggerLines), t.labelOffset = v * (t.opposite ? -1 : 1)) : sW(r, function (t, e) {
        t.destroy(), delete r[e];
      }), d?.text && !1 !== d.enabled && (t.addTitle(m), m && !p && !1 !== d.reserveSpace && (t.titleOffset = x = t.axisTitle.getBBox()[i ? "height" : "width"], b = sA(y = d.offset) ? 0 : sG(d.margin, i ? 5 : 10))), t.renderLine(), t.offset = f * sG(s.offset, u[o] ? u[o] + (s.margin || 0) : 0), t.tickRotCorr = t.tickRotCorr || {
        x: 0,
        y: 0
      }, k = 0 === o ? -t.labelMetrics().h : 2 === o ? t.tickRotCorr.y : 0, M = Math.abs(v) + b, v && (M -= k, M += f * (i ? sG(c.y, t.tickRotCorr.y + f * c.distance) : sG(c.x, f * c.distance))), t.axisTitleMargin = sG(y, M), t.getMaxLabelDimensions && (t.maxLabelDimensions = t.getMaxLabelDimensions(r, n)), "colorAxis" !== a && g) {
        let e = this.tickSize("tick");
        u[o] = Math.max(u[o], (t.axisTitleMargin || 0) + x + f * t.offset, M, n && n.length && e ? e[0] + f * t.offset : 0);
        let i = !t.axisLine || s.offset ? 0 : t.axisLine.strokeWidth() / 2;
        g[h] = Math.max(g[h], i);
      }
      sE(this, "afterGetOffset");
    }
    getLinePath(t) {
      let e = this.chart,
        i = this.opposite,
        s = this.offset,
        o = this.horiz,
        r = this.left + (i ? this.width : 0) + s,
        n = e.chartHeight - this.bottom - (i ? this.height : 0) + s;
      return i && (t *= -1), e.renderer.crispLine([["M", o ? this.left : r, o ? n : this.top], ["L", o ? e.chartWidth - this.right : r, o ? n : e.chartHeight - this.bottom]], t);
    }
    renderLine() {
      this.axisLine || (this.axisLine = this.chart.renderer.path().addClass("highcharts-axis-line").add(this.axisGroup), this.chart.styledMode || this.axisLine.attr({
        stroke: this.options.lineColor,
        "stroke-width": this.options.lineWidth,
        zIndex: 7
      }));
    }
    getTitlePosition(t) {
      let e = this.horiz,
        i = this.left,
        s = this.top,
        o = this.len,
        r = this.options.title,
        n = e ? i : s,
        a = this.opposite,
        h = this.offset,
        l = r.x,
        d = r.y,
        c = this.chart.renderer.fontMetrics(t),
        p = t ? Math.max(t.getBBox(!1, 0).height - c.h - 1, 0) : 0,
        u = {
          low: n + (e ? 0 : o),
          middle: n + o / 2,
          high: n + (e ? o : 0)
        }[r.align],
        g = (e ? s + this.height : i) + (e ? 1 : -1) * (a ? -1 : 1) * (this.axisTitleMargin || 0) + [-p, p, c.f, -p][this.side],
        f = {
          x: e ? u + l : g + (a ? this.width : 0) + h + l,
          y: e ? g + d - (a ? this.height : 0) + h : u + d
        };
      return sE(this, "afterGetTitlePosition", {
        titlePosition: f
      }), f;
    }
    renderMinorTick(t, e) {
      let i = this.minorTicks;
      i[t] || (i[t] = new sg(this, t, "minor")), e && i[t].isNew && i[t].render(null, !0), i[t].render(null, !1, 1);
    }
    renderTick(t, e, i) {
      let s = this.isLinked,
        o = this.ticks;
      (!s || t >= this.min && t <= this.max || this.grid && this.grid.isColumn) && (o[t] || (o[t] = new sg(this, t)), i && o[t].isNew && o[t].render(e, !0, -1), o[t].render(e));
    }
    render() {
      let t, e;
      let i = this,
        s = i.chart,
        o = i.logarithmic,
        r = s.renderer,
        n = i.options,
        a = i.isLinked,
        h = i.tickPositions,
        l = i.axisTitle,
        d = i.ticks,
        c = i.minorTicks,
        p = i.alternateBands,
        u = n.stackLabels,
        g = n.alternateGridColor,
        f = n.crossing,
        m = i.tickmarkOffset,
        x = i.axisLine,
        y = i.showAxis,
        b = sf(r.globalAnimation);
      if (i.labelEdge.length = 0, i.overlap = !1, [d, c, p].forEach(function (t) {
        sW(t, function (t) {
          t.isActive = !1;
        });
      }), sD(f)) {
        let t = this.isXAxis ? s.yAxis[0] : s.xAxis[0],
          e = [1, -1, -1, 1][this.side];
        if (t) {
          let s = t.toPixels(f, !0);
          i.horiz && (s = t.len - s), i.offset = e * s;
        }
      }
      if (i.hasData() || a) {
        let r = i.chart.hasRendered && i.old && sD(i.old.min);
        i.minorTickInterval && !i.categories && i.getMinorTickPositions().forEach(function (t) {
          i.renderMinorTick(t, r);
        }), h.length && (h.forEach(function (t, e) {
          i.renderTick(t, e, r);
        }), m && (0 === i.min || i.single) && (d[-1] || (d[-1] = new sg(i, -1, null, !0)), d[-1].render(-1))), g && h.forEach(function (r, n) {
          e = void 0 !== h[n + 1] ? h[n + 1] + m : i.max - m, n % 2 == 0 && r < i.max && e <= i.max + (s.polar ? -m : m) && (p[r] || (p[r] = new O.PlotLineOrBand(i, {})), t = r + m, p[r].options = {
            from: o ? o.lin2log(t) : t,
            to: o ? o.lin2log(e) : e,
            color: g,
            className: "highcharts-alternate-grid"
          }, p[r].render(), p[r].isActive = !0);
        }), i._addedPlotLB || (i._addedPlotLB = !0, (n.plotLines || []).concat(n.plotBands || []).forEach(function (t) {
          i.addPlotBandOrLine(t);
        }));
      }
      [d, c, p].forEach(function (t) {
        let e = [],
          i = b.duration;
        sW(t, function (t, i) {
          t.isActive || (t.render(i, !1, 0), t.isActive = !1, e.push(i));
        }), sY(function () {
          let i = e.length;
          for (; i--;) t[e[i]] && !t[e[i]].isActive && (t[e[i]].destroy(), delete t[e[i]]);
        }, t !== p && s.hasRendered && i ? i : 0);
      }), x && (x[x.isPlaced ? "animate" : "attr"]({
        d: this.getLinePath(x.strokeWidth())
      }), x.isPlaced = !0, x[y ? "show" : "hide"](y)), l && y && (l[l.isNew ? "attr" : "animate"](i.getTitlePosition(l)), l.isNew = !1), u && u.enabled && i.stacking && i.stacking.renderStackTotals(), i.old = {
        len: i.len,
        max: i.max,
        min: i.min,
        transA: i.transA,
        userMax: i.userMax,
        userMin: i.userMin
      }, i.isDirty = !1, sE(this, "afterRender");
    }
    redraw() {
      this.visible && (this.render(), this.plotLinesAndBands.forEach(function (t) {
        t.render();
      })), this.series.forEach(function (t) {
        t.isDirty = !0;
      });
    }
    getKeepProps() {
      return this.keepProps || sU.keepProps;
    }
    destroy(t) {
      let e = this,
        i = e.plotLinesAndBands,
        s = this.eventOptions;
      if (sE(this, "destroy", {
        keepEvents: t
      }), t || sF(e), [e.ticks, e.minorTicks, e.alternateBands].forEach(function (t) {
        sT(t);
      }), i) {
        let t = i.length;
        for (; t--;) i[t].destroy();
      }
      for (let t in ["axisLine", "axisTitle", "axisGroup", "gridGroup", "labelGroup", "cross", "scrollbar"].forEach(function (t) {
        e[t] && (e[t] = e[t].destroy());
      }), e.plotLinesAndBandsGroups) e.plotLinesAndBandsGroups[t] = e.plotLinesAndBandsGroups[t].destroy();
      sW(e, function (t, i) {
        -1 === e.getKeepProps().indexOf(i) && delete e[i];
      }), this.eventOptions = s;
    }
    drawCrosshair(t, e) {
      let i = this.crosshair,
        s = sG(i && i.snap, !0),
        o = this.chart,
        r,
        n,
        a,
        h = this.cross,
        l;
      if (sE(this, "drawCrosshair", {
        e: t,
        point: e
      }), t || (t = this.cross && this.cross.e), i && !1 !== (sA(e) || !s)) {
        if (s ? sA(e) && (n = sG("colorAxis" !== this.coll ? e.crosshairPos : null, this.isXAxis ? e.plotX : this.len - e.plotY)) : n = t && (this.horiz ? t.chartX - this.pos : this.len - t.chartY + this.pos), sA(n) && (l = {
          value: e && (this.isXAxis ? e.x : sG(e.stackY, e.y)),
          translatedValue: n
        }, o.polar && sO(l, {
          isCrosshair: !0,
          chartX: t && t.chartX,
          chartY: t && t.chartY,
          point: e
        }), r = this.getPlotLinePath(l) || null), !sA(r)) {
          this.hideCrosshair();
          return;
        }
        a = this.categories && !this.isRadial, h || (this.cross = h = o.renderer.path().addClass("highcharts-crosshair highcharts-crosshair-" + (a ? "category " : "thin ") + (i.className || "")).attr({
          zIndex: sG(i.zIndex, 2)
        }).add(), !o.styledMode && (h.attr({
          stroke: i.color || (a ? tO.parse("#ccd3ff").setOpacity(.25).get() : "#cccccc"),
          "stroke-width": sG(i.width, 1)
        }).css({
          "pointer-events": "none"
        }), i.dashStyle && h.attr({
          dashstyle: i.dashStyle
        }))), h.show().attr({
          d: r
        }), a && !i.width && h.attr({
          "stroke-width": this.transA
        }), this.cross.e = t;
      } else this.hideCrosshair();
      sE(this, "afterDrawCrosshair", {
        e: t,
        point: e
      });
    }
    hideCrosshair() {
      this.cross && this.cross.hide(), sE(this, "afterHideCrosshair");
    }
    update(t, e) {
      let i = this.chart;
      t = sN(this.userOptions, t), this.destroy(!0), this.init(i, t), i.isDirtyBox = !0, sG(e, !0) && i.redraw();
    }
    remove(t) {
      let e = this.chart,
        i = this.coll,
        s = this.series,
        o = s.length;
      for (; o--;) s[o] && s[o].remove(!1);
      sP(e.axes, this), sP(e[i] || [], this), e.orderItems(i), this.destroy(), e.isDirtyBox = !0, sG(t, !0) && e.redraw();
    }
    setTitle(t, e) {
      this.update({
        title: t
      }, e);
    }
    setCategories(t, e) {
      this.update({
        categories: t
      }, e);
    }
  }
  sU.keepProps = ["coll", "extKey", "hcEvents", "len", "names", "series", "userMax", "userMin"];
  let {
    addEvent: sV,
    getMagnitude: s$,
    normalizeTickInterval: s_,
    timeUnits: sq
  } = tt;
  !function (t) {
    function e() {
      return this.chart.time.getTimeTicks.apply(this.chart.time, arguments);
    }
    function i() {
      if ("datetime" !== this.type) {
        this.dateTime = void 0;
        return;
      }
      this.dateTime || (this.dateTime = new s(this));
    }
    t.compose = function (t) {
      return t.keepProps.includes("dateTime") || (t.keepProps.push("dateTime"), t.prototype.getTimeTicks = e, sV(t, "afterSetType", i)), t;
    };
    class s {
      constructor(t) {
        this.axis = t;
      }
      normalizeTimeTickInterval(t, e) {
        let i = e || [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2]], ["week", [1, 2]], ["month", [1, 2, 3, 4, 6]], ["year", null]],
          s = i[i.length - 1],
          o = sq[s[0]],
          r = s[1],
          n;
        for (n = 0; n < i.length && (o = sq[(s = i[n])[0]], r = s[1], !i[n + 1] || !(t <= (o * r[r.length - 1] + sq[i[n + 1][0]]) / 2)); n++);
        o === sq.year && t < 5 * o && (r = [1, 2, 5]);
        let a = s_(t / o, r, "year" === s[0] ? Math.max(s$(t / o), 1) : 1);
        return {
          unitRange: o,
          count: a,
          unitName: s[0]
        };
      }
      getXDateFormat(t, e) {
        let {
            axis: i
          } = this,
          s = i.chart.time;
        return i.closestPointRange ? s.getDateFormat(i.closestPointRange, t, i.options.startOfWeek, e) || s.resolveDTLFormat(e.year).main : s.resolveDTLFormat(e.day).main;
      }
    }
    t.Additions = s;
  }(d || (d = {}));
  let sZ = d,
    {
      addEvent: sK,
      normalizeTickInterval: sJ,
      pick: sQ
    } = tt;
  !function (t) {
    function e() {
      "logarithmic" !== this.type ? this.logarithmic = void 0 : this.logarithmic ?? (this.logarithmic = new s(this));
    }
    function i() {
      let t = this.logarithmic;
      t && (this.lin2val = function (e) {
        return t.lin2log(e);
      }, this.val2lin = function (e) {
        return t.log2lin(e);
      });
    }
    t.compose = function (t) {
      return t.keepProps.includes("logarithmic") || (t.keepProps.push("logarithmic"), sK(t, "afterSetType", e), sK(t, "afterInit", i)), t;
    };
    class s {
      constructor(t) {
        this.axis = t;
      }
      getLogTickPositions(t, e, i, s) {
        let o = this.axis,
          r = o.len,
          n = o.options,
          a = [];
        if (s || (this.minorAutoInterval = void 0), t >= .5) t = Math.round(t), a = o.getLinearTickPositions(t, e, i);else if (t >= .08) {
          let o, r, n, h, l, d, c;
          let p = Math.floor(e);
          for (o = t > .3 ? [1, 2, 4] : t > .15 ? [1, 2, 4, 6, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9], r = p; r < i + 1 && !c; r++) for (n = 0, h = o.length; n < h && !c; n++) (l = this.log2lin(this.lin2log(r) * o[n])) > e && (!s || d <= i) && void 0 !== d && a.push(d), d > i && (c = !0), d = l;
        } else {
          let h = this.lin2log(e),
            l = this.lin2log(i),
            d = s ? o.getMinorTickInterval() : n.tickInterval,
            c = n.tickPixelInterval / (s ? 5 : 1),
            p = s ? r / o.tickPositions.length : r;
          t = sJ(t = sQ("auto" === d ? null : d, this.minorAutoInterval, (l - h) * c / (p || 1))), a = o.getLinearTickPositions(t, h, l).map(this.log2lin), s || (this.minorAutoInterval = t / 5);
        }
        return s || (o.tickInterval = t), a;
      }
      lin2log(t) {
        return Math.pow(10, t);
      }
      log2lin(t) {
        return Math.log(t) / Math.LN10;
      }
    }
    t.Additions = s;
  }(c || (c = {}));
  let s0 = c,
    {
      erase: s1,
      extend: s2,
      isNumber: s3
    } = tt;
  !function (t) {
    let e;
    function i(t) {
      return this.addPlotBandOrLine(t, "plotBands");
    }
    function s(t, i) {
      let s = this.userOptions,
        o = new e(this, t);
      if (this.visible && (o = o.render()), o) {
        if (this._addedPlotLB || (this._addedPlotLB = !0, (s.plotLines || []).concat(s.plotBands || []).forEach(t => {
          this.addPlotBandOrLine(t);
        })), i) {
          let e = s[i] || [];
          e.push(t), s[i] = e;
        }
        this.plotLinesAndBands.push(o);
      }
      return o;
    }
    function o(t) {
      return this.addPlotBandOrLine(t, "plotLines");
    }
    function r(t, e, i) {
      i = i || this.options;
      let s = this.getPlotLinePath({
          value: e,
          force: !0,
          acrossPanes: i.acrossPanes
        }),
        o = [],
        r = this.horiz,
        n = !s3(this.min) || !s3(this.max) || t < this.min && e < this.min || t > this.max && e > this.max,
        a = this.getPlotLinePath({
          value: t,
          force: !0,
          acrossPanes: i.acrossPanes
        }),
        h,
        l = 1,
        d;
      if (a && s) for (n && (d = a.toString() === s.toString(), l = 0), h = 0; h < a.length; h += 2) {
        let t = a[h],
          e = a[h + 1],
          i = s[h],
          n = s[h + 1];
        ("M" === t[0] || "L" === t[0]) && ("M" === e[0] || "L" === e[0]) && ("M" === i[0] || "L" === i[0]) && ("M" === n[0] || "L" === n[0]) && (r && i[1] === t[1] ? (i[1] += l, n[1] += l) : r || i[2] !== t[2] || (i[2] += l, n[2] += l), o.push(["M", t[1], t[2]], ["L", e[1], e[2]], ["L", n[1], n[2]], ["L", i[1], i[2]], ["Z"])), o.isFlat = d;
      }
      return o;
    }
    function n(t) {
      this.removePlotBandOrLine(t);
    }
    function a(t) {
      let e = this.plotLinesAndBands,
        i = this.options,
        s = this.userOptions;
      if (e) {
        let o = e.length;
        for (; o--;) e[o].id === t && e[o].destroy();
        [i.plotLines || [], s.plotLines || [], i.plotBands || [], s.plotBands || []].forEach(function (e) {
          for (o = e.length; o--;) (e[o] || {}).id === t && s1(e, e[o]);
        });
      }
    }
    function h(t) {
      this.removePlotBandOrLine(t);
    }
    t.compose = function (t, l) {
      let d = l.prototype;
      return d.addPlotBand || (e = t, s2(d, {
        addPlotBand: i,
        addPlotLine: o,
        addPlotBandOrLine: s,
        getPlotBandPath: r,
        removePlotBand: n,
        removePlotLine: h,
        removePlotBandOrLine: a
      })), l;
    };
  }(p || (p = {}));
  let s5 = p,
    {
      addEvent: s6,
      arrayMax: s9,
      arrayMin: s4,
      defined: s8,
      destroyObjectProperties: s7,
      erase: ot,
      fireEvent: oe,
      merge: oi,
      objectEach: os,
      pick: oo
    } = tt;
  class or {
    static compose(t, e) {
      return s6(t, "afterInit", function () {
        this.labelCollectors.push(() => {
          let t = [];
          for (let e of this.axes) for (let {
            label: i,
            options: s
          } of e.plotLinesAndBands) i && !s?.label?.allowOverlap && t.push(i);
          return t;
        });
      }), s5.compose(or, e);
    }
    constructor(t, e) {
      this.axis = t, this.options = e, this.id = e.id;
    }
    render() {
      oe(this, "render");
      let {
          axis: t,
          options: e
        } = this,
        {
          horiz: i,
          logarithmic: s
        } = t,
        {
          color: o,
          events: r,
          zIndex: n = 0
        } = e,
        {
          renderer: a,
          time: h
        } = t.chart,
        l = {},
        d = h.parse(e.to),
        c = h.parse(e.from),
        p = h.parse(e.value),
        u = e.borderWidth,
        g = e.label,
        {
          label: f,
          svgElem: m
        } = this,
        x = [],
        y,
        b = s8(c) && s8(d),
        v = s8(p),
        M = !m,
        k = {
          class: "highcharts-plot-" + (b ? "band " : "line ") + (e.className || "")
        },
        w = b ? "bands" : "lines";
      if (!t.chart.styledMode && (v ? (k.stroke = o || "#999999", k["stroke-width"] = oo(e.width, 1), e.dashStyle && (k.dashstyle = e.dashStyle)) : b && (k.fill = o || "#e6e9ff", u && (k.stroke = e.borderColor, k["stroke-width"] = u))), l.zIndex = n, w += "-" + n, (y = t.plotLinesAndBandsGroups[w]) || (t.plotLinesAndBandsGroups[w] = y = a.g("plot-" + w).attr(l).add()), m || (this.svgElem = m = a.path().attr(k).add(y)), s8(p)) x = t.getPlotLinePath({
        value: s?.log2lin(p) ?? p,
        lineWidth: m.strokeWidth(),
        acrossPanes: e.acrossPanes
      });else {
        if (!(s8(c) && s8(d))) return;
        x = t.getPlotBandPath(s?.log2lin(c) ?? c, s?.log2lin(d) ?? d, e);
      }
      return !this.eventsAdded && r && (os(r, (t, e) => {
        m?.on(e, t => {
          r[e].apply(this, [t]);
        });
      }), this.eventsAdded = !0), (M || !m.d) && x?.length ? m.attr({
        d: x
      }) : m && (x ? (m.show(), m.animate({
        d: x
      })) : m.d && (m.hide(), f && (this.label = f = f.destroy()))), g && (s8(g.text) || s8(g.formatter)) && x?.length && t.width > 0 && t.height > 0 && !x.isFlat ? (g = oi({
        align: i && b ? "center" : void 0,
        x: i ? !b && 4 : 10,
        verticalAlign: !i && b ? "middle" : void 0,
        y: i ? b ? 16 : 10 : b ? 6 : -4,
        rotation: i && !b ? 90 : 0,
        ...(b ? {
          inside: !0
        } : {})
      }, g), this.renderLabel(g, x, b, n)) : f && f.hide(), this;
    }
    renderLabel(t, e, i, s) {
      let o = this.axis,
        r = o.chart.renderer,
        n = t.inside,
        a = this.label;
      a || (this.label = a = r.text(this.getLabelText(t), 0, 0, t.useHTML).attr({
        align: t.textAlign || t.align,
        rotation: t.rotation,
        class: "highcharts-plot-" + (i ? "band" : "line") + "-label " + (t.className || ""),
        zIndex: s
      }), o.chart.styledMode || a.css(oi({
        fontSize: "0.8em",
        textOverflow: i && !n ? "" : "ellipsis"
      }, t.style)), a.add());
      let h = e.xBounds || [e[0][1], e[1][1], i ? e[2][1] : e[0][1]],
        l = e.yBounds || [e[0][2], e[1][2], i ? e[2][2] : e[0][2]],
        d = s4(h),
        c = s4(l),
        p = s9(h) - d;
      a.align(t, !1, {
        x: d,
        y: c,
        width: p,
        height: s9(l) - c
      }), (!a.alignValue || "left" === a.alignValue || s8(n)) && a.css({
        width: (t.style?.width || (i && n ? p : 90 === a.rotation ? o.height - (a.alignAttr.y - o.top) : (t.clip ? o.width : o.chart.chartWidth) - (a.alignAttr.x - o.left))) + "px"
      }), a.show(!0);
    }
    getLabelText(t) {
      return s8(t.formatter) ? t.formatter.call(this) : t.text;
    }
    destroy() {
      ot(this.axis.plotLinesAndBands, this), delete this.axis, s7(this);
    }
  }
  let {
      animObject: on
    } = tU,
    {
      format: oa
    } = ep,
    {
      composed: oh,
      dateFormats: ol,
      doc: od,
      isSafari: oc
    } = O,
    {
      distribute: op
    } = ey,
    {
      addEvent: ou,
      clamp: og,
      css: of,
      discardElement: om,
      extend: ox,
      fireEvent: oy,
      isArray: ob,
      isNumber: ov,
      isObject: oM,
      isString: ok,
      merge: ow,
      pick: oS,
      pushUnique: oA,
      splat: oT,
      syncTimeout: oP
    } = tt;
  class oC {
    constructor(t, e, i) {
      this.allowShared = !0, this.crosshairs = [], this.distance = 0, this.isHidden = !0, this.isSticky = !1, this.options = {}, this.outside = !1, this.chart = t, this.init(t, e), this.pointer = i;
    }
    bodyFormatter(t) {
      return t.map(t => {
        let e = t.series.tooltipOptions,
          i = t.formatPrefix || "point";
        return (e[i + "Formatter"] || t.tooltipFormatter).call(t, e[i + "Format"] || "");
      });
    }
    cleanSplit(t) {
      this.chart.series.forEach(function (e) {
        let i = e && e.tt;
        i && (!i.isActive || t ? e.tt = i.destroy() : i.isActive = !1);
      });
    }
    defaultFormatter(t) {
      let e;
      let i = this.points || oT(this);
      return (e = (e = [t.headerFooterFormatter(i[0])]).concat(t.bodyFormatter(i))).push(t.headerFooterFormatter(i[0], !0)), e;
    }
    destroy() {
      this.label && (this.label = this.label.destroy()), this.split && (this.cleanSplit(!0), this.tt && (this.tt = this.tt.destroy())), this.renderer && (this.renderer = this.renderer.destroy(), om(this.container)), tt.clearTimeout(this.hideTimer);
    }
    getAnchor(t, e) {
      let i;
      let {
          chart: s,
          pointer: o
        } = this,
        r = s.inverted,
        n = s.plotTop,
        a = s.plotLeft;
      if ((t = oT(t))[0].series && t[0].series.yAxis && !t[0].series.yAxis.options.reversedStacks && (t = t.slice().reverse()), this.followPointer && e) void 0 === e.chartX && (e = o.normalize(e)), i = [e.chartX - a, e.chartY - n];else if (t[0].tooltipPos) i = t[0].tooltipPos;else {
        let s = 0,
          o = 0;
        t.forEach(function (t) {
          let e = t.pos(!0);
          e && (s += e[0], o += e[1]);
        }), s /= t.length, o /= t.length, this.shared && t.length > 1 && e && (r ? s = e.chartX : o = e.chartY), i = [s - a, o - n];
      }
      return i.map(Math.round);
    }
    getClassName(t, e, i) {
      let s = this.options,
        o = t.series,
        r = o.options;
      return [s.className, "highcharts-label", i && "highcharts-tooltip-header", e ? "highcharts-tooltip-box" : "highcharts-tooltip", !i && "highcharts-color-" + oS(t.colorIndex, o.colorIndex), r && r.className].filter(ok).join(" ");
    }
    getLabel({
      anchorX: t,
      anchorY: e
    } = {
      anchorX: 0,
      anchorY: 0
    }) {
      let i = this,
        s = this.chart.styledMode,
        o = this.options,
        r = this.split && this.allowShared,
        n = this.container,
        a = this.chart.renderer;
      if (this.label) {
        let t = !this.label.hasClass("highcharts-label");
        (!r && t || r && !t) && this.destroy();
      }
      if (!this.label) {
        if (this.outside) {
          let t = this.chart,
            e = t.options.chart.style,
            i = eu.getRendererType();
          this.container = n = O.doc.createElement("div"), n.className = "highcharts-tooltip-container " + (t.renderTo.className.match(/(highcharts[a-zA-Z0-9-]+)\s?/gm) || ""), of(n, {
            position: "absolute",
            top: "1px",
            pointerEvents: "none",
            zIndex: Math.max(this.options.style.zIndex || 0, (e && e.zIndex || 0) + 3)
          }), this.renderer = a = new i(n, 0, 0, e, void 0, void 0, a.styledMode);
        }
        if (r ? this.label = a.g("tooltip") : (this.label = a.label("", t, e, o.shape, void 0, void 0, o.useHTML, void 0, "tooltip").attr({
          padding: o.padding,
          r: o.borderRadius
        }), s || this.label.attr({
          fill: o.backgroundColor,
          "stroke-width": o.borderWidth || 0
        }).css(o.style).css({
          pointerEvents: o.style.pointerEvents || (this.shouldStickOnContact() ? "auto" : "none")
        })), i.outside) {
          let t = this.label;
          [t.xSetter, t.ySetter].forEach((e, s) => {
            t[s ? "ySetter" : "xSetter"] = o => {
              e.call(t, i.distance), t[s ? "y" : "x"] = o, n && (n.style[s ? "top" : "left"] = `${o}px`);
            };
          });
        }
        this.label.attr({
          zIndex: 8
        }).shadow(o.shadow).add();
      }
      return n && !n.parentElement && O.doc.body.appendChild(n), this.label;
    }
    getPlayingField() {
      let {
          body: t,
          documentElement: e
        } = od,
        {
          chart: i,
          distance: s,
          outside: o
        } = this;
      return {
        width: o ? Math.max(t.scrollWidth, e.scrollWidth, t.offsetWidth, e.offsetWidth, e.clientWidth) - 2 * s - 2 : i.chartWidth,
        height: o ? Math.max(t.scrollHeight, e.scrollHeight, t.offsetHeight, e.offsetHeight, e.clientHeight) : i.chartHeight
      };
    }
    getPosition(t, e, i) {
      let {
          distance: s,
          chart: o,
          outside: r,
          pointer: n
        } = this,
        {
          inverted: a,
          plotLeft: h,
          plotTop: l,
          polar: d
        } = o,
        {
          plotX: c = 0,
          plotY: p = 0
        } = i,
        u = {},
        g = a && i.h || 0,
        {
          height: f,
          width: m
        } = this.getPlayingField(),
        x = n.getChartPosition(),
        y = t => t * x.scaleX,
        b = t => t * x.scaleY,
        v = i => {
          let n = "x" === i;
          return [i, n ? m : f, n ? t : e].concat(r ? [n ? y(t) : b(e), n ? x.left - s + y(c + h) : x.top - s + b(p + l), 0, n ? m : f] : [n ? t : e, n ? c + h : p + l, n ? h : l, n ? h + o.plotWidth : l + o.plotHeight]);
        },
        M = v("y"),
        k = v("x"),
        w,
        S = !!i.negative;
      !d && o.hoverSeries?.yAxis?.reversed && (S = !S);
      let A = !this.followPointer && oS(i.ttBelow, !d && !a === S),
        T = function (t, e, i, o, n, a, h) {
          let l = r ? "y" === t ? b(s) : y(s) : s,
            d = (i - o) / 2,
            c = o < n - s,
            p = n + s + o < e,
            f = n - l - i + d,
            m = n + l - d;
          if (A && p) u[t] = m;else if (!A && c) u[t] = f;else if (c) u[t] = Math.min(h - o, f - g < 0 ? f : f - g);else {
            if (!p) return !1;
            u[t] = Math.max(a, m + g + i > e ? m : m + g);
          }
        },
        P = function (t, e, i, o, r) {
          if (r < s || r > e - s) return !1;
          r < i / 2 ? u[t] = 1 : r > e - o / 2 ? u[t] = e - o - 2 : u[t] = r - i / 2;
        },
        C = function (t) {
          [M, k] = [k, M], w = t;
        },
        O = () => {
          !1 !== T.apply(0, M) ? !1 !== P.apply(0, k) || w || (C(!0), O()) : w ? u.x = u.y = 0 : (C(!0), O());
        };
      return (a && !d || this.len > 1) && C(), O(), u;
    }
    hide(t) {
      let e = this;
      tt.clearTimeout(this.hideTimer), t = oS(t, this.options.hideDelay), this.isHidden || (this.hideTimer = oP(function () {
        let i = e.getLabel();
        e.getLabel().animate({
          opacity: 0
        }, {
          duration: t ? 150 : t,
          complete: () => {
            i.hide(), e.container && e.container.remove();
          }
        }), e.isHidden = !0;
      }, t));
    }
    init(t, e) {
      this.chart = t, this.options = e, this.crosshairs = [], this.isHidden = !0, this.split = e.split && !t.inverted && !t.polar, this.shared = e.shared || this.split, this.outside = oS(e.outside, !!(t.scrollablePixelsX || t.scrollablePixelsY));
    }
    shouldStickOnContact(t) {
      return !!(!this.followPointer && this.options.stickOnContact && (!t || this.pointer.inClass(t.target, "highcharts-tooltip")));
    }
    move(t, e, i, s) {
      let o = this,
        r = on(!o.isHidden && o.options.animation),
        n = o.followPointer || (o.len || 0) > 1,
        a = {
          x: t,
          y: e
        };
      n || (a.anchorX = i, a.anchorY = s), r.step = () => o.drawTracker(), o.getLabel().animate(a, r);
    }
    refresh(t, e) {
      let {
          chart: i,
          options: s,
          pointer: o,
          shared: r
        } = this,
        n = oT(t),
        a = n[0],
        h = s.format,
        l = s.formatter || this.defaultFormatter,
        d = i.styledMode,
        c = this.allowShared;
      if (!s.enabled || !a.series) return;
      tt.clearTimeout(this.hideTimer), this.allowShared = !(!ob(t) && t.series && t.series.noSharedTooltip), c = c && !this.allowShared, this.followPointer = !this.split && a.series.tooltipOptions.followPointer;
      let p = this.getAnchor(t, e),
        u = p[0],
        g = p[1];
      r && this.allowShared && (o.applyInactiveState(n), n.forEach(t => t.setState("hover")), a.points = n), this.len = n.length;
      let f = ok(h) ? oa(h, a, i) : l.call(a, this);
      a.points = void 0;
      let m = a.series;
      if (this.distance = oS(m.tooltipOptions.distance, 16), !1 === f) this.hide();else {
        if (this.split && this.allowShared) this.renderSplit(f, n);else {
          let t = u,
            r = g;
          if (e && o.isDirectTouch && (t = e.chartX - i.plotLeft, r = e.chartY - i.plotTop), i.polar || !1 === m.options.clip || n.some(e => o.isDirectTouch || e.series.shouldShowTooltip(t, r))) {
            let t = this.getLabel(c && this.tt || {});
            (!s.style.width || d) && t.css({
              width: (this.outside ? this.getPlayingField() : i.spacingBox).width + "px"
            }), t.attr({
              class: this.getClassName(a),
              text: f && f.join ? f.join("") : f
            }), this.outside && t.attr({
              x: og(t.x || 0, 0, this.getPlayingField().width - (t.width || 0) - 1)
            }), d || t.attr({
              stroke: s.borderColor || a.color || m.color || "#666666"
            }), this.updatePosition({
              plotX: u,
              plotY: g,
              negative: a.negative,
              ttBelow: a.ttBelow,
              h: p[2] || 0
            });
          } else {
            this.hide();
            return;
          }
        }
        this.isHidden && this.label && this.label.attr({
          opacity: 1
        }).show(), this.isHidden = !1;
      }
      oy(this, "refresh");
    }
    renderSplit(t, e) {
      let i = this,
        {
          chart: s,
          chart: {
            chartWidth: o,
            chartHeight: r,
            plotHeight: n,
            plotLeft: a,
            plotTop: h,
            scrollablePixelsY: l = 0,
            scrollablePixelsX: d,
            styledMode: c
          },
          distance: p,
          options: u,
          options: {
            positioner: g
          },
          pointer: f
        } = i,
        {
          scrollLeft: m = 0,
          scrollTop: x = 0
        } = s.scrollablePlotArea?.scrollingContainer || {},
        y = i.outside && "number" != typeof d ? od.documentElement.getBoundingClientRect() : {
          left: m,
          right: m + o,
          top: x,
          bottom: x + r
        },
        b = i.getLabel(),
        v = this.renderer || s.renderer,
        M = !!(s.xAxis[0] && s.xAxis[0].opposite),
        {
          left: k,
          top: w
        } = f.getChartPosition(),
        S = h + x,
        A = 0,
        T = n - l;
      function P(t, e, s, o, r = !0) {
        let n, a;
        return s ? (n = M ? 0 : T, a = og(t - o / 2, y.left, y.right - o - (i.outside ? k : 0))) : (n = e - S, a = og(a = r ? t - o - p : t + p, r ? a : y.left, y.right)), {
          x: a,
          y: n
        };
      }
      ok(t) && (t = [!1, t]);
      let C = t.slice(0, e.length + 1).reduce(function (t, s, o) {
        if (!1 !== s && "" !== s) {
          let r = e[o - 1] || {
              isHeader: !0,
              plotX: e[0].plotX,
              plotY: n,
              series: {}
            },
            l = r.isHeader,
            d = l ? i : r.series,
            f = d.tt = function (t, e, s) {
              let o = t,
                {
                  isHeader: r,
                  series: n
                } = e;
              if (!o) {
                let t = {
                  padding: u.padding,
                  r: u.borderRadius
                };
                c || (t.fill = u.backgroundColor, t["stroke-width"] = u.borderWidth ?? 1), o = v.label("", 0, 0, u[r ? "headerShape" : "shape"], void 0, void 0, u.useHTML).addClass(i.getClassName(e, !0, r)).attr(t).add(b);
              }
              return o.isActive = !0, o.attr({
                text: s
              }), c || o.css(u.style).attr({
                stroke: u.borderColor || e.color || n.color || "#333333"
              }), o;
            }(d.tt, r, s.toString()),
            m = f.getBBox(),
            x = m.width + f.strokeWidth();
          l && (A = m.height, T += A, M && (S -= A));
          let {
            anchorX: k,
            anchorY: w
          } = function (t) {
            let e, i;
            let {
              isHeader: s,
              plotX: o = 0,
              plotY: r = 0,
              series: l
            } = t;
            if (s) e = Math.max(a + o, a), i = h + n / 2;else {
              let {
                xAxis: t,
                yAxis: s
              } = l;
              e = t.pos + og(o, -p, t.len + p), l.shouldShowTooltip(0, s.pos - h + r, {
                ignoreX: !0
              }) && (i = s.pos + r);
            }
            return {
              anchorX: e = og(e, y.left - p, y.right + p),
              anchorY: i
            };
          }(r);
          if ("number" == typeof w) {
            let e = m.height + 1,
              s = g ? g.call(i, x, e, r) : P(k, w, l, x);
            t.push({
              align: g ? 0 : void 0,
              anchorX: k,
              anchorY: w,
              boxWidth: x,
              point: r,
              rank: oS(s.rank, l ? 1 : 0),
              size: e,
              target: s.y,
              tt: f,
              x: s.x
            });
          } else f.isActive = !1;
        }
        return t;
      }, []);
      !g && C.some(t => {
        let {
            outside: e
          } = i,
          s = (e ? k : 0) + t.anchorX;
        return s < y.left && s + t.boxWidth < y.right || s < k - y.left + t.boxWidth && y.right - s > s;
      }) && (C = C.map(t => {
        let {
          x: e,
          y: i
        } = P(t.anchorX, t.anchorY, t.point.isHeader, t.boxWidth, !1);
        return ox(t, {
          target: i,
          x: e
        });
      })), i.cleanSplit(), op(C, T);
      let O = {
        left: k,
        right: k
      };
      C.forEach(function (t) {
        let {
          x: e,
          boxWidth: s,
          isHeader: o
        } = t;
        !o && (i.outside && k + e < O.left && (O.left = k + e), !o && i.outside && O.left + s > O.right && (O.right = k + e));
      }), C.forEach(function (t) {
        let {
            x: e,
            anchorX: s,
            anchorY: o,
            pos: r,
            point: {
              isHeader: n
            }
          } = t,
          a = {
            visibility: void 0 === r ? "hidden" : "inherit",
            x: e,
            y: (r || 0) + S,
            anchorX: s,
            anchorY: o
          };
        if (i.outside && e < s) {
          let t = k - O.left;
          t > 0 && (n || (a.x = e + t, a.anchorX = s + t), n && (a.x = (O.right - O.left) / 2, a.anchorX = s + t));
        }
        t.tt.attr(a);
      });
      let {
        container: E,
        outside: L,
        renderer: B
      } = i;
      if (L && E && B) {
        let {
          width: t,
          height: e,
          x: i,
          y: s
        } = b.getBBox();
        B.setSize(t + i, e + s, !1), E.style.left = O.left + "px", E.style.top = w + "px";
      }
      oc && b.attr({
        opacity: 1 === b.opacity ? .999 : 1
      });
    }
    drawTracker() {
      if (!this.shouldStickOnContact()) {
        this.tracker && (this.tracker = this.tracker.destroy());
        return;
      }
      let t = this.chart,
        e = this.label,
        i = this.shared ? t.hoverPoints : t.hoverPoint;
      if (!e || !i) return;
      let s = {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        o = this.getAnchor(i),
        r = e.getBBox();
      o[0] += t.plotLeft - (e.translateX || 0), o[1] += t.plotTop - (e.translateY || 0), s.x = Math.min(0, o[0]), s.y = Math.min(0, o[1]), s.width = o[0] < 0 ? Math.max(Math.abs(o[0]), r.width - o[0]) : Math.max(Math.abs(o[0]), r.width), s.height = o[1] < 0 ? Math.max(Math.abs(o[1]), r.height - Math.abs(o[1])) : Math.max(Math.abs(o[1]), r.height), this.tracker ? this.tracker.attr(s) : (this.tracker = e.renderer.rect(s).addClass("highcharts-tracker").add(e), t.styledMode || this.tracker.attr({
        fill: "rgba(0,0,0,0)"
      }));
    }
    styledModeFormat(t) {
      return t.replace('style="font-size: 0.8em"', 'class="highcharts-header"').replace(/style="color:{(point|series)\.color}"/g, 'class="highcharts-color-{$1.colorIndex} {series.options.className} {point.options.className}"');
    }
    headerFooterFormatter(t, e) {
      let i = t.series,
        s = i.tooltipOptions,
        o = i.xAxis,
        r = o && o.dateTime,
        n = {
          isFooter: e,
          point: t
        },
        a = s.xDateFormat || "",
        h = s[e ? "footerFormat" : "headerFormat"];
      return oy(this, "headerFormatter", n, function (e) {
        if (r && !a && ov(t.key) && (a = r.getXDateFormat(t.key, s.dateTimeLabelFormats)), r && a) {
          if (oM(a)) {
            let t = a;
            ol[0] = e => i.chart.time.dateFormat(t, e), a = "%0";
          }
          (t.tooltipDateKeys || ["key"]).forEach(t => {
            h = h.replace(RegExp("point\\." + t + "([ \\)}])", ""), `(point.${t}:${a})$1`);
          });
        }
        i.chart.styledMode && (h = this.styledModeFormat(h)), e.text = oa(h, t, this.chart);
      }), n.text || "";
    }
    update(t) {
      this.destroy(), this.init(this.chart, ow(!0, this.options, t));
    }
    updatePosition(t) {
      let {
          chart: e,
          container: i,
          distance: s,
          options: o,
          pointer: r,
          renderer: n
        } = this,
        {
          height: a = 0,
          width: h = 0
        } = this.getLabel(),
        {
          left: l,
          top: d,
          scaleX: c,
          scaleY: p
        } = r.getChartPosition(),
        u = (o.positioner || this.getPosition).call(this, h, a, t),
        g = O.doc,
        f = (t.plotX || 0) + e.plotLeft,
        m = (t.plotY || 0) + e.plotTop,
        x;
      n && i && (o.positioner && (u.x += l - s, u.y += d - s), x = (o.borderWidth || 0) + 2 * s + 2, n.setSize(og(h + x, 0, g.documentElement.clientWidth) - 1, a + x, !1), (1 !== c || 1 !== p) && (of(i, {
        transform: `scale(${c}, ${p})`
      }), f *= c, m *= p), f += l - u.x, m += d - u.y), this.move(Math.round(u.x), Math.round(u.y || 0), f, m);
    }
  }
  !function (t) {
    t.compose = function (e) {
      oA(oh, "Core.Tooltip") && ou(e, "afterInit", function () {
        let e = this.chart;
        e.options.tooltip && (e.tooltip = new t(e, e.options.tooltip, this));
      });
    };
  }(oC || (oC = {}));
  let oO = oC,
    {
      animObject: oE
    } = tU,
    {
      defaultOptions: oL
    } = tS,
    {
      format: oB
    } = ep,
    {
      addEvent: oI,
      crisp: oD,
      erase: oR,
      extend: oN,
      fireEvent: oz,
      getNestedProperty: oW,
      isArray: oG,
      isFunction: oH,
      isNumber: oF,
      isObject: oX,
      merge: oY,
      pick: oj,
      syncTimeout: oU,
      removeEvent: oV,
      uniqueKey: o$
    } = tt;
  class o_ {
    animateBeforeDestroy() {
      let t = this,
        e = {
          x: t.startXPos,
          opacity: 0
        },
        i = t.getGraphicalProps();
      i.singular.forEach(function (i) {
        t[i] = t[i].animate("dataLabel" === i ? {
          x: t[i].startXPos,
          y: t[i].startYPos,
          opacity: 0
        } : e);
      }), i.plural.forEach(function (e) {
        t[e].forEach(function (e) {
          e.element && e.animate(oN({
            x: t.startXPos
          }, e.startYPos ? {
            x: e.startXPos,
            y: e.startYPos
          } : {}));
        });
      });
    }
    applyOptions(t, e) {
      let i = this.series,
        s = i.options.pointValKey || i.pointValKey;
      return oN(this, t = o_.prototype.optionsToObject.call(this, t)), this.options = this.options ? oN(this.options, t) : t, t.group && delete this.group, t.dataLabels && delete this.dataLabels, s && (this.y = o_.prototype.getNestedProperty.call(this, s)), this.selected && (this.state = "select"), "name" in this && void 0 === e && i.xAxis && i.xAxis.hasNames && (this.x = i.xAxis.nameToX(this)), void 0 === this.x && i ? this.x = e ?? i.autoIncrement() : oF(t.x) && i.options.relativeXValue ? this.x = i.autoIncrement(t.x) : "string" == typeof this.x && (e ?? (e = i.chart.time.parse(this.x)), oF(e) && (this.x = e)), this.isNull = this.isValid && !this.isValid(), this.formatPrefix = this.isNull ? "null" : "point", this;
    }
    destroy() {
      if (!this.destroyed) {
        let t = this,
          e = t.series,
          i = e.chart,
          s = e.options.dataSorting,
          o = i.hoverPoints,
          r = oE(t.series.chart.renderer.globalAnimation),
          n = () => {
            for (let e in (t.graphic || t.graphics || t.dataLabel || t.dataLabels) && (oV(t), t.destroyElements()), t) delete t[e];
          };
        t.legendItem && i.legend.destroyItem(t), o && (t.setState(), oR(o, t), o.length || (i.hoverPoints = null)), t === i.hoverPoint && t.onMouseOut(), s && s.enabled ? (this.animateBeforeDestroy(), oU(n, r.duration)) : n(), i.pointCount--;
      }
      this.destroyed = !0;
    }
    destroyElements(t) {
      let e = this,
        i = e.getGraphicalProps(t);
      i.singular.forEach(function (t) {
        e[t] = e[t].destroy();
      }), i.plural.forEach(function (t) {
        e[t].forEach(function (t) {
          t && t.element && t.destroy();
        }), delete e[t];
      });
    }
    firePointEvent(t, e, i) {
      let s = this,
        o = this.series.options;
      s.manageEvent(t), "click" === t && o.allowPointSelect && (i = function (t) {
        !s.destroyed && s.select && s.select(null, t.ctrlKey || t.metaKey || t.shiftKey);
      }), oz(s, t, e, i);
    }
    getClassName() {
      return "highcharts-point" + (this.selected ? " highcharts-point-select" : "") + (this.negative ? " highcharts-negative" : "") + (this.isNull ? " highcharts-null-point" : "") + (void 0 !== this.colorIndex ? " highcharts-color-" + this.colorIndex : "") + (this.options.className ? " " + this.options.className : "") + (this.zone && this.zone.className ? " " + this.zone.className.replace("highcharts-negative", "") : "");
    }
    getGraphicalProps(t) {
      let e, i;
      let s = this,
        o = [],
        r = {
          singular: [],
          plural: []
        };
      for ((t = t || {
        graphic: 1,
        dataLabel: 1
      }).graphic && o.push("graphic", "connector"), t.dataLabel && o.push("dataLabel", "dataLabelPath", "dataLabelUpper"), i = o.length; i--;) s[e = o[i]] && r.singular.push(e);
      return ["graphic", "dataLabel"].forEach(function (e) {
        let i = e + "s";
        t[e] && s[i] && r.plural.push(i);
      }), r;
    }
    getNestedProperty(t) {
      return t ? 0 === t.indexOf("custom.") ? oW(t, this.options) : this[t] : void 0;
    }
    getZone() {
      let t = this.series,
        e = t.zones,
        i = t.zoneAxis || "y",
        s,
        o = 0;
      for (s = e[0]; this[i] >= s.value;) s = e[++o];
      return this.nonZonedColor || (this.nonZonedColor = this.color), s && s.color && !this.options.color ? this.color = s.color : this.color = this.nonZonedColor, s;
    }
    hasNewShapeType() {
      return (this.graphic && (this.graphic.symbolName || this.graphic.element.nodeName)) !== this.shapeType;
    }
    constructor(t, e, i) {
      this.formatPrefix = "point", this.visible = !0, this.point = this, this.series = t, this.applyOptions(e, i), this.id ?? (this.id = o$()), this.resolveColor(), t.chart.pointCount++, oz(this, "afterInit");
    }
    isValid() {
      return (oF(this.x) || this.x instanceof Date) && oF(this.y);
    }
    optionsToObject(t) {
      let e = this.series,
        i = e.options.keys,
        s = i || e.pointArrayMap || ["y"],
        o = s.length,
        r = {},
        n,
        a = 0,
        h = 0;
      if (oF(t) || null === t) r[s[0]] = t;else if (oG(t)) for (!i && t.length > o && ("string" == (n = typeof t[0]) ? e.xAxis?.dateTime ? r.x = e.chart.time.parse(t[0]) : r.name = t[0] : "number" === n && (r.x = t[0]), a++); h < o;) i && void 0 === t[a] || (s[h].indexOf(".") > 0 ? o_.prototype.setNestedProperty(r, t[a], s[h]) : r[s[h]] = t[a]), a++, h++;else "object" == typeof t && (r = t, t.dataLabels && (e.hasDataLabels = () => !0), t.marker && (e._hasPointMarkers = !0));
      return r;
    }
    pos(t, e = this.plotY) {
      if (!this.destroyed) {
        let {
            plotX: i,
            series: s
          } = this,
          {
            chart: o,
            xAxis: r,
            yAxis: n
          } = s,
          a = 0,
          h = 0;
        if (oF(i) && oF(e)) return t && (a = r ? r.pos : o.plotLeft, h = n ? n.pos : o.plotTop), o.inverted && r && n ? [n.len - e + h, r.len - i + a] : [i + a, e + h];
      }
    }
    resolveColor() {
      let t = this.series,
        e = t.chart.options.chart,
        i = t.chart.styledMode,
        s,
        o,
        r = e.colorCount,
        n;
      delete this.nonZonedColor, t.options.colorByPoint ? (i || (s = (o = t.options.colors || t.chart.options.colors)[t.colorCounter], r = o.length), n = t.colorCounter, t.colorCounter++, t.colorCounter === r && (t.colorCounter = 0)) : (i || (s = t.color), n = t.colorIndex), this.colorIndex = oj(this.options.colorIndex, n), this.color = oj(this.options.color, s);
    }
    setNestedProperty(t, e, i) {
      return i.split(".").reduce(function (t, i, s, o) {
        let r = o.length - 1 === s;
        return t[i] = r ? e : oX(t[i], !0) ? t[i] : {}, t[i];
      }, t), t;
    }
    shouldDraw() {
      return !this.isNull;
    }
    tooltipFormatter(t) {
      let {
          chart: e,
          pointArrayMap: i = ["y"],
          tooltipOptions: s
        } = this.series,
        {
          valueDecimals: o = "",
          valuePrefix: r = "",
          valueSuffix: n = ""
        } = s;
      return e.styledMode && (t = e.tooltip?.styledModeFormat(t) || t), i.forEach(e => {
        e = "{point." + e, (r || n) && (t = t.replace(RegExp(e + "}", "g"), r + e + "}" + n)), t = t.replace(RegExp(e + "}", "g"), e + ":,." + o + "f}");
      }), oB(t, this, e);
    }
    update(t, e, i, s) {
      let o;
      let r = this,
        n = r.series,
        a = r.graphic,
        h = n.chart,
        l = n.options;
      function d() {
        r.applyOptions(t);
        let s = a && r.hasMockGraphic,
          d = null === r.y ? !s : s;
        a && d && (r.graphic = a.destroy(), delete r.hasMockGraphic), oX(t, !0) && (a && a.element && t && t.marker && void 0 !== t.marker.symbol && (r.graphic = a.destroy()), t?.dataLabels && r.dataLabel && (r.dataLabel = r.dataLabel.destroy())), o = r.index;
        let c = {};
        for (let t of n.dataColumnKeys()) c[t] = r[t];
        n.dataTable.setRow(c, o), l.data[o] = oX(l.data[o], !0) || oX(t, !0) ? r.options : oj(t, l.data[o]), n.isDirty = n.isDirtyData = !0, !n.fixedBox && n.hasCartesianSeries && (h.isDirtyBox = !0), "point" === l.legendType && (h.isDirtyLegend = !0), e && h.redraw(i);
      }
      e = oj(e, !0), !1 === s ? d() : r.firePointEvent("update", {
        options: t
      }, d);
    }
    remove(t, e) {
      this.series.removePoint(this.series.data.indexOf(this), t, e);
    }
    select(t, e) {
      let i = this,
        s = i.series,
        o = s.chart;
      t = oj(t, !i.selected), this.selectedStaging = t, i.firePointEvent(t ? "select" : "unselect", {
        accumulate: e
      }, function () {
        i.selected = i.options.selected = t, s.options.data[s.data.indexOf(i)] = i.options, i.setState(t && "select"), e || o.getSelectedPoints().forEach(function (t) {
          let e = t.series;
          t.selected && t !== i && (t.selected = t.options.selected = !1, e.options.data[e.data.indexOf(t)] = t.options, t.setState(o.hoverPoints && e.options.inactiveOtherPoints ? "inactive" : ""), t.firePointEvent("unselect"));
        });
      }), delete this.selectedStaging;
    }
    onMouseOver(t) {
      let {
        inverted: e,
        pointer: i
      } = this.series.chart;
      i && (t = t ? i.normalize(t) : i.getChartCoordinatesFromPoint(this, e), i.runPointActions(t, this));
    }
    onMouseOut() {
      let t = this.series.chart;
      this.firePointEvent("mouseOut"), this.series.options.inactiveOtherPoints || (t.hoverPoints || []).forEach(function (t) {
        t.setState();
      }), t.hoverPoints = t.hoverPoint = null;
    }
    manageEvent(t) {
      let e = oY(this.series.options.point, this.options),
        i = e.events?.[t];
      oH(i) && (!this.hcEvents?.[t] || this.hcEvents?.[t]?.map(t => t.fn).indexOf(i) === -1) ? (this.importedUserEvent?.(), this.importedUserEvent = oI(this, t, i), this.hcEvents && (this.hcEvents[t].userEvent = !0)) : this.importedUserEvent && !i && this.hcEvents?.[t] && this.hcEvents?.[t].userEvent && (oV(this, t), delete this.hcEvents[t], Object.keys(this.hcEvents) || delete this.importedUserEvent);
    }
    setState(t, e) {
      let i = this.series,
        s = this.state,
        o = i.options.states[t || "normal"] || {},
        r = oL.plotOptions[i.type].marker && i.options.marker,
        n = r && !1 === r.enabled,
        a = r && r.states && r.states[t || "normal"] || {},
        h = !1 === a.enabled,
        l = this.marker || {},
        d = i.chart,
        c = r && i.markerAttribs,
        p = i.halo,
        u,
        g,
        f,
        m = i.stateMarkerGraphic,
        x;
      if ((t = t || "") === this.state && !e || this.selected && "select" !== t || !1 === o.enabled || t && (h || n && !1 === a.enabled) || t && l.states && l.states[t] && !1 === l.states[t].enabled) return;
      if (this.state = t, c && (u = i.markerAttribs(this, t)), this.graphic && !this.hasMockGraphic) {
        if (s && this.graphic.removeClass("highcharts-point-" + s), t && this.graphic.addClass("highcharts-point-" + t), !d.styledMode) {
          g = i.pointAttribs(this, t), f = oj(d.options.chart.animation, o.animation);
          let e = g.opacity;
          i.options.inactiveOtherPoints && oF(e) && (this.dataLabels || []).forEach(function (t) {
            t && !t.hasClass("highcharts-data-label-hidden") && (t.animate({
              opacity: e
            }, f), t.connector && t.connector.animate({
              opacity: e
            }, f));
          }), this.graphic.animate(g, f);
        }
        u && this.graphic.animate(u, oj(d.options.chart.animation, a.animation, r.animation)), m && m.hide();
      } else t && a && (x = l.symbol || i.symbol, m && m.currentSymbol !== x && (m = m.destroy()), u && (m ? m[e ? "animate" : "attr"]({
        x: u.x,
        y: u.y
      }) : x && (i.stateMarkerGraphic = m = d.renderer.symbol(x, u.x, u.y, u.width, u.height, oY(r, a)).add(i.markerGroup), m.currentSymbol = x)), !d.styledMode && m && "inactive" !== this.state && m.attr(i.pointAttribs(this, t))), m && (m[t && this.isInside ? "show" : "hide"](), m.element.point = this, m.addClass(this.getClassName(), !0));
      let y = o.halo,
        b = this.graphic || m,
        v = b && b.visibility || "inherit";
      y && y.size && b && "hidden" !== v && !this.isCluster ? (p || (i.halo = p = d.renderer.path().add(b.parentGroup)), p.show()[e ? "animate" : "attr"]({
        d: this.haloPath(y.size)
      }), p.attr({
        class: "highcharts-halo highcharts-color-" + oj(this.colorIndex, i.colorIndex) + (this.className ? " " + this.className : ""),
        visibility: v,
        zIndex: -1
      }), p.point = this, d.styledMode || p.attr(oN({
        fill: this.color || i.color,
        "fill-opacity": y.opacity
      }, t6.filterUserAttributes(y.attributes || {})))) : p?.point?.haloPath && !p.point.destroyed && p.animate({
        d: p.point.haloPath(0)
      }, null, p.hide), oz(this, "afterSetState", {
        state: t
      });
    }
    haloPath(t) {
      let e = this.pos();
      return e ? this.series.chart.renderer.symbols.circle(oD(e[0], 1) - t, e[1] - t, 2 * t, 2 * t) : [];
    }
  }
  let oq = o_,
    {
      parse: oZ
    } = tO,
    {
      charts: oK,
      composed: oJ,
      isTouchDevice: oQ
    } = O,
    {
      addEvent: o0,
      attr: o1,
      css: o2,
      extend: o3,
      find: o5,
      fireEvent: o6,
      isNumber: o9,
      isObject: o4,
      objectEach: o8,
      offset: o7,
      pick: rt,
      pushUnique: re,
      splat: ri
    } = tt;
  class rs {
    applyInactiveState(t) {
      let e = [],
        i;
      (t || []).forEach(function (t) {
        i = t.series, e.push(i), i.linkedParent && e.push(i.linkedParent), i.linkedSeries && (e = e.concat(i.linkedSeries)), i.navigatorSeries && e.push(i.navigatorSeries);
      }), this.chart.series.forEach(function (t) {
        -1 === e.indexOf(t) ? t.setState("inactive", !0) : t.options.inactiveOtherPoints && t.setAllPointsToState("inactive");
      });
    }
    destroy() {
      let t = this;
      this.eventsToUnbind.forEach(t => t()), this.eventsToUnbind = [], !O.chartCount && (rs.unbindDocumentMouseUp.forEach(t => t.unbind()), rs.unbindDocumentMouseUp.length = 0, rs.unbindDocumentTouchEnd && (rs.unbindDocumentTouchEnd = rs.unbindDocumentTouchEnd())), clearInterval(t.tooltipTimeout), o8(t, function (e, i) {
        t[i] = void 0;
      });
    }
    getSelectionMarkerAttrs(t, e) {
      let i = {
        args: {
          chartX: t,
          chartY: e
        },
        attrs: {},
        shapeType: "rect"
      };
      return o6(this, "getSelectionMarkerAttrs", i, i => {
        let s;
        let {
            chart: o,
            zoomHor: r,
            zoomVert: n
          } = this,
          {
            mouseDownX: a = 0,
            mouseDownY: h = 0
          } = o,
          l = i.attrs;
        l.x = o.plotLeft, l.y = o.plotTop, l.width = r ? 1 : o.plotWidth, l.height = n ? 1 : o.plotHeight, r && (s = t - a, l.width = Math.max(1, Math.abs(s)), l.x = (s > 0 ? 0 : s) + a), n && (s = e - h, l.height = Math.max(1, Math.abs(s)), l.y = (s > 0 ? 0 : s) + h);
      }), i;
    }
    drag(t) {
      let {
          chart: e
        } = this,
        {
          mouseDownX: i = 0,
          mouseDownY: s = 0
        } = e,
        {
          panning: o,
          panKey: r,
          selectionMarkerFill: n
        } = e.options.chart,
        a = e.plotLeft,
        h = e.plotTop,
        l = e.plotWidth,
        d = e.plotHeight,
        c = o4(o) ? o.enabled : o,
        p = r && t[`${r}Key`],
        u = t.chartX,
        g = t.chartY,
        f,
        m = this.selectionMarker;
      if ((!m || !m.touch) && (u < a ? u = a : u > a + l && (u = a + l), g < h ? g = h : g > h + d && (g = h + d), this.hasDragged = Math.sqrt(Math.pow(i - u, 2) + Math.pow(s - g, 2)), this.hasDragged > 10)) {
        f = e.isInsidePlot(i - a, s - h, {
          visiblePlotOnly: !0
        });
        let {
          shapeType: r,
          attrs: l
        } = this.getSelectionMarkerAttrs(u, g);
        (e.hasCartesianSeries || e.mapView) && this.hasZoom && f && !p && !m && (this.selectionMarker = m = e.renderer[r](), m.attr({
          class: "highcharts-selection-marker",
          zIndex: 7
        }).add(), e.styledMode || m.attr({
          fill: n || oZ("#334eff").setOpacity(.25).get()
        })), m && m.attr(l), f && !m && c && e.pan(t, o);
      }
    }
    dragStart(t) {
      let e = this.chart;
      e.mouseIsDown = t.type, e.cancelClick = !1, e.mouseDownX = t.chartX, e.mouseDownY = t.chartY;
    }
    getSelectionBox(t) {
      let e = {
        args: {
          marker: t
        },
        result: t.getBBox()
      };
      return o6(this, "getSelectionBox", e), e.result;
    }
    drop(t) {
      let e;
      let {
        chart: i,
        selectionMarker: s
      } = this;
      for (let t of i.axes) t.isPanning && (t.isPanning = !1, (t.options.startOnTick || t.options.endOnTick || t.series.some(t => t.boosted)) && (t.forceRedraw = !0, t.setExtremes(t.userMin, t.userMax, !1), e = !0));
      if (e && i.redraw(), s && t) {
        if (this.hasDragged) {
          let e = this.getSelectionBox(s);
          i.transform({
            axes: i.axes.filter(t => t.zoomEnabled && ("xAxis" === t.coll && this.zoomX || "yAxis" === t.coll && this.zoomY)),
            selection: {
              originalEvent: t,
              xAxis: [],
              yAxis: [],
              ...e
            },
            from: e
          });
        }
        o9(i.index) && (this.selectionMarker = s.destroy());
      }
      i && o9(i.index) && (o2(i.container, {
        cursor: i._cursor
      }), i.cancelClick = this.hasDragged > 10, i.mouseIsDown = !1, this.hasDragged = 0, this.pinchDown = []);
    }
    findNearestKDPoint(t, e, i) {
      let s;
      return t.forEach(function (t) {
        let o = !(t.noSharedTooltip && e) && 0 > t.options.findNearestPointBy.indexOf("y"),
          r = t.searchPoint(i, o);
        o4(r, !0) && r.series && (!o4(s, !0) || function (t, i) {
          let s = t.distX - i.distX,
            o = t.dist - i.dist,
            r = i.series.group?.zIndex - t.series.group?.zIndex;
          return 0 !== s && e ? s : 0 !== o ? o : 0 !== r ? r : t.series.index > i.series.index ? -1 : 1;
        }(s, r) > 0) && (s = r);
      }), s;
    }
    getChartCoordinatesFromPoint(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = t.series,
        o = t.shapeArgs;
      if (i && s) {
        let r = t.clientX ?? t.plotX ?? 0,
          n = t.plotY || 0;
        return t.isNode && o && o9(o.x) && o9(o.y) && (r = o.x, n = o.y), e ? {
          chartX: s.len + s.pos - n,
          chartY: i.len + i.pos - r
        } : {
          chartX: r + i.pos,
          chartY: n + s.pos
        };
      }
      if (o && o.x && o.y) return {
        chartX: o.x,
        chartY: o.y
      };
    }
    getChartPosition() {
      if (this.chartPosition) return this.chartPosition;
      let {
          container: t
        } = this.chart,
        e = o7(t);
      this.chartPosition = {
        left: e.left,
        top: e.top,
        scaleX: 1,
        scaleY: 1
      };
      let {
        offsetHeight: i,
        offsetWidth: s
      } = t;
      return s > 2 && i > 2 && (this.chartPosition.scaleX = e.width / s, this.chartPosition.scaleY = e.height / i), this.chartPosition;
    }
    getCoordinates(t) {
      let e = {
        xAxis: [],
        yAxis: []
      };
      for (let i of this.chart.axes) e[i.isXAxis ? "xAxis" : "yAxis"].push({
        axis: i,
        value: i.toValue(t[i.horiz ? "chartX" : "chartY"])
      });
      return e;
    }
    getHoverData(t, e, i, s, o, r) {
      let n = [],
        a = function (t) {
          return t.visible && !(!o && t.directTouch) && rt(t.options.enableMouseTracking, !0);
        },
        h = e,
        l,
        d = {
          chartX: r ? r.chartX : void 0,
          chartY: r ? r.chartY : void 0,
          shared: o
        };
      o6(this, "beforeGetHoverData", d), l = h && !h.stickyTracking ? [h] : i.filter(t => t.stickyTracking && (d.filter || a)(t));
      let c = s && t || !r ? t : this.findNearestKDPoint(l, o, r);
      return h = c && c.series, c && (o && !h.noSharedTooltip ? (l = i.filter(function (t) {
        return d.filter ? d.filter(t) : a(t) && !t.noSharedTooltip;
      })).forEach(function (t) {
        let e = o5(t.points, function (t) {
          return t.x === c.x && !t.isNull;
        });
        o4(e) && (t.boosted && t.boost && (e = t.boost.getPoint(e)), n.push(e));
      }) : n.push(c)), o6(this, "afterGetHoverData", d = {
        hoverPoint: c
      }), {
        hoverPoint: d.hoverPoint,
        hoverSeries: h,
        hoverPoints: n
      };
    }
    getPointFromEvent(t) {
      let e = t.target,
        i;
      for (; e && !i;) i = e.point, e = e.parentNode;
      return i;
    }
    onTrackerMouseOut(t) {
      let e = this.chart,
        i = t.relatedTarget,
        s = e.hoverSeries;
      this.isDirectTouch = !1, !s || !i || s.stickyTracking || this.inClass(i, "highcharts-tooltip") || this.inClass(i, "highcharts-series-" + s.index) && this.inClass(i, "highcharts-tracker") || s.onMouseOut();
    }
    inClass(t, e) {
      let i = t,
        s;
      for (; i;) {
        if (s = o1(i, "class")) {
          if (-1 !== s.indexOf(e)) return !0;
          if (-1 !== s.indexOf("highcharts-container")) return !1;
        }
        i = i.parentElement;
      }
    }
    constructor(t, e) {
      this.hasDragged = 0, this.pointerCaptureEventsToUnbind = [], this.eventsToUnbind = [], this.options = e, this.chart = t, this.runChartClick = !!e.chart.events?.click, this.pinchDown = [], this.setDOMEvents(), o6(this, "afterInit");
    }
    normalize(t, e) {
      let i = t.touches,
        s = i ? i.length ? i.item(0) : rt(i.changedTouches, t.changedTouches)[0] : t;
      e || (e = this.getChartPosition());
      let o = s.pageX - e.left,
        r = s.pageY - e.top;
      return o3(t, {
        chartX: Math.round(o /= e.scaleX),
        chartY: Math.round(r /= e.scaleY)
      });
    }
    onContainerClick(t) {
      let e = this.chart,
        i = e.hoverPoint,
        s = this.normalize(t),
        o = e.plotLeft,
        r = e.plotTop;
      !e.cancelClick && (i && this.inClass(s.target, "highcharts-tracker") ? (o6(i.series, "click", o3(s, {
        point: i
      })), e.hoverPoint && i.firePointEvent("click", s)) : (o3(s, this.getCoordinates(s)), e.isInsidePlot(s.chartX - o, s.chartY - r, {
        visiblePlotOnly: !0
      }) && o6(e, "click", s)));
    }
    onContainerMouseDown(t) {
      let e = (1 & (t.buttons || t.button)) == 1;
      t = this.normalize(t), O.isFirefox && 0 !== t.button && this.onContainerMouseMove(t), (void 0 === t.button || e) && (this.zoomOption(t), e && t.preventDefault?.(), this.dragStart(t));
    }
    onContainerMouseLeave(t) {
      let {
        pointer: e
      } = oK[rt(rs.hoverChartIndex, -1)] || {};
      t = this.normalize(t), this.onContainerMouseMove(t), e && !this.inClass(t.relatedTarget, "highcharts-tooltip") && (e.reset(), e.chartPosition = void 0);
    }
    onContainerMouseEnter() {
      delete this.chartPosition;
    }
    onContainerMouseMove(t) {
      let e = this.chart,
        i = e.tooltip,
        s = this.normalize(t);
      this.setHoverChartIndex(t), ("mousedown" === e.mouseIsDown || this.touchSelect(s)) && this.drag(s), !e.openMenu && (this.inClass(s.target, "highcharts-tracker") || e.isInsidePlot(s.chartX - e.plotLeft, s.chartY - e.plotTop, {
        visiblePlotOnly: !0
      })) && !(i && i.shouldStickOnContact(s)) && (this.inClass(s.target, "highcharts-no-tooltip") ? this.reset(!1, 0) : this.runPointActions(s));
    }
    onDocumentTouchEnd(t) {
      this.onDocumentMouseUp(t);
    }
    onContainerTouchMove(t) {
      this.touchSelect(t) ? this.onContainerMouseMove(t) : this.touch(t);
    }
    onContainerTouchStart(t) {
      this.touchSelect(t) ? this.onContainerMouseDown(t) : (this.zoomOption(t), this.touch(t, !0));
    }
    onDocumentMouseMove(t) {
      let e = this.chart,
        i = e.tooltip,
        s = this.chartPosition,
        o = this.normalize(t, s);
      !s || e.isInsidePlot(o.chartX - e.plotLeft, o.chartY - e.plotTop, {
        visiblePlotOnly: !0
      }) || i && i.shouldStickOnContact(o) || o.target !== e.container.ownerDocument && this.inClass(o.target, "highcharts-tracker") || this.reset();
    }
    onDocumentMouseUp(t) {
      oK[rt(rs.hoverChartIndex, -1)]?.pointer?.drop(t);
    }
    pinch(t) {
      let e = this,
        {
          chart: i,
          hasZoom: s,
          lastTouches: o
        } = e,
        r = [].map.call(t.touches || [], t => e.normalize(t)),
        n = r.length,
        a = 1 === n && (e.inClass(t.target, "highcharts-tracker") && i.runTrackerClick || e.runChartClick),
        h = i.tooltip,
        l = 1 === n && rt(h?.options.followTouchMove, !0);
      n > 1 ? e.initiated = !0 : l && (e.initiated = !1), s && e.initiated && !a && !1 !== t.cancelable && t.preventDefault(), "touchstart" === t.type ? (e.pinchDown = r, e.res = !0, i.mouseDownX = t.chartX) : l ? this.runPointActions(e.normalize(t)) : o && (o6(i, "touchpan", {
        originalEvent: t,
        touches: r
      }, () => {
        let e = t => {
          let e = t[0],
            i = t[1] || e;
          return {
            x: e.chartX,
            y: e.chartY,
            width: i.chartX - e.chartX,
            height: i.chartY - e.chartY
          };
        };
        i.transform({
          axes: i.axes.filter(t => t.zoomEnabled && (this.zoomHor && t.horiz || this.zoomVert && !t.horiz)),
          to: e(r),
          from: e(o),
          trigger: t.type
        });
      }), e.res && (e.res = !1, this.reset(!1, 0))), e.lastTouches = r;
    }
    reset(t, e) {
      let i = this.chart,
        s = i.hoverSeries,
        o = i.hoverPoint,
        r = i.hoverPoints,
        n = i.tooltip,
        a = n && n.shared ? r : o;
      t && a && ri(a).forEach(function (e) {
        e.series.isCartesian && void 0 === e.plotX && (t = !1);
      }), t ? n && a && ri(a).length && (n.refresh(a), n.shared && r ? r.forEach(function (t) {
        t.setState(t.state, !0), t.series.isCartesian && (t.series.xAxis.crosshair && t.series.xAxis.drawCrosshair(null, t), t.series.yAxis.crosshair && t.series.yAxis.drawCrosshair(null, t));
      }) : o && (o.setState(o.state, !0), i.axes.forEach(function (t) {
        t.crosshair && o.series[t.coll] === t && t.drawCrosshair(null, o);
      }))) : (o && o.onMouseOut(), r && r.forEach(function (t) {
        t.setState();
      }), s && s.onMouseOut(), n && n.hide(e), this.unDocMouseMove && (this.unDocMouseMove = this.unDocMouseMove()), i.axes.forEach(function (t) {
        t.hideCrosshair();
      }), i.hoverPoints = i.hoverPoint = void 0);
    }
    runPointActions(t, e, i) {
      let s = this.chart,
        o = s.series,
        r = s.tooltip && s.tooltip.options.enabled ? s.tooltip : void 0,
        n = !!r && r.shared,
        a = e || s.hoverPoint,
        h = a && a.series || s.hoverSeries,
        l = (!t || "touchmove" !== t.type) && (!!e || h && h.directTouch && this.isDirectTouch),
        d = this.getHoverData(a, h, o, l, n, t);
      a = d.hoverPoint, h = d.hoverSeries;
      let c = d.hoverPoints,
        p = h && h.tooltipOptions.followPointer && !h.tooltipOptions.split,
        u = n && h && !h.noSharedTooltip;
      if (a && (i || a !== s.hoverPoint || r && r.isHidden)) {
        if ((s.hoverPoints || []).forEach(function (t) {
          -1 === c.indexOf(t) && t.setState();
        }), s.hoverSeries !== h && h.onMouseOver(), this.applyInactiveState(c), (c || []).forEach(function (t) {
          t.setState("hover");
        }), s.hoverPoint && s.hoverPoint.firePointEvent("mouseOut"), !a.series) return;
        s.hoverPoints = c, s.hoverPoint = a, a.firePointEvent("mouseOver", void 0, () => {
          r && a && r.refresh(u ? c : a, t);
        });
      } else if (p && r && !r.isHidden) {
        let e = r.getAnchor([{}], t);
        s.isInsidePlot(e[0], e[1], {
          visiblePlotOnly: !0
        }) && r.updatePosition({
          plotX: e[0],
          plotY: e[1]
        });
      }
      this.unDocMouseMove || (this.unDocMouseMove = o0(s.container.ownerDocument, "mousemove", t => oK[rs.hoverChartIndex ?? -1]?.pointer?.onDocumentMouseMove(t)), this.eventsToUnbind.push(this.unDocMouseMove)), s.axes.forEach(function (e) {
        let i;
        let o = rt((e.crosshair || {}).snap, !0);
        !o || (i = s.hoverPoint) && i.series[e.coll] === e || (i = o5(c, t => t.series && t.series[e.coll] === e)), i || !o ? e.drawCrosshair(t, i) : e.hideCrosshair();
      });
    }
    setDOMEvents() {
      let t = this.chart.container,
        e = t.ownerDocument;
      t.onmousedown = this.onContainerMouseDown.bind(this), t.onmousemove = this.onContainerMouseMove.bind(this), t.onclick = this.onContainerClick.bind(this), this.eventsToUnbind.push(o0(t, "mouseenter", this.onContainerMouseEnter.bind(this)), o0(t, "mouseleave", this.onContainerMouseLeave.bind(this))), rs.unbindDocumentMouseUp.some(t => t.doc === e) || rs.unbindDocumentMouseUp.push({
        doc: e,
        unbind: o0(e, "mouseup", this.onDocumentMouseUp.bind(this))
      });
      let i = this.chart.renderTo.parentElement;
      for (; i && "BODY" !== i.tagName;) this.eventsToUnbind.push(o0(i, "scroll", () => {
        delete this.chartPosition;
      })), i = i.parentElement;
      this.eventsToUnbind.push(o0(t, "touchstart", this.onContainerTouchStart.bind(this), {
        passive: !1
      }), o0(t, "touchmove", this.onContainerTouchMove.bind(this), {
        passive: !1
      })), rs.unbindDocumentTouchEnd || (rs.unbindDocumentTouchEnd = o0(e, "touchend", this.onDocumentTouchEnd.bind(this), {
        passive: !1
      })), this.setPointerCapture(), o0(this.chart, "redraw", this.setPointerCapture.bind(this));
    }
    setPointerCapture() {
      if (!oQ) return;
      let t = this.pointerCaptureEventsToUnbind,
        e = this.chart,
        i = e.container,
        s = rt(e.options.tooltip?.followTouchMove, !0) && e.series.some(t => t.options.findNearestPointBy.indexOf("y") > -1);
      !this.hasPointerCapture && s ? (t.push(o0(i, "pointerdown", t => {
        t.target?.hasPointerCapture(t.pointerId) && t.target?.releasePointerCapture(t.pointerId);
      }), o0(i, "pointermove", t => {
        e.pointer?.getPointFromEvent(t)?.onMouseOver(t);
      })), e.styledMode || o2(i, {
        "touch-action": "none"
      }), i.className += " highcharts-no-touch-action", this.hasPointerCapture = !0) : this.hasPointerCapture && !s && (t.forEach(t => t()), t.length = 0, e.styledMode || o2(i, {
        "touch-action": rt(e.options.chart.style?.["touch-action"], "manipulation")
      }), i.className = i.className.replace(" highcharts-no-touch-action", ""), this.hasPointerCapture = !1);
    }
    setHoverChartIndex(t) {
      let e = this.chart,
        i = O.charts[rt(rs.hoverChartIndex, -1)];
      if (i && i !== e) {
        let s = {
          relatedTarget: e.container
        };
        t && !t?.relatedTarget && Object.assign({}, t, s), i.pointer?.onContainerMouseLeave(t || s);
      }
      i && i.mouseIsDown || (rs.hoverChartIndex = e.index);
    }
    touch(t, e) {
      let i;
      let {
        chart: s,
        pinchDown: o = []
      } = this;
      this.setHoverChartIndex(), 1 === (t = this.normalize(t)).touches.length ? s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop, {
        visiblePlotOnly: !0
      }) && !s.openMenu ? (e && this.runPointActions(t), "touchmove" === t.type && (i = !!o[0] && Math.pow(o[0].chartX - t.chartX, 2) + Math.pow(o[0].chartY - t.chartY, 2) >= 16), rt(i, !0) && this.pinch(t)) : e && this.reset() : 2 === t.touches.length && this.pinch(t);
    }
    touchSelect(t) {
      return !!(this.chart.zooming.singleTouch && t.touches && 1 === t.touches.length);
    }
    zoomOption(t) {
      let e = this.chart,
        i = e.inverted,
        s = e.zooming.type || "",
        o,
        r;
      /touch/.test(t.type) && (s = rt(e.zooming.pinchType, s)), this.zoomX = o = /x/.test(s), this.zoomY = r = /y/.test(s), this.zoomHor = o && !i || r && i, this.zoomVert = r && !i || o && i, this.hasZoom = o || r;
    }
  }
  rs.unbindDocumentMouseUp = [], function (t) {
    t.compose = function (e) {
      re(oJ, "Core.Pointer") && o0(e, "beforeRender", function () {
        this.pointer = new t(this, this.options);
      });
    };
  }(rs || (rs = {}));
  let ro = rs,
    {
      fireEvent: rr,
      isArray: rn,
      objectEach: ra,
      uniqueKey: rh
    } = tt,
    rl = class {
      constructor(t = {}) {
        this.autoId = !t.id, this.columns = {}, this.id = t.id || rh(), this.modified = this, this.rowCount = 0, this.versionTag = rh();
        let e = 0;
        ra(t.columns || {}, (t, i) => {
          this.columns[i] = t.slice(), e = Math.max(e, t.length);
        }), this.applyRowCount(e);
      }
      applyRowCount(t) {
        this.rowCount = t, ra(this.columns, e => {
          rn(e) && (e.length = t);
        });
      }
      getColumn(t, e) {
        return this.columns[t];
      }
      getColumns(t, e) {
        return (t || Object.keys(this.columns)).reduce((t, e) => (t[e] = this.columns[e], t), {});
      }
      getRow(t, e) {
        return (e || Object.keys(this.columns)).map(e => this.columns[e]?.[t]);
      }
      setColumn(t, e = [], i = 0, s) {
        this.setColumns({
          [t]: e
        }, i, s);
      }
      setColumns(t, e, i) {
        let s = this.rowCount;
        ra(t, (t, e) => {
          this.columns[e] = t.slice(), s = t.length;
        }), this.applyRowCount(s), i?.silent || (rr(this, "afterSetColumns"), this.versionTag = rh());
      }
      setRow(t, e = this.rowCount, i, s) {
        let {
            columns: o
          } = this,
          r = i ? this.rowCount + 1 : e + 1;
        ra(t, (t, n) => {
          let a = o[n] || s?.addColumns !== !1 && Array(r);
          a && (i ? a.splice(e, 0, t) : a[e] = t, o[n] = a);
        }), r > this.rowCount && this.applyRowCount(r), s?.silent || (rr(this, "afterSetRows"), this.versionTag = rh());
      }
    },
    {
      extend: rd,
      merge: rc,
      pick: rp
    } = tt;
  !function (t) {
    function e(t, e, i) {
      let s = this.legendItem = this.legendItem || {},
        {
          chart: o,
          options: r
        } = this,
        {
          baseline: n = 0,
          symbolWidth: a,
          symbolHeight: h
        } = t,
        l = this.symbol || "circle",
        d = h / 2,
        c = o.renderer,
        p = s.group,
        u = n - Math.round((t.fontMetrics?.b || h) * (i ? .4 : .3)),
        g = {},
        f,
        m = r.marker,
        x = 0;
      if (o.styledMode || (g["stroke-width"] = Math.min(r.lineWidth || 0, 24), r.dashStyle ? g.dashstyle = r.dashStyle : "square" === r.linecap || (g["stroke-linecap"] = "round")), s.line = c.path().addClass("highcharts-graph").attr(g).add(p), i && (s.area = c.path().addClass("highcharts-area").add(p)), g["stroke-linecap"] && (x = Math.min(s.line.strokeWidth(), a) / 2), a) {
        let t = [["M", x, u], ["L", a - x, u]];
        s.line.attr({
          d: t
        }), s.area?.attr({
          d: [...t, ["L", a - x, n], ["L", x, n]]
        });
      }
      if (m && !1 !== m.enabled && a) {
        let t = Math.min(rp(m.radius, d), d);
        0 === l.indexOf("url") && (m = rc(m, {
          width: h,
          height: h
        }), t = 0), s.symbol = f = c.symbol(l, a / 2 - t, u - t, 2 * t, 2 * t, rd({
          context: "legend"
        }, m)).addClass("highcharts-point").add(p), f.isMarker = !0;
      }
    }
    t.areaMarker = function (t, i) {
      e.call(this, t, i, !0);
    }, t.lineMarker = e, t.rectangle = function (t, e) {
      let i = e.legendItem || {},
        s = t.options,
        o = t.symbolHeight,
        r = s.squareSymbol,
        n = r ? o : t.symbolWidth;
      i.symbol = this.chart.renderer.rect(r ? (t.symbolWidth - o) / 2 : 0, t.baseline - o + 1, n, o, rp(t.options.symbolRadius, o / 2)).addClass("highcharts-point").attr({
        zIndex: 3
      }).add(i.group);
    };
  }(u || (u = {}));
  let ru = u,
    {
      defaultOptions: rg
    } = tS,
    {
      extend: rf,
      extendClass: rm,
      merge: rx
    } = tt;
  !function (t) {
    function e(e, i) {
      let s = rg.plotOptions || {},
        o = i.defaultOptions,
        r = i.prototype;
      return r.type = e, r.pointClass || (r.pointClass = oq), !t.seriesTypes[e] && (o && (s[e] = o), t.seriesTypes[e] = i, !0);
    }
    t.seriesTypes = O.seriesTypes, t.registerSeriesType = e, t.seriesType = function (i, s, o, r, n) {
      let a = rg.plotOptions || {};
      if (s = s || "", a[i] = rx(a[s], o), delete t.seriesTypes[i], e(i, rm(t.seriesTypes[s] || function () {}, r)), t.seriesTypes[i].prototype.type = i, n) {
        class e extends oq {}
        rf(e.prototype, n), t.seriesTypes[i].prototype.pointClass = e;
      }
      return t.seriesTypes[i];
    };
  }(g || (g = {}));
  let ry = g,
    {
      animObject: rb,
      setAnimation: rv
    } = tU,
    {
      defaultOptions: rM
    } = tS,
    {
      registerEventOptions: rk
    } = se,
    {
      svg: rw,
      win: rS
    } = O,
    {
      seriesTypes: rA
    } = ry,
    {
      arrayMax: rT,
      arrayMin: rP,
      clamp: rC,
      correctFloat: rO,
      crisp: rE,
      defined: rL,
      destroyObjectProperties: rB,
      diffObjects: rI,
      erase: rD,
      error: rR,
      extend: rN,
      find: rz,
      fireEvent: rW,
      getClosestDistance: rG,
      getNestedProperty: rH,
      insertItem: rF,
      isArray: rX,
      isNumber: rY,
      isString: rj,
      merge: rU,
      objectEach: rV,
      pick: r$,
      removeEvent: r_,
      syncTimeout: rq
    } = tt;
  class rZ {
    constructor() {
      this.zoneAxis = "y";
    }
    init(t, e) {
      let i;
      rW(this, "init", {
        options: e
      }), this.dataTable ?? (this.dataTable = new rl());
      let s = t.series;
      this.eventsToUnbind = [], this.chart = t, this.options = this.setOptions(e);
      let o = this.options,
        r = !1 !== o.visible;
      this.linkedSeries = [], this.bindAxes(), rN(this, {
        name: o.name,
        state: "",
        visible: r,
        selected: !0 === o.selected
      }), rk(this, o);
      let n = o.events;
      (n && n.click || o.point && o.point.events && o.point.events.click || o.allowPointSelect) && (t.runTrackerClick = !0), this.getColor(), this.getSymbol(), this.isCartesian && (t.hasCartesianSeries = !0), s.length && (i = s[s.length - 1]), this._i = r$(i && i._i, -1) + 1, this.opacity = this.options.opacity, t.orderItems("series", rF(this, s)), o.dataSorting && o.dataSorting.enabled ? this.setDataSortingOptions() : this.points || this.data || this.setData(o.data, !1), rW(this, "afterInit");
    }
    is(t) {
      return rA[t] && this instanceof rA[t];
    }
    bindAxes() {
      let t;
      let e = this,
        i = e.options,
        s = e.chart;
      rW(this, "bindAxes", null, function () {
        (e.axisTypes || []).forEach(function (o) {
          (s[o] || []).forEach(function (s) {
            t = s.options, (r$(i[o], 0) === s.index || void 0 !== i[o] && i[o] === t.id) && (rF(e, s.series), e[o] = s, s.isDirty = !0);
          }), e[o] || e.optionalAxis === o || rR(18, !0, s);
        });
      }), rW(this, "afterBindAxes");
    }
    hasData() {
      return this.visible && void 0 !== this.dataMax && void 0 !== this.dataMin || this.visible && this.dataTable.rowCount > 0;
    }
    hasMarkerChanged(t, e) {
      let i = t.marker,
        s = e.marker || {};
      return i && (s.enabled && !i.enabled || s.symbol !== i.symbol || s.height !== i.height || s.width !== i.width);
    }
    autoIncrement(t) {
      let e;
      let i = this.options,
        {
          pointIntervalUnit: s,
          relativeXValue: o
        } = this.options,
        r = this.chart.time,
        n = this.xIncrement ?? r.parse(i.pointStart) ?? 0;
      if (this.pointInterval = e = r$(this.pointInterval, i.pointInterval, 1), o && rY(t) && (e *= t), s) {
        let t = r.toParts(n);
        "day" === s ? t[2] += e : "month" === s ? t[1] += e : "year" === s && (t[0] += e), e = r.makeTime.apply(r, t) - n;
      }
      return o && rY(t) ? n + e : (this.xIncrement = n + e, n);
    }
    setDataSortingOptions() {
      let t = this.options;
      rN(this, {
        requireSorting: !1,
        sorted: !1,
        enabledDataSorting: !0,
        allowDG: !1
      }), rL(t.pointRange) || (t.pointRange = 1);
    }
    setOptions(t) {
      let e;
      let i = this.chart,
        s = i.options.plotOptions,
        o = i.userOptions || {},
        r = rU(t),
        n = i.styledMode,
        a = {
          plotOptions: s,
          userOptions: r
        };
      rW(this, "setOptions", a);
      let h = a.plotOptions[this.type],
        l = o.plotOptions || {},
        d = l.series || {},
        c = rM.plotOptions[this.type] || {},
        p = l[this.type] || {};
      this.userOptions = a.userOptions;
      let u = rU(h, s.series, p, r);
      this.tooltipOptions = rU(rM.tooltip, rM.plotOptions.series?.tooltip, c?.tooltip, i.userOptions.tooltip, l.series?.tooltip, p.tooltip, r.tooltip), this.stickyTracking = r$(r.stickyTracking, p.stickyTracking, d.stickyTracking, !!this.tooltipOptions.shared && !this.noSharedTooltip || u.stickyTracking), null === h.marker && delete u.marker, this.zoneAxis = u.zoneAxis || "y";
      let g = this.zones = (u.zones || []).map(t => ({
        ...t
      }));
      return (u.negativeColor || u.negativeFillColor) && !u.zones && (e = {
        value: u[this.zoneAxis + "Threshold"] || u.threshold || 0,
        className: "highcharts-negative"
      }, n || (e.color = u.negativeColor, e.fillColor = u.negativeFillColor), g.push(e)), g.length && rL(g[g.length - 1].value) && g.push(n ? {} : {
        color: this.color,
        fillColor: this.fillColor
      }), rW(this, "afterSetOptions", {
        options: u
      }), u;
    }
    getName() {
      return r$(this.options.name, "Series " + (this.index + 1));
    }
    getCyclic(t, e, i) {
      let s, o;
      let r = this.chart,
        n = `${t}Index`,
        a = `${t}Counter`,
        h = i?.length || r.options.chart.colorCount;
      !e && (rL(o = r$("color" === t ? this.options.colorIndex : void 0, this[n])) ? s = o : (r.series.length || (r[a] = 0), s = r[a] % h, r[a] += 1), i && (e = i[s])), void 0 !== s && (this[n] = s), this[t] = e;
    }
    getColor() {
      this.chart.styledMode ? this.getCyclic("color") : this.options.colorByPoint ? this.color = "#cccccc" : this.getCyclic("color", this.options.color || rM.plotOptions[this.type].color, this.chart.options.colors);
    }
    getPointsCollection() {
      return (this.hasGroupedData ? this.points : this.data) || [];
    }
    getSymbol() {
      let t = this.options.marker;
      this.getCyclic("symbol", t.symbol, this.chart.options.symbols);
    }
    getColumn(t, e) {
      return (e ? this.dataTable.modified : this.dataTable).getColumn(t, !0) || [];
    }
    findPointIndex(t, e) {
      let i, s, o;
      let r = t.id,
        n = t.x,
        a = this.points,
        h = this.options.dataSorting;
      if (r) {
        let t = this.chart.get(r);
        t instanceof oq && (i = t);
      } else if (this.linkedParent || this.enabledDataSorting || this.options.relativeXValue) {
        let e = e => !e.touched && e.index === t.index;
        if (h && h.matchByName ? e = e => !e.touched && e.name === t.name : this.options.relativeXValue && (e = e => !e.touched && e.options.x === t.x), !(i = rz(a, e))) return;
      }
      return i && void 0 !== (o = i && i.index) && (s = !0), void 0 === o && rY(n) && (o = this.getColumn("x").indexOf(n, e)), -1 !== o && void 0 !== o && this.cropped && (o = o >= this.cropStart ? o - this.cropStart : o), !s && rY(o) && a[o] && a[o].touched && (o = void 0), o;
    }
    updateData(t, e) {
      let i = this.options,
        s = i.dataSorting,
        o = this.points,
        r = [],
        n = this.requireSorting,
        a = t.length === o.length,
        h,
        l,
        d,
        c,
        p = !0;
      if (this.xIncrement = null, t.forEach(function (t, e) {
        let l;
        let d = rL(t) && this.pointClass.prototype.optionsToObject.call({
            series: this
          }, t) || {},
          p = d.x;
        d.id || rY(p) ? (-1 === (l = this.findPointIndex(d, c)) || void 0 === l ? r.push(t) : o[l] && t !== i.data[l] ? (o[l].update(t, !1, null, !1), o[l].touched = !0, n && (c = l + 1)) : o[l] && (o[l].touched = !0), (!a || e !== l || s && s.enabled || this.hasDerivedData) && (h = !0)) : r.push(t);
      }, this), h) for (l = o.length; l--;) (d = o[l]) && !d.touched && d.remove && d.remove(!1, e);else !a || s && s.enabled ? p = !1 : (t.forEach(function (t, e) {
        t === o[e].y || o[e].destroyed || o[e].update(t, !1, null, !1);
      }), r.length = 0);
      if (o.forEach(function (t) {
        t && (t.touched = !1);
      }), !p) return !1;
      r.forEach(function (t) {
        this.addPoint(t, !1, null, null, !1);
      }, this);
      let u = this.getColumn("x");
      return null === this.xIncrement && u.length && (this.xIncrement = rT(u), this.autoIncrement()), !0;
    }
    dataColumnKeys() {
      return ["x", ...(this.pointArrayMap || ["y"])];
    }
    setData(t, e = !0, i, s) {
      let o = this.points,
        r = o && o.length || 0,
        n = this.options,
        a = this.chart,
        h = n.dataSorting,
        l = this.xAxis,
        d = n.turboThreshold,
        c = this.dataTable,
        p = this.dataColumnKeys(),
        u = this.pointValKey || "y",
        g = (this.pointArrayMap || []).length,
        f = n.keys,
        m,
        x,
        y = 0,
        b = 1,
        v;
      a.options.chart.allowMutatingData || (n.data && delete this.options.data, this.userOptions.data && delete this.userOptions.data, v = rU(!0, t));
      let M = (t = v || t || []).length;
      if (h && h.enabled && (t = this.sortData(t)), a.options.chart.allowMutatingData && !1 !== s && M && r && !this.cropped && !this.hasGroupedData && this.visible && !this.boosted && (x = this.updateData(t, i)), !x) {
        this.xIncrement = null, this.colorCounter = 0;
        let e = d && M > d;
        if (e) {
          let i = this.getFirstValidPoint(t),
            s = this.getFirstValidPoint(t, M - 1, -1),
            o = t => !!(rX(t) && (f || rY(t[0])));
          if (rY(i) && rY(s)) {
            let e = [],
              i = [];
            for (let s of t) e.push(this.autoIncrement()), i.push(s);
            c.setColumns({
              x: e,
              [u]: i
            });
          } else if (o(i) && o(s)) {
            if (g) {
              let e = i.length === g ? 1 : 0,
                s = Array(p.length).fill(0).map(() => []);
              for (let i of t) {
                e && s[0].push(this.autoIncrement());
                for (let t = e; t <= g; t++) s[t]?.push(i[t - e]);
              }
              c.setColumns(p.reduce((t, e, i) => (t[e] = s[i], t), {}));
            } else {
              f && (y = f.indexOf("x"), b = f.indexOf("y"), y = y >= 0 ? y : 0, b = b >= 0 ? b : 1), 1 === i.length && (b = 0);
              let e = [],
                s = [];
              if (y === b) for (let i of t) e.push(this.autoIncrement()), s.push(i[b]);else for (let i of t) e.push(i[y]), s.push(i[b]);
              c.setColumns({
                x: e,
                [u]: s
              });
            }
          } else e = !1;
        }
        if (!e) {
          let e = p.reduce((t, e) => (t[e] = [], t), {});
          for (m = 0; m < M; m++) {
            let i = this.pointClass.prototype.applyOptions.apply({
              series: this
            }, [t[m]]);
            for (let t of p) e[t][m] = i[t];
          }
          c.setColumns(e);
        }
        for (rj(this.getColumn("y")[0]) && rR(14, !0, a), this.data = [], this.options.data = this.userOptions.data = t, m = r; m--;) o[m]?.destroy();
        l && (l.minRange = l.userMinRange), this.isDirty = a.isDirtyBox = !0, this.isDirtyData = !!o, i = !1;
      }
      "point" === n.legendType && (this.processData(), this.generatePoints()), e && a.redraw(i);
    }
    sortData(t) {
      let e = this,
        i = e.options.dataSorting.sortKey || "y",
        s = function (t, e) {
          return rL(e) && t.pointClass.prototype.optionsToObject.call({
            series: t
          }, e) || {};
        };
      return t.forEach(function (i, o) {
        t[o] = s(e, i), t[o].index = o;
      }, this), t.concat().sort((t, e) => {
        let s = rH(i, t),
          o = rH(i, e);
        return o < s ? -1 : o > s ? 1 : 0;
      }).forEach(function (t, e) {
        t.x = e;
      }, this), e.linkedSeries && e.linkedSeries.forEach(function (e) {
        let i = e.options,
          o = i.data;
        i.dataSorting && i.dataSorting.enabled || !o || (o.forEach(function (i, r) {
          o[r] = s(e, i), t[r] && (o[r].x = t[r].x, o[r].index = r);
        }), e.setData(o, !1));
      }), t;
    }
    getProcessedData(t) {
      let e = this,
        {
          dataTable: i,
          isCartesian: s,
          options: o,
          xAxis: r
        } = e,
        n = o.cropThreshold,
        a = t || e.getExtremesFromAll,
        h = r?.logarithmic,
        l = i.rowCount,
        d,
        c,
        p = 0,
        u,
        g,
        f,
        m = e.getColumn("x"),
        x = i,
        y = !1;
      return r && (g = (u = r.getExtremes()).min, f = u.max, y = !!(r.categories && !r.names.length), s && e.sorted && !a && (!n || l > n || e.forceCrop) && (m[l - 1] < g || m[0] > f ? x = new rl() : e.getColumn(e.pointValKey || "y").length && (m[0] < g || m[l - 1] > f) && (x = (d = this.cropData(i, g, f)).modified, p = d.start, c = !0))), m = x.getColumn("x") || [], {
        modified: x,
        cropped: c,
        cropStart: p,
        closestPointRange: rG([h ? m.map(h.log2lin) : m], () => e.requireSorting && !y && rR(15, !1, e.chart))
      };
    }
    processData(t) {
      let e = this.xAxis,
        i = this.dataTable;
      if (this.isCartesian && !this.isDirty && !e.isDirty && !this.yAxis.isDirty && !t) return !1;
      let s = this.getProcessedData();
      i.modified = s.modified, this.cropped = s.cropped, this.cropStart = s.cropStart, this.closestPointRange = this.basePointRange = s.closestPointRange, rW(this, "afterProcessData");
    }
    cropData(t, e, i) {
      let s = t.getColumn("x", !0) || [],
        o = s.length,
        r = {},
        n,
        a,
        h = 0,
        l = o;
      for (n = 0; n < o; n++) if (s[n] >= e) {
        h = Math.max(0, n - 1);
        break;
      }
      for (a = n; a < o; a++) if (s[a] > i) {
        l = a + 1;
        break;
      }
      for (let e of this.dataColumnKeys()) {
        let i = t.getColumn(e, !0);
        i && (r[e] = i.slice(h, l));
      }
      return {
        modified: new rl({
          columns: r
        }),
        start: h,
        end: l
      };
    }
    generatePoints() {
      let t = this.options,
        e = this.processedData || t.data,
        i = this.dataTable.modified,
        s = this.getColumn("x", !0),
        o = this.pointClass,
        r = i.rowCount,
        n = this.cropStart || 0,
        a = this.hasGroupedData,
        h = t.keys,
        l = [],
        d = t.dataGrouping && t.dataGrouping.groupAll ? n : 0,
        c = this.xAxis?.categories,
        p = this.pointArrayMap || ["y"],
        u = this.dataColumnKeys(),
        g,
        f,
        m,
        x,
        y = this.data,
        b;
      if (!y && !a) {
        let t = [];
        t.length = e?.length || 0, y = this.data = t;
      }
      for (h && a && (this.options.keys = !1), x = 0; x < r; x++) f = n + x, a ? ((m = new o(this, i.getRow(x, u) || [])).dataGroup = this.groupMap[d + x], m.dataGroup?.options && (m.options = m.dataGroup.options, rN(m, m.dataGroup.options), delete m.dataLabels)) : (m = y[f], b = e ? e[f] : i.getRow(x, p), m || void 0 === b || (y[f] = m = new o(this, b, s[x]))), m && (m.index = a ? d + x : f, l[x] = m, m.category = c?.[m.x] ?? m.x, m.key = m.name ?? m.category);
      if (this.options.keys = h, y && (r !== (g = y.length) || a)) for (x = 0; x < g; x++) x !== n || a || (x += r), y[x] && (y[x].destroyElements(), y[x].plotX = void 0);
      this.data = y, this.points = l, rW(this, "afterGeneratePoints");
    }
    getXExtremes(t) {
      return {
        min: rP(t),
        max: rT(t)
      };
    }
    getExtremes(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = this,
        o = e || this.getExtremesFromAll || this.options.getExtremesFromAll,
        r = o && this.cropped ? this.dataTable : this.dataTable.modified,
        n = r.rowCount,
        a = t || this.stackedYData,
        h = a ? [a] : (this.keysAffectYAxis || this.pointArrayMap || ["y"])?.map(t => r.getColumn(t, !0) || []) || [],
        l = this.getColumn("x", !0),
        d = [],
        c = this.requireSorting && !this.is("column") ? 1 : 0,
        p = !!s && s.positiveValuesOnly,
        u = o || this.cropped || !i,
        g,
        f,
        m,
        x = 0,
        y = 0;
      for (i && (x = (g = i.getExtremes()).min, y = g.max), m = 0; m < n; m++) if (f = l[m], u || (l[m + c] || f) >= x && (l[m - c] || f) <= y) for (let t of h) {
        let e = t[m];
        rY(e) && (e > 0 || !p) && d.push(e);
      }
      let b = {
        activeYData: d,
        dataMin: rP(d),
        dataMax: rT(d)
      };
      return rW(this, "afterGetExtremes", {
        dataExtremes: b
      }), b;
    }
    applyExtremes() {
      let t = this.getExtremes();
      return this.dataMin = t.dataMin, this.dataMax = t.dataMax, t;
    }
    getFirstValidPoint(t, e = 0, i = 1) {
      let s = t.length,
        o = e;
      for (; o >= 0 && o < s;) {
        if (rL(t[o])) return t[o];
        o += i;
      }
    }
    translate() {
      this.generatePoints();
      let t = this.options,
        e = t.stacking,
        i = this.xAxis,
        s = this.enabledDataSorting,
        o = this.yAxis,
        r = this.points,
        n = r.length,
        a = this.pointPlacementToXValue(),
        h = !!a,
        l = t.threshold,
        d = t.startFromThreshold ? l : 0,
        c,
        p,
        u,
        g,
        f = Number.MAX_VALUE;
      function m(t) {
        return rC(t, -1e9, 1e9);
      }
      for (c = 0; c < n; c++) {
        let t;
        let n = r[c],
          x = n.x,
          y,
          b,
          v = n.y,
          M = n.low,
          k = e && o.stacking?.stacks[(this.negStacks && v < (d ? 0 : l) ? "-" : "") + this.stackKey];
        p = i.translate(x, !1, !1, !1, !0, a), n.plotX = rY(p) ? rO(m(p)) : void 0, e && this.visible && k && k[x] && (g = this.getStackIndicator(g, x, this.index), !n.isNull && g.key && (b = (y = k[x]).points[g.key]), y && rX(b) && (M = b[0], v = b[1], M === d && g.key === k[x].base && (M = r$(rY(l) ? l : o.min)), o.positiveValuesOnly && rL(M) && M <= 0 && (M = void 0), n.total = n.stackTotal = r$(y.total), n.percentage = rL(n.y) && y.total ? n.y / y.total * 100 : void 0, n.stackY = v, this.irregularWidths || y.setOffset(this.pointXOffset || 0, this.barW || 0, void 0, void 0, void 0, this.xAxis))), n.yBottom = rL(M) ? m(o.translate(M, !1, !0, !1, !0)) : void 0, this.dataModify && (v = this.dataModify.modifyValue(v, c)), rY(v) && void 0 !== n.plotX && (t = rY(t = o.translate(v, !1, !0, !1, !0)) ? m(t) : void 0), n.plotY = t, n.isInside = this.isPointInside(n), n.clientX = h ? rO(i.translate(x, !1, !1, !1, !0, a)) : p, n.negative = (n.y || 0) < (l || 0), n.isNull || !1 === n.visible || (void 0 !== u && (f = Math.min(f, Math.abs(p - u))), u = p), n.zone = this.zones.length ? n.getZone() : void 0, !n.graphic && this.group && s && (n.isNew = !0);
      }
      this.closestPointRangePx = f, rW(this, "afterTranslate");
    }
    getValidPoints(t, e, i) {
      let s = this.chart;
      return (t || this.points || []).filter(function (t) {
        let {
          plotX: o,
          plotY: r
        } = t;
        return !!((i || !t.isNull && rY(r)) && (!e || s.isInsidePlot(o, r, {
          inverted: s.inverted
        }))) && !1 !== t.visible;
      });
    }
    getClipBox() {
      let {
          chart: t,
          xAxis: e,
          yAxis: i
        } = this,
        {
          x: s,
          y: o,
          width: r,
          height: n
        } = rU(t.clipBox);
      return e && e.len !== t.plotSizeX && (r = e.len), i && i.len !== t.plotSizeY && (n = i.len), t.inverted && !this.invertible && ([r, n] = [n, r]), {
        x: s,
        y: o,
        width: r,
        height: n
      };
    }
    getSharedClipKey() {
      return this.sharedClipKey = (this.options.xAxis || 0) + "," + (this.options.yAxis || 0), this.sharedClipKey;
    }
    setClip() {
      let {
          chart: t,
          group: e,
          markerGroup: i
        } = this,
        s = t.sharedClips,
        o = t.renderer,
        r = this.getClipBox(),
        n = this.getSharedClipKey(),
        a = s[n];
      a ? a.animate(r) : s[n] = a = o.clipRect(r), e && e.clip(!1 === this.options.clip ? void 0 : a), i && i.clip();
    }
    animate(t) {
      let {
          chart: e,
          group: i,
          markerGroup: s
        } = this,
        o = e.inverted,
        r = rb(this.options.animation),
        n = [this.getSharedClipKey(), r.duration, r.easing, r.defer].join(","),
        a = e.sharedClips[n],
        h = e.sharedClips[n + "m"];
      if (t && i) {
        let t = this.getClipBox();
        if (a) a.attr("height", t.height);else {
          t.width = 0, o && (t.x = e.plotHeight), a = e.renderer.clipRect(t), e.sharedClips[n] = a;
          let i = {
            x: -99,
            y: -99,
            width: o ? e.plotWidth + 199 : 99,
            height: o ? 99 : e.plotHeight + 199
          };
          h = e.renderer.clipRect(i), e.sharedClips[n + "m"] = h;
        }
        i.clip(a), s?.clip(h);
      } else if (a && !a.hasClass("highcharts-animating")) {
        let t = this.getClipBox(),
          i = r.step;
        (s?.element.childNodes.length || e.series.length > 1) && (r.step = function (t, e) {
          i && i.apply(e, arguments), "width" === e.prop && h?.element && h.attr(o ? "height" : "width", t + 99);
        }), a.addClass("highcharts-animating").animate(t, r);
      }
    }
    afterAnimate() {
      this.setClip(), rV(this.chart.sharedClips, (t, e, i) => {
        t && !this.chart.container.querySelector(`[clip-path="url(#${t.id})"]`) && (t.destroy(), delete i[e]);
      }), this.finishedAnimating = !0, rW(this, "afterAnimate");
    }
    drawPoints(t = this.points) {
      let e, i, s, o, r, n, a;
      let h = this.chart,
        l = h.styledMode,
        {
          colorAxis: d,
          options: c
        } = this,
        p = c.marker,
        u = this[this.specialGroup || "markerGroup"],
        g = this.xAxis,
        f = r$(p.enabled, !g || !!g.isRadial || null, this.closestPointRangePx >= p.enabledThreshold * p.radius);
      if (!1 !== p.enabled || this._hasPointMarkers) for (e = 0; e < t.length; e++) if (o = (s = (i = t[e]).graphic) ? "animate" : "attr", r = i.marker || {}, n = !!i.marker, (f && void 0 === r.enabled || r.enabled) && !i.isNull && !1 !== i.visible) {
        let t = r$(r.symbol, this.symbol, "rect");
        a = this.markerAttribs(i, i.selected && "select"), this.enabledDataSorting && (i.startXPos = g.reversed ? -(a.width || 0) : g.width);
        let e = !1 !== i.isInside;
        if (!s && e && ((a.width || 0) > 0 || i.hasImage) && (i.graphic = s = h.renderer.symbol(t, a.x, a.y, a.width, a.height, n ? r : p).add(u), this.enabledDataSorting && h.hasRendered && (s.attr({
          x: i.startXPos
        }), o = "animate")), s && "animate" === o && s[e ? "show" : "hide"](e).animate(a), s) {
          let t = this.pointAttribs(i, l || !i.selected ? void 0 : "select");
          l ? d && s.css({
            fill: t.fill
          }) : s[o](t);
        }
        s && s.addClass(i.getClassName(), !0);
      } else s && (i.graphic = s.destroy());
    }
    markerAttribs(t, e) {
      let i = this.options,
        s = i.marker,
        o = t.marker || {},
        r = o.symbol || s.symbol,
        n = {},
        a,
        h,
        l = r$(o.radius, s && s.radius);
      e && (a = s.states[e], l = r$((h = o.states && o.states[e]) && h.radius, a && a.radius, l && l + (a && a.radiusPlus || 0))), t.hasImage = r && 0 === r.indexOf("url"), t.hasImage && (l = 0);
      let d = t.pos();
      return rY(l) && d && (i.crisp && (d[0] = rE(d[0], t.hasImage ? 0 : "rect" === r ? s?.lineWidth || 0 : 1)), n.x = d[0] - l, n.y = d[1] - l), l && (n.width = n.height = 2 * l), n;
    }
    pointAttribs(t, e) {
      let i = this.options.marker,
        s = t && t.options,
        o = s && s.marker || {},
        r = s && s.color,
        n = t && t.color,
        a = t && t.zone && t.zone.color,
        h,
        l,
        d = this.color,
        c,
        p,
        u = r$(o.lineWidth, i.lineWidth),
        g = 1;
      return d = r || a || n || d, c = o.fillColor || i.fillColor || d, p = o.lineColor || i.lineColor || d, e = e || "normal", h = i.states[e] || {}, u = r$((l = o.states && o.states[e] || {}).lineWidth, h.lineWidth, u + r$(l.lineWidthPlus, h.lineWidthPlus, 0)), c = l.fillColor || h.fillColor || c, {
        stroke: p = l.lineColor || h.lineColor || p,
        "stroke-width": u,
        fill: c,
        opacity: g = r$(l.opacity, h.opacity, g)
      };
    }
    destroy(t) {
      let e, i, s;
      let o = this,
        r = o.chart,
        n = /AppleWebKit\/533/.test(rS.navigator.userAgent),
        a = o.data || [];
      for (rW(o, "destroy", {
        keepEventsForUpdate: t
      }), this.removeEvents(t), (o.axisTypes || []).forEach(function (t) {
        (s = o[t]) && s.series && (rD(s.series, o), s.isDirty = s.forceRedraw = !0);
      }), o.legendItem && o.chart.legend.destroyItem(o), e = a.length; e--;) (i = a[e]) && i.destroy && i.destroy();
      for (let t of o.zones) rB(t, void 0, !0);
      tt.clearTimeout(o.animationTimeout), rV(o, function (t, e) {
        t instanceof eJ && !t.survive && t[n && "group" === e ? "hide" : "destroy"]();
      }), r.hoverSeries === o && (r.hoverSeries = void 0), rD(r.series, o), r.orderItems("series"), rV(o, function (e, i) {
        t && "hcEvents" === i || delete o[i];
      });
    }
    applyZones() {
      let {
          area: t,
          chart: e,
          graph: i,
          zones: s,
          points: o,
          xAxis: r,
          yAxis: n,
          zoneAxis: a
        } = this,
        {
          inverted: h,
          renderer: l
        } = e,
        d = this[`${a}Axis`],
        {
          isXAxis: c,
          len: p = 0,
          minPointOffset: u = 0
        } = d || {},
        g = (i?.strokeWidth() || 0) / 2 + 1,
        f = (t, e = 0, i = 0) => {
          h && (i = p - i);
          let {
              translated: s = 0,
              lineClip: o
            } = t,
            r = i - s;
          o?.push(["L", e, Math.abs(r) < g ? i - g * (r <= 0 ? -1 : 1) : s]);
        };
      if (s.length && (i || t) && d && rY(d.min)) {
        let e = d.getExtremes().max + u,
          g = t => {
            t.forEach((e, i) => {
              ("M" === e[0] || "L" === e[0]) && (t[i] = [e[0], c ? p - e[1] : e[1], c ? e[2] : p - e[2]]);
            });
          };
        if (s.forEach(t => {
          t.lineClip = [], t.translated = rC(d.toPixels(r$(t.value, e), !0) || 0, 0, p);
        }), i && !this.showLine && i.hide(), t && t.hide(), "y" === a && o.length < r.len) for (let t of o) {
          let {
              plotX: e,
              plotY: i,
              zone: o
            } = t,
            r = o && s[s.indexOf(o) - 1];
          o && f(o, e, i), r && f(r, e, i);
        }
        let m = [],
          x = d.toPixels(d.getExtremes().min - u, !0);
        s.forEach(e => {
          let s = e.lineClip || [],
            o = Math.round(e.translated || 0);
          r.reversed && s.reverse();
          let {
              clip: a,
              simpleClip: d
            } = e,
            p = 0,
            u = 0,
            f = r.len,
            y = n.len;
          c ? (p = o, f = x) : (u = o, y = x);
          let b = [["M", p, u], ["L", f, u], ["L", f, y], ["L", p, y], ["Z"]],
            v = [b[0], ...s, b[1], b[2], ...m, b[3], b[4]];
          m = s.reverse(), x = o, h && (g(v), t && g(b)), a ? (a.animate({
            d: v
          }), d?.animate({
            d: b
          })) : (a = e.clip = l.path(v), t && (d = e.simpleClip = l.path(b))), i && e.graph?.clip(a), t && e.area?.clip(d);
        });
      } else this.visible && (i && i.show(), t && t.show());
    }
    plotGroup(t, e, i, s, o) {
      let r = this[t],
        n = !r,
        a = {
          visibility: i,
          zIndex: s || .1
        };
      return rL(this.opacity) && !this.chart.styledMode && "inactive" !== this.state && (a.opacity = this.opacity), r || (this[t] = r = this.chart.renderer.g().add(o)), r.addClass("highcharts-" + e + " highcharts-series-" + this.index + " highcharts-" + this.type + "-series " + (rL(this.colorIndex) ? "highcharts-color-" + this.colorIndex + " " : "") + (this.options.className || "") + (r.hasClass("highcharts-tracker") ? " highcharts-tracker" : ""), !0), r.attr(a)[n ? "attr" : "animate"](this.getPlotBox(e)), r;
    }
    getPlotBox(t) {
      let e = this.xAxis,
        i = this.yAxis,
        s = this.chart,
        o = s.inverted && !s.polar && e && this.invertible && "series" === t;
      return s.inverted && (e = i, i = this.xAxis), {
        translateX: e ? e.left : s.plotLeft,
        translateY: i ? i.top : s.plotTop,
        rotation: o ? 90 : 0,
        rotationOriginX: o ? (e.len - i.len) / 2 : 0,
        rotationOriginY: o ? (e.len + i.len) / 2 : 0,
        scaleX: o ? -1 : 1,
        scaleY: 1
      };
    }
    removeEvents(t) {
      let {
        eventsToUnbind: e
      } = this;
      t || r_(this), e.length && (e.forEach(t => {
        t();
      }), e.length = 0);
    }
    render() {
      let t = this,
        {
          chart: e,
          options: i,
          hasRendered: s
        } = t,
        o = rb(i.animation),
        r = t.visible ? "inherit" : "hidden",
        n = i.zIndex,
        a = e.seriesGroup,
        h = t.finishedAnimating ? 0 : o.duration;
      rW(this, "render"), t.plotGroup("group", "series", r, n, a), t.markerGroup = t.plotGroup("markerGroup", "markers", r, n, a), !1 !== i.clip && t.setClip(), h && t.animate?.(!0), t.drawGraph && (t.drawGraph(), t.applyZones()), t.visible && t.drawPoints(), t.drawDataLabels?.(), t.redrawPoints?.(), i.enableMouseTracking && t.drawTracker?.(), h && t.animate?.(), s || (h && o.defer && (h += o.defer), t.animationTimeout = rq(() => {
        t.afterAnimate();
      }, h || 0)), t.isDirty = !1, t.hasRendered = !0, rW(t, "afterRender");
    }
    redraw() {
      let t = this.isDirty || this.isDirtyData;
      this.translate(), this.render(), t && delete this.kdTree;
    }
    reserveSpace() {
      return this.visible || !this.chart.options.chart.ignoreHiddenSeries;
    }
    searchPoint(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = this,
        o = this.chart.inverted;
      return this.searchKDTree({
        clientX: o ? i.len - t.chartY + i.pos : t.chartX - i.pos,
        plotY: o ? s.len - t.chartX + s.pos : t.chartY - s.pos
      }, e, t);
    }
    buildKDTree(t) {
      this.buildingKdTree = !0;
      let e = this,
        i = e.options.findNearestPointBy.indexOf("y") > -1 ? 2 : 1;
      delete e.kdTree, rq(function () {
        e.kdTree = function t(i, s, o) {
          let r, n;
          let a = i?.length;
          if (a) return r = e.kdAxisArray[s % o], i.sort((t, e) => (t[r] || 0) - (e[r] || 0)), {
            point: i[n = Math.floor(a / 2)],
            left: t(i.slice(0, n), s + 1, o),
            right: t(i.slice(n + 1), s + 1, o)
          };
        }(e.getValidPoints(void 0, !e.directTouch), i, i), e.buildingKdTree = !1;
      }, e.options.kdNow || t?.type === "touchstart" ? 0 : 1);
    }
    searchKDTree(t, e, i, s, o) {
      let r = this,
        [n, a] = this.kdAxisArray,
        h = e ? "distX" : "dist",
        l = (r.options.findNearestPointBy || "").indexOf("y") > -1 ? 2 : 1,
        d = !!r.isBubble,
        c = s || ((t, e, i) => [(t[i] || 0) < (e[i] || 0) ? t : e, !1]),
        p = o || ((t, e) => t < e);
      if (this.kdTree || this.buildingKdTree || this.buildKDTree(i), this.kdTree) return function t(e, i, s, o) {
        let l = i.point,
          u = r.kdAxisArray[s % o],
          g = l,
          f = !1;
        !function (t, e) {
          let i = t[n],
            s = e[n],
            o = rL(i) && rL(s) ? i - s : null,
            r = t[a],
            h = e[a],
            l = rL(r) && rL(h) ? r - h : 0,
            c = d && e.marker?.radius || 0;
          e.dist = Math.sqrt((o && o * o || 0) + l * l) - c, e.distX = rL(o) ? Math.abs(o) - c : Number.MAX_VALUE;
        }(e, l);
        let m = (e[u] || 0) - (l[u] || 0) + (d && l.marker?.radius || 0),
          x = m < 0 ? "left" : "right",
          y = m < 0 ? "right" : "left";
        return i[x] && ([g, f] = c(l, t(e, i[x], s + 1, o), h)), i[y] && p(Math.sqrt(m * m), g[h], f) && (g = c(g, t(e, i[y], s + 1, o), h)[0]), g;
      }(t, this.kdTree, l, l);
    }
    pointPlacementToXValue() {
      let {
          options: t,
          xAxis: e
        } = this,
        i = t.pointPlacement;
      return "between" === i && (i = e.reversed ? -.5 : .5), rY(i) ? i * (t.pointRange || e.pointRange) : 0;
    }
    isPointInside(t) {
      let {
          chart: e,
          xAxis: i,
          yAxis: s
        } = this,
        {
          plotX: o = -1,
          plotY: r = -1
        } = t;
      return r >= 0 && r <= (s ? s.len : e.plotHeight) && o >= 0 && o <= (i ? i.len : e.plotWidth);
    }
    drawTracker() {
      let t = this,
        e = t.options,
        i = e.trackByArea,
        s = [].concat((i ? t.areaPath : t.graphPath) || []),
        o = t.chart,
        r = o.pointer,
        n = o.renderer,
        a = o.options.tooltip?.snap || 0,
        h = () => {
          e.enableMouseTracking && o.hoverSeries !== t && t.onMouseOver();
        },
        l = "rgba(192,192,192," + (rw ? 1e-4 : .002) + ")",
        d = t.tracker;
      d ? d.attr({
        d: s
      }) : t.graph && (t.tracker = d = n.path(s).attr({
        visibility: t.visible ? "inherit" : "hidden",
        zIndex: 2
      }).addClass(i ? "highcharts-tracker-area" : "highcharts-tracker-line").add(t.group), o.styledMode || d.attr({
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        stroke: l,
        fill: i ? l : "none",
        "stroke-width": t.graph.strokeWidth() + (i ? 0 : 2 * a)
      }), [t.tracker, t.markerGroup, t.dataLabelsGroup].forEach(t => {
        t && (t.addClass("highcharts-tracker").on("mouseover", h).on("mouseout", t => {
          r?.onTrackerMouseOut(t);
        }), e.cursor && !o.styledMode && t.css({
          cursor: e.cursor
        }), t.on("touchstart", h));
      })), rW(this, "afterDrawTracker");
    }
    addPoint(t, e, i, s, o) {
      let r, n;
      let a = this.options,
        {
          chart: h,
          data: l,
          dataTable: d,
          xAxis: c
        } = this,
        p = c && c.hasNames && c.names,
        u = a.data,
        g = this.getColumn("x");
      e = r$(e, !0);
      let f = {
        series: this
      };
      this.pointClass.prototype.applyOptions.apply(f, [t]);
      let m = f.x;
      if (n = g.length, this.requireSorting && m < g[n - 1]) for (r = !0; n && g[n - 1] > m;) n--;
      d.setRow(f, n, !0, {
        addColumns: !1
      }), p && f.name && (p[m] = f.name), u?.splice(n, 0, t), (r || this.processedData) && (this.data.splice(n, 0, null), this.processData()), "point" === a.legendType && this.generatePoints(), i && (l[0] && l[0].remove ? l[0].remove(!1) : ([l, u, ...Object.values(d.getColumns())].filter(rL).forEach(t => {
        t.shift();
      }), d.rowCount -= 1, rW(d, "afterDeleteRows"))), !1 !== o && rW(this, "addPoint", {
        point: f
      }), this.isDirty = !0, this.isDirtyData = !0, e && h.redraw(s);
    }
    removePoint(t, e, i) {
      let s = this,
        {
          chart: o,
          data: r,
          points: n,
          dataTable: a
        } = s,
        h = r[t],
        l = function () {
          [n?.length === r.length ? n : void 0, r, s.options.data, ...Object.values(a.getColumns())].filter(rL).forEach(e => {
            e.splice(t, 1);
          }), a.rowCount -= 1, rW(a, "afterDeleteRows"), h?.destroy(), s.isDirty = !0, s.isDirtyData = !0, e && o.redraw();
        };
      rv(i, o), e = r$(e, !0), h ? h.firePointEvent("remove", null, l) : l();
    }
    remove(t, e, i, s) {
      let o = this,
        r = o.chart;
      function n() {
        o.destroy(s), r.isDirtyLegend = r.isDirtyBox = !0, r.linkSeries(s), r$(t, !0) && r.redraw(e);
      }
      !1 !== i ? rW(o, "remove", null, n) : n();
    }
    update(t, e) {
      rW(this, "update", {
        options: t = rI(t, this.userOptions)
      });
      let i = this,
        s = i.chart,
        o = i.userOptions,
        r = i.initialType || i.type,
        n = s.options.plotOptions,
        a = rA[r].prototype,
        h = i.finishedAnimating && {
          animation: !1
        },
        l = {},
        d,
        c,
        p = ["colorIndex", "eventOptions", "navigatorSeries", "symbolIndex", "baseSeries"],
        u = t.type || o.type || s.options.chart.type,
        g = !(this.hasDerivedData || u && u !== this.type || void 0 !== t.keys || void 0 !== t.pointStart || void 0 !== t.pointInterval || void 0 !== t.relativeXValue || t.joinBy || t.mapData || ["dataGrouping", "pointStart", "pointInterval", "pointIntervalUnit", "keys"].some(t => i.hasOptionChanged(t)));
      u = u || r, g ? (p.push("data", "isDirtyData", "isDirtyCanvas", "points", "dataTable", "processedData", "xIncrement", "cropped", "_hasPointMarkers", "hasDataLabels", "nodes", "layout", "level", "mapMap", "mapData", "minY", "maxY", "minX", "maxX", "transformGroups"), !1 !== t.visible && p.push("area", "graph"), i.parallelArrays.forEach(function (t) {
        p.push(t + "Data");
      }), t.data && (t.dataSorting && rN(i.options.dataSorting, t.dataSorting), this.setData(t.data, !1))) : this.dataTable.modified = this.dataTable, t = rU(o, {
        index: void 0 === o.index ? i.index : o.index,
        pointStart: n?.series?.pointStart ?? o.pointStart ?? i.getColumn("x")[0]
      }, !g && {
        data: i.options.data
      }, t, h), g && t.data && (t.data = i.options.data), (p = ["group", "markerGroup", "dataLabelsGroup", "transformGroup"].concat(p)).forEach(function (t) {
        p[t] = i[t], delete i[t];
      });
      let f = !1;
      if (rA[u]) {
        if (f = u !== i.type, i.remove(!1, !1, !1, !0), f) {
          if (s.propFromSeries(), Object.setPrototypeOf) Object.setPrototypeOf(i, rA[u].prototype);else {
            let t = Object.hasOwnProperty.call(i, "hcEvents") && i.hcEvents;
            for (c in a) i[c] = void 0;
            rN(i, rA[u].prototype), t ? i.hcEvents = t : delete i.hcEvents;
          }
        }
      } else rR(17, !0, s, {
        missingModuleFor: u
      });
      if (p.forEach(function (t) {
        i[t] = p[t];
      }), i.init(s, t), g && this.points) for (let t of (!1 === (d = i.options).visible ? (l.graphic = 1, l.dataLabel = 1) : (this.hasMarkerChanged(d, o) && (l.graphic = 1), i.hasDataLabels?.() || (l.dataLabel = 1)), this.points)) t && t.series && (t.resolveColor(), Object.keys(l).length && t.destroyElements(l), !1 === d.showInLegend && t.legendItem && s.legend.destroyItem(t));
      i.initialType = r, s.linkSeries(), s.setSortedData(), f && i.linkedSeries.length && (i.isDirtyData = !0), rW(this, "afterUpdate"), r$(e, !0) && s.redraw(!!g && void 0);
    }
    setName(t) {
      this.name = this.options.name = this.userOptions.name = t, this.chart.isDirtyLegend = !0;
    }
    hasOptionChanged(t) {
      let e = this.chart,
        i = this.options[t],
        s = e.options.plotOptions,
        o = this.userOptions[t],
        r = r$(s?.[this.type]?.[t], s?.series?.[t]);
      return o && !rL(r) ? i !== o : i !== r$(r, i);
    }
    onMouseOver() {
      let t = this.chart,
        e = t.hoverSeries,
        i = t.pointer;
      i?.setHoverChartIndex(), e && e !== this && e.onMouseOut(), this.options.events.mouseOver && rW(this, "mouseOver"), this.setState("hover"), t.hoverSeries = this;
    }
    onMouseOut() {
      let t = this.options,
        e = this.chart,
        i = e.tooltip,
        s = e.hoverPoint;
      e.hoverSeries = null, s && s.onMouseOut(), this && t.events.mouseOut && rW(this, "mouseOut"), i && !this.stickyTracking && (!i.shared || this.noSharedTooltip) && i.hide(), e.series.forEach(function (t) {
        t.setState("", !0);
      });
    }
    setState(t, e) {
      let i = this,
        s = i.options,
        o = i.graph,
        r = s.inactiveOtherPoints,
        n = s.states,
        a = r$(n[t || "normal"] && n[t || "normal"].animation, i.chart.options.chart.animation),
        h = s.lineWidth,
        l = s.opacity;
      if (t = t || "", i.state !== t && ([i.group, i.markerGroup, i.dataLabelsGroup].forEach(function (e) {
        e && (i.state && e.removeClass("highcharts-series-" + i.state), t && e.addClass("highcharts-series-" + t));
      }), i.state = t, !i.chart.styledMode)) {
        if (n[t] && !1 === n[t].enabled) return;
        if (t && (h = n[t].lineWidth || h + (n[t].lineWidthPlus || 0), l = r$(n[t].opacity, l)), o && !o.dashstyle && rY(h)) for (let t of [o, ...this.zones.map(t => t.graph)]) t?.animate({
          "stroke-width": h
        }, a);
        r || [i.group, i.markerGroup, i.dataLabelsGroup, i.labelBySeries].forEach(function (t) {
          t && t.animate({
            opacity: l
          }, a);
        });
      }
      e && r && i.points && i.setAllPointsToState(t || void 0);
    }
    setAllPointsToState(t) {
      this.points.forEach(function (e) {
        e.setState && e.setState(t);
      });
    }
    setVisible(t, e) {
      let i = this,
        s = i.chart,
        o = s.options.chart.ignoreHiddenSeries,
        r = i.visible;
      i.visible = t = i.options.visible = i.userOptions.visible = void 0 === t ? !r : t;
      let n = t ? "show" : "hide";
      ["group", "dataLabelsGroup", "markerGroup", "tracker", "tt"].forEach(t => {
        i[t]?.[n]();
      }), (s.hoverSeries === i || s.hoverPoint?.series === i) && i.onMouseOut(), i.legendItem && s.legend.colorizeItem(i, t), i.isDirty = !0, i.options.stacking && s.series.forEach(t => {
        t.options.stacking && t.visible && (t.isDirty = !0);
      }), i.linkedSeries.forEach(e => {
        e.setVisible(t, !1);
      }), o && (s.isDirtyBox = !0), rW(i, n), !1 !== e && s.redraw();
    }
    show() {
      this.setVisible(!0);
    }
    hide() {
      this.setVisible(!1);
    }
    select(t) {
      this.selected = t = this.options.selected = void 0 === t ? !this.selected : t, this.checkbox && (this.checkbox.checked = t), rW(this, t ? "select" : "unselect");
    }
    shouldShowTooltip(t, e, i = {}) {
      return i.series = this, i.visiblePlotOnly = !0, this.chart.isInsidePlot(t, e, i);
    }
    drawLegendSymbol(t, e) {
      ru[this.options.legendSymbol || "rectangle"]?.call(this, t, e);
    }
  }
  rZ.defaultOptions = {
    lineWidth: 2,
    allowPointSelect: !1,
    crisp: !0,
    showCheckbox: !1,
    animation: {
      duration: 1e3
    },
    enableMouseTracking: !0,
    events: {},
    marker: {
      enabledThreshold: 2,
      lineColor: "#ffffff",
      lineWidth: 0,
      radius: 4,
      states: {
        normal: {
          animation: !0
        },
        hover: {
          animation: {
            duration: 150
          },
          enabled: !0,
          radiusPlus: 2,
          lineWidthPlus: 1
        },
        select: {
          fillColor: "#cccccc",
          lineColor: "#000000",
          lineWidth: 2
        }
      }
    },
    point: {
      events: {}
    },
    dataLabels: {
      animation: {},
      align: "center",
      borderWidth: 0,
      defer: !0,
      formatter: function () {
        let {
          numberFormatter: t
        } = this.series.chart;
        return "number" != typeof this.y ? "" : t(this.y, -1);
      },
      padding: 5,
      style: {
        fontSize: "0.7em",
        fontWeight: "bold",
        color: "contrast",
        textOutline: "1px contrast"
      },
      verticalAlign: "bottom",
      x: 0,
      y: 0
    },
    cropThreshold: 300,
    opacity: 1,
    pointRange: 0,
    softThreshold: !0,
    states: {
      normal: {
        animation: !0
      },
      hover: {
        animation: {
          duration: 150
        },
        lineWidthPlus: 1,
        marker: {},
        halo: {
          size: 10,
          opacity: .25
        }
      },
      select: {
        animation: {
          duration: 0
        }
      },
      inactive: {
        animation: {
          duration: 150
        },
        opacity: .2
      }
    },
    stickyTracking: !0,
    turboThreshold: 1e3,
    findNearestPointBy: "x"
  }, rZ.types = ry.seriesTypes, rZ.registerType = ry.registerSeriesType, rN(rZ.prototype, {
    axisTypes: ["xAxis", "yAxis"],
    coll: "series",
    colorCounter: 0,
    directTouch: !1,
    invertible: !0,
    isCartesian: !0,
    kdAxisArray: ["clientX", "plotY"],
    parallelArrays: ["x", "y"],
    pointClass: oq,
    requireSorting: !0,
    sorted: !0
  }), ry.series = rZ;
  let rK = rZ,
    {
      animObject: rJ,
      setAnimation: rQ
    } = tU,
    {
      registerEventOptions: r0
    } = se,
    {
      composed: r1,
      marginNames: r2
    } = O,
    {
      distribute: r3
    } = ey,
    {
      format: r5
    } = ep,
    {
      addEvent: r6,
      createElement: r9,
      css: r4,
      defined: r8,
      discardElement: r7,
      find: nt,
      fireEvent: ne,
      isNumber: ni,
      merge: ns,
      pick: no,
      pushUnique: nr,
      relativeLength: nn,
      stableSort: na,
      syncTimeout: nh
    } = tt;
  class nl {
    constructor(t, e) {
      this.allItems = [], this.initialItemY = 0, this.itemHeight = 0, this.itemMarginBottom = 0, this.itemMarginTop = 0, this.itemX = 0, this.itemY = 0, this.lastItemY = 0, this.lastLineHeight = 0, this.legendHeight = 0, this.legendWidth = 0, this.maxItemWidth = 0, this.maxLegendWidth = 0, this.offsetWidth = 0, this.padding = 0, this.pages = [], this.symbolHeight = 0, this.symbolWidth = 0, this.titleHeight = 0, this.totalItemWidth = 0, this.widthOption = 0, this.chart = t, this.setOptions(e), e.enabled && (this.render(), r0(this, e), r6(this.chart, "endResize", function () {
        this.legend.positionCheckboxes();
      })), r6(this.chart, "render", () => {
        this.options.enabled && this.proximate && (this.proximatePositions(), this.positionItems());
      });
    }
    setOptions(t) {
      let e = no(t.padding, 8);
      this.options = t, this.chart.styledMode || (this.itemStyle = t.itemStyle, this.itemHiddenStyle = ns(this.itemStyle, t.itemHiddenStyle)), this.itemMarginTop = t.itemMarginTop, this.itemMarginBottom = t.itemMarginBottom, this.padding = e, this.initialItemY = e - 5, this.symbolWidth = no(t.symbolWidth, 16), this.pages = [], this.proximate = "proximate" === t.layout && !this.chart.inverted, this.baseline = void 0;
    }
    update(t, e) {
      let i = this.chart;
      this.setOptions(ns(!0, this.options, t)), "events" in this.options && r0(this, this.options), this.destroy(), i.isDirtyLegend = i.isDirtyBox = !0, no(e, !0) && i.redraw(), ne(this, "afterUpdate", {
        redraw: e
      });
    }
    colorizeItem(t, e) {
      let i = t.color,
        {
          area: s,
          group: o,
          label: r,
          line: n,
          symbol: a
        } = t.legendItem || {};
      if ((t instanceof rK || t instanceof oq) && (t.color = t.options?.legendSymbolColor || i), o?.[e ? "removeClass" : "addClass"]("highcharts-legend-item-hidden"), !this.chart.styledMode) {
        let {
            itemHiddenStyle: i = {}
          } = this,
          o = i.color,
          {
            fillColor: h,
            fillOpacity: l,
            lineColor: d,
            marker: c
          } = t.options,
          p = t => (!e && (t.fill && (t.fill = o), t.stroke && (t.stroke = o)), t);
        r?.css(ns(e ? this.itemStyle : i)), n?.attr(p({
          stroke: d || t.color
        })), a && a.attr(p(c && a.isMarker ? t.pointAttribs() : {
          fill: t.color
        })), s?.attr(p({
          fill: h || t.color,
          "fill-opacity": h ? 1 : l ?? .75
        }));
      }
      t.color = i, ne(this, "afterColorizeItem", {
        item: t,
        visible: e
      });
    }
    positionItems() {
      this.allItems.forEach(this.positionItem, this), this.chart.isResizing || this.positionCheckboxes();
    }
    positionItem(t) {
      let {
          group: e,
          x: i = 0,
          y: s = 0
        } = t.legendItem || {},
        o = this.options,
        r = o.symbolPadding,
        n = !o.rtl,
        a = t.checkbox;
      if (e && e.element) {
        let o = {
          translateX: n ? i : this.legendWidth - i - 2 * r - 4,
          translateY: s
        };
        e[r8(e.translateY) ? "animate" : "attr"](o, void 0, () => {
          ne(this, "afterPositionItem", {
            item: t
          });
        });
      }
      a && (a.x = i, a.y = s);
    }
    destroyItem(t) {
      let e = t.checkbox,
        i = t.legendItem || {};
      for (let t of ["group", "label", "line", "symbol"]) i[t] && (i[t] = i[t].destroy());
      e && r7(e), t.legendItem = void 0;
    }
    destroy() {
      for (let t of this.getAllItems()) this.destroyItem(t);
      for (let t of ["clipRect", "up", "down", "pager", "nav", "box", "title", "group"]) this[t] && (this[t] = this[t].destroy());
      this.display = null;
    }
    positionCheckboxes() {
      let t;
      let e = this.group && this.group.alignAttr,
        i = this.clipHeight || this.legendHeight,
        s = this.titleHeight;
      e && (t = e.translateY, this.allItems.forEach(function (o) {
        let r;
        let n = o.checkbox;
        n && (r = t + s + n.y + (this.scrollOffset || 0) + 3, r4(n, {
          left: e.translateX + o.checkboxOffset + n.x - 20 + "px",
          top: r + "px",
          display: this.proximate || r > t - 6 && r < t + i - 6 ? "" : "none"
        }));
      }, this));
    }
    renderTitle() {
      let t = this.options,
        e = this.padding,
        i = t.title,
        s,
        o = 0;
      i.text && (this.title || (this.title = this.chart.renderer.label(i.text, e - 3, e - 4, void 0, void 0, void 0, t.useHTML, void 0, "legend-title").attr({
        zIndex: 1
      }), this.chart.styledMode || this.title.css(i.style), this.title.add(this.group)), i.width || this.title.css({
        width: this.maxLegendWidth + "px"
      }), o = (s = this.title.getBBox()).height, this.offsetWidth = s.width, this.contentGroup.attr({
        translateY: o
      })), this.titleHeight = o;
    }
    setText(t) {
      let e = this.options;
      t.legendItem.label.attr({
        text: e.labelFormat ? r5(e.labelFormat, t, this.chart) : e.labelFormatter.call(t)
      });
    }
    renderItem(t) {
      let e = t.legendItem = t.legendItem || {},
        i = this.chart,
        s = i.renderer,
        o = this.options,
        r = "horizontal" === o.layout,
        n = this.symbolWidth,
        a = o.symbolPadding || 0,
        h = this.itemStyle,
        l = this.itemHiddenStyle,
        d = r ? no(o.itemDistance, 20) : 0,
        c = !o.rtl,
        p = !t.series,
        u = !p && t.series.drawLegendSymbol ? t.series : t,
        g = u.options,
        f = !!this.createCheckboxForItem && g && g.showCheckbox,
        m = o.useHTML,
        x = t.options.className,
        y = e.label,
        b = n + a + d + (f ? 20 : 0);
      !y && (e.group = s.g("legend-item").addClass("highcharts-" + u.type + "-series highcharts-color-" + t.colorIndex + (x ? " " + x : "") + (p ? " highcharts-series-" + t.index : "")).attr({
        zIndex: 1
      }).add(this.scrollGroup), e.label = y = s.text("", c ? n + a : -a, this.baseline || 0, m), i.styledMode || y.css(ns(t.visible ? h : l)), y.attr({
        align: c ? "left" : "right",
        zIndex: 2
      }).add(e.group), !this.baseline && (this.fontMetrics = s.fontMetrics(y), this.baseline = this.fontMetrics.f + 3 + this.itemMarginTop, y.attr("y", this.baseline), this.symbolHeight = no(o.symbolHeight, this.fontMetrics.f), o.squareSymbol && (this.symbolWidth = no(o.symbolWidth, Math.max(this.symbolHeight, 16)), b = this.symbolWidth + a + d + (f ? 20 : 0), c && y.attr("x", this.symbolWidth + a))), u.drawLegendSymbol(this, t), this.setItemEvents && this.setItemEvents(t, y, m)), f && !t.checkbox && this.createCheckboxForItem && this.createCheckboxForItem(t), this.colorizeItem(t, t.visible), (i.styledMode || !h.width) && y.css({
        width: (o.itemWidth || this.widthOption || i.spacingBox.width) - b + "px"
      }), this.setText(t);
      let v = y.getBBox(),
        M = this.fontMetrics && this.fontMetrics.h || 0;
      t.itemWidth = t.checkboxOffset = o.itemWidth || e.labelWidth || v.width + b, this.maxItemWidth = Math.max(this.maxItemWidth, t.itemWidth), this.totalItemWidth += t.itemWidth, this.itemHeight = t.itemHeight = Math.round(e.labelHeight || (v.height > 1.5 * M ? v.height : M));
    }
    layoutItem(t) {
      let e = this.options,
        i = this.padding,
        s = "horizontal" === e.layout,
        o = t.itemHeight,
        r = this.itemMarginBottom,
        n = this.itemMarginTop,
        a = s ? no(e.itemDistance, 20) : 0,
        h = this.maxLegendWidth,
        l = e.alignColumns && this.totalItemWidth > h ? this.maxItemWidth : t.itemWidth,
        d = t.legendItem || {};
      s && this.itemX - i + l > h && (this.itemX = i, this.lastLineHeight && (this.itemY += n + this.lastLineHeight + r), this.lastLineHeight = 0), this.lastItemY = n + this.itemY + r, this.lastLineHeight = Math.max(o, this.lastLineHeight), d.x = this.itemX, d.y = this.itemY, s ? this.itemX += l : (this.itemY += n + o + r, this.lastLineHeight = o), this.offsetWidth = this.widthOption || Math.max((s ? this.itemX - i - (t.checkbox ? 0 : a) : l) + i, this.offsetWidth);
    }
    getAllItems() {
      let t = [];
      return this.chart.series.forEach(function (e) {
        let i = e && e.options;
        e && no(i.showInLegend, !r8(i.linkedTo) && void 0, !0) && (t = t.concat((e.legendItem || {}).labels || ("point" === i.legendType ? e.data : e)));
      }), ne(this, "afterGetAllItems", {
        allItems: t
      }), t;
    }
    getAlignment() {
      let t = this.options;
      return this.proximate ? t.align.charAt(0) + "tv" : t.floating ? "" : t.align.charAt(0) + t.verticalAlign.charAt(0) + t.layout.charAt(0);
    }
    adjustMargins(t, e) {
      let i = this.chart,
        s = this.options,
        o = this.getAlignment();
      o && [/(lth|ct|rth)/, /(rtv|rm|rbv)/, /(rbh|cb|lbh)/, /(lbv|lm|ltv)/].forEach(function (r, n) {
        r.test(o) && !r8(t[n]) && (i[r2[n]] = Math.max(i[r2[n]], i.legend[(n + 1) % 2 ? "legendHeight" : "legendWidth"] + [1, -1, -1, 1][n] * s[n % 2 ? "x" : "y"] + no(s.margin, 12) + e[n] + (i.titleOffset[n] || 0)));
      });
    }
    proximatePositions() {
      let t;
      let e = this.chart,
        i = [],
        s = "left" === this.options.align;
      for (let o of (this.allItems.forEach(function (t) {
        let o,
          r,
          n = s,
          a,
          h;
        t.yAxis && (t.xAxis.options.reversed && (n = !n), t.points && (o = nt(n ? t.points : t.points.slice(0).reverse(), function (t) {
          return ni(t.plotY);
        })), r = this.itemMarginTop + t.legendItem.label.getBBox().height + this.itemMarginBottom, h = t.yAxis.top - e.plotTop, a = t.visible ? (o ? o.plotY : t.yAxis.height) + (h - .3 * r) : h + t.yAxis.height, i.push({
          target: a,
          size: r,
          item: t
        }));
      }, this), r3(i, e.plotHeight))) t = o.item.legendItem || {}, ni(o.pos) && (t.y = e.plotTop - e.spacing[0] + o.pos);
    }
    render() {
      let t = this.chart,
        e = t.renderer,
        i = this.options,
        s = this.padding,
        o = this.getAllItems(),
        r,
        n,
        a,
        h = this.group,
        l,
        d = this.box;
      this.itemX = s, this.itemY = this.initialItemY, this.offsetWidth = 0, this.lastItemY = 0, this.widthOption = nn(i.width, t.spacingBox.width - s), l = t.spacingBox.width - 2 * s - i.x, ["rm", "lm"].indexOf(this.getAlignment().substring(0, 2)) > -1 && (l /= 2), this.maxLegendWidth = this.widthOption || l, h || (this.group = h = e.g("legend").addClass(i.className || "").attr({
        zIndex: 7
      }).add(), this.contentGroup = e.g().attr({
        zIndex: 1
      }).add(h), this.scrollGroup = e.g().add(this.contentGroup)), this.renderTitle(), na(o, (t, e) => (t.options && t.options.legendIndex || 0) - (e.options && e.options.legendIndex || 0)), i.reversed && o.reverse(), this.allItems = o, this.display = r = !!o.length, this.lastLineHeight = 0, this.maxItemWidth = 0, this.totalItemWidth = 0, this.itemHeight = 0, o.forEach(this.renderItem, this), o.forEach(this.layoutItem, this), n = (this.widthOption || this.offsetWidth) + s, a = this.lastItemY + this.lastLineHeight + this.titleHeight, a = this.handleOverflow(a) + s, d || (this.box = d = e.rect().addClass("highcharts-legend-box").attr({
        r: i.borderRadius
      }).add(h)), t.styledMode || d.attr({
        stroke: i.borderColor,
        "stroke-width": i.borderWidth || 0,
        fill: i.backgroundColor || "none"
      }).shadow(i.shadow), n > 0 && a > 0 && d[d.placed ? "animate" : "attr"](d.crisp.call({}, {
        x: 0,
        y: 0,
        width: n,
        height: a
      }, d.strokeWidth())), h[r ? "show" : "hide"](), t.styledMode && "none" === h.getStyle("display") && (n = a = 0), this.legendWidth = n, this.legendHeight = a, r && this.align(), this.proximate || this.positionItems(), ne(this, "afterRender");
    }
    align(t = this.chart.spacingBox) {
      let e = this.chart,
        i = this.options,
        s = t.y;
      /(lth|ct|rth)/.test(this.getAlignment()) && e.titleOffset[0] > 0 ? s += e.titleOffset[0] : /(lbh|cb|rbh)/.test(this.getAlignment()) && e.titleOffset[2] > 0 && (s -= e.titleOffset[2]), s !== t.y && (t = ns(t, {
        y: s
      })), e.hasRendered || (this.group.placed = !1), this.group.align(ns(i, {
        width: this.legendWidth,
        height: this.legendHeight,
        verticalAlign: this.proximate ? "top" : i.verticalAlign
      }), !0, t);
    }
    handleOverflow(t) {
      let e = this,
        i = this.chart,
        s = i.renderer,
        o = this.options,
        r = o.y,
        n = "top" === o.verticalAlign,
        a = this.padding,
        h = o.maxHeight,
        l = o.navigation,
        d = no(l.animation, !0),
        c = l.arrowSize || 12,
        p = this.pages,
        u = this.allItems,
        g = function (t) {
          "number" == typeof t ? M.attr({
            height: t
          }) : M && (e.clipRect = M.destroy(), e.contentGroup.clip()), e.contentGroup.div && (e.contentGroup.div.style.clip = t ? "rect(" + a + "px,9999px," + (a + t) + "px,0)" : "auto");
        },
        f = function (t) {
          return e[t] = s.circle(0, 0, 1.3 * c).translate(c / 2, c / 2).add(v), i.styledMode || e[t].attr("fill", "rgba(0,0,0,0.0001)"), e[t];
        },
        m,
        x,
        y,
        b = i.spacingBox.height + (n ? -r : r) - a,
        v = this.nav,
        M = this.clipRect;
      return "horizontal" !== o.layout || "middle" === o.verticalAlign || o.floating || (b /= 2), h && (b = Math.min(b, h)), p.length = 0, t && b > 0 && t > b && !1 !== l.enabled ? (this.clipHeight = m = Math.max(b - 20 - this.titleHeight - a, 0), this.currentPage = no(this.currentPage, 1), this.fullHeight = t, u.forEach((t, e) => {
        let i = (y = t.legendItem || {}).y || 0,
          s = Math.round(y.label.getBBox().height),
          o = p.length;
        (!o || i - p[o - 1] > m && (x || i) !== p[o - 1]) && (p.push(x || i), o++), y.pageIx = o - 1, x && ((u[e - 1].legendItem || {}).pageIx = o - 1), e === u.length - 1 && i + s - p[o - 1] > m && i > p[o - 1] && (p.push(i), y.pageIx = o), i !== x && (x = i);
      }), M || (M = e.clipRect = s.clipRect(0, a - 2, 9999, 0), e.contentGroup.clip(M)), g(m), v || (this.nav = v = s.g().attr({
        zIndex: 1
      }).add(this.group), this.up = s.symbol("triangle", 0, 0, c, c).add(v), f("upTracker").on("click", function () {
        e.scroll(-1, d);
      }), this.pager = s.text("", 15, 10).addClass("highcharts-legend-navigation"), !i.styledMode && l.style && this.pager.css(l.style), this.pager.add(v), this.down = s.symbol("triangle-down", 0, 0, c, c).add(v), f("downTracker").on("click", function () {
        e.scroll(1, d);
      })), e.scroll(0), t = b) : v && (g(), this.nav = v.destroy(), this.scrollGroup.attr({
        translateY: 1
      }), this.clipHeight = 0), t;
    }
    scroll(t, e) {
      let i = this.chart,
        s = this.pages,
        o = s.length,
        r = this.clipHeight,
        n = this.options.navigation,
        a = this.pager,
        h = this.padding,
        l = this.currentPage + t;
      l > o && (l = o), l > 0 && (void 0 !== e && rQ(e, i), this.nav.attr({
        translateX: h,
        translateY: r + this.padding + 7 + this.titleHeight,
        visibility: "inherit"
      }), [this.up, this.upTracker].forEach(function (t) {
        t.attr({
          class: 1 === l ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        });
      }), a.attr({
        text: l + "/" + o
      }), [this.down, this.downTracker].forEach(function (t) {
        t.attr({
          x: 18 + this.pager.getBBox().width,
          class: l === o ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        });
      }, this), i.styledMode || (this.up.attr({
        fill: 1 === l ? n.inactiveColor : n.activeColor
      }), this.upTracker.css({
        cursor: 1 === l ? "default" : "pointer"
      }), this.down.attr({
        fill: l === o ? n.inactiveColor : n.activeColor
      }), this.downTracker.css({
        cursor: l === o ? "default" : "pointer"
      })), this.scrollOffset = -s[l - 1] + this.initialItemY, this.scrollGroup.animate({
        translateY: this.scrollOffset
      }), this.currentPage = l, this.positionCheckboxes(), nh(() => {
        ne(this, "afterScroll", {
          currentPage: l
        });
      }, rJ(no(e, i.renderer.globalAnimation, !0)).duration));
    }
    setItemEvents(t, e, i) {
      let s = this,
        o = t.legendItem || {},
        r = s.chart.renderer.boxWrapper,
        n = t instanceof oq,
        a = t instanceof rK,
        h = "highcharts-legend-" + (n ? "point" : "series") + "-active",
        l = s.chart.styledMode,
        d = i ? [e, o.symbol] : [o.group],
        c = e => {
          s.allItems.forEach(i => {
            t !== i && [i].concat(i.linkedSeries || []).forEach(t => {
              t.setState(e, !n);
            });
          });
        };
      for (let i of d) i && i.on("mouseover", function () {
        t.visible && c("inactive"), t.setState("hover"), t.visible && r.addClass(h), l || e.css(s.options.itemHoverStyle);
      }).on("mouseout", function () {
        s.chart.styledMode || e.css(ns(t.visible ? s.itemStyle : s.itemHiddenStyle)), c(""), r.removeClass(h), t.setState();
      }).on("click", function (e) {
        let i = function () {
          t.setVisible && t.setVisible(), c(t.visible ? "inactive" : "");
        };
        r.removeClass(h), ne(s, "itemClick", {
          browserEvent: e,
          legendItem: t
        }, i), n ? t.firePointEvent("legendItemClick", {
          browserEvent: e
        }) : a && ne(t, "legendItemClick", {
          browserEvent: e
        });
      });
    }
    createCheckboxForItem(t) {
      t.checkbox = r9("input", {
        type: "checkbox",
        className: "highcharts-legend-checkbox",
        checked: t.selected,
        defaultChecked: t.selected
      }, this.options.itemCheckboxStyle, this.chart.container), r6(t.checkbox, "click", function (e) {
        let i = e.target;
        ne(t.series || t, "checkboxClick", {
          checked: i.checked,
          item: t
        }, function () {
          t.select();
        });
      });
    }
  }
  !function (t) {
    t.compose = function (e) {
      nr(r1, "Core.Legend") && r6(e, "beforeMargins", function () {
        this.legend = new t(this, this.options.legend);
      });
    };
  }(nl || (nl = {}));
  let nd = nl,
    {
      animate: nc,
      animObject: np,
      setAnimation: nu
    } = tU,
    {
      defaultOptions: ng
    } = tS,
    {
      numberFormat: nf
    } = ep,
    {
      registerEventOptions: nm
    } = se,
    {
      charts: nx,
      doc: ny,
      marginNames: nb,
      svg: nv,
      win: nM
    } = O,
    {
      seriesTypes: nk
    } = ry,
    {
      addEvent: nw,
      attr: nS,
      createElement: nA,
      css: nT,
      defined: nP,
      diffObjects: nC,
      discardElement: nO,
      erase: nE,
      error: nL,
      extend: nB,
      find: nI,
      fireEvent: nD,
      getAlignFactor: nR,
      getStyle: nN,
      isArray: nz,
      isNumber: nW,
      isObject: nG,
      isString: nH,
      merge: nF,
      objectEach: nX,
      pick: nY,
      pInt: nj,
      relativeLength: nU,
      removeEvent: nV,
      splat: n$,
      syncTimeout: n_,
      uniqueKey: nq
    } = tt;
  class nZ {
    static chart(t, e, i) {
      return new nZ(t, e, i);
    }
    constructor(t, e, i) {
      this.sharedClips = {};
      let s = [...arguments];
      (nH(t) || t.nodeName) && (this.renderTo = s.shift()), this.init(s[0], s[1]);
    }
    setZoomOptions() {
      let t = this.options.chart,
        e = t.zooming;
      this.zooming = {
        ...e,
        type: nY(t.zoomType, e.type),
        key: nY(t.zoomKey, e.key),
        pinchType: nY(t.pinchType, e.pinchType),
        singleTouch: nY(t.zoomBySingleTouch, e.singleTouch, !1),
        resetButton: nF(e.resetButton, t.resetZoomButton)
      };
    }
    init(t, e) {
      nD(this, "init", {
        args: arguments
      }, function () {
        let i = nF(ng, t),
          s = i.chart,
          o = this.renderTo || s.renderTo;
        this.userOptions = nB({}, t), (this.renderTo = nH(o) ? ny.getElementById(o) : o) || nL(13, !0, this), this.margin = [], this.spacing = [], this.labelCollectors = [], this.callback = e, this.isResizing = 0, this.options = i, this.axes = [], this.series = [], this.locale = i.lang.locale ?? this.renderTo.closest("[lang]")?.lang, this.time = new ty(nB(i.time || {}, {
          locale: this.locale
        })), i.time = this.time.options, this.numberFormatter = (s.numberFormatter || nf).bind(this), this.styledMode = s.styledMode, this.hasCartesianSeries = s.showAxes, this.index = nx.length, nx.push(this), O.chartCount++, nm(this, s), this.xAxis = [], this.yAxis = [], this.pointCount = this.colorCounter = this.symbolCounter = 0, this.setZoomOptions(), nD(this, "afterInit"), this.firstRender();
      });
    }
    initSeries(t) {
      let e = this.options.chart,
        i = t.type || e.type,
        s = nk[i];
      s || nL(17, !0, this, {
        missingModuleFor: i
      });
      let o = new s();
      return "function" == typeof o.init && o.init(this, t), o;
    }
    setSortedData() {
      this.getSeriesOrderByLinks().forEach(function (t) {
        t.points || t.data || !t.enabledDataSorting || t.setData(t.options.data, !1);
      });
    }
    getSeriesOrderByLinks() {
      return this.series.concat().sort(function (t, e) {
        return t.linkedSeries.length || e.linkedSeries.length ? e.linkedSeries.length - t.linkedSeries.length : 0;
      });
    }
    orderItems(t, e = 0) {
      let i = this[t],
        s = this.options[t] = n$(this.options[t]).slice(),
        o = this.userOptions[t] = this.userOptions[t] ? n$(this.userOptions[t]).slice() : [];
      if (this.hasRendered && (s.splice(e), o.splice(e)), i) for (let t = e, r = i.length; t < r; ++t) {
        let e = i[t];
        e && (e.index = t, e instanceof rK && (e.name = e.getName()), e.options.isInternal || (s[t] = e.options, o[t] = e.userOptions));
      }
    }
    isInsidePlot(t, e, i = {}) {
      let {
          inverted: s,
          plotBox: o,
          plotLeft: r,
          plotTop: n,
          scrollablePlotBox: a
        } = this,
        {
          scrollLeft: h = 0,
          scrollTop: l = 0
        } = i.visiblePlotOnly && this.scrollablePlotArea?.scrollingContainer || {},
        d = i.series,
        c = i.visiblePlotOnly && a || o,
        p = i.inverted ? e : t,
        u = i.inverted ? t : e,
        g = {
          x: p,
          y: u,
          isInsidePlot: !0,
          options: i
        };
      if (!i.ignoreX) {
        let t = d && (s && !this.polar ? d.yAxis : d.xAxis) || {
            pos: r,
            len: 1 / 0
          },
          e = i.paneCoordinates ? t.pos + p : r + p;
        e >= Math.max(h + r, t.pos) && e <= Math.min(h + r + c.width, t.pos + t.len) || (g.isInsidePlot = !1);
      }
      if (!i.ignoreY && g.isInsidePlot) {
        let t = !s && i.axis && !i.axis.isXAxis && i.axis || d && (s ? d.xAxis : d.yAxis) || {
            pos: n,
            len: 1 / 0
          },
          e = i.paneCoordinates ? t.pos + u : n + u;
        e >= Math.max(l + n, t.pos) && e <= Math.min(l + n + c.height, t.pos + t.len) || (g.isInsidePlot = !1);
      }
      return nD(this, "afterIsInsidePlot", g), g.isInsidePlot;
    }
    redraw(t) {
      nD(this, "beforeRedraw");
      let e = this.hasCartesianSeries ? this.axes : this.colorAxis || [],
        i = this.series,
        s = this.pointer,
        o = this.legend,
        r = this.userOptions.legend,
        n = this.renderer,
        a = n.isHidden(),
        h = [],
        l,
        d,
        c,
        p = this.isDirtyBox,
        u = this.isDirtyLegend,
        g;
      for (n.rootFontSize = n.boxWrapper.getStyle("font-size"), this.setResponsive && this.setResponsive(!1), nu(!!this.hasRendered && t, this), a && this.temporaryDisplay(), this.layOutTitles(!1), c = i.length; c--;) if (((g = i[c]).options.stacking || g.options.centerInCategory) && (d = !0, g.isDirty)) {
        l = !0;
        break;
      }
      if (l) for (c = i.length; c--;) (g = i[c]).options.stacking && (g.isDirty = !0);
      i.forEach(function (t) {
        t.isDirty && ("point" === t.options.legendType ? ("function" == typeof t.updateTotals && t.updateTotals(), u = !0) : r && (r.labelFormatter || r.labelFormat) && (u = !0)), t.isDirtyData && nD(t, "updatedData");
      }), u && o && o.options.enabled && (o.render(), this.isDirtyLegend = !1), d && this.getStacks(), e.forEach(function (t) {
        t.updateNames(), t.setScale();
      }), this.getMargins(), e.forEach(function (t) {
        t.isDirty && (p = !0);
      }), e.forEach(function (t) {
        let e = t.min + "," + t.max;
        t.extKey !== e && (t.extKey = e, h.push(function () {
          nD(t, "afterSetExtremes", nB(t.eventArgs, t.getExtremes())), delete t.eventArgs;
        })), (p || d) && t.redraw();
      }), p && this.drawChartBox(), nD(this, "predraw"), i.forEach(function (t) {
        (p || t.isDirty) && t.visible && t.redraw(), t.isDirtyData = !1;
      }), s && s.reset(!0), n.draw(), nD(this, "redraw"), nD(this, "render"), a && this.temporaryDisplay(!0), h.forEach(function (t) {
        t.call();
      });
    }
    get(t) {
      let e = this.series;
      function i(e) {
        return e.id === t || e.options && e.options.id === t;
      }
      let s = nI(this.axes, i) || nI(this.series, i);
      for (let t = 0; !s && t < e.length; t++) s = nI(e[t].points || [], i);
      return s;
    }
    createAxes() {
      let t = this.userOptions;
      for (let e of (nD(this, "createAxes"), ["xAxis", "yAxis"])) for (let i of t[e] = n$(t[e] || {})) new sU(this, i, e);
      nD(this, "afterCreateAxes");
    }
    getSelectedPoints() {
      return this.series.reduce((t, e) => (e.getPointsCollection().forEach(e => {
        nY(e.selectedStaging, e.selected) && t.push(e);
      }), t), []);
    }
    getSelectedSeries() {
      return this.series.filter(t => t.selected);
    }
    setTitle(t, e, i) {
      this.applyDescription("title", t), this.applyDescription("subtitle", e), this.applyDescription("caption", void 0), this.layOutTitles(i);
    }
    applyDescription(t, e) {
      let i = this,
        s = this.options[t] = nF(this.options[t], e),
        o = this[t];
      o && e && (this[t] = o = o.destroy()), s && !o && ((o = this.renderer.text(s.text, 0, 0, s.useHTML).attr({
        align: s.align,
        class: "highcharts-" + t,
        zIndex: s.zIndex || 4
      }).css({
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }).add()).update = function (e, s) {
        i.applyDescription(t, e), i.layOutTitles(s);
      }, this.styledMode || o.css(nB("title" === t ? {
        fontSize: this.options.isStock ? "1em" : "1.2em"
      } : {}, s.style)), o.textPxLength = o.getBBox().width, o.css({
        whiteSpace: s.style?.whiteSpace
      }), this[t] = o);
    }
    layOutTitles(t = !0) {
      let e = [0, 0, 0],
        {
          options: i,
          renderer: s,
          spacingBox: o
        } = this;
      ["title", "subtitle", "caption"].forEach(t => {
        let i = this[t],
          r = this.options[t],
          n = nF(o),
          a = i?.textPxLength || 0;
        if (i && r) {
          nD(this, "layOutTitle", {
            alignTo: n,
            key: t,
            textPxLength: a
          });
          let o = s.fontMetrics(i),
            h = o.b,
            l = o.h,
            d = r.verticalAlign || "top",
            c = "top" === d,
            p = c && r.minScale || 1,
            u = "title" === t ? c ? -3 : 0 : c ? e[0] + 2 : 0,
            g = Math.min(n.width / a, 1),
            f = Math.max(p, g),
            m = nF({
              y: "bottom" === d ? h : u + h
            }, {
              align: "title" === t ? g < p ? "left" : "center" : this.title?.alignValue
            }, r),
            x = r.width || (g > p ? this.chartWidth : n.width) / f;
          i.alignValue !== m.align && (i.placed = !1);
          let y = Math.round(i.css({
            width: `${x}px`
          }).getBBox(r.useHTML).height);
          if (m.height = y, i.align(m, !1, n).attr({
            align: m.align,
            scaleX: f,
            scaleY: f,
            "transform-origin": `${n.x + a * f * nR(m.align)} ${l}`
          }), !r.floating) {
            let t = y * (y < 1.2 * l ? 1 : f);
            "top" === d ? e[0] = Math.ceil(e[0] + t) : "bottom" === d && (e[2] = Math.ceil(e[2] + t));
          }
        }
      }, this), e[0] && "top" === (i.title?.verticalAlign || "top") && (e[0] += i.title?.margin || 0), e[2] && i.caption?.verticalAlign === "bottom" && (e[2] += i.caption?.margin || 0);
      let r = !this.titleOffset || this.titleOffset.join(",") !== e.join(",");
      this.titleOffset = e, nD(this, "afterLayOutTitles"), !this.isDirtyBox && r && (this.isDirtyBox = this.isDirtyLegend = r, this.hasRendered && t && this.isDirtyBox && this.redraw());
    }
    getContainerBox() {
      let t = [].map.call(this.renderTo.children, t => {
          if (t !== this.container) {
            let e = t.style.display;
            return t.style.display = "none", [t, e];
          }
        }),
        e = {
          width: nN(this.renderTo, "width", !0) || 0,
          height: nN(this.renderTo, "height", !0) || 0
        };
      return t.filter(Boolean).forEach(([t, e]) => {
        t.style.display = e;
      }), e;
    }
    getChartSize() {
      let t = this.options.chart,
        e = t.width,
        i = t.height,
        s = this.getContainerBox(),
        o = s.height <= 1 || !this.renderTo.parentElement?.style.height && "100%" === this.renderTo.style.height;
      this.chartWidth = Math.max(0, e || s.width || 600), this.chartHeight = Math.max(0, nU(i, this.chartWidth) || (o ? 400 : s.height)), this.containerBox = s;
    }
    temporaryDisplay(t) {
      let e = this.renderTo,
        i;
      if (t) for (; e && e.style;) e.hcOrigStyle && (nT(e, e.hcOrigStyle), delete e.hcOrigStyle), e.hcOrigDetached && (ny.body.removeChild(e), e.hcOrigDetached = !1), e = e.parentNode;else for (; e && e.style && (ny.body.contains(e) || e.parentNode || (e.hcOrigDetached = !0, ny.body.appendChild(e)), ("none" === nN(e, "display", !1) || e.hcOricDetached) && (e.hcOrigStyle = {
        display: e.style.display,
        height: e.style.height,
        overflow: e.style.overflow
      }, i = {
        display: "block",
        overflow: "hidden"
      }, e !== this.renderTo && (i.height = 0), nT(e, i), e.offsetWidth || e.style.setProperty("display", "block", "important")), (e = e.parentNode) !== ny.body););
    }
    setClassName(t) {
      this.container.className = "highcharts-container " + (t || "");
    }
    getContainer() {
      let t;
      let e = this.options,
        i = e.chart,
        s = "data-highcharts-chart",
        o = nq(),
        r = this.renderTo,
        n = nj(nS(r, s));
      nW(n) && nx[n] && nx[n].hasRendered && nx[n].destroy(), nS(r, s, this.index), r.innerHTML = t6.emptyHTML, i.skipClone || r.offsetWidth || this.temporaryDisplay(), this.getChartSize();
      let a = this.chartHeight,
        h = this.chartWidth;
      nT(r, {
        overflow: "hidden"
      }), this.styledMode || (t = nB({
        position: "relative",
        overflow: "hidden",
        width: h + "px",
        height: a + "px",
        textAlign: "left",
        lineHeight: "normal",
        zIndex: 0,
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
        userSelect: "none",
        "touch-action": "manipulation",
        outline: "none",
        padding: "0px"
      }, i.style || {}));
      let l = nA("div", {
        id: o
      }, t, r);
      this.container = l, this.getChartSize(), h === this.chartWidth || (h = this.chartWidth, this.styledMode || nT(l, {
        width: nY(i.style?.width, h + "px")
      })), this.containerBox = this.getContainerBox(), this._cursor = l.style.cursor;
      let d = i.renderer || !nv ? eu.getRendererType(i.renderer) : iU;
      if (this.renderer = new d(l, h, a, void 0, i.forExport, e.exporting && e.exporting.allowHTML, this.styledMode), nu(void 0, this), this.setClassName(i.className), this.styledMode) for (let t in e.defs) this.renderer.definition(e.defs[t]);else this.renderer.setStyle(i.style);
      this.renderer.chartIndex = this.index, nD(this, "afterGetContainer");
    }
    getMargins(t) {
      let {
        spacing: e,
        margin: i,
        titleOffset: s
      } = this;
      this.resetMargins(), s[0] && !nP(i[0]) && (this.plotTop = Math.max(this.plotTop, s[0] + e[0])), s[2] && !nP(i[2]) && (this.marginBottom = Math.max(this.marginBottom, s[2] + e[2])), this.legend && this.legend.display && this.legend.adjustMargins(i, e), nD(this, "getMargins"), t || this.getAxisMargins();
    }
    getAxisMargins() {
      let t = this,
        e = t.axisOffset = [0, 0, 0, 0],
        i = t.colorAxis,
        s = t.margin,
        o = function (t) {
          t.forEach(function (t) {
            t.visible && t.getOffset();
          });
        };
      t.hasCartesianSeries ? o(t.axes) : i && i.length && o(i), nb.forEach(function (i, o) {
        nP(s[o]) || (t[i] += e[o]);
      }), t.setChartSize();
    }
    getOptions() {
      return nC(this.userOptions, ng);
    }
    reflow(t) {
      let e = this,
        i = e.containerBox,
        s = e.getContainerBox();
      delete e.pointer?.chartPosition, !e.isPrinting && !e.isResizing && i && s.width && ((s.width !== i.width || s.height !== i.height) && (tt.clearTimeout(e.reflowTimeout), e.reflowTimeout = n_(function () {
        e.container && e.setSize(void 0, void 0, !1);
      }, t ? 100 : 0)), e.containerBox = s);
    }
    setReflow() {
      let t = this,
        e = e => {
          t.options?.chart.reflow && t.hasLoaded && t.reflow(e);
        };
      if ("function" == typeof ResizeObserver) new ResizeObserver(e).observe(t.renderTo);else {
        let t = nw(nM, "resize", e);
        nw(this, "destroy", t);
      }
    }
    setSize(t, e, i) {
      let s = this,
        o = s.renderer;
      s.isResizing += 1, nu(i, s);
      let r = o.globalAnimation;
      s.oldChartHeight = s.chartHeight, s.oldChartWidth = s.chartWidth, void 0 !== t && (s.options.chart.width = t), void 0 !== e && (s.options.chart.height = e), s.getChartSize();
      let {
        chartWidth: n,
        chartHeight: a,
        scrollablePixelsX: h = 0,
        scrollablePixelsY: l = 0
      } = s;
      (s.isDirtyBox || n !== s.oldChartWidth || a !== s.oldChartHeight) && (s.styledMode || (r ? nc : nT)(s.container, {
        width: `${n + h}px`,
        height: `${a + l}px`
      }, r), s.setChartSize(!0), o.setSize(n, a, r), s.axes.forEach(function (t) {
        t.isDirty = !0, t.setScale();
      }), s.isDirtyLegend = !0, s.isDirtyBox = !0, s.layOutTitles(), s.getMargins(), s.redraw(r), s.oldChartHeight = void 0, nD(s, "resize"), setTimeout(() => {
        s && nD(s, "endResize");
      }, np(r).duration)), s.isResizing -= 1;
    }
    setChartSize(t) {
      let e, i, s, o;
      let {
          chartHeight: r,
          chartWidth: n,
          inverted: a,
          spacing: h,
          renderer: l
        } = this,
        d = this.clipOffset,
        c = Math[a ? "floor" : "round"];
      this.plotLeft = e = Math.round(this.plotLeft), this.plotTop = i = Math.round(this.plotTop), this.plotWidth = s = Math.max(0, Math.round(n - e - this.marginRight)), this.plotHeight = o = Math.max(0, Math.round(r - i - this.marginBottom)), this.plotSizeX = a ? o : s, this.plotSizeY = a ? s : o, this.spacingBox = l.spacingBox = {
        x: h[3],
        y: h[0],
        width: n - h[3] - h[1],
        height: r - h[0] - h[2]
      }, this.plotBox = l.plotBox = {
        x: e,
        y: i,
        width: s,
        height: o
      }, d && (this.clipBox = {
        x: c(d[3]),
        y: c(d[0]),
        width: c(this.plotSizeX - d[1] - d[3]),
        height: c(this.plotSizeY - d[0] - d[2])
      }), t || (this.axes.forEach(function (t) {
        t.setAxisSize(), t.setAxisTranslation();
      }), l.alignElements()), nD(this, "afterSetChartSize", {
        skipAxes: t
      });
    }
    resetMargins() {
      nD(this, "resetMargins");
      let t = this,
        e = t.options.chart,
        i = e.plotBorderWidth || 0,
        s = i / 2;
      ["margin", "spacing"].forEach(function (i) {
        let s = e[i],
          o = nG(s) ? s : [s, s, s, s];
        ["Top", "Right", "Bottom", "Left"].forEach(function (s, r) {
          t[i][r] = nY(e[i + s], o[r]);
        });
      }), nb.forEach(function (e, i) {
        t[e] = nY(t.margin[i], t.spacing[i]);
      }), t.axisOffset = [0, 0, 0, 0], t.clipOffset = [s, s, s, s], t.plotBorderWidth = i;
    }
    drawChartBox() {
      let t = this.options.chart,
        e = this.renderer,
        i = this.chartWidth,
        s = this.chartHeight,
        o = this.styledMode,
        r = this.plotBGImage,
        n = t.backgroundColor,
        a = t.plotBackgroundColor,
        h = t.plotBackgroundImage,
        l = this.plotLeft,
        d = this.plotTop,
        c = this.plotWidth,
        p = this.plotHeight,
        u = this.plotBox,
        g = this.clipRect,
        f = this.clipBox,
        m = this.chartBackground,
        x = this.plotBackground,
        y = this.plotBorder,
        b,
        v,
        M,
        k = "animate";
      m || (this.chartBackground = m = e.rect().addClass("highcharts-background").add(), k = "attr"), o ? b = v = m.strokeWidth() : (v = (b = t.borderWidth || 0) + (t.shadow ? 8 : 0), M = {
        fill: n || "none"
      }, (b || m["stroke-width"]) && (M.stroke = t.borderColor, M["stroke-width"] = b), m.attr(M).shadow(t.shadow)), m[k]({
        x: v / 2,
        y: v / 2,
        width: i - v - b % 2,
        height: s - v - b % 2,
        r: t.borderRadius
      }), k = "animate", x || (k = "attr", this.plotBackground = x = e.rect().addClass("highcharts-plot-background").add()), x[k](u), !o && (x.attr({
        fill: a || "none"
      }).shadow(t.plotShadow), h && (r ? (h !== r.attr("href") && r.attr("href", h), r.animate(u)) : this.plotBGImage = e.image(h, l, d, c, p).add())), g ? g.animate({
        width: f.width,
        height: f.height
      }) : this.clipRect = e.clipRect(f), k = "animate", y || (k = "attr", this.plotBorder = y = e.rect().addClass("highcharts-plot-border").attr({
        zIndex: 1
      }).add()), o || y.attr({
        stroke: t.plotBorderColor,
        "stroke-width": t.plotBorderWidth || 0,
        fill: "none"
      }), y[k](y.crisp({
        x: l,
        y: d,
        width: c,
        height: p
      }, -y.strokeWidth())), this.isDirtyBox = !1, nD(this, "afterDrawChartBox");
    }
    propFromSeries() {
      let t, e, i;
      let s = this,
        o = s.options.chart,
        r = s.options.series;
      ["inverted", "angular", "polar"].forEach(function (n) {
        for (e = nk[o.type], i = o[n] || e && e.prototype[n], t = r && r.length; !i && t--;) (e = nk[r[t].type]) && e.prototype[n] && (i = !0);
        s[n] = i;
      });
    }
    linkSeries(t) {
      let e = this,
        i = e.series;
      i.forEach(function (t) {
        t.linkedSeries.length = 0;
      }), i.forEach(function (t) {
        let {
          linkedTo: i
        } = t.options;
        if (nH(i)) {
          let s;
          (s = ":previous" === i ? e.series[t.index - 1] : e.get(i)) && s.linkedParent !== t && (s.linkedSeries.push(t), t.linkedParent = s, s.enabledDataSorting && t.setDataSortingOptions(), t.visible = nY(t.options.visible, s.options.visible, t.visible));
        }
      }), nD(this, "afterLinkSeries", {
        isUpdating: t
      });
    }
    renderSeries() {
      this.series.forEach(function (t) {
        t.translate(), t.render();
      });
    }
    render() {
      let t = this.axes,
        e = this.colorAxis,
        i = this.renderer,
        s = this.options.chart.axisLayoutRuns || 2,
        o = t => {
          t.forEach(t => {
            t.visible && t.render();
          });
        },
        r = 0,
        n = !0,
        a,
        h = 0;
      for (let e of (this.setTitle(), nD(this, "beforeMargins"), this.getStacks?.(), this.getMargins(!0), this.setChartSize(), t)) {
        let {
            options: t
          } = e,
          {
            labels: i
          } = t;
        if (this.hasCartesianSeries && e.horiz && e.visible && i.enabled && e.series.length && "colorAxis" !== e.coll && !this.polar) {
          r = t.tickLength, e.createGroups();
          let s = new sg(e, 0, "", !0),
            o = s.createLabel("x", i);
          if (s.destroy(), o && nY(i.reserveSpace, !nW(t.crossing)) && (r = o.getBBox().height + i.distance + Math.max(t.offset || 0, 0)), r) {
            o?.destroy();
            break;
          }
        }
      }
      for (this.plotHeight = Math.max(this.plotHeight - r, 0); (n || a || s > 1) && h < s;) {
        let e = this.plotWidth,
          i = this.plotHeight;
        for (let e of t) 0 === h ? e.setScale() : (e.horiz && n || !e.horiz && a) && e.setTickInterval(!0);
        0 === h ? this.getAxisMargins() : this.getMargins(), n = e / this.plotWidth > (h ? 1 : 1.1), a = i / this.plotHeight > (h ? 1 : 1.05), h++;
      }
      this.drawChartBox(), this.hasCartesianSeries ? o(t) : e && e.length && o(e), this.seriesGroup || (this.seriesGroup = i.g("series-group").attr({
        zIndex: 3
      }).shadow(this.options.chart.seriesGroupShadow).add()), this.renderSeries(), this.addCredits(), this.setResponsive && this.setResponsive(), this.hasRendered = !0;
    }
    addCredits(t) {
      let e = this,
        i = nF(!0, this.options.credits, t);
      i.enabled && !this.credits && (this.credits = this.renderer.text(i.text + (this.mapCredits || ""), 0, 0).addClass("highcharts-credits").on("click", function () {
        i.href && (nM.location.href = i.href);
      }).attr({
        align: i.position.align,
        zIndex: 8
      }), e.styledMode || this.credits.css(i.style), this.credits.add().align(i.position), this.credits.update = function (t) {
        e.credits = e.credits.destroy(), e.addCredits(t);
      });
    }
    destroy() {
      let t;
      let e = this,
        i = e.axes,
        s = e.series,
        o = e.container,
        r = o && o.parentNode;
      for (nD(e, "destroy"), e.renderer.forExport ? nE(nx, e) : nx[e.index] = void 0, O.chartCount--, e.renderTo.removeAttribute("data-highcharts-chart"), nV(e), t = i.length; t--;) i[t] = i[t].destroy();
      for (this.scroller && this.scroller.destroy && this.scroller.destroy(), t = s.length; t--;) s[t] = s[t].destroy();
      ["title", "subtitle", "chartBackground", "plotBackground", "plotBGImage", "plotBorder", "seriesGroup", "clipRect", "credits", "pointer", "rangeSelector", "legend", "resetZoomButton", "tooltip", "renderer"].forEach(function (t) {
        let i = e[t];
        i && i.destroy && (e[t] = i.destroy());
      }), o && (o.innerHTML = t6.emptyHTML, nV(o), r && nO(o)), nX(e, function (t, i) {
        delete e[i];
      });
    }
    firstRender() {
      let t = this,
        e = t.options;
      t.getContainer(), t.resetMargins(), t.setChartSize(), t.propFromSeries(), t.createAxes();
      let i = nz(e.series) ? e.series : [];
      e.series = [], i.forEach(function (e) {
        t.initSeries(e);
      }), t.linkSeries(), t.setSortedData(), nD(t, "beforeRender"), t.render(), t.pointer?.getChartPosition(), t.renderer.imgCount || t.hasLoaded || t.onload(), t.temporaryDisplay(!0);
    }
    onload() {
      this.callbacks.concat([this.callback]).forEach(function (t) {
        t && void 0 !== this.index && t.apply(this, [this]);
      }, this), nD(this, "load"), nD(this, "render"), nP(this.index) && this.setReflow(), this.warnIfA11yModuleNotLoaded(), this.hasLoaded = !0;
    }
    warnIfA11yModuleNotLoaded() {
      let {
        options: t,
        title: e
      } = this;
      !t || this.accessibility || (this.renderer.boxWrapper.attr({
        role: "img",
        "aria-label": (e && e.element.textContent || "").replace(/</g, "&lt;")
      }), t.accessibility && !1 === t.accessibility.enabled || nL('Highcharts warning: Consider including the "accessibility.js" module to make your chart more usable for people with disabilities. Set the "accessibility.enabled" option to false to remove this warning. See https://www.highcharts.com/docs/accessibility/accessibility-module.', !1, this));
    }
    addSeries(t, e, i) {
      let s;
      let o = this;
      return t && (e = nY(e, !0), nD(o, "addSeries", {
        options: t
      }, function () {
        s = o.initSeries(t), o.isDirtyLegend = !0, o.linkSeries(), s.enabledDataSorting && s.setData(t.data, !1), nD(o, "afterAddSeries", {
          series: s
        }), e && o.redraw(i);
      })), s;
    }
    addAxis(t, e, i, s) {
      return this.createAxis(e ? "xAxis" : "yAxis", {
        axis: t,
        redraw: i,
        animation: s
      });
    }
    addColorAxis(t, e, i) {
      return this.createAxis("colorAxis", {
        axis: t,
        redraw: e,
        animation: i
      });
    }
    createAxis(t, e) {
      let i = new sU(this, e.axis, t);
      return nY(e.redraw, !0) && this.redraw(e.animation), i;
    }
    showLoading(t) {
      let e = this,
        i = e.options,
        s = i.loading,
        o = function () {
          r && nT(r, {
            left: e.plotLeft + "px",
            top: e.plotTop + "px",
            width: e.plotWidth + "px",
            height: e.plotHeight + "px"
          });
        },
        r = e.loadingDiv,
        n = e.loadingSpan;
      r || (e.loadingDiv = r = nA("div", {
        className: "highcharts-loading highcharts-loading-hidden"
      }, null, e.container)), n || (e.loadingSpan = n = nA("span", {
        className: "highcharts-loading-inner"
      }, null, r), nw(e, "redraw", o)), r.className = "highcharts-loading", t6.setElementHTML(n, nY(t, i.lang.loading, "")), e.styledMode || (nT(r, nB(s.style, {
        zIndex: 10
      })), nT(n, s.labelStyle), e.loadingShown || (nT(r, {
        opacity: 0,
        display: ""
      }), nc(r, {
        opacity: s.style.opacity || .5
      }, {
        duration: s.showDuration || 0
      }))), e.loadingShown = !0, o();
    }
    hideLoading() {
      let t = this.options,
        e = this.loadingDiv;
      e && (e.className = "highcharts-loading highcharts-loading-hidden", this.styledMode || nc(e, {
        opacity: 0
      }, {
        duration: t.loading.hideDuration || 100,
        complete: function () {
          nT(e, {
            display: "none"
          });
        }
      })), this.loadingShown = !1;
    }
    update(t, e, i, s) {
      let o, r, n;
      let a = this,
        h = {
          credits: "addCredits",
          title: "setTitle",
          subtitle: "setSubtitle",
          caption: "setCaption"
        },
        l = t.isResponsiveOptions,
        d = [];
      nD(a, "update", {
        options: t
      }), l || a.setResponsive(!1, !0), t = nC(t, a.options), a.userOptions = nF(a.userOptions, t);
      let c = t.chart;
      c && (nF(!0, a.options.chart, c), this.setZoomOptions(), "className" in c && a.setClassName(c.className), ("inverted" in c || "polar" in c || "type" in c) && (a.propFromSeries(), o = !0), "alignTicks" in c && (o = !0), "events" in c && nm(this, c), nX(c, function (t, e) {
        -1 !== a.propsRequireUpdateSeries.indexOf("chart." + e) && (r = !0), -1 !== a.propsRequireDirtyBox.indexOf(e) && (a.isDirtyBox = !0), -1 === a.propsRequireReflow.indexOf(e) || (a.isDirtyBox = !0, l || (n = !0));
      }), !a.styledMode && c.style && a.renderer.setStyle(a.options.chart.style || {})), !a.styledMode && t.colors && (this.options.colors = t.colors), nX(t, function (e, i) {
        a[i] && "function" == typeof a[i].update ? a[i].update(e, !1) : "function" == typeof a[h[i]] ? a[h[i]](e) : "colors" !== i && -1 === a.collectionsWithUpdate.indexOf(i) && nF(!0, a.options[i], t[i]), "chart" !== i && -1 !== a.propsRequireUpdateSeries.indexOf(i) && (r = !0);
      }), this.collectionsWithUpdate.forEach(function (e) {
        t[e] && (n$(t[e]).forEach(function (t, s) {
          let o;
          let r = nP(t.id);
          r && (o = a.get(t.id)), !o && a[e] && (o = a[e][nY(t.index, s)]) && (r && nP(o.options.id) || o.options.isInternal) && (o = void 0), o && o.coll === e && (o.update(t, !1), i && (o.touched = !0)), !o && i && a.collectionsWithInit[e] && (a.collectionsWithInit[e][0].apply(a, [t].concat(a.collectionsWithInit[e][1] || []).concat([!1])).touched = !0);
        }), i && a[e].forEach(function (t) {
          t.touched || t.options.isInternal ? delete t.touched : d.push(t);
        }));
      }), d.forEach(function (t) {
        t.chart && t.remove && t.remove(!1);
      }), o && a.axes.forEach(function (t) {
        t.update({}, !1);
      }), r && a.getSeriesOrderByLinks().forEach(function (t) {
        t.chart && t.update({}, !1);
      }, this);
      let p = c && c.width,
        u = c && (nH(c.height) ? nU(c.height, p || a.chartWidth) : c.height);
      n || nW(p) && p !== a.chartWidth || nW(u) && u !== a.chartHeight ? a.setSize(p, u, s) : nY(e, !0) && a.redraw(s), nD(a, "afterUpdate", {
        options: t,
        redraw: e,
        animation: s
      });
    }
    setSubtitle(t, e) {
      this.applyDescription("subtitle", t), this.layOutTitles(e);
    }
    setCaption(t, e) {
      this.applyDescription("caption", t), this.layOutTitles(e);
    }
    showResetZoom() {
      let t = this,
        e = ng.lang,
        i = t.zooming.resetButton,
        s = i.theme,
        o = "chart" === i.relativeTo || "spacingBox" === i.relativeTo ? null : "plotBox";
      function r() {
        t.zoomOut();
      }
      nD(this, "beforeShowResetZoom", null, function () {
        t.resetZoomButton = t.renderer.button(e.resetZoom, null, null, r, s).attr({
          align: i.position.align,
          title: e.resetZoomTitle
        }).addClass("highcharts-reset-zoom").add().align(i.position, !1, o);
      }), nD(this, "afterShowResetZoom");
    }
    zoomOut() {
      nD(this, "selection", {
        resetSelection: !0
      }, () => this.transform({
        reset: !0,
        trigger: "zoom"
      }));
    }
    pan(t, e) {
      let i = this,
        s = "object" == typeof e ? e : {
          enabled: e,
          type: "x"
        },
        o = s.type,
        r = o && i[{
          x: "xAxis",
          xy: "axes",
          y: "yAxis"
        }[o]].filter(t => t.options.panningEnabled && !t.options.isInternal),
        n = i.options.chart;
      n?.panning && (n.panning = s), nD(this, "pan", {
        originalEvent: t
      }, () => {
        i.transform({
          axes: r,
          event: t,
          to: {
            x: t.chartX - (i.mouseDownX || 0),
            y: t.chartY - (i.mouseDownY || 0)
          },
          trigger: "pan"
        }), nT(i.container, {
          cursor: "move"
        });
      });
    }
    transform(t) {
      let {
          axes: e = this.axes,
          event: i,
          from: s = {},
          reset: o,
          selection: r,
          to: n = {},
          trigger: a
        } = t,
        {
          inverted: h,
          time: l
        } = this,
        d = !1,
        c,
        p;
      for (let t of (this.hoverPoints?.forEach(t => t.setState()), e)) {
        let {
            horiz: e,
            len: u,
            minPointOffset: g = 0,
            options: f,
            reversed: m
          } = t,
          x = e ? "width" : "height",
          y = e ? "x" : "y",
          b = nY(n[x], t.len),
          v = nY(s[x], t.len),
          M = 10 > Math.abs(b) ? 1 : b / v,
          k = (s[y] || 0) + v / 2 - t.pos,
          w = k - ((n[y] ?? t.pos) + b / 2 - t.pos) / M,
          S = m && !h || !m && h ? -1 : 1;
        if (!o && (k < 0 || k > t.len)) continue;
        let A = t.toValue(w, !0) + (r || t.isOrdinal ? 0 : g * S),
          T = t.toValue(w + u / M, !0) - (r || t.isOrdinal ? 0 : g * S || 0),
          P = t.allExtremes;
        if (A > T && ([A, T] = [T, A]), 1 === M && !o && "yAxis" === t.coll && !P) {
          for (let e of t.series) {
            let t = e.getExtremes(e.getProcessedData(!0).modified.getColumn("y") || [], !0);
            P ?? (P = {
              dataMin: Number.MAX_VALUE,
              dataMax: -Number.MAX_VALUE
            }), nW(t.dataMin) && nW(t.dataMax) && (P.dataMin = Math.min(t.dataMin, P.dataMin), P.dataMax = Math.max(t.dataMax, P.dataMax));
          }
          t.allExtremes = P;
        }
        let {
            dataMin: C,
            dataMax: O,
            min: E,
            max: L
          } = nB(t.getExtremes(), P || {}),
          B = l.parse(f.min),
          I = l.parse(f.max),
          D = C ?? B,
          R = O ?? I,
          N = T - A,
          z = t.categories ? 0 : Math.min(N, R - D),
          W = D - z * (nP(B) ? 0 : f.minPadding),
          G = R + z * (nP(I) ? 0 : f.maxPadding),
          H = t.allowZoomOutside || 1 === M || "zoom" !== a && M > 1,
          F = Math.min(B ?? W, W, H ? E : W),
          X = Math.max(I ?? G, G, H ? L : G);
        (!t.isOrdinal || t.options.overscroll || 1 !== M || o) && (A < F && (A = F, M >= 1 && (T = A + N)), T > X && (T = X, M >= 1 && (A = T - N)), (o || t.series.length && (A !== E || T !== L) && A >= F && T <= X) && (r ? r[t.coll].push({
          axis: t,
          min: A,
          max: T
        }) : (t.isPanning = "zoom" !== a, t.isPanning && (p = !0), t.setExtremes(o ? void 0 : A, o ? void 0 : T, !1, !1, {
          move: w,
          trigger: a,
          scale: M
        }), !o && (A > F || T < X) && "mousewheel" !== a && (c = !0)), d = !0), i && (this[e ? "mouseDownX" : "mouseDownY"] = i[e ? "chartX" : "chartY"]));
      }
      return d && (r ? nD(this, "selection", r, () => {
        delete t.selection, t.trigger = "zoom", this.transform(t);
      }) : (!c || p || this.resetZoomButton ? !c && this.resetZoomButton && (this.resetZoomButton = this.resetZoomButton.destroy()) : this.showResetZoom(), this.redraw("zoom" === a && (this.options.chart.animation ?? this.pointCount < 100)))), d;
    }
  }
  nB(nZ.prototype, {
    callbacks: [],
    collectionsWithInit: {
      xAxis: [nZ.prototype.addAxis, [!0]],
      yAxis: [nZ.prototype.addAxis, [!1]],
      series: [nZ.prototype.addSeries]
    },
    collectionsWithUpdate: ["xAxis", "yAxis", "series"],
    propsRequireDirtyBox: ["backgroundColor", "borderColor", "borderWidth", "borderRadius", "plotBackgroundColor", "plotBackgroundImage", "plotBorderColor", "plotBorderWidth", "plotShadow", "shadow"],
    propsRequireReflow: ["margin", "marginTop", "marginRight", "marginBottom", "marginLeft", "spacing", "spacingTop", "spacingRight", "spacingBottom", "spacingLeft"],
    propsRequireUpdateSeries: ["chart.inverted", "chart.polar", "chart.ignoreHiddenSeries", "chart.type", "colors", "plotOptions", "time", "tooltip"]
  });
  let nK = nZ,
    {
      stop: nJ
    } = tU,
    {
      composed: nQ
    } = O,
    {
      addEvent: n0,
      createElement: n1,
      css: n2,
      defined: n3,
      erase: n5,
      merge: n6,
      pushUnique: n9
    } = tt;
  function n4() {
    let t = this.scrollablePlotArea;
    (this.scrollablePixelsX || this.scrollablePixelsY) && !t && (this.scrollablePlotArea = t = new n7(this)), t?.applyFixed();
  }
  function n8() {
    this.chart.scrollablePlotArea && (this.chart.scrollablePlotArea.isDirty = !0);
  }
  class n7 {
    static compose(t, e, i) {
      n9(nQ, this.compose) && (n0(t, "afterInit", n8), n0(e, "afterSetChartSize", t => this.afterSetSize(t.target, t)), n0(e, "render", n4), n0(i, "show", n8));
    }
    static afterSetSize(t, e) {
      let i, s, o;
      let {
          minWidth: r,
          minHeight: n
        } = t.options.chart.scrollablePlotArea || {},
        {
          clipBox: a,
          plotBox: h,
          inverted: l,
          renderer: d
        } = t;
      if (!d.forExport && (r ? (t.scrollablePixelsX = i = Math.max(0, r - t.chartWidth), i && (t.scrollablePlotBox = n6(t.plotBox), h.width = t.plotWidth += i, a[l ? "height" : "width"] += i, o = !0)) : n && (t.scrollablePixelsY = s = Math.max(0, n - t.chartHeight), n3(s) && (t.scrollablePlotBox = n6(t.plotBox), h.height = t.plotHeight += s, a[l ? "width" : "height"] += s, o = !1)), n3(o) && !e.skipAxes)) for (let e of t.axes) (e.horiz === o || t.hasParallelCoordinates && "yAxis" === e.coll) && (e.setAxisSize(), e.setAxisTranslation());
    }
    constructor(t) {
      let e;
      let i = t.options.chart,
        s = eu.getRendererType(),
        o = i.scrollablePlotArea || {},
        r = this.moveFixedElements.bind(this),
        n = {
          WebkitOverflowScrolling: "touch",
          overflowX: "hidden",
          overflowY: "hidden"
        };
      t.scrollablePixelsX && (n.overflowX = "auto"), t.scrollablePixelsY && (n.overflowY = "auto"), this.chart = t;
      let a = this.parentDiv = n1("div", {
          className: "highcharts-scrolling-parent"
        }, {
          position: "relative"
        }, t.renderTo),
        h = this.scrollingContainer = n1("div", {
          className: "highcharts-scrolling"
        }, n, a),
        l = this.innerContainer = n1("div", {
          className: "highcharts-inner-container"
        }, void 0, h),
        d = this.fixedDiv = n1("div", {
          className: "highcharts-fixed"
        }, {
          position: "absolute",
          overflow: "hidden",
          pointerEvents: "none",
          zIndex: (i.style?.zIndex || 0) + 2,
          top: 0
        }, void 0, !0),
        c = this.fixedRenderer = new s(d, t.chartWidth, t.chartHeight, i.style);
      this.mask = c.path().attr({
        fill: i.backgroundColor || "#fff",
        "fill-opacity": o.opacity ?? .85,
        zIndex: -1
      }).addClass("highcharts-scrollable-mask").add(), h.parentNode.insertBefore(d, h), n2(t.renderTo, {
        overflow: "visible"
      }), n0(t, "afterShowResetZoom", r), n0(t, "afterApplyDrilldown", r), n0(t, "afterLayOutTitles", r), n0(h, "scroll", () => {
        let {
          pointer: i,
          hoverPoint: s
        } = t;
        i && (delete i.chartPosition, s && (e = s), i.runPointActions(void 0, e, !0));
      }), l.appendChild(t.container);
    }
    applyFixed() {
      let {
          chart: t,
          fixedRenderer: e,
          isDirty: i,
          scrollingContainer: s
        } = this,
        {
          axisOffset: o,
          chartWidth: r,
          chartHeight: n,
          container: a,
          plotHeight: h,
          plotLeft: l,
          plotTop: d,
          plotWidth: c,
          scrollablePixelsX: p = 0,
          scrollablePixelsY: u = 0
        } = t,
        {
          scrollPositionX: g = 0,
          scrollPositionY: f = 0
        } = t.options.chart.scrollablePlotArea || {},
        m = r + p,
        x = n + u;
      e.setSize(r, n), (i ?? !0) && (this.isDirty = !1, this.moveFixedElements()), nJ(t.container), n2(a, {
        width: `${m}px`,
        height: `${x}px`
      }), t.renderer.boxWrapper.attr({
        width: m,
        height: x,
        viewBox: [0, 0, m, x].join(" ")
      }), t.chartBackground?.attr({
        width: m,
        height: x
      }), n2(s, {
        width: `${r}px`,
        height: `${n}px`
      }), n3(i) || (s.scrollLeft = p * g, s.scrollTop = u * f);
      let y = d - o[0] - 1,
        b = l - o[3] - 1,
        v = d + h + o[2] + 1,
        M = l + c + o[1] + 1,
        k = l + c - p,
        w = d + h - u,
        S = [["M", 0, 0]];
      p ? S = [["M", 0, y], ["L", l - 1, y], ["L", l - 1, v], ["L", 0, v], ["Z"], ["M", k, y], ["L", r, y], ["L", r, v], ["L", k, v], ["Z"]] : u && (S = [["M", b, 0], ["L", b, d - 1], ["L", M, d - 1], ["L", M, 0], ["Z"], ["M", b, w], ["L", b, n], ["L", M, n], ["L", M, w], ["Z"]]), "adjustHeight" !== t.redrawTrigger && this.mask.attr({
        d: S
      });
    }
    moveFixedElements() {
      let t;
      let {
          container: e,
          inverted: i,
          scrollablePixelsX: s,
          scrollablePixelsY: o
        } = this.chart,
        r = this.fixedRenderer,
        n = n7.fixedSelectors;
      if (s && !i ? t = ".highcharts-yaxis" : s && i ? t = ".highcharts-xaxis" : o && !i ? t = ".highcharts-xaxis" : o && i && (t = ".highcharts-yaxis"), t && !(this.chart.hasParallelCoordinates && ".highcharts-yaxis" === t)) for (let e of [`${t}:not(.highcharts-radial-axis)`, `${t}-labels:not(.highcharts-radial-axis-labels)`]) n9(n, e);else for (let t of [".highcharts-xaxis", ".highcharts-yaxis"]) for (let e of [`${t}:not(.highcharts-radial-axis)`, `${t}-labels:not(.highcharts-radial-axis-labels)`]) n5(n, e);
      for (let t of n) [].forEach.call(e.querySelectorAll(t), t => {
        (t.namespaceURI === r.SVG_NS ? r.box : r.box.parentNode).appendChild(t), t.style.pointerEvents = "auto";
      });
    }
  }
  n7.fixedSelectors = [".highcharts-breadcrumbs-group", ".highcharts-contextbutton", ".highcharts-caption", ".highcharts-credits", ".highcharts-drillup-button", ".highcharts-legend", ".highcharts-legend-checkbox", ".highcharts-navigator-series", ".highcharts-navigator-xaxis", ".highcharts-navigator-yaxis", ".highcharts-navigator", ".highcharts-range-selector-group", ".highcharts-reset-zoom", ".highcharts-scrollbar", ".highcharts-subtitle", ".highcharts-title"];
  let {
      format: at
    } = ep,
    {
      series: ae
    } = ry,
    {
      destroyObjectProperties: ai,
      fireEvent: as,
      getAlignFactor: ao,
      isNumber: ar,
      pick: an
    } = tt,
    aa = class {
      constructor(t, e, i, s, o) {
        let r = t.chart.inverted,
          n = t.reversed;
        this.axis = t;
        let a = this.isNegative = !!i != !!n;
        this.options = e = e || {}, this.x = s, this.total = null, this.cumulative = null, this.points = {}, this.hasValidPoints = !1, this.stack = o, this.leftCliff = 0, this.rightCliff = 0, this.alignOptions = {
          align: e.align || (r ? a ? "left" : "right" : "center"),
          verticalAlign: e.verticalAlign || (r ? "middle" : a ? "bottom" : "top"),
          y: e.y,
          x: e.x
        }, this.textAlign = e.textAlign || (r ? a ? "right" : "left" : "center");
      }
      destroy() {
        ai(this, this.axis);
      }
      render(t) {
        let e = this.axis.chart,
          i = this.options,
          s = i.format,
          o = s ? at(s, this, e) : i.formatter.call(this);
        if (this.label) this.label.attr({
          text: o,
          visibility: "hidden"
        });else {
          this.label = e.renderer.label(o, null, void 0, i.shape, void 0, void 0, i.useHTML, !1, "stack-labels");
          let s = {
            r: i.borderRadius || 0,
            text: o,
            padding: an(i.padding, 5),
            visibility: "hidden"
          };
          e.styledMode || (s.fill = i.backgroundColor, s.stroke = i.borderColor, s["stroke-width"] = i.borderWidth, this.label.css(i.style || {})), this.label.attr(s), this.label.added || this.label.add(t);
        }
        this.label.labelrank = e.plotSizeY, as(this, "afterRender");
      }
      setOffset(t, e, i, s, o, r) {
        let {
            alignOptions: n,
            axis: a,
            label: h,
            options: l,
            textAlign: d
          } = this,
          c = a.chart,
          p = this.getStackBox({
            xOffset: t,
            width: e,
            boxBottom: i,
            boxTop: s,
            defaultX: o,
            xAxis: r
          }),
          {
            verticalAlign: u
          } = n;
        if (h && p) {
          let t = h.getBBox(void 0, 0),
            e = h.padding,
            i = "justify" === an(l.overflow, "justify"),
            s;
          n.x = l.x || 0, n.y = l.y || 0;
          let {
            x: o,
            y: r
          } = this.adjustStackPosition({
            labelBox: t,
            verticalAlign: u,
            textAlign: d
          });
          p.x -= o, p.y -= r, h.align(n, !1, p), (s = c.isInsidePlot(h.alignAttr.x + n.x + o, h.alignAttr.y + n.y + r)) || (i = !1), i && ae.prototype.justifyDataLabel.call(a, h, n, h.alignAttr, t, p), h.attr({
            x: h.alignAttr.x,
            y: h.alignAttr.y,
            rotation: l.rotation,
            rotationOriginX: t.width * ao(l.textAlign || "center"),
            rotationOriginY: t.height / 2
          }), an(!i && l.crop, !0) && (s = ar(h.x) && ar(h.y) && c.isInsidePlot(h.x - e + (h.width || 0), h.y) && c.isInsidePlot(h.x + e, h.y)), h[s ? "show" : "hide"]();
        }
        as(this, "afterSetOffset", {
          xOffset: t,
          width: e
        });
      }
      adjustStackPosition({
        labelBox: t,
        verticalAlign: e,
        textAlign: i
      }) {
        return {
          x: t.width / 2 + t.width / 2 * (2 * ao(i) - 1),
          y: t.height / 2 * 2 * (1 - ao(e))
        };
      }
      getStackBox(t) {
        let e = this.axis,
          i = e.chart,
          {
            boxTop: s,
            defaultX: o,
            xOffset: r,
            width: n,
            boxBottom: a
          } = t,
          h = e.stacking.usePercentage ? 100 : an(s, this.total, 0),
          l = e.toPixels(h),
          d = t.xAxis || i.xAxis[0],
          c = an(o, d.translate(this.x)) + r,
          p = Math.abs(l - e.toPixels(a || ar(e.min) && e.logarithmic && e.logarithmic.lin2log(e.min) || 0)),
          u = i.inverted,
          g = this.isNegative;
        return u ? {
          x: (g ? l : l - p) - i.plotLeft,
          y: d.height - c - n + d.top - i.plotTop,
          width: p,
          height: n
        } : {
          x: c + d.transB - i.plotLeft,
          y: (g ? l - p : l) - i.plotTop,
          width: n,
          height: p
        };
      }
    },
    {
      getDeferredAnimation: ah
    } = tU,
    {
      series: {
        prototype: al
      }
    } = ry,
    {
      addEvent: ad,
      correctFloat: ac,
      defined: ap,
      destroyObjectProperties: au,
      fireEvent: ag,
      isNumber: af,
      objectEach: am,
      pick: ax
    } = tt;
  function ay() {
    let t = this.inverted;
    this.axes.forEach(t => {
      t.stacking && t.stacking.stacks && t.hasVisibleSeries && (t.stacking.oldStacks = t.stacking.stacks);
    }), this.series.forEach(e => {
      let i = e.xAxis && e.xAxis.options || {};
      e.options.stacking && e.reserveSpace() && (e.stackKey = [e.type, ax(e.options.stack, ""), t ? i.top : i.left, t ? i.height : i.width].join(","));
    });
  }
  function ab() {
    let t = this.stacking;
    if (t) {
      let e = t.stacks;
      am(e, (t, i) => {
        au(t), delete e[i];
      }), t.stackTotalGroup?.destroy();
    }
  }
  function av() {
    this.stacking || (this.stacking = new aT(this));
  }
  function aM(t, e, i, s) {
    return !ap(t) || t.x !== e || s && t.stackKey !== s ? t = {
      x: e,
      index: 0,
      key: s,
      stackKey: s
    } : t.index++, t.key = [i, e, t.index].join(","), t;
  }
  function ak() {
    let t;
    let e = this,
      i = e.yAxis,
      s = e.stackKey || "",
      o = i.stacking.stacks,
      r = e.getColumn("x", !0),
      n = e.options.stacking,
      a = e[n + "Stacker"];
    a && [s, "-" + s].forEach(i => {
      let s = r.length,
        n,
        h,
        l;
      for (; s--;) n = r[s], t = e.getStackIndicator(t, n, e.index, i), h = o[i]?.[n], (l = h?.points[t.key || ""]) && a.call(e, l, h, s);
    });
  }
  function aw(t, e, i) {
    let s = e.total ? 100 / e.total : 0;
    t[0] = ac(t[0] * s), t[1] = ac(t[1] * s), this.stackedYData[i] = t[1];
  }
  function aS(t) {
    (this.is("column") || this.is("columnrange")) && (this.options.centerInCategory && this.chart.series.length > 1 ? al.setStackedPoints.call(this, t, "group") : t.stacking.resetStacks());
  }
  function aA(t, e) {
    let i, s, o, r, n, a, h;
    let l = e || this.options.stacking;
    if (!l || !this.reserveSpace() || ({
      group: "xAxis"
    }[l] || "yAxis") !== t.coll) return;
    let d = this.getColumn("x", !0),
      c = this.getColumn(this.pointValKey || "y", !0),
      p = [],
      u = c.length,
      g = this.options,
      f = g.threshold || 0,
      m = g.startFromThreshold ? f : 0,
      x = g.stack,
      y = e ? `${this.type},${l}` : this.stackKey || "",
      b = "-" + y,
      v = this.negStacks,
      M = t.stacking,
      k = M.stacks,
      w = M.oldStacks;
    for (M.stacksTouched += 1, h = 0; h < u; h++) {
      let e = d[h] || 0,
        u = c[h],
        g = af(u) && u || 0;
      a = (i = this.getStackIndicator(i, e, this.index)).key || "", k[n = (s = v && g < (m ? 0 : f)) ? b : y] || (k[n] = {}), k[n][e] || (w[n]?.[e] ? (k[n][e] = w[n][e], k[n][e].total = null) : k[n][e] = new aa(t, t.options.stackLabels, !!s, e, x)), o = k[n][e], null !== u ? (o.points[a] = o.points[this.index] = [ax(o.cumulative, m)], ap(o.cumulative) || (o.base = a), o.touched = M.stacksTouched, i.index > 0 && !1 === this.singleStacks && (o.points[a][0] = o.points[this.index + "," + e + ",0"][0])) : (delete o.points[a], delete o.points[this.index]);
      let S = o.total || 0;
      "percent" === l ? (r = s ? y : b, S = v && k[r]?.[e] ? (r = k[r][e]).total = Math.max(r.total || 0, S) + Math.abs(g) : ac(S + Math.abs(g))) : "group" === l ? af(u) && S++ : S = ac(S + g), "group" === l ? o.cumulative = (S || 1) - 1 : o.cumulative = ac(ax(o.cumulative, m) + g), o.total = S, null !== u && (o.points[a].push(o.cumulative), p[h] = o.cumulative, o.hasValidPoints = !0);
    }
    "percent" === l && (M.usePercentage = !0), "group" !== l && (this.stackedYData = p), M.oldStacks = {};
  }
  class aT {
    constructor(t) {
      this.oldStacks = {}, this.stacks = {}, this.stacksTouched = 0, this.axis = t;
    }
    buildStacks() {
      let t, e;
      let i = this.axis,
        s = i.series,
        o = "xAxis" === i.coll,
        r = i.options.reversedStacks,
        n = s.length;
      for (this.resetStacks(), this.usePercentage = !1, e = n; e--;) t = s[r ? e : n - e - 1], o && t.setGroupedPoints(i), t.setStackedPoints(i);
      if (!o) for (e = 0; e < n; e++) s[e].modifyStacks();
      ag(i, "afterBuildStacks");
    }
    cleanStacks() {
      this.oldStacks && (this.stacks = this.oldStacks, am(this.stacks, t => {
        am(t, t => {
          t.cumulative = t.total;
        });
      }));
    }
    resetStacks() {
      am(this.stacks, t => {
        am(t, (e, i) => {
          af(e.touched) && e.touched < this.stacksTouched ? (e.destroy(), delete t[i]) : (e.total = null, e.cumulative = null);
        });
      });
    }
    renderStackTotals() {
      let t = this.axis,
        e = t.chart,
        i = e.renderer,
        s = this.stacks,
        o = ah(e, t.options.stackLabels?.animation || !1),
        r = this.stackTotalGroup = this.stackTotalGroup || i.g("stack-labels").attr({
          zIndex: 6,
          opacity: 0
        }).add();
      r.translate(e.plotLeft, e.plotTop), am(s, t => {
        am(t, t => {
          t.render(r);
        });
      }), r.animate({
        opacity: 1
      }, o);
    }
  }
  (f || (f = {})).compose = function (t, e, i) {
    let s = e.prototype,
      o = i.prototype;
    s.getStacks || (ad(t, "init", av), ad(t, "destroy", ab), s.getStacks = ay, o.getStackIndicator = aM, o.modifyStacks = ak, o.percentStacker = aw, o.setGroupedPoints = aS, o.setStackedPoints = aA);
  };
  let aP = f,
    {
      defined: aC,
      merge: aO,
      isObject: aE
    } = tt;
  class aL extends rK {
    drawGraph() {
      let t = this.options,
        e = (this.gappedPath || this.getGraphPath).call(this),
        i = this.chart.styledMode;
      [this, ...this.zones].forEach((s, o) => {
        let r,
          n = s.graph,
          a = n ? "animate" : "attr",
          h = s.dashStyle || t.dashStyle;
        n ? (n.endX = this.preventGraphAnimation ? null : e.xMap, n.animate({
          d: e
        })) : e.length && (s.graph = n = this.chart.renderer.path(e).addClass("highcharts-graph" + (o ? ` highcharts-zone-graph-${o - 1} ` : " ") + (o && s.className || "")).attr({
          zIndex: 1
        }).add(this.group)), n && !i && (r = {
          stroke: !o && t.lineColor || s.color || this.color || "#cccccc",
          "stroke-width": t.lineWidth || 0,
          fill: this.fillGraph && this.color || "none"
        }, h ? r.dashstyle = h : "square" !== t.linecap && (r["stroke-linecap"] = r["stroke-linejoin"] = "round"), n[a](r).shadow(t.shadow && aO({
          filterUnits: "userSpaceOnUse"
        }, aE(t.shadow) ? t.shadow : {}))), n && (n.startX = e.xMap, n.isArea = e.isArea);
      });
    }
    getGraphPath(t, e, i) {
      let s = this,
        o = s.options,
        r = [],
        n = [],
        a,
        h = o.step,
        l = (t = t || s.points).reversed;
      return l && t.reverse(), (h = {
        right: 1,
        center: 2
      }[h] || h && 3) && l && (h = 4 - h), (t = this.getValidPoints(t, !1, !(o.connectNulls && !e && !i))).forEach(function (l, d) {
        let c;
        let p = l.plotX,
          u = l.plotY,
          g = t[d - 1],
          f = l.isNull || "number" != typeof u;
        (l.leftCliff || g && g.rightCliff) && !i && (a = !0), f && !aC(e) && d > 0 ? a = !o.connectNulls : f && !e ? a = !0 : (0 === d || a ? c = [["M", l.plotX, l.plotY]] : s.getPointSpline ? c = [s.getPointSpline(t, l, d)] : h ? (c = 1 === h ? [["L", g.plotX, u]] : 2 === h ? [["L", (g.plotX + p) / 2, g.plotY], ["L", (g.plotX + p) / 2, u]] : [["L", p, g.plotY]]).push(["L", p, u]) : c = [["L", p, u]], n.push(l.x), h && (n.push(l.x), 2 === h && n.push(l.x)), r.push.apply(r, c), a = !1);
      }), r.xMap = n, s.graphPath = r, r;
    }
  }
  aL.defaultOptions = aO(rK.defaultOptions, {
    legendSymbol: "lineMarker"
  }), ry.registerSeriesType("line", aL);
  let {
      seriesTypes: {
        line: aB
      }
    } = ry,
    {
      extend: aI,
      merge: aD,
      objectEach: aR,
      pick: aN
    } = tt;
  class az extends aB {
    drawGraph() {
      this.areaPath = [], super.drawGraph.apply(this);
      let {
        areaPath: t,
        options: e
      } = this;
      [this, ...this.zones].forEach((i, s) => {
        let o = {},
          r = i.fillColor || e.fillColor,
          n = i.area,
          a = n ? "animate" : "attr";
        n ? (n.endX = this.preventGraphAnimation ? null : t.xMap, n.animate({
          d: t
        })) : (o.zIndex = 0, (n = i.area = this.chart.renderer.path(t).addClass("highcharts-area" + (s ? ` highcharts-zone-area-${s - 1} ` : " ") + (s && i.className || "")).add(this.group)).isArea = !0), this.chart.styledMode || (o.fill = r || i.color || this.color, o["fill-opacity"] = r ? 1 : e.fillOpacity ?? .75, n.css({
          pointerEvents: this.stickyTracking ? "none" : "auto"
        })), n[a](o), n.startX = t.xMap, n.shiftUnit = e.step ? 2 : 1;
      });
    }
    getGraphPath(t) {
      let e, i, s;
      let o = aB.prototype.getGraphPath,
        r = this.options,
        n = r.stacking,
        a = this.yAxis,
        h = [],
        l = [],
        d = this.index,
        c = a.stacking.stacks[this.stackKey],
        p = r.threshold,
        u = Math.round(a.getThreshold(r.threshold)),
        g = aN(r.connectNulls, "percent" === n),
        f = function (i, s, o) {
          let r = t[i],
            g = n && c[r.x].points[d],
            f = r[o + "Null"] || 0,
            m = r[o + "Cliff"] || 0,
            x,
            y,
            b = !0;
          m || f ? (x = (f ? g[0] : g[1]) + m, y = g[0] + m, b = !!f) : !n && t[s] && t[s].isNull && (x = y = p), void 0 !== x && (l.push({
            plotX: e,
            plotY: null === x ? u : a.getThreshold(x),
            isNull: b,
            isCliff: !0
          }), h.push({
            plotX: e,
            plotY: null === y ? u : a.getThreshold(y),
            doCurve: !1
          }));
        };
      t = t || this.points, n && (t = this.getStackPoints(t));
      for (let o = 0, r = t.length; o < r; ++o) n || (t[o].leftCliff = t[o].rightCliff = t[o].leftNull = t[o].rightNull = void 0), i = t[o].isNull, e = aN(t[o].rectPlotX, t[o].plotX), s = n ? aN(t[o].yBottom, u) : u, i && !g || (g || f(o, o - 1, "left"), i && !n && g || (l.push(t[o]), h.push({
        x: o,
        plotX: e,
        plotY: s
      })), g || f(o, o + 1, "right"));
      let m = o.call(this, l, !0, !0);
      h.reversed = !0;
      let x = o.call(this, h, !0, !0),
        y = x[0];
      y && "M" === y[0] && (x[0] = ["L", y[1], y[2]]);
      let b = m.concat(x);
      b.length && b.push(["Z"]);
      let v = o.call(this, l, !1, g);
      return this.chart.series.length > 1 && n && l.some(t => t.isCliff) && (b.hasStackedCliffs = v.hasStackedCliffs = !0), b.xMap = m.xMap, this.areaPath = b, v;
    }
    getStackPoints(t) {
      let e = this,
        i = [],
        s = [],
        o = this.xAxis,
        r = this.yAxis,
        n = r.stacking.stacks[this.stackKey],
        a = {},
        h = r.series,
        l = h.length,
        d = r.options.reversedStacks ? 1 : -1,
        c = h.indexOf(e);
      if (t = t || this.points, this.options.stacking) {
        for (let e = 0; e < t.length; e++) t[e].leftNull = t[e].rightNull = void 0, a[t[e].x] = t[e];
        aR(n, function (t, e) {
          null !== t.total && s.push(e);
        }), s.sort(function (t, e) {
          return t - e;
        });
        let p = h.map(t => t.visible);
        s.forEach(function (t, u) {
          let g = 0,
            f,
            m;
          if (a[t] && !a[t].isNull) i.push(a[t]), [-1, 1].forEach(function (i) {
            let o = 1 === i ? "rightNull" : "leftNull",
              r = n[s[u + i]],
              g = 0;
            if (r) {
              let i = c;
              for (; i >= 0 && i < l;) {
                let s = h[i].index;
                !(f = r.points[s]) && (s === e.index ? a[t][o] = !0 : p[i] && (m = n[t].points[s]) && (g -= m[1] - m[0])), i += d;
              }
            }
            a[t][1 === i ? "rightCliff" : "leftCliff"] = g;
          });else {
            let e = c;
            for (; e >= 0 && e < l;) {
              let i = h[e].index;
              if (f = n[t].points[i]) {
                g = f[1];
                break;
              }
              e += d;
            }
            g = aN(g, 0), g = r.translate(g, 0, 1, 0, 1), i.push({
              isNull: !0,
              plotX: o.translate(t, 0, 0, 0, 1),
              x: t,
              plotY: g,
              yBottom: g
            });
          }
        });
      }
      return i;
    }
  }
  az.defaultOptions = aD(aB.defaultOptions, {
    threshold: 0,
    legendSymbol: "areaMarker"
  }), aI(az.prototype, {
    singleStacks: !1
  }), ry.registerSeriesType("area", az);
  let {
      line: aW
    } = ry.seriesTypes,
    {
      merge: aG,
      pick: aH
    } = tt;
  class aF extends aW {
    getPointSpline(t, e, i) {
      let s, o, r, n;
      let a = e.plotX || 0,
        h = e.plotY || 0,
        l = t[i - 1],
        d = t[i + 1];
      function c(t) {
        return t && !t.isNull && !1 !== t.doCurve && !e.isCliff;
      }
      if (c(l) && c(d)) {
        let t = l.plotX || 0,
          i = l.plotY || 0,
          c = d.plotX || 0,
          p = d.plotY || 0,
          u = 0;
        s = (1.5 * a + t) / 2.5, o = (1.5 * h + i) / 2.5, r = (1.5 * a + c) / 2.5, n = (1.5 * h + p) / 2.5, r !== s && (u = (n - o) * (r - a) / (r - s) + h - n), o += u, n += u, o > i && o > h ? (o = Math.max(i, h), n = 2 * h - o) : o < i && o < h && (o = Math.min(i, h), n = 2 * h - o), n > p && n > h ? (n = Math.max(p, h), o = 2 * h - n) : n < p && n < h && (n = Math.min(p, h), o = 2 * h - n), e.rightContX = r, e.rightContY = n, e.controlPoints = {
          low: [s, o],
          high: [r, n]
        };
      }
      let p = ["C", aH(l.rightContX, l.plotX, 0), aH(l.rightContY, l.plotY, 0), aH(s, a, 0), aH(o, h, 0), a, h];
      return l.rightContX = l.rightContY = void 0, p;
    }
  }
  aF.defaultOptions = aG(aW.defaultOptions), ry.registerSeriesType("spline", aF);
  let aX = aF,
    {
      area: aY,
      area: {
        prototype: aj
      }
    } = ry.seriesTypes,
    {
      extend: aU,
      merge: aV
    } = tt;
  class a$ extends aX {}
  a$.defaultOptions = aV(aX.defaultOptions, aY.defaultOptions), aU(a$.prototype, {
    getGraphPath: aj.getGraphPath,
    getStackPoints: aj.getStackPoints,
    drawGraph: aj.drawGraph
  }), ry.registerSeriesType("areaspline", a$);
  let {
      animObject: a_
    } = tU,
    {
      parse: aq
    } = tO,
    {
      noop: aZ
    } = O,
    {
      clamp: aK,
      crisp: aJ,
      defined: aQ,
      extend: a0,
      fireEvent: a1,
      isArray: a2,
      isNumber: a3,
      merge: a5,
      pick: a6,
      objectEach: a9
    } = tt;
  class a4 extends rK {
    animate(t) {
      let e, i;
      let s = this,
        o = this.yAxis,
        r = o.pos,
        n = o.reversed,
        a = s.options,
        {
          clipOffset: h,
          inverted: l
        } = this.chart,
        d = {},
        c = l ? "translateX" : "translateY";
      t && h ? (d.scaleY = .001, i = aK(o.toPixels(a.threshold || 0), r, r + o.len), l ? (i += n ? -Math.floor(h[0]) : Math.ceil(h[2]), d.translateX = i - o.len) : (i += n ? Math.ceil(h[0]) : -Math.floor(h[2]), d.translateY = i), s.clipBox && s.setClip(), s.group.attr(d)) : (e = Number(s.group.attr(c)), s.group.animate({
        scaleY: 1
      }, a0(a_(s.options.animation), {
        step: function (t, i) {
          s.group && (d[c] = e + i.pos * (r - e), s.group.attr(d));
        }
      })));
    }
    init(t, e) {
      super.init.apply(this, arguments);
      let i = this;
      (t = i.chart).hasRendered && t.series.forEach(function (t) {
        t.type === i.type && (t.isDirty = !0);
      });
    }
    getColumnMetrics() {
      let t = this,
        e = t.options,
        i = t.xAxis,
        s = t.yAxis,
        o = i.options.reversedStacks,
        r = i.reversed && !o || !i.reversed && o,
        n = {},
        a,
        h = 0;
      !1 === e.grouping ? h = 1 : t.chart.series.forEach(function (e) {
        let i;
        let o = e.yAxis,
          r = e.options;
        e.type === t.type && e.reserveSpace() && s.len === o.len && s.pos === o.pos && (r.stacking && "group" !== r.stacking ? (void 0 === n[a = e.stackKey] && (n[a] = h++), i = n[a]) : !1 !== r.grouping && (i = h++), e.columnIndex = i);
      });
      let l = Math.min(Math.abs(i.transA) * (!i.brokenAxis?.hasBreaks && i.ordinal?.slope || e.pointRange || i.closestPointRange || i.tickInterval || 1), i.len),
        d = l * e.groupPadding,
        c = (l - 2 * d) / (h || 1),
        p = Math.min(e.maxPointWidth || i.len, a6(e.pointWidth, c * (1 - 2 * e.pointPadding))),
        u = (t.columnIndex || 0) + (r ? 1 : 0);
      return t.columnMetrics = {
        width: p,
        offset: (c - p) / 2 + (d + u * c - l / 2) * (r ? -1 : 1),
        paddedWidth: c,
        columnCount: h
      }, t.columnMetrics;
    }
    crispCol(t, e, i, s) {
      let o = this.borderWidth,
        r = this.chart.inverted;
      return s = aJ(e + s, o, r) - (e = aJ(e, o, r)), this.options.crisp && (i = aJ(t + i, o) - (t = aJ(t, o))), {
        x: t,
        y: e,
        width: i,
        height: s
      };
    }
    adjustForMissingColumns(t, e, i, s) {
      if (!i.isNull && s.columnCount > 1) {
        let o = this.xAxis.series.filter(t => t.visible).map(t => t.index),
          r = 0,
          n = 0;
        a9(this.xAxis.stacking?.stacks, t => {
          let e = "number" == typeof i.x ? t[i.x.toString()]?.points : void 0,
            s = e?.[this.index],
            a = {};
          if (e && a2(s)) {
            let t = this.index,
              i = Object.keys(e).filter(t => !t.match(",") && e[t] && e[t].length > 1).map(parseFloat).filter(t => -1 !== o.indexOf(t)).filter(e => {
                let i = this.chart.series[e].options,
                  s = i.stacking && i.stack;
                if (aQ(s)) {
                  if (a3(a[s])) return t === e && (t = a[s]), !1;
                  a[s] = e;
                }
                return !0;
              }).sort((t, e) => e - t);
            r = i.indexOf(t), n = i.length;
          }
        }), r = this.xAxis.reversed ? n - 1 - r : r;
        let a = (n - 1) * s.paddedWidth + e;
        t = (i.plotX || 0) + a / 2 - e - r * s.paddedWidth;
      }
      return t;
    }
    translate() {
      let t = this,
        e = t.chart,
        i = t.options,
        s = t.dense = t.closestPointRange * t.xAxis.transA < 2,
        o = t.borderWidth = a6(i.borderWidth, s ? 0 : 1),
        r = t.xAxis,
        n = t.yAxis,
        a = i.threshold,
        h = a6(i.minPointLength, 5),
        l = t.getColumnMetrics(),
        d = l.width,
        c = t.pointXOffset = l.offset,
        p = t.dataMin,
        u = t.dataMax,
        g = t.translatedThreshold = n.getThreshold(a),
        f = t.barW = Math.max(d, 1 + 2 * o);
      i.pointPadding && i.crisp && (f = Math.ceil(f)), rK.prototype.translate.apply(t), t.points.forEach(function (s) {
        let o = a6(s.yBottom, g),
          m = 999 + Math.abs(o),
          x = s.plotX || 0,
          y = aK(s.plotY, -m, n.len + m),
          b,
          v = Math.min(y, o),
          M = Math.max(y, o) - v,
          k = d,
          w = x + c,
          S = f;
        h && Math.abs(M) < h && (M = h, b = !n.reversed && !s.negative || n.reversed && s.negative, a3(a) && a3(u) && s.y === a && u <= a && (n.min || 0) < a && (p !== u || (n.max || 0) <= a) && (b = !b, s.negative = !s.negative), v = Math.abs(v - g) > h ? o - h : g - (b ? h : 0)), aQ(s.options.pointWidth) && (w -= Math.round(((k = S = Math.ceil(s.options.pointWidth)) - d) / 2)), i.centerInCategory && (w = t.adjustForMissingColumns(w, k, s, l)), s.barX = w, s.pointWidth = k, s.tooltipPos = e.inverted ? [aK(n.len + n.pos - e.plotLeft - y, n.pos - e.plotLeft, n.len + n.pos - e.plotLeft), r.len + r.pos - e.plotTop - w - S / 2, M] : [r.left - e.plotLeft + w + S / 2, aK(y + n.pos - e.plotTop, n.pos - e.plotTop, n.len + n.pos - e.plotTop), M], s.shapeType = t.pointClass.prototype.shapeType || "roundedRect", s.shapeArgs = t.crispCol(w, s.isNull ? g : v, S, s.isNull ? 0 : M);
      }), a1(this, "afterColumnTranslate");
    }
    drawGraph() {
      this.group[this.dense ? "addClass" : "removeClass"]("highcharts-dense-data");
    }
    pointAttribs(t, e) {
      let i = this.options,
        s = this.pointAttrToOptions || {},
        o = s.stroke || "borderColor",
        r = s["stroke-width"] || "borderWidth",
        n,
        a,
        h,
        l = t && t.color || this.color,
        d = t && t[o] || i[o] || l,
        c = t && t.options.dashStyle || i.dashStyle,
        p = t && t[r] || i[r] || this[r] || 0,
        u = a6(t && t.opacity, i.opacity, 1);
      t && this.zones.length && (a = t.getZone(), l = t.options.color || a && (a.color || t.nonZonedColor) || this.color, a && (d = a.borderColor || d, c = a.dashStyle || c, p = a.borderWidth || p)), e && t && (h = (n = a5(i.states[e], t.options.states && t.options.states[e] || {})).brightness, l = n.color || void 0 !== h && aq(l).brighten(n.brightness).get() || l, d = n[o] || d, p = n[r] || p, c = n.dashStyle || c, u = a6(n.opacity, u));
      let g = {
        fill: l,
        stroke: d,
        "stroke-width": p,
        opacity: u
      };
      return c && (g.dashstyle = c), g;
    }
    drawPoints(t = this.points) {
      let e;
      let i = this,
        s = this.chart,
        o = i.options,
        r = s.renderer,
        n = o.animationLimit || 250;
      t.forEach(function (t) {
        let a = t.plotY,
          h = t.graphic,
          l = !!h,
          d = h && s.pointCount < n ? "animate" : "attr";
        a3(a) && null !== t.y ? (e = t.shapeArgs, h && t.hasNewShapeType() && (h = h.destroy()), i.enabledDataSorting && (t.startXPos = i.xAxis.reversed ? -(e && e.width || 0) : i.xAxis.width), !h && (t.graphic = h = r[t.shapeType](e).add(t.group || i.group), h && i.enabledDataSorting && s.hasRendered && s.pointCount < n && (h.attr({
          x: t.startXPos
        }), l = !0, d = "animate")), h && l && h[d](a5(e)), s.styledMode || h[d](i.pointAttribs(t, t.selected && "select")).shadow(!1 !== t.allowShadow && o.shadow), h && (h.addClass(t.getClassName(), !0), h.attr({
          visibility: t.visible ? "inherit" : "hidden"
        }))) : h && (t.graphic = h.destroy());
      });
    }
    drawTracker(t = this.points) {
      let e;
      let i = this,
        s = i.chart,
        o = s.pointer,
        r = function (t) {
          o?.normalize(t);
          let e = o?.getPointFromEvent(t),
            r = !s.scrollablePlotArea || s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop, {
              visiblePlotOnly: !0
            });
          o && e && i.options.enableMouseTracking && r && (o.isDirectTouch = !0, e.onMouseOver(t));
        };
      t.forEach(function (t) {
        e = a2(t.dataLabels) ? t.dataLabels : t.dataLabel ? [t.dataLabel] : [], t.graphic && (t.graphic.element.point = t), e.forEach(function (e) {
          (e.div || e.element).point = t;
        });
      }), i._hasTracking || (i.trackerGroups.forEach(function (t) {
        i[t] && (i[t].addClass("highcharts-tracker").on("mouseover", r).on("mouseout", function (t) {
          o?.onTrackerMouseOut(t);
        }).on("touchstart", r), !s.styledMode && i.options.cursor && i[t].css({
          cursor: i.options.cursor
        }));
      }), i._hasTracking = !0), a1(this, "afterDrawTracker");
    }
    remove() {
      let t = this,
        e = t.chart;
      e.hasRendered && e.series.forEach(function (e) {
        e.type === t.type && (e.isDirty = !0);
      }), rK.prototype.remove.apply(t, arguments);
    }
  }
  a4.defaultOptions = a5(rK.defaultOptions, {
    borderRadius: 3,
    centerInCategory: !1,
    groupPadding: .2,
    marker: null,
    pointPadding: .1,
    minPointLength: 0,
    cropThreshold: 50,
    pointRange: null,
    states: {
      hover: {
        halo: !1,
        brightness: .1
      },
      select: {
        color: "#cccccc",
        borderColor: "#000000"
      }
    },
    dataLabels: {
      align: void 0,
      verticalAlign: void 0,
      y: void 0
    },
    startFromThreshold: !0,
    stickyTracking: !1,
    tooltip: {
      distance: 6
    },
    threshold: 0,
    borderColor: "#ffffff"
  }), a0(a4.prototype, {
    directTouch: !0,
    getSymbol: aZ,
    negStacks: !0,
    trackerGroups: ["group", "dataLabelsGroup"]
  }), ry.registerSeriesType("column", a4);
  let a8 = a4,
    {
      getDeferredAnimation: a7
    } = tU,
    {
      format: ht
    } = ep,
    {
      defined: he,
      extend: hi,
      fireEvent: hs,
      getAlignFactor: ho,
      isArray: hr,
      isString: hn,
      merge: ha,
      objectEach: hh,
      pick: hl,
      pInt: hd,
      splat: hc
    } = tt;
  !function (t) {
    function e() {
      return h(this).some(t => t?.enabled);
    }
    function i(t, e, i, s, o) {
      let {
          chart: r,
          enabledDataSorting: n
        } = this,
        a = this.isCartesian && r.inverted,
        h = t.plotX,
        l = t.plotY,
        d = i.rotation || 0,
        c = he(h) && he(l) && r.isInsidePlot(h, Math.round(l), {
          inverted: a,
          paneCoordinates: !0,
          series: this
        }),
        p = 0 === d && "justify" === hl(i.overflow, n ? "none" : "justify"),
        u = this.visible && !1 !== t.visible && he(h) && (t.series.forceDL || n && !p || c || hl(i.inside, !!this.options.stacking) && s && r.isInsidePlot(h, a ? s.x + 1 : s.y + s.height - 1, {
          inverted: a,
          paneCoordinates: !0,
          series: this
        })),
        g = t.pos();
      if (u && g) {
        var f;
        let h = e.getBBox(),
          l = e.getBBox(void 0, 0);
        if (s = hi({
          x: g[0],
          y: Math.round(g[1]),
          width: 0,
          height: 0
        }, s || {}), "plotEdges" === i.alignTo && this.isCartesian && (s[a ? "x" : "y"] = 0, s[a ? "width" : "height"] = this.yAxis?.len || 0), hi(i, {
          width: h.width,
          height: h.height
        }), f = s, n && this.xAxis && !p && this.setDataLabelStartPos(t, e, o, c, f), e.align(ha(i, {
          width: l.width,
          height: l.height
        }), !1, s, !1), e.alignAttr.x += ho(i.align) * (l.width - h.width), e.alignAttr.y += ho(i.verticalAlign) * (l.height - h.height), e[e.placed ? "animate" : "attr"]({
          "text-align": e.alignAttr["text-align"] || "center",
          x: e.alignAttr.x + (h.width - l.width) / 2,
          y: e.alignAttr.y + (h.height - l.height) / 2,
          rotationOriginX: (e.width || 0) / 2,
          rotationOriginY: (e.height || 0) / 2
        }), p && s.height >= 0) this.justifyDataLabel(e, i, e.alignAttr, h, s, o);else if (hl(i.crop, !0)) {
          let {
            x: t,
            y: i
          } = e.alignAttr;
          u = r.isInsidePlot(t, i, {
            paneCoordinates: !0,
            series: this
          }) && r.isInsidePlot(t + h.width - 1, i + h.height - 1, {
            paneCoordinates: !0,
            series: this
          });
        }
        i.shape && !d && e[o ? "attr" : "animate"]({
          anchorX: g[0],
          anchorY: g[1]
        });
      }
      o && n && (e.placed = !1), u || n && !p ? (e.show(), e.placed = !0) : (e.hide(), e.placed = !1);
    }
    function s() {
      return this.plotGroup("dataLabelsGroup", "data-labels", this.hasRendered ? "inherit" : "hidden", this.options.dataLabels.zIndex || 6);
    }
    function o(t) {
      let e = this.hasRendered || 0,
        i = this.initDataLabelsGroup().attr({
          opacity: +e
        });
      return !e && i && (this.visible && i.show(), this.options.animation ? i.animate({
        opacity: 1
      }, t) : i.attr({
        opacity: 1
      })), i;
    }
    function r(t) {
      let e;
      t = t || this.points;
      let i = this,
        s = i.chart,
        o = i.options,
        r = s.renderer,
        {
          backgroundColor: n,
          plotBackgroundColor: l
        } = s.options.chart,
        d = r.getContrast(hn(l) && l || hn(n) && n || "#000000"),
        c = h(i),
        {
          animation: p,
          defer: u
        } = c[0],
        g = u ? a7(s, p, i) : {
          defer: 0,
          duration: 0
        };
      hs(this, "drawDataLabels"), i.hasDataLabels?.() && (e = this.initDataLabels(g), t.forEach(t => {
        let n = t.dataLabels || [];
        hc(a(c, t.dlOptions || t.options?.dataLabels)).forEach((a, h) => {
          let l = a.enabled && (t.visible || t.dataLabelOnHidden) && (!t.isNull || t.dataLabelOnNull) && function (t, e) {
              let i = e.filter;
              if (i) {
                let e = i.operator,
                  s = t[i.property],
                  o = i.value;
                return ">" === e && s > o || "<" === e && s < o || ">=" === e && s >= o || "<=" === e && s <= o || "==" === e && s == o || "===" === e && s === o || "!=" === e && s != o || "!==" === e && s !== o;
              }
              return !0;
            }(t, a),
            {
              backgroundColor: c,
              borderColor: p,
              distance: u,
              style: g = {}
            } = a,
            f,
            m,
            x,
            y = {},
            b = n[h],
            v = !b,
            M;
          l && (m = he(f = hl(a[t.formatPrefix + "Format"], a.format)) ? ht(f, t, s) : (a[t.formatPrefix + "Formatter"] || a.formatter).call(t, a), x = a.rotation, !s.styledMode && (g.color = hl(a.color, g.color, hn(i.color) ? i.color : void 0, "#000000"), "contrast" === g.color ? ("none" !== c && (M = c), t.contrastColor = r.getContrast("auto" !== M && M || t.color || i.color), g.color = M || !he(u) && a.inside || 0 > hd(u || 0) || o.stacking ? t.contrastColor : d) : delete t.contrastColor, o.cursor && (g.cursor = o.cursor)), y = {
            r: a.borderRadius || 0,
            rotation: x,
            padding: a.padding,
            zIndex: 1
          }, s.styledMode || (y.fill = "auto" === c ? t.color : c, y.stroke = "auto" === p ? t.color : p, y["stroke-width"] = a.borderWidth), hh(y, (t, e) => {
            void 0 === t && delete y[e];
          })), !b || l && he(m) && !!b.div == !!a.useHTML && (b.rotation && a.rotation || b.rotation === a.rotation) || (b = void 0, v = !0), l && he(m) && (b ? y.text = m : (b = r.label(m, 0, 0, a.shape, void 0, void 0, a.useHTML, void 0, "data-label")).addClass(" highcharts-data-label-color-" + t.colorIndex + " " + (a.className || "") + (a.useHTML ? " highcharts-tracker" : "")), b && (b.options = a, b.attr(y), s.styledMode ? g.width && b.css({
            width: g.width,
            textOverflow: g.textOverflow,
            whiteSpace: g.whiteSpace
          }) : b.css(g).shadow(a.shadow), hs(b, "beforeAddingDataLabel", {
            labelOptions: a,
            point: t
          }), b.added || b.add(e), i.alignDataLabel(t, b, a, void 0, v), b.isActive = !0, n[h] && n[h] !== b && n[h].destroy(), n[h] = b));
        });
        let h = n.length;
        for (; h--;) n[h] && n[h].isActive ? n[h].isActive = !1 : (n[h]?.destroy(), n.splice(h, 1));
        t.dataLabel = n[0], t.dataLabels = n;
      })), hs(this, "afterDrawDataLabels");
    }
    function n(t, e, i, s, o, r) {
      let n = this.chart,
        a = e.align,
        h = e.verticalAlign,
        l = t.box ? 0 : t.padding || 0,
        d = n.inverted ? this.yAxis : this.xAxis,
        c = d ? d.left - n.plotLeft : 0,
        p = n.inverted ? this.xAxis : this.yAxis,
        u = p ? p.top - n.plotTop : 0,
        {
          x: g = 0,
          y: f = 0
        } = e,
        m,
        x;
      return (m = (i.x || 0) + l + c) < 0 && ("right" === a && g >= 0 ? (e.align = "left", e.inside = !0) : g -= m, x = !0), (m = (i.x || 0) + s.width - l + c) > n.plotWidth && ("left" === a && g <= 0 ? (e.align = "right", e.inside = !0) : g += n.plotWidth - m, x = !0), (m = i.y + l + u) < 0 && ("bottom" === h && f >= 0 ? (e.verticalAlign = "top", e.inside = !0) : f -= m, x = !0), (m = (i.y || 0) + s.height - l + u) > n.plotHeight && ("top" === h && f <= 0 ? (e.verticalAlign = "bottom", e.inside = !0) : f += n.plotHeight - m, x = !0), x && (e.x = g, e.y = f, t.placed = !r, t.align(e, void 0, o)), x;
    }
    function a(t, e) {
      let i = [],
        s;
      if (hr(t) && !hr(e)) i = t.map(function (t) {
        return ha(t, e);
      });else if (hr(e) && !hr(t)) i = e.map(function (e) {
        return ha(t, e);
      });else if (hr(t) || hr(e)) {
        if (hr(t) && hr(e)) for (s = Math.max(t.length, e.length); s--;) i[s] = ha(t[s], e[s]);
      } else i = ha(t, e);
      return i;
    }
    function h(t) {
      let e = t.chart.options.plotOptions;
      return hc(a(a(e?.series?.dataLabels, e?.[t.type]?.dataLabels), t.options.dataLabels));
    }
    function l(t, e, i, s, o) {
      let r = this.chart,
        n = r.inverted,
        a = this.xAxis,
        h = a.reversed,
        l = ((n ? e.height : e.width) || 0) / 2,
        d = t.pointWidth,
        c = d ? d / 2 : 0;
      e.startXPos = n ? o.x : h ? -l - c : a.width - l + c, e.startYPos = n ? h ? this.yAxis.height - l + c : -l - c : o.y, s ? "hidden" === e.visibility && (e.show(), e.attr({
        opacity: 0
      }).animate({
        opacity: 1
      })) : e.attr({
        opacity: 1
      }).animate({
        opacity: 0
      }, void 0, e.hide), r.hasRendered && (i && e.attr({
        x: e.startXPos,
        y: e.startYPos
      }), e.placed = !0);
    }
    t.compose = function (t) {
      let a = t.prototype;
      a.initDataLabels || (a.initDataLabels = o, a.initDataLabelsGroup = s, a.alignDataLabel = i, a.drawDataLabels = r, a.justifyDataLabel = n, a.setDataLabelStartPos = l, a.hasDataLabels = e);
    };
  }(m || (m = {}));
  let hp = m,
    {
      composed: hu
    } = O,
    {
      series: hg
    } = ry,
    {
      merge: hf,
      pick: hm,
      pushUnique: hx
    } = tt;
  !function (t) {
    function e(t, e, i, s, o) {
      let r = this.chart.inverted,
        n = t.series,
        a = (n.xAxis ? n.xAxis.len : this.chart.plotSizeX) || 0,
        h = (n.yAxis ? n.yAxis.len : this.chart.plotSizeY) || 0,
        l = t.dlBox || t.shapeArgs,
        d = hm(t.below, t.plotY > hm(this.translatedThreshold, h)),
        c = hm(i.inside, !!this.options.stacking);
      if (l) {
        if (s = hf(l), !("allow" === i.overflow && !1 === i.crop)) {
          s.y < 0 && (s.height += s.y, s.y = 0);
          let t = s.y + s.height - h;
          t > 0 && t < s.height - 1 && (s.height -= t);
        }
        r && (s = {
          x: h - s.y - s.height,
          y: a - s.x - s.width,
          width: s.height,
          height: s.width
        }), c || (r ? (s.x += d ? 0 : s.width, s.width = 0) : (s.y += d ? s.height : 0, s.height = 0));
      }
      i.align = hm(i.align, !r || c ? "center" : d ? "right" : "left"), i.verticalAlign = hm(i.verticalAlign, r || c ? "middle" : d ? "top" : "bottom"), hg.prototype.alignDataLabel.call(this, t, e, i, s, o), i.inside && t.contrastColor && e.css({
        color: t.contrastColor
      });
    }
    t.compose = function (t) {
      hp.compose(hg), hx(hu, "ColumnDataLabel") && (t.prototype.alignDataLabel = e);
    };
  }(x || (x = {}));
  let hy = x,
    {
      extend: hb,
      merge: hv
    } = tt;
  class hM extends a8 {}
  hM.defaultOptions = hv(a8.defaultOptions, {}), hb(hM.prototype, {
    inverted: !0
  }), ry.registerSeriesType("bar", hM);
  let {
      column: hk,
      line: hw
    } = ry.seriesTypes,
    {
      addEvent: hS,
      extend: hA,
      merge: hT
    } = tt;
  class hP extends hw {
    applyJitter() {
      let t = this,
        e = this.options.jitter,
        i = this.points.length;
      e && this.points.forEach(function (s, o) {
        ["x", "y"].forEach(function (r, n) {
          if (e[r] && !s.isNull) {
            let a = `plot${r.toUpperCase()}`,
              h = t[`${r}Axis`],
              l = e[r] * h.transA;
            if (h && !h.logarithmic) {
              let t = Math.max(0, (s[a] || 0) - l),
                e = Math.min(h.len, (s[a] || 0) + l);
              s[a] = t + (e - t) * function (t) {
                let e = 1e4 * Math.sin(t);
                return e - Math.floor(e);
              }(o + n * i), "x" === r && (s.clientX = s.plotX);
            }
          }
        });
      });
    }
    drawGraph() {
      this.options.lineWidth ? super.drawGraph() : this.graph && (this.graph = this.graph.destroy());
    }
  }
  hP.defaultOptions = hT(hw.defaultOptions, {
    lineWidth: 0,
    findNearestPointBy: "xy",
    jitter: {
      x: 0,
      y: 0
    },
    marker: {
      enabled: !0
    },
    tooltip: {
      headerFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 0.8em"> {series.name}</span><br/>',
      pointFormat: "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
    }
  }), hA(hP.prototype, {
    drawTracker: hk.prototype.drawTracker,
    sorted: !1,
    requireSorting: !1,
    noSharedTooltip: !0,
    trackerGroups: ["group", "markerGroup", "dataLabelsGroup"]
  }), hS(hP, "afterTranslate", function () {
    this.applyJitter();
  }), ry.registerSeriesType("scatter", hP);
  let {
      deg2rad: hC
    } = O,
    {
      fireEvent: hO,
      isNumber: hE,
      pick: hL,
      relativeLength: hB
    } = tt;
  !function (t) {
    t.getCenter = function () {
      let t = this.options,
        e = this.chart,
        i = 2 * (t.slicedOffset || 0),
        s = e.plotWidth - 2 * i,
        o = e.plotHeight - 2 * i,
        r = t.center,
        n = Math.min(s, o),
        a = t.thickness,
        h,
        l = t.size,
        d = t.innerSize || 0,
        c,
        p;
      "string" == typeof l && (l = parseFloat(l)), "string" == typeof d && (d = parseFloat(d));
      let u = [hL(r?.[0], "50%"), hL(r?.[1], "50%"), hL(l && l < 0 ? void 0 : t.size, "100%"), hL(d && d < 0 ? void 0 : t.innerSize || 0, "0%")];
      for (!e.angular || this instanceof rK || (u[3] = 0), c = 0; c < 4; ++c) p = u[c], h = c < 2 || 2 === c && /%$/.test(p), u[c] = hB(p, [s, o, n, u[2]][c]) + (h ? i : 0);
      return u[3] > u[2] && (u[3] = u[2]), hE(a) && 2 * a < u[2] && a > 0 && (u[3] = u[2] - 2 * a), hO(this, "afterGetCenter", {
        positions: u
      }), u;
    }, t.getStartAndEndRadians = function (t, e) {
      let i = hE(t) ? t : 0,
        s = hE(e) && e > i && e - i < 360 ? e : i + 360;
      return {
        start: hC * (i + -90),
        end: hC * (s + -90)
      };
    };
  }(y || (y = {}));
  let hI = y,
    {
      setAnimation: hD
    } = tU,
    {
      addEvent: hR,
      defined: hN,
      extend: hz,
      isNumber: hW,
      pick: hG,
      relativeLength: hH
    } = tt;
  class hF extends oq {
    getConnectorPath(t) {
      let e = t.dataLabelPosition,
        i = t.options || {},
        s = i.connectorShape,
        o = this.connectorShapes[s] || s;
      return e && o.call(this, {
        ...e.computed,
        alignment: e.alignment
      }, e.connectorPosition, i) || [];
    }
    getTranslate() {
      return this.sliced && this.slicedTranslation || {
        translateX: 0,
        translateY: 0
      };
    }
    haloPath(t) {
      let e = this.shapeArgs;
      return this.sliced || !this.visible ? [] : this.series.chart.renderer.symbols.arc(e.x, e.y, e.r + t, e.r + t, {
        innerR: e.r - 1,
        start: e.start,
        end: e.end,
        borderRadius: e.borderRadius
      });
    }
    constructor(t, e, i) {
      super(t, e, i), this.half = 0, this.name ?? (this.name = "Slice");
      let s = t => {
        this.slice("select" === t.type);
      };
      hR(this, "select", s), hR(this, "unselect", s);
    }
    isValid() {
      return hW(this.y) && this.y >= 0;
    }
    setVisible(t, e = !0) {
      t !== this.visible && this.update({
        visible: t ?? !this.visible
      }, e, void 0, !1);
    }
    slice(t, e, i) {
      let s = this.series;
      hD(i, s.chart), e = hG(e, !0), this.sliced = this.options.sliced = t = hN(t) ? t : !this.sliced, s.options.data[s.data.indexOf(this)] = this.options, this.graphic && this.graphic.animate(this.getTranslate());
    }
  }
  hz(hF.prototype, {
    connectorShapes: {
      fixedOffset: function (t, e, i) {
        let s = e.breakAt,
          o = e.touchingSliceAt,
          r = i.softConnector ? ["C", t.x + ("left" === t.alignment ? -5 : 5), t.y, 2 * s.x - o.x, 2 * s.y - o.y, s.x, s.y] : ["L", s.x, s.y];
        return [["M", t.x, t.y], r, ["L", o.x, o.y]];
      },
      straight: function (t, e) {
        let i = e.touchingSliceAt;
        return [["M", t.x, t.y], ["L", i.x, i.y]];
      },
      crookedLine: function (t, e, i) {
        let {
            angle: s = this.angle || 0,
            breakAt: o,
            touchingSliceAt: r
          } = e,
          {
            series: n
          } = this,
          [a, h, l] = n.center,
          d = l / 2,
          {
            plotLeft: c,
            plotWidth: p
          } = n.chart,
          u = "left" === t.alignment,
          {
            x: g,
            y: f
          } = t,
          m = o.x;
        if (i.crookDistance) {
          let t = hH(i.crookDistance, 1);
          m = u ? a + d + (p + c - a - d) * (1 - t) : c + (a - d) * t;
        } else m = a + (h - f) * Math.tan(s - Math.PI / 2);
        let x = [["M", g, f]];
        return (u ? m <= g && m >= o.x : m >= g && m <= o.x) && x.push(["L", m, f]), x.push(["L", o.x, o.y], ["L", r.x, r.y]), x;
      }
    }
  });
  let {
      getStartAndEndRadians: hX
    } = hI,
    {
      noop: hY
    } = O,
    {
      clamp: hj,
      extend: hU,
      fireEvent: hV,
      merge: h$,
      pick: h_
    } = tt;
  class hq extends rK {
    animate(t) {
      let e = this,
        i = e.points,
        s = e.startAngleRad;
      t || i.forEach(function (t) {
        let i = t.graphic,
          o = t.shapeArgs;
        i && o && (i.attr({
          r: h_(t.startR, e.center && e.center[3] / 2),
          start: s,
          end: s
        }), i.animate({
          r: o.r,
          start: o.start,
          end: o.end
        }, e.options.animation));
      });
    }
    drawEmpty() {
      let t, e;
      let i = this.startAngleRad,
        s = this.endAngleRad,
        o = this.options;
      0 === this.total && this.center ? (t = this.center[0], e = this.center[1], this.graph || (this.graph = this.chart.renderer.arc(t, e, this.center[1] / 2, 0, i, s).addClass("highcharts-empty-series").add(this.group)), this.graph.attr({
        d: is.arc(t, e, this.center[2] / 2, 0, {
          start: i,
          end: s,
          innerR: this.center[3] / 2
        })
      }), this.chart.styledMode || this.graph.attr({
        "stroke-width": o.borderWidth,
        fill: o.fillColor || "none",
        stroke: o.color || "#cccccc"
      })) : this.graph && (this.graph = this.graph.destroy());
    }
    drawPoints() {
      let t = this.chart.renderer;
      this.points.forEach(function (e) {
        e.graphic && e.hasNewShapeType() && (e.graphic = e.graphic.destroy()), e.graphic || (e.graphic = t[e.shapeType](e.shapeArgs).add(e.series.group), e.delayedRendering = !0);
      });
    }
    generatePoints() {
      super.generatePoints(), this.updateTotals();
    }
    getX(t, e, i, s) {
      let o = this.center,
        r = this.radii ? this.radii[i.index] || 0 : o[2] / 2,
        n = s.dataLabelPosition,
        a = n?.distance || 0,
        h = Math.asin(hj((t - o[1]) / (r + a), -1, 1));
      return o[0] + Math.cos(h) * (r + a) * (e ? -1 : 1) + (a > 0 ? (e ? -1 : 1) * (s.padding || 0) : 0);
    }
    hasData() {
      return !!this.dataTable.rowCount;
    }
    redrawPoints() {
      let t, e, i, s;
      let o = this,
        r = o.chart;
      this.drawEmpty(), o.group && !r.styledMode && o.group.shadow(o.options.shadow), o.points.forEach(function (n) {
        let a = {};
        e = n.graphic, !n.isNull && e ? (s = n.shapeArgs, t = n.getTranslate(), r.styledMode || (i = o.pointAttribs(n, n.selected && "select")), n.delayedRendering ? (e.setRadialReference(o.center).attr(s).attr(t), r.styledMode || e.attr(i).attr({
          "stroke-linejoin": "round"
        }), n.delayedRendering = !1) : (e.setRadialReference(o.center), r.styledMode || h$(!0, a, i), h$(!0, a, s, t), e.animate(a)), e.attr({
          visibility: n.visible ? "inherit" : "hidden"
        }), e.addClass(n.getClassName(), !0)) : e && (n.graphic = e.destroy());
      });
    }
    sortByAngle(t, e) {
      t.sort(function (t, i) {
        return void 0 !== t.angle && (i.angle - t.angle) * e;
      });
    }
    translate(t) {
      hV(this, "translate"), this.generatePoints();
      let e = this.options,
        i = e.slicedOffset,
        s = hX(e.startAngle, e.endAngle),
        o = this.startAngleRad = s.start,
        r = (this.endAngleRad = s.end) - o,
        n = this.points,
        a = e.ignoreHiddenPoint,
        h = n.length,
        l,
        d,
        c,
        p,
        u,
        g,
        f,
        m = 0;
      for (t || (this.center = t = this.getCenter()), g = 0; g < h; g++) {
        f = n[g], l = o + m * r, f.isValid() && (!a || f.visible) && (m += f.percentage / 100), d = o + m * r;
        let e = {
          x: t[0],
          y: t[1],
          r: t[2] / 2,
          innerR: t[3] / 2,
          start: Math.round(1e3 * l) / 1e3,
          end: Math.round(1e3 * d) / 1e3
        };
        f.shapeType = "arc", f.shapeArgs = e, (c = (d + l) / 2) > 1.5 * Math.PI ? c -= 2 * Math.PI : c < -Math.PI / 2 && (c += 2 * Math.PI), f.slicedTranslation = {
          translateX: Math.round(Math.cos(c) * i),
          translateY: Math.round(Math.sin(c) * i)
        }, p = Math.cos(c) * t[2] / 2, u = Math.sin(c) * t[2] / 2, f.tooltipPos = [t[0] + .7 * p, t[1] + .7 * u], f.half = c < -Math.PI / 2 || c > Math.PI / 2 ? 1 : 0, f.angle = c;
      }
      hV(this, "afterTranslate");
    }
    updateTotals() {
      let t = this.points,
        e = t.length,
        i = this.options.ignoreHiddenPoint,
        s,
        o,
        r = 0;
      for (s = 0; s < e; s++) (o = t[s]).isValid() && (!i || o.visible) && (r += o.y);
      for (s = 0, this.total = r; s < e; s++) (o = t[s]).percentage = r > 0 && (o.visible || !i) ? o.y / r * 100 : 0, o.total = r;
    }
  }
  hq.defaultOptions = h$(rK.defaultOptions, {
    borderRadius: 3,
    center: [null, null],
    clip: !1,
    colorByPoint: !0,
    dataLabels: {
      connectorPadding: 5,
      connectorShape: "crookedLine",
      crookDistance: void 0,
      distance: 30,
      enabled: !0,
      formatter: function () {
        return this.isNull ? void 0 : this.name;
      },
      softConnector: !0,
      x: 0
    },
    fillColor: void 0,
    ignoreHiddenPoint: !0,
    inactiveOtherPoints: !0,
    legendType: "point",
    marker: null,
    size: null,
    showInLegend: !1,
    slicedOffset: 10,
    stickyTracking: !1,
    tooltip: {
      followPointer: !0
    },
    borderColor: "#ffffff",
    borderWidth: 1,
    lineWidth: void 0,
    states: {
      hover: {
        brightness: .1
      }
    }
  }), hU(hq.prototype, {
    axisTypes: [],
    directTouch: !0,
    drawGraph: void 0,
    drawTracker: a8.prototype.drawTracker,
    getCenter: hI.getCenter,
    getSymbol: hY,
    invertible: !1,
    isCartesian: !1,
    noSharedTooltip: !0,
    pointAttribs: a8.prototype.pointAttribs,
    pointClass: hF,
    requireSorting: !1,
    searchPoint: hY,
    trackerGroups: ["group", "dataLabelsGroup"]
  }), ry.registerSeriesType("pie", hq);
  let {
      composed: hZ,
      noop: hK
    } = O,
    {
      distribute: hJ
    } = ey,
    {
      series: hQ
    } = ry,
    {
      arrayMax: h0,
      clamp: h1,
      defined: h2,
      pick: h3,
      pushUnique: h5,
      relativeLength: h6
    } = tt;
  !function (t) {
    let e = {
      radialDistributionY: function (t, e) {
        return (e.dataLabelPosition?.top || 0) + t.distributeBox.pos;
      },
      radialDistributionX: function (t, e, i, s, o) {
        let r = o.dataLabelPosition;
        return t.getX(i < (r?.top || 0) + 2 || i > (r?.bottom || 0) - 2 ? s : i, e.half, e, o);
      },
      justify: function (t, e, i, s) {
        return s[0] + (t.half ? -1 : 1) * (i + (e.dataLabelPosition?.distance || 0));
      },
      alignToPlotEdges: function (t, e, i, s) {
        let o = t.getBBox().width;
        return e ? o + s : i - o - s;
      },
      alignToConnectors: function (t, e, i, s) {
        let o = 0,
          r;
        return t.forEach(function (t) {
          (r = t.dataLabel.getBBox().width) > o && (o = r);
        }), e ? o + s : i - o - s;
      }
    };
    function i(t, e) {
      let i = Math.PI / 2,
        {
          start: s = 0,
          end: o = 0
        } = t.shapeArgs || {},
        r = t.angle || 0;
      e > 0 && s < i && o > i && r > i / 2 && r < 1.5 * i && (r = r <= i ? Math.max(i / 2, (s + i) / 2) : Math.min(1.5 * i, (i + o) / 2));
      let {
          center: n,
          options: a
        } = this,
        h = n[2] / 2,
        l = Math.cos(r),
        d = Math.sin(r),
        c = n[0] + l * h,
        p = n[1] + d * h,
        u = Math.min((a.slicedOffset || 0) + (a.borderWidth || 0), e / 5);
      return {
        natural: {
          x: c + l * e,
          y: p + d * e
        },
        computed: {},
        alignment: e < 0 ? "center" : t.half ? "right" : "left",
        connectorPosition: {
          angle: r,
          breakAt: {
            x: c + l * u,
            y: p + d * u
          },
          touchingSliceAt: {
            x: c,
            y: p
          }
        },
        distance: e
      };
    }
    function s() {
      let t = this,
        e = t.points,
        i = t.chart,
        s = i.plotWidth,
        o = i.plotHeight,
        r = i.plotLeft,
        n = Math.round(i.chartWidth / 3),
        a = t.center,
        h = a[2] / 2,
        l = a[1],
        d = [[], []],
        c = [0, 0, 0, 0],
        p = t.dataLabelPositioners,
        u,
        g,
        f,
        m = 0;
      t.visible && t.hasDataLabels?.() && (e.forEach(t => {
        (t.dataLabels || []).forEach(t => {
          t.shortened && (t.attr({
            width: "auto"
          }).css({
            width: "auto",
            textOverflow: "clip"
          }), t.shortened = !1);
        });
      }), hQ.prototype.drawDataLabels.apply(t), e.forEach(t => {
        (t.dataLabels || []).forEach((e, i) => {
          let s = a[2] / 2,
            o = e.options,
            r = h6(o?.distance || 0, s);
          0 === i && d[t.half].push(t), !h2(o?.style?.width) && e.getBBox().width > n && (e.css({
            width: Math.round(.7 * n) + "px"
          }), e.shortened = !0), e.dataLabelPosition = this.getDataLabelPosition(t, r), m = Math.max(m, r);
        });
      }), d.forEach((e, n) => {
        let d = e.length,
          u = [],
          x,
          y,
          b = 0,
          v;
        d && (t.sortByAngle(e, n - .5), m > 0 && (x = Math.max(0, l - h - m), y = Math.min(l + h + m, i.plotHeight), e.forEach(t => {
          (t.dataLabels || []).forEach(e => {
            let s = e.dataLabelPosition;
            s && s.distance > 0 && (s.top = Math.max(0, l - h - s.distance), s.bottom = Math.min(l + h + s.distance, i.plotHeight), b = e.getBBox().height || 21, e.lineHeight = i.renderer.fontMetrics(e.text || e).h + 2 * e.padding, t.distributeBox = {
              target: (e.dataLabelPosition?.natural.y || 0) - s.top + e.lineHeight / 2,
              size: b,
              rank: t.y
            }, u.push(t.distributeBox));
          });
        }), hJ(u, v = y + b - x, v / 5)), e.forEach(i => {
          (i.dataLabels || []).forEach(l => {
            let d = l.options || {},
              m = i.distributeBox,
              x = l.dataLabelPosition,
              y = x?.natural.y || 0,
              b = d.connectorPadding || 0,
              v = l.lineHeight || 21,
              M = (v - l.getBBox().height) / 2,
              k = 0,
              w = y,
              S = "inherit";
            if (x) {
              if (u && h2(m) && x.distance > 0 && (void 0 === m.pos ? S = "hidden" : (f = m.size, w = p.radialDistributionY(i, l))), d.justify) k = p.justify(i, l, h, a);else switch (d.alignTo) {
                case "connectors":
                  k = p.alignToConnectors(e, n, s, r);
                  break;
                case "plotEdges":
                  k = p.alignToPlotEdges(l, n, s, r);
                  break;
                default:
                  k = p.radialDistributionX(t, i, w - M, y, l);
              }
              if (x.attribs = {
                visibility: S,
                align: x.alignment
              }, x.posAttribs = {
                x: k + (d.x || 0) + ({
                  left: b,
                  right: -b
                }[x.alignment] || 0),
                y: w + (d.y || 0) - v / 2
              }, x.computed.x = k, x.computed.y = w - M, h3(d.crop, !0)) {
                let t;
                k - (g = l.getBBox().width) < b && 1 === n ? (t = Math.round(g - k + b), c[3] = Math.max(t, c[3])) : k + g > s - b && 0 === n && (t = Math.round(k + g - s + b), c[1] = Math.max(t, c[1])), w - f / 2 < 0 ? c[0] = Math.max(Math.round(-w + f / 2), c[0]) : w + f / 2 > o && (c[2] = Math.max(Math.round(w + f / 2 - o), c[2])), x.sideOverflow = t;
              }
            }
          });
        }));
      }), (0 === h0(c) || this.verifyDataLabelOverflow(c)) && (this.placeDataLabels(), this.points.forEach(e => {
        (e.dataLabels || []).forEach(s => {
          let {
              connectorColor: o,
              connectorWidth: r = 1
            } = s.options || {},
            n = s.dataLabelPosition;
          if (r) {
            let a;
            u = s.connector, n && n.distance > 0 ? (a = !u, u || (s.connector = u = i.renderer.path().addClass("highcharts-data-label-connector  highcharts-color-" + e.colorIndex + (e.className ? " " + e.className : "")).add(t.dataLabelsGroup)), i.styledMode || u.attr({
              "stroke-width": r,
              stroke: o || e.color || "#666666"
            }), u[a ? "attr" : "animate"]({
              d: e.getConnectorPath(s)
            }), u.attr({
              visibility: n.attribs?.visibility
            })) : u && (s.connector = u.destroy());
          }
        });
      })));
    }
    function o() {
      this.points.forEach(t => {
        (t.dataLabels || []).forEach(t => {
          let e = t.dataLabelPosition;
          e ? (e.sideOverflow && (t.css({
            width: Math.max(t.getBBox().width - e.sideOverflow, 0) + "px",
            textOverflow: (t.options?.style || {}).textOverflow || "ellipsis"
          }), t.shortened = !0), t.attr(e.attribs), t[t.moved ? "animate" : "attr"](e.posAttribs), t.moved = !0) : t && t.attr({
            y: -9999
          });
        }), delete t.distributeBox;
      }, this);
    }
    function r(t) {
      let e = this.center,
        i = this.options,
        s = i.center,
        o = i.minSize || 80,
        r = o,
        n = null !== i.size;
      return !n && (null !== s[0] ? r = Math.max(e[2] - Math.max(t[1], t[3]), o) : (r = Math.max(e[2] - t[1] - t[3], o), e[0] += (t[3] - t[1]) / 2), null !== s[1] ? r = h1(r, o, e[2] - Math.max(t[0], t[2])) : (r = h1(r, o, e[2] - t[0] - t[2]), e[1] += (t[0] - t[2]) / 2), r < e[2] ? (e[2] = r, e[3] = Math.min(i.thickness ? Math.max(0, r - 2 * i.thickness) : Math.max(0, h6(i.innerSize || 0, r)), r), this.translate(e), this.drawDataLabels && this.drawDataLabels()) : n = !0), n;
    }
    t.compose = function (t) {
      if (hp.compose(hQ), h5(hZ, "PieDataLabel")) {
        let n = t.prototype;
        n.dataLabelPositioners = e, n.alignDataLabel = hK, n.drawDataLabels = s, n.getDataLabelPosition = i, n.placeDataLabels = o, n.verifyDataLabelOverflow = r;
      }
    };
  }(b || (b = {}));
  let h9 = b;
  !function (t) {
    t.getCenterOfPoints = function (t) {
      let e = t.reduce((t, e) => (t.x += e.x, t.y += e.y, t), {
        x: 0,
        y: 0
      });
      return {
        x: e.x / t.length,
        y: e.y / t.length
      };
    }, t.getDistanceBetweenPoints = function (t, e) {
      return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
    }, t.getAngleBetweenPoints = function (t, e) {
      return Math.atan2(e.x - t.x, e.y - t.y);
    }, t.pointInPolygon = function ({
      x: t,
      y: e
    }, i) {
      let s = i.length,
        o,
        r,
        n = !1;
      for (o = 0, r = s - 1; o < s; r = o++) {
        let [s, a] = i[o],
          [h, l] = i[r];
        a > e != l > e && t < (h - s) * (e - a) / (l - a) + s && (n = !n);
      }
      return n;
    };
  }(v || (v = {}));
  let {
      pointInPolygon: h4
    } = v,
    {
      addEvent: h8,
      fireEvent: h7,
      objectEach: lt,
      pick: le
    } = tt;
  function li(t) {
    let e = t.length,
      i = (t, e) => !(e.x >= t.x + t.width || e.x + e.width <= t.x || e.y >= t.y + t.height || e.y + e.height <= t.y),
      s = (t, e) => {
        for (let i of t) if (h4({
          x: i[0],
          y: i[1]
        }, e)) return !0;
        return !1;
      },
      o,
      r,
      n,
      a,
      h,
      l = !1;
    for (let i = 0; i < e; i++) (o = t[i]) && (o.oldOpacity = o.opacity, o.newOpacity = 1, o.absoluteBox = function (t) {
      if (t && (!t.alignAttr || t.placed)) {
        let e = t.box ? 0 : t.padding || 0,
          i = t.alignAttr || {
            x: t.attr("x"),
            y: t.attr("y")
          },
          s = t.getBBox();
        return t.width = s.width, t.height = s.height, {
          x: i.x + (t.parentGroup?.translateX || 0) + e,
          y: i.y + (t.parentGroup?.translateY || 0) + e,
          width: (t.width || 0) - 2 * e,
          height: (t.height || 0) - 2 * e,
          polygon: s?.polygon
        };
      }
    }(o));
    t.sort((t, e) => (e.labelrank || 0) - (t.labelrank || 0));
    for (let o = 0; o < e; ++o) {
      a = (r = t[o]) && r.absoluteBox;
      let l = a?.polygon;
      for (let d = o + 1; d < e; ++d) {
        h = (n = t[d]) && n.absoluteBox;
        let e = !1;
        if (a && h && r !== n && 0 !== r.newOpacity && 0 !== n.newOpacity && "hidden" !== r.visibility && "hidden" !== n.visibility) {
          let t = h.polygon;
          if (l && t && l !== t ? s(l, t) && (e = !0) : i(a, h) && (e = !0), e) {
            let t = r.labelrank < n.labelrank ? r : n,
              e = t.text;
            t.newOpacity = 0, e?.element.querySelector("textPath") && e.hide();
          }
        }
      }
    }
    for (let e of t) ls(e, this) && (l = !0);
    l && h7(this, "afterHideAllOverlappingLabels");
  }
  function ls(t, e) {
    let i,
      s,
      o = !1;
    return t && (s = t.newOpacity, t.oldOpacity !== s && (t.hasClass("highcharts-data-label") ? (t[s ? "removeClass" : "addClass"]("highcharts-data-label-hidden"), i = function () {
      e.styledMode || t.css({
        pointerEvents: s ? "auto" : "none"
      });
    }, o = !0, t[t.isOld ? "animate" : "attr"]({
      opacity: s
    }, void 0, i), h7(e, "afterHideOverlappingLabel")) : t.attr({
      opacity: s
    })), t.isOld = !0), o;
  }
  function lo() {
    let t = this,
      e = [];
    for (let i of t.labelCollectors || []) e = e.concat(i());
    for (let i of t.yAxis || []) i.stacking && i.options.stackLabels && !i.options.stackLabels.allowOverlap && lt(i.stacking.stacks, t => {
      lt(t, t => {
        t.label && e.push(t.label);
      });
    });
    for (let i of t.series || []) if (i.visible && i.hasDataLabels?.()) {
      let s = i => {
        for (let s of i) s.visible && (s.dataLabels || []).forEach(i => {
          let o = i.options || {};
          i.labelrank = le(o.labelrank, s.labelrank, s.shapeArgs?.height), o.allowOverlap ?? Number(o.distance) > 0 ? (i.oldOpacity = i.opacity, i.newOpacity = 1, ls(i, t)) : e.push(i);
        });
      };
      s(i.nodes || []), s(i.points);
    }
    this.hideOverlappingLabels(e);
  }
  let lr = {
      compose: function (t) {
        let e = t.prototype;
        e.hideOverlappingLabels || (e.hideOverlappingLabels = li, h8(t, "render", lo));
      }
    },
    {
      defaultOptions: ln
    } = tS,
    {
      noop: la
    } = O,
    {
      addEvent: lh,
      extend: ll,
      isObject: ld,
      merge: lc,
      relativeLength: lp
    } = tt,
    lu = {
      radius: 0,
      scope: "stack",
      where: void 0
    },
    lg = la,
    lf = la;
  function lm(t, e, i, s, o = {}) {
    let r = lg(t, e, i, s, o),
      {
        innerR: n = 0,
        r: a = i,
        start: h = 0,
        end: l = 0
      } = o;
    if (o.open || !o.borderRadius) return r;
    let d = l - h,
      c = Math.sin(d / 2),
      p = Math.max(Math.min(lp(o.borderRadius || 0, a - n), (a - n) / 2, a * c / (1 + c)), 0),
      u = Math.min(p, d / Math.PI * 2 * n),
      g = r.length - 1;
    for (; g--;) !function (t, e, i) {
      let s, o, r;
      let n = t[e],
        a = t[e + 1];
      if ("Z" === a[0] && (a = t[0]), ("M" === n[0] || "L" === n[0]) && "A" === a[0] ? (s = n, o = a, r = !0) : "A" === n[0] && ("M" === a[0] || "L" === a[0]) && (s = a, o = n), s && o && o.params) {
        let n = o[1],
          a = o[5],
          h = o.params,
          {
            start: l,
            end: d,
            cx: c,
            cy: p
          } = h,
          u = a ? n - i : n + i,
          g = u ? Math.asin(i / u) : 0,
          f = a ? g : -g,
          m = Math.cos(g) * u;
        r ? (h.start = l + f, s[1] = c + m * Math.cos(l), s[2] = p + m * Math.sin(l), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + n * Math.cos(h.start), p + n * Math.sin(h.start)])) : (h.end = d - f, o[6] = c + n * Math.cos(h.end), o[7] = p + n * Math.sin(h.end), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + m * Math.cos(d), p + m * Math.sin(d)])), o[4] = Math.abs(h.end - h.start) < Math.PI ? 0 : 1;
      }
    }(r, g, g > 1 ? u : p);
    return r;
  }
  function lx() {
    if (this.options.borderRadius && !(this.chart.is3d && this.chart.is3d())) {
      let {
          options: t,
          yAxis: e
        } = this,
        i = "percent" === t.stacking,
        s = ln.plotOptions?.[this.type]?.borderRadius,
        o = ly(t.borderRadius, ld(s) ? s : {}),
        r = e.options.reversed;
      for (let s of this.points) {
        let {
          shapeArgs: n
        } = s;
        if ("roundedRect" === s.shapeType && n) {
          let {
              width: a = 0,
              height: h = 0,
              y: l = 0
            } = n,
            d = l,
            c = h;
          if ("stack" === o.scope && s.stackTotal) {
            let o = e.translate(i ? 100 : s.stackTotal, !1, !0, !1, !0),
              r = e.translate(t.threshold || 0, !1, !0, !1, !0),
              n = this.crispCol(0, Math.min(o, r), 0, Math.abs(o - r));
            d = n.y, c = n.height;
          }
          let p = (s.negative ? -1 : 1) * (r ? -1 : 1) == -1,
            u = o.where;
          !u && this.is("waterfall") && Math.abs((s.yBottom || 0) - (this.translatedThreshold || 0)) > this.borderWidth && (u = "all"), u || (u = "end");
          let g = Math.min(lp(o.radius, a), a / 2, "all" === u ? h / 2 : 1 / 0) || 0;
          "end" === u && (p && (d -= g), c += g), ll(n, {
            brBoxHeight: c,
            brBoxY: d,
            r: g
          });
        }
      }
    }
  }
  function ly(t, e) {
    return ld(t) || (t = {
      radius: t || 0
    }), lc(lu, e, t);
  }
  function lb() {
    let t = ly(this.options.borderRadius);
    for (let e of this.points) {
      let i = e.shapeArgs;
      i && (i.borderRadius = lp(t.radius, (i.r || 0) - (i.innerR || 0)));
    }
  }
  function lv(t, e, i, s, o = {}) {
    let r = lf(t, e, i, s, o),
      {
        r: n = 0,
        brBoxHeight: a = s,
        brBoxY: h = e
      } = o,
      l = e - h,
      d = h + a - (e + s),
      c = l - n > -.1 ? 0 : n,
      p = d - n > -.1 ? 0 : n,
      u = Math.max(c && l, 0),
      g = Math.max(p && d, 0),
      f = [t + c, e],
      m = [t + i - c, e],
      x = [t + i, e + c],
      y = [t + i, e + s - p],
      b = [t + i - p, e + s],
      v = [t + p, e + s],
      M = [t, e + s - p],
      k = [t, e + c],
      w = (t, e) => Math.sqrt(Math.pow(t, 2) - Math.pow(e, 2));
    if (u) {
      let t = w(c, c - u);
      f[0] -= t, m[0] += t, x[1] = k[1] = e + c - u;
    }
    if (s < c - u) {
      let o = w(c, c - u - s);
      x[0] = y[0] = t + i - c + o, b[0] = Math.min(x[0], b[0]), v[0] = Math.max(y[0], v[0]), M[0] = k[0] = t + c - o, x[1] = k[1] = e + s;
    }
    if (g) {
      let t = w(p, p - g);
      b[0] += t, v[0] -= t, y[1] = M[1] = e + s - p + g;
    }
    if (s < p - g) {
      let o = w(p, p - g - s);
      x[0] = y[0] = t + i - p + o, m[0] = Math.min(x[0], m[0]), f[0] = Math.max(y[0], f[0]), M[0] = k[0] = t + p - o, y[1] = M[1] = e;
    }
    return r.length = 0, r.push(["M", ...f], ["L", ...m], ["A", c, c, 0, 0, 1, ...x], ["L", ...y], ["A", p, p, 0, 0, 1, ...b], ["L", ...v], ["A", p, p, 0, 0, 1, ...M], ["L", ...k], ["A", c, c, 0, 0, 1, ...f], ["Z"]), r;
  }
  let {
    diffObjects: lM,
    extend: lk,
    find: lw,
    merge: lS,
    pick: lA,
    uniqueKey: lT
  } = tt;
  !function (t) {
    function e(t, e) {
      let i = t.condition;
      (i.callback || function () {
        return this.chartWidth <= lA(i.maxWidth, Number.MAX_VALUE) && this.chartHeight <= lA(i.maxHeight, Number.MAX_VALUE) && this.chartWidth >= lA(i.minWidth, 0) && this.chartHeight >= lA(i.minHeight, 0);
      }).call(this) && e.push(t._id);
    }
    function i(t, e) {
      let i = this.options.responsive,
        s = this.currentResponsive,
        o = [],
        r;
      !e && i && i.rules && i.rules.forEach(t => {
        void 0 === t._id && (t._id = lT()), this.matchResponsiveRule(t, o);
      }, this);
      let n = lS(...o.map(t => lw((i || {}).rules || [], e => e._id === t)).map(t => t && t.chartOptions));
      n.isResponsiveOptions = !0, o = o.toString() || void 0;
      let a = s && s.ruleIds;
      o === a || (s && (this.currentResponsive = void 0, this.updatingResponsive = !0, this.update(s.undoOptions, t, !0), this.updatingResponsive = !1), o ? ((r = lM(n, this.options, !0, this.collectionsWithUpdate)).isResponsiveOptions = !0, this.currentResponsive = {
        ruleIds: o,
        mergedOptions: n,
        undoOptions: r
      }, this.updatingResponsive || this.update(n, t, !0)) : this.currentResponsive = void 0);
    }
    t.compose = function (t) {
      let s = t.prototype;
      return s.matchResponsiveRule || lk(s, {
        matchResponsiveRule: e,
        setResponsive: i
      }), t;
    };
  }(M || (M = {}));
  let lP = M;
  function lC(t, e, i, s) {
    return [["M", t, e + s / 2], ["L", t + i, e], ["L", t, e + s / 2], ["L", t + i, e + s]];
  }
  function lO(t, e, i, s) {
    return lC(t, e, i / 2, s);
  }
  function lE(t, e, i, s) {
    return [["M", t + i, e], ["L", t, e + s / 2], ["L", t + i, e + s], ["Z"]];
  }
  function lL(t, e, i, s) {
    return lE(t, e, i / 2, s);
  }
  O.AST = t6, O.Axis = sU, O.Chart = nK, O.Color = tO, O.DataLabel = hp, O.DataTableCore = rl, O.Fx = tD, O.HTMLElement = i5, O.Legend = nd, O.LegendSymbol = ru, O.OverlappingDataLabels = O.OverlappingDataLabels || lr, O.PlotLineOrBand = or, O.Point = oq, O.Pointer = ro, O.RendererRegistry = eu, O.Series = rK, O.SeriesRegistry = ry, O.StackItem = aa, O.SVGElement = eJ, O.SVGRenderer = iU, O.Templating = ep, O.Tick = sg, O.Time = ty, O.Tooltip = oO, O.animate = tU.animate, O.animObject = tU.animObject, O.chart = nK.chart, O.color = tO.parse, O.dateFormat = ep.dateFormat, O.defaultOptions = tS.defaultOptions, O.distribute = ey.distribute, O.format = ep.format, O.getDeferredAnimation = tU.getDeferredAnimation, O.getOptions = tS.getOptions, O.numberFormat = ep.numberFormat, O.seriesType = ry.seriesType, O.setAnimation = tU.setAnimation, O.setOptions = tS.setOptions, O.stop = tU.stop, O.time = tS.defaultTime, O.timers = tD.timers, {
    compose: function (t, e, i) {
      let s = t.types.pie;
      if (!e.symbolCustomAttribs.includes("borderRadius")) {
        let o = i.prototype.symbols;
        lh(t, "afterColumnTranslate", lx, {
          order: 9
        }), lh(s, "afterTranslate", lb), e.symbolCustomAttribs.push("borderRadius", "brBoxHeight", "brBoxY"), lg = o.arc, lf = o.roundedRect, o.arc = lm, o.roundedRect = lv;
      }
    },
    optionsToObject: ly
  }.compose(O.Series, O.SVGElement, O.SVGRenderer), hy.compose(O.Series.types.column), hp.compose(O.Series), sZ.compose(O.Axis), i5.compose(O.SVGRenderer), nd.compose(O.Chart), s0.compose(O.Axis), lr.compose(O.Chart), h9.compose(O.Series.types.pie), or.compose(O.Chart, O.Axis), ro.compose(O.Chart), lP.compose(O.Chart), n7.compose(O.Axis, O.Chart, O.Series), aP.compose(O.Axis, O.Chart, O.Series), oO.compose(O.Pointer), tt.extend(O, tt);
  let lB = {
      compose: function (t) {
        let e = t.prototype.symbols;
        e.arrow = lC, e["arrow-filled"] = lE, e["arrow-filled-half"] = lL, e["arrow-half"] = lO, e["triangle-left"] = lE, e["triangle-left-half"] = lL;
      }
    },
    {
      defined: lI,
      error: lD,
      merge: lR,
      objectEach: lN
    } = tt,
    lz = O.deg2rad,
    lW = Math.max,
    lG = Math.min,
    lH = class {
      constructor(t, e, i) {
        this.init(t, e, i);
      }
      init(t, e, i) {
        this.fromPoint = t, this.toPoint = e, this.options = i, this.chart = t.series.chart, this.pathfinder = this.chart.pathfinder;
      }
      renderPath(t, e) {
        let i = this.chart,
          s = i.styledMode,
          o = this.pathfinder,
          r = {},
          n = this.graphics && this.graphics.path;
        o.group || (o.group = i.renderer.g().addClass("highcharts-pathfinder-group").attr({
          zIndex: -1
        }).add(i.seriesGroup)), o.group.translate(i.plotLeft, i.plotTop), n && n.renderer || (n = i.renderer.path().add(o.group), s || n.attr({
          opacity: 0
        })), n.attr(e), r.d = t, s || (r.opacity = 1), n.animate(r), this.graphics = this.graphics || {}, this.graphics.path = n;
      }
      addMarker(t, e, i) {
        let s, o, r, n, a, h, l, d;
        let c = this.fromPoint.series.chart,
          p = c.pathfinder,
          u = c.renderer,
          g = "start" === t ? this.fromPoint : this.toPoint,
          f = g.getPathfinderAnchorPoint(e);
        e.enabled && ((d = "start" === t ? i[1] : i[i.length - 2]) && "M" === d[0] || "L" === d[0]) && (l = {
          x: d[1],
          y: d[2]
        }, o = g.getRadiansToVector(l, f), s = g.getMarkerVector(o, e.radius, f), r = -o / lz, e.width && e.height ? (a = e.width, h = e.height) : a = h = 2 * e.radius, this.graphics = this.graphics || {}, n = {
          x: s.x - a / 2,
          y: s.y - h / 2,
          width: a,
          height: h,
          rotation: r,
          rotationOriginX: s.x,
          rotationOriginY: s.y
        }, this.graphics[t] ? this.graphics[t].animate(n) : (this.graphics[t] = u.symbol(e.symbol).addClass("highcharts-point-connecting-path-" + t + "-marker highcharts-color-" + this.fromPoint.colorIndex).attr(n).add(p.group), u.styledMode || this.graphics[t].attr({
          fill: e.color || this.fromPoint.color,
          stroke: e.lineColor,
          "stroke-width": e.lineWidth,
          opacity: 0
        }).animate({
          opacity: 1
        }, g.series.options.animation)));
      }
      getPath(t) {
        let e = this.pathfinder,
          i = this.chart,
          s = e.algorithms[t.type],
          o = e.chartObstacles;
        return "function" != typeof s ? (lD('"' + t.type + '" is not a Pathfinder algorithm.'), {
          path: [],
          obstacles: []
        }) : (s.requiresObstacles && !o && (o = e.chartObstacles = e.getChartObstacles(t), i.options.connectors.algorithmMargin = t.algorithmMargin, e.chartObstacleMetrics = e.getObstacleMetrics(o)), s(this.fromPoint.getPathfinderAnchorPoint(t.startMarker), this.toPoint.getPathfinderAnchorPoint(t.endMarker), lR({
          chartObstacles: o,
          lineObstacles: e.lineObstacles || [],
          obstacleMetrics: e.chartObstacleMetrics,
          hardBounds: {
            xMin: 0,
            xMax: i.plotWidth,
            yMin: 0,
            yMax: i.plotHeight
          },
          obstacleOptions: {
            margin: t.algorithmMargin
          },
          startDirectionX: e.getAlgorithmStartDirection(t.startMarker)
        }, t)));
      }
      render() {
        let t = this.fromPoint,
          e = t.series,
          i = e.chart,
          s = i.pathfinder,
          o = {},
          r = lR(i.options.connectors, e.options.connectors, t.options.connectors, this.options);
        !i.styledMode && (o.stroke = r.lineColor || t.color, o["stroke-width"] = r.lineWidth, r.dashStyle && (o.dashstyle = r.dashStyle)), o.class = "highcharts-point-connecting-path highcharts-color-" + t.colorIndex, lI((r = lR(o, r)).marker.radius) || (r.marker.radius = lG(lW(Math.ceil((r.algorithmMargin || 8) / 2) - 1, 1), 5));
        let n = this.getPath(r),
          a = n.path;
        n.obstacles && (s.lineObstacles = s.lineObstacles || [], s.lineObstacles = s.lineObstacles.concat(n.obstacles)), this.renderPath(a, o), this.addMarker("start", lR(r.marker, r.startMarker), a), this.addMarker("end", lR(r.marker, r.endMarker), a);
      }
      destroy() {
        this.graphics && (lN(this.graphics, function (t) {
          t.destroy();
        }), delete this.graphics);
      }
    },
    {
      composed: lF
    } = O,
    {
      addEvent: lX,
      merge: lY,
      pushUnique: lj,
      wrap: lU
    } = tt,
    lV = {
      color: "#ccd3ff",
      width: 2,
      label: {
        format: "%[abdYHM]",
        formatter: function (t, e) {
          return this.axis.chart.time.dateFormat(e || "", t, !0);
        },
        rotation: 0,
        style: {
          fontSize: "0.7em"
        }
      }
    };
  function l$() {
    let t = this.options,
      e = t.currentDateIndicator;
    if (e) {
      let i = "object" == typeof e ? lY(lV, e) : lY(lV);
      i.value = Date.now(), i.className = "highcharts-current-date-indicator", t.plotLines || (t.plotLines = []), t.plotLines.push(i);
    }
  }
  function l_() {
    this.label && this.label.attr({
      text: this.getLabelText(this.options.label)
    });
  }
  function lq(t, e) {
    let i = this.options;
    return i && i.className && -1 !== i.className.indexOf("highcharts-current-date-indicator") && i.label && "function" == typeof i.label.formatter ? (i.value = Date.now(), i.label.formatter.call(this, i.value, i.label.format)) : t.call(this, e);
  }
  let {
      defaultOptions: lZ
    } = tS,
    {
      isArray: lK,
      merge: lJ,
      splat: lQ
    } = tt;
  class l0 extends nK {
    init(t, e) {
      let i;
      let s = t.xAxis,
        o = t.yAxis;
      t.xAxis = t.yAxis = void 0;
      let r = lJ(!0, {
        chart: {
          type: "gantt"
        },
        title: {
          text: ""
        },
        legend: {
          enabled: !1
        },
        navigator: {
          series: {
            type: "gantt"
          },
          yAxis: {
            type: "category"
          }
        }
      }, t, {
        isGantt: !0
      });
      t.xAxis = s, t.yAxis = o, r.xAxis = (lK(t.xAxis) ? t.xAxis : [t.xAxis || {}, {}]).map((t, e) => (1 === e && (i = 0), lJ({
        grid: {
          borderColor: "#cccccc",
          enabled: !0
        },
        opposite: lZ.xAxis?.opposite ?? t.opposite ?? !0,
        linkedTo: i
      }, t, {
        type: "datetime"
      }))), r.yAxis = lQ(t.yAxis || {}).map(t => lJ({
        grid: {
          borderColor: "#cccccc",
          enabled: !0
        },
        staticScale: 50,
        reversed: !0,
        type: t.categories ? t.type : "treegrid"
      }, t)), super.init(r, e);
    }
  }
  !function (t) {
    t.ganttChart = function (e, i, s) {
      return new t(e, i, s);
    };
  }(l0 || (l0 = {}));
  let l1 = l0,
    {
      isTouchDevice: l2
    } = O,
    {
      addEvent: l3,
      merge: l5,
      pick: l6
    } = tt,
    l9 = [];
  function l4() {
    this.navigator && this.navigator.setBaseSeries(null, !1);
  }
  function l8() {
    let t, e, i;
    let s = this.legend,
      o = this.navigator;
    if (o) {
      t = s && s.options, e = o.xAxis, i = o.yAxis;
      let {
        scrollbarHeight: r,
        scrollButtonSize: n
      } = o;
      this.inverted ? (o.left = o.opposite ? this.chartWidth - r - o.height : this.spacing[3] + r, o.top = this.plotTop + n) : (o.left = l6(e.left, this.plotLeft + n), o.top = o.navigatorOptions.top || this.chartHeight - o.height - r - (this.scrollbar?.options.margin || 0) - this.spacing[2] - (this.rangeSelector && this.extraBottomMargin ? this.rangeSelector.getHeight() : 0) - (t && "bottom" === t.verticalAlign && "proximate" !== t.layout && t.enabled && !t.floating ? s.legendHeight + l6(t.margin, 10) : 0) - (this.titleOffset ? this.titleOffset[2] : 0)), e && i && (this.inverted ? e.options.left = i.options.left = o.left : e.options.top = i.options.top = o.top, e.setAxisSize(), i.setAxisSize());
    }
  }
  function l7(t) {
    !this.navigator && !this.scroller && (this.options.navigator.enabled || this.options.scrollbar.enabled) && (this.scroller = this.navigator = new i(this), l6(t.redraw, !0) && this.redraw(t.animation));
  }
  function dt() {
    let t = this.options;
    (t.navigator.enabled || t.scrollbar.enabled) && (this.scroller = this.navigator = new i(this));
  }
  function de() {
    let t = this.options,
      e = t.navigator,
      i = t.rangeSelector;
    if ((e && e.enabled || i && i.enabled) && (!l2 && "x" === this.zooming.type || l2 && "x" === this.zooming.pinchType)) return !1;
  }
  function di(t) {
    let e = t.navigator;
    if (e && t.xAxis[0]) {
      let i = t.xAxis[0].getExtremes();
      e.render(i.min, i.max);
    }
  }
  function ds(t) {
    let e = t.options.navigator || {},
      i = t.options.scrollbar || {};
    !this.navigator && !this.scroller && (e.enabled || i.enabled) && (l5(!0, this.options.navigator, e), l5(!0, this.options.scrollbar, i), delete t.options.navigator, delete t.options.scrollbar);
  }
  let dr = {
      compose: function (t, e) {
        if (tt.pushUnique(l9, t)) {
          let s = t.prototype;
          i = e, s.callbacks.push(di), l3(t, "afterAddSeries", l4), l3(t, "afterSetChartSize", l8), l3(t, "afterUpdate", l7), l3(t, "beforeRender", dt), l3(t, "beforeShowResetZoom", de), l3(t, "update", ds);
        }
      }
    },
    {
      isTouchDevice: dn
    } = O,
    {
      addEvent: da,
      correctFloat: dh,
      defined: dl,
      isNumber: dd,
      pick: dc
    } = tt;
  function dp() {
    this.navigatorAxis || (this.navigatorAxis = new dg(this));
  }
  function du(t) {
    let e;
    let i = this.chart,
      s = i.options,
      o = s.navigator,
      r = this.navigatorAxis,
      n = i.zooming.pinchType,
      a = s.rangeSelector,
      h = i.zooming.type;
    if (this.isXAxis && (o?.enabled || a?.enabled)) {
      if ("y" === h && "zoom" === t.trigger) e = !1;else if (("zoom" === t.trigger && "xy" === h || dn && "xy" === n) && this.options.range) {
        let e = r.previousZoom;
        dl(t.min) ? r.previousZoom = [this.min, this.max] : e && (t.min = e[0], t.max = e[1], r.previousZoom = void 0);
      }
    }
    void 0 !== e && t.preventDefault();
  }
  class dg {
    static compose(t) {
      t.keepProps.includes("navigatorAxis") || (t.keepProps.push("navigatorAxis"), da(t, "init", dp), da(t, "setExtremes", du));
    }
    constructor(t) {
      this.axis = t;
    }
    destroy() {
      this.axis = void 0;
    }
    toFixedRange(t, e, i, s) {
      let o = this.axis,
        r = (o.pointRange || 0) / 2,
        n = dc(i, o.translate(t, !0, !o.horiz)),
        a = dc(s, o.translate(e, !0, !o.horiz));
      return dl(i) || (n = dh(n + r)), dl(s) || (a = dh(a - r)), dd(n) && dd(a) || (n = a = void 0), {
        min: n,
        max: a
      };
    }
  }
  let {
      parse: df
    } = tO,
    {
      seriesTypes: dm
    } = ry,
    dx = {
      height: 40,
      margin: 25,
      maskInside: !0,
      handles: {
        width: 7,
        borderRadius: 0,
        height: 15,
        symbols: ["navigator-handle", "navigator-handle"],
        enabled: !0,
        lineWidth: 1,
        backgroundColor: "#f2f2f2",
        borderColor: "#999999"
      },
      maskFill: df("#667aff").setOpacity(.3).get(),
      outlineColor: "#999999",
      outlineWidth: 1,
      series: {
        type: void 0 === dm.areaspline ? "line" : "areaspline",
        fillOpacity: .05,
        lineWidth: 1,
        compare: null,
        sonification: {
          enabled: !1
        },
        dataGrouping: {
          approximation: "average",
          enabled: !0,
          groupPixelWidth: 2,
          firstAnchor: "firstPoint",
          anchor: "middle",
          lastAnchor: "lastPoint",
          units: [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2, 3, 4]], ["week", [1, 2, 3]], ["month", [1, 3, 6]], ["year", null]]
        },
        dataLabels: {
          enabled: !1,
          zIndex: 2
        },
        id: "highcharts-navigator-series",
        className: "highcharts-navigator-series",
        lineColor: null,
        marker: {
          enabled: !1
        },
        threshold: null
      },
      xAxis: {
        className: "highcharts-navigator-xaxis",
        tickLength: 0,
        lineWidth: 0,
        gridLineColor: "#e6e6e6",
        id: "navigator-x-axis",
        gridLineWidth: 1,
        tickPixelInterval: 200,
        labels: {
          align: "left",
          style: {
            color: "#000000",
            fontSize: "0.7em",
            opacity: .6,
            textOutline: "2px contrast"
          },
          x: 3,
          y: -4
        },
        crosshair: !1
      },
      yAxis: {
        className: "highcharts-navigator-yaxis",
        gridLineWidth: 0,
        startOnTick: !1,
        endOnTick: !1,
        minPadding: .1,
        id: "navigator-y-axis",
        maxPadding: .1,
        labels: {
          enabled: !1
        },
        crosshair: !1,
        title: {
          text: null
        },
        tickLength: 0,
        tickWidth: 0
      }
    },
    {
      relativeLength: dy
    } = tt,
    db = {
      "navigator-handle": function (t, e, i, s, o = {}) {
        let r = o.width ? o.width / 2 : i,
          n = dy(o.borderRadius || 0, Math.min(2 * r, s));
        return [["M", -1.5, (s = o.height || s) / 2 - 3.5], ["L", -1.5, s / 2 + 4.5], ["M", .5, s / 2 - 3.5], ["L", .5, s / 2 + 4.5], ...is.rect(-r - 1, .5, 2 * r + 1, s, {
          r: n
        })];
      }
    },
    {
      defined: dv
    } = tt,
    {
      setOptions: dM
    } = tS,
    {
      composed: dk
    } = O,
    {
      getRendererType: dw
    } = eu,
    {
      setFixedRange: dS
    } = {
      setFixedRange: function (t) {
        let e = this.xAxis[0];
        dv(e.dataMax) && dv(e.dataMin) && t ? this.fixedRange = Math.min(t, e.dataMax - e.dataMin) : this.fixedRange = t;
      }
    },
    {
      addEvent: dA,
      extend: dT,
      pushUnique: dP
    } = tt;
  function dC() {
    this.chart.navigator && !this.options.isInternal && this.chart.navigator.setBaseSeries(null, !1);
  }
  let dO = {
      compose: function (t, e, i) {
        dg.compose(e), dP(dk, "Navigator") && (t.prototype.setFixedRange = dS, dT(dw().prototype.symbols, db), dA(i, "afterUpdate", dC), dM({
          navigator: dx
        }));
      }
    },
    {
      composed: dE
    } = O,
    {
      addEvent: dL,
      defined: dB,
      pick: dI,
      pushUnique: dD
    } = tt;
  !function (t) {
    let e;
    function i(t) {
      let e = dI(t.options && t.options.min, t.min),
        i = dI(t.options && t.options.max, t.max);
      return {
        axisMin: e,
        axisMax: i,
        scrollMin: dB(t.dataMin) ? Math.min(e, t.min, t.dataMin, dI(t.threshold, 1 / 0)) : e,
        scrollMax: dB(t.dataMax) ? Math.max(i, t.max, t.dataMax, dI(t.threshold, -1 / 0)) : i
      };
    }
    function s() {
      let t = this.scrollbar,
        e = t && !t.options.opposite,
        i = this.horiz ? 2 : e ? 3 : 1;
      t && (this.chart.scrollbarsOffsets = [0, 0], this.chart.axisOffset[i] += t.size + (t.options.margin || 0));
    }
    function o() {
      let t = this;
      t.options && t.options.scrollbar && t.options.scrollbar.enabled && (t.options.scrollbar.vertical = !t.horiz, t.options.startOnTick = t.options.endOnTick = !1, t.scrollbar = new e(t.chart.renderer, t.options.scrollbar, t.chart), dL(t.scrollbar, "changed", function (e) {
        let s, o;
        let {
            axisMin: r,
            axisMax: n,
            scrollMin: a,
            scrollMax: h
          } = i(t),
          l = h - a;
        if (dB(r) && dB(n)) {
          if (t.horiz && !t.reversed || !t.horiz && t.reversed ? (s = a + l * this.to, o = a + l * this.from) : (s = a + l * (1 - this.from), o = a + l * (1 - this.to)), this.shouldUpdateExtremes(e.DOMType)) {
            let i = "mousemove" !== e.DOMType && "touchmove" !== e.DOMType && void 0;
            t.setExtremes(o, s, !0, i, e);
          } else this.setRange(this.from, this.to);
        }
      }));
    }
    function r() {
      let t, e, s;
      let {
          scrollMin: o,
          scrollMax: r
        } = i(this),
        n = this.scrollbar,
        a = this.axisTitleMargin + (this.titleOffset || 0),
        h = this.chart.scrollbarsOffsets,
        l = this.options.margin || 0;
      if (n && h) {
        if (this.horiz) this.opposite || (h[1] += a), n.position(this.left, this.top + this.height + 2 + h[1] - (this.opposite ? l : 0), this.width, this.height), this.opposite || (h[1] += l), t = 1;else {
          let e;
          this.opposite && (h[0] += a), e = n.options.opposite ? this.left + this.width + 2 + h[0] - (this.opposite ? 0 : l) : this.opposite ? 0 : l, n.position(e, this.top, this.width, this.height), this.opposite && (h[0] += l), t = 0;
        }
        if (h[t] += n.size + (n.options.margin || 0), isNaN(o) || isNaN(r) || !dB(this.min) || !dB(this.max) || this.dataMin === this.dataMax) n.setRange(0, 1);else if (this.min === this.max) {
          let t = this.pointRange / (this.dataMax + 1);
          e = t * this.min, s = t * (this.max + 1), n.setRange(e, s);
        } else e = (this.min - o) / (r - o), s = (this.max - o) / (r - o), this.horiz && !this.reversed || !this.horiz && this.reversed ? n.setRange(e, s) : n.setRange(1 - s, 1 - e);
      }
    }
    t.compose = function (t, i) {
      dD(dE, "Axis.Scrollbar") && (e = i, dL(t, "afterGetOffset", s), dL(t, "afterInit", o), dL(t, "afterRender", r));
    };
  }(k || (k = {}));
  let dR = k,
    dN = {
      height: 10,
      barBorderRadius: 5,
      buttonBorderRadius: 0,
      buttonsEnabled: !1,
      liveRedraw: void 0,
      margin: void 0,
      minWidth: 6,
      opposite: !0,
      step: .2,
      zIndex: 3,
      barBackgroundColor: "#cccccc",
      barBorderWidth: 0,
      barBorderColor: "#cccccc",
      buttonArrowColor: "#333333",
      buttonBackgroundColor: "#e6e6e6",
      buttonBorderColor: "#cccccc",
      buttonBorderWidth: 1,
      rifleColor: "none",
      trackBackgroundColor: "rgba(255, 255, 255, 0.001)",
      trackBorderColor: "#cccccc",
      trackBorderRadius: 5,
      trackBorderWidth: 1
    },
    {
      defaultOptions: dz
    } = tS,
    {
      addEvent: dW,
      correctFloat: dG,
      crisp: dH,
      defined: dF,
      destroyObjectProperties: dX,
      fireEvent: dY,
      merge: dj,
      pick: dU,
      removeEvent: dV
    } = tt;
  class d$ {
    static compose(t) {
      dR.compose(t, d$);
    }
    static swapXY(t, e) {
      return e && t.forEach(t => {
        let e;
        let i = t.length;
        for (let s = 0; s < i; s += 2) "number" == typeof (e = t[s + 1]) && (t[s + 1] = t[s + 2], t[s + 2] = e);
      }), t;
    }
    constructor(t, e, i) {
      this._events = [], this.chartX = 0, this.chartY = 0, this.from = 0, this.scrollbarButtons = [], this.scrollbarLeft = 0, this.scrollbarStrokeWidth = 1, this.scrollbarTop = 0, this.size = 0, this.to = 0, this.trackBorderWidth = 1, this.x = 0, this.y = 0, this.init(t, e, i);
    }
    addEvents() {
      let t = this.options.inverted ? [1, 0] : [0, 1],
        e = this.scrollbarButtons,
        i = this.scrollbarGroup.element,
        s = this.track.element,
        o = this.mouseDownHandler.bind(this),
        r = this.mouseMoveHandler.bind(this),
        n = this.mouseUpHandler.bind(this),
        a = [[e[t[0]].element, "click", this.buttonToMinClick.bind(this)], [e[t[1]].element, "click", this.buttonToMaxClick.bind(this)], [s, "click", this.trackClick.bind(this)], [i, "mousedown", o], [i.ownerDocument, "mousemove", r], [i.ownerDocument, "mouseup", n], [i, "touchstart", o], [i.ownerDocument, "touchmove", r], [i.ownerDocument, "touchend", n]];
      a.forEach(function (t) {
        dW.apply(null, t);
      }), this._events = a;
    }
    buttonToMaxClick(t) {
      let e = (this.to - this.from) * dU(this.options.step, .2);
      this.updatePosition(this.from + e, this.to + e), dY(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: t
      });
    }
    buttonToMinClick(t) {
      let e = dG(this.to - this.from) * dU(this.options.step, .2);
      this.updatePosition(dG(this.from - e), dG(this.to - e)), dY(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: t
      });
    }
    cursorToScrollbarPosition(t) {
      let e = this.options,
        i = e.minWidth > this.calculatedWidth ? e.minWidth : 0;
      return {
        chartX: (t.chartX - this.x - this.xOffset) / (this.barWidth - i),
        chartY: (t.chartY - this.y - this.yOffset) / (this.barWidth - i)
      };
    }
    destroy() {
      let t = this,
        e = t.chart.scroller;
      t.removeEvents(), ["track", "scrollbarRifles", "scrollbar", "scrollbarGroup", "group"].forEach(function (e) {
        t[e] && t[e].destroy && (t[e] = t[e].destroy());
      }), e && t === e.scrollbar && (e.scrollbar = null, dX(e.scrollbarButtons));
    }
    drawScrollbarButton(t) {
      let e = this.renderer,
        i = this.scrollbarButtons,
        s = this.options,
        o = this.size,
        r = e.g().add(this.group);
      if (i.push(r), s.buttonsEnabled) {
        let n = e.rect().addClass("highcharts-scrollbar-button").add(r);
        this.chart.styledMode || n.attr({
          stroke: s.buttonBorderColor,
          "stroke-width": s.buttonBorderWidth,
          fill: s.buttonBackgroundColor
        }), n.attr(n.crisp({
          x: -.5,
          y: -.5,
          width: o,
          height: o,
          r: s.buttonBorderRadius
        }, n.strokeWidth()));
        let a = e.path(d$.swapXY([["M", o / 2 + (t ? -1 : 1), o / 2 - 3], ["L", o / 2 + (t ? -1 : 1), o / 2 + 3], ["L", o / 2 + (t ? 2 : -2), o / 2]], s.vertical)).addClass("highcharts-scrollbar-arrow").add(i[t]);
        this.chart.styledMode || a.attr({
          fill: s.buttonArrowColor
        });
      }
    }
    init(t, e, i) {
      this.scrollbarButtons = [], this.renderer = t, this.userOptions = e, this.options = dj(dN, dz.scrollbar, e), this.options.margin = dU(this.options.margin, 10), this.chart = i, this.size = dU(this.options.size, this.options.height), e.enabled && (this.render(), this.addEvents());
    }
    mouseDownHandler(t) {
      let e = this.chart.pointer?.normalize(t) || t,
        i = this.cursorToScrollbarPosition(e);
      this.chartX = i.chartX, this.chartY = i.chartY, this.initPositions = [this.from, this.to], this.grabbedCenter = !0;
    }
    mouseMoveHandler(t) {
      let e;
      let i = this.chart.pointer?.normalize(t) || t,
        s = this.options.vertical ? "chartY" : "chartX",
        o = this.initPositions || [];
      this.grabbedCenter && (!t.touches || 0 !== t.touches[0][s]) && (e = this.cursorToScrollbarPosition(i)[s] - this[s], this.hasDragged = !0, this.updatePosition(o[0] + e, o[1] + e), this.hasDragged && dY(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMType: t.type,
        DOMEvent: t
      }));
    }
    mouseUpHandler(t) {
      this.hasDragged && dY(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMType: t.type,
        DOMEvent: t
      }), this.grabbedCenter = this.hasDragged = this.chartX = this.chartY = null;
    }
    position(t, e, i, s) {
      let {
          buttonsEnabled: o,
          margin: r = 0,
          vertical: n
        } = this.options,
        a = this.rendered ? "animate" : "attr",
        h = s,
        l = 0;
      this.group.show(), this.x = t, this.y = e + this.trackBorderWidth, this.width = i, this.height = s, this.xOffset = h, this.yOffset = l, n ? (this.width = this.yOffset = i = l = this.size, this.xOffset = h = 0, this.yOffset = l = o ? this.size : 0, this.barWidth = s - (o ? 2 * i : 0), this.x = t += r) : (this.height = s = this.size, this.xOffset = h = o ? this.size : 0, this.barWidth = i - (o ? 2 * s : 0), this.y = this.y + r), this.group[a]({
        translateX: t,
        translateY: this.y
      }), this.track[a]({
        width: i,
        height: s
      }), this.scrollbarButtons[1][a]({
        translateX: n ? 0 : i - h,
        translateY: n ? s - l : 0
      });
    }
    removeEvents() {
      this._events.forEach(function (t) {
        dV.apply(null, t);
      }), this._events.length = 0;
    }
    render() {
      let t = this.renderer,
        e = this.options,
        i = this.size,
        s = this.chart.styledMode,
        o = t.g("scrollbar").attr({
          zIndex: e.zIndex
        }).hide().add();
      this.group = o, this.track = t.rect().addClass("highcharts-scrollbar-track").attr({
        r: e.trackBorderRadius || 0,
        height: i,
        width: i
      }).add(o), s || this.track.attr({
        fill: e.trackBackgroundColor,
        stroke: e.trackBorderColor,
        "stroke-width": e.trackBorderWidth
      });
      let r = this.trackBorderWidth = this.track.strokeWidth();
      this.track.attr({
        x: -dH(0, r),
        y: -dH(0, r)
      }), this.scrollbarGroup = t.g().add(o), this.scrollbar = t.rect().addClass("highcharts-scrollbar-thumb").attr({
        height: i - r,
        width: i - r,
        r: e.barBorderRadius || 0
      }).add(this.scrollbarGroup), this.scrollbarRifles = t.path(d$.swapXY([["M", -3, i / 4], ["L", -3, 2 * i / 3], ["M", 0, i / 4], ["L", 0, 2 * i / 3], ["M", 3, i / 4], ["L", 3, 2 * i / 3]], e.vertical)).addClass("highcharts-scrollbar-rifles").add(this.scrollbarGroup), s || (this.scrollbar.attr({
        fill: e.barBackgroundColor,
        stroke: e.barBorderColor,
        "stroke-width": e.barBorderWidth
      }), this.scrollbarRifles.attr({
        stroke: e.rifleColor,
        "stroke-width": 1
      })), this.scrollbarStrokeWidth = this.scrollbar.strokeWidth(), this.scrollbarGroup.translate(-dH(0, this.scrollbarStrokeWidth), -dH(0, this.scrollbarStrokeWidth)), this.drawScrollbarButton(0), this.drawScrollbarButton(1);
    }
    setRange(t, e) {
      let i, s;
      let o = this.options,
        r = o.vertical,
        n = o.minWidth,
        a = this.barWidth,
        h = !this.rendered || this.hasDragged || this.chart.navigator && this.chart.navigator.hasDragged ? "attr" : "animate";
      if (!dF(a)) return;
      let l = a * Math.min(e, 1);
      i = Math.ceil(a * (t = Math.max(t, 0))), this.calculatedWidth = s = dG(l - i), s < n && (i = (a - n + s) * t, s = n);
      let d = Math.floor(i + this.xOffset + this.yOffset),
        c = s / 2 - .5;
      this.from = t, this.to = e, r ? (this.scrollbarGroup[h]({
        translateY: d
      }), this.scrollbar[h]({
        height: s
      }), this.scrollbarRifles[h]({
        translateY: c
      }), this.scrollbarTop = d, this.scrollbarLeft = 0) : (this.scrollbarGroup[h]({
        translateX: d
      }), this.scrollbar[h]({
        width: s
      }), this.scrollbarRifles[h]({
        translateX: c
      }), this.scrollbarLeft = d, this.scrollbarTop = 0), s <= 12 ? this.scrollbarRifles.hide() : this.scrollbarRifles.show(), !1 === o.showFull && (t <= 0 && e >= 1 ? this.group.hide() : this.group.show()), this.rendered = !0;
    }
    shouldUpdateExtremes(t) {
      return dU(this.options.liveRedraw, O.svg && !O.isTouchDevice && !this.chart.boosted) || "mouseup" === t || "touchend" === t || !dF(t);
    }
    trackClick(t) {
      let e = this.chart.pointer?.normalize(t) || t,
        i = this.to - this.from,
        s = this.y + this.scrollbarTop,
        o = this.x + this.scrollbarLeft;
      this.options.vertical && e.chartY > s || !this.options.vertical && e.chartX > o ? this.updatePosition(this.from + i, this.to + i) : this.updatePosition(this.from - i, this.to - i), dY(this, "changed", {
        from: this.from,
        to: this.to,
        trigger: "scrollbar",
        DOMEvent: t
      });
    }
    update(t) {
      this.destroy(), this.init(this.chart.renderer, dj(!0, this.options, t), this.chart);
    }
    updatePosition(t, e) {
      e > 1 && (t = dG(1 - dG(e - t)), e = 1), t < 0 && (e = dG(e - t), t = 0), this.from = t, this.to = e;
    }
  }
  d$.defaultOptions = dN, dz.scrollbar = dj(!0, d$.defaultOptions, dz.scrollbar);
  let {
      defaultOptions: d_
    } = tS,
    {
      isTouchDevice: dq
    } = O,
    {
      prototype: {
        symbols: dZ
      }
    } = iU,
    {
      addEvent: dK,
      clamp: dJ,
      correctFloat: dQ,
      defined: d0,
      destroyObjectProperties: d1,
      erase: d2,
      extend: d3,
      find: d5,
      fireEvent: d6,
      isArray: d9,
      isNumber: d4,
      merge: d8,
      pick: d7,
      removeEvent: ct,
      splat: ce
    } = tt;
  function ci(t, ...e) {
    let i = [].filter.call(e, d4);
    if (i.length) return Math[t].apply(0, i);
  }
  class cs {
    static compose(t, e, i) {
      dr.compose(t, cs), dO.compose(t, e, i);
    }
    constructor(t) {
      this.isDirty = !1, this.scrollbarHeight = 0, this.init(t);
    }
    drawHandle(t, e, i, s) {
      let o = this.navigatorOptions.handles.height;
      this.handles[e][s](i ? {
        translateX: Math.round(this.left + this.height / 2),
        translateY: Math.round(this.top + parseInt(t, 10) + .5 - o)
      } : {
        translateX: Math.round(this.left + parseInt(t, 10)),
        translateY: Math.round(this.top + this.height / 2 - o / 2 - 1)
      });
    }
    drawOutline(t, e, i, s) {
      let o = this.navigatorOptions.maskInside,
        r = this.outline.strokeWidth(),
        n = r / 2,
        a = r % 2 / 2,
        h = this.scrollButtonSize,
        l = this.size,
        d = this.top,
        c = this.height,
        p = d - n,
        u = d + c,
        g = this.left,
        f,
        m;
      i ? (f = d + e + a, e = d + t + a, m = [["M", g + c, d - h - a], ["L", g + c, f], ["L", g, f], ["M", g, e], ["L", g + c, e], ["L", g + c, d + l + h]], o && m.push(["M", g + c, f - n], ["L", g + c, e + n])) : (g -= h, t += g + h - a, e += g + h - a, m = [["M", g, p], ["L", t, p], ["L", t, u], ["M", e, u], ["L", e, p], ["L", g + l + 2 * h, p]], o && m.push(["M", t - n, p], ["L", e + n, p])), this.outline[s]({
        d: m
      });
    }
    drawMasks(t, e, i, s) {
      let o, r, n, a;
      let h = this.left,
        l = this.top,
        d = this.height;
      i ? (n = [h, h, h], a = [l, l + t, l + e], r = [d, d, d], o = [t, e - t, this.size - e]) : (n = [h, h + t, h + e], a = [l, l, l], r = [t, e - t, this.size - e], o = [d, d, d]), this.shades.forEach((t, e) => {
        t[s]({
          x: n[e],
          y: a[e],
          width: r[e],
          height: o[e]
        });
      });
    }
    renderElements() {
      let t = this,
        e = t.navigatorOptions,
        i = e.maskInside,
        s = t.chart,
        o = s.inverted,
        r = s.renderer,
        n = {
          cursor: o ? "ns-resize" : "ew-resize"
        },
        a = t.navigatorGroup ?? (t.navigatorGroup = r.g("navigator").attr({
          zIndex: 8,
          visibility: "hidden"
        }).add());
      if ([!i, i, !i].forEach((i, o) => {
        let h = t.shades[o] ?? (t.shades[o] = r.rect().addClass("highcharts-navigator-mask" + (1 === o ? "-inside" : "-outside")).add(a));
        s.styledMode || (h.attr({
          fill: i ? e.maskFill : "rgba(0,0,0,0)"
        }), 1 === o && h.css(n));
      }), t.outline || (t.outline = r.path().addClass("highcharts-navigator-outline").add(a)), s.styledMode || t.outline.attr({
        "stroke-width": e.outlineWidth,
        stroke: e.outlineColor
      }), e.handles?.enabled) {
        let i = e.handles,
          {
            height: o,
            width: h
          } = i;
        [0, 1].forEach(e => {
          let l = i.symbols[e];
          if (t.handles[e] && t.handles[e].symbolUrl === l) {
            if (!t.handles[e].isImg && t.handles[e].symbolName !== l) {
              let i = dZ[l].call(dZ, -h / 2 - 1, 0, h, o);
              t.handles[e].attr({
                d: i
              }), t.handles[e].symbolName = l;
            }
          } else t.handles[e]?.destroy(), t.handles[e] = r.symbol(l, -h / 2 - 1, 0, h, o, i), t.handles[e].attr({
            zIndex: 7 - e
          }).addClass("highcharts-navigator-handle highcharts-navigator-handle-" + ["left", "right"][e]).add(a), t.addMouseEvents();
          s.inverted && t.handles[e].attr({
            rotation: 90,
            rotationOriginX: Math.floor(-h / 2),
            rotationOriginY: (o + h) / 2
          }), s.styledMode || t.handles[e].attr({
            fill: i.backgroundColor,
            stroke: i.borderColor,
            "stroke-width": i.lineWidth,
            width: i.width,
            height: i.height,
            x: -h / 2 - 1,
            y: 0
          }).css(n);
        });
      }
    }
    update(t, e = !1) {
      let i = this.chart,
        s = i.options.chart.inverted !== i.scrollbar?.options.vertical;
      if (d8(!0, i.options.navigator, t), this.navigatorOptions = i.options.navigator || {}, this.setOpposite(), d0(t.enabled) || s) return this.destroy(), this.navigatorEnabled = t.enabled || this.navigatorEnabled, this.init(i);
      if (this.navigatorEnabled && (this.isDirty = !0, !1 === t.adaptToUpdatedData && this.baseSeries.forEach(t => {
        ct(t, "updatedData", this.updatedDataHandler);
      }, this), t.adaptToUpdatedData && this.baseSeries.forEach(t => {
        t.eventsToUnbind.push(dK(t, "updatedData", this.updatedDataHandler));
      }, this), (t.series || t.baseSeries) && this.setBaseSeries(void 0, !1), t.height || t.xAxis || t.yAxis)) {
        this.height = t.height ?? this.height;
        let e = this.getXAxisOffsets();
        this.xAxis.update({
          ...t.xAxis,
          offsets: e,
          [i.inverted ? "width" : "height"]: this.height,
          [i.inverted ? "height" : "width"]: void 0
        }, !1), this.yAxis.update({
          ...t.yAxis,
          [i.inverted ? "width" : "height"]: this.height
        }, !1);
      }
      e && i.redraw();
    }
    render(t, e, i, s) {
      let o = this.chart,
        r = this.xAxis,
        n = r.pointRange || 0,
        a = r.navigatorAxis.fake ? o.xAxis[0] : r,
        h = this.navigatorEnabled,
        l = this.rendered,
        d = o.inverted,
        c = o.xAxis[0].minRange,
        p = o.xAxis[0].options.maxRange,
        u = this.scrollButtonSize,
        g,
        f,
        m,
        x = this.scrollbarHeight,
        y,
        b;
      if (this.hasDragged && !d0(i)) return;
      if (this.isDirty && this.renderElements(), t = dQ(t - n / 2), e = dQ(e + n / 2), !d4(t) || !d4(e)) {
        if (!l) return;
        i = 0, s = d7(r.width, a.width);
      }
      this.left = d7(r.left, o.plotLeft + u + (d ? o.plotWidth : 0));
      let v = this.size = y = d7(r.len, (d ? o.plotHeight : o.plotWidth) - 2 * u);
      g = d ? x : y + 2 * u, i = d7(i, r.toPixels(t, !0)), s = d7(s, r.toPixels(e, !0)), d4(i) && Math.abs(i) !== 1 / 0 || (i = 0, s = g);
      let M = r.toValue(i, !0),
        k = r.toValue(s, !0),
        w = Math.abs(dQ(k - M));
      w < c ? this.grabbedLeft ? i = r.toPixels(k - c - n, !0) : this.grabbedRight && (s = r.toPixels(M + c + n, !0)) : d0(p) && dQ(w - n) > p && (this.grabbedLeft ? i = r.toPixels(k - p - n, !0) : this.grabbedRight && (s = r.toPixels(M + p + n, !0))), this.zoomedMax = dJ(Math.max(i, s), 0, v), this.zoomedMin = dJ(this.fixedWidth ? this.zoomedMax - this.fixedWidth : Math.min(i, s), 0, v), this.range = this.zoomedMax - this.zoomedMin, v = Math.round(this.zoomedMax);
      let S = Math.round(this.zoomedMin);
      h && (this.navigatorGroup.attr({
        visibility: "inherit"
      }), b = l && !this.hasDragged ? "animate" : "attr", this.drawMasks(S, v, d, b), this.drawOutline(S, v, d, b), this.navigatorOptions.handles.enabled && (this.drawHandle(S, 0, d, b), this.drawHandle(v, 1, d, b))), this.scrollbar && (d ? (m = this.top - u, f = this.left - x + (h || !a.opposite ? 0 : (a.titleOffset || 0) + a.axisTitleMargin), x = y + 2 * u) : (m = this.top + (h ? this.height : -x), f = this.left - u), this.scrollbar.position(f, m, g, x), this.scrollbar.setRange(this.zoomedMin / (y || 1), this.zoomedMax / (y || 1))), this.rendered = !0, this.isDirty = !1, d6(this, "afterRender");
    }
    addMouseEvents() {
      let t = this,
        e = t.chart,
        i = e.container,
        s = [],
        o,
        r;
      t.mouseMoveHandler = o = function (e) {
        t.onMouseMove(e);
      }, t.mouseUpHandler = r = function (e) {
        t.onMouseUp(e);
      }, (s = t.getPartsEvents("mousedown")).push(dK(e.renderTo, "mousemove", o), dK(i.ownerDocument, "mouseup", r), dK(e.renderTo, "touchmove", o), dK(i.ownerDocument, "touchend", r)), s.concat(t.getPartsEvents("touchstart")), t.eventsToUnbind = s, t.series && t.series[0] && s.push(dK(t.series[0].xAxis, "foundExtremes", function () {
        e.navigator.modifyNavigatorAxisExtremes();
      }));
    }
    getPartsEvents(t) {
      let e = this,
        i = [];
      return ["shades", "handles"].forEach(function (s) {
        e[s].forEach(function (o, r) {
          i.push(dK(o.element, t, function (t) {
            e[s + "Mousedown"](t, r);
          }));
        });
      }), i;
    }
    shadesMousedown(t, e) {
      t = this.chart.pointer?.normalize(t) || t;
      let i = this.chart,
        s = this.xAxis,
        o = this.zoomedMin,
        r = this.size,
        n = this.range,
        a = this.left,
        h = t.chartX,
        l,
        d,
        c,
        p;
      i.inverted && (h = t.chartY, a = this.top), 1 === e ? (this.grabbedCenter = h, this.fixedWidth = n, this.dragOffset = h - o) : (p = h - a - n / 2, 0 === e ? p = Math.max(0, p) : 2 === e && p + n >= r && (p = r - n, this.reversedExtremes ? (p -= n, d = this.getUnionExtremes().dataMin) : l = this.getUnionExtremes().dataMax), p !== o && (this.fixedWidth = n, d0((c = s.navigatorAxis.toFixedRange(p, p + n, d, l)).min) && d6(this, "setRange", {
        min: Math.min(c.min, c.max),
        max: Math.max(c.min, c.max),
        redraw: !0,
        eventArguments: {
          trigger: "navigator"
        }
      })));
    }
    handlesMousedown(t, e) {
      t = this.chart.pointer?.normalize(t) || t;
      let i = this.chart,
        s = i.xAxis[0],
        o = this.reversedExtremes;
      0 === e ? (this.grabbedLeft = !0, this.otherHandlePos = this.zoomedMax, this.fixedExtreme = o ? s.min : s.max) : (this.grabbedRight = !0, this.otherHandlePos = this.zoomedMin, this.fixedExtreme = o ? s.max : s.min), i.setFixedRange(void 0);
    }
    onMouseMove(t) {
      let e = this,
        i = e.chart,
        s = e.navigatorSize,
        o = e.range,
        r = e.dragOffset,
        n = i.inverted,
        a = e.left,
        h;
      (!t.touches || 0 !== t.touches[0].pageX) && (h = (t = i.pointer?.normalize(t) || t).chartX, n && (a = e.top, h = t.chartY), e.grabbedLeft ? (e.hasDragged = !0, e.render(0, 0, h - a, e.otherHandlePos)) : e.grabbedRight ? (e.hasDragged = !0, e.render(0, 0, e.otherHandlePos, h - a)) : e.grabbedCenter && (e.hasDragged = !0, h < r ? h = r : h > s + r - o && (h = s + r - o), e.render(0, 0, h - r, h - r + o)), e.hasDragged && e.scrollbar && d7(e.scrollbar.options.liveRedraw, !dq && !this.chart.boosted) && (t.DOMType = t.type, setTimeout(function () {
        e.onMouseUp(t);
      }, 0)));
    }
    onMouseUp(t) {
      let e, i, s, o, r, n;
      let a = this.chart,
        h = this.xAxis,
        l = this.scrollbar,
        d = t.DOMEvent || t,
        c = a.inverted,
        p = this.rendered && !this.hasDragged ? "animate" : "attr";
      (this.hasDragged && (!l || !l.hasDragged) || "scrollbar" === t.trigger) && (s = this.getUnionExtremes(), this.zoomedMin === this.otherHandlePos ? o = this.fixedExtreme : this.zoomedMax === this.otherHandlePos && (r = this.fixedExtreme), this.zoomedMax === this.size && (r = this.reversedExtremes ? s.dataMin : s.dataMax), 0 === this.zoomedMin && (o = this.reversedExtremes ? s.dataMax : s.dataMin), d0((n = h.navigatorAxis.toFixedRange(this.zoomedMin, this.zoomedMax, o, r)).min) && d6(this, "setRange", {
        min: Math.min(n.min, n.max),
        max: Math.max(n.min, n.max),
        redraw: !0,
        animation: !this.hasDragged && null,
        eventArguments: {
          trigger: "navigator",
          triggerOp: "navigator-drag",
          DOMEvent: d
        }
      })), "mousemove" !== t.DOMType && "touchmove" !== t.DOMType && (this.grabbedLeft = this.grabbedRight = this.grabbedCenter = this.fixedWidth = this.fixedExtreme = this.otherHandlePos = this.hasDragged = this.dragOffset = null), this.navigatorEnabled && d4(this.zoomedMin) && d4(this.zoomedMax) && (i = Math.round(this.zoomedMin), e = Math.round(this.zoomedMax), this.shades && this.drawMasks(i, e, c, p), this.outline && this.drawOutline(i, e, c, p), this.navigatorOptions.handles.enabled && Object.keys(this.handles).length === this.handles.length && (this.drawHandle(i, 0, c, p), this.drawHandle(e, 1, c, p)));
    }
    removeEvents() {
      this.eventsToUnbind && (this.eventsToUnbind.forEach(function (t) {
        t();
      }), this.eventsToUnbind = void 0), this.removeBaseSeriesEvents();
    }
    removeBaseSeriesEvents() {
      let t = this.baseSeries || [];
      this.navigatorEnabled && t[0] && (!1 !== this.navigatorOptions.adaptToUpdatedData && t.forEach(function (t) {
        ct(t, "updatedData", this.updatedDataHandler);
      }, this), t[0].xAxis && ct(t[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes));
    }
    getXAxisOffsets() {
      return this.chart.inverted ? [this.scrollButtonSize, 0, -this.scrollButtonSize, 0] : [0, -this.scrollButtonSize, 0, this.scrollButtonSize];
    }
    init(t) {
      let e = t.options,
        i = e.navigator || {},
        s = i.enabled,
        o = e.scrollbar || {},
        r = o.enabled,
        n = s && i.height || 0,
        a = r && o.height || 0,
        h = o.buttonsEnabled && a || 0;
      this.handles = [], this.shades = [], this.chart = t, this.setBaseSeries(), this.height = n, this.scrollbarHeight = a, this.scrollButtonSize = h, this.scrollbarEnabled = r, this.navigatorEnabled = s, this.navigatorOptions = i, this.scrollbarOptions = o, this.setOpposite();
      let l = this,
        d = l.baseSeries,
        c = t.xAxis.length,
        p = t.yAxis.length,
        u = d && d[0] && d[0].xAxis || t.xAxis[0] || {
          options: {}
        };
      if (t.isDirtyBox = !0, l.navigatorEnabled) {
        let e = this.getXAxisOffsets();
        l.xAxis = new sU(t, d8({
          breaks: u.options.breaks,
          ordinal: u.options.ordinal,
          overscroll: u.options.overscroll
        }, i.xAxis, {
          type: "datetime",
          yAxis: i.yAxis?.id,
          index: c,
          isInternal: !0,
          offset: 0,
          keepOrdinalPadding: !0,
          startOnTick: !1,
          endOnTick: !1,
          minPadding: u.options.ordinal ? 0 : u.options.minPadding,
          maxPadding: u.options.ordinal ? 0 : u.options.maxPadding,
          zoomEnabled: !1
        }, t.inverted ? {
          offsets: e,
          width: n
        } : {
          offsets: e,
          height: n
        }), "xAxis"), l.yAxis = new sU(t, d8(i.yAxis, {
          alignTicks: !1,
          offset: 0,
          index: p,
          isInternal: !0,
          reversed: d7(i.yAxis && i.yAxis.reversed, t.yAxis[0] && t.yAxis[0].reversed, !1),
          zoomEnabled: !1
        }, t.inverted ? {
          width: n
        } : {
          height: n
        }), "yAxis"), d || i.series.data ? l.updateNavigatorSeries(!1) : 0 === t.series.length && (l.unbindRedraw = dK(t, "beforeRedraw", function () {
          t.series.length > 0 && !l.series && (l.setBaseSeries(), l.unbindRedraw());
        })), l.reversedExtremes = t.inverted && !l.xAxis.reversed || !t.inverted && l.xAxis.reversed, l.renderElements(), l.addMouseEvents();
      } else l.xAxis = {
        chart: t,
        navigatorAxis: {
          fake: !0
        },
        translate: function (e, i) {
          let s = t.xAxis[0],
            o = s.getExtremes(),
            r = s.len - 2 * h,
            n = ci("min", s.options.min, o.dataMin),
            a = ci("max", s.options.max, o.dataMax) - n;
          return i ? e * a / r + n : r * (e - n) / a;
        },
        toPixels: function (t) {
          return this.translate(t);
        },
        toValue: function (t) {
          return this.translate(t, !0);
        }
      }, l.xAxis.navigatorAxis.axis = l.xAxis, l.xAxis.navigatorAxis.toFixedRange = dg.prototype.toFixedRange.bind(l.xAxis.navigatorAxis);
      if (t.options.scrollbar.enabled) {
        let e = d8(t.options.scrollbar, {
          vertical: t.inverted
        });
        !d4(e.margin) && l.navigatorEnabled && (e.margin = t.inverted ? -3 : 3), t.scrollbar = l.scrollbar = new d$(t.renderer, e, t), dK(l.scrollbar, "changed", function (t) {
          let e = l.size,
            i = e * this.to,
            s = e * this.from;
          l.hasDragged = l.scrollbar.hasDragged, l.render(0, 0, s, i), this.shouldUpdateExtremes(t.DOMType) && setTimeout(function () {
            l.onMouseUp(t);
          });
        });
      }
      l.addBaseSeriesEvents(), l.addChartEvents();
    }
    setOpposite() {
      let t = this.navigatorOptions,
        e = this.navigatorEnabled,
        i = this.chart;
      this.opposite = d7(t.opposite, !!(!e && i.inverted));
    }
    getUnionExtremes(t) {
      let e;
      let i = this.chart.xAxis[0],
        s = this.chart.time,
        o = this.xAxis,
        r = o.options,
        n = i.options;
      return t && null === i.dataMin || (e = {
        dataMin: d7(s.parse(r?.min), ci("min", s.parse(n.min), i.dataMin, o.dataMin, o.min)),
        dataMax: d7(s.parse(r?.max), ci("max", s.parse(n.max), i.dataMax, o.dataMax, o.max))
      }), e;
    }
    setBaseSeries(t, e) {
      let i = this.chart,
        s = this.baseSeries = [];
      t = t || i.options && i.options.navigator.baseSeries || (i.series.length ? d5(i.series, t => !t.options.isInternal).index : 0), (i.series || []).forEach((e, i) => {
        !e.options.isInternal && (e.options.showInNavigator || (i === t || e.options.id === t) && !1 !== e.options.showInNavigator) && s.push(e);
      }), this.xAxis && !this.xAxis.navigatorAxis.fake && this.updateNavigatorSeries(!0, e);
    }
    updateNavigatorSeries(t, e) {
      let i = this,
        s = i.chart,
        o = i.baseSeries,
        r = {
          enableMouseTracking: !1,
          index: null,
          linkedTo: null,
          group: "nav",
          padXAxis: !1,
          xAxis: this.navigatorOptions.xAxis?.id,
          yAxis: this.navigatorOptions.yAxis?.id,
          showInLegend: !1,
          stacking: void 0,
          isInternal: !0,
          states: {
            inactive: {
              opacity: 1
            }
          }
        },
        n = i.series = (i.series || []).filter(t => {
          let e = t.baseSeries;
          return !(0 > o.indexOf(e)) || (e && (ct(e, "updatedData", i.updatedDataHandler), delete e.navigatorSeries), t.chart && t.destroy(), !1);
        }),
        a,
        h,
        l = i.navigatorOptions.series,
        d;
      o && o.length && o.forEach(t => {
        let c = t.navigatorSeries,
          p = d3({
            color: t.color,
            visible: t.visible
          }, d9(l) ? d_.navigator.series : l);
        if (c && !1 === i.navigatorOptions.adaptToUpdatedData) return;
        r.name = "Navigator " + o.length, d = (a = t.options || {}).navigatorOptions || {}, p.dataLabels = ce(p.dataLabels), (h = d8(a, r, p, d)).pointRange = d7(p.pointRange, d.pointRange, d_.plotOptions[h.type || "line"].pointRange);
        let u = d.data || p.data;
        i.hasNavigatorData = i.hasNavigatorData || !!u, h.data = u || a.data?.slice(0), c && c.options ? c.update(h, e) : (t.navigatorSeries = s.initSeries(h), s.setSortedData(), t.navigatorSeries.baseSeries = t, n.push(t.navigatorSeries));
      }), (l.data && !(o && o.length) || d9(l)) && (i.hasNavigatorData = !1, (l = ce(l)).forEach((t, e) => {
        r.name = "Navigator " + (n.length + 1), (h = d8(d_.navigator.series, {
          color: s.series[e] && !s.series[e].options.isInternal && s.series[e].color || s.options.colors[e] || s.options.colors[0]
        }, r, t)).data = t.data, h.data && (i.hasNavigatorData = !0, n.push(s.initSeries(h)));
      })), t && this.addBaseSeriesEvents();
    }
    addBaseSeriesEvents() {
      let t = this,
        e = t.baseSeries || [];
      e[0] && e[0].xAxis && e[0].eventsToUnbind.push(dK(e[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes)), e.forEach(i => {
        i.eventsToUnbind.push(dK(i, "show", function () {
          this.navigatorSeries && this.navigatorSeries.setVisible(!0, !1);
        })), i.eventsToUnbind.push(dK(i, "hide", function () {
          this.navigatorSeries && this.navigatorSeries.setVisible(!1, !1);
        })), !1 !== this.navigatorOptions.adaptToUpdatedData && i.xAxis && i.eventsToUnbind.push(dK(i, "updatedData", this.updatedDataHandler)), i.eventsToUnbind.push(dK(i, "remove", function () {
          e && d2(e, i), this.navigatorSeries && t.series && (d2(t.series, this.navigatorSeries), d0(this.navigatorSeries.options) && this.navigatorSeries.remove(!1), delete this.navigatorSeries);
        }));
      });
    }
    getBaseSeriesMin(t) {
      return this.baseSeries.reduce(function (t, e) {
        return Math.min(t, e.getColumn("x")[0] ?? t);
      }, t);
    }
    modifyNavigatorAxisExtremes() {
      let t = this.xAxis;
      if (void 0 !== t.getExtremes) {
        let e = this.getUnionExtremes(!0);
        e && (e.dataMin !== t.min || e.dataMax !== t.max) && (t.min = e.dataMin, t.max = e.dataMax);
      }
    }
    modifyBaseAxisExtremes() {
      let t, e;
      let i = this.chart.navigator,
        s = this.getExtremes(),
        o = s.min,
        r = s.max,
        n = s.dataMin,
        a = s.dataMax,
        h = r - o,
        l = i.stickToMin,
        d = i.stickToMax,
        c = d7(this.ordinal?.convertOverscroll(this.options.overscroll), 0),
        p = i.series && i.series[0],
        u = !!this.setExtremes;
      !(this.eventArgs && "rangeSelectorButton" === this.eventArgs.trigger) && (l && (t = (e = n) + h), d && (t = a + c, l || (e = Math.max(n, t - h, i.getBaseSeriesMin(p && p.xData ? p.xData[0] : -Number.MAX_VALUE)))), u && (l || d) && d4(e) && (this.min = this.userMin = e, this.max = this.userMax = t)), i.stickToMin = i.stickToMax = null;
    }
    updatedDataHandler() {
      let t = this.chart.navigator,
        e = this.navigatorSeries,
        i = t.reversedExtremes ? 0 === Math.round(t.zoomedMin) : Math.round(t.zoomedMax) >= Math.round(t.size);
      t.stickToMax = d7(this.chart.options.navigator && this.chart.options.navigator.stickToMax, i), t.stickToMin = t.shouldStickToMin(this, t), e && !t.hasNavigatorData && (e.options.pointStart = this.getColumn("x")[0], e.setData(this.options.data, !1, null, !1));
    }
    shouldStickToMin(t, e) {
      let i = e.getBaseSeriesMin(t.getColumn("x")[0]),
        s = t.xAxis,
        o = s.max,
        r = s.min,
        n = s.options.range;
      return !!(d4(o) && d4(r)) && (n && o - i > 0 ? o - i < n : r <= i);
    }
    addChartEvents() {
      this.eventsToUnbind || (this.eventsToUnbind = []), this.eventsToUnbind.push(dK(this.chart, "redraw", function () {
        let t = this.navigator,
          e = t && (t.baseSeries && t.baseSeries[0] && t.baseSeries[0].xAxis || this.xAxis[0]);
        e && t.render(e.min, e.max);
      }), dK(this.chart, "getMargins", function () {
        let t = this.navigator,
          e = t.opposite ? "plotTop" : "marginBottom";
        this.inverted && (e = t.opposite ? "marginRight" : "plotLeft"), this[e] = (this[e] || 0) + (t.navigatorEnabled || !this.inverted ? t.height + t.scrollbarHeight : 0) + t.navigatorOptions.margin;
      }), dK(cs, "setRange", function (t) {
        this.chart.xAxis[0].setExtremes(t.min, t.max, t.redraw, t.animation, t.eventArguments);
      }));
    }
    destroy() {
      this.removeEvents(), this.xAxis && (d2(this.chart.xAxis, this.xAxis), d2(this.chart.axes, this.xAxis)), this.yAxis && (d2(this.chart.yAxis, this.yAxis), d2(this.chart.axes, this.yAxis)), (this.series || []).forEach(t => {
        t.destroy && t.destroy();
      }), ["series", "xAxis", "yAxis", "shades", "outline", "scrollbarTrack", "scrollbarRifles", "scrollbarGroup", "scrollbar", "navigatorGroup", "rendered"].forEach(t => {
        this[t] && this[t].destroy && this[t].destroy(), this[t] = null;
      }), [this.handles].forEach(t => {
        d1(t);
      }), this.navigatorEnabled = !1;
    }
  }
  let co = {
      lang: {
        rangeSelectorZoom: "Zoom",
        rangeSelectorFrom: "",
        rangeSelectorTo: "→"
      },
      rangeSelector: {
        allButtonsEnabled: !1,
        buttons: [{
          type: "month",
          count: 1,
          text: "1m",
          title: "View 1 month"
        }, {
          type: "month",
          count: 3,
          text: "3m",
          title: "View 3 months"
        }, {
          type: "month",
          count: 6,
          text: "6m",
          title: "View 6 months"
        }, {
          type: "ytd",
          text: "YTD",
          title: "View year to date"
        }, {
          type: "year",
          count: 1,
          text: "1y",
          title: "View 1 year"
        }, {
          type: "all",
          text: "All",
          title: "View all"
        }],
        buttonSpacing: 5,
        dropdown: "responsive",
        enabled: void 0,
        verticalAlign: "top",
        buttonTheme: {
          width: 28,
          height: 18,
          padding: 2,
          zIndex: 7
        },
        floating: !1,
        x: 0,
        y: 0,
        height: void 0,
        inputBoxBorderColor: "none",
        inputBoxHeight: 17,
        inputBoxWidth: void 0,
        inputDateFormat: "%[ebY]",
        inputDateParser: void 0,
        inputEditDateFormat: "%Y-%m-%d",
        inputEnabled: !0,
        inputPosition: {
          align: "right",
          x: 0,
          y: 0
        },
        inputSpacing: 5,
        selected: void 0,
        buttonPosition: {
          align: "left",
          x: 0,
          y: 0
        },
        inputStyle: {
          color: "#334eff",
          cursor: "pointer",
          fontSize: "0.8em"
        },
        labelStyle: {
          color: "#666666",
          fontSize: "0.8em"
        }
      }
    },
    {
      defaultOptions: cr
    } = tS,
    {
      composed: cn
    } = O,
    {
      addEvent: ca,
      defined: ch,
      extend: cl,
      isNumber: cd,
      merge: cc,
      pick: cp,
      pushUnique: cu
    } = tt,
    cg = [];
  function cf() {
    let t, e;
    let i = this.range,
      s = i.type,
      o = this.max,
      r = this.chart.time,
      n = function (t, e) {
        let i = r.toParts(t),
          o = i.slice();
        "year" === s ? o[0] += e : o[1] += e;
        let n = r.makeTime.apply(r, o),
          a = r.toParts(n);
        return "month" === s && i[1] === a[1] && 1 === Math.abs(e) && (o[0] = i[0], o[1] = i[1], o[2] = 0), (n = r.makeTime.apply(r, o)) - t;
      };
    cd(i) ? (t = o - i, e = i) : i && (t = o + n(o, -(i.count || 1)), this.chart && this.chart.setFixedRange(o - t));
    let a = cp(this.dataMin, Number.MIN_VALUE);
    return cd(t) || (t = a), t <= a && (t = a, void 0 === e && (e = n(t, i.count)), this.newMax = Math.min(t + e, cp(this.dataMax, Number.MAX_VALUE))), cd(o) ? !cd(i) && i && i._offsetMin && (t += i._offsetMin) : t = void 0, t;
  }
  function cm() {
    this.rangeSelector?.redrawElements();
  }
  function cx() {
    this.options.rangeSelector && this.options.rangeSelector.enabled && (this.rangeSelector = new s(this));
  }
  function cy() {
    let t = this.rangeSelector;
    if (t) {
      cd(t.deferredYTDClick) && (t.clickButton(t.deferredYTDClick), delete t.deferredYTDClick);
      let e = t.options.verticalAlign;
      t.options.floating || ("bottom" === e ? this.extraBottomMargin = !0 : "top" !== e || (this.extraTopMargin = !0));
    }
  }
  function cb() {
    let t;
    let e = this.rangeSelector;
    if (!e) return;
    let i = this.xAxis[0].getExtremes(),
      s = this.legend,
      o = e && e.options.verticalAlign;
    cd(i.min) && e.render(i.min, i.max), s.display && "top" === o && o === s.options.verticalAlign && (t = cc(this.spacingBox), "vertical" === s.options.layout ? t.y = this.plotTop : t.y += e.getHeight(), s.group.placed = !1, s.align(t));
  }
  function cv() {
    for (let t = 0, e = cg.length; t < e; ++t) {
      let e = cg[t];
      if (e[0] === this) {
        e[1].forEach(t => t()), cg.splice(t, 1);
        return;
      }
    }
  }
  function cM() {
    let t = this.rangeSelector;
    if (t?.options?.enabled) {
      let e = t.getHeight(),
        i = t.options.verticalAlign;
      t.options.floating || ("bottom" === i ? this.marginBottom += e : "middle" === i || (this.plotTop += e));
    }
  }
  function ck(t) {
    let e = t.options.rangeSelector,
      i = this.extraBottomMargin,
      o = this.extraTopMargin,
      r = this.rangeSelector;
    if (e && e.enabled && !ch(r) && this.options.rangeSelector && (this.options.rangeSelector.enabled = !0, this.rangeSelector = r = new s(this)), this.extraBottomMargin = !1, this.extraTopMargin = !1, r) {
      let t = e && e.verticalAlign || r.options && r.options.verticalAlign;
      r.options.floating || ("bottom" === t ? this.extraBottomMargin = !0 : "middle" === t || (this.extraTopMargin = !0)), (this.extraBottomMargin !== i || this.extraTopMargin !== o) && (this.isDirtyBox = !0);
    }
  }
  let cw = {
      compose: function (t, e, i) {
        if (s = i, cu(cn, "RangeSelector")) {
          let i = e.prototype;
          t.prototype.minFromRange = cf, ca(e, "afterGetContainer", cx), ca(e, "beforeRender", cy), ca(e, "destroy", cv), ca(e, "getMargins", cM), ca(e, "redraw", cb), ca(e, "update", ck), ca(e, "beforeRedraw", cm), i.callbacks.push(cb), cl(cr, {
            rangeSelector: co.rangeSelector
          }), cl(cr.lang, co.lang);
        }
      }
    },
    {
      addEvent: cS,
      correctFloat: cA,
      css: cT,
      defined: cP,
      error: cC,
      isNumber: cO,
      pick: cE,
      timeUnits: cL,
      isString: cB
    } = tt;
  !function (t) {
    function e(t, i, s, o, r = [], n = 0, a) {
      let h = {},
        l = this.options.tickPixelInterval,
        d = this.chart.time,
        c = [],
        p,
        u,
        g,
        f,
        m,
        x = 0,
        y = [],
        b = -Number.MAX_VALUE;
      if (!this.options.ordinal && !this.options.breaks || !r || r.length < 3 || void 0 === i) return d.getTimeTicks.apply(d, arguments);
      let v = r.length;
      for (p = 0; p < v; p++) {
        if (m = p && r[p - 1] > s, r[p] < i && (x = p), p === v - 1 || r[p + 1] - r[p] > 5 * n || m) {
          if (r[p] > b) {
            for (u = d.getTimeTicks(t, r[x], r[p], o); u.length && u[0] <= b;) u.shift();
            u.length && (b = u[u.length - 1]), c.push(y.length), y = y.concat(u);
          }
          x = p + 1;
        }
        if (m) break;
      }
      if (u) {
        if (f = u.info, a && f.unitRange <= cL.hour) {
          for (x = 1, p = y.length - 1; x < p; x++) d.dateFormat("%d", y[x]) !== d.dateFormat("%d", y[x - 1]) && (h[y[x]] = "day", g = !0);
          g && (h[y[0]] = "day"), f.higherRanks = h;
        }
        f.segmentStarts = c, y.info = f;
      } else cC(12, !1, this.chart);
      if (a && cP(l)) {
        let t = y.length,
          e = [],
          i = [],
          o,
          r,
          n,
          a,
          d,
          c = t;
        for (; c--;) r = this.translate(y[c]), n && (i[c] = n - r), e[c] = n = r;
        for (i.sort((t, e) => t - e), (a = i[Math.floor(i.length / 2)]) < .6 * l && (a = null), c = y[t - 1] > s ? t - 1 : t, n = void 0; c--;) d = Math.abs(n - (r = e[c])), n && d < .8 * l && (null === a || d < .8 * a) ? (h[y[c]] && !h[y[c + 1]] ? (o = c + 1, n = r) : o = c, y.splice(o, 1)) : n = r;
      }
      return y;
    }
    function i(t) {
      let e = this.ordinal.positions;
      if (!e) return t;
      let i = e.length - 1,
        s;
      return (t < 0 ? t = e[0] : t > i ? t = e[i] : (i = Math.floor(t), s = t - i), void 0 !== s && void 0 !== e[i]) ? e[i] + (s ? s * (e[i + 1] - e[i]) : 0) : t;
    }
    function s(t) {
      let e = this.ordinal,
        i = this.old ? this.old.min : this.min,
        s = this.old ? this.old.transA : this.transA,
        o = e.getExtendedPositions();
      if (o?.length) {
        let r = cA((t - i) * s + this.minPixelPadding),
          n = cA(e.getIndexOfPoint(r, o)),
          a = cA(n % 1);
        if (n >= 0 && n <= o.length - 1) {
          let t = o[Math.floor(n)],
            e = o[Math.ceil(n)];
          return o[Math.floor(n)] + a * (e - t);
        }
      }
      return t;
    }
    function o(e, i) {
      let s = t.Additions.findIndexOf(e, i, !0);
      if (e[s] === i) return s;
      let o = (i - e[s]) / (e[s + 1] - e[s]);
      return s + o;
    }
    function r() {
      this.ordinal || (this.ordinal = new t.Additions(this));
    }
    function n() {
      let {
        eventArgs: t,
        options: e
      } = this;
      if (this.isXAxis && cP(e.overscroll) && 0 !== e.overscroll && cO(this.max) && cO(this.min) && (this.options.ordinal && !this.ordinal.originalOrdinalRange && this.ordinal.getExtendedPositions(!1), this.max === this.dataMax && (t?.trigger !== "pan" || this.isInternal) && t?.trigger !== "navigator")) {
        let i = this.ordinal.convertOverscroll(e.overscroll);
        this.max += i, !this.isInternal && cP(this.userMin) && t?.trigger !== "mousewheel" && (this.min += i);
      }
    }
    function a() {
      this.horiz && !this.isDirty && (this.isDirty = this.isOrdinal && this.chart.navigator && !this.chart.navigator.adaptToUpdatedData);
    }
    function h() {
      this.ordinal && (this.ordinal.beforeSetTickPositions(), this.tickInterval = this.ordinal.postProcessTickInterval(this.tickInterval));
    }
    function l(t) {
      let e = this.xAxis[0],
        i = e.ordinal.convertOverscroll(e.options.overscroll),
        s = t.originalEvent.chartX,
        o = this.options.chart.panning,
        r = !1;
      if (o && "y" !== o.type && e.options.ordinal && e.series.length && (!t.touches || t.touches.length <= 1)) {
        let t, o;
        let n = this.mouseDownX,
          a = e.getExtremes(),
          h = a.dataMin,
          l = a.dataMax,
          d = a.min,
          c = a.max,
          p = this.hoverPoints,
          u = e.closestPointRange || e.ordinal && e.ordinal.overscrollPointsRange,
          g = Math.round((n - s) / (e.translationSlope * (e.ordinal.slope || u))),
          f = e.ordinal.getExtendedPositions(),
          m = {
            ordinal: {
              positions: f,
              extendedOrdinalPositions: f
            }
          },
          x = e.index2val,
          y = e.val2lin;
        if (d <= h && g < 0 || c + i >= l && g > 0) return;
        m.ordinal.positions ? Math.abs(g) > 1 && (p && p.forEach(function (t) {
          t.setState();
        }), l > (o = m.ordinal.positions)[o.length - 1] && o.push(l), this.setFixedRange(c - d), (t = e.navigatorAxis.toFixedRange(void 0, void 0, x.apply(m, [y.apply(m, [d, !0]) + g]), x.apply(m, [y.apply(m, [c, !0]) + g]))).min >= Math.min(o[0], d) && t.max <= Math.max(o[o.length - 1], c) + i && e.setExtremes(t.min, t.max, !0, !1, {
          trigger: "pan"
        }), this.mouseDownX = s, cT(this.container, {
          cursor: "move"
        })) : r = !0;
      } else r = !0;
      r || o && /y/.test(o.type) ? i && (e.max = e.dataMax + i) : t.preventDefault();
    }
    function d() {
      let t = this.xAxis;
      t && t.options.ordinal && (delete t.ordinal.index, delete t.ordinal.originalOrdinalRange);
    }
    function c(t, e) {
      let i;
      let s = this.ordinal,
        r = s.positions,
        n = s.slope,
        a;
      if (!r) return t;
      let h = r.length;
      if (r[0] <= t && r[h - 1] >= t) i = o(r, t);else {
        if (!((a = s.getExtendedPositions && s.getExtendedPositions()) && a.length)) return t;
        let h = a.length;
        n || (n = (a[h - 1] - a[0]) / h);
        let l = o(a, r[0]);
        if (t >= a[0] && t <= a[h - 1]) i = o(a, t) - l;else {
          if (!e) return t;
          i = t < a[0] ? -l - (a[0] - t) / n : (t - a[h - 1]) / n + h - l;
        }
      }
      return e ? i : n * (i || 0) + s.offset;
    }
    t.compose = function (t, o, p) {
      let u = t.prototype;
      return u.ordinal2lin || (u.getTimeTicks = e, u.index2val = i, u.lin2val = s, u.val2lin = c, u.ordinal2lin = u.val2lin, cS(t, "afterInit", r), cS(t, "foundExtremes", n), cS(t, "afterSetScale", a), cS(t, "initialAxisTranslation", h), cS(p, "pan", l), cS(p, "touchpan", l), cS(o, "updatedData", d)), t;
    }, t.Additions = class {
      constructor(t) {
        this.index = {}, this.axis = t;
      }
      beforeSetTickPositions() {
        let t = this.axis,
          e = t.ordinal,
          i = t.getExtremes(),
          s = i.min,
          o = i.max,
          r = t.brokenAxis?.hasBreaks,
          n = t.options.ordinal,
          a,
          h,
          l,
          d,
          c,
          p,
          u,
          g = [],
          f = Number.MAX_VALUE,
          m = !1,
          x = !1,
          y = !1;
        if (n || r) {
          let i = 0;
          if (t.series.forEach(function (t, e) {
            let s = t.getColumn("x", !0);
            if (h = [], e > 0 && "highcharts-navigator-series" !== t.options.id && s.length > 1 && (x = i !== s[1] - s[0]), i = s[1] - s[0], t.boosted && (y = t.boosted), t.reserveSpace() && (!1 !== t.takeOrdinalPosition || r) && (a = (g = g.concat(s)).length, g.sort(function (t, e) {
              return t - e;
            }), f = Math.min(f, cE(t.closestPointRange, f)), a)) {
              for (e = 0; e < a - 1;) g[e] !== g[e + 1] && h.push(g[e + 1]), e++;
              h[0] !== g[0] && h.unshift(g[0]), g = h;
            }
          }), t.ordinal.originalOrdinalRange || (t.ordinal.originalOrdinalRange = (g.length - 1) * f), x && y && (g.pop(), g.shift()), (a = g.length) > 2) {
            for (l = g[1] - g[0], u = a - 1; u-- && !m;) g[u + 1] - g[u] !== l && (m = !0);
            !t.options.keepOrdinalPadding && (g[0] - s > l || o - g[g.length - 1] > l) && (m = !0);
          } else t.options.overscroll && (2 === a ? f = g[1] - g[0] : 1 === a ? (f = t.ordinal.convertOverscroll(t.options.overscroll), g = [g[0], g[0] + f]) : f = e.overscrollPointsRange);
          m || t.forceOrdinal ? (t.options.overscroll && (e.overscrollPointsRange = f, g = g.concat(e.getOverscrollPositions())), e.positions = g, d = t.ordinal2lin(Math.max(s, g[0]), !0), c = Math.max(t.ordinal2lin(Math.min(o, g[g.length - 1]), !0), 1), e.slope = p = (o - s) / (c - d), e.offset = s - d * p) : (e.overscrollPointsRange = cE(t.closestPointRange, e.overscrollPointsRange), e.positions = t.ordinal.slope = e.offset = void 0);
        }
        t.isOrdinal = n && m, e.groupIntervalFactor = null;
      }
      static findIndexOf(t, e, i) {
        let s = 0,
          o = t.length - 1,
          r;
        for (; s < o;) t[r = Math.ceil((s + o) / 2)] <= e ? s = r : o = r - 1;
        return t[s] === e ? s : i ? s : -1;
      }
      getExtendedPositions(t = !0) {
        let e = this,
          i = e.axis,
          s = i.constructor.prototype,
          o = i.chart,
          r = i.series.reduce((t, e) => {
            let i = e.currentDataGrouping;
            return t + (i ? i.count + i.unitName : "raw");
          }, ""),
          n = t ? i.ordinal.convertOverscroll(i.options.overscroll) : 0,
          a = i.getExtremes(),
          h,
          l,
          d = e.index;
        return d || (d = e.index = {}), !d[r] && ((h = {
          series: [],
          chart: o,
          forceOrdinal: !1,
          getExtremes: function () {
            return {
              min: a.dataMin,
              max: a.dataMax + n
            };
          },
          applyGrouping: s.applyGrouping,
          getGroupPixelWidth: s.getGroupPixelWidth,
          getTimeTicks: s.getTimeTicks,
          options: {
            ordinal: !0
          },
          ordinal: {
            getGroupIntervalFactor: this.getGroupIntervalFactor
          },
          ordinal2lin: s.ordinal2lin,
          getIndexOfPoint: s.getIndexOfPoint,
          val2lin: s.val2lin
        }).ordinal.axis = h, i.series.forEach(i => {
          l = {
            xAxis: h,
            chart: o,
            groupPixelWidth: i.groupPixelWidth,
            destroyGroupedData: O.noop,
            getColumn: i.getColumn,
            applyGrouping: i.applyGrouping,
            getProcessedData: i.getProcessedData,
            reserveSpace: i.reserveSpace,
            visible: i.visible
          };
          let s = i.getColumn("x").concat(t ? e.getOverscrollPositions() : []);
          l.dataTable = new rl({
            columns: {
              x: s
            }
          }), l.options = {
            ...i.options,
            dataGrouping: i.currentDataGrouping ? {
              firstAnchor: i.options.dataGrouping?.firstAnchor,
              anchor: i.options.dataGrouping?.anchor,
              lastAnchor: i.options.dataGrouping?.firstAnchor,
              enabled: !0,
              forced: !0,
              approximation: "open",
              units: [[i.currentDataGrouping.unitName, [i.currentDataGrouping.count]]]
            } : {
              enabled: !1
            }
          }, h.series.push(l), i.processData.apply(l);
        }), h.applyGrouping({
          hasExtremesChanged: !0
        }), l?.closestPointRange !== l?.basePointRange && l.currentDataGrouping && (h.forceOrdinal = !0), i.ordinal.beforeSetTickPositions.apply({
          axis: h
        }), !i.ordinal.originalOrdinalRange && h.ordinal.originalOrdinalRange && (i.ordinal.originalOrdinalRange = h.ordinal.originalOrdinalRange), h.ordinal.positions && (d[r] = h.ordinal.positions)), d[r];
      }
      getGroupIntervalFactor(t, e, i) {
        let s = i.getColumn("x", !0),
          o = s.length,
          r = [],
          n,
          a,
          h = this.groupIntervalFactor;
        if (!h) {
          for (a = 0; a < o - 1; a++) r[a] = s[a + 1] - s[a];
          r.sort(function (t, e) {
            return t - e;
          }), n = r[Math.floor(o / 2)], t = Math.max(t, s[0]), e = Math.min(e, s[o - 1]), this.groupIntervalFactor = h = o * n / (e - t);
        }
        return h;
      }
      getIndexOfPoint(t, e) {
        let i = this.axis,
          s = i.min,
          r = i.minPixelPadding;
        return o(e, s) + cA((t - r) / (i.translationSlope * (this.slope || i.closestPointRange || this.overscrollPointsRange)));
      }
      getOverscrollPositions() {
        let t = this.axis,
          e = this.convertOverscroll(t.options.overscroll),
          i = this.overscrollPointsRange,
          s = [],
          o = t.dataMax;
        if (cP(i)) for (; o < t.dataMax + e;) s.push(o += i);
        return s;
      }
      postProcessTickInterval(t) {
        let e = this.axis,
          i = this.slope,
          s = e.closestPointRange;
        return i && s ? e.options.breaks ? s || t : t / (i / s) : t;
      }
      convertOverscroll(t = 0) {
        let e = this,
          i = e.axis,
          s = function (t) {
            return cE(e.originalOrdinalRange, cP(i.dataMax) && cP(i.dataMin) ? i.dataMax - i.dataMin : 0) * t;
          };
        if (cB(t)) {
          let e = parseInt(t, 10);
          if (/%$/.test(t)) return s(e / 100);
          if (/px/.test(t)) {
            let t = Math.min(e, .9 * i.len) / i.len;
            return s(t / (1 - t));
          }
          return 0;
        }
        return t;
      }
    };
  }(w || (w = {}));
  let cI = w,
    {
      defaultOptions: cD
    } = tS,
    {
      addEvent: cR,
      createElement: cN,
      css: cz,
      defined: cW,
      destroyObjectProperties: cG,
      diffObjects: cH,
      discardElement: cF,
      extend: cX,
      fireEvent: cY,
      isNumber: cj,
      isString: cU,
      merge: cV,
      objectEach: c$,
      pick: c_,
      splat: cq
    } = tt;
  function cZ(t) {
    let e = e => RegExp(`%[[a-zA-Z]*${e}`).test(t);
    if (cU(t) ? -1 !== t.indexOf("%L") : t.fractionalSecondDigits) return "text";
    let i = cU(t) ? ["a", "A", "d", "e", "w", "b", "B", "m", "o", "y", "Y"].some(e) : t.dateStyle || t.day || t.month || t.year,
      s = cU(t) ? ["H", "k", "I", "l", "M", "S"].some(e) : t.timeStyle || t.hour || t.minute || t.second;
    return i && s ? "datetime-local" : i ? "date" : s ? "time" : "text";
  }
  class cK {
    static compose(t, e) {
      cw.compose(t, e, cK);
    }
    constructor(t) {
      this.isDirty = !1, this.buttonOptions = cK.prototype.defaultButtons, this.initialButtonGroupWidth = 0, this.maxButtonWidth = () => {
        let t = 0;
        return this.buttons.forEach(e => {
          let i = e.getBBox();
          i.width > t && (t = i.width);
        }), t;
      }, this.init(t);
    }
    clickButton(t, e) {
      let i = this.chart,
        s = this.buttonOptions[t],
        o = i.xAxis[0],
        r = i.scroller && i.scroller.getUnionExtremes() || o || {},
        n = s.type,
        a = s.dataGrouping,
        h = r.dataMin,
        l = r.dataMax,
        d,
        c = cj(o?.max) ? Math.round(Math.min(o.max, l ?? o.max)) : void 0,
        p,
        u = s._range,
        g,
        f,
        m,
        x = !0;
      if (null !== h && null !== l) {
        if (this.setSelected(t), a && (this.forcedDataGrouping = !0, sU.prototype.setDataGrouping.call(o || {
          chart: this.chart
        }, a, !1), this.frozenStates = s.preserveDataGrouping), "month" === n || "year" === n) o ? (f = {
          range: s,
          max: c,
          chart: i,
          dataMin: h,
          dataMax: l
        }, d = o.minFromRange.call(f), cj(f.newMax) && (c = f.newMax), x = !1) : u = s;else if (u) cj(c) && (c = Math.min((d = Math.max(c - u, h)) + u, l), x = !1);else if ("ytd" === n) {
          if (o) !o.hasData() || cj(l) && cj(h) || (h = Number.MAX_VALUE, l = -Number.MAX_VALUE, i.series.forEach(t => {
            let e = t.getColumn("x");
            e.length && (h = Math.min(e[0], h), l = Math.max(e[e.length - 1], l));
          }), e = !1), cj(l) && cj(h) && (d = g = (m = this.getYTDExtremes(l, h)).min, c = m.max);else {
            this.deferredYTDClick = t;
            return;
          }
        } else "all" === n && o && (i.navigator && i.navigator.baseSeries[0] && (i.navigator.baseSeries[0].xAxis.options.range = void 0), d = h, c = l);
        if (x && s._offsetMin && cW(d) && (d += s._offsetMin), s._offsetMax && cW(c) && (c += s._offsetMax), this.dropdown && (this.dropdown.selectedIndex = t + 1), o) cj(d) && cj(c) && (o.setExtremes(d, c, c_(e, !0), void 0, {
          trigger: "rangeSelectorButton",
          rangeSelectorButton: s
        }), i.setFixedRange(s._range));else {
          p = cq(i.options.xAxis || {})[0];
          let t = cR(i, "afterCreateAxes", function () {
            let t = i.xAxis[0];
            t.range = t.options.range = u, t.min = t.options.min = g;
          });
          cR(i, "load", function () {
            let e = i.xAxis[0];
            i.setFixedRange(s._range), e.options.range = p.range, e.options.min = p.min, t();
          });
        }
        cY(this, "afterBtnClick");
      }
    }
    setSelected(t) {
      this.selected = this.options.selected = t;
    }
    init(t) {
      let e = this,
        i = t.options.rangeSelector,
        s = i.buttons,
        o = i.selected,
        r = function () {
          let t = e.minInput,
            i = e.maxInput;
          t && t.blur && cY(t, "blur"), i && i.blur && cY(i, "blur");
        };
      e.chart = t, e.options = i, e.buttons = [], e.buttonOptions = s, this.eventsToUnbind = [], this.eventsToUnbind.push(cR(t.container, "mousedown", r)), this.eventsToUnbind.push(cR(t, "resize", r)), s.forEach(e.computeButtonRange), void 0 !== o && s[o] && this.clickButton(o, !1), this.eventsToUnbind.push(cR(t, "load", function () {
        t.xAxis && t.xAxis[0] && cR(t.xAxis[0], "setExtremes", function (i) {
          cj(this.max) && cj(this.min) && this.max - this.min !== t.fixedRange && "rangeSelectorButton" !== i.trigger && "updatedData" !== i.trigger && e.forcedDataGrouping && !e.frozenStates && this.setDataGrouping(!1, !1);
        });
      })), this.createElements();
    }
    updateButtonStates() {
      let t = this,
        e = this.chart,
        i = this.dropdown,
        s = this.dropdownLabel,
        o = e.xAxis[0],
        r = Math.round(o.max - o.min),
        n = !o.hasVisibleSeries,
        a = 24 * 36e5,
        h = e.scroller && e.scroller.getUnionExtremes() || o,
        l = h.dataMin,
        d = h.dataMax,
        c = t.getYTDExtremes(d, l),
        p = c.min,
        u = c.max,
        g = t.selected,
        f = t.options.allButtonsEnabled,
        m = Array(t.buttonOptions.length).fill(0),
        x = cj(g),
        y = t.buttons,
        b = !1,
        v = null;
      t.buttonOptions.forEach((e, i) => {
        let s = e._range,
          h = e.type,
          c = e.count || 1,
          y = e._offsetMax - e._offsetMin,
          M = i === g,
          k = s > d - l,
          w = s < o.minRange,
          S = !1,
          A = s === r;
        if (M && k && (b = !0), o.isOrdinal && o.ordinal?.positions && s && r < s) {
          let t = o.ordinal.positions,
            e = cI.Additions.findIndexOf(t, o.min, !0),
            i = Math.min(cI.Additions.findIndexOf(t, o.max, !0) + 1, t.length - 1);
          t[i] - t[e] > s && (A = !0);
        } else ("month" === h || "year" === h) && r + 36e5 >= {
          month: 28,
          year: 365
        }[h] * a * c - y && r - 36e5 <= {
          month: 31,
          year: 366
        }[h] * a * c + y ? A = !0 : "ytd" === h ? (A = u - p + y === r, S = !M) : "all" === h && (A = o.max - o.min >= d - l);
        let T = !f && !(b && "all" === h) && (k || w || n),
          P = b && "all" === h || !S && A || M && t.frozenStates;
        T ? m[i] = 3 : P && (!x || i === g) && (v = i);
      }), null !== v ? (m[v] = 2, t.setSelected(v), this.dropdown && (this.dropdown.selectedIndex = v + 1)) : (t.setSelected(), this.dropdown && (this.dropdown.selectedIndex = -1), s && (s.setState(0), s.attr({
        text: (cD.lang.rangeSelectorZoom || "") + " ▾"
      })));
      for (let e = 0; e < m.length; e++) {
        let o = m[e],
          r = y[e];
        if (r.state !== o && (r.setState(o), i)) {
          i.options[e + 1].disabled = 3 === o, 2 === o && (s && (s.setState(2), s.attr({
            text: t.buttonOptions[e].text + " ▾"
          })), i.selectedIndex = e + 1);
          let r = s.getBBox();
          cz(i, {
            width: `${r.width}px`,
            height: `${r.height}px`
          });
        }
      }
    }
    computeButtonRange(t) {
      let e = t.type,
        i = t.count || 1,
        s = {
          millisecond: 1,
          second: 1e3,
          minute: 6e4,
          hour: 36e5,
          day: 864e5,
          week: 6048e5
        };
      s[e] ? t._range = s[e] * i : ("month" === e || "year" === e) && (t._range = 24 * {
        month: 30,
        year: 365
      }[e] * 36e5 * i), t._offsetMin = c_(t.offsetMin, 0), t._offsetMax = c_(t.offsetMax, 0), t._range += t._offsetMax - t._offsetMin;
    }
    getInputValue(t) {
      let e = "min" === t ? this.minInput : this.maxInput,
        i = this.chart.options.rangeSelector,
        s = this.chart.time;
      return e ? ("text" === e.type && i.inputDateParser || this.defaultInputDateParser)(e.value, "UTC" === s.timezone, s) : 0;
    }
    setInputValue(t, e) {
      let i = this.options,
        s = this.chart.time,
        o = "min" === t ? this.minInput : this.maxInput,
        r = "min" === t ? this.minDateBox : this.maxDateBox;
      if (o) {
        o.setAttribute("type", cZ(i.inputDateFormat || "%e %b %Y"));
        let t = o.getAttribute("data-hc-time"),
          n = cW(t) ? Number(t) : void 0;
        if (cW(e)) {
          let t = n;
          cW(t) && o.setAttribute("data-hc-time-previous", t), o.setAttribute("data-hc-time", e), n = e;
        }
        o.value = s.dateFormat(this.inputTypeFormats[o.type] || i.inputEditDateFormat, n), r && r.attr({
          text: s.dateFormat(i.inputDateFormat, n)
        });
      }
    }
    setInputExtremes(t, e, i) {
      let s = "min" === t ? this.minInput : this.maxInput;
      if (s) {
        let t = this.inputTypeFormats[s.type],
          o = this.chart.time;
        if (t) {
          let r = o.dateFormat(t, e);
          s.min !== r && (s.min = r);
          let n = o.dateFormat(t, i);
          s.max !== n && (s.max = n);
        }
      }
    }
    showInput(t) {
      let e = "min" === t ? this.minDateBox : this.maxDateBox,
        i = "min" === t ? this.minInput : this.maxInput;
      if (i && e && this.inputGroup) {
        let t = "text" === i.type,
          {
            translateX: s = 0,
            translateY: o = 0
          } = this.inputGroup,
          {
            x: r = 0,
            width: n = 0,
            height: a = 0
          } = e,
          {
            inputBoxWidth: h
          } = this.options;
        cz(i, {
          width: t ? n + (h ? -2 : 20) + "px" : "auto",
          height: a - 2 + "px",
          border: "2px solid silver"
        }), t && h ? cz(i, {
          left: s + r + "px",
          top: o + "px"
        }) : cz(i, {
          left: Math.min(Math.round(r + s - (i.offsetWidth - n) / 2), this.chart.chartWidth - i.offsetWidth) + "px",
          top: o - (i.offsetHeight - a) / 2 + "px"
        });
      }
    }
    hideInput(t) {
      let e = "min" === t ? this.minInput : this.maxInput;
      e && cz(e, {
        top: "-9999em",
        border: 0,
        width: "1px",
        height: "1px"
      });
    }
    defaultInputDateParser(t, e, i) {
      return i?.parse(t) || 0;
    }
    drawInput(t) {
      let {
          chart: e,
          div: i,
          inputGroup: s
        } = this,
        o = this,
        r = e.renderer.style || {},
        n = e.renderer,
        a = e.options.rangeSelector,
        h = cD.lang,
        l = "min" === t;
      function d(t) {
        let {
            maxInput: i,
            minInput: s
          } = o,
          r = e.xAxis[0],
          n = e.scroller?.getUnionExtremes() || r,
          a = n.dataMin,
          h = n.dataMax,
          d = e.xAxis[0].getExtremes()[t],
          c = o.getInputValue(t);
        cj(c) && c !== d && (l && i && cj(a) ? c > Number(i.getAttribute("data-hc-time")) ? c = void 0 : c < a && (c = a) : s && cj(h) && (c < Number(s.getAttribute("data-hc-time")) ? c = void 0 : c > h && (c = h)), void 0 !== c && r.setExtremes(l ? c : r.min, l ? r.max : c, void 0, void 0, {
          trigger: "rangeSelectorInput"
        }));
      }
      let c = h[l ? "rangeSelectorFrom" : "rangeSelectorTo"] || "",
        p = n.label(c, 0).addClass("highcharts-range-label").attr({
          padding: c ? 2 : 0,
          height: c ? a.inputBoxHeight : 0
        }).add(s),
        u = n.label("", 0).addClass("highcharts-range-input").attr({
          padding: 2,
          width: a.inputBoxWidth,
          height: a.inputBoxHeight,
          "text-align": "center"
        }).on("click", function () {
          o.showInput(t), o[t + "Input"].focus();
        });
      e.styledMode || u.attr({
        stroke: a.inputBoxBorderColor,
        "stroke-width": 1
      }), u.add(s);
      let g = cN("input", {
        name: t,
        className: "highcharts-range-selector"
      }, void 0, i);
      g.setAttribute("type", cZ(a.inputDateFormat || "%e %b %Y")), e.styledMode || (p.css(cV(r, a.labelStyle)), u.css(cV({
        color: "#333333"
      }, r, a.inputStyle)), cz(g, cX({
        position: "absolute",
        border: 0,
        boxShadow: "0 0 15px rgba(0,0,0,0.3)",
        width: "1px",
        height: "1px",
        padding: 0,
        textAlign: "center",
        fontSize: r.fontSize,
        fontFamily: r.fontFamily,
        top: "-9999em"
      }, a.inputStyle))), g.onfocus = () => {
        o.showInput(t);
      }, g.onblur = () => {
        g === O.doc.activeElement && d(t), o.hideInput(t), o.setInputValue(t), g.blur();
      };
      let f = !1;
      return g.onchange = () => {
        f || (d(t), o.hideInput(t), g.blur());
      }, g.onkeypress = e => {
        13 === e.keyCode && d(t);
      }, g.onkeydown = e => {
        f = !0, ("ArrowUp" === e.key || "ArrowDown" === e.key || "Tab" === e.key) && d(t);
      }, g.onkeyup = () => {
        f = !1;
      }, {
        dateBox: u,
        input: g,
        label: p
      };
    }
    getPosition() {
      let t = this.chart,
        e = t.options.rangeSelector,
        i = "top" === e.verticalAlign ? t.plotTop - t.axisOffset[0] : 0;
      return {
        buttonTop: i + e.buttonPosition.y,
        inputTop: i + e.inputPosition.y - 10
      };
    }
    getYTDExtremes(t, e) {
      let i = this.chart.time,
        s = i.toParts(t)[0];
      return {
        max: t,
        min: Math.max(e, i.makeTime(s, 0))
      };
    }
    createElements() {
      let t = this.chart,
        e = t.renderer,
        i = t.container,
        s = t.options,
        o = s.rangeSelector,
        r = o.inputEnabled,
        n = c_(s.chart.style?.zIndex, 0) + 1;
      !1 !== o.enabled && (this.group = e.g("range-selector-group").attr({
        zIndex: 7
      }).add(), this.div = cN("div", void 0, {
        position: "relative",
        height: 0,
        zIndex: n
      }), this.buttonOptions.length && this.renderButtons(), i.parentNode && i.parentNode.insertBefore(this.div, i), r && this.createInputs());
    }
    createInputs() {
      this.inputGroup = this.chart.renderer.g("input-group").add(this.group);
      let t = this.drawInput("min");
      this.minDateBox = t.dateBox, this.minLabel = t.label, this.minInput = t.input;
      let e = this.drawInput("max");
      this.maxDateBox = e.dateBox, this.maxLabel = e.label, this.maxInput = e.input;
    }
    render(t, e) {
      if (!1 === this.options.enabled) return;
      let i = this.chart,
        s = i.options.rangeSelector;
      if (s.inputEnabled) {
        this.inputGroup || this.createInputs(), this.setInputValue("min", t), this.setInputValue("max", e), this.chart.styledMode || (this.maxLabel?.css(s.labelStyle), this.minLabel?.css(s.labelStyle));
        let o = i.scroller && i.scroller.getUnionExtremes() || i.xAxis[0] || {};
        if (cW(o.dataMin) && cW(o.dataMax)) {
          let t = i.xAxis[0].minRange || 0;
          this.setInputExtremes("min", o.dataMin, Math.min(o.dataMax, this.getInputValue("max")) - t), this.setInputExtremes("max", Math.max(o.dataMin, this.getInputValue("min")) + t, o.dataMax);
        }
        if (this.inputGroup) {
          let t = 0;
          [this.minLabel, this.minDateBox, this.maxLabel, this.maxDateBox].forEach(e => {
            if (e) {
              let {
                width: i
              } = e.getBBox();
              i && (e.attr({
                x: t
              }), t += i + s.inputSpacing);
            }
          });
        }
      } else this.inputGroup && (this.inputGroup.destroy(), delete this.inputGroup);
      !this.chart.styledMode && this.zoomText && this.zoomText.css(s.labelStyle), this.alignElements(), this.updateButtonStates();
    }
    renderButtons() {
      var t;
      let {
          chart: e,
          options: i
        } = this,
        s = cD.lang,
        o = e.renderer,
        r = cV(i.buttonTheme),
        n = r && r.states;
      delete r.width, delete r.states, this.buttonGroup = o.g("range-selector-buttons").add(this.group);
      let a = this.dropdown = cN("select", void 0, {
          position: "absolute",
          padding: 0,
          border: 0,
          cursor: "pointer",
          opacity: 1e-4
        }, this.div),
        h = e.userOptions.rangeSelector?.buttonTheme;
      this.dropdownLabel = o.button("", 0, 0, () => {}, cV(r, {
        "stroke-width": c_(r["stroke-width"], 0),
        width: "auto",
        paddingLeft: c_(i.buttonTheme.paddingLeft, h?.padding, 8),
        paddingRight: c_(i.buttonTheme.paddingRight, h?.padding, 8)
      }), n && n.hover, n && n.select, n && n.disabled).hide().add(this.group), cR(a, "touchstart", () => {
        a.style.fontSize = "16px";
      });
      let l = O.isMS ? "mouseover" : "mouseenter",
        d = O.isMS ? "mouseout" : "mouseleave";
      cR(a, l, () => {
        cY(this.dropdownLabel.element, l);
      }), cR(a, d, () => {
        cY(this.dropdownLabel.element, d);
      }), cR(a, "change", () => {
        cY(this.buttons[a.selectedIndex - 1].element, "click");
      }), this.zoomText = o.label(s.rangeSelectorZoom || "", 0).attr({
        padding: i.buttonTheme.padding,
        height: i.buttonTheme.height,
        paddingLeft: 0,
        paddingRight: 0
      }).add(this.buttonGroup), this.chart.styledMode || (this.zoomText.css(i.labelStyle), (t = i.buttonTheme)["stroke-width"] ?? (t["stroke-width"] = 0)), cN("option", {
        textContent: this.zoomText.textStr,
        disabled: !0
      }, void 0, a), this.createButtons();
    }
    createButtons() {
      let {
          options: t
        } = this,
        e = cV(t.buttonTheme),
        i = e && e.states,
        s = e.width || 28;
      delete e.width, delete e.states, this.buttonOptions.forEach((t, e) => {
        this.createButton(t, e, s, i);
      });
    }
    createButton(t, e, i, s) {
      let {
          dropdown: o,
          buttons: r,
          chart: n,
          options: a
        } = this,
        h = n.renderer,
        l = cV(a.buttonTheme);
      o?.add(cN("option", {
        textContent: t.title || t.text
      }), e + 2), r[e] = h.button(t.text, 0, 0, i => {
        let s;
        let o = t.events && t.events.click;
        o && (s = o.call(t, i)), !1 !== s && this.clickButton(e), this.isActive = !0;
      }, l, s && s.hover, s && s.select, s && s.disabled).attr({
        "text-align": "center",
        width: i
      }).add(this.buttonGroup), t.title && r[e].attr("title", t.title);
    }
    alignElements() {
      let {
          buttonGroup: t,
          buttons: e,
          chart: i,
          group: s,
          inputGroup: o,
          options: r,
          zoomText: n
        } = this,
        a = i.options,
        h = a.exporting && !1 !== a.exporting.enabled && a.navigation && a.navigation.buttonOptions,
        {
          buttonPosition: l,
          inputPosition: d,
          verticalAlign: c
        } = r,
        p = (t, e, s) => h && this.titleCollision(i) && "top" === c && s && e.y - t.getBBox().height - 12 < (h.y || 0) + (h.height || 0) + i.spacing[0] ? -40 : 0,
        u = i.plotLeft;
      if (s && l && d) {
        let a = l.x - i.spacing[3];
        if (t) {
          if (this.positionButtons(), !this.initialButtonGroupWidth) {
            let t = 0;
            n && (t += n.getBBox().width + 5), e.forEach((i, s) => {
              t += i.width || 0, s !== e.length - 1 && (t += r.buttonSpacing);
            }), this.initialButtonGroupWidth = t;
          }
          u -= i.spacing[3];
          let o = p(t, l, "right" === l.align || "right" === d.align);
          this.alignButtonGroup(o), this.buttonGroup?.translateY && this.dropdownLabel.attr({
            y: this.buttonGroup.translateY
          }), s.placed = t.placed = i.hasLoaded;
        }
        let h = 0;
        r.inputEnabled && o && (h = p(o, d, "right" === l.align || "right" === d.align), "left" === d.align ? a = u : "right" === d.align && (a = -Math.max(i.axisOffset[1], -h)), o.align({
          y: d.y,
          width: o.getBBox().width,
          align: d.align,
          x: d.x + a - 2
        }, !0, i.spacingBox), o.placed = i.hasLoaded), this.handleCollision(h), s.align({
          verticalAlign: c
        }, !0, i.spacingBox);
        let g = s.alignAttr.translateY,
          f = s.getBBox().height + 20,
          m = 0;
        if ("bottom" === c) {
          let t = i.legend && i.legend.options;
          m = g - (f = f + (t && "bottom" === t.verticalAlign && t.enabled && !t.floating ? i.legend.legendHeight + c_(t.margin, 10) : 0) - 20) - (r.floating ? 0 : r.y) - (i.titleOffset ? i.titleOffset[2] : 0) - 10;
        }
        "top" === c ? (r.floating && (m = 0), i.titleOffset && i.titleOffset[0] && (m = i.titleOffset[0]), m += i.margin[0] - i.spacing[0] || 0) : "middle" === c && (d.y === l.y ? m = g : (d.y || l.y) && (d.y < 0 || l.y < 0 ? m -= Math.min(d.y, l.y) : m = g - f)), s.translate(r.x, r.y + Math.floor(m));
        let {
          minInput: x,
          maxInput: y,
          dropdown: b
        } = this;
        r.inputEnabled && x && y && (x.style.marginTop = s.translateY + "px", y.style.marginTop = s.translateY + "px"), b && (b.style.marginTop = s.translateY + "px");
      }
    }
    redrawElements() {
      let t = this.chart,
        {
          inputBoxHeight: e,
          inputBoxBorderColor: i
        } = this.options;
      if (this.maxDateBox?.attr({
        height: e
      }), this.minDateBox?.attr({
        height: e
      }), t.styledMode || (this.maxDateBox?.attr({
        stroke: i
      }), this.minDateBox?.attr({
        stroke: i
      })), this.isDirty) {
        this.isDirty = !1, this.isCollapsed = void 0;
        let t = this.options.buttons ?? [],
          e = Math.min(t.length, this.buttonOptions.length),
          {
            dropdown: i,
            options: s
          } = this,
          o = cV(s.buttonTheme),
          r = o && o.states,
          n = o.width || 28;
        if (t.length < this.buttonOptions.length) for (let e = this.buttonOptions.length - 1; e >= t.length; e--) {
          let t = this.buttons.pop();
          t?.destroy(), this.dropdown?.options.remove(e + 1);
        }
        for (let s = e - 1; s >= 0; s--) if (0 !== Object.keys(cH(t[s], this.buttonOptions[s])).length) {
          let e = t[s];
          this.buttons[s].destroy(), i?.options.remove(s + 1), this.createButton(e, s, n, r), this.computeButtonRange(e);
        }
        if (t.length > this.buttonOptions.length) for (let e = this.buttonOptions.length; e < t.length; e++) this.createButton(t[e], e, n, r), this.computeButtonRange(t[e]);
        this.buttonOptions = this.options.buttons ?? [], cW(this.options.selected) && this.buttons.length && this.clickButton(this.options.selected, !1);
      }
    }
    alignButtonGroup(t, e) {
      let {
          chart: i,
          options: s,
          buttonGroup: o,
          dropdown: r,
          dropdownLabel: n
        } = this,
        {
          buttonPosition: a
        } = s,
        h = i.plotLeft - i.spacing[3],
        l = a.x - i.spacing[3],
        d = i.plotLeft;
      "right" === a.align ? (l += t - h, this.hasVisibleDropdown && (d = i.chartWidth + t - this.maxButtonWidth() - 20)) : "center" === a.align && (l -= h / 2, this.hasVisibleDropdown && (d = i.chartWidth / 2 - this.maxButtonWidth())), r && cz(r, {
        left: d + "px",
        top: o?.translateY + "px"
      }), n?.attr({
        x: d
      }), o && o.align({
        y: a.y,
        width: c_(e, this.initialButtonGroupWidth),
        align: a.align,
        x: l
      }, !0, i.spacingBox);
    }
    positionButtons() {
      let {
          buttons: t,
          chart: e,
          options: i,
          zoomText: s
        } = this,
        o = e.hasLoaded ? "animate" : "attr",
        {
          buttonPosition: r
        } = i,
        n = e.plotLeft,
        a = n;
      s && "hidden" !== s.visibility && (s[o]({
        x: c_(n + r.x, n)
      }), a += r.x + s.getBBox().width + 5);
      for (let e = 0, s = this.buttonOptions.length; e < s; ++e) "hidden" !== t[e].visibility ? (t[e][o]({
        x: a
      }), a += (t[e].width || 0) + i.buttonSpacing) : t[e][o]({
        x: n
      });
    }
    handleCollision(t) {
      let {
          chart: e,
          buttonGroup: i,
          inputGroup: s,
          initialButtonGroupWidth: o
        } = this,
        {
          buttonPosition: r,
          dropdown: n,
          inputPosition: a
        } = this.options,
        h = () => {
          s && i && s.attr({
            translateX: s.alignAttr.translateX + (e.axisOffset[1] >= -t ? 0 : -t),
            translateY: s.alignAttr.translateY + i.getBBox().height + 10
          });
        };
      s && i ? a.align === r.align ? (h(), o > e.plotWidth + t - 20 ? this.collapseButtons() : this.expandButtons()) : o - t + s.getBBox().width > e.plotWidth ? "responsive" === n ? this.collapseButtons() : h() : this.expandButtons() : i && "responsive" === n && (o > e.plotWidth ? this.collapseButtons() : this.expandButtons()), i && ("always" === n && this.collapseButtons(), "never" === n && this.expandButtons()), this.alignButtonGroup(t);
    }
    collapseButtons() {
      let {
        buttons: t,
        zoomText: e
      } = this;
      !0 !== this.isCollapsed && (this.isCollapsed = !0, e.hide(), t.forEach(t => void t.hide()), this.showDropdown());
    }
    expandButtons() {
      let {
        buttons: t,
        zoomText: e
      } = this;
      !1 !== this.isCollapsed && (this.isCollapsed = !1, this.hideDropdown(), e.show(), t.forEach(t => void t.show()), this.positionButtons());
    }
    showDropdown() {
      let {
        buttonGroup: t,
        dropdownLabel: e,
        dropdown: i
      } = this;
      t && i && (e.show(), cz(i, {
        visibility: "inherit"
      }), this.hasVisibleDropdown = !0);
    }
    hideDropdown() {
      let {
        dropdown: t
      } = this;
      t && (this.dropdownLabel.hide(), cz(t, {
        visibility: "hidden",
        width: "1px",
        height: "1px"
      }), this.hasVisibleDropdown = !1);
    }
    getHeight() {
      let t = this.options,
        e = this.group,
        i = t.inputPosition,
        s = t.buttonPosition,
        o = t.y,
        r = s.y,
        n = i.y,
        a = 0;
      if (t.height) return t.height;
      this.alignElements(), a = e ? e.getBBox(!0).height + 13 + o : 0;
      let h = Math.min(n, r);
      return (n < 0 && r < 0 || n > 0 && r > 0) && (a += Math.abs(h)), a;
    }
    titleCollision(t) {
      return !(t.options.title.text || t.options.subtitle.text);
    }
    update(t, e = !0) {
      let i = this.chart;
      if (cV(!0, this.options, t), this.options.selected && this.options.selected >= this.options.buttons.length && (this.options.selected = void 0, i.options.rangeSelector.selected = void 0), cW(t.enabled)) return this.destroy(), this.init(i);
      this.isDirty = !!t.buttons, e && this.render();
    }
    destroy() {
      let t = this,
        e = t.minInput,
        i = t.maxInput;
      t.eventsToUnbind && (t.eventsToUnbind.forEach(t => t()), t.eventsToUnbind = void 0), cG(t.buttons), e && (e.onfocus = e.onblur = e.onchange = null), i && (i.onfocus = i.onblur = i.onchange = null), c$(t, function (e, i) {
        e && "chart" !== i && (e instanceof eJ ? e.destroy() : e instanceof window.HTMLElement && cF(e), delete t[i]), e !== cK.prototype[i] && (t[i] = null);
      }, this), this.buttons = [];
    }
  }
  cX(cK.prototype, {
    inputTypeFormats: {
      "datetime-local": "%Y-%m-%dT%H:%M:%S",
      date: "%Y-%m-%d",
      time: "%H:%M:%S"
    }
  });
  let cJ = {
      applyRadius: function (t, e) {
        let i = [];
        for (let s = 0; s < t.length; s++) {
          let o = t[s][1],
            r = t[s][2];
          if ("number" == typeof o && "number" == typeof r) {
            if (0 === s) i.push(["M", o, r]);else if (s === t.length - 1) i.push(["L", o, r]);else if (e) {
              let n = t[s - 1],
                a = t[s + 1];
              if (n && a) {
                let t = n[1],
                  s = n[2],
                  h = a[1],
                  l = a[2];
                if ("number" == typeof t && "number" == typeof h && "number" == typeof s && "number" == typeof l && t !== h && s !== l) {
                  let n = t < h ? 1 : -1,
                    a = s < l ? 1 : -1;
                  i.push(["L", o - n * Math.min(Math.abs(o - t), e), r - a * Math.min(Math.abs(r - s), e)], ["C", o, r, o, r, o + n * Math.min(Math.abs(o - h), e), r + a * Math.min(Math.abs(r - l), e)]);
                }
              }
            } else i.push(["L", o, r]);
          }
        }
        return i;
      }
    },
    {
      pick: cQ
    } = tt,
    {
      min: c0,
      max: c1,
      abs: c2
    } = Math;
  function c3(t, e, i) {
    let s = e - 1e-7,
      o = i || 0,
      r = t.length - 1,
      n,
      a;
    for (; o <= r;) if ((a = s - t[n = r + o >> 1].xMin) > 0) o = n + 1;else {
      if (!(a < 0)) return n;
      r = n - 1;
    }
    return o > 0 ? o - 1 : 0;
  }
  function c5(t, e) {
    let i = c3(t, e.x + 1) + 1;
    for (; i--;) {
      var s;
      if (t[i].xMax >= e.x && (s = t[i], e.x <= s.xMax && e.x >= s.xMin && e.y <= s.yMax && e.y >= s.yMin)) return i;
    }
    return -1;
  }
  function c6(t) {
    let e = [];
    if (t.length) {
      e.push(["M", t[0].start.x, t[0].start.y]);
      for (let i = 0; i < t.length; ++i) e.push(["L", t[i].end.x, t[i].end.y]);
    }
    return e;
  }
  function c9(t, e) {
    t.yMin = c1(t.yMin, e.yMin), t.yMax = c0(t.yMax, e.yMax), t.xMin = c1(t.xMin, e.xMin), t.xMax = c0(t.xMax, e.xMax);
  }
  let c4 = function (t, e, i) {
    let s = [],
      o = i.chartObstacles,
      r = c5(o, t),
      n = c5(o, e),
      a,
      h = cQ(i.startDirectionX, c2(e.x - t.x) > c2(e.y - t.y)) ? "x" : "y",
      l,
      d,
      c,
      p;
    function u(t, e, i, s, o) {
      let r = {
        x: t.x,
        y: t.y
      };
      return r[e] = i[s || e] + (o || 0), r;
    }
    function g(t, e, i) {
      let s = c2(e[i] - t[i + "Min"]) > c2(e[i] - t[i + "Max"]);
      return u(e, i, t, i + (s ? "Max" : "Min"), s ? 1 : -1);
    }
    n > -1 ? (a = {
      start: d = g(o[n], e, h),
      end: e
    }, p = d) : p = e, r > -1 && (d = g(l = o[r], t, h), s.push({
      start: t,
      end: d
    }), d[h] >= t[h] == d[h] >= p[h] && (c = t[h = "y" === h ? "x" : "y"] < e[h], s.push({
      start: d,
      end: u(d, h, l, h + (c ? "Max" : "Min"), c ? 1 : -1)
    }), h = "y" === h ? "x" : "y"));
    let f = s.length ? s[s.length - 1].end : t;
    d = u(f, h, p), s.push({
      start: f,
      end: d
    });
    let m = u(d, h = "y" === h ? "x" : "y", p);
    return s.push({
      start: d,
      end: m
    }), s.push(a), {
      path: cJ.applyRadius(c6(s), i.radius),
      obstacles: s
    };
  };
  function c8(t, e, i) {
    let s = cQ(i.startDirectionX, c2(e.x - t.x) > c2(e.y - t.y)),
      o = s ? "x" : "y",
      r = [],
      n = i.obstacleMetrics,
      a = c0(t.x, e.x) - n.maxWidth - 10,
      h = c1(t.x, e.x) + n.maxWidth + 10,
      l = c0(t.y, e.y) - n.maxHeight - 10,
      d = c1(t.y, e.y) + n.maxHeight + 10,
      c,
      p,
      u,
      g = !1,
      f = i.chartObstacles,
      m = c3(f, h),
      x = c3(f, a);
    function y(t, e, i) {
      let s, o, r, n;
      let a = t.x < e.x ? 1 : -1;
      t.x < e.x ? (s = t, o = e) : (s = e, o = t), t.y < e.y ? (n = t, r = e) : (n = e, r = t);
      let h = a < 0 ? c0(c3(f, o.x), f.length - 1) : 0;
      for (; f[h] && (a > 0 && f[h].xMin <= o.x || a < 0 && f[h].xMax >= s.x);) {
        if (f[h].xMin <= o.x && f[h].xMax >= s.x && f[h].yMin <= r.y && f[h].yMax >= n.y) {
          if (i) return {
            y: t.y,
            x: t.x < e.x ? f[h].xMin - 1 : f[h].xMax + 1,
            obstacle: f[h]
          };
          return {
            x: t.x,
            y: t.y < e.y ? f[h].yMin - 1 : f[h].yMax + 1,
            obstacle: f[h]
          };
        }
        h += a;
      }
      return e;
    }
    function b(t, e, i, s, o) {
      let r = o.soft,
        n = o.hard,
        a = s ? "x" : "y",
        h = {
          x: e.x,
          y: e.y
        },
        l = {
          x: e.x,
          y: e.y
        },
        d = t[a + "Max"] >= r[a + "Max"],
        c = t[a + "Min"] <= r[a + "Min"],
        p = t[a + "Max"] >= n[a + "Max"],
        u = t[a + "Min"] <= n[a + "Min"],
        g = c2(t[a + "Min"] - e[a]),
        f = c2(t[a + "Max"] - e[a]),
        m = 10 > c2(g - f) ? e[a] < i[a] : f < g;
      l[a] = t[a + "Min"], h[a] = t[a + "Max"];
      let x = y(e, l, s)[a] !== l[a],
        b = y(e, h, s)[a] !== h[a];
      return m = x ? !b || m : !b && m, m = c ? !d || m : !d && m, m = u ? !p || m : !p && m;
    }
    for ((m = c5(f = f.slice(x, m + 1), e)) > -1 && (u = function (t, e, s) {
      let o = c0(t.xMax - e.x, e.x - t.xMin) < c0(t.yMax - e.y, e.y - t.yMin),
        r = b(t, e, s, o, {
          soft: i.hardBounds,
          hard: i.hardBounds
        });
      return o ? {
        y: e.y,
        x: t[r ? "xMax" : "xMin"] + (r ? 1 : -1)
      } : {
        x: e.x,
        y: t[r ? "yMax" : "yMin"] + (r ? 1 : -1)
      };
    }(f[m], e, t), r.push({
      end: e,
      start: u
    }), e = u); (m = c5(f, e)) > -1;) p = e[o] - t[o] < 0, (u = {
      x: e.x,
      y: e.y
    })[o] = f[m][p ? o + "Max" : o + "Min"] + (p ? 1 : -1), r.push({
      end: e,
      start: u
    }), e = u;
    return {
      path: c6(c = (c = function t(e, s, o) {
        let r, n, c, p, u, m, x;
        if (e.x === s.x && e.y === s.y) return [];
        let v = o ? "x" : "y",
          M = i.obstacleOptions.margin,
          k = {
            soft: {
              xMin: a,
              xMax: h,
              yMin: l,
              yMax: d
            },
            hard: i.hardBounds
          };
        return (u = c5(f, e)) > -1 ? (p = b(u = f[u], e, s, o, k), c9(u, i.hardBounds), x = o ? {
          y: e.y,
          x: u[p ? "xMax" : "xMin"] + (p ? 1 : -1)
        } : {
          x: e.x,
          y: u[p ? "yMax" : "yMin"] + (p ? 1 : -1)
        }, (m = c5(f, x)) > -1 && (c9(m = f[m], i.hardBounds), x[v] = p ? c1(u[v + "Max"] - M + 1, (m[v + "Min"] + u[v + "Max"]) / 2) : c0(u[v + "Min"] + M - 1, (m[v + "Max"] + u[v + "Min"]) / 2), e.x === x.x && e.y === x.y ? (g && (x[v] = p ? c1(u[v + "Max"], m[v + "Max"]) + 1 : c0(u[v + "Min"], m[v + "Min"]) - 1), g = !g) : g = !1), n = [{
          start: e,
          end: x
        }]) : (r = y(e, {
          x: o ? s.x : e.x,
          y: o ? e.y : s.y
        }, o), n = [{
          start: e,
          end: {
            x: r.x,
            y: r.y
          }
        }], r[o ? "x" : "y"] !== s[o ? "x" : "y"] && (p = b(r.obstacle, r, s, !o, k), c9(r.obstacle, i.hardBounds), c = {
          x: o ? r.x : r.obstacle[p ? "xMax" : "xMin"] + (p ? 1 : -1),
          y: o ? r.obstacle[p ? "yMax" : "yMin"] + (p ? 1 : -1) : r.y
        }, o = !o, n = n.concat(t({
          x: r.x,
          y: r.y
        }, c, o)))), n = n.concat(t(n[n.length - 1].end, s, !o));
      }(t, e, s)).concat(r.reverse())),
      obstacles: c
    };
  }
  c4.requiresObstacles = !0, c8.requiresObstacles = !0;
  let c7 = {
      connectors: {
        type: "straight",
        radius: 0,
        lineWidth: 1,
        marker: {
          enabled: !1,
          align: "center",
          verticalAlign: "middle",
          inside: !1,
          lineWidth: 1
        },
        startMarker: {
          symbol: "diamond"
        },
        endMarker: {
          symbol: "arrow-filled"
        }
      }
    },
    {
      setOptions: pt
    } = tS,
    {
      defined: pe,
      error: pi,
      merge: ps
    } = tt;
  function po(t) {
    let e = t.shapeArgs;
    if (e) return {
      xMin: e.x || 0,
      xMax: (e.x || 0) + (e.width || 0),
      yMin: e.y || 0,
      yMax: (e.y || 0) + (e.height || 0)
    };
    let i = t.graphic && t.graphic.getBBox();
    return i ? {
      xMin: t.plotX - i.width / 2,
      xMax: t.plotX + i.width / 2,
      yMin: t.plotY - i.height / 2,
      yMax: t.plotY + i.height / 2
    } : null;
  }
  !function (t) {
    function e(t) {
      let e, i;
      let s = po(this);
      switch (t.align) {
        case "right":
          e = "xMax";
          break;
        case "left":
          e = "xMin";
      }
      switch (t.verticalAlign) {
        case "top":
          i = "yMin";
          break;
        case "bottom":
          i = "yMax";
      }
      return {
        x: e ? s[e] : (s.xMin + s.xMax) / 2,
        y: i ? s[i] : (s.yMin + s.yMax) / 2
      };
    }
    function i(t, e) {
      let i;
      return !pe(e) && (i = po(this)) && (e = {
        x: (i.xMin + i.xMax) / 2,
        y: (i.yMin + i.yMax) / 2
      }), Math.atan2(e.y - t.y, t.x - e.x);
    }
    function s(t, e, i) {
      let s = 2 * Math.PI,
        o = po(this),
        r = o.xMax - o.xMin,
        n = o.yMax - o.yMin,
        a = Math.atan2(n, r),
        h = r / 2,
        l = n / 2,
        d = o.xMin + h,
        c = o.yMin + l,
        p = {
          x: d,
          y: c
        },
        u = t,
        g = 1,
        f = !1,
        m = 1,
        x = 1;
      for (; u < -Math.PI;) u += s;
      for (; u > Math.PI;) u -= s;
      return g = Math.tan(u), u > -a && u <= a ? (x = -1, f = !0) : u > a && u <= Math.PI - a ? x = -1 : u > Math.PI - a || u <= -(Math.PI - a) ? (m = -1, f = !0) : m = -1, f ? (p.x += m * h, p.y += x * h * g) : (p.x += n / (2 * g) * m, p.y += x * l), i.x !== d && (p.x = i.x), i.y !== c && (p.y = i.y), {
        x: p.x + e * Math.cos(u),
        y: p.y - e * Math.sin(u)
      };
    }
    t.compose = function (t, o, r) {
      let n = r.prototype;
      n.getPathfinderAnchorPoint || (t.prototype.callbacks.push(function (t) {
        !1 !== t.options.connectors.enabled && ((t.options.pathfinder || t.series.reduce(function (t, e) {
          return e.options && ps(!0, e.options.connectors = e.options.connectors || {}, e.options.pathfinder), t || e.options && e.options.pathfinder;
        }, !1)) && (ps(!0, t.options.connectors = t.options.connectors || {}, t.options.pathfinder), pi('WARNING: Pathfinder options have been renamed. Use "chart.connectors" or "series.connectors" instead.')), this.pathfinder = new o(this), this.pathfinder.update(!0));
      }), n.getMarkerVector = s, n.getPathfinderAnchorPoint = e, n.getRadiansToVector = i, pt(c7));
    };
  }(S || (S = {}));
  let pr = S,
    {
      addEvent: pn,
      defined: pa,
      pick: ph,
      splat: pl
    } = tt,
    pd = Math.max,
    pc = Math.min;
  class pp {
    static compose(t, e) {
      pr.compose(t, pp, e);
    }
    constructor(t) {
      this.init(t);
    }
    init(t) {
      this.chart = t, this.connections = [], pn(t, "redraw", function () {
        this.pathfinder.update();
      });
    }
    update(t) {
      let e = this.chart,
        i = this,
        s = i.connections;
      i.connections = [], e.series.forEach(function (t) {
        t.visible && !t.options.isInternal && t.points.forEach(function (t) {
          let s;
          let o = t.options;
          o && o.dependency && (o.connect = o.dependency);
          let r = t.options?.connect ? pl(t.options.connect) : [];
          t.visible && !1 !== t.isInside && r.forEach(o => {
            let r = "string" == typeof o ? o : o.to;
            r && (s = e.get(r)), s instanceof oq && s.series.visible && s.visible && !1 !== s.isInside && i.connections.push(new lH(t, s, "string" == typeof o ? {} : o));
          });
        });
      });
      for (let t = 0, e, o, r = s.length, n = i.connections.length; t < r; ++t) {
        o = !1;
        let r = s[t];
        for (e = 0; e < n; ++e) {
          let t = i.connections[e];
          if ((r.options && r.options.type) === (t.options && t.options.type) && r.fromPoint === t.fromPoint && r.toPoint === t.toPoint) {
            t.graphics = r.graphics, o = !0;
            break;
          }
        }
        o || r.destroy();
      }
      delete this.chartObstacles, delete this.lineObstacles, i.renderConnections(t);
    }
    renderConnections(t) {
      t ? this.chart.series.forEach(function (t) {
        let e = function () {
          let e = t.chart.pathfinder;
          (e && e.connections || []).forEach(function (e) {
            e.fromPoint && e.fromPoint.series === t && e.render();
          }), t.pathfinderRemoveRenderEvent && (t.pathfinderRemoveRenderEvent(), delete t.pathfinderRemoveRenderEvent);
        };
        !1 === t.options.animation ? e() : t.pathfinderRemoveRenderEvent = pn(t, "afterAnimate", e);
      }) : this.connections.forEach(function (t) {
        t.render();
      });
    }
    getChartObstacles(t) {
      let e = this.chart.series,
        i = ph(t.algorithmMargin, 0),
        s = [],
        o;
      for (let t = 0, o = e.length; t < o; ++t) if (e[t].visible && !e[t].options.isInternal) for (let o = 0, r = e[t].points.length, n, a; o < r; ++o) (a = e[t].points[o]).visible && (n = function (t) {
        let e = t.shapeArgs;
        if (e) return {
          xMin: e.x || 0,
          xMax: (e.x || 0) + (e.width || 0),
          yMin: e.y || 0,
          yMax: (e.y || 0) + (e.height || 0)
        };
        let i = t.graphic && t.graphic.getBBox();
        return i ? {
          xMin: t.plotX - i.width / 2,
          xMax: t.plotX + i.width / 2,
          yMin: t.plotY - i.height / 2,
          yMax: t.plotY + i.height / 2
        } : null;
      }(a)) && s.push({
        xMin: n.xMin - i,
        xMax: n.xMax + i,
        yMin: n.yMin - i,
        yMax: n.yMax + i
      });
      return s = s.sort(function (t, e) {
        return t.xMin - e.xMin;
      }), pa(t.algorithmMargin) || (o = t.algorithmMargin = function (t) {
        let e;
        let i = t.length,
          s = [];
        for (let o = 0; o < i; ++o) for (let r = o + 1; r < i; ++r) (e = function t(e, i, s) {
          let o = ph(s, 10),
            r = e.yMax + o > i.yMin - o && e.yMin - o < i.yMax + o,
            n = e.xMax + o > i.xMin - o && e.xMin - o < i.xMax + o,
            a = r ? e.xMin > i.xMax ? e.xMin - i.xMax : i.xMin - e.xMax : 1 / 0,
            h = n ? e.yMin > i.yMax ? e.yMin - i.yMax : i.yMin - e.yMax : 1 / 0;
          return n && r ? o ? t(e, i, Math.floor(o / 2)) : 1 / 0 : pc(a, h);
        }(t[o], t[r])) < 80 && s.push(e);
        return s.push(80), pd(Math.floor(s.sort(function (t, e) {
          return t - e;
        })[Math.floor(s.length / 10)] / 2 - 1), 1);
      }(s), s.forEach(function (t) {
        t.xMin -= o, t.xMax += o, t.yMin -= o, t.yMax += o;
      })), s;
    }
    getObstacleMetrics(t) {
      let e = 0,
        i = 0,
        s,
        o,
        r = t.length;
      for (; r--;) s = t[r].xMax - t[r].xMin, o = t[r].yMax - t[r].yMin, e < s && (e = s), i < o && (i = o);
      return {
        maxHeight: i,
        maxWidth: e
      };
    }
    getAlgorithmStartDirection(t) {
      let e = "left" !== t.align && "right" !== t.align,
        i = "top" !== t.verticalAlign && "bottom" !== t.verticalAlign;
      return e ? !!i && void 0 : !!i || void 0;
    }
  }
  pp.prototype.algorithms = {
    fastAvoid: c8,
    straight: function (t, e) {
      return {
        path: [["M", t.x, t.y], ["L", e.x, e.y]],
        obstacles: [{
          start: t,
          end: e
        }]
      };
    },
    simpleConnect: c4
  }, O.Pathfinder = O.Pathfinder || pp, lB.compose(O.SVGRenderer), O.Pathfinder.compose(O.Chart, O.Point);
  let {
    addEvent: pu,
    defined: pg,
    isNumber: pf,
    pick: pm
  } = tt;
  function px() {
    let t = this.chart.options.chart;
    !this.horiz && pf(this.options.staticScale) && (!t.height || t.scrollablePlotArea && t.scrollablePlotArea.minHeight) && (this.staticScale = this.options.staticScale);
  }
  function py() {
    if ("adjustHeight" !== this.redrawTrigger) {
      for (let t of this.axes || []) {
        let e = t.chart,
          i = !!e.initiatedScale && e.options.animation,
          s = t.options.staticScale;
        if (t.staticScale && pg(t.min)) {
          let o = pm(t.brokenAxis && t.brokenAxis.unitLength, t.max + t.tickInterval - t.min) * s,
            r = (o = Math.max(o, s)) - e.plotHeight;
          !e.scrollablePixelsY && Math.abs(r) >= 1 && (e.plotHeight = o, e.redrawTrigger = "adjustHeight", e.setSize(void 0, e.chartHeight + r, i)), t.series.forEach(function (t) {
            let i = t.sharedClipKey && e.sharedClips[t.sharedClipKey];
            i && i.attr(e.inverted ? {
              width: e.plotHeight
            } : {
              height: e.plotHeight
            });
          });
        }
      }
      this.initiatedScale = !0;
    }
    this.redrawTrigger = null;
  }
  let pb = {
    compose: function (t, e) {
      let i = e.prototype;
      i.adjustHeight || (pu(t, "afterSetOptions", px), i.adjustHeight = py, pu(e, "render", i.adjustHeight));
    }
  };
  pb.compose(O.Axis, O.Chart);
  let {
      correctFloat: pv,
      isNumber: pM,
      isObject: pk
    } = tt,
    {
      column: {
        prototype: {
          pointClass: pw
        }
      }
    } = ry.seriesTypes,
    {
      extend: pS
    } = tt;
  class pA extends pw {
    static getColorByCategory(t, e) {
      let i = t.options.colors || t.chart.options.colors,
        s = i ? i.length : t.chart.options.chart.colorCount,
        o = e.y % s,
        r = i && i[o];
      return {
        colorIndex: o,
        color: r
      };
    }
    resolveColor() {
      let t = this.series;
      if (t.options.colorByPoint && !this.options.color) {
        let e = pA.getColorByCategory(t, this);
        t.chart.styledMode || (this.color = e.color), this.options.colorIndex || (this.colorIndex = e.colorIndex);
      } else this.color = this.options.color || t.color;
    }
    constructor(t, e) {
      super(t, e), this.y || (this.y = 0);
    }
    applyOptions(t, e) {
      return super.applyOptions(t, e), this.x2 = this.series.chart.time.parse(this.x2), this.isNull = !this.isValid?.(), this;
    }
    setState() {
      super.setState.apply(this, arguments), this.series.drawPoint(this, this.series.getAnimationVerb());
    }
    isValid() {
      return "number" == typeof this.x && "number" == typeof this.x2;
    }
  }
  pS(pA.prototype, {
    ttBelow: !1,
    tooltipDateKeys: ["x", "x2"]
  });
  let {
      composed: pT,
      noop: pP
    } = O,
    {
      parse: pC
    } = tO,
    {
      column: pO
    } = ry.seriesTypes,
    {
      addEvent: pE,
      clamp: pL,
      crisp: pB,
      defined: pI,
      extend: pD,
      find: pR,
      isNumber: pN,
      isObject: pz,
      merge: pW,
      pick: pG,
      pushUnique: pH,
      relativeLength: pF
    } = tt;
  function pX() {
    let t, e;
    if (this.isXAxis) {
      for (let i of (t = pG(this.dataMax, -Number.MAX_VALUE), this.series)) {
        let s = i.dataTable.getColumn("x2", !0) || i.dataTable.getColumn("end", !0);
        if (s) for (let i of s) pN(i) && i > t && (t = i, e = !0);
      }
      e && (this.dataMax = t);
    }
  }
  class pY extends pO {
    static compose(t) {
      pH(pT, "Series.XRange") && pE(t, "afterGetSeriesExtremes", pX);
    }
    init() {
      super.init.apply(this, arguments), this.options.stacking = void 0;
    }
    getColumnMetrics() {
      let t = () => {
        for (let t of this.chart.series) {
          let e = t.xAxis;
          t.xAxis = t.yAxis, t.yAxis = e;
        }
      };
      t();
      let e = super.getColumnMetrics();
      return t(), e;
    }
    cropData(t, e, i) {
      let s = t.getColumn("x") || [],
        o = t.getColumn("x2");
      t.setColumn("x", o, void 0, {
        silent: !0
      });
      let r = super.cropData(t, e, i);
      return t.setColumn("x", s.slice(r.start, r.end), void 0, {
        silent: !0
      }), r;
    }
    findPointIndex(t) {
      let e;
      let {
          cropStart: i,
          points: s
        } = this,
        {
          id: o
        } = t;
      if (o) {
        let t = pR(s, t => t.id === o);
        e = t ? t.index : void 0;
      }
      if (void 0 === e) {
        let i = pR(s, e => e.x === t.x && e.x2 === t.x2 && !e.touched);
        e = i ? i.index : void 0;
      }
      return this.cropped && pN(e) && pN(i) && e >= i && (e -= i), e;
    }
    alignDataLabel(t) {
      let e = t.plotX;
      t.plotX = pG(t.dlBox && t.dlBox.centerX, t.plotX), t.dataLabel && t.shapeArgs?.width && t.dataLabel.css({
        width: `${t.shapeArgs.width}px`
      }), super.alignDataLabel.apply(this, arguments), t.plotX = e;
    }
    translatePoint(t) {
      let e = this.xAxis,
        i = this.yAxis,
        s = this.columnMetrics,
        o = this.options,
        r = o.minPointLength || 0,
        n = (t.shapeArgs && t.shapeArgs.width || 0) / 2,
        a = this.pointXOffset = s.offset,
        h = pG(t.x2, t.x + (t.len || 0)),
        l = o.borderRadius,
        d = this.chart.plotTop,
        c = this.chart.plotLeft,
        p = t.plotX,
        u = e.translate(h, 0, 0, 0, 1),
        g = Math.abs(u - p),
        f = this.chart.inverted,
        m = pG(o.borderWidth, 1),
        x,
        y,
        b = s.offset,
        v = Math.round(s.width),
        M,
        k,
        w,
        S;
      r && ((x = r - g) < 0 && (x = 0), p -= x / 2, u += x / 2), p = Math.max(p, -10), u = pL(u, -10, e.len + 10), pI(t.options.pointWidth) && (b -= (Math.ceil(t.options.pointWidth) - v) / 2, v = Math.ceil(t.options.pointWidth)), o.pointPlacement && pN(t.plotY) && i.categories && (t.plotY = i.translate(t.y, 0, 1, 0, 1, o.pointPlacement));
      let A = pB(Math.min(p, u), m),
        T = pB(Math.max(p, u), m) - A,
        P = Math.min(pF("object" == typeof l ? l.radius : l || 0, v), Math.min(T, v) / 2),
        C = {
          x: A,
          y: pB((t.plotY || 0) + b, m),
          width: T,
          height: v,
          r: P
        };
      t.shapeArgs = C, f ? t.tooltipPos[1] += a + n : t.tooltipPos[0] -= n + a - C.width / 2, k = (M = C.x) + C.width, M < 0 || k > e.len ? (M = pL(M, 0, e.len), w = (k = pL(k, 0, e.len)) - M, t.dlBox = pW(C, {
        x: M,
        width: k - M,
        centerX: w ? w / 2 : null
      })) : t.dlBox = null;
      let O = t.tooltipPos,
        E = f ? 1 : 0,
        L = f ? 0 : 1,
        B = this.columnMetrics ? this.columnMetrics.offset : -s.width / 2;
      f ? O[E] += C.width / 2 : O[E] = pL(O[E] + (e.reversed ? -1 : 0) * C.width, e.left - c, e.left + e.len - c - 1), O[L] = pL(O[L] + (f ? -1 : 1) * B, i.top - d, i.top + i.len - d - 1), (y = t.partialFill) && (pz(y) && (y = y.amount), pN(y) || (y = 0), t.partShapeArgs = pW(C), S = Math.max(Math.round(g * y + t.plotX - p), 0), t.clipRectArgs = {
        x: e.reversed ? C.x + g - S : C.x,
        y: C.y,
        width: S,
        height: C.height
      }), t.key = t.category || t.name, t.yCategory = i.categories?.[t.y ?? -1];
    }
    translate() {
      for (let t of (super.translate.apply(this, arguments), this.points)) this.translatePoint(t);
    }
    drawPoint(t, e) {
      let i = this.options,
        s = this.chart.renderer,
        o = t.shapeType,
        r = t.shapeArgs,
        n = t.partShapeArgs,
        a = t.clipRectArgs,
        h = t.state,
        l = i.states[h || "normal"] || {},
        d = void 0 === h ? "attr" : e,
        c = this.pointAttribs(t, h),
        p = pG(this.chart.options.chart.animation, l.animation),
        u = t.graphic,
        g = t.partialFill;
      if (t.isNull || !1 === t.visible) u && (t.graphic = u.destroy());else if (u ? u.rect[e](r) : (t.graphic = u = s.g("point").addClass(t.getClassName()).add(t.group || this.group), u.rect = s[o](pW(r)).addClass(t.getClassName()).addClass("highcharts-partfill-original").add(u)), n && (u.partRect ? (u.partRect[e](pW(n)), u.partialClipRect[e](pW(a))) : (u.partialClipRect = s.clipRect(a.x, a.y, a.width, a.height), u.partRect = s[o](n).addClass("highcharts-partfill-overlay").add(u).clip(u.partialClipRect))), !this.chart.styledMode && (u.rect[e](c, p).shadow(i.shadow), n)) {
        pz(g) || (g = {}), pz(i.partialFill) && (g = pW(i.partialFill, g));
        let e = g.fill || pC(c.fill).brighten(-.3).get() || pC(t.color || this.color).brighten(-.3).get();
        c.fill = e, u.partRect[d](c, p).shadow(i.shadow);
      }
    }
    drawPoints() {
      let t = this.getAnimationVerb();
      for (let e of this.points) this.drawPoint(e, t);
    }
    getAnimationVerb() {
      return this.chart.pointCount < (this.options.animationLimit || 250) ? "animate" : "attr";
    }
    isPointInside(t) {
      let e = t.shapeArgs,
        i = t.plotX,
        s = t.plotY;
      return e ? void 0 !== i && void 0 !== s && s >= 0 && s <= this.yAxis.len && (e.x || 0) + (e.width || 0) >= 0 && i <= this.xAxis.len : super.isPointInside.apply(this, arguments);
    }
  }
  pY.defaultOptions = pW(pO.defaultOptions, {
    colorByPoint: !0,
    dataLabels: {
      formatter: function () {
        let t = this.partialFill;
        if (pk(t) && (t = t.amount), pM(t) && t > 0) return pv(100 * t) + "%";
      },
      inside: !0,
      verticalAlign: "middle",
      style: {
        whiteSpace: "nowrap"
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size: 0.8em">{ucfirst point.x} - {point.x2}</span><br/>',
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.yCategory}</b><br/>'
    },
    borderRadius: 3,
    pointRange: 0
  }), pD(pY.prototype, {
    pointClass: pA,
    pointArrayMap: ["x2", "y"],
    getExtremesFromAll: !0,
    keysAffectYAxis: ["y"],
    parallelArrays: ["x", "x2", "y"],
    requireSorting: !1,
    type: "xrange",
    animate: ry.series.prototype.animate,
    autoIncrement: pP,
    buildKDTree: pP
  }), ry.registerSeriesType("xrange", pY), pY.compose(O.Axis);
  let {
    xrange: {
      prototype: {
        pointClass: pj
      }
    }
  } = ry.seriesTypes;
  class pU extends pj {
    static setGanttPointAliases(t, e) {
      t.x = t.start = e.time.parse(t.start ?? t.x), t.x2 = t.end = e.time.parse(t.end ?? t.x2), t.partialFill = t.completed = t.completed ?? t.partialFill;
    }
    applyOptions(t, e) {
      let i = super.applyOptions(t, e);
      return pU.setGanttPointAliases(i, i.series.chart), this.isNull = !this.isValid?.(), i;
    }
    isValid() {
      return ("number" == typeof this.start || "number" == typeof this.x) && ("number" == typeof this.end || "number" == typeof this.x2 || this.milestone);
    }
  }
  let {
      isNumber: pV
    } = tt,
    {
      addEvent: p$,
      find: p_,
      fireEvent: pq,
      isArray: pZ,
      isNumber: pK,
      pick: pJ
    } = tt;
  !function (t) {
    function e() {
      void 0 !== this.brokenAxis && this.brokenAxis.setBreaks(this.options.breaks, !1);
    }
    function i() {
      this.brokenAxis?.hasBreaks && (this.options.ordinal = !1);
    }
    function s() {
      let t = this.brokenAxis;
      if (t?.hasBreaks) {
        let e = this.tickPositions,
          i = this.tickPositions.info,
          s = [];
        for (let i = 0; i < e.length; i++) t.isInAnyBreak(e[i]) || s.push(e[i]);
        this.tickPositions = s, this.tickPositions.info = i;
      }
    }
    function o() {
      this.brokenAxis || (this.brokenAxis = new l(this));
    }
    function r() {
      let {
        isDirty: t,
        options: {
          connectNulls: e
        },
        points: i,
        xAxis: s,
        yAxis: o
      } = this;
      if (t) {
        let t = i.length;
        for (; t--;) {
          let r = i[t],
            n = !(null === r.y && !1 === e) && (s?.brokenAxis?.isInAnyBreak(r.x, !0) || o?.brokenAxis?.isInAnyBreak(r.y, !0));
          r.visible = !n && !1 !== r.options.visible;
        }
      }
    }
    function n() {
      this.drawBreaks(this.xAxis, ["x"]), this.drawBreaks(this.yAxis, pJ(this.pointArrayMap, ["y"]));
    }
    function a(t, e) {
      let i, s, o;
      let r = this,
        n = r.points;
      if (t?.brokenAxis?.hasBreaks) {
        let a = t.brokenAxis;
        e.forEach(function (e) {
          i = a?.breakArray || [], s = t.isXAxis ? t.min : pJ(r.options.threshold, t.min);
          let h = t?.options?.breaks?.filter(function (t) {
            let e = !0;
            for (let s = 0; s < i.length; s++) {
              let o = i[s];
              if (o.from === t.from && o.to === t.to) {
                e = !1;
                break;
              }
            }
            return e;
          });
          n.forEach(function (r) {
            o = pJ(r["stack" + e.toUpperCase()], r[e]), i.forEach(function (e) {
              if (pK(s) && pK(o)) {
                let i = "";
                s < e.from && o > e.to || s > e.from && o < e.from ? i = "pointBreak" : (s < e.from && o > e.from && o < e.to || s > e.from && o > e.to && o < e.from) && (i = "pointInBreak"), i && pq(t, i, {
                  point: r,
                  brk: e
                });
              }
            }), h?.forEach(function (e) {
              pq(t, "pointOutsideOfBreak", {
                point: r,
                brk: e
              });
            });
          });
        });
      }
    }
    function h() {
      let t = this.currentDataGrouping,
        e = t?.gapSize,
        i = this.points.slice(),
        s = this.yAxis,
        o = this.options.gapSize,
        r = i.length - 1;
      if (o && r > 0) {
        let t, n;
        for ("value" !== this.options.gapUnit && (o *= this.basePointRange), e && e > o && e >= this.basePointRange && (o = e); r--;) if (n && !1 !== n.visible || (n = i[r + 1]), t = i[r], !1 !== n.visible && !1 !== t.visible) {
          if (n.x - t.x > o) {
            let e = (t.x + n.x) / 2;
            i.splice(r + 1, 0, {
              isNull: !0,
              x: e
            }), s.stacking && this.options.stacking && ((s.stacking.stacks[this.stackKey][e] = new aa(s, s.options.stackLabels, !1, e, this.stack)).total = 0);
          }
          n = t;
        }
      }
      return this.getGraphPath(i);
    }
    t.compose = function (t, l) {
      if (!t.keepProps.includes("brokenAxis")) {
        t.keepProps.push("brokenAxis"), p$(t, "init", o), p$(t, "afterInit", e), p$(t, "afterSetTickPositions", s), p$(t, "afterSetOptions", i);
        let d = l.prototype;
        d.drawBreaks = a, d.gappedPath = h, p$(l, "afterGeneratePoints", r), p$(l, "afterRender", n);
      }
      return t;
    };
    class l {
      static isInBreak(t, e) {
        let i = t.repeat || 1 / 0,
          s = t.from,
          o = t.to - t.from,
          r = e >= s ? (e - s) % i : i - (s - e) % i;
        return t.inclusive ? r <= o : r < o && 0 !== r;
      }
      static lin2Val(t) {
        let e = this.brokenAxis,
          i = e && e.breakArray;
        if (!i || !pK(t)) return t;
        let s = t,
          o,
          r;
        for (r = 0; r < i.length && !((o = i[r]).from >= s); r++) o.to < s ? s += o.len : l.isInBreak(o, s) && (s += o.len);
        return s;
      }
      static val2Lin(t) {
        let e = this.brokenAxis,
          i = e && e.breakArray;
        if (!i || !pK(t)) return t;
        let s = t,
          o,
          r;
        for (r = 0; r < i.length; r++) if ((o = i[r]).to <= t) s -= o.len;else if (o.from >= t) break;else if (l.isInBreak(o, t)) {
          s -= t - o.from;
          break;
        }
        return s;
      }
      constructor(t) {
        this.hasBreaks = !1, this.axis = t;
      }
      findBreakAt(t, e) {
        return p_(e, function (e) {
          return e.from < t && t < e.to;
        });
      }
      isInAnyBreak(t, e) {
        let i = this.axis,
          s = i.options.breaks || [],
          o = s.length,
          r,
          n,
          a;
        if (o && pK(t)) {
          for (; o--;) l.isInBreak(s[o], t) && (r = !0, n || (n = pJ(s[o].showPoints, !i.isXAxis)));
          a = r && e ? r && !n : r;
        }
        return a;
      }
      setBreaks(t, e) {
        let i = this,
          s = i.axis,
          o = s.chart.time,
          r = pZ(t) && !!t.length && !!Object.keys(t[0]).length;
        s.isDirty = i.hasBreaks !== r, i.hasBreaks = r, t?.forEach(t => {
          t.from = o.parse(t.from) || 0, t.to = o.parse(t.to) || 0;
        }), t !== s.options.breaks && (s.options.breaks = s.userOptions.breaks = t), s.forceRedraw = !0, s.series.forEach(function (t) {
          t.isDirty = !0;
        }), r || s.val2lin !== l.val2Lin || (delete s.val2lin, delete s.lin2val), r && (s.userOptions.ordinal = !1, s.lin2val = l.lin2Val, s.val2lin = l.val2Lin, s.setExtremes = function (t, e, o, r, n) {
          if (i.hasBreaks) {
            let s;
            let o = this.options.breaks || [];
            for (; s = i.findBreakAt(t, o);) t = s.to;
            for (; s = i.findBreakAt(e, o);) e = s.from;
            e < t && (e = t);
          }
          s.constructor.prototype.setExtremes.call(this, t, e, o, r, n);
        }, s.setAxisTranslation = function () {
          if (s.constructor.prototype.setAxisTranslation.call(this), i.unitLength = void 0, i.hasBreaks) {
            let t = s.options.breaks || [],
              e = [],
              o = [],
              r = pJ(s.pointRangePadding, 0),
              n = 0,
              a,
              h,
              d = s.userMin || s.min,
              c = s.userMax || s.max,
              p,
              u;
            t.forEach(function (t) {
              h = t.repeat || 1 / 0, pK(d) && pK(c) && (l.isInBreak(t, d) && (d += t.to % h - d % h), l.isInBreak(t, c) && (c -= c % h - t.from % h));
            }), t.forEach(function (t) {
              if (p = t.from, h = t.repeat || 1 / 0, pK(d) && pK(c)) {
                for (; p - h > d;) p -= h;
                for (; p < d;) p += h;
                for (u = p; u < c; u += h) e.push({
                  value: u,
                  move: "in"
                }), e.push({
                  value: u + t.to - t.from,
                  move: "out",
                  size: t.breakSize
                });
              }
            }), e.sort(function (t, e) {
              return t.value === e.value ? ("in" === t.move ? 0 : 1) - ("in" === e.move ? 0 : 1) : t.value - e.value;
            }), a = 0, p = d, e.forEach(function (t) {
              1 === (a += "in" === t.move ? 1 : -1) && "in" === t.move && (p = t.value), 0 === a && pK(p) && (o.push({
                from: p,
                to: t.value,
                len: t.value - p - (t.size || 0)
              }), n += t.value - p - (t.size || 0));
            }), i.breakArray = o, pK(d) && pK(c) && pK(s.min) && (i.unitLength = c - d - n + r, pq(s, "afterBreaks"), s.staticScale ? s.transA = s.staticScale : i.unitLength && (s.transA *= (c - s.min + r) / i.unitLength), r && (s.minPixelPadding = s.transA * (s.minPointOffset || 0)), s.min = d, s.max = c);
          }
        }), pJ(e, !0) && s.chart.redraw();
      }
    }
    t.Additions = l;
  }(A || (A = {}));
  let pQ = A,
    {
      dateFormats: p0
    } = O,
    {
      addEvent: p1,
      defined: p2,
      erase: p3,
      find: p5,
      isArray: p6,
      isNumber: p9,
      merge: p4,
      pick: p8,
      timeUnits: p7,
      wrap: ut
    } = tt;
  function ue(t) {
    return tt.isObject(t, !0);
  }
  function ui(t, e) {
    let i = {
      width: 0,
      height: 0
    };
    if (e.forEach(function (e) {
      let s = t[e],
        o = 0,
        r = 0,
        n;
      ue(s) && (o = (n = ue(s.label) ? s.label : {}).getBBox ? n.getBBox().height : 0, n.textStr && !p9(n.textPxLength) && (n.textPxLength = n.getBBox().width), r = p9(n.textPxLength) ? Math.round(n.textPxLength) : 0, n.textStr && (r = Math.round(n.getBBox().width)), i.height = Math.max(o, i.height), i.width = Math.max(r, i.width));
    }), "treegrid" === this.type && this.treeGrid && this.treeGrid.mapOfPosToGridNode) {
      let t = this.treeGrid.mapOfPosToGridNode[-1].height || 0;
      i.width += this.options.labels.indentation * (t - 1);
    }
    return i;
  }
  function us(t) {
    let {
        grid: e
      } = this,
      i = 3 === this.side;
    if (i || t.apply(this), !e?.isColumn) {
      let t = e?.columns || [];
      i && (t = t.slice().reverse()), t.forEach(t => {
        t.getOffset();
      });
    }
    i && t.apply(this);
  }
  function uo(t) {
    if (!0 === (this.options.grid || {}).enabled) {
      let {
          axisTitle: e,
          height: i,
          horiz: s,
          left: o,
          offset: r,
          opposite: n,
          options: a,
          top: h,
          width: l
        } = this,
        d = this.tickSize(),
        c = e && e.getBBox().width,
        p = a.title.x,
        u = a.title.y,
        g = p8(a.title.margin, s ? 5 : 10),
        f = e ? this.chart.renderer.fontMetrics(e).f : 0,
        m = (s ? h + i : o) + (s ? 1 : -1) * (n ? -1 : 1) * (d ? d[0] / 2 : 0) + (this.side === T.bottom ? f : 0);
      t.titlePosition.x = s ? o - (c || 0) / 2 - g + p : m + (n ? l : 0) + r + p, t.titlePosition.y = s ? m - (n ? i : 0) + (n ? f : -f) / 2 + r + u : h - g + u;
    }
  }
  function ur() {
    let {
      chart: t,
      options: {
        grid: e = {}
      },
      userOptions: i
    } = this;
    if (e.enabled && function (t) {
      let e = t.options;
      e.labels.align = p8(e.labels.align, "center"), t.categories || (e.showLastLabel = !1), t.labelRotation = 0, e.labels.rotation = 0, e.minTickInterval = 1;
    }(this), e.columns) {
      let s = this.grid.columns = [],
        o = this.grid.columnIndex = 0;
      for (; ++o < e.columns.length;) {
        let r = p4(i, e.columns[o], {
            isInternal: !0,
            linkedTo: 0,
            scrollbar: {
              enabled: !1
            }
          }, {
            grid: {
              columns: void 0
            }
          }),
          n = new sU(this.chart, r, "yAxis");
        n.grid.isColumn = !0, n.grid.columnIndex = o, p3(t.axes, n), p3(t[this.coll] || [], n), s.push(n);
      }
    }
  }
  function un() {
    let {
      axisTitle: t,
      grid: e,
      options: i
    } = this;
    if (!0 === (i.grid || {}).enabled) {
      let s = this.min || 0,
        o = this.max || 0,
        r = this.ticks[this.tickPositions[0]];
      if (t && !this.chart.styledMode && r?.slotWidth && !this.options.title.style.width && t.css({
        width: `${r.slotWidth}px`
      }), this.maxLabelDimensions = this.getMaxLabelDimensions(this.ticks, this.tickPositions), this.rightWall && this.rightWall.destroy(), this.grid && this.grid.isOuterAxis() && this.axisLine) {
        let t = i.lineWidth;
        if (t) {
          let e = this.getLinePath(t),
            r = e[0],
            n = e[1],
            a = (this.tickSize("tick") || [1])[0] * (this.side === T.top || this.side === T.left ? -1 : 1);
          if ("M" === r[0] && "L" === n[0] && (this.horiz ? (r[2] += a, n[2] += a) : (r[1] += a, n[1] += a)), !this.horiz && this.chart.marginRight) {
            let t = ["L", this.left, r[2] || 0],
              e = [r, t],
              a = ["L", this.chart.chartWidth - this.chart.marginRight, this.toPixels(o + this.tickmarkOffset)],
              h = [["M", n[1] || 0, this.toPixels(o + this.tickmarkOffset)], a];
            this.grid.upperBorder || s % 1 == 0 || (this.grid.upperBorder = this.grid.renderBorder(e)), this.grid.upperBorder && (this.grid.upperBorder.attr({
              stroke: i.lineColor,
              "stroke-width": i.lineWidth
            }), this.grid.upperBorder.animate({
              d: e
            })), this.grid.lowerBorder || o % 1 == 0 || (this.grid.lowerBorder = this.grid.renderBorder(h)), this.grid.lowerBorder && (this.grid.lowerBorder.attr({
              stroke: i.lineColor,
              "stroke-width": i.lineWidth
            }), this.grid.lowerBorder.animate({
              d: h
            }));
          }
          this.grid.axisLineExtra ? (this.grid.axisLineExtra.attr({
            stroke: i.lineColor,
            "stroke-width": i.lineWidth
          }), this.grid.axisLineExtra.animate({
            d: e
          })) : this.grid.axisLineExtra = this.grid.renderBorder(e), this.axisLine[this.showAxis ? "show" : "hide"]();
        }
      }
      if ((e && e.columns || []).forEach(t => t.render()), !this.horiz && this.chart.hasRendered && (this.scrollbar || this.linkedParent && this.linkedParent.scrollbar) && this.tickPositions.length) {
        let t, e;
        let i = this.tickmarkOffset,
          r = this.tickPositions[this.tickPositions.length - 1],
          n = this.tickPositions[0];
        for (; (t = this.hiddenLabels.pop()) && t.element;) t.show();
        for (; (e = this.hiddenMarks.pop()) && e.element;) e.show();
        (t = this.ticks[n].label) && (s - n > i ? this.hiddenLabels.push(t.hide()) : t.show()), (t = this.ticks[r].label) && (r - o > i ? this.hiddenLabels.push(t.hide()) : t.show());
        let a = this.ticks[r].mark;
        a && r - o < i && r - o > 0 && this.ticks[r].isLast && this.hiddenMarks.push(a.hide());
      }
    }
  }
  function ua() {
    let t = this.tickPositions && this.tickPositions.info,
      e = this.options,
      i = e.grid || {},
      s = this.userOptions.labels || {};
    i.enabled && (this.horiz ? (this.series.forEach(t => {
      t.options.pointRange = 0;
    }), t && e.dateTimeLabelFormats && e.labels && !p2(s.align) && (!1 === e.dateTimeLabelFormats[t.unitName].range || t.count > 1) && (e.labels.align = "left", p2(s.x) || (e.labels.x = 3))) : "treegrid" !== this.type && this.grid && this.grid.columns && (this.minPointOffset = this.tickInterval));
  }
  function uh(t) {
    let e;
    let i = this.options,
      s = t.userOptions,
      o = i && ue(i.grid) ? i.grid : {};
    !0 === o.enabled && (e = p4(!0, {
      className: "highcharts-grid-axis " + (s.className || ""),
      dateTimeLabelFormats: {
        hour: {
          list: ["%[HM]", "%[H]"]
        },
        day: {
          list: ["%[AeB]", "%[aeb]", "%[E]"]
        },
        week: {
          list: ["Week %W", "W%W"]
        },
        month: {
          list: ["%[B]", "%[b]", "%o"]
        }
      },
      grid: {
        borderWidth: 1
      },
      labels: {
        padding: 2,
        style: {
          fontSize: "0.9em"
        }
      },
      margin: 0,
      title: {
        text: null,
        reserveSpace: !1,
        rotation: 0,
        style: {
          textOverflow: "ellipsis"
        }
      },
      units: [["millisecond", [1, 10, 100]], ["second", [1, 10]], ["minute", [1, 5, 15]], ["hour", [1, 6]], ["day", [1]], ["week", [1]], ["month", [1]], ["year", null]]
    }, s), "xAxis" !== this.coll || (p2(s.linkedTo) && !p2(s.tickPixelInterval) && (e.tickPixelInterval = 350), !(!p2(s.tickPixelInterval) && p2(s.linkedTo)) || p2(s.tickPositioner) || p2(s.tickInterval) || p2(s.units) || (e.tickPositioner = function (t, i) {
      let s = this.linkedParent && this.linkedParent.tickPositions && this.linkedParent.tickPositions.info;
      if (s) {
        let o = e.units || [],
          r,
          n = 1,
          a = "year";
        for (let t = 0; t < o.length; t++) {
          let e = o[t];
          if (e && e[0] === s.unitName) {
            r = t;
            break;
          }
        }
        let h = p9(r) && o[r + 1];
        if (h) {
          a = h[0] || "year";
          let t = h[1];
          n = t && t[0] || 1;
        } else "year" === s.unitName && (n = 10 * s.count);
        let l = p7[a];
        return this.tickInterval = l * n, this.chart.time.getTimeTicks({
          unitRange: l,
          count: n,
          unitName: a
        }, t, i, this.options.startOfWeek);
      }
    })), p4(!0, this.options, e), this.horiz && (i.minPadding = p8(s.minPadding, 0), i.maxPadding = p8(s.maxPadding, 0)), p9(i.grid.borderWidth) && (i.tickWidth = i.lineWidth = o.borderWidth));
  }
  function ul(t) {
    let e = t.userOptions,
      i = e && e.grid || {},
      s = i.columns;
    i.enabled && s && p4(!0, this.options, s[0]);
  }
  function ud() {
    (this.grid.columns || []).forEach(t => t.setScale());
  }
  function uc(t) {
    let {
      horiz: e,
      maxLabelDimensions: i,
      options: {
        grid: s = {}
      }
    } = this;
    if (s.enabled && i) {
      let o = 2 * this.options.labels.distance,
        r = e ? s.cellHeight || o + i.height : o + i.width;
      p6(t.tickSize) ? t.tickSize[0] = r : t.tickSize = [r, 0];
    }
  }
  function up() {
    this.axes.forEach(t => {
      (t.grid && t.grid.columns || []).forEach(t => {
        t.setAxisSize(), t.setAxisTranslation();
      });
    });
  }
  function uu(t) {
    let {
      grid: e
    } = this;
    (e.columns || []).forEach(e => e.destroy(t.keepEvents)), e.columns = void 0;
  }
  function ug(t) {
    let e = t.userOptions || {},
      i = e.grid || {};
    i.enabled && p2(i.borderColor) && (e.tickColor = e.lineColor = i.borderColor), this.grid || (this.grid = new ub(this)), this.hiddenLabels = [], this.hiddenMarks = [];
  }
  function uf(t) {
    let e = this.label,
      i = this.axis,
      s = i.reversed,
      o = i.chart,
      r = i.options.grid || {},
      n = i.options.labels,
      a = n.align,
      h = T[i.side],
      l = t.tickmarkOffset,
      d = i.tickPositions,
      c = this.pos - l,
      p = p9(d[t.index + 1]) ? d[t.index + 1] - l : (i.max || 0) + l,
      u = i.tickSize("tick"),
      g = u ? u[0] : 0,
      f = u ? u[1] / 2 : 0;
    if (!0 === r.enabled) {
      let r, l, d, u;
      if ("top" === h ? l = (r = i.top + i.offset) - g : "bottom" === h ? r = (l = o.chartHeight - i.bottom + i.offset) + g : (r = i.top + i.len - (i.translate(s ? p : c) || 0), l = i.top + i.len - (i.translate(s ? c : p) || 0)), "right" === h ? u = (d = o.chartWidth - i.right + i.offset) + g : "left" === h ? d = (u = i.left + i.offset) - g : (d = Math.round(i.left + (i.translate(s ? p : c) || 0)) - f, u = Math.min(Math.round(i.left + (i.translate(s ? c : p) || 0)) - f, i.left + i.len)), this.slotWidth = u - d, t.pos.x = "left" === a ? d : "right" === a ? u : d + (u - d) / 2, t.pos.y = l + (r - l) / 2, e) {
        let i = o.renderer.fontMetrics(e),
          s = e.getBBox().height;
        if (n.useHTML) t.pos.y += i.b + -(s / 2);else {
          let e = Math.round(s / i.h);
          t.pos.y += (i.b - (i.h - i.f)) / 2 + -((e - 1) * i.h / 2);
        }
      }
      t.pos.x += i.horiz && n.x || 0;
    }
  }
  function um(t) {
    let {
      axis: e,
      value: i
    } = t;
    if (e.options.grid && e.options.grid.enabled) {
      let s;
      let o = e.tickPositions,
        r = (e.linkedParent || e).series[0],
        n = i === o[0],
        a = i === o[o.length - 1],
        h = r && p5(r.options.data, function (t) {
          return t[e.isXAxis ? "x" : "y"] === i;
        });
      h && r.is("gantt") && (s = p4(h), O.seriesTypes.gantt.prototype.pointClass.setGanttPointAliases(s, e.chart)), t.isFirst = n, t.isLast = a, t.point = s;
    }
  }
  function ux() {
    let t = this.options,
      e = t.grid || {},
      i = this.categories,
      s = this.tickPositions,
      o = s[0],
      r = s[1],
      n = s[s.length - 1],
      a = s[s.length - 2],
      h = this.linkedParent && this.linkedParent.min,
      l = this.linkedParent && this.linkedParent.max,
      d = h || this.min,
      c = l || this.max,
      p = this.tickInterval,
      u = p9(d) && d >= o + p && d < r,
      g = p9(d) && o < d && o + p > d,
      f = p9(c) && n > c && n - p < c,
      m = p9(c) && c <= n - p && c > a;
    !0 === e.enabled && !i && (this.isXAxis || this.isLinked) && ((g || u) && !t.startOnTick && (s[0] = d), (f || m) && !t.endOnTick && (s[s.length - 1] = c));
  }
  function uy(t) {
    var e;
    let {
      options: {
        grid: i = {}
      }
    } = this;
    return !0 === i.enabled && this.categories ? this.tickInterval : t.apply(this, (e = arguments, Array.prototype.slice.call(e, 1)));
  }
  !function (t) {
    t[t.top = 0] = "top", t[t.right = 1] = "right", t[t.bottom = 2] = "bottom", t[t.left = 3] = "left";
  }(T || (T = {}));
  class ub {
    constructor(t) {
      this.axis = t;
    }
    isOuterAxis() {
      let t = this.axis,
        e = t.chart,
        i = t.grid.columnIndex,
        s = t.linkedParent?.grid.columns || t.grid.columns || [],
        o = i ? t.linkedParent : t,
        r = -1,
        n = 0;
      return 3 === t.side && !e.inverted && s.length ? !t.linkedParent : ((e[t.coll] || []).forEach((e, i) => {
        e.side !== t.side || e.options.isInternal || (n = i, e !== o || (r = i));
      }), n === r && (!p9(i) || s.length === i));
    }
    renderBorder(t) {
      let e = this.axis,
        i = e.chart.renderer,
        s = e.options,
        o = i.path(t).addClass("highcharts-axis-line").add(e.axisGroup);
      return i.styledMode || o.attr({
        stroke: s.lineColor,
        "stroke-width": s.lineWidth,
        zIndex: 7
      }), o;
    }
  }
  p0.E = function (t) {
    return this.dateFormat("%a", t, !0).charAt(0);
  }, p0.W = function (t) {
    let e = this.toParts(t),
      i = (e[7] + 6) % 7,
      s = e.slice(0);
    s[2] = e[2] - i + 3;
    let o = this.toParts(this.makeTime(s[0], 0, 1));
    return 4 !== o[7] && (e[1] = 0, e[2] = 1 + (11 - o[7]) % 7), (1 + Math.floor((this.makeTime(s[0], s[1], s[2]) - this.makeTime(o[0], o[1], o[2])) / 6048e5)).toString();
  };
  let uv = {
      compose: function (t, e, i) {
        return t.keepProps.includes("grid") || (t.keepProps.push("grid"), t.prototype.getMaxLabelDimensions = ui, ut(t.prototype, "unsquish", uy), ut(t.prototype, "getOffset", us), p1(t, "init", ug), p1(t, "afterGetTitlePosition", uo), p1(t, "afterInit", ur), p1(t, "afterRender", un), p1(t, "afterSetAxisTranslation", ua), p1(t, "afterSetOptions", uh), p1(t, "afterSetOptions", ul), p1(t, "afterSetScale", ud), p1(t, "afterTickSize", uc), p1(t, "trimTicks", ux), p1(t, "destroy", uu), p1(e, "afterSetChartSize", up), p1(i, "afterGetLabelPosition", uf), p1(i, "labelFormat", um)), t;
      }
    },
    {
      extend: uM,
      isNumber: uk,
      pick: uw
    } = tt;
  function uS(t, e, i, s, o, r) {
    let n = r && r.after,
      a = r && r.before,
      h = {
        data: s,
        depth: i - 1,
        id: t,
        level: i,
        parent: e || ""
      },
      l = 0,
      d = 0,
      c,
      p;
    "function" == typeof a && a(h, r);
    let u = (o[t] || []).map(e => {
      let s = uS(e.id, t, i + 1, e, o, r),
        n = e.start || NaN,
        a = !0 === e.milestone ? n : e.end || NaN;
      return c = !uk(c) || n < c ? n : c, p = !uk(p) || a > p ? a : p, l = l + 1 + s.descendants, d = Math.max(s.height + 1, d), s;
    });
    return s && (s.start = uw(s.start, c), s.end = uw(s.end, p)), uM(h, {
      children: u,
      descendants: l,
      height: d
    }), "function" == typeof n && n(h, r), h;
  }
  let uA = {
      getNode: uS,
      getTree: function (t, e) {
        return uS("", null, 1, null, function (t) {
          let e = [],
            i = t.reduce((t, i) => {
              let {
                parent: s = "",
                id: o
              } = i;
              return void 0 === t[s] && (t[s] = []), t[s].push(i), o && e.push(o), t;
            }, {});
          return Object.keys(i).forEach(t => {
            if ("" !== t && -1 === e.indexOf(t)) {
              let e = i[t].map(function (t) {
                let {
                  ...e
                } = t;
                return e;
              });
              i[""].push(...e), delete i[t];
            }
          }), i;
        }(t), e);
      }
    },
    {
      addEvent: uT,
      removeEvent: uP,
      isObject: uC,
      isNumber: uO,
      pick: uE,
      wrap: uL
    } = tt;
  function uB() {
    this.treeGrid || (this.treeGrid = new uR(this));
  }
  function uI(t, e, i, s, o, r, n, a, h) {
    let l, d, c;
    let p = uE(this.options && this.options.labels, r),
      u = this.pos,
      g = this.axis,
      f = "treegrid" === g.type,
      m = t.apply(this, [e, i, s, o, p, n, a, h]);
    if (f) {
      let {
          width: t = 0,
          padding: e = g.linkedParent ? 0 : 5
        } = p && uC(p.symbol, !0) ? p.symbol : {},
        i = p && uO(p.indentation) ? p.indentation : 0;
      c = (d = (l = g.treeGrid.mapOfPosToGridNode) && l[u]) && d.depth || 1, m.x += t + 2 * e + (c - 1) * i;
    }
    return m;
  }
  function uD(t) {
    let e, i, s;
    let {
        pos: o,
        axis: r,
        label: n,
        treeGrid: a,
        options: h
      } = this,
      l = a?.labelIcon,
      d = n?.element,
      {
        treeGrid: c,
        options: p,
        chart: u,
        tickPositions: g
      } = r,
      f = c.mapOfPosToGridNode,
      m = uE(h?.labels, p?.labels),
      x = m && uC(m.symbol, !0) ? m.symbol : {},
      y = f && f[o],
      {
        descendants: b,
        depth: v
      } = y || {},
      M = y && b && b > 0,
      k = "treegrid" === r.type && d,
      w = g.indexOf(o) > -1,
      S = "highcharts-treegrid-node-",
      A = S + "level-",
      T = u.styledMode;
    k && y && n.removeClass(RegExp(A + ".*")).addClass(A + v), t.apply(this, Array.prototype.slice.call(arguments, 1)), k && M ? (e = c.isCollapsed(y), function (t, e) {
      let i = t.treeGrid,
        s = !i.labelIcon,
        o = e.renderer,
        r = e.xy,
        n = e.options,
        a = n.width || 0,
        h = n.height || 0,
        l = n.padding ?? t.axis.linkedParent ? 0 : 5,
        d = {
          x: r.x - a / 2 - l,
          y: r.y - h / 2
        },
        c = e.collapsed ? 90 : 180,
        p = e.show && uO(d.y),
        u = i.labelIcon;
      u || (i.labelIcon = u = o.path(o.symbols[n.type](n.x || 0, n.y || 0, a, h)).addClass("highcharts-label-icon").add(e.group)), u[p ? "show" : "hide"](), o.styledMode || u.attr({
        cursor: "pointer",
        fill: uE(e.color, "#666666"),
        "stroke-width": 1,
        stroke: n.lineColor,
        strokeWidth: n.lineWidth || 0
      }), u[s ? "attr" : "animate"]({
        translateX: d.x,
        translateY: d.y,
        rotation: c
      });
    }(this, {
      color: !T && n.styles.color || "",
      collapsed: e,
      group: n.parentGroup,
      options: x,
      renderer: n.renderer,
      show: w,
      xy: n.xy
    }), i = S + (e ? "collapsed" : "expanded"), s = S + (e ? "expanded" : "collapsed"), n.addClass(i).removeClass(s), T || n.css({
      cursor: "pointer"
    }), [n, l].forEach(t => {
      t && !t.attachedTreeGridEvents && (uT(t.element, "mouseover", function () {
        n.addClass("highcharts-treegrid-node-active"), n.renderer.styledMode || n.css({
          textDecoration: "underline"
        });
      }), uT(t.element, "mouseout", function () {
        !function (t, e) {
          let i = uC(e.style) ? e.style : {};
          t.removeClass("highcharts-treegrid-node-active"), t.renderer.styledMode || t.css({
            textDecoration: i.textDecoration || "none"
          });
        }(n, m);
      }), uT(t.element, "click", function () {
        a.toggleCollapse();
      }), t.attachedTreeGridEvents = !0);
    })) : l && (uP(d), n?.css({
      cursor: "default"
    }), l.destroy());
  }
  class uR {
    static compose(t) {
      let e = t.prototype;
      e.toggleCollapse || (uT(t, "init", uB), uL(e, "getLabelPosition", uI), uL(e, "renderLabel", uD), e.collapse = function (t) {
        this.treeGrid.collapse(t);
      }, e.expand = function (t) {
        this.treeGrid.expand(t);
      }, e.toggleCollapse = function (t) {
        this.treeGrid.toggleCollapse(t);
      });
    }
    constructor(t) {
      this.tick = t;
    }
    collapse(t) {
      let e = this.tick,
        i = e.axis,
        s = i.brokenAxis;
      if (s && i.treeGrid.mapOfPosToGridNode) {
        let o = e.pos,
          r = i.treeGrid.mapOfPosToGridNode[o],
          n = i.treeGrid.collapse(r);
        s.setBreaks(n, uE(t, !0));
      }
    }
    destroy() {
      this.labelIcon && this.labelIcon.destroy();
    }
    expand(t) {
      let {
          pos: e,
          axis: i
        } = this.tick,
        {
          treeGrid: s,
          brokenAxis: o
        } = i,
        r = s.mapOfPosToGridNode;
      if (o && r) {
        let i = r[e],
          n = s.expand(i);
        o.setBreaks(n, uE(t, !0));
      }
    }
    toggleCollapse(t) {
      let e = this.tick,
        i = e.axis,
        s = i.brokenAxis;
      if (s && i.treeGrid.mapOfPosToGridNode) {
        let o = e.pos,
          r = i.treeGrid.mapOfPosToGridNode[o],
          n = i.treeGrid.toggleCollapse(r);
        s.setBreaks(n, uE(t, !0));
      }
    }
  }
  let {
      extend: uN,
      isArray: uz,
      isNumber: uW,
      isObject: uG,
      merge: uH,
      pick: uF,
      relativeLength: uX
    } = tt,
    {
      getLevelOptions: uY
    } = {
      getColor: function (t, e) {
        let i, s, o, r, n, a;
        let h = e.index,
          l = e.mapOptionsToLevel,
          d = e.parentColor,
          c = e.parentColorIndex,
          p = e.series,
          u = e.colors,
          g = e.siblings,
          f = p.points,
          m = p.chart.options.chart;
        return t && (i = f[t.i], s = l[t.level] || {}, i && s.colorByPoint && (r = i.index % (u ? u.length : m.colorCount), o = u && u[r]), p.chart.styledMode || (n = uF(i && i.options.color, s && s.color, o, d && (t => {
          let e = s && s.colorVariation;
          return e && "brightness" === e.key && h && g ? tO.parse(t).brighten(e.to * (h / g)).get() : t;
        })(d), p.color)), a = uF(i && i.options.colorIndex, s && s.colorIndex, r, c, e.colorIndex)), {
          color: n,
          colorIndex: a
        };
      },
      getLevelOptions: function (t) {
        let e, i, s, o, r, n;
        let a = {};
        if (uG(t)) for (o = uW(t.from) ? t.from : 1, n = t.levels, i = {}, e = uG(t.defaults) ? t.defaults : {}, uz(n) && (i = n.reduce((t, i) => {
          let s, r, n;
          return uG(i) && uW(i.level) && (r = uF((n = uH({}, i)).levelIsConstant, e.levelIsConstant), delete n.levelIsConstant, delete n.level, uG(t[s = i.level + (r ? 0 : o - 1)]) ? uH(!0, t[s], n) : t[s] = n), t;
        }, {})), r = uW(t.to) ? t.to : 1, s = 0; s <= r; s++) a[s] = uH({}, e, uG(i[s]) ? i[s] : {});
        return a;
      },
      getNodeWidth: function (t, e) {
        let {
            chart: i,
            options: s
          } = t,
          {
            nodeDistance: o = 0,
            nodeWidth: r = 0
          } = s,
          {
            plotSizeX: n = 1
          } = i;
        if ("auto" === r) {
          if ("string" == typeof o && /%$/.test(o)) return n / (e + parseFloat(o) / 100 * (e - 1));
          let t = Number(o);
          return (n + t) / (e || 1) - t;
        }
        return uX(r, n);
      },
      setTreeValues: function t(e, i) {
        let s = i.before,
          o = i.idRoot,
          r = i.mapIdToNode[o],
          n = !1 !== i.levelIsConstant,
          a = i.points[e.i],
          h = a && a.options || {},
          l = [],
          d = 0;
        e.levelDynamic = e.level - (n ? 0 : r.level), e.name = uF(a && a.name, ""), e.visible = o === e.id || !0 === i.visible, "function" == typeof s && (e = s(e, i)), e.children.forEach((s, o) => {
          let r = uN({}, i);
          uN(r, {
            index: o,
            siblings: e.children.length,
            visible: e.visible
          }), s = t(s, r), l.push(s), s.visible && (d += s.val);
        });
        let c = uF(h.value, d);
        return e.visible = c >= 0 && (d > 0 || e.visible), e.children = l, e.childrenTotal = d, e.isLeaf = e.visible && !d, e.val = c, e;
      },
      updateRootId: function (t) {
        let e, i;
        return uG(t) && (i = uG(t.options) ? t.options : {}, e = uF(t.rootNode, i.rootId, ""), uG(t.userOptions) && (t.userOptions.rootId = e), t.rootNode = e), e;
      }
    },
    {
      addEvent: uj,
      isArray: uU,
      splat: uV,
      find: u$,
      fireEvent: u_,
      isObject: uq,
      isString: uZ,
      merge: uK,
      pick: uJ,
      removeEvent: uQ,
      wrap: u0
    } = tt;
  function u1(t, e) {
    let i = t.collapseEnd || 0,
      s = t.collapseStart || 0;
    return i >= e && (s -= .5), {
      from: s,
      to: i,
      showPoints: !1
    };
  }
  function u2(t, e, i) {
    let s = [],
      o = [],
      r = {},
      n = e || !1,
      a = {},
      h = -1,
      l = uA.getTree(t, {
        after: function (t) {
          let e = a[t.pos],
            i = 0,
            s = 0;
          e.children.forEach(function (t) {
            s += (t.descendants || 0) + 1, i = Math.max((t.height || 0) + 1, i);
          }), e.descendants = s, e.height = i, e.collapsed && o.push(e);
        },
        before: function (t) {
          let e, i;
          let o = uq(t.data, !0) ? t.data : {},
            l = uZ(o.name) ? o.name : "",
            d = r[t.parent],
            c = uq(d, !0) ? a[d.pos] : null;
          n && uq(c, !0) && (e = u$(c.children, function (t) {
            return t.name === l;
          })) ? (i = e.pos, e.nodes.push(t)) : i = h++, !a[i] && (a[i] = e = {
            depth: c ? c.depth + 1 : 0,
            name: l,
            id: o.id,
            nodes: [t],
            children: [],
            pos: i
          }, -1 !== i && s.push(l), uq(c, !0) && c.children.push(e)), uZ(t.id) && (r[t.id] = t), e && !0 === o.collapsed && (e.collapsed = !0), t.pos = i;
        }
      });
    return {
      categories: s,
      mapOfIdToNode: r,
      mapOfPosToGridNode: a = function (t, e) {
        let i = function (t, s, o) {
          let r = t.nodes,
            n = s + (-1 === s ? 0 : e - 1),
            a = (n - s) / 2,
            h = s + a;
          return r.forEach(function (t) {
            let e = t.data;
            uq(e, !0) && (e.y = s + (e.seriesIndex || 0), delete e.seriesIndex), t.pos = h;
          }), o[h] = t, t.pos = h, t.tickmarkOffset = a + .5, t.collapseStart = n + .5, t.children.forEach(function (t) {
            i(t, n + 1, o), n = (t.collapseEnd || 0) - .5;
          }), t.collapseEnd = n + .5, o;
        };
        return i(t["-1"], -1, {});
      }(a, i),
      collapsedNodes: o,
      tree: l
    };
  }
  function u3(t) {
    let e = t.target;
    e.axes.filter(t => "treegrid" === t.type).forEach(function (i) {
      let s = i.options || {},
        o = s.labels,
        r = i.uniqueNames,
        n = e.time.parse(s.max),
        a = !i.treeGrid.mapOfPosToGridNode || i.series.some(function (t) {
          return !t.hasRendered || t.isDirtyData || t.isDirty;
        }),
        h = 0,
        l,
        d;
      if (a) {
        let s = [];
        if (l = i.series.reduce(function (t, i) {
          let o = i.options.data || [],
            n = o[0],
            a = Array.isArray(n) && !n.find(t => "object" == typeof t);
          return s.push(a), i.visible && (o.forEach(function (s) {
            (a || i.options.keys && i.options.keys.length) && (s = i.pointClass.prototype.optionsToObject.call({
              series: i
            }, s), i.pointClass.setGanttPointAliases(s, e)), uq(s, !0) && (s.seriesIndex = h, t.push(s));
          }), !0 === r && h++), t;
        }, []), n && l.length < n) for (let t = l.length; t <= n; t++) l.push({
          name: t + "​"
        });
        d = u2(l, r || !1, !0 === r ? h : 1), i.categories = d.categories, i.treeGrid.mapOfPosToGridNode = d.mapOfPosToGridNode, i.hasNames = !0, i.treeGrid.tree = d.tree, i.series.forEach(function (t, e) {
          let i = (t.options.data || []).map(function (i) {
            return (s[e] || uU(i) && t.options.keys && t.options.keys.length) && l.forEach(function (t) {
              let e = uV(i);
              e.indexOf(t.x || 0) >= 0 && e.indexOf(t.x2 || 0) >= 0 && (i = t);
            }), uq(i, !0) ? uK(i) : i;
          });
          t.visible && t.setData(i, !1);
        }), i.treeGrid.mapOptionsToLevel = uY({
          defaults: o,
          from: 1,
          levels: o && o.levels,
          to: i.treeGrid.tree && i.treeGrid.tree.height
        }), "beforeRender" === t.type && (i.treeGrid.collapsedNodes = d.collapsedNodes);
      }
    });
  }
  function u5(t, e) {
    let i = this.treeGrid.mapOptionsToLevel || {},
      s = "treegrid" === this.type,
      r = this.ticks,
      n = r[e],
      a,
      h,
      l;
    s && this.treeGrid.mapOfPosToGridNode ? ((a = i[(l = this.treeGrid.mapOfPosToGridNode[e]).depth]) && (h = {
      labels: a
    }), !n && o ? r[e] = n = new o(this, e, void 0, void 0, {
      category: l.name,
      tickmarkOffset: l.tickmarkOffset,
      options: h
    }) : (n.parameters.category = l.name, n.options = h, n.addLabel())) : t.apply(this, Array.prototype.slice.call(arguments, 1));
  }
  function u6(t, e, i, s) {
    let o = this,
      r = "treegrid" === i.type;
    o.treeGrid || (o.treeGrid = new u8(o)), r && (uj(e, "beforeRender", u3), uj(e, "beforeRedraw", u3), uj(e, "addSeries", function (t) {
      if (t.options.data) {
        let e = u2(t.options.data, i.uniqueNames || !1, 1);
        o.treeGrid.collapsedNodes = (o.treeGrid.collapsedNodes || []).concat(e.collapsedNodes);
      }
    }), uj(o, "foundExtremes", function () {
      o.treeGrid.collapsedNodes && o.treeGrid.collapsedNodes.forEach(function (t) {
        let e = o.treeGrid.collapse(t);
        o.brokenAxis && (o.brokenAxis.setBreaks(e, !1), o.treeGrid.collapsedNodes && (o.treeGrid.collapsedNodes = o.treeGrid.collapsedNodes.filter(e => t.collapseStart !== e.collapseStart || t.collapseEnd !== e.collapseEnd)));
      });
    }), uj(o, "afterBreaks", function () {
      "yAxis" === o.coll && !o.staticScale && o.chart.options.chart.height && (o.isDirty = !0);
    }), i = uK({
      grid: {
        enabled: !0
      },
      labels: {
        align: "left",
        levels: [{
          level: void 0
        }, {
          level: 1,
          style: {
            fontWeight: "bold"
          }
        }],
        symbol: {
          type: "triangle",
          x: -5,
          y: -5,
          height: 10,
          width: 10
        }
      },
      uniqueNames: !1
    }, i, {
      reversed: !0
    })), t.apply(o, [e, i, s]), r && (o.hasNames = !0, o.options.showLastLabel = !0);
  }
  function u9(t) {
    let e = this.options,
      i = this.chart.time,
      s = "number" == typeof e.linkedTo ? this.chart[this.coll]?.[e.linkedTo] : void 0;
    if ("treegrid" === this.type) {
      if (this.min = this.userMin ?? i.parse(e.min) ?? this.dataMin, this.max = this.userMax ?? i.parse(e.max) ?? this.dataMax, u_(this, "foundExtremes"), this.setAxisTranslation(), this.tickInterval = 1, this.tickmarkOffset = .5, this.tickPositions = this.treeGrid.mapOfPosToGridNode ? this.treeGrid.getTickPositions() : [], s) {
        let t = s.getExtremes();
        this.min = uJ(t.min, t.dataMin), this.max = uJ(t.max, t.dataMax), this.tickPositions = s.tickPositions;
      }
      this.linkedParent = s;
    } else t.apply(this, Array.prototype.slice.call(arguments, 1));
  }
  function u4(t) {
    let e = this;
    "treegrid" === this.type && e.visible && e.tickPositions.forEach(function (t) {
      let i = e.ticks[t];
      i.label && i.label.attachedTreeGridEvents && (uQ(i.label.element), i.label.attachedTreeGridEvents = !1);
    }), t.apply(e, Array.prototype.slice.call(arguments, 1));
  }
  class u8 {
    static compose(t, e, i, s) {
      if (!t.keepProps.includes("treeGrid")) {
        let e = t.prototype;
        t.keepProps.push("treeGrid"), u0(e, "generateTick", u5), u0(e, "init", u6), u0(e, "setTickInterval", u9), u0(e, "redraw", u4), e.utils = {
          getNode: uA.getNode
        }, o || (o = s);
      }
      return uv.compose(t, e, s), pQ.compose(t, i), uR.compose(s), t;
    }
    constructor(t) {
      this.axis = t;
    }
    setCollapsedStatus(t) {
      let e = this.axis,
        i = e.chart;
      e.series.forEach(function (e) {
        let s = e.options.data;
        if (t.id && s) {
          let o = i.get(t.id),
            r = s[e.data.indexOf(o)];
          o && r && (o.collapsed = t.collapsed, r.collapsed = t.collapsed);
        }
      });
    }
    collapse(t) {
      let e = this.axis,
        i = e.options.breaks || [],
        s = u1(t, e.max);
      return i.push(s), t.collapsed = !0, e.treeGrid.setCollapsedStatus(t), i;
    }
    expand(t) {
      let e = this.axis,
        i = e.options.breaks || [],
        s = u1(t, e.max);
      return t.collapsed = !1, e.treeGrid.setCollapsedStatus(t), i.reduce(function (t, e) {
        return (e.to !== s.to || e.from !== s.from) && t.push(e), t;
      }, []);
    }
    getTickPositions() {
      let t = this.axis,
        e = Math.floor(t.min / t.tickInterval) * t.tickInterval,
        i = Math.ceil(t.max / t.tickInterval) * t.tickInterval;
      return Object.keys(t.treeGrid.mapOfPosToGridNode || {}).reduce(function (s, o) {
        let r = +o;
        return r >= e && r <= i && !(t.brokenAxis && t.brokenAxis.isInAnyBreak(r)) && s.push(r), s;
      }, []);
    }
    isCollapsed(t) {
      let e = this.axis,
        i = e.options.breaks || [],
        s = u1(t, e.max);
      return i.some(function (t) {
        return t.from === s.from && t.to === s.to;
      });
    }
    toggleCollapse(t) {
      return this.isCollapsed(t) ? this.expand(t) : this.collapse(t);
    }
  }
  let {
      series: u7,
      seriesTypes: {
        xrange: gt
      }
    } = ry,
    {
      extend: ge,
      isNumber: gi,
      merge: gs
    } = tt;
  class go extends gt {
    static compose(t, e, i, s) {
      gt.compose(t), e && (pb.compose(t, e), i) && (pp.compose(e, i.prototype.pointClass), s && u8.compose(t, e, i, s));
    }
    drawPoint(t, e) {
      let i = this.options,
        s = this.chart.renderer,
        o = t.shapeArgs,
        r = t.plotY,
        n = t.selected && "select",
        a = i.stacking && !i.borderRadius,
        h = t.graphic,
        l;
      t.options.milestone ? gi(r) && null !== t.y && !1 !== t.visible ? (l = s.symbols.diamond(o.x || 0, o.y || 0, o.width || 0, o.height || 0), h ? h[e]({
        d: l
      }) : t.graphic = h = s.path(l).addClass(t.getClassName(), !0).add(t.group || this.group), this.chart.styledMode || t.graphic.attr(this.pointAttribs(t, n)).shadow(i.shadow, null, a)) : h && (t.graphic = h.destroy()) : super.drawPoint(t, e);
    }
    translatePoint(t) {
      let e, i;
      super.translatePoint(t), t.options.milestone && (i = (e = t.shapeArgs).height || 0, t.shapeArgs = {
        x: (e.x || 0) - i / 2,
        y: e.y,
        width: i,
        height: i
      });
    }
  }
  go.defaultOptions = gs(gt.defaultOptions, {
    grouping: !1,
    dataLabels: {
      enabled: !0
    },
    tooltip: {
      headerFormat: '<span style="font-size: 0.8em">{series.name}</span><br/>',
      pointFormat: null,
      pointFormatter: function () {
        let t = this.series,
          e = t.xAxis,
          i = t.tooltipOptions.dateTimeLabelFormats,
          s = e.options.startOfWeek,
          o = t.tooltipOptions,
          r = this.options.milestone,
          n = o.xDateFormat,
          a = "<b>" + (this.name || this.yCategory) + "</b>";
        if (o.pointFormat) return this.tooltipFormatter(o.pointFormat);
        !n && pV(this.start) && (n = t.chart.time.getDateFormat(e.closestPointRange, this.start, s, i || {}));
        let h = t.chart.time.dateFormat(n, this.start),
          l = t.chart.time.dateFormat(n, this.end);
        return a += "<br/>", r ? a += h + "<br/>" : a += "Start: " + h + "<br/>End: " + l + "<br/>", a;
      }
    },
    connectors: {
      type: "simpleConnect",
      animation: {
        reversed: !0
      },
      radius: 0,
      startMarker: {
        enabled: !0,
        symbol: "arrow-filled",
        radius: 4,
        fill: "#fa0",
        align: "left"
      },
      endMarker: {
        enabled: !1,
        align: "right"
      }
    }
  }), ge(go.prototype, {
    pointArrayMap: ["start", "end", "y"],
    pointClass: pU,
    setData: u7.prototype.setData
  }), ry.registerSeriesType("gantt", go), O.Connection = O.Connection || lH, O.GanttChart = O.GanttChart || l1, O.Navigator = O.Navigator || cs, O.RangeSelector = O.RangeSelector || cK, O.Scrollbar = O.Scrollbar || d$, O.ganttChart = O.GanttChart.ganttChart, lB.compose(O.SVGRenderer), {
    compose: function (t, e) {
      lj(lF, "CurrentDateIndication") && (lX(t, "afterSetOptions", l$), lX(e, "render", l_), lU(e.prototype, "getLabelText", lq));
    }
  }.compose(O.Axis, O.PlotLineOrBand), go.compose(O.Axis, O.Chart, O.Series, O.Tick), O.Navigator.compose(O.Chart, O.Axis, O.Series), O.RangeSelector.compose(O.Axis, O.Chart), O.Scrollbar.compose(O.Axis),
  /**
  * @license Highcharts Gantt JS v12.1.2 (2024-12-21)
  * @module highcharts/highcharts-gantt
  *
  * (c) 2017-2024 Lars Cabrera, Torstein Honsi, Jon Arild Nygard & Oystein Moseng
  *
  * License: www.highcharts.com/license
  */
  O.product = "Highcharts Gantt";
  let gr = O;
  return C.default;
})());