!
/**
* Highcharts JS v12.1.2 (2024-12-21)
* @module highcharts/highcharts
*
* (c) 2009-2024 Torstein Honsi
*
* License: www.highcharts.com/license
*/
function (t, e) {
  "object" == typeof exports && "object" == typeof module ? (t._Highcharts = e(), module.exports = t._Highcharts) : "function" == typeof define && define.amd ? define("highcharts/highcharts", [], e) : "object" == typeof exports ? (t._Highcharts = e(), exports.highcharts = t._Highcharts) : (t.Highcharts && t.Highcharts.error(16, !0), t.Highcharts = e());
}("undefined" == typeof window ? this : window, () => (() => {
  "use strict";

  let t, e, i;
  var s,
    o,
    r,
    a,
    n,
    l,
    h,
    d,
    c,
    p,
    u,
    g,
    m,
    f,
    x,
    y,
    b,
    v,
    M,
    w,
    k,
    S,
    T = {};
  T.d = (t, e) => {
    for (var i in e) T.o(e, i) && !T.o(t, i) && Object.defineProperty(t, i, {
      enumerable: !0,
      get: e[i]
    });
  }, T.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e);
  var C = {};
  T.d(C, {
    default: () => p8
  }), function (t) {
    t.SVG_NS = "http://www.w3.org/2000/svg", t.product = "Highcharts", t.version = "12.1.2", t.win = "undefined" != typeof window ? window : {}, t.doc = t.win.document, t.svg = t.doc && t.doc.createElementNS && !!t.doc.createElementNS(t.SVG_NS, "svg").createSVGRect, t.pageLang = t.doc?.documentElement?.closest("[lang]")?.lang, t.userAgent = t.win.navigator && t.win.navigator.userAgent || "", t.isChrome = t.win.chrome, t.isFirefox = -1 !== t.userAgent.indexOf("Firefox"), t.isMS = /(edge|msie|trident)/i.test(t.userAgent) && !t.win.opera, t.isSafari = !t.isChrome && -1 !== t.userAgent.indexOf("Safari"), t.isTouchDevice = /(Mobile|Android|Windows Phone)/.test(t.userAgent), t.isWebKit = -1 !== t.userAgent.indexOf("AppleWebKit"), t.deg2rad = 2 * Math.PI / 360, t.marginNames = ["plotTop", "marginRight", "marginBottom", "plotLeft"], t.noop = function () {}, t.supportsPassiveEvents = function () {
      let e = !1;
      if (!t.isMS) {
        let i = Object.defineProperty({}, "passive", {
          get: function () {
            e = !0;
          }
        });
        t.win.addEventListener && t.win.removeEventListener && (t.win.addEventListener("testPassive", t.noop, i), t.win.removeEventListener("testPassive", t.noop, i));
      }
      return e;
    }(), t.charts = [], t.composed = [], t.dateFormats = {}, t.seriesTypes = {}, t.symbolSizes = {}, t.chartCount = 0;
  }(s || (s = {}));
  let A = s,
    {
      charts: P,
      doc: L,
      win: O
    } = A;
  function E(t, e, i, s) {
    let o = e ? "Highcharts error" : "Highcharts warning";
    32 === t && (t = `${o}: Deprecated member`);
    let r = W(t),
      a = r ? `${o} #${t}: www.highcharts.com/errors/${t}/` : t.toString();
    if (void 0 !== s) {
      let t = "";
      r && (a += "?"), $(s, function (e, i) {
        t += `
 - ${i}: ${e}`, r && (a += encodeURI(i) + "=" + encodeURI(e));
      }), a += t;
    }
    q(A, "displayError", {
      chart: i,
      code: t,
      message: a,
      params: s
    }, function () {
      if (e) throw Error(a);
      O.console && -1 === E.messages.indexOf(a) && console.warn(a);
    }), E.messages.push(a);
  }
  function I(t, e) {
    return parseInt(t, e || 10);
  }
  function D(t) {
    return "string" == typeof t;
  }
  function B(t) {
    let e = Object.prototype.toString.call(t);
    return "[object Array]" === e || "[object Array Iterator]" === e;
  }
  function z(t, e) {
    return !!t && "object" == typeof t && (!e || !B(t));
  }
  function N(t) {
    return z(t) && "number" == typeof t.nodeType;
  }
  function R(t) {
    let e = t && t.constructor;
    return !!(z(t, !0) && !N(t) && e && e.name && "Object" !== e.name);
  }
  function W(t) {
    return "number" == typeof t && !isNaN(t) && t < 1 / 0 && t > -1 / 0;
  }
  function j(t) {
    return null != t;
  }
  function X(t, e, i) {
    let s;
    let o = D(e) && !j(i),
      r = (e, i) => {
        j(e) ? t.setAttribute(i, e) : o ? (s = t.getAttribute(i)) || "class" !== i || (s = t.getAttribute(i + "Name")) : t.removeAttribute(i);
      };
    return D(e) ? r(i, e) : $(e, r), s;
  }
  function G(t) {
    return B(t) ? t : [t];
  }
  function F(t, e) {
    let i;
    for (i in t || (t = {}), e) t[i] = e[i];
    return t;
  }
  function H() {
    let t = arguments,
      e = t.length;
    for (let i = 0; i < e; i++) {
      let e = t[i];
      if (null != e) return e;
    }
  }
  function Y(t, e) {
    F(t.style, e);
  }
  function V(t) {
    return Math.pow(10, Math.floor(Math.log(t) / Math.LN10));
  }
  function U(t, e) {
    return t > 1e14 ? t : parseFloat(t.toPrecision(e || 14));
  }
  (E || (E = {})).messages = [], Math.easeInOutSine = function (t) {
    return -.5 * (Math.cos(Math.PI * t) - 1);
  };
  let Z = Array.prototype.find ? function (t, e) {
    return t.find(e);
  } : function (t, e) {
    let i;
    let s = t.length;
    for (i = 0; i < s; i++) if (e(t[i], i)) return t[i];
  };
  function $(t, e, i) {
    for (let s in t) Object.hasOwnProperty.call(t, s) && e.call(i || t[s], t[s], s, t);
  }
  function _(t, e, i) {
    function s(e, i) {
      let s = t.removeEventListener;
      s && s.call(t, e, i, !1);
    }
    function o(i) {
      let o, r;
      t.nodeName && (e ? (o = {})[e] = !0 : o = i, $(o, function (t, e) {
        if (i[e]) for (r = i[e].length; r--;) s(e, i[e][r].fn);
      }));
    }
    let r = "function" == typeof t && t.prototype || t;
    if (Object.hasOwnProperty.call(r, "hcEvents")) {
      let t = r.hcEvents;
      if (e) {
        let r = t[e] || [];
        i ? (t[e] = r.filter(function (t) {
          return i !== t.fn;
        }), s(e, i)) : (o(t), t[e] = []);
      } else o(t), delete r.hcEvents;
    }
  }
  function q(t, e, i, s) {
    if (i = i || {}, L.createEvent && (t.dispatchEvent || t.fireEvent && t !== A)) {
      let s = L.createEvent("Events");
      s.initEvent(e, !0, !0), i = F(s, i), t.dispatchEvent ? t.dispatchEvent(i) : t.fireEvent(e, i);
    } else if (t.hcEvents) {
      i.target || F(i, {
        preventDefault: function () {
          i.defaultPrevented = !0;
        },
        target: t,
        type: e
      });
      let s = [],
        o = t,
        r = !1;
      for (; o.hcEvents;) Object.hasOwnProperty.call(o, "hcEvents") && o.hcEvents[e] && (s.length && (r = !0), s.unshift.apply(s, o.hcEvents[e])), o = Object.getPrototypeOf(o);
      r && s.sort((t, e) => t.order - e.order), s.forEach(e => {
        !1 === e.fn.call(t, i) && i.preventDefault();
      });
    }
    s && !i.defaultPrevented && s.call(t, i);
  }
  let K = function () {
    let e = Math.random().toString(36).substring(2, 9) + "-",
      i = 0;
    return function () {
      return "highcharts-" + (t ? "" : e) + i++;
    };
  }();
  O.jQuery && (O.jQuery.fn.highcharts = function () {
    let t = [].slice.call(arguments);
    if (this[0]) return t[0] ? (new A[D(t[0]) ? t.shift() : "Chart"](this[0], t[0], t[1]), this) : P[X(this[0], "data-highcharts-chart")];
  });
  let J = {
      addEvent: function (t, e, i, s = {}) {
        let o = "function" == typeof t && t.prototype || t;
        Object.hasOwnProperty.call(o, "hcEvents") || (o.hcEvents = {});
        let r = o.hcEvents;
        A.Point && t instanceof A.Point && t.series && t.series.chart && (t.series.chart.runTrackerClick = !0);
        let a = t.addEventListener;
        a && a.call(t, e, i, !!A.supportsPassiveEvents && {
          passive: void 0 === s.passive ? -1 !== e.indexOf("touch") : s.passive,
          capture: !1
        }), r[e] || (r[e] = []);
        let n = {
          fn: i,
          order: "number" == typeof s.order ? s.order : 1 / 0
        };
        return r[e].push(n), r[e].sort((t, e) => t.order - e.order), function () {
          _(t, e, i);
        };
      },
      arrayMax: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] > i && (i = t[e]);
        return i;
      },
      arrayMin: function (t) {
        let e = t.length,
          i = t[0];
        for (; e--;) t[e] < i && (i = t[e]);
        return i;
      },
      attr: X,
      clamp: function (t, e, i) {
        return t > e ? t < i ? t : i : e;
      },
      clearTimeout: function (t) {
        j(t) && clearTimeout(t);
      },
      correctFloat: U,
      createElement: function (t, e, i, s, o) {
        let r = L.createElement(t);
        return e && F(r, e), o && Y(r, {
          padding: "0",
          border: "none",
          margin: "0"
        }), i && Y(r, i), s && s.appendChild(r), r;
      },
      crisp: function (t, e = 0, i) {
        let s = e % 2 / 2,
          o = i ? -1 : 1;
        return (Math.round(t * o - s) + s) * o;
      },
      css: Y,
      defined: j,
      destroyObjectProperties: function (t, e, i) {
        $(t, function (s, o) {
          s !== e && s?.destroy && s.destroy(), (s?.destroy || !i) && delete t[o];
        });
      },
      diffObjects: function (t, e, i, s) {
        let o = {};
        return !function t(e, o, r, a) {
          let n = i ? o : e;
          $(e, function (i, l) {
            if (!a && s && s.indexOf(l) > -1 && o[l]) {
              i = G(i), r[l] = [];
              for (let e = 0; e < Math.max(i.length, o[l].length); e++) o[l][e] && (void 0 === i[e] ? r[l][e] = o[l][e] : (r[l][e] = {}, t(i[e], o[l][e], r[l][e], a + 1)));
            } else z(i, !0) && !i.nodeType ? (r[l] = B(i) ? [] : {}, t(i, o[l] || {}, r[l], a + 1), 0 !== Object.keys(r[l]).length || "colorAxis" === l && 0 === a || delete r[l]) : (e[l] !== o[l] || l in e && !(l in o)) && "__proto__" !== l && "constructor" !== l && (r[l] = n[l]);
          });
        }(t, e, o, 0), o;
      },
      discardElement: function (t) {
        t && t.parentElement && t.parentElement.removeChild(t);
      },
      erase: function (t, e) {
        let i = t.length;
        for (; i--;) if (t[i] === e) {
          t.splice(i, 1);
          break;
        }
      },
      error: E,
      extend: F,
      extendClass: function (t, e) {
        let i = function () {};
        return i.prototype = new t(), F(i.prototype, e), i;
      },
      find: Z,
      fireEvent: q,
      getAlignFactor: (t = "") => ({
        center: .5,
        right: 1,
        middle: .5,
        bottom: 1
      })[t] || 0,
      getClosestDistance: function (t, e) {
        let i, s, o, r;
        let a = !e;
        return t.forEach(t => {
          if (t.length > 1) for (r = s = t.length - 1; r > 0; r--) (o = t[r] - t[r - 1]) < 0 && !a ? (e?.(), e = void 0) : o && (void 0 === i || o < i) && (i = o);
        }), i;
      },
      getMagnitude: V,
      getNestedProperty: function (t, e) {
        let i = t.split(".");
        for (; i.length && j(e);) {
          let t = i.shift();
          if (void 0 === t || "__proto__" === t) return;
          if ("this" === t) {
            let t;
            return z(e) && (t = e["@this"]), t ?? e;
          }
          let s = e[t.replace(/[\\'"]/g, "")];
          if (!j(s) || "function" == typeof s || "number" == typeof s.nodeType || s === O) return;
          e = s;
        }
        return e;
      },
      getStyle: function t(e, i, s) {
        let o;
        if ("width" === i) {
          let i = Math.min(e.offsetWidth, e.scrollWidth),
            s = e.getBoundingClientRect && e.getBoundingClientRect().width;
          return s < i && s >= i - 1 && (i = Math.floor(s)), Math.max(0, i - (t(e, "padding-left", !0) || 0) - (t(e, "padding-right", !0) || 0));
        }
        if ("height" === i) return Math.max(0, Math.min(e.offsetHeight, e.scrollHeight) - (t(e, "padding-top", !0) || 0) - (t(e, "padding-bottom", !0) || 0));
        let r = O.getComputedStyle(e, void 0);
        return r && (o = r.getPropertyValue(i), H(s, "opacity" !== i) && (o = I(o))), o;
      },
      insertItem: function (t, e) {
        let i;
        let s = t.options.index,
          o = e.length;
        for (i = t.options.isInternal ? o : 0; i < o + 1; i++) if (!e[i] || W(s) && s < H(e[i].options.index, e[i]._i) || e[i].options.isInternal) {
          e.splice(i, 0, t);
          break;
        }
        return i;
      },
      isArray: B,
      isClass: R,
      isDOMElement: N,
      isFunction: function (t) {
        return "function" == typeof t;
      },
      isNumber: W,
      isObject: z,
      isString: D,
      merge: function (t, ...e) {
        let i,
          s = [t, ...e],
          o = {},
          r = function (t, e) {
            return "object" != typeof t && (t = {}), $(e, function (i, s) {
              "__proto__" !== s && "constructor" !== s && (!z(i, !0) || R(i) || N(i) ? t[s] = e[s] : t[s] = r(t[s] || {}, i));
            }), t;
          };
        !0 === t && (o = s[1], s = Array.prototype.slice.call(s, 2));
        let a = s.length;
        for (i = 0; i < a; i++) o = r(o, s[i]);
        return o;
      },
      normalizeTickInterval: function (t, e, i, s, o) {
        let r,
          a = t;
        i = H(i, V(t));
        let n = t / i;
        for (!e && (e = o ? [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10] : [1, 2, 2.5, 5, 10], !1 === s && (1 === i ? e = e.filter(function (t) {
          return t % 1 == 0;
        }) : i <= .1 && (e = [1 / i]))), r = 0; r < e.length && (a = e[r], (!o || !(a * i >= t)) && (o || !(n <= (e[r] + (e[r + 1] || e[r])) / 2))); r++);
        return U(a * i, -Math.round(Math.log(.001) / Math.LN10));
      },
      objectEach: $,
      offset: function (t) {
        let e = L.documentElement,
          i = t.parentElement || t.parentNode ? t.getBoundingClientRect() : {
            top: 0,
            left: 0,
            width: 0,
            height: 0
          };
        return {
          top: i.top + (O.pageYOffset || e.scrollTop) - (e.clientTop || 0),
          left: i.left + (O.pageXOffset || e.scrollLeft) - (e.clientLeft || 0),
          width: i.width,
          height: i.height
        };
      },
      pad: function (t, e, i) {
        return Array((e || 2) + 1 - String(t).replace("-", "").length).join(i || "0") + t;
      },
      pick: H,
      pInt: I,
      pushUnique: function (t, e) {
        return 0 > t.indexOf(e) && !!t.push(e);
      },
      relativeLength: function (t, e, i) {
        return /%$/.test(t) ? e * parseFloat(t) / 100 + (i || 0) : parseFloat(t);
      },
      removeEvent: _,
      replaceNested: function (t, ...e) {
        let i, s;
        do for (s of (i = t, e)) t = t.replace(s[0], s[1]); while (t !== i);
        return t;
      },
      splat: G,
      stableSort: function (t, e) {
        let i, s;
        let o = t.length;
        for (s = 0; s < o; s++) t[s].safeI = s;
        for (t.sort(function (t, s) {
          return 0 === (i = e(t, s)) ? t.safeI - s.safeI : i;
        }), s = 0; s < o; s++) delete t[s].safeI;
      },
      syncTimeout: function (t, e, i) {
        return e > 0 ? setTimeout(t, e, i) : (t.call(0, i), -1);
      },
      timeUnits: {
        millisecond: 1,
        second: 1e3,
        minute: 6e4,
        hour: 36e5,
        day: 864e5,
        week: 6048e5,
        month: 24192e5,
        year: 314496e5
      },
      ucfirst: function (t) {
        return D(t) ? t.substring(0, 1).toUpperCase() + t.substring(1) : String(t);
      },
      uniqueKey: K,
      useSerialIds: function (e) {
        return t = H(e, t);
      },
      wrap: function (t, e, i) {
        let s = t[e];
        t[e] = function () {
          let t = arguments,
            e = this;
          return i.apply(this, [function () {
            return s.apply(e, arguments.length ? arguments : t);
          }].concat([].slice.call(arguments)));
        };
      }
    },
    {
      pageLang: Q,
      win: tt
    } = A,
    {
      defined: te,
      error: ti,
      extend: ts,
      isNumber: to,
      isObject: tr,
      isString: ta,
      merge: tn,
      objectEach: tl,
      pad: th,
      splat: td,
      timeUnits: tc,
      ucfirst: tp
    } = J,
    tu = A.isSafari && tt.Intl && !tt.Intl.DateTimeFormat.prototype.formatRange,
    tg = t => void 0 === t.main,
    tm = t => ["D", "L", "M", "X", "J", "V", "S"].indexOf(t),
    tf = class {
      constructor(t) {
        this.options = {
          timezone: "UTC"
        }, this.variableTimezone = !1, this.Date = tt.Date, this.update(t);
      }
      update(t = {}) {
        this.dTLCache = {}, this.options = t = tn(!0, this.options, t);
        let {
          timezoneOffset: e,
          useUTC: i
        } = t;
        this.Date = t.Date || tt.Date || Date;
        let s = t.timezone;
        te(i) && (s = i ? "UTC" : void 0), e && e % 60 == 0 && (s = "Etc/GMT" + (e > 0 ? "+" : "") + e / 60), this.variableTimezone = "UTC" !== s && s?.indexOf("Etc/GMT") !== 0, this.timezone = s, ["months", "shortMonths", "weekdays", "shortWeekdays"].forEach(t => {
          let e = /months/i.test(t),
            i = /short/.test(t),
            s = {
              timeZone: "UTC"
            };
          s[e ? "month" : "weekday"] = i ? "short" : "long", this[t] = (e ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : [3, 4, 5, 6, 7, 8, 9]).map(t => this.dateFormat(s, (e ? 31 : 1) * 24 * 36e5 * t));
        });
      }
      toParts(t) {
        let [e, i, s, o, r, a, n] = this.dateTimeFormat({
          weekday: "narrow",
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        }, t, "es").split(/(?:, |\/|:)/g);
        return [o, +s - 1, i, r, a, n, Math.floor(Number(t) || 0) % 1e3, tm(e)].map(Number);
      }
      dateTimeFormat(t, e, i = this.options.locale || Q) {
        let s = JSON.stringify(t) + i;
        ta(t) && (t = this.str2dtf(t));
        let o = this.dTLCache[s];
        if (!o) {
          t.timeZone ?? (t.timeZone = this.timezone);
          try {
            o = new Intl.DateTimeFormat(i, t);
          } catch (e) {
            /Invalid time zone/i.test(e.message) ? (ti(34), t.timeZone = "UTC", o = new Intl.DateTimeFormat(i, t)) : ti(e.message, !1);
          }
        }
        return this.dTLCache[s] = o, o?.format(e) || "";
      }
      str2dtf(t, e = {}) {
        let i = {
          L: {
            fractionalSecondDigits: 3
          },
          S: {
            second: "2-digit"
          },
          M: {
            minute: "numeric"
          },
          H: {
            hour: "2-digit"
          },
          k: {
            hour: "numeric"
          },
          E: {
            weekday: "narrow"
          },
          a: {
            weekday: "short"
          },
          A: {
            weekday: "long"
          },
          d: {
            day: "2-digit"
          },
          e: {
            day: "numeric"
          },
          b: {
            month: "short"
          },
          B: {
            month: "long"
          },
          m: {
            month: "2-digit"
          },
          o: {
            month: "numeric"
          },
          y: {
            year: "2-digit"
          },
          Y: {
            year: "numeric"
          }
        };
        return Object.keys(i).forEach(s => {
          -1 !== t.indexOf(s) && ts(e, i[s]);
        }), e;
      }
      makeTime(t, e, i = 1, s = 0, o, r, a) {
        let n = this.Date.UTC(t, e, i, s, o || 0, r || 0, a || 0);
        if ("UTC" !== this.timezone) {
          let t = this.getTimezoneOffset(n);
          if (n += t, -1 !== [2, 3, 8, 9, 10, 11].indexOf(e) && (s < 5 || s > 20)) {
            let e = this.getTimezoneOffset(n);
            t !== e ? n += e - t : t - 36e5 !== this.getTimezoneOffset(n - 36e5) || tu || (n -= 36e5);
          }
        }
        return n;
      }
      parse(t) {
        if (!ta(t)) return t ?? void 0;
        let e = (t = t.replace(/\//g, "-").replace(/(GMT|UTC)/, "")).indexOf("Z") > -1 || /([+-][0-9]{2}):?[0-9]{2}$/.test(t),
          i = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(t);
        e || i || (t += "Z");
        let s = Date.parse(t);
        if (to(s)) return s + (!e || i ? this.getTimezoneOffset(s) : 0);
      }
      getTimezoneOffset(t) {
        if ("UTC" !== this.timezone) {
          let [e, i, s, o, r = 0] = this.dateTimeFormat({
              timeZoneName: "shortOffset"
            }, t, "en").split(/(GMT|:)/).map(Number),
            a = -(36e5 * (s + r / 60));
          if (to(a)) return a;
        }
        return 0;
      }
      dateFormat(t, e, i) {
        let s = A.defaultOptions?.lang;
        if (!te(e) || isNaN(e)) return s?.invalidDate || "";
        if (ta(t = t ?? "%Y-%m-%d %H:%M:%S")) {
          let i;
          let s = /%\[([a-zA-Z]+)\]/g;
          for (; i = s.exec(t);) t = t.replace(i[0], this.dateTimeFormat(i[1], e));
        }
        if (ta(t) && -1 !== t.indexOf("%")) {
          let i = this,
            [o, r, a, n, l, h, d, c] = this.toParts(e),
            p = s?.weekdays || this.weekdays,
            u = s?.shortWeekdays || this.shortWeekdays,
            g = s?.months || this.months,
            m = s?.shortMonths || this.shortMonths;
          tl(ts({
            a: u ? u[c] : p[c].substr(0, 3),
            A: p[c],
            d: th(a),
            e: th(a, 2, " "),
            w: c,
            b: m[r],
            B: g[r],
            m: th(r + 1),
            o: r + 1,
            y: o.toString().substr(2, 2),
            Y: o,
            H: th(n),
            k: n,
            I: th(n % 12 || 12),
            l: n % 12 || 12,
            M: th(l),
            p: n < 12 ? "AM" : "PM",
            P: n < 12 ? "am" : "pm",
            S: th(h),
            L: th(d, 3)
          }, A.dateFormats), function (s, o) {
            if (ta(t)) for (; -1 !== t.indexOf("%" + o);) t = t.replace("%" + o, "function" == typeof s ? s.call(i, e) : s);
          });
        } else if (tr(t)) {
          let i = (this.getTimezoneOffset(e) || 0) / 36e5,
            s = this.timezone || "Etc/GMT" + (i >= 0 ? "+" : "") + i,
            {
              prefix: o = "",
              suffix: r = ""
            } = t;
          t = o + this.dateTimeFormat(ts({
            timeZone: s
          }, t), e) + r;
        }
        return i ? tp(t) : t;
      }
      resolveDTLFormat(t) {
        return tr(t, !0) ? tr(t, !0) && tg(t) ? {
          main: t
        } : t : {
          main: (t = td(t))[0],
          from: t[1],
          to: t[2]
        };
      }
      getTimeTicks(t, e, i, s) {
        let o = this,
          r = [],
          a = {},
          {
            count: n = 1,
            unitRange: l
          } = t,
          [h, d, c, p, u, g] = o.toParts(e),
          m = (e || 0) % 1e3,
          f;
        if (s ?? (s = 1), te(e)) {
          if (m = l >= tc.second ? 0 : n * Math.floor(m / n), l >= tc.second && (g = l >= tc.minute ? 0 : n * Math.floor(g / n)), l >= tc.minute && (u = l >= tc.hour ? 0 : n * Math.floor(u / n)), l >= tc.hour && (p = l >= tc.day ? 0 : n * Math.floor(p / n)), l >= tc.day && (c = l >= tc.month ? 1 : Math.max(1, n * Math.floor(c / n))), l >= tc.month && (d = l >= tc.year ? 0 : n * Math.floor(d / n)), l >= tc.year && (h -= h % n), l === tc.week) {
            n && (e = o.makeTime(h, d, c, p, u, g, m));
            let t = tm(this.dateTimeFormat({
              timeZone: this.timezone,
              weekday: "narrow"
            }, e, "es"));
            c += -t + s + (t < s ? -7 : 0);
          }
          e = o.makeTime(h, d, c, p, u, g, m), o.variableTimezone && te(i) && (f = i - e > 4 * tc.month || o.getTimezoneOffset(e) !== o.getTimezoneOffset(i));
          let t = e,
            x = 1;
          for (; t < i;) r.push(t), l === tc.year ? t = o.makeTime(h + x * n, 0) : l === tc.month ? t = o.makeTime(h, d + x * n) : f && (l === tc.day || l === tc.week) ? t = o.makeTime(h, d, c + x * n * (l === tc.day ? 1 : 7)) : f && l === tc.hour && n > 1 ? t = o.makeTime(h, d, c, p + x * n) : t += l * n, x++;
          r.push(t), l <= tc.hour && r.length < 1e4 && r.forEach(t => {
            t % 18e5 == 0 && "000000000" === o.dateFormat("%H%M%S%L", t) && (a[t] = "day");
          });
        }
        return r.info = ts(t, {
          higherRanks: a,
          totalRange: l * n
        }), r;
      }
      getDateFormat(t, e, i, s) {
        let o = this.dateFormat("%m-%d %H:%M:%S.%L", e),
          r = "01-01 00:00:00.000",
          a = {
            millisecond: 15,
            second: 12,
            minute: 9,
            hour: 6,
            day: 3
          },
          n = "millisecond",
          l = n;
        for (n in tc) {
          if (t === tc.week && +this.dateFormat("%w", e) === i && o.substr(6) === r.substr(6)) {
            n = "week";
            break;
          }
          if (tc[n] > t) {
            n = l;
            break;
          }
          if (a[n] && o.substr(a[n]) !== r.substr(a[n])) break;
          "week" !== n && (l = n);
        }
        return this.resolveDTLFormat(s[n]).main;
      }
    },
    {
      isTouchDevice: tx
    } = A,
    {
      fireEvent: ty,
      merge: tb
    } = J,
    tv = {
      colors: ["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"],
      symbols: ["circle", "diamond", "square", "triangle", "triangle-down"],
      lang: {
        locale: void 0,
        loading: "Loading...",
        months: void 0,
        shortMonths: void 0,
        weekdays: void 0,
        numericSymbols: ["k", "M", "G", "T", "P", "E"],
        resetZoom: "Reset zoom",
        resetZoomTitle: "Reset zoom level 1:1"
      },
      global: {
        buttonTheme: {
          fill: "#f7f7f7",
          padding: 8,
          r: 2,
          stroke: "#cccccc",
          "stroke-width": 1,
          style: {
            color: "#333333",
            cursor: "pointer",
            fontSize: "0.8em",
            fontWeight: "normal"
          },
          states: {
            hover: {
              fill: "#e6e6e6"
            },
            select: {
              fill: "#e6e9ff",
              style: {
                color: "#000000",
                fontWeight: "bold"
              }
            },
            disabled: {
              style: {
                color: "#cccccc"
              }
            }
          }
        }
      },
      time: {
        Date: void 0,
        timezone: "UTC",
        timezoneOffset: 0,
        useUTC: void 0
      },
      chart: {
        alignThresholds: !1,
        panning: {
          enabled: !1,
          type: "x"
        },
        styledMode: !1,
        borderRadius: 0,
        colorCount: 10,
        allowMutatingData: !0,
        ignoreHiddenSeries: !0,
        spacing: [10, 10, 15, 10],
        resetZoomButton: {
          theme: {},
          position: {}
        },
        reflow: !0,
        type: "line",
        zooming: {
          singleTouch: !1,
          resetButton: {
            theme: {
              zIndex: 6
            },
            position: {
              align: "right",
              x: -10,
              y: 10
            }
          }
        },
        width: null,
        height: null,
        borderColor: "#334eff",
        backgroundColor: "#ffffff",
        plotBorderColor: "#cccccc"
      },
      title: {
        style: {
          color: "#333333",
          fontWeight: "bold"
        },
        text: "Chart title",
        margin: 15,
        minScale: .67
      },
      subtitle: {
        style: {
          color: "#666666",
          fontSize: "0.8em"
        },
        text: ""
      },
      caption: {
        margin: 15,
        style: {
          color: "#666666",
          fontSize: "0.8em"
        },
        text: "",
        align: "left",
        verticalAlign: "bottom"
      },
      plotOptions: {},
      legend: {
        enabled: !0,
        align: "center",
        alignColumns: !0,
        className: "highcharts-no-tooltip",
        events: {},
        layout: "horizontal",
        itemMarginBottom: 2,
        itemMarginTop: 2,
        labelFormatter: function () {
          return this.name;
        },
        borderColor: "#999999",
        borderRadius: 0,
        navigation: {
          style: {
            fontSize: "0.8em"
          },
          activeColor: "#0022ff",
          inactiveColor: "#cccccc"
        },
        itemStyle: {
          color: "#333333",
          cursor: "pointer",
          fontSize: "0.8em",
          textDecoration: "none",
          textOverflow: "ellipsis"
        },
        itemHoverStyle: {
          color: "#000000"
        },
        itemHiddenStyle: {
          color: "#666666",
          textDecoration: "line-through"
        },
        shadow: !1,
        itemCheckboxStyle: {
          position: "absolute",
          width: "13px",
          height: "13px"
        },
        squareSymbol: !0,
        symbolPadding: 5,
        verticalAlign: "bottom",
        x: 0,
        y: 0,
        title: {
          style: {
            fontSize: "0.8em",
            fontWeight: "bold"
          }
        }
      },
      loading: {
        labelStyle: {
          fontWeight: "bold",
          position: "relative",
          top: "45%"
        },
        style: {
          position: "absolute",
          backgroundColor: "#ffffff",
          opacity: .5,
          textAlign: "center"
        }
      },
      tooltip: {
        enabled: !0,
        animation: {
          duration: 300,
          easing: t => Math.sqrt(1 - Math.pow(t - 1, 2))
        },
        borderRadius: 3,
        dateTimeLabelFormats: {
          millisecond: "%[AebHMSL]",
          second: "%[AebHMS]",
          minute: "%[AebHM]",
          hour: "%[AebHM]",
          day: "%[AebY]",
          week: "Week from %[AebY]",
          month: "%[BY]",
          year: "%Y"
        },
        footerFormat: "",
        headerShape: "callout",
        hideDelay: 500,
        padding: 8,
        shape: "callout",
        shared: !1,
        snap: tx ? 25 : 10,
        headerFormat: '<span style="font-size: 0.8em">{ucfirst point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
        backgroundColor: "#ffffff",
        borderWidth: void 0,
        shadow: !0,
        stickOnContact: !1,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "0.8em"
        },
        useHTML: !1
      },
      credits: {
        enabled: !0,
        href: "https://www.highcharts.com?credits",
        position: {
          align: "right",
          x: -10,
          verticalAlign: "bottom",
          y: -5
        },
        style: {
          cursor: "pointer",
          color: "#999999",
          fontSize: "0.6em"
        },
        text: "Highcharts.com"
      }
    },
    tM = new tf(tv.time),
    tw = {
      defaultOptions: tv,
      defaultTime: tM,
      getOptions: function () {
        return tv;
      },
      setOptions: function (t) {
        return ty(A, "setOptions", {
          options: t
        }), tb(!0, tv, t), t.time && tM.update(tv.time), t.lang && "locale" in t.lang && tM.update({
          locale: t.lang.locale
        }), tv;
      }
    },
    {
      isNumber: tk,
      merge: tS,
      pInt: tT,
      defined: tC
    } = J;
  class tA {
    static parse(t) {
      return t ? new tA(t) : tA.None;
    }
    constructor(t) {
      let e, i, s, o;
      this.rgba = [NaN, NaN, NaN, NaN], this.input = t;
      let r = A.Color;
      if (r && r !== tA) return new r(t);
      if ("object" == typeof t && void 0 !== t.stops) this.stops = t.stops.map(t => new tA(t[1]));else if ("string" == typeof t) for (this.input = t = tA.names[t.toLowerCase()] || t, s = tA.parsers.length; s-- && !i;) (e = (o = tA.parsers[s]).regex.exec(t)) && (i = o.parse(e));
      i && (this.rgba = i);
    }
    get(t) {
      let e = this.input,
        i = this.rgba;
      if ("object" == typeof e && void 0 !== this.stops) {
        let i = tS(e);
        return i.stops = [].slice.call(i.stops), this.stops.forEach((e, s) => {
          i.stops[s] = [i.stops[s][0], e.get(t)];
        }), i;
      }
      return i && tk(i[0]) ? "rgb" !== t && (t || 1 !== i[3]) ? "a" === t ? `${i[3]}` : "rgba(" + i.join(",") + ")" : "rgb(" + i[0] + "," + i[1] + "," + i[2] + ")" : e;
    }
    brighten(t) {
      let e = this.rgba;
      if (this.stops) this.stops.forEach(function (e) {
        e.brighten(t);
      });else if (tk(t) && 0 !== t) for (let i = 0; i < 3; i++) e[i] += tT(255 * t), e[i] < 0 && (e[i] = 0), e[i] > 255 && (e[i] = 255);
      return this;
    }
    setOpacity(t) {
      return this.rgba[3] = t, this;
    }
    tweenTo(t, e) {
      let i = this.rgba,
        s = t.rgba;
      if (!tk(i[0]) || !tk(s[0])) return t.input || "none";
      let o = 1 !== s[3] || 1 !== i[3],
        r = (t, s) => t + (i[s] - t) * (1 - e),
        a = s.slice(0, 3).map(r).map(Math.round);
      return o && a.push(r(s[3], 3)), (o ? "rgba(" : "rgb(") + a.join(",") + ")";
    }
  }
  tA.names = {
    white: "#ffffff",
    black: "#000000"
  }, tA.parsers = [{
    regex: /rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d?(?:\.\d+)?)\s*\)/,
    parse: function (t) {
      return [tT(t[1]), tT(t[2]), tT(t[3]), parseFloat(t[4], 10)];
    }
  }, {
    regex: /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)/,
    parse: function (t) {
      return [tT(t[1]), tT(t[2]), tT(t[3]), 1];
    }
  }, {
    regex: /^#([a-f0-9])([a-f0-9])([a-f0-9])([a-f0-9])?$/i,
    parse: function (t) {
      return [tT(t[1] + t[1], 16), tT(t[2] + t[2], 16), tT(t[3] + t[3], 16), tC(t[4]) ? tT(t[4] + t[4], 16) / 255 : 1];
    }
  }, {
    regex: /^#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})?$/i,
    parse: function (t) {
      return [tT(t[1], 16), tT(t[2], 16), tT(t[3], 16), tC(t[4]) ? tT(t[4], 16) / 255 : 1];
    }
  }], tA.None = new tA("");
  let {
      parse: tP
    } = tA,
    {
      win: tL
    } = A,
    {
      isNumber: tO,
      objectEach: tE
    } = J;
  class tI {
    constructor(t, e, i) {
      this.pos = NaN, this.options = e, this.elem = t, this.prop = i;
    }
    dSetter() {
      let t = this.paths,
        e = t && t[0],
        i = t && t[1],
        s = this.now || 0,
        o = [];
      if (1 !== s && e && i) {
        if (e.length === i.length && s < 1) for (let t = 0; t < i.length; t++) {
          let r = e[t],
            a = i[t],
            n = [];
          for (let t = 0; t < a.length; t++) {
            let e = r[t],
              i = a[t];
            tO(e) && tO(i) && !("A" === a[0] && (4 === t || 5 === t)) ? n[t] = e + s * (i - e) : n[t] = i;
          }
          o.push(n);
        } else o = i;
      } else o = this.toD || [];
      this.elem.attr("d", o, void 0, !0);
    }
    update() {
      let t = this.elem,
        e = this.prop,
        i = this.now,
        s = this.options.step;
      this[e + "Setter"] ? this[e + "Setter"]() : t.attr ? t.element && t.attr(e, i, null, !0) : t.style[e] = i + this.unit, s && s.call(t, i, this);
    }
    run(t, e, i) {
      let s = this,
        o = s.options,
        r = function (t) {
          return !r.stopped && s.step(t);
        },
        a = tL.requestAnimationFrame || function (t) {
          setTimeout(t, 13);
        },
        n = function () {
          for (let t = 0; t < tI.timers.length; t++) tI.timers[t]() || tI.timers.splice(t--, 1);
          tI.timers.length && a(n);
        };
      t !== e || this.elem["forceAnimate:" + this.prop] ? (this.startTime = +new Date(), this.start = t, this.end = e, this.unit = i, this.now = this.start, this.pos = 0, r.elem = this.elem, r.prop = this.prop, r() && 1 === tI.timers.push(r) && a(n)) : (delete o.curAnim[this.prop], o.complete && 0 === Object.keys(o.curAnim).length && o.complete.call(this.elem));
    }
    step(t) {
      let e, i;
      let s = +new Date(),
        o = this.options,
        r = this.elem,
        a = o.complete,
        n = o.duration,
        l = o.curAnim;
      return r.attr && !r.element ? e = !1 : t || s >= n + this.startTime ? (this.now = this.end, this.pos = 1, this.update(), l[this.prop] = !0, i = !0, tE(l, function (t) {
        !0 !== t && (i = !1);
      }), i && a && a.call(r), e = !1) : (this.pos = o.easing((s - this.startTime) / n), this.now = this.start + (this.end - this.start) * this.pos, this.update(), e = !0), e;
    }
    initPath(t, e, i) {
      let s = t.startX,
        o = t.endX,
        r = i.slice(),
        a = t.isArea,
        n = a ? 2 : 1,
        l = e && i.length > e.length && i.hasStackedCliffs,
        h,
        d,
        c,
        p,
        u = e && e.slice();
      if (!u || l) return [r, r];
      function g(t, e) {
        for (; t.length < d;) {
          let i = t[0],
            s = e[d - t.length];
          if (s && "M" === i[0] && ("C" === s[0] ? t[0] = ["C", i[1], i[2], i[1], i[2], i[1], i[2]] : t[0] = ["L", i[1], i[2]]), t.unshift(i), a) {
            let e = t.pop();
            t.push(t[t.length - 1], e);
          }
        }
      }
      function m(t) {
        for (; t.length < d;) {
          let e = t[Math.floor(t.length / n) - 1].slice();
          if ("C" === e[0] && (e[1] = e[5], e[2] = e[6]), a) {
            let i = t[Math.floor(t.length / n)].slice();
            t.splice(t.length / 2, 0, e, i);
          } else t.push(e);
        }
      }
      if (s && o && o.length) {
        for (c = 0; c < s.length; c++) {
          if (s[c] === o[0]) {
            h = c;
            break;
          }
          if (s[0] === o[o.length - s.length + c]) {
            h = c, p = !0;
            break;
          }
          if (s[s.length - 1] === o[o.length - s.length + c]) {
            h = s.length - c;
            break;
          }
        }
        void 0 === h && (u = []);
      }
      return u.length && tO(h) && (d = r.length + h * n, p ? (g(u, r), m(r)) : (g(r, u), m(u))), [u, r];
    }
    fillSetter() {
      tI.prototype.strokeSetter.apply(this, arguments);
    }
    strokeSetter() {
      this.elem.attr(this.prop, tP(this.start).tweenTo(tP(this.end), this.pos), void 0, !0);
    }
  }
  tI.timers = [];
  let {
    defined: tD,
    getStyle: tB,
    isArray: tz,
    isNumber: tN,
    isObject: tR,
    merge: tW,
    objectEach: tj,
    pick: tX
  } = J;
  function tG(t) {
    return tR(t) ? tW({
      duration: 500,
      defer: 0
    }, t) : {
      duration: t ? 500 : 0,
      defer: 0
    };
  }
  function tF(t, e) {
    let i = tI.timers.length;
    for (; i--;) tI.timers[i].elem !== t || e && e !== tI.timers[i].prop || (tI.timers[i].stopped = !0);
  }
  let tH = {
      animate: function (t, e, i) {
        let s,
          o = "",
          r,
          a,
          n;
        tR(i) || (n = arguments, i = {
          duration: n[2],
          easing: n[3],
          complete: n[4]
        }), tN(i.duration) || (i.duration = 400), i.easing = "function" == typeof i.easing ? i.easing : Math[i.easing] || Math.easeInOutSine, i.curAnim = tW(e), tj(e, function (n, l) {
          tF(t, l), a = new tI(t, i, l), r = void 0, "d" === l && tz(e.d) ? (a.paths = a.initPath(t, t.pathArray, e.d), a.toD = e.d, s = 0, r = 1) : t.attr ? s = t.attr(l) : (s = parseFloat(tB(t, l)) || 0, "opacity" !== l && (o = "px")), r || (r = n), "string" == typeof r && r.match("px") && (r = r.replace(/px/g, "")), a.run(s, r, o);
        });
      },
      animObject: tG,
      getDeferredAnimation: function (t, e, i) {
        let s = tG(e),
          o = i ? [i] : t.series,
          r = 0,
          a = 0;
        return o.forEach(t => {
          let i = tG(t.options.animation);
          r = tR(e) && tD(e.defer) ? s.defer : Math.max(r, i.duration + i.defer), a = Math.min(s.duration, i.duration);
        }), t.renderer.forExport && (r = 0), {
          defer: Math.max(0, r - a),
          duration: Math.min(r, a)
        };
      },
      setAnimation: function (t, e) {
        e.renderer.globalAnimation = tX(t, e.options.chart.animation, !0);
      },
      stop: tF
    },
    {
      SVG_NS: tY,
      win: tV
    } = A,
    {
      attr: tU,
      createElement: tZ,
      css: t$,
      error: t_,
      isFunction: tq,
      isString: tK,
      objectEach: tJ,
      splat: tQ
    } = J,
    {
      trustedTypes: t0
    } = tV,
    t1 = t0 && tq(t0.createPolicy) && t0.createPolicy("highcharts", {
      createHTML: t => t
    }),
    t2 = t1 ? t1.createHTML("") : "";
  class t3 {
    static filterUserAttributes(t) {
      return tJ(t, (e, i) => {
        let s = !0;
        -1 === t3.allowedAttributes.indexOf(i) && (s = !1), -1 !== ["background", "dynsrc", "href", "lowsrc", "src"].indexOf(i) && (s = tK(e) && t3.allowedReferences.some(t => 0 === e.indexOf(t))), s || (t_(33, !1, void 0, {
          "Invalid attribute in config": `${i}`
        }), delete t[i]), tK(e) && t[i] && (t[i] = e.replace(/</g, "&lt;"));
      }), t;
    }
    static parseStyle(t) {
      return t.split(";").reduce((t, e) => {
        let i = e.split(":").map(t => t.trim()),
          s = i.shift();
        return s && i.length && (t[s.replace(/-([a-z])/g, t => t[1].toUpperCase())] = i.join(":")), t;
      }, {});
    }
    static setElementHTML(t, e) {
      t.innerHTML = t3.emptyHTML, e && new t3(e).addToDOM(t);
    }
    constructor(t) {
      this.nodes = "string" == typeof t ? this.parseMarkup(t) : t;
    }
    addToDOM(t) {
      return function t(e, i) {
        let s;
        return tQ(e).forEach(function (e) {
          let o;
          let r = e.tagName,
            a = e.textContent ? A.doc.createTextNode(e.textContent) : void 0,
            n = t3.bypassHTMLFiltering;
          if (r) {
            if ("#text" === r) o = a;else if (-1 !== t3.allowedTags.indexOf(r) || n) {
              let s = "svg" === r ? tY : i.namespaceURI || tY,
                l = A.doc.createElementNS(s, r),
                h = e.attributes || {};
              tJ(e, function (t, e) {
                "tagName" !== e && "attributes" !== e && "children" !== e && "style" !== e && "textContent" !== e && (h[e] = t);
              }), tU(l, n ? h : t3.filterUserAttributes(h)), e.style && t$(l, e.style), a && l.appendChild(a), t(e.children || [], l), o = l;
            } else t_(33, !1, void 0, {
              "Invalid tagName in config": r
            });
          }
          o && i.appendChild(o), s = o;
        }), s;
      }(this.nodes, t);
    }
    parseMarkup(t) {
      let e;
      let i = [];
      t = t.trim().replace(/ style=(["'])/g, " data-style=$1");
      try {
        e = new DOMParser().parseFromString(t1 ? t1.createHTML(t) : t, "text/html");
      } catch (t) {}
      if (!e) {
        let i = tZ("div");
        i.innerHTML = t, e = {
          body: i
        };
      }
      let s = (t, e) => {
        let i = t.nodeName.toLowerCase(),
          o = {
            tagName: i
          };
        "#text" === i && (o.textContent = t.textContent || "");
        let r = t.attributes;
        if (r) {
          let t = {};
          [].forEach.call(r, e => {
            "data-style" === e.name ? o.style = t3.parseStyle(e.value) : t[e.name] = e.value;
          }), o.attributes = t;
        }
        if (t.childNodes.length) {
          let e = [];
          [].forEach.call(t.childNodes, t => {
            s(t, e);
          }), e.length && (o.children = e);
        }
        e.push(o);
      };
      return [].forEach.call(e.body.childNodes, t => s(t, i)), i;
    }
  }
  t3.allowedAttributes = ["alt", "aria-controls", "aria-describedby", "aria-expanded", "aria-haspopup", "aria-hidden", "aria-label", "aria-labelledby", "aria-live", "aria-pressed", "aria-readonly", "aria-roledescription", "aria-selected", "class", "clip-path", "color", "colspan", "cx", "cy", "d", "dx", "dy", "disabled", "fill", "filterUnits", "flood-color", "flood-opacity", "height", "href", "id", "in", "in2", "markerHeight", "markerWidth", "offset", "opacity", "operator", "orient", "padding", "paddingLeft", "paddingRight", "patternUnits", "r", "radius", "refX", "refY", "role", "scope", "slope", "src", "startOffset", "stdDeviation", "stroke", "stroke-linecap", "stroke-width", "style", "tableValues", "result", "rowspan", "summary", "target", "tabindex", "text-align", "text-anchor", "textAnchor", "textLength", "title", "type", "valign", "width", "x", "x1", "x2", "xlink:href", "y", "y1", "y2", "zIndex"], t3.allowedReferences = ["https://", "http://", "mailto:", "/", "../", "./", "#"], t3.allowedTags = ["a", "abbr", "b", "br", "button", "caption", "circle", "clipPath", "code", "dd", "defs", "div", "dl", "dt", "em", "feComponentTransfer", "feComposite", "feDropShadow", "feFlood", "feFuncA", "feFuncB", "feFuncG", "feFuncR", "feGaussianBlur", "feMorphology", "feOffset", "feMerge", "feMergeNode", "filter", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "li", "linearGradient", "marker", "ol", "p", "path", "pattern", "pre", "rect", "small", "span", "stop", "strong", "style", "sub", "sup", "svg", "table", "text", "textPath", "thead", "title", "tbody", "tspan", "td", "th", "tr", "u", "ul", "#text"], t3.emptyHTML = t2, t3.bypassHTMLFiltering = !1;
  let {
      defaultOptions: t6,
      defaultTime: t5
    } = tw,
    {
      pageLang: t9
    } = A,
    {
      extend: t8,
      getNestedProperty: t4,
      isArray: t7,
      isNumber: et,
      isObject: ee,
      isString: ei,
      pick: es,
      ucfirst: eo
    } = J,
    er = {
      add: (t, e) => t + e,
      divide: (t, e) => 0 !== e ? t / e : "",
      eq: (t, e) => t == e,
      each: function (t) {
        let e = arguments[arguments.length - 1];
        return !!t7(t) && t.map((i, s) => el(e.body, t8(ee(i) ? i : {
          "@this": i
        }, {
          "@index": s,
          "@first": 0 === s,
          "@last": s === t.length - 1
        }))).join("");
      },
      ge: (t, e) => t >= e,
      gt: (t, e) => t > e,
      if: t => !!t,
      le: (t, e) => t <= e,
      lt: (t, e) => t < e,
      multiply: (t, e) => t * e,
      ne: (t, e) => t != e,
      subtract: (t, e) => t - e,
      ucfirst: eo,
      unless: t => !t
    },
    ea = {},
    en = t => /^["'].+["']$/.test(t);
  function el(t = "", e, i) {
    let s = /\{([\p{L}\d:\.,;\-\/<>\[\]%_@+"'’= #\(\)]+)\}/gu,
      o = /\(([\p{L}\d:\.,;\-\/<>\[\]%_@+"'= ]+)\)/gu,
      r = [],
      a = /f$/,
      n = /\.(\d)/,
      l = i?.options.lang || t6.lang,
      h = i && i.time || t5,
      d = i && i.numberFormatter || eh,
      c = (t = "") => {
        let i;
        return "true" === t || "false" !== t && ((i = Number(t)).toString() === t ? i : en(t) ? t.slice(1, -1) : t4(t, e));
      },
      p,
      u,
      g = 0,
      m;
    for (; null !== (p = s.exec(t));) {
      let i = p,
        s = o.exec(p[1]);
      s && (p = s, m = !0), u && u.isBlock || (u = {
        ctx: e,
        expression: p[1],
        find: p[0],
        isBlock: "#" === p[1].charAt(0),
        start: p.index,
        startInner: p.index + p[0].length,
        length: p[0].length
      });
      let a = (u.isBlock ? i : p)[1].split(" ")[0].replace("#", "");
      er[a] && (u.isBlock && a === u.fn && g++, u.fn || (u.fn = a));
      let n = "else" === p[1];
      if (u.isBlock && u.fn && (p[1] === `/${u.fn}` || n)) {
        if (g) !n && g--;else {
          let e = u.startInner,
            i = t.substr(e, p.index - e);
          void 0 === u.body ? (u.body = i, u.startInner = p.index + p[0].length) : u.elseBody = i, u.find += i + p[0], n || (r.push(u), u = void 0);
        }
      } else u.isBlock || r.push(u);
      if (s && !u?.isBlock) break;
    }
    return r.forEach(s => {
      let r, p;
      let {
        body: u,
        elseBody: g,
        expression: m,
        fn: f
      } = s;
      if (f) {
        let t = [s],
          o = [],
          a = m.length,
          n = 0,
          l;
        for (p = 0; p <= a; p++) {
          let t = m.charAt(p);
          l || '"' !== t && "'" !== t ? l === t && (l = "") : l = t, l || " " !== t && p !== a || (o.push(m.substr(n, p - n)), n = p + 1);
        }
        for (p = er[f].length; p--;) t.unshift(c(o[p + 1]));
        r = er[f].apply(e, t), s.isBlock && "boolean" == typeof r && (r = el(r ? u : g, e, i));
      } else {
        let t = en(m) ? [m] : m.split(":");
        if (r = c(t.shift() || ""), t.length && "number" == typeof r) {
          let e = t.join(":");
          if (a.test(e)) {
            let t = parseInt((e.match(n) || ["", "-1"])[1], 10);
            null !== r && (r = d(r, t, l.decimalPoint, e.indexOf(",") > -1 ? l.thousandsSep : ""));
          } else r = h.dateFormat(e, r);
        }
        o.lastIndex = 0, o.test(s.find) && ei(r) && (r = `"${r}"`);
      }
      t = t.replace(s.find, es(r, ""));
    }), m ? el(t, e, i) : t;
  }
  function eh(t, e, i, s) {
    e = +e;
    let o,
      r,
      [a, n] = (t = +t || 0).toString().split("e").map(Number),
      l = this?.options?.lang || t6.lang,
      h = (t.toString().split(".")[1] || "").split("e")[0].length,
      d = e,
      c = {};
    i ?? (i = l.decimalPoint), s ?? (s = l.thousandsSep), -1 === e ? e = Math.min(h, 20) : et(e) ? e && n < 0 && ((r = e + n) >= 0 ? (a = +a.toExponential(r).split("e")[0], e = r) : (a = Math.floor(a), t = e < 20 ? +(a * Math.pow(10, n)).toFixed(e) : 0, n = 0)) : e = 2, n && (e ?? (e = 2), t = a), et(e) && e >= 0 && (c.minimumFractionDigits = e, c.maximumFractionDigits = e), "" === s && (c.useGrouping = !1);
    let p = s || i,
      u = p ? "en" : this?.locale || l.locale || t9,
      g = JSON.stringify(c) + u;
    return o = (ea[g] ?? (ea[g] = new Intl.NumberFormat(u, c))).format(t), p && (o = o.replace(/([,\.])/g, "_$1").replace(/_\,/g, s ?? ",").replace("_.", i ?? ".")), (e || 0 != +o) && (!(n < 0) || d) || (o = "0"), n && 0 != +o && (o += "e" + (n < 0 ? "" : "+") + n), o;
  }
  let ed = {
    dateFormat: function (t, e, i) {
      return t5.dateFormat(t, e, i);
    },
    format: el,
    helpers: er,
    numberFormat: eh
  };
  !function (t) {
    let e;
    t.rendererTypes = {}, t.getRendererType = function (i = e) {
      return t.rendererTypes[i] || t.rendererTypes[e];
    }, t.registerRendererType = function (i, s, o) {
      t.rendererTypes[i] = s, (!e || o) && (e = i, A.Renderer = s);
    };
  }(o || (o = {}));
  let ec = o,
    {
      clamp: ep,
      pick: eu,
      pushUnique: eg,
      stableSort: em
    } = J;
  (r || (r = {})).distribute = function t(e, i, s) {
    let o = e,
      r = o.reducedLen || i,
      a = (t, e) => t.target - e.target,
      n = [],
      l = e.length,
      h = [],
      d = n.push,
      c,
      p,
      u,
      g = !0,
      m,
      f,
      x = 0,
      y;
    for (c = l; c--;) x += e[c].size;
    if (x > r) {
      for (em(e, (t, e) => (e.rank || 0) - (t.rank || 0)), u = (y = e[0].rank === e[e.length - 1].rank) ? l / 2 : -1, p = y ? u : l - 1; u && x > r;) m = e[c = Math.floor(p)], eg(h, c) && (x -= m.size), p += u, y && p >= e.length && (u /= 2, p = u);
      h.sort((t, e) => e - t).forEach(t => d.apply(n, e.splice(t, 1)));
    }
    for (em(e, a), e = e.map(t => ({
      size: t.size,
      targets: [t.target],
      align: eu(t.align, .5)
    })); g;) {
      for (c = e.length; c--;) m = e[c], f = (Math.min.apply(0, m.targets) + Math.max.apply(0, m.targets)) / 2, m.pos = ep(f - m.size * m.align, 0, i - m.size);
      for (c = e.length, g = !1; c--;) c > 0 && e[c - 1].pos + e[c - 1].size > e[c].pos && (e[c - 1].size += e[c].size, e[c - 1].targets = e[c - 1].targets.concat(e[c].targets), e[c - 1].align = .5, e[c - 1].pos + e[c - 1].size > i && (e[c - 1].pos = i - e[c - 1].size), e.splice(c, 1), g = !0);
    }
    return d.apply(o, n), c = 0, e.some(e => {
      let r = 0;
      return (e.targets || []).some(() => (o[c].pos = e.pos + r, void 0 !== s && Math.abs(o[c].pos - o[c].target) > s) ? (o.slice(0, c + 1).forEach(t => delete t.pos), o.reducedLen = (o.reducedLen || i) - .1 * i, o.reducedLen > .1 * i && t(o, i, s), !0) : (r += o[c].size, c++, !1));
    }), em(o, a), o;
  };
  let ef = r,
    {
      animate: ex,
      animObject: ey,
      stop: eb
    } = tH,
    {
      deg2rad: ev,
      doc: eM,
      svg: ew,
      SVG_NS: ek,
      win: eS,
      isFirefox: eT
    } = A,
    {
      addEvent: eC,
      attr: eA,
      createElement: eP,
      crisp: eL,
      css: eO,
      defined: eE,
      erase: eI,
      extend: eD,
      fireEvent: eB,
      getAlignFactor: ez,
      isArray: eN,
      isFunction: eR,
      isNumber: eW,
      isObject: ej,
      isString: eX,
      merge: eG,
      objectEach: eF,
      pick: eH,
      pInt: eY,
      pushUnique: eV,
      replaceNested: eU,
      syncTimeout: eZ,
      uniqueKey: e$
    } = J;
  class e_ {
    _defaultGetter(t) {
      let e = eH(this[t + "Value"], this[t], this.element ? this.element.getAttribute(t) : null, 0);
      return /^-?[\d\.]+$/.test(e) && (e = parseFloat(e)), e;
    }
    _defaultSetter(t, e, i) {
      i.setAttribute(e, t);
    }
    add(t) {
      let e;
      let i = this.renderer,
        s = this.element;
      return t && (this.parentGroup = t), void 0 !== this.textStr && "text" === this.element.nodeName && i.buildText(this), this.added = !0, (!t || t.handleZ || this.zIndex) && (e = this.zIndexSetter()), e || (t ? t.element : i.box).appendChild(s), this.onAdd && this.onAdd(), this;
    }
    addClass(t, e) {
      let i = e ? "" : this.attr("class") || "";
      return (t = (t || "").split(/ /g).reduce(function (t, e) {
        return -1 === i.indexOf(e) && t.push(e), t;
      }, i ? [i] : []).join(" ")) !== i && this.attr("class", t), this;
    }
    afterSetters() {
      this.doTransform && (this.updateTransform(), this.doTransform = !1);
    }
    align(t, e, i, s = !0) {
      let o = this.renderer,
        r = o.alignedObjects,
        a = !!t;
      t ? (this.alignOptions = t, this.alignByTranslate = e, this.alignTo = i) : (t = this.alignOptions || {}, e = this.alignByTranslate, i = this.alignTo);
      let n = !i || eX(i) ? i || "renderer" : void 0;
      n && (a && eV(r, this), i = void 0);
      let l = eH(i, o[n], o),
        h = (l.x || 0) + (t.x || 0) + ((l.width || 0) - (t.width || 0)) * ez(t.align),
        d = (l.y || 0) + (t.y || 0) + ((l.height || 0) - (t.height || 0)) * ez(t.verticalAlign),
        c = {
          "text-align": t?.align
        };
      return c[e ? "translateX" : "x"] = Math.round(h), c[e ? "translateY" : "y"] = Math.round(d), s && (this[this.placed ? "animate" : "attr"](c), this.placed = !0), this.alignAttr = c, this;
    }
    alignSetter(t) {
      let e = {
        left: "start",
        center: "middle",
        right: "end"
      };
      e[t] && (this.alignValue = t, this.element.setAttribute("text-anchor", e[t]));
    }
    animate(t, e, i) {
      let s = ey(eH(e, this.renderer.globalAnimation, !0)),
        o = s.defer;
      return eM.hidden && (s.duration = 0), 0 !== s.duration ? (i && (s.complete = i), eZ(() => {
        this.element && ex(this, t, s);
      }, o)) : (this.attr(t, void 0, i || s.complete), eF(t, function (t, e) {
        s.step && s.step.call(this, t, {
          prop: e,
          pos: 1,
          elem: this
        });
      }, this)), this;
    }
    applyTextOutline(t) {
      let e = this.element;
      -1 !== t.indexOf("contrast") && (t = t.replace(/contrast/g, this.renderer.getContrast(e.style.fill)));
      let i = t.split(" "),
        s = i[i.length - 1],
        o = i[0];
      if (o && "none" !== o && A.svg) {
        this.fakeTS = !0, o = o.replace(/(^[\d\.]+)(.*?)$/g, function (t, e, i) {
          return 2 * Number(e) + i;
        }), this.removeTextOutline();
        let t = eM.createElementNS(ek, "tspan");
        eA(t, {
          class: "highcharts-text-outline",
          fill: s,
          stroke: s,
          "stroke-width": o,
          "stroke-linejoin": "round"
        });
        let i = e.querySelector("textPath") || e;
        [].forEach.call(i.childNodes, e => {
          let i = e.cloneNode(!0);
          i.removeAttribute && ["fill", "stroke", "stroke-width", "stroke"].forEach(t => i.removeAttribute(t)), t.appendChild(i);
        });
        let r = 0;
        [].forEach.call(i.querySelectorAll("text tspan"), t => {
          r += Number(t.getAttribute("dy"));
        });
        let a = eM.createElementNS(ek, "tspan");
        a.textContent = "​", eA(a, {
          x: Number(e.getAttribute("x")),
          dy: -r
        }), t.appendChild(a), i.insertBefore(t, i.firstChild);
      }
    }
    attr(t, e, i, s) {
      let {
          element: o
        } = this,
        r = e_.symbolCustomAttribs,
        a,
        n,
        l = this,
        h;
      return "string" == typeof t && void 0 !== e && (a = t, (t = {})[a] = e), "string" == typeof t ? l = (this[t + "Getter"] || this._defaultGetter).call(this, t, o) : (eF(t, function (e, i) {
        h = !1, s || eb(this, i), this.symbolName && -1 !== r.indexOf(i) && (n || (this.symbolAttr(t), n = !0), h = !0), this.rotation && ("x" === i || "y" === i) && (this.doTransform = !0), h || (this[i + "Setter"] || this._defaultSetter).call(this, e, i, o);
      }, this), this.afterSetters()), i && i.call(this), l;
    }
    clip(t) {
      if (t && !t.clipPath) {
        let e = e$() + "-",
          i = this.renderer.createElement("clipPath").attr({
            id: e
          }).add(this.renderer.defs);
        eD(t, {
          clipPath: i,
          id: e,
          count: 0
        }), t.add(i);
      }
      return this.attr("clip-path", t ? `url(${this.renderer.url}#${t.id})` : "none");
    }
    crisp(t, e) {
      e = Math.round(e || t.strokeWidth || 0);
      let i = t.x || this.x || 0,
        s = t.y || this.y || 0,
        o = (t.width || this.width || 0) + i,
        r = (t.height || this.height || 0) + s,
        a = eL(i, e),
        n = eL(s, e);
      return eD(t, {
        x: a,
        y: n,
        width: eL(o, e) - a,
        height: eL(r, e) - n
      }), eE(t.strokeWidth) && (t.strokeWidth = e), t;
    }
    complexColor(t, e, i) {
      let s = this.renderer,
        o,
        r,
        a,
        n,
        l,
        h,
        d,
        c,
        p,
        u,
        g = [],
        m;
      eB(this.renderer, "complexColor", {
        args: arguments
      }, function () {
        if (t.radialGradient ? r = "radialGradient" : t.linearGradient && (r = "linearGradient"), r) {
          if (a = t[r], l = s.gradients, h = t.stops, p = i.radialReference, eN(a) && (t[r] = a = {
            x1: a[0],
            y1: a[1],
            x2: a[2],
            y2: a[3],
            gradientUnits: "userSpaceOnUse"
          }), "radialGradient" === r && p && !eE(a.gradientUnits) && (n = a, a = eG(a, s.getRadialAttr(p, n), {
            gradientUnits: "userSpaceOnUse"
          })), eF(a, function (t, e) {
            "id" !== e && g.push(e, t);
          }), eF(h, function (t) {
            g.push(t);
          }), l[g = g.join(",")]) u = l[g].attr("id");else {
            a.id = u = e$();
            let t = l[g] = s.createElement(r).attr(a).add(s.defs);
            t.radAttr = n, t.stops = [], h.forEach(function (e) {
              0 === e[1].indexOf("rgba") ? (d = (o = tA.parse(e[1])).get("rgb"), c = o.get("a")) : (d = e[1], c = 1);
              let i = s.createElement("stop").attr({
                offset: e[0],
                "stop-color": d,
                "stop-opacity": c
              }).add(t);
              t.stops.push(i);
            });
          }
          m = "url(" + s.url + "#" + u + ")", i.setAttribute(e, m), i.gradient = g, t.toString = function () {
            return m;
          };
        }
      });
    }
    css(t) {
      let e = this.styles,
        i = {},
        s = this.element,
        o,
        r = !e;
      if (e && eF(t, function (t, s) {
        e && e[s] !== t && (i[s] = t, r = !0);
      }), r) {
        e && (t = eD(e, i)), null === t.width || "auto" === t.width ? delete this.textWidth : "text" === s.nodeName.toLowerCase() && t.width && (o = this.textWidth = eY(t.width)), eD(this.styles, t), o && !ew && this.renderer.forExport && delete t.width;
        let r = eT && t.fontSize || null;
        r && (eW(r) || /^\d+$/.test(r)) && (t.fontSize += "px");
        let a = eG(t);
        s.namespaceURI === this.SVG_NS && (["textOutline", "textOverflow", "whiteSpace", "width"].forEach(t => a && delete a[t]), a.color && (a.fill = a.color)), eO(s, a);
      }
      return this.added && ("text" === this.element.nodeName && this.renderer.buildText(this), t.textOutline && this.applyTextOutline(t.textOutline)), this;
    }
    dashstyleSetter(t) {
      let e,
        i = this["stroke-width"];
      if ("inherit" === i && (i = 1), t = t && t.toLowerCase()) {
        let s = t.replace("shortdashdotdot", "3,1,1,1,1,1,").replace("shortdashdot", "3,1,1,1").replace("shortdot", "1,1,").replace("shortdash", "3,1,").replace("longdash", "8,3,").replace(/dot/g, "1,3,").replace("dash", "4,3,").replace(/,$/, "").split(",");
        for (e = s.length; e--;) s[e] = "" + eY(s[e]) * eH(i, NaN);
        t = s.join(",").replace(/NaN/g, "none"), this.element.setAttribute("stroke-dasharray", t);
      }
    }
    destroy() {
      let t = this,
        e = t.element || {},
        i = t.renderer,
        s = e.ownerSVGElement,
        o = "SPAN" === e.nodeName && t.parentGroup || void 0,
        r,
        a;
      if (e.onclick = e.onmouseout = e.onmouseover = e.onmousemove = e.point = null, eb(t), t.clipPath && s) {
        let e = t.clipPath;
        [].forEach.call(s.querySelectorAll("[clip-path],[CLIP-PATH]"), function (t) {
          t.getAttribute("clip-path").indexOf(e.element.id) > -1 && t.removeAttribute("clip-path");
        }), t.clipPath = e.destroy();
      }
      if (t.connector = t.connector?.destroy(), t.stops) {
        for (a = 0; a < t.stops.length; a++) t.stops[a].destroy();
        t.stops.length = 0, t.stops = void 0;
      }
      for (t.safeRemoveChild(e); o && o.div && 0 === o.div.childNodes.length;) r = o.parentGroup, t.safeRemoveChild(o.div), delete o.div, o = r;
      t.alignOptions && eI(i.alignedObjects, t), eF(t, function (e, i) {
        t[i] && t[i].parentGroup === t && t[i].destroy && t[i].destroy(), delete t[i];
      });
    }
    dSetter(t, e, i) {
      eN(t) && ("string" == typeof t[0] && (t = this.renderer.pathToSegments(t)), this.pathArray = t, t = t.reduce((t, e, i) => e && e.join ? (i ? t + " " : "") + e.join(" ") : (e || "").toString(), "")), /(NaN| {2}|^$)/.test(t) && (t = "M 0 0"), this[e] !== t && (i.setAttribute(e, t), this[e] = t);
    }
    fillSetter(t, e, i) {
      "string" == typeof t ? i.setAttribute(e, t) : t && this.complexColor(t, e, i);
    }
    hrefSetter(t, e, i) {
      i.setAttributeNS("http://www.w3.org/1999/xlink", e, t);
    }
    getBBox(t, e) {
      let i, s, o, r;
      let {
          alignValue: a,
          element: n,
          renderer: l,
          styles: h,
          textStr: d
        } = this,
        {
          cache: c,
          cacheKeys: p
        } = l,
        u = n.namespaceURI === this.SVG_NS,
        g = eH(e, this.rotation, 0),
        m = l.styledMode ? n && e_.prototype.getStyle.call(n, "font-size") : h.fontSize;
      if (eE(d) && (-1 === (r = d.toString()).indexOf("<") && (r = r.replace(/\d/g, "0")), r += ["", l.rootFontSize, m, g, this.textWidth, a, h.lineClamp, h.textOverflow, h.fontWeight].join(",")), r && !t && (i = c[r]), !i || i.polygon) {
        if (u || l.forExport) {
          try {
            o = this.fakeTS && function (t) {
              let e = n.querySelector(".highcharts-text-outline");
              e && eO(e, {
                display: t
              });
            }, eR(o) && o("none"), i = n.getBBox ? eD({}, n.getBBox()) : {
              width: n.offsetWidth,
              height: n.offsetHeight,
              x: 0,
              y: 0
            }, eR(o) && o("");
          } catch (t) {}
          (!i || i.width < 0) && (i = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          });
        } else i = this.htmlGetBBox();
        s = i.height, u && (i.height = s = {
          "11px,17": 14,
          "13px,20": 16
        }[`${m || ""},${Math.round(s)}`] || s), g && (i = this.getRotatedBox(i, g));
        let t = {
          bBox: i
        };
        eB(this, "afterGetBBox", t), i = t.bBox;
      }
      if (r && ("" === d || i.height > 0)) {
        for (; p.length > 250;) delete c[p.shift()];
        c[r] || p.push(r), c[r] = i;
      }
      return i;
    }
    getRotatedBox(t, e) {
      let {
          x: i,
          y: s,
          width: o,
          height: r
        } = t,
        {
          alignValue: a,
          translateY: n,
          rotationOriginX: l = 0,
          rotationOriginY: h = 0
        } = this,
        d = ez(a),
        c = Number(this.element.getAttribute("y") || 0) - (n ? 0 : s),
        p = e * ev,
        u = (e - 90) * ev,
        g = Math.cos(p),
        m = Math.sin(p),
        f = o * g,
        x = o * m,
        y = Math.cos(u),
        b = Math.sin(u),
        [[v, M], [w, k]] = [l, h].map(t => [t - t * g, t * m]),
        S = i + d * (o - f) + v + k + c * y,
        T = S + f,
        C = T - r * y,
        A = C - f,
        P = s + c - d * x - M + w + c * b,
        L = P + x,
        O = L - r * b,
        E = O - x,
        I = Math.min(S, T, C, A),
        D = Math.min(P, L, O, E),
        B = Math.max(S, T, C, A) - I,
        z = Math.max(P, L, O, E) - D;
      return {
        x: I,
        y: D,
        width: B,
        height: z,
        polygon: [[S, P], [T, L], [C, O], [A, E]]
      };
    }
    getStyle(t) {
      return eS.getComputedStyle(this.element || this, "").getPropertyValue(t);
    }
    hasClass(t) {
      return -1 !== ("" + this.attr("class")).split(" ").indexOf(t);
    }
    hide() {
      return this.attr({
        visibility: "hidden"
      });
    }
    htmlGetBBox() {
      return {
        height: 0,
        width: 0,
        x: 0,
        y: 0
      };
    }
    constructor(t, e) {
      this.onEvents = {}, this.opacity = 1, this.SVG_NS = ek, this.element = "span" === e || "body" === e ? eP(e) : eM.createElementNS(this.SVG_NS, e), this.renderer = t, this.styles = {}, eB(this, "afterInit");
    }
    on(t, e) {
      let {
        onEvents: i
      } = this;
      return i[t] && i[t](), i[t] = eC(this.element, t, e), this;
    }
    opacitySetter(t, e, i) {
      let s = Number(Number(t).toFixed(3));
      this.opacity = s, i.setAttribute(e, s);
    }
    reAlign() {
      this.alignOptions?.width && "left" !== this.alignOptions.align && (this.alignOptions.width = this.getBBox().width, this.placed = !1, this.align());
    }
    removeClass(t) {
      return this.attr("class", ("" + this.attr("class")).replace(eX(t) ? RegExp(`(^| )${t}( |$)`) : t, " ").replace(/ +/g, " ").trim());
    }
    removeTextOutline() {
      let t = this.element.querySelector("tspan.highcharts-text-outline");
      t && this.safeRemoveChild(t);
    }
    safeRemoveChild(t) {
      let e = t.parentNode;
      e && e.removeChild(t);
    }
    setRadialReference(t) {
      let e = this.element.gradient && this.renderer.gradients[this.element.gradient];
      return this.element.radialReference = t, e && e.radAttr && e.animate(this.renderer.getRadialAttr(t, e.radAttr)), this;
    }
    shadow(t) {
      let {
          renderer: e
        } = this,
        i = eG(this.parentGroup?.rotation === 90 ? {
          offsetX: -1,
          offsetY: -1
        } : {}, ej(t) ? t : {}),
        s = e.shadowDefinition(i);
      return this.attr({
        filter: t ? `url(${e.url}#${s})` : "none"
      });
    }
    show(t = !0) {
      return this.attr({
        visibility: t ? "inherit" : "visible"
      });
    }
    "stroke-widthSetter"(t, e, i) {
      this[e] = t, i.setAttribute(e, t);
    }
    strokeWidth() {
      if (!this.renderer.styledMode) return this["stroke-width"] || 0;
      let t = this.getStyle("stroke-width"),
        e = 0,
        i;
      return /px$/.test(t) ? e = eY(t) : "" !== t && (eA(i = eM.createElementNS(ek, "rect"), {
        width: t,
        "stroke-width": 0
      }), this.element.parentNode.appendChild(i), e = i.getBBox().width, i.parentNode.removeChild(i)), e;
    }
    symbolAttr(t) {
      let e = this;
      e_.symbolCustomAttribs.forEach(function (i) {
        e[i] = eH(t[i], e[i]);
      }), e.attr({
        d: e.renderer.symbols[e.symbolName](e.x, e.y, e.width, e.height, e)
      });
    }
    textSetter(t) {
      t !== this.textStr && (delete this.textPxLength, this.textStr = t, this.added && this.renderer.buildText(this), this.reAlign());
    }
    titleSetter(t) {
      let e = this.element,
        i = e.getElementsByTagName("title")[0] || eM.createElementNS(this.SVG_NS, "title");
      e.insertBefore ? e.insertBefore(i, e.firstChild) : e.appendChild(i), i.textContent = eU(eH(t, ""), [/<[^>]*>/g, ""]).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }
    toFront() {
      let t = this.element;
      return t.parentNode.appendChild(t), this;
    }
    translate(t, e) {
      return this.attr({
        translateX: t,
        translateY: e
      });
    }
    updateTransform(t = "transform") {
      let {
          element: e,
          matrix: i,
          rotation: s = 0,
          rotationOriginX: o,
          rotationOriginY: r,
          scaleX: a,
          scaleY: n,
          translateX: l = 0,
          translateY: h = 0
        } = this,
        d = ["translate(" + l + "," + h + ")"];
      eE(i) && d.push("matrix(" + i.join(",") + ")"), s && (d.push("rotate(" + s + " " + eH(o, e.getAttribute("x"), 0) + " " + eH(r, e.getAttribute("y") || 0) + ")"), this.text?.element.tagName === "SPAN" && this.text.attr({
        rotation: s,
        rotationOriginX: (o || 0) - this.padding,
        rotationOriginY: (r || 0) - this.padding
      })), (eE(a) || eE(n)) && d.push("scale(" + eH(a, 1) + " " + eH(n, 1) + ")"), d.length && !(this.text || this).textPath && e.setAttribute(t, d.join(" "));
    }
    visibilitySetter(t, e, i) {
      "inherit" === t ? i.removeAttribute(e) : this[e] !== t && i.setAttribute(e, t), this[e] = t;
    }
    xGetter(t) {
      return "circle" === this.element.nodeName && ("x" === t ? t = "cx" : "y" === t && (t = "cy")), this._defaultGetter(t);
    }
    zIndexSetter(t, e) {
      let i = this.renderer,
        s = this.parentGroup,
        o = (s || i).element || i.box,
        r = this.element,
        a = o === i.box,
        n,
        l,
        h,
        d = !1,
        c,
        p = this.added,
        u;
      if (eE(t) ? (r.setAttribute("data-z-index", t), t = +t, this[e] === t && (p = !1)) : eE(this[e]) && r.removeAttribute("data-z-index"), this[e] = t, p) {
        for ((t = this.zIndex) && s && (s.handleZ = !0), u = (n = o.childNodes).length - 1; u >= 0 && !d; u--) c = !eE(h = (l = n[u]).getAttribute("data-z-index")), l !== r && (t < 0 && c && !a && !u ? (o.insertBefore(r, n[u]), d = !0) : (eY(h) <= t || c && (!eE(t) || t >= 0)) && (o.insertBefore(r, n[u + 1]), d = !0));
        d || (o.insertBefore(r, n[a ? 3 : 0]), d = !0);
      }
      return d;
    }
  }
  e_.symbolCustomAttribs = ["anchorX", "anchorY", "clockwise", "end", "height", "innerR", "r", "start", "width", "x", "y"], e_.prototype.strokeSetter = e_.prototype.fillSetter, e_.prototype.yGetter = e_.prototype.xGetter, e_.prototype.matrixSetter = e_.prototype.rotationOriginXSetter = e_.prototype.rotationOriginYSetter = e_.prototype.rotationSetter = e_.prototype.scaleXSetter = e_.prototype.scaleYSetter = e_.prototype.translateXSetter = e_.prototype.translateYSetter = e_.prototype.verticalAlignSetter = function (t, e) {
    this[e] = t, this.doTransform = !0;
  };
  let eq = e_,
    {
      defined: eK,
      extend: eJ,
      getAlignFactor: eQ,
      isNumber: e0,
      merge: e1,
      pick: e2,
      removeEvent: e3
    } = J;
  class e6 extends eq {
    constructor(t, e, i, s, o, r, a, n, l, h) {
      let d;
      super(t, "g"), this.paddingLeftSetter = this.paddingSetter, this.paddingRightSetter = this.paddingSetter, this.doUpdate = !1, this.textStr = e, this.x = i, this.y = s, this.anchorX = r, this.anchorY = a, this.baseline = l, this.className = h, this.addClass("button" === h ? "highcharts-no-tooltip" : "highcharts-label"), h && this.addClass("highcharts-" + h), this.text = t.text(void 0, 0, 0, n).attr({
        zIndex: 1
      }), "string" == typeof o && ((d = /^url\((.*?)\)$/.test(o)) || this.renderer.symbols[o]) && (this.symbolKey = o), this.bBox = e6.emptyBBox, this.padding = 3, this.baselineOffset = 0, this.needsBox = t.styledMode || d, this.deferredAttr = {}, this.alignFactor = 0;
    }
    alignSetter(t) {
      let e = eQ(t);
      this.textAlign = t, e !== this.alignFactor && (this.alignFactor = e, this.bBox && e0(this.xSetting) && this.attr({
        x: this.xSetting
      }));
    }
    anchorXSetter(t, e) {
      this.anchorX = t, this.boxAttr(e, Math.round(t) - this.getCrispAdjust() - this.xSetting);
    }
    anchorYSetter(t, e) {
      this.anchorY = t, this.boxAttr(e, t - this.ySetting);
    }
    boxAttr(t, e) {
      this.box ? this.box.attr(t, e) : this.deferredAttr[t] = e;
    }
    css(t) {
      if (t) {
        let e = {};
        t = e1(t), e6.textProps.forEach(i => {
          void 0 !== t[i] && (e[i] = t[i], delete t[i]);
        }), this.text.css(e), "fontSize" in e || "fontWeight" in e ? this.updateTextPadding() : ("width" in e || "textOverflow" in e) && this.updateBoxSize();
      }
      return eq.prototype.css.call(this, t);
    }
    destroy() {
      e3(this.element, "mouseenter"), e3(this.element, "mouseleave"), this.text && this.text.destroy(), this.box && (this.box = this.box.destroy()), eq.prototype.destroy.call(this);
    }
    fillSetter(t, e) {
      t && (this.needsBox = !0), this.fill = t, this.boxAttr(e, t);
    }
    getBBox(t, e) {
      this.textStr && 0 === this.bBox.width && 0 === this.bBox.height && this.updateBoxSize();
      let {
          padding: i,
          height: s = 0,
          translateX: o = 0,
          translateY: r = 0,
          width: a = 0
        } = this,
        n = e2(this.paddingLeft, i),
        l = e ?? (this.rotation || 0),
        h = {
          width: a,
          height: s,
          x: o + this.bBox.x - n,
          y: r + this.bBox.y - i + this.baselineOffset
        };
      return l && (h = this.getRotatedBox(h, l)), h;
    }
    getCrispAdjust() {
      return (this.renderer.styledMode && this.box ? this.box.strokeWidth() : this["stroke-width"] ? parseInt(this["stroke-width"], 10) : 0) % 2 / 2;
    }
    heightSetter(t) {
      this.heightSetting = t, this.doUpdate = !0;
    }
    afterSetters() {
      super.afterSetters(), this.doUpdate && (this.updateBoxSize(), this.doUpdate = !1);
    }
    onAdd() {
      this.text.add(this), this.attr({
        text: e2(this.textStr, ""),
        x: this.x || 0,
        y: this.y || 0
      }), this.box && eK(this.anchorX) && this.attr({
        anchorX: this.anchorX,
        anchorY: this.anchorY
      });
    }
    paddingSetter(t, e) {
      e0(t) ? t !== this[e] && (this[e] = t, this.updateTextPadding()) : this[e] = void 0;
    }
    rSetter(t, e) {
      this.boxAttr(e, t);
    }
    strokeSetter(t, e) {
      this.stroke = t, this.boxAttr(e, t);
    }
    "stroke-widthSetter"(t, e) {
      t && (this.needsBox = !0), this["stroke-width"] = t, this.boxAttr(e, t);
    }
    "text-alignSetter"(t) {
      this.textAlign = this["text-align"] = t, this.updateTextPadding();
    }
    textSetter(t) {
      void 0 !== t && this.text.attr({
        text: t
      }), this.updateTextPadding(), this.reAlign();
    }
    updateBoxSize() {
      let t;
      let e = this.text,
        i = {},
        s = this.padding,
        o = this.bBox = (!e0(this.widthSetting) || !e0(this.heightSetting) || this.textAlign) && eK(e.textStr) ? e.getBBox(void 0, 0) : e6.emptyBBox;
      this.width = this.getPaddedWidth(), this.height = (this.heightSetting || o.height || 0) + 2 * s;
      let r = this.renderer.fontMetrics(e);
      if (this.baselineOffset = s + Math.min((this.text.firstLineMetrics || r).b, o.height || 1 / 0), this.heightSetting && (this.baselineOffset += (this.heightSetting - r.h) / 2), this.needsBox && !e.textPath) {
        if (!this.box) {
          let t = this.box = this.symbolKey ? this.renderer.symbol(this.symbolKey) : this.renderer.rect();
          t.addClass(("button" === this.className ? "" : "highcharts-label-box") + (this.className ? " highcharts-" + this.className + "-box" : "")), t.add(this);
        }
        t = this.getCrispAdjust(), i.x = t, i.y = (this.baseline ? -this.baselineOffset : 0) + t, i.width = Math.round(this.width), i.height = Math.round(this.height), this.box.attr(eJ(i, this.deferredAttr)), this.deferredAttr = {};
      }
    }
    updateTextPadding() {
      let t = this.text,
        e = t.styles.textAlign || this.textAlign;
      if (!t.textPath) {
        this.updateBoxSize();
        let i = this.baseline ? 0 : this.baselineOffset,
          s = (this.paddingLeft ?? this.padding) + eQ(e) * (this.widthSetting ?? this.bBox.width);
        (s !== t.x || i !== t.y) && (t.attr({
          align: e,
          x: s
        }), void 0 !== i && t.attr("y", i)), t.x = s, t.y = i;
      }
    }
    widthSetter(t) {
      this.widthSetting = e0(t) ? t : void 0, this.doUpdate = !0;
    }
    getPaddedWidth() {
      let t = this.padding,
        e = e2(this.paddingLeft, t),
        i = e2(this.paddingRight, t);
      return (this.widthSetting || this.bBox.width || 0) + e + i;
    }
    xSetter(t) {
      this.x = t, this.alignFactor && (t -= this.alignFactor * this.getPaddedWidth(), this["forceAnimate:x"] = !0), this.xSetting = Math.round(t), this.attr("translateX", this.xSetting);
    }
    ySetter(t) {
      this.ySetting = this.y = Math.round(t), this.attr("translateY", this.ySetting);
    }
  }
  e6.emptyBBox = {
    width: 0,
    height: 0,
    x: 0,
    y: 0
  }, e6.textProps = ["color", "direction", "fontFamily", "fontSize", "fontStyle", "fontWeight", "lineClamp", "lineHeight", "textAlign", "textDecoration", "textOutline", "textOverflow", "whiteSpace", "width"];
  let {
    defined: e5,
    isNumber: e9,
    pick: e8
  } = J;
  function e4(t, e, i, s, o) {
    let r = [];
    if (o) {
      let a = o.start || 0,
        n = e8(o.r, i),
        l = e8(o.r, s || i),
        h = 2e-4 / (o.borderRadius ? 1 : Math.max(n, 1)),
        d = Math.abs((o.end || 0) - a - 2 * Math.PI) < h,
        c = (o.end || 0) - (d ? h : 0),
        p = o.innerR,
        u = e8(o.open, d),
        g = Math.cos(a),
        m = Math.sin(a),
        f = Math.cos(c),
        x = Math.sin(c),
        y = e8(o.longArc, c - a - Math.PI < h ? 0 : 1),
        b = ["A", n, l, 0, y, e8(o.clockwise, 1), t + n * f, e + l * x];
      b.params = {
        start: a,
        end: c,
        cx: t,
        cy: e
      }, r.push(["M", t + n * g, e + l * m], b), e5(p) && ((b = ["A", p, p, 0, y, e5(o.clockwise) ? 1 - o.clockwise : 0, t + p * g, e + p * m]).params = {
        start: c,
        end: a,
        cx: t,
        cy: e
      }, r.push(u ? ["M", t + p * f, e + p * x] : ["L", t + p * f, e + p * x], b)), u || r.push(["Z"]);
    }
    return r;
  }
  function e7(t, e, i, s, o) {
    return o && o.r ? it(t, e, i, s, o) : [["M", t, e], ["L", t + i, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
  }
  function it(t, e, i, s, o) {
    let r = o?.r || 0;
    return [["M", t + r, e], ["L", t + i - r, e], ["A", r, r, 0, 0, 1, t + i, e + r], ["L", t + i, e + s - r], ["A", r, r, 0, 0, 1, t + i - r, e + s], ["L", t + r, e + s], ["A", r, r, 0, 0, 1, t, e + s - r], ["L", t, e + r], ["A", r, r, 0, 0, 1, t + r, e], ["Z"]];
  }
  let ie = {
      arc: e4,
      callout: function (t, e, i, s, o) {
        let r = Math.min(o && o.r || 0, i, s),
          a = r + 6,
          n = o && o.anchorX,
          l = o && o.anchorY || 0,
          h = it(t, e, i, s, {
            r
          });
        if (!e9(n) || n < i && n > 0 && l < s && l > 0) return h;
        if (t + n > i - a) {
          if (l > e + a && l < e + s - a) h.splice(3, 1, ["L", t + i, l - 6], ["L", t + i + 6, l], ["L", t + i, l + 6], ["L", t + i, e + s - r]);else if (n < i) {
            let o = l < e + a,
              d = o ? e : e + s;
            h.splice(o ? 2 : 5, 0, ["L", n, l], ["L", t + i - r, d]);
          } else h.splice(3, 1, ["L", t + i, s / 2], ["L", n, l], ["L", t + i, s / 2], ["L", t + i, e + s - r]);
        } else if (t + n < a) {
          if (l > e + a && l < e + s - a) h.splice(7, 1, ["L", t, l + 6], ["L", t - 6, l], ["L", t, l - 6], ["L", t, e + r]);else if (n > 0) {
            let i = l < e + a,
              o = i ? e : e + s;
            h.splice(i ? 1 : 6, 0, ["L", n, l], ["L", t + r, o]);
          } else h.splice(7, 1, ["L", t, s / 2], ["L", n, l], ["L", t, s / 2], ["L", t, e + r]);
        } else l > s && n < i - a ? h.splice(5, 1, ["L", n + 6, e + s], ["L", n, e + s + 6], ["L", n - 6, e + s], ["L", t + r, e + s]) : l < 0 && n > a && h.splice(1, 1, ["L", n - 6, e], ["L", n, e - 6], ["L", n + 6, e], ["L", i - r, e]);
        return h;
      },
      circle: function (t, e, i, s) {
        return e4(t + i / 2, e + s / 2, i / 2, s / 2, {
          start: .5 * Math.PI,
          end: 2.5 * Math.PI,
          open: !1
        });
      },
      diamond: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s / 2], ["L", t + i / 2, e + s], ["L", t, e + s / 2], ["Z"]];
      },
      rect: e7,
      roundedRect: it,
      square: e7,
      triangle: function (t, e, i, s) {
        return [["M", t + i / 2, e], ["L", t + i, e + s], ["L", t, e + s], ["Z"]];
      },
      "triangle-down": function (t, e, i, s) {
        return [["M", t, e], ["L", t + i, e], ["L", t + i / 2, e + s], ["Z"]];
      }
    },
    {
      doc: ii,
      SVG_NS: is,
      win: io
    } = A,
    {
      attr: ir,
      extend: ia,
      fireEvent: il,
      isString: ih,
      objectEach: id,
      pick: ic
    } = J,
    ip = (t, e) => t.substring(0, e) + "…",
    iu = class {
      constructor(t) {
        let e = t.styles;
        this.renderer = t.renderer, this.svgElement = t, this.width = t.textWidth, this.textLineHeight = e && e.lineHeight, this.textOutline = e && e.textOutline, this.ellipsis = !!(e && "ellipsis" === e.textOverflow), this.lineClamp = e?.lineClamp, this.noWrap = !!(e && "nowrap" === e.whiteSpace);
      }
      buildSVG() {
        let t = this.svgElement,
          e = t.element,
          i = t.renderer,
          s = ic(t.textStr, "").toString(),
          o = -1 !== s.indexOf("<"),
          r = e.childNodes,
          a = !t.added && i.box,
          n = [s, this.ellipsis, this.noWrap, this.textLineHeight, this.textOutline, t.getStyle("font-size"), t.styles.lineClamp, this.width].join(",");
        if (n !== t.textCache) {
          t.textCache = n, delete t.actualWidth;
          for (let t = r.length; t--;) e.removeChild(r[t]);
          if (o || this.ellipsis || this.width || t.textPath || -1 !== s.indexOf(" ") && (!this.noWrap || /<br.*?>/g.test(s))) {
            if ("" !== s) {
              a && a.appendChild(e);
              let i = new t3(s);
              this.modifyTree(i.nodes), i.addToDOM(e), this.modifyDOM(), this.ellipsis && -1 !== (e.textContent || "").indexOf("…") && t.attr("title", this.unescapeEntities(t.textStr || "", ["&lt;", "&gt;"])), a && a.removeChild(e);
            }
          } else e.appendChild(ii.createTextNode(this.unescapeEntities(s)));
          ih(this.textOutline) && t.applyTextOutline && t.applyTextOutline(this.textOutline);
        }
      }
      modifyDOM() {
        let t;
        let e = this.svgElement,
          i = ir(e.element, "x");
        for (e.firstLineMetrics = void 0; t = e.element.firstChild;) if (/^[\s\u200B]*$/.test(t.textContent || " ")) e.element.removeChild(t);else break;
        [].forEach.call(e.element.querySelectorAll("tspan.highcharts-br"), (t, s) => {
          t.nextSibling && t.previousSibling && (0 === s && 1 === t.previousSibling.nodeType && (e.firstLineMetrics = e.renderer.fontMetrics(t.previousSibling)), ir(t, {
            dy: this.getLineHeight(t.nextSibling),
            x: i
          }));
        });
        let s = this.width || 0;
        if (!s) return;
        let o = (t, o) => {
            let r = t.textContent || "",
              a = r.replace(/([^\^])-/g, "$1- ").split(" "),
              n = !this.noWrap && (a.length > 1 || e.element.childNodes.length > 1),
              l = this.getLineHeight(o),
              h = Math.max(0, s - .8 * l),
              d = 0,
              c = e.actualWidth;
            if (n) {
              let r = [],
                n = [];
              for (; o.firstChild && o.firstChild !== t;) n.push(o.firstChild), o.removeChild(o.firstChild);
              for (; a.length;) if (a.length && !this.noWrap && d > 0 && (r.push(t.textContent || ""), t.textContent = a.join(" ").replace(/- /g, "-")), this.truncate(t, void 0, a, 0 === d && c || 0, s, h, (t, e) => a.slice(0, e).join(" ").replace(/- /g, "-")), c = e.actualWidth, d++, this.lineClamp && d >= this.lineClamp) {
                a.length && (this.truncate(t, t.textContent || "", void 0, 0, s, h, ip), t.textContent = t.textContent?.replace("…", "") + "…");
                break;
              }
              n.forEach(e => {
                o.insertBefore(e, t);
              }), r.forEach(e => {
                o.insertBefore(ii.createTextNode(e), t);
                let s = ii.createElementNS(is, "tspan");
                s.textContent = "​", ir(s, {
                  dy: l,
                  x: i
                }), o.insertBefore(s, t);
              });
            } else this.ellipsis && r && this.truncate(t, r, void 0, 0, s, h, ip);
          },
          r = t => {
            [].slice.call(t.childNodes).forEach(i => {
              i.nodeType === io.Node.TEXT_NODE ? o(i, t) : (-1 !== i.className.baseVal.indexOf("highcharts-br") && (e.actualWidth = 0), r(i));
            });
          };
        r(e.element);
      }
      getLineHeight(t) {
        let e = t.nodeType === io.Node.TEXT_NODE ? t.parentElement : t;
        return this.textLineHeight ? parseInt(this.textLineHeight.toString(), 10) : this.renderer.fontMetrics(e || this.svgElement.element).h;
      }
      modifyTree(t) {
        let e = (i, s) => {
          let {
              attributes: o = {},
              children: r,
              style: a = {},
              tagName: n
            } = i,
            l = this.renderer.styledMode;
          if ("b" === n || "strong" === n ? l ? o.class = "highcharts-strong" : a.fontWeight = "bold" : ("i" === n || "em" === n) && (l ? o.class = "highcharts-emphasized" : a.fontStyle = "italic"), a && a.color && (a.fill = a.color), "br" === n) {
            o.class = "highcharts-br", i.textContent = "​";
            let e = t[s + 1];
            e && e.textContent && (e.textContent = e.textContent.replace(/^ +/gm, ""));
          } else "a" === n && r && r.some(t => "#text" === t.tagName) && (i.children = [{
            children: r,
            tagName: "tspan"
          }]);
          "#text" !== n && "a" !== n && (i.tagName = "tspan"), ia(i, {
            attributes: o,
            style: a
          }), r && r.filter(t => "#text" !== t.tagName).forEach(e);
        };
        t.forEach(e), il(this.svgElement, "afterModifyTree", {
          nodes: t
        });
      }
      truncate(t, e, i, s, o, r, a) {
        let n, l;
        let h = this.svgElement,
          {
            rotation: d
          } = h,
          c = [],
          p = i && !s ? 1 : 0,
          u = (e || i || "").length,
          g = u;
        i || (o = r);
        let m = function (e, o) {
          let r = o || e,
            a = t.parentNode;
          if (a && void 0 === c[r] && a.getSubStringLength) try {
            c[r] = s + a.getSubStringLength(0, i ? r + 1 : r);
          } catch (t) {}
          return c[r];
        };
        if (h.rotation = 0, s + (l = m(t.textContent.length)) > o) {
          for (; p <= u;) g = Math.ceil((p + u) / 2), i && (n = a(i, g)), l = m(g, n && n.length - 1), p === u ? p = u + 1 : l > o ? u = g - 1 : p = g;
          0 === u ? t.textContent = "" : e && u === e.length - 1 || (t.textContent = n || a(e || i, g)), this.ellipsis && l > o && this.truncate(t, t.textContent || "", void 0, 0, o, r, ip);
        }
        i && i.splice(0, g), h.actualWidth = l, h.rotation = d;
      }
      unescapeEntities(t, e) {
        return id(this.renderer.escapes, function (i, s) {
          e && -1 !== e.indexOf(i) || (t = t.toString().replace(RegExp(i, "g"), s));
        }), t;
      }
    },
    {
      defaultOptions: ig
    } = tw,
    {
      charts: im,
      deg2rad: ix,
      doc: iy,
      isFirefox: ib,
      isMS: iv,
      isWebKit: iM,
      noop: iw,
      SVG_NS: ik,
      symbolSizes: iS,
      win: iT
    } = A,
    {
      addEvent: iC,
      attr: iA,
      createElement: iP,
      crisp: iL,
      css: iO,
      defined: iE,
      destroyObjectProperties: iI,
      extend: iD,
      isArray: iB,
      isNumber: iz,
      isObject: iN,
      isString: iR,
      merge: iW,
      pick: ij,
      pInt: iX,
      replaceNested: iG,
      uniqueKey: iF
    } = J;
  class iH {
    constructor(t, e, i, s, o, r, a) {
      let n, l;
      let h = this.createElement("svg").attr({
          version: "1.1",
          class: "highcharts-root"
        }),
        d = h.element;
      a || h.css(this.getStyle(s || {})), t.appendChild(d), iA(t, "dir", "ltr"), -1 === t.innerHTML.indexOf("xmlns") && iA(d, "xmlns", this.SVG_NS), this.box = d, this.boxWrapper = h, this.alignedObjects = [], this.url = this.getReferenceURL(), this.createElement("desc").add().element.appendChild(iy.createTextNode("Created with Highcharts 12.1.2")), this.defs = this.createElement("defs").add(), this.allowHTML = r, this.forExport = o, this.styledMode = a, this.gradients = {}, this.cache = {}, this.cacheKeys = [], this.imgCount = 0, this.rootFontSize = h.getStyle("font-size"), this.setSize(e, i, !1), ib && t.getBoundingClientRect && ((n = function () {
        iO(t, {
          left: 0,
          top: 0
        }), l = t.getBoundingClientRect(), iO(t, {
          left: Math.ceil(l.left) - l.left + "px",
          top: Math.ceil(l.top) - l.top + "px"
        });
      })(), this.unSubPixelFix = iC(iT, "resize", n));
    }
    definition(t) {
      return new t3([t]).addToDOM(this.defs.element);
    }
    getReferenceURL() {
      if ((ib || iM) && iy.getElementsByTagName("base").length) {
        if (!iE(e)) {
          let t = iF(),
            i = new t3([{
              tagName: "svg",
              attributes: {
                width: 8,
                height: 8
              },
              children: [{
                tagName: "defs",
                children: [{
                  tagName: "clipPath",
                  attributes: {
                    id: t
                  },
                  children: [{
                    tagName: "rect",
                    attributes: {
                      width: 4,
                      height: 4
                    }
                  }]
                }]
              }, {
                tagName: "rect",
                attributes: {
                  id: "hitme",
                  width: 8,
                  height: 8,
                  "clip-path": `url(#${t})`,
                  fill: "rgba(0,0,0,0.001)"
                }
              }]
            }]).addToDOM(iy.body);
          iO(i, {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9e5
          });
          let s = iy.elementFromPoint(6, 6);
          e = "hitme" === (s && s.id), iy.body.removeChild(i);
        }
        if (e) return iG(iT.location.href.split("#")[0], [/<[^>]*>/g, ""], [/([\('\)])/g, "\\$1"], [/ /g, "%20"]);
      }
      return "";
    }
    getStyle(t) {
      return this.style = iD({
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
        fontSize: "1rem"
      }, t), this.style;
    }
    setStyle(t) {
      this.boxWrapper.css(this.getStyle(t));
    }
    isHidden() {
      return !this.boxWrapper.getBBox().width;
    }
    destroy() {
      let t = this.defs;
      return this.box = null, this.boxWrapper = this.boxWrapper.destroy(), iI(this.gradients || {}), this.gradients = null, this.defs = t.destroy(), this.unSubPixelFix && this.unSubPixelFix(), this.alignedObjects = null, null;
    }
    createElement(t) {
      return new this.Element(this, t);
    }
    getRadialAttr(t, e) {
      return {
        cx: t[0] - t[2] / 2 + (e.cx || 0) * t[2],
        cy: t[1] - t[2] / 2 + (e.cy || 0) * t[2],
        r: (e.r || 0) * t[2]
      };
    }
    shadowDefinition(t) {
      let e = [`highcharts-drop-shadow-${this.chartIndex}`, ...Object.keys(t).map(e => `${e}-${t[e]}`)].join("-").toLowerCase().replace(/[^a-z\d\-]/g, ""),
        i = iW({
          color: "#000000",
          offsetX: 1,
          offsetY: 1,
          opacity: .15,
          width: 5
        }, t);
      return this.defs.element.querySelector(`#${e}`) || this.definition({
        tagName: "filter",
        attributes: {
          id: e,
          filterUnits: i.filterUnits
        },
        children: this.getShadowFilterContent(i)
      }), e;
    }
    getShadowFilterContent(t) {
      return [{
        tagName: "feDropShadow",
        attributes: {
          dx: t.offsetX,
          dy: t.offsetY,
          "flood-color": t.color,
          "flood-opacity": Math.min(5 * t.opacity, 1),
          stdDeviation: t.width / 2
        }
      }];
    }
    buildText(t) {
      new iu(t).buildSVG();
    }
    getContrast(t) {
      let e = tA.parse(t).rgba.map(t => {
          let e = t / 255;
          return e <= .03928 ? e / 12.92 : Math.pow((e + .055) / 1.055, 2.4);
        }),
        i = .2126 * e[0] + .7152 * e[1] + .0722 * e[2];
      return 1.05 / (i + .05) > (i + .05) / .05 ? "#FFFFFF" : "#000000";
    }
    button(t, e, i, s, o = {}, r, a, n, l, h) {
      let d = this.label(t, e, i, l, void 0, void 0, h, void 0, "button"),
        c = this.styledMode,
        p = arguments,
        u = 0;
      o = iW(ig.global.buttonTheme, o), c && (delete o.fill, delete o.stroke, delete o["stroke-width"]);
      let g = o.states || {},
        m = o.style || {};
      delete o.states, delete o.style;
      let f = [t3.filterUserAttributes(o)],
        x = [m];
      return c || ["hover", "select", "disabled"].forEach((t, e) => {
        f.push(iW(f[0], t3.filterUserAttributes(p[e + 5] || g[t] || {}))), x.push(f[e + 1].style), delete f[e + 1].style;
      }), iC(d.element, iv ? "mouseover" : "mouseenter", function () {
        3 !== u && d.setState(1);
      }), iC(d.element, iv ? "mouseout" : "mouseleave", function () {
        3 !== u && d.setState(u);
      }), d.setState = (t = 0) => {
        if (1 !== t && (d.state = u = t), d.removeClass(/highcharts-button-(normal|hover|pressed|disabled)/).addClass("highcharts-button-" + ["normal", "hover", "pressed", "disabled"][t]), !c) {
          d.attr(f[t]);
          let e = x[t];
          iN(e) && d.css(e);
        }
      }, d.attr(f[0]), !c && (d.css(iD({
        cursor: "default"
      }, m)), h && d.text.css({
        pointerEvents: "none"
      })), d.on("touchstart", t => t.stopPropagation()).on("click", function (t) {
        3 !== u && s.call(d, t);
      });
    }
    crispLine(t, e) {
      let [i, s] = t;
      return iE(i[1]) && i[1] === s[1] && (i[1] = s[1] = iL(i[1], e)), iE(i[2]) && i[2] === s[2] && (i[2] = s[2] = iL(i[2], e)), t;
    }
    path(t) {
      let e = this.styledMode ? {} : {
        fill: "none"
      };
      return iB(t) ? e.d = t : iN(t) && iD(e, t), this.createElement("path").attr(e);
    }
    circle(t, e, i) {
      let s = iN(t) ? t : void 0 === t ? {} : {
          x: t,
          y: e,
          r: i
        },
        o = this.createElement("circle");
      return o.xSetter = o.ySetter = function (t, e, i) {
        i.setAttribute("c" + e, t);
      }, o.attr(s);
    }
    arc(t, e, i, s, o, r) {
      let a;
      iN(t) ? (e = (a = t).y, i = a.r, s = a.innerR, o = a.start, r = a.end, t = a.x) : a = {
        innerR: s,
        start: o,
        end: r
      };
      let n = this.symbol("arc", t, e, i, i, a);
      return n.r = i, n;
    }
    rect(t, e, i, s, o, r) {
      let a = iN(t) ? t : void 0 === t ? {} : {
          x: t,
          y: e,
          r: o,
          width: Math.max(i || 0, 0),
          height: Math.max(s || 0, 0)
        },
        n = this.createElement("rect");
      return this.styledMode || (void 0 !== r && (a["stroke-width"] = r, iD(a, n.crisp(a))), a.fill = "none"), n.rSetter = function (t, e, i) {
        n.r = t, iA(i, {
          rx: t,
          ry: t
        });
      }, n.rGetter = function () {
        return n.r || 0;
      }, n.attr(a);
    }
    roundedRect(t) {
      return this.symbol("roundedRect").attr(t);
    }
    setSize(t, e, i) {
      this.width = t, this.height = e, this.boxWrapper.animate({
        width: t,
        height: e
      }, {
        step: function () {
          this.attr({
            viewBox: "0 0 " + this.attr("width") + " " + this.attr("height")
          });
        },
        duration: ij(i, !0) ? void 0 : 0
      }), this.alignElements();
    }
    g(t) {
      let e = this.createElement("g");
      return t ? e.attr({
        class: "highcharts-" + t
      }) : e;
    }
    image(t, e, i, s, o, r) {
      let a = {
        preserveAspectRatio: "none"
      };
      iz(e) && (a.x = e), iz(i) && (a.y = i), iz(s) && (a.width = s), iz(o) && (a.height = o);
      let n = this.createElement("image").attr(a),
        l = function (e) {
          n.attr({
            href: t
          }), r.call(n, e);
        };
      if (r) {
        n.attr({
          href: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        });
        let e = new iT.Image();
        iC(e, "load", l), e.src = t, e.complete && l({});
      } else n.attr({
        href: t
      });
      return n;
    }
    symbol(t, e, i, s, o, r) {
      let a, n, l, h;
      let d = this,
        c = /^url\((.*?)\)$/,
        p = c.test(t),
        u = !p && (this.symbols[t] ? t : "circle"),
        g = u && this.symbols[u];
      if (g) "number" == typeof e && (n = g.call(this.symbols, e || 0, i || 0, s || 0, o || 0, r)), a = this.path(n), d.styledMode || a.attr("fill", "none"), iD(a, {
        symbolName: u || void 0,
        x: e,
        y: i,
        width: s,
        height: o
      }), r && iD(a, r);else if (p) {
        l = t.match(c)[1];
        let s = a = this.image(l);
        s.imgwidth = ij(r && r.width, iS[l] && iS[l].width), s.imgheight = ij(r && r.height, iS[l] && iS[l].height), h = t => t.attr({
          width: t.width,
          height: t.height
        }), ["width", "height"].forEach(t => {
          s[`${t}Setter`] = function (t, e) {
            this[e] = t;
            let {
                alignByTranslate: i,
                element: s,
                width: o,
                height: a,
                imgwidth: n,
                imgheight: l
              } = this,
              h = "width" === e ? n : l,
              d = 1;
            r && "within" === r.backgroundSize && o && a && n && l ? (d = Math.min(o / n, a / l), iA(s, {
              width: Math.round(n * d),
              height: Math.round(l * d)
            })) : s && h && s.setAttribute(e, h), !i && n && l && this.translate(((o || 0) - n * d) / 2, ((a || 0) - l * d) / 2);
          };
        }), iE(e) && s.attr({
          x: e,
          y: i
        }), s.isImg = !0, s.symbolUrl = t, iE(s.imgwidth) && iE(s.imgheight) ? h(s) : (s.attr({
          width: 0,
          height: 0
        }), iP("img", {
          onload: function () {
            let t = im[d.chartIndex];
            0 === this.width && (iO(this, {
              position: "absolute",
              top: "-999em"
            }), iy.body.appendChild(this)), iS[l] = {
              width: this.width,
              height: this.height
            }, s.imgwidth = this.width, s.imgheight = this.height, s.element && h(s), this.parentNode && this.parentNode.removeChild(this), d.imgCount--, d.imgCount || !t || t.hasLoaded || t.onload();
          },
          src: l
        }), this.imgCount++);
      }
      return a;
    }
    clipRect(t, e, i, s) {
      return this.rect(t, e, i, s, 0);
    }
    text(t, e, i, s) {
      let o = {};
      if (s && (this.allowHTML || !this.forExport)) return this.html(t, e, i);
      o.x = Math.round(e || 0), i && (o.y = Math.round(i)), iE(t) && (o.text = t);
      let r = this.createElement("text").attr(o);
      return s && (!this.forExport || this.allowHTML) || (r.xSetter = function (t, e, i) {
        let s = i.getElementsByTagName("tspan"),
          o = i.getAttribute(e);
        for (let i = 0, r; i < s.length; i++) (r = s[i]).getAttribute(e) === o && r.setAttribute(e, t);
        i.setAttribute(e, t);
      }), r;
    }
    fontMetrics(t) {
      let e = iX(eq.prototype.getStyle.call(t, "font-size") || 0),
        i = e < 24 ? e + 3 : Math.round(1.2 * e),
        s = Math.round(.8 * i);
      return {
        h: i,
        b: s,
        f: e
      };
    }
    rotCorr(t, e, i) {
      let s = t;
      return e && i && (s = Math.max(s * Math.cos(e * ix), 4)), {
        x: -t / 3 * Math.sin(e * ix),
        y: s
      };
    }
    pathToSegments(t) {
      let e = [],
        i = [],
        s = {
          A: 8,
          C: 7,
          H: 2,
          L: 3,
          M: 3,
          Q: 5,
          S: 5,
          T: 3,
          V: 2
        };
      for (let o = 0; o < t.length; o++) iR(i[0]) && iz(t[o]) && i.length === s[i[0].toUpperCase()] && t.splice(o, 0, i[0].replace("M", "L").replace("m", "l")), "string" == typeof t[o] && (i.length && e.push(i.slice(0)), i.length = 0), i.push(t[o]);
      return e.push(i.slice(0)), e;
    }
    label(t, e, i, s, o, r, a, n, l) {
      return new e6(this, t, e, i, s, o, r, a, n, l);
    }
    alignElements() {
      this.alignedObjects.forEach(t => t.align());
    }
  }
  iD(iH.prototype, {
    Element: eq,
    SVG_NS: ik,
    escapes: {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      "'": "&#39;",
      '"': "&quot;"
    },
    symbols: ie,
    draw: iw
  }), ec.registerRendererType("svg", iH, !0);
  let {
      composed: iY
    } = A,
    {
      attr: iV,
      css: iU,
      createElement: iZ,
      defined: i$,
      extend: i_,
      getAlignFactor: iq,
      isNumber: iK,
      pInt: iJ,
      pushUnique: iQ
    } = J;
  function i0(t, e, i) {
    let s = this.div?.style || i.style;
    eq.prototype[`${e}Setter`].call(this, t, e, i), s && (s[e] = t);
  }
  let i1 = (t, e) => {
    if (!t.div) {
      let i = iV(t.element, "class"),
        s = t.css,
        o = iZ("div", i ? {
          className: i
        } : void 0, {
          position: "absolute",
          left: `${t.translateX || 0}px`,
          top: `${t.translateY || 0}px`,
          ...t.styles,
          display: t.display,
          opacity: t.opacity,
          visibility: t.visibility
        }, t.parentGroup?.div || e);
      t.classSetter = (t, e, i) => {
        i.setAttribute("class", t), o.className = t;
      }, t.translateXSetter = t.translateYSetter = (e, i) => {
        t[i] = e, o.style["translateX" === i ? "left" : "top"] = `${e}px`, t.doTransform = !0;
      }, t.opacitySetter = t.visibilitySetter = i0, t.css = e => (s.call(t, e), e.cursor && (o.style.cursor = e.cursor), e.pointerEvents && (o.style.pointerEvents = e.pointerEvents), t), t.on = function () {
        return eq.prototype.on.apply({
          element: o,
          onEvents: t.onEvents
        }, arguments), t;
      }, t.div = o;
    }
    return t.div;
  };
  class i2 extends eq {
    static compose(t) {
      iQ(iY, this.compose) && (t.prototype.html = function (t, e, i) {
        return new i2(this, "span").attr({
          text: t,
          x: Math.round(e),
          y: Math.round(i)
        });
      });
    }
    constructor(t, e) {
      super(t, e), this.css({
        position: "absolute",
        ...(t.styledMode ? {} : {
          fontFamily: t.style.fontFamily,
          fontSize: t.style.fontSize
        })
      });
    }
    getSpanCorrection(t, e, i) {
      this.xCorr = -t * i, this.yCorr = -e;
    }
    css(t) {
      let e;
      let {
          element: i
        } = this,
        s = "SPAN" === i.tagName && t && "width" in t,
        o = s && t.width;
      return s && (delete t.width, this.textWidth = iJ(o) || void 0, e = !0), t?.textOverflow === "ellipsis" && (t.overflow = "hidden"), t?.lineClamp && (t.display = "-webkit-box", t.WebkitLineClamp = t.lineClamp, t.WebkitBoxOrient = "vertical", t.overflow = "hidden"), iK(Number(t?.fontSize)) && (t.fontSize = t.fontSize + "px"), i_(this.styles, t), iU(i, t), e && this.updateTransform(), this;
    }
    htmlGetBBox() {
      let {
        element: t
      } = this;
      return {
        x: t.offsetLeft,
        y: t.offsetTop,
        width: t.offsetWidth,
        height: t.offsetHeight
      };
    }
    updateTransform() {
      if (!this.added) {
        this.alignOnAdd = !0;
        return;
      }
      let {
          element: t,
          renderer: e,
          rotation: i,
          rotationOriginX: s,
          rotationOriginY: o,
          scaleX: r,
          scaleY: a,
          styles: n,
          textAlign: l = "left",
          textWidth: h,
          translateX: d = 0,
          translateY: c = 0,
          x: p = 0,
          y: u = 0
        } = this,
        {
          display: g = "block",
          whiteSpace: m
        } = n;
      if (iU(t, {
        marginLeft: `${d}px`,
        marginTop: `${c}px`
      }), "SPAN" === t.tagName) {
        let n;
        let d = [i, l, t.innerHTML, h, this.textAlign].join(","),
          c = -(this.parentGroup?.padding * 1) || 0;
        if (h !== this.oldTextWidth) {
          let e = this.textPxLength ? this.textPxLength : (iU(t, {
              width: "",
              whiteSpace: m || "nowrap"
            }), t.offsetWidth),
            s = h || 0;
          (s > this.oldTextWidth || e > s) && (/[ \-]/.test(t.textContent || t.innerText) || "ellipsis" === t.style.textOverflow) && (iU(t, {
            width: e > s || i || r ? h + "px" : "auto",
            display: g,
            whiteSpace: m || "normal"
          }), this.oldTextWidth = h);
        }
        d !== this.cTT && (n = e.fontMetrics(t).b, i$(i) && (i !== (this.oldRotation || 0) || l !== this.oldAlign) && this.setSpanRotation(i, c, c), this.getSpanCorrection(!i$(i) && !this.textWidth && this.textPxLength || t.offsetWidth, n, iq(l)));
        let {
            xCorr: f = 0,
            yCorr: x = 0
          } = this,
          y = {
            left: `${p + f}px`,
            top: `${u + x}px`,
            textAlign: l,
            transformOrigin: `${(s ?? p) - f - p - c}px ${(o ?? u) - x - u - c}px`
          };
        (r || a) && (y.transform = `scale(${r ?? 1},${a ?? 1})`), iU(t, y), this.cTT = d, this.oldRotation = i, this.oldAlign = l;
      }
    }
    setSpanRotation(t, e, i) {
      iU(this.element, {
        transform: `rotate(${t}deg)`,
        transformOrigin: `${e}% ${i}px`
      });
    }
    add(t) {
      let e;
      let i = this.renderer.box.parentNode,
        s = [];
      if (this.parentGroup = t, t && !(e = t.div)) {
        let o = t;
        for (; o;) s.push(o), o = o.parentGroup;
        for (let t of s.reverse()) e = i1(t, i);
      }
      return (e || i).appendChild(this.element), this.added = !0, this.alignOnAdd && this.updateTransform(), this;
    }
    textSetter(t) {
      t !== this.textStr && (delete this.bBox, delete this.oldTextWidth, t3.setElementHTML(this.element, t ?? ""), this.textStr = t, this.doTransform = !0);
    }
    alignSetter(t) {
      this.alignValue = this.textAlign = t, this.doTransform = !0;
    }
    xSetter(t, e) {
      this[e] = t, this.doTransform = !0;
    }
  }
  let i3 = i2.prototype;
  i3.visibilitySetter = i3.opacitySetter = i0, i3.ySetter = i3.rotationSetter = i3.rotationOriginXSetter = i3.rotationOriginYSetter = i3.xSetter, function (t) {
    t.xAxis = {
      alignTicks: !0,
      allowDecimals: void 0,
      panningEnabled: !0,
      zIndex: 2,
      zoomEnabled: !0,
      dateTimeLabelFormats: {
        millisecond: {
          main: "%[HMSL]",
          range: !1
        },
        second: {
          main: "%[HMS]",
          range: !1
        },
        minute: {
          main: "%[HM]",
          range: !1
        },
        hour: {
          main: "%[HM]",
          range: !1
        },
        day: {
          main: "%[eb]"
        },
        week: {
          main: "%[eb]"
        },
        month: {
          main: "%[bY]"
        },
        year: {
          main: "%Y"
        }
      },
      endOnTick: !1,
      gridLineDashStyle: "Solid",
      gridZIndex: 1,
      labels: {
        autoRotationLimit: 80,
        distance: 15,
        enabled: !0,
        indentation: 10,
        overflow: "justify",
        reserveSpace: void 0,
        rotation: void 0,
        staggerLines: 0,
        step: 0,
        useHTML: !1,
        zIndex: 7,
        style: {
          color: "#333333",
          cursor: "default",
          fontSize: "0.8em",
          textOverflow: "ellipsis"
        }
      },
      maxPadding: .01,
      minorGridLineDashStyle: "Solid",
      minorTickLength: 2,
      minorTickPosition: "outside",
      minorTicksPerMajor: 5,
      minPadding: .01,
      offset: void 0,
      reversed: void 0,
      reversedStacks: !1,
      showEmpty: !0,
      showFirstLabel: !0,
      showLastLabel: !0,
      startOfWeek: 1,
      startOnTick: !1,
      tickLength: 10,
      tickPixelInterval: 100,
      tickmarkPlacement: "between",
      tickPosition: "outside",
      title: {
        align: "middle",
        useHTML: !1,
        x: 0,
        y: 0,
        style: {
          color: "#666666",
          fontSize: "0.8em"
        }
      },
      visible: !0,
      minorGridLineColor: "#f2f2f2",
      minorGridLineWidth: 1,
      minorTickColor: "#999999",
      lineColor: "#333333",
      lineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineWidth: void 0,
      tickColor: "#333333"
    }, t.yAxis = {
      reversedStacks: !0,
      endOnTick: !0,
      maxPadding: .05,
      minPadding: .05,
      tickPixelInterval: 72,
      showLastLabel: !0,
      labels: {
        x: void 0
      },
      startOnTick: !0,
      title: {
        text: "Values"
      },
      stackLabels: {
        animation: {},
        allowOverlap: !1,
        enabled: !1,
        crop: !0,
        overflow: "justify",
        formatter: function () {
          let {
            numberFormatter: t
          } = this.axis.chart;
          return t(this.total || 0, -1);
        },
        style: {
          color: "#000000",
          fontSize: "0.7em",
          fontWeight: "bold",
          textOutline: "1px contrast"
        }
      },
      gridLineWidth: 1,
      lineWidth: 0
    };
  }(a || (a = {}));
  let i6 = a,
    {
      addEvent: i5,
      isFunction: i9,
      objectEach: i8,
      removeEvent: i4
    } = J;
  (n || (n = {})).registerEventOptions = function (t, e) {
    t.eventOptions = t.eventOptions || {}, i8(e.events, function (e, i) {
      t.eventOptions[i] !== e && (t.eventOptions[i] && (i4(t, i, t.eventOptions[i]), delete t.eventOptions[i]), i9(e) && (t.eventOptions[i] = e, i5(t, i, e, {
        order: 0
      })));
    });
  };
  let i7 = n,
    {
      deg2rad: st
    } = A,
    {
      clamp: se,
      correctFloat: si,
      defined: ss,
      destroyObjectProperties: so,
      extend: sr,
      fireEvent: sa,
      getAlignFactor: sn,
      isNumber: sl,
      merge: sh,
      objectEach: sd,
      pick: sc
    } = J,
    sp = class {
      constructor(t, e, i, s, o) {
        this.isNew = !0, this.isNewLabel = !0, this.axis = t, this.pos = e, this.type = i || "", this.parameters = o || {}, this.tickmarkOffset = this.parameters.tickmarkOffset, this.options = this.parameters.options, sa(this, "init"), i || s || this.addLabel();
      }
      addLabel() {
        let t = this,
          e = t.axis,
          i = e.options,
          s = e.chart,
          o = e.categories,
          r = e.logarithmic,
          a = e.names,
          n = t.pos,
          l = sc(t.options && t.options.labels, i.labels),
          h = e.tickPositions,
          d = n === h[0],
          c = n === h[h.length - 1],
          p = (!l.step || 1 === l.step) && 1 === e.tickInterval,
          u = h.info,
          g = t.label,
          m,
          f,
          x,
          y = this.parameters.category || (o ? sc(o[n], a[n], n) : n);
        r && sl(y) && (y = si(r.lin2log(y))), e.dateTime && (u ? m = (f = s.time.resolveDTLFormat(i.dateTimeLabelFormats[!i.grid && u.higherRanks[n] || u.unitName])).main : sl(y) && (m = e.dateTime.getXDateFormat(y, i.dateTimeLabelFormats || {}))), t.isFirst = d, t.isLast = c;
        let b = {
          axis: e,
          chart: s,
          dateTimeLabelFormat: m,
          isFirst: d,
          isLast: c,
          pos: n,
          tick: t,
          tickPositionInfo: u,
          value: y
        };
        sa(this, "labelFormat", b);
        let v = t => l.formatter ? l.formatter.call(t, t) : l.format ? (t.text = e.defaultLabelFormatter.call(t), ed.format(l.format, t, s)) : e.defaultLabelFormatter.call(t),
          M = v.call(b, b),
          w = f && f.list;
        w ? t.shortenLabel = function () {
          for (x = 0; x < w.length; x++) if (sr(b, {
            dateTimeLabelFormat: w[x]
          }), g.attr({
            text: v.call(b, b)
          }), g.getBBox().width < e.getSlotWidth(t) - 2 * (l.padding || 0)) return;
          g.attr({
            text: ""
          });
        } : t.shortenLabel = void 0, p && e._addedPlotLB && t.moveLabel(M, l), ss(g) || t.movedLabel ? g && g.textStr !== M && !p && (!g.textWidth || l.style.width || g.styles.width || g.css({
          width: null
        }), g.attr({
          text: M
        }), g.textPxLength = g.getBBox().width) : (t.label = g = t.createLabel(M, l), t.rotation = 0);
      }
      createLabel(t, e, i) {
        let s = this.axis,
          {
            renderer: o,
            styledMode: r
          } = s.chart,
          a = ss(t) && e.enabled ? o.text(t, i?.x, i?.y, e.useHTML).add(s.labelGroup) : void 0;
        if (a) {
          let t = e.style.whiteSpace || "normal";
          r || a.css(sh(e.style, {
            whiteSpace: "nowrap"
          })), a.textPxLength = a.getBBox().width, r || a.css({
            whiteSpace: t
          });
        }
        return a;
      }
      destroy() {
        so(this, this.axis);
      }
      getPosition(t, e, i, s) {
        let o = this.axis,
          r = o.chart,
          a = s && r.oldChartHeight || r.chartHeight,
          n = {
            x: t ? si(o.translate(e + i, void 0, void 0, s) + o.transB) : o.left + o.offset + (o.opposite ? (s && r.oldChartWidth || r.chartWidth) - o.right - o.left : 0),
            y: t ? a - o.bottom + o.offset - (o.opposite ? o.height : 0) : si(a - o.translate(e + i, void 0, void 0, s) - o.transB)
          };
        return n.y = se(n.y, -1e9, 1e9), sa(this, "afterGetPosition", {
          pos: n
        }), n;
      }
      getLabelPosition(t, e, i, s, o, r, a, n) {
        let l, h;
        let d = this.axis,
          c = d.transA,
          p = d.isLinked && d.linkedParent ? d.linkedParent.reversed : d.reversed,
          u = d.staggerLines,
          g = d.tickRotCorr || {
            x: 0,
            y: 0
          },
          m = s || d.reserveSpaceDefault ? 0 : -d.labelOffset * ("center" === d.labelAlign ? .5 : 1),
          f = o.distance,
          x = {};
        return l = 0 === d.side ? i.rotation ? -f : -i.getBBox().height : 2 === d.side ? g.y + f : Math.cos(i.rotation * st) * (g.y - i.getBBox(!1, 0).height / 2), ss(o.y) && (l = 0 === d.side && d.horiz ? o.y + l : o.y), t = t + sc(o.x, [0, 1, 0, -1][d.side] * f) + m + g.x - (r && s ? r * c * (p ? -1 : 1) : 0), e = e + l - (r && !s ? r * c * (p ? 1 : -1) : 0), u && (h = a / (n || 1) % u, d.opposite && (h = u - h - 1), e += h * (d.labelOffset / u)), x.x = t, x.y = Math.round(e), sa(this, "afterGetLabelPosition", {
          pos: x,
          tickmarkOffset: r,
          index: a
        }), x;
      }
      getLabelSize() {
        return this.label ? this.label.getBBox()[this.axis.horiz ? "height" : "width"] : 0;
      }
      getMarkPath(t, e, i, s, o = !1, r) {
        return r.crispLine([["M", t, e], ["L", t + (o ? 0 : -i), e + (o ? i : 0)]], s);
      }
      handleOverflow(t) {
        let e = this.axis,
          i = e.options.labels,
          s = t.x,
          o = e.chart.chartWidth,
          r = e.chart.spacing,
          a = sc(e.labelLeft, Math.min(e.pos, r[3])),
          n = sc(e.labelRight, Math.max(e.isRadial ? 0 : e.pos + e.len, o - r[1])),
          l = this.label,
          h = this.rotation,
          d = sn(e.labelAlign || l.attr("align")),
          c = l.getBBox().width,
          p = e.getSlotWidth(this),
          u = p,
          g = 1,
          m,
          f,
          x;
        h || "justify" !== i.overflow ? h < 0 && s - d * c < a ? x = Math.round(s / Math.cos(h * st) - a) : h > 0 && s + d * c > n && (x = Math.round((o - s) / Math.cos(h * st))) : (m = s - d * c, f = s + (1 - d) * c, m < a ? u = t.x + u * (1 - d) - a : f > n && (u = n - t.x + u * d, g = -1), (u = Math.min(p, u)) < p && "center" === e.labelAlign && (t.x += g * (p - u - d * (p - Math.min(c, u)))), (c > u || e.autoRotation && (l.styles || {}).width) && (x = u)), x && l && (this.shortenLabel ? this.shortenLabel() : l.css(sr({}, {
          width: Math.floor(x) + "px",
          lineClamp: e.isRadial ? 0 : 1
        })));
      }
      moveLabel(t, e) {
        let i = this,
          s = i.label,
          o = i.axis,
          r = !1,
          a;
        s && s.textStr === t ? (i.movedLabel = s, r = !0, delete i.label) : sd(o.ticks, function (e) {
          r || e.isNew || e === i || !e.label || e.label.textStr !== t || (i.movedLabel = e.label, r = !0, e.labelPos = i.movedLabel.xy, delete e.label);
        }), !r && (i.labelPos || s) && (a = i.labelPos || s.xy, i.movedLabel = i.createLabel(t, e, a), i.movedLabel && i.movedLabel.attr({
          opacity: 0
        }));
      }
      render(t, e, i) {
        let s = this.axis,
          o = s.horiz,
          r = this.pos,
          a = sc(this.tickmarkOffset, s.tickmarkOffset),
          n = this.getPosition(o, r, a, e),
          l = n.x,
          h = n.y,
          d = s.pos,
          c = d + s.len,
          p = o ? l : h;
        !s.chart.polar && this.isNew && (si(p) < d || p > c) && (i = 0);
        let u = sc(i, this.label && this.label.newOpacity, 1);
        i = sc(i, 1), this.isActive = !0, this.renderGridLine(e, i), this.renderMark(n, i), this.renderLabel(n, e, u, t), this.isNew = !1, sa(this, "afterRender");
      }
      renderGridLine(t, e) {
        let i = this.axis,
          s = i.options,
          o = {},
          r = this.pos,
          a = this.type,
          n = sc(this.tickmarkOffset, i.tickmarkOffset),
          l = i.chart.renderer,
          h = this.gridLine,
          d,
          c = s.gridLineWidth,
          p = s.gridLineColor,
          u = s.gridLineDashStyle;
        "minor" === this.type && (c = s.minorGridLineWidth, p = s.minorGridLineColor, u = s.minorGridLineDashStyle), h || (i.chart.styledMode || (o.stroke = p, o["stroke-width"] = c || 0, o.dashstyle = u), a || (o.zIndex = 1), t && (e = 0), this.gridLine = h = l.path().attr(o).addClass("highcharts-" + (a ? a + "-" : "") + "grid-line").add(i.gridGroup)), h && (d = i.getPlotLinePath({
          value: r + n,
          lineWidth: h.strokeWidth(),
          force: "pass",
          old: t,
          acrossPanes: !1
        })) && h[t || this.isNew ? "attr" : "animate"]({
          d: d,
          opacity: e
        });
      }
      renderMark(t, e) {
        let i = this.axis,
          s = i.options,
          o = i.chart.renderer,
          r = this.type,
          a = i.tickSize(r ? r + "Tick" : "tick"),
          n = t.x,
          l = t.y,
          h = sc(s["minor" !== r ? "tickWidth" : "minorTickWidth"], !r && i.isXAxis ? 1 : 0),
          d = s["minor" !== r ? "tickColor" : "minorTickColor"],
          c = this.mark,
          p = !c;
        a && (i.opposite && (a[0] = -a[0]), c || (this.mark = c = o.path().addClass("highcharts-" + (r ? r + "-" : "") + "tick").add(i.axisGroup), i.chart.styledMode || c.attr({
          stroke: d,
          "stroke-width": h
        })), c[p ? "attr" : "animate"]({
          d: this.getMarkPath(n, l, a[0], c.strokeWidth(), i.horiz, o),
          opacity: e
        }));
      }
      renderLabel(t, e, i, s) {
        let o = this.axis,
          r = o.horiz,
          a = o.options,
          n = this.label,
          l = a.labels,
          h = l.step,
          d = sc(this.tickmarkOffset, o.tickmarkOffset),
          c = t.x,
          p = t.y,
          u = !0;
        n && sl(c) && (n.xy = t = this.getLabelPosition(c, p, n, r, l, d, s, h), (!this.isFirst || this.isLast || a.showFirstLabel) && (!this.isLast || this.isFirst || a.showLastLabel) ? !r || l.step || l.rotation || e || 0 === i || this.handleOverflow(t) : u = !1, h && s % h && (u = !1), u && sl(t.y) ? (t.opacity = i, n[this.isNewLabel ? "attr" : "animate"](t).show(!0), this.isNewLabel = !1) : (n.hide(), this.isNewLabel = !0));
      }
      replaceMovedLabel() {
        let t = this.label,
          e = this.axis;
        t && !this.isNew && (t.animate({
          opacity: 0
        }, void 0, t.destroy), delete this.label), e.isDirty = !0, this.label = this.movedLabel, delete this.movedLabel;
      }
    },
    {
      animObject: su
    } = tH,
    {
      xAxis: sg,
      yAxis: sm
    } = i6,
    {
      defaultOptions: sf
    } = tw,
    {
      registerEventOptions: sx
    } = i7,
    {
      deg2rad: sy
    } = A,
    {
      arrayMax: sb,
      arrayMin: sv,
      clamp: sM,
      correctFloat: sw,
      defined: sk,
      destroyObjectProperties: sS,
      erase: sT,
      error: sC,
      extend: sA,
      fireEvent: sP,
      getClosestDistance: sL,
      insertItem: sO,
      isArray: sE,
      isNumber: sI,
      isString: sD,
      merge: sB,
      normalizeTickInterval: sz,
      objectEach: sN,
      pick: sR,
      relativeLength: sW,
      removeEvent: sj,
      splat: sX,
      syncTimeout: sG
    } = J,
    sF = (t, e) => sz(e, void 0, void 0, sR(t.options.allowDecimals, e < .5 || void 0 !== t.tickAmount), !!t.tickAmount);
  sA(sf, {
    xAxis: sg,
    yAxis: sB(sg, sm)
  });
  class sH {
    constructor(t, e, i) {
      this.init(t, e, i);
    }
    init(t, e, i = this.coll) {
      let s = "xAxis" === i,
        o = this.isZAxis || (t.inverted ? !s : s);
      this.chart = t, this.horiz = o, this.isXAxis = s, this.coll = i, sP(this, "init", {
        userOptions: e
      }), this.opposite = sR(e.opposite, this.opposite), this.side = sR(e.side, this.side, o ? this.opposite ? 0 : 2 : this.opposite ? 1 : 3), this.setOptions(e);
      let r = this.options,
        a = r.labels;
      this.type ?? (this.type = r.type || "linear"), this.uniqueNames ?? (this.uniqueNames = r.uniqueNames ?? !0), sP(this, "afterSetType"), this.userOptions = e, this.minPixelPadding = 0, this.reversed = sR(r.reversed, this.reversed), this.visible = r.visible, this.zoomEnabled = r.zoomEnabled, this.hasNames = "category" === this.type || !0 === r.categories, this.categories = sE(r.categories) && r.categories || (this.hasNames ? [] : void 0), this.names || (this.names = [], this.names.keys = {}), this.plotLinesAndBandsGroups = {}, this.positiveValuesOnly = !!this.logarithmic, this.isLinked = sk(r.linkedTo), this.ticks = {}, this.labelEdge = [], this.minorTicks = {}, this.plotLinesAndBands = [], this.alternateBands = {}, this.len ?? (this.len = 0), this.minRange = this.userMinRange = r.minRange || r.maxZoom, this.range = r.range, this.offset = r.offset || 0, this.max = void 0, this.min = void 0;
      let n = sR(r.crosshair, sX(t.options.tooltip.crosshairs)[s ? 0 : 1]);
      this.crosshair = !0 === n ? {} : n, -1 === t.axes.indexOf(this) && (s ? t.axes.splice(t.xAxis.length, 0, this) : t.axes.push(this), sO(this, t[this.coll])), t.orderItems(this.coll), this.series = this.series || [], t.inverted && !this.isZAxis && s && !sk(this.reversed) && (this.reversed = !0), this.labelRotation = sI(a.rotation) ? a.rotation : void 0, sx(this, r), sP(this, "afterInit");
    }
    setOptions(t) {
      let e = this.horiz ? {
        labels: {
          autoRotation: [-45],
          padding: 3
        },
        margin: 15
      } : {
        labels: {
          padding: 1
        },
        title: {
          rotation: 90 * this.side
        }
      };
      this.options = sB(e, sf[this.coll], t), sP(this, "afterSetOptions", {
        userOptions: t
      });
    }
    defaultLabelFormatter() {
      let t = this.axis,
        {
          numberFormatter: e
        } = this.chart,
        i = sI(this.value) ? this.value : NaN,
        s = t.chart.time,
        o = t.categories,
        r = this.dateTimeLabelFormat,
        a = sf.lang,
        n = a.numericSymbols,
        l = a.numericSymbolMagnitude || 1e3,
        h = t.logarithmic ? Math.abs(i) : t.tickInterval,
        d = n && n.length,
        c,
        p;
      if (o) p = `${this.value}`;else if (r) p = s.dateFormat(r, i, !0);else if (d && n && h >= 1e3) for (; d-- && void 0 === p;) h >= (c = Math.pow(l, d + 1)) && 10 * i % c == 0 && null !== n[d] && 0 !== i && (p = e(i / c, -1) + n[d]);
      return void 0 === p && (p = Math.abs(i) >= 1e4 ? e(i, -1) : e(i, -1, void 0, "")), p;
    }
    getSeriesExtremes() {
      let t;
      let e = this;
      sP(this, "getSeriesExtremes", null, function () {
        e.hasVisibleSeries = !1, e.dataMin = e.dataMax = e.threshold = void 0, e.softThreshold = !e.isXAxis, e.series.forEach(i => {
          if (i.reserveSpace()) {
            let s = i.options,
              o,
              r = s.threshold,
              a,
              n;
            if (e.hasVisibleSeries = !0, e.positiveValuesOnly && 0 >= (r || 0) && (r = void 0), e.isXAxis) (o = i.getColumn("x")).length && (o = e.logarithmic ? o.filter(t => t > 0) : o, a = (t = i.getXExtremes(o)).min, n = t.max, sI(a) || a instanceof Date || (o = o.filter(sI), a = (t = i.getXExtremes(o)).min, n = t.max), o.length && (e.dataMin = Math.min(sR(e.dataMin, a), a), e.dataMax = Math.max(sR(e.dataMax, n), n)));else {
              let t = i.applyExtremes();
              sI(t.dataMin) && (a = t.dataMin, e.dataMin = Math.min(sR(e.dataMin, a), a)), sI(t.dataMax) && (n = t.dataMax, e.dataMax = Math.max(sR(e.dataMax, n), n)), sk(r) && (e.threshold = r), (!s.softThreshold || e.positiveValuesOnly) && (e.softThreshold = !1);
            }
          }
        });
      }), sP(this, "afterGetSeriesExtremes");
    }
    translate(t, e, i, s, o, r) {
      let a = this.linkedParent || this,
        n = s && a.old ? a.old.min : a.min;
      if (!sI(n)) return NaN;
      let l = a.minPixelPadding,
        h = (a.isOrdinal || a.brokenAxis?.hasBreaks || a.logarithmic && o) && a.lin2val,
        d = 1,
        c = 0,
        p = s && a.old ? a.old.transA : a.transA,
        u = 0;
      return p || (p = a.transA), i && (d *= -1, c = a.len), a.reversed && (d *= -1, c -= d * (a.sector || a.len)), e ? (u = (t = t * d + c - l) / p + n, h && (u = a.lin2val(u))) : (h && (t = a.val2lin(t)), u = d * (t - n) * p + c + d * l + (sI(r) ? p * r : 0), a.isRadial || (u = sw(u))), u;
    }
    toPixels(t, e) {
      return this.translate(this.chart?.time.parse(t) ?? NaN, !1, !this.horiz, void 0, !0) + (e ? 0 : this.pos);
    }
    toValue(t, e) {
      return this.translate(t - (e ? 0 : this.pos), !0, !this.horiz, void 0, !0);
    }
    getPlotLinePath(t) {
      let e = this,
        i = e.chart,
        s = e.left,
        o = e.top,
        r = t.old,
        a = t.value,
        n = t.lineWidth,
        l = r && i.oldChartHeight || i.chartHeight,
        h = r && i.oldChartWidth || i.chartWidth,
        d = e.transB,
        c = t.translatedValue,
        p = t.force,
        u,
        g,
        m,
        f,
        x;
      function y(t, e, i) {
        return "pass" !== p && (t < e || t > i) && (p ? t = sM(t, e, i) : x = !0), t;
      }
      let b = {
        value: a,
        lineWidth: n,
        old: r,
        force: p,
        acrossPanes: t.acrossPanes,
        translatedValue: c
      };
      return sP(this, "getPlotLinePath", b, function (t) {
        u = m = (c = sM(c = sR(c, e.translate(a, void 0, void 0, r)), -1e9, 1e9)) + d, g = f = l - c - d, sI(c) ? e.horiz ? (g = o, f = l - e.bottom + (e.options.isInternal ? 0 : i.scrollablePixelsY || 0), u = m = y(u, s, s + e.width)) : (u = s, m = h - e.right + (i.scrollablePixelsX || 0), g = f = y(g, o, o + e.height)) : (x = !0, p = !1), t.path = x && !p ? void 0 : i.renderer.crispLine([["M", u, g], ["L", m, f]], n || 1);
      }), b.path;
    }
    getLinearTickPositions(t, e, i) {
      let s, o, r;
      let a = sw(Math.floor(e / t) * t),
        n = sw(Math.ceil(i / t) * t),
        l = [];
      if (sw(a + t) === a && (r = 20), this.single) return [e];
      for (s = a; s <= n && (l.push(s), (s = sw(s + t, r)) !== o);) o = s;
      return l;
    }
    getMinorTickInterval() {
      let {
        minorTicks: t,
        minorTickInterval: e
      } = this.options;
      return !0 === t ? sR(e, "auto") : !1 !== t ? e : void 0;
    }
    getMinorTickPositions() {
      let t = this.options,
        e = this.tickPositions,
        i = this.minorTickInterval,
        s = this.pointRangePadding || 0,
        o = (this.min || 0) - s,
        r = (this.max || 0) + s,
        a = this.brokenAxis?.hasBreaks ? this.brokenAxis.unitLength : r - o,
        n = [],
        l;
      if (a && a / i < this.len / 3) {
        let s = this.logarithmic;
        if (s) this.paddedTicks.forEach(function (t, e, o) {
          e && n.push.apply(n, s.getLogTickPositions(i, o[e - 1], o[e], !0));
        });else if (this.dateTime && "auto" === this.getMinorTickInterval()) n = n.concat(this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(i), o, r, t.startOfWeek));else for (l = o + (e[0] - o) % i; l <= r && l !== n[0]; l += i) n.push(l);
      }
      return 0 !== n.length && this.trimTicks(n), n;
    }
    adjustForMinRange() {
      let t = this.options,
        e = this.logarithmic,
        i = this.chart.time,
        {
          max: s,
          min: o,
          minRange: r
        } = this,
        a,
        n,
        l,
        h;
      this.isXAxis && void 0 === r && !e && (r = sk(t.min) || sk(t.max) || sk(t.floor) || sk(t.ceiling) ? null : Math.min(5 * (sL(this.series.map(t => {
        let e = t.getColumn("x");
        return t.xIncrement ? e.slice(0, 2) : e;
      })) || 0), this.dataMax - this.dataMin)), sI(s) && sI(o) && sI(r) && s - o < r && (n = this.dataMax - this.dataMin >= r, a = (r - s + o) / 2, l = [o - a, i.parse(t.min) ?? o - a], n && (l[2] = e ? e.log2lin(this.dataMin) : this.dataMin), h = [(o = sb(l)) + r, i.parse(t.max) ?? o + r], n && (h[2] = e ? e.log2lin(this.dataMax) : this.dataMax), (s = sv(h)) - o < r && (l[0] = s - r, l[1] = i.parse(t.min) ?? s - r, o = sb(l))), this.minRange = r, this.min = o, this.max = s;
    }
    getClosest() {
      let t, e;
      if (this.categories) e = 1;else {
        let i = [];
        this.series.forEach(function (t) {
          let s = t.closestPointRange,
            o = t.getColumn("x");
          1 === o.length ? i.push(o[0]) : t.sorted && sk(s) && t.reserveSpace() && (e = sk(e) ? Math.min(e, s) : s);
        }), i.length && (i.sort((t, e) => t - e), t = sL([i]));
      }
      return t && e ? Math.min(t, e) : t || e;
    }
    nameToX(t) {
      let e = sE(this.options.categories),
        i = e ? this.categories : this.names,
        s = t.options.x,
        o;
      return t.series.requireSorting = !1, sk(s) || (s = this.uniqueNames && i ? e ? i.indexOf(t.name) : sR(i.keys[t.name], -1) : t.series.autoIncrement()), -1 === s ? !e && i && (o = i.length) : sI(s) && (o = s), void 0 !== o ? (this.names[o] = t.name, this.names.keys[t.name] = o) : t.x && (o = t.x), o;
    }
    updateNames() {
      let t = this,
        e = this.names;
      e.length > 0 && (Object.keys(e.keys).forEach(function (t) {
        delete e.keys[t];
      }), e.length = 0, this.minRange = this.userMinRange, (this.series || []).forEach(e => {
        e.xIncrement = null, (!e.points || e.isDirtyData) && (t.max = Math.max(t.max || 0, e.dataTable.rowCount - 1), e.processData(), e.generatePoints());
        let i = e.getColumn("x").slice();
        e.data.forEach((e, s) => {
          let o = i[s];
          e?.options && void 0 !== e.name && void 0 !== (o = t.nameToX(e)) && o !== e.x && (i[s] = e.x = o);
        }), e.dataTable.setColumn("x", i);
      }));
    }
    setAxisTranslation() {
      let t = this,
        e = t.max - t.min,
        i = t.linkedParent,
        s = !!t.categories,
        o = t.isXAxis,
        r = t.axisPointRange || 0,
        a,
        n = 0,
        l = 0,
        h,
        d = t.transA;
      (o || s || r) && (a = t.getClosest(), i ? (n = i.minPointOffset, l = i.pointRangePadding) : t.series.forEach(function (e) {
        let i = s ? 1 : o ? sR(e.options.pointRange, a, 0) : t.axisPointRange || 0,
          h = e.options.pointPlacement;
        if (r = Math.max(r, i), !t.single || s) {
          let t = e.is("xrange") ? !o : o;
          n = Math.max(n, t && sD(h) ? 0 : i / 2), l = Math.max(l, t && "on" === h ? 0 : i);
        }
      }), h = t.ordinal && t.ordinal.slope && a ? t.ordinal.slope / a : 1, t.minPointOffset = n *= h, t.pointRangePadding = l *= h, t.pointRange = Math.min(r, t.single && s ? 1 : e), o && a && (t.closestPointRange = a)), t.translationSlope = t.transA = d = t.staticScale || t.len / (e + l || 1), t.transB = t.horiz ? t.left : t.bottom, t.minPixelPadding = d * n, sP(this, "afterSetAxisTranslation");
    }
    minFromRange() {
      let {
        max: t,
        min: e
      } = this;
      return sI(t) && sI(e) && t - e || void 0;
    }
    setTickInterval(t) {
      let {
          categories: e,
          chart: i,
          dataMax: s,
          dataMin: o,
          dateTime: r,
          isXAxis: a,
          logarithmic: n,
          options: l,
          softThreshold: h
        } = this,
        d = i.time,
        c = sI(this.threshold) ? this.threshold : void 0,
        p = this.minRange || 0,
        {
          ceiling: u,
          floor: g,
          linkedTo: m,
          softMax: f,
          softMin: x
        } = l,
        y = sI(m) && i[this.coll]?.[m],
        b = l.tickPixelInterval,
        v = l.maxPadding,
        M = l.minPadding,
        w = 0,
        k,
        S = sI(l.tickInterval) && l.tickInterval >= 0 ? l.tickInterval : void 0,
        T,
        C,
        A,
        P;
      if (r || e || y || this.getTickAmount(), A = sR(this.userMin, d.parse(l.min)), P = sR(this.userMax, d.parse(l.max)), y ? (this.linkedParent = y, k = y.getExtremes(), this.min = sR(k.min, k.dataMin), this.max = sR(k.max, k.dataMax), this.type !== y.type && sC(11, !0, i)) : (h && sk(c) && sI(s) && sI(o) && (o >= c ? (T = c, M = 0) : s <= c && (C = c, v = 0)), this.min = sR(A, T, o), this.max = sR(P, C, s)), sI(this.max) && sI(this.min) && (n && (this.positiveValuesOnly && !t && 0 >= Math.min(this.min, sR(o, this.min)) && sC(10, !0, i), this.min = sw(n.log2lin(this.min), 16), this.max = sw(n.log2lin(this.max), 16)), this.range && sI(o) && (this.userMin = this.min = A = Math.max(o, this.minFromRange() || 0), this.userMax = P = this.max, this.range = void 0)), sP(this, "foundExtremes"), this.adjustForMinRange(), sI(this.min) && sI(this.max)) {
        if (!sI(this.userMin) && sI(x) && x < this.min && (this.min = A = x), !sI(this.userMax) && sI(f) && f > this.max && (this.max = P = f), e || this.axisPointRange || this.stacking?.usePercentage || y || !(w = this.max - this.min) || (!sk(A) && M && (this.min -= w * M), sk(P) || !v || (this.max += w * v)), !sI(this.userMin) && sI(g) && (this.min = Math.max(this.min, g)), !sI(this.userMax) && sI(u) && (this.max = Math.min(this.max, u)), h && sI(o) && sI(s)) {
          let t = c || 0;
          !sk(A) && this.min < t && o >= t ? this.min = l.minRange ? Math.min(t, this.max - p) : t : !sk(P) && this.max > t && s <= t && (this.max = l.minRange ? Math.max(t, this.min + p) : t);
        }
        !i.polar && this.min > this.max && (sk(l.min) ? this.max = this.min : sk(l.max) && (this.min = this.max)), w = this.max - this.min;
      }
      if (this.min !== this.max && sI(this.min) && sI(this.max) ? y && !S && b === y.options.tickPixelInterval ? this.tickInterval = S = y.tickInterval : this.tickInterval = sR(S, this.tickAmount ? w / Math.max(this.tickAmount - 1, 1) : void 0, e ? 1 : w * b / Math.max(this.len, b)) : this.tickInterval = 1, a && !t) {
        let t = this.min !== this.old?.min || this.max !== this.old?.max;
        this.series.forEach(function (e) {
          e.forceCrop = e.forceCropping?.(), e.processData(t);
        }), sP(this, "postProcessData", {
          hasExtremesChanged: t
        });
      }
      this.setAxisTranslation(), sP(this, "initialAxisTranslation"), this.pointRange && !S && (this.tickInterval = Math.max(this.pointRange, this.tickInterval));
      let L = sR(l.minTickInterval, r && !this.series.some(t => !t.sorted) ? this.closestPointRange : 0);
      !S && this.tickInterval < L && (this.tickInterval = L), r || n || S || (this.tickInterval = sF(this, this.tickInterval)), this.tickAmount || (this.tickInterval = this.unsquish()), this.setTickPositions();
    }
    setTickPositions() {
      let t = this.options,
        e = t.tickPositions,
        i = t.tickPositioner,
        s = this.getMinorTickInterval(),
        o = !this.isPanning,
        r = o && t.startOnTick,
        a = o && t.endOnTick,
        n = [],
        l;
      if (this.tickmarkOffset = this.categories && "between" === t.tickmarkPlacement && 1 === this.tickInterval ? .5 : 0, this.single = this.min === this.max && sk(this.min) && !this.tickAmount && (this.min % 1 == 0 || !1 !== t.allowDecimals), e) n = e.slice();else if (sI(this.min) && sI(this.max)) {
        if (!this.ordinal?.positions && (this.max - this.min) / this.tickInterval > Math.max(2 * this.len, 200)) n = [this.min, this.max], sC(19, !1, this.chart);else if (this.dateTime) n = this.getTimeTicks(this.dateTime.normalizeTimeTickInterval(this.tickInterval, t.units), this.min, this.max, t.startOfWeek, this.ordinal?.positions, this.closestPointRange, !0);else if (this.logarithmic) n = this.logarithmic.getLogTickPositions(this.tickInterval, this.min, this.max);else {
          let t = this.tickInterval,
            e = t;
          for (; e <= 2 * t;) if (n = this.getLinearTickPositions(this.tickInterval, this.min, this.max), this.tickAmount && n.length > this.tickAmount) this.tickInterval = sF(this, e *= 1.1);else break;
        }
        n.length > this.len && (n = [n[0], n[n.length - 1]])[0] === n[1] && (n.length = 1), i && (this.tickPositions = n, (l = i.apply(this, [this.min, this.max])) && (n = l));
      }
      this.tickPositions = n, this.minorTickInterval = "auto" === s && this.tickInterval ? this.tickInterval / t.minorTicksPerMajor : s, this.paddedTicks = n.slice(0), this.trimTicks(n, r, a), !this.isLinked && sI(this.min) && sI(this.max) && (this.single && n.length < 2 && !this.categories && !this.series.some(t => t.is("heatmap") && "between" === t.options.pointPlacement) && (this.min -= .5, this.max += .5), e || l || this.adjustTickAmount()), sP(this, "afterSetTickPositions");
    }
    trimTicks(t, e, i) {
      let s = t[0],
        o = t[t.length - 1],
        r = !this.isOrdinal && this.minPointOffset || 0;
      if (sP(this, "trimTicks"), !this.isLinked) {
        if (e && s !== -1 / 0) this.min = s;else for (; this.min - r > t[0];) t.shift();
        if (i) this.max = o;else for (; this.max + r < t[t.length - 1];) t.pop();
        0 === t.length && sk(s) && !this.options.tickPositions && t.push((o + s) / 2);
      }
    }
    alignToOthers() {
      let t;
      let e = this,
        i = e.chart,
        s = [this],
        o = e.options,
        r = i.options.chart,
        a = "yAxis" === this.coll && r.alignThresholds,
        n = [];
      if (e.thresholdAlignment = void 0, (!1 !== r.alignTicks && o.alignTicks || a) && !1 !== o.startOnTick && !1 !== o.endOnTick && !e.logarithmic) {
        let o = t => {
            let {
              horiz: e,
              options: i
            } = t;
            return [e ? i.left : i.top, i.width, i.height, i.pane].join(",");
          },
          r = o(this);
        i[this.coll].forEach(function (i) {
          let {
            series: a
          } = i;
          a.length && a.some(t => t.visible) && i !== e && o(i) === r && (t = !0, s.push(i));
        });
      }
      if (t && a) {
        s.forEach(t => {
          let i = t.getThresholdAlignment(e);
          sI(i) && n.push(i);
        });
        let t = n.length > 1 ? n.reduce((t, e) => t += e, 0) / n.length : void 0;
        s.forEach(e => {
          e.thresholdAlignment = t;
        });
      }
      return t;
    }
    getThresholdAlignment(t) {
      if ((!sI(this.dataMin) || this !== t && this.series.some(t => t.isDirty || t.isDirtyData)) && this.getSeriesExtremes(), sI(this.threshold)) {
        let t = sM((this.threshold - (this.dataMin || 0)) / ((this.dataMax || 0) - (this.dataMin || 0)), 0, 1);
        return this.options.reversed && (t = 1 - t), t;
      }
    }
    getTickAmount() {
      let t = this.options,
        e = t.tickPixelInterval,
        i = t.tickAmount;
      sk(t.tickInterval) || i || !(this.len < e) || this.isRadial || this.logarithmic || !t.startOnTick || !t.endOnTick || (i = 2), !i && this.alignToOthers() && (i = Math.ceil(this.len / e) + 1), i < 4 && (this.finalTickAmt = i, i = 5), this.tickAmount = i;
    }
    adjustTickAmount() {
      let t = this,
        {
          finalTickAmt: e,
          max: i,
          min: s,
          options: o,
          tickPositions: r,
          tickAmount: a,
          thresholdAlignment: n
        } = t,
        l = r?.length,
        h = sR(t.threshold, t.softThreshold ? 0 : null),
        d,
        c,
        p = t.tickInterval,
        u,
        g = () => r.push(sw(r[r.length - 1] + p)),
        m = () => r.unshift(sw(r[0] - p));
      if (sI(n) && (u = n < .5 ? Math.ceil(n * (a - 1)) : Math.floor(n * (a - 1)), o.reversed && (u = a - 1 - u)), t.hasData() && sI(s) && sI(i)) {
        let n = () => {
          t.transA *= (l - 1) / (a - 1), t.min = o.startOnTick ? r[0] : Math.min(s, r[0]), t.max = o.endOnTick ? r[r.length - 1] : Math.max(i, r[r.length - 1]);
        };
        if (sI(u) && sI(t.threshold)) {
          for (; r[u] !== h || r.length !== a || r[0] > s || r[r.length - 1] < i;) {
            for (r.length = 0, r.push(t.threshold); r.length < a;) void 0 === r[u] || r[u] > t.threshold ? m() : g();
            if (p > 8 * t.tickInterval) break;
            p *= 2;
          }
          n();
        } else if (l < a) {
          for (; r.length < a;) r.length % 2 || s === h ? g() : m();
          n();
        }
        if (sk(e)) {
          for (c = d = r.length; c--;) (3 === e && c % 2 == 1 || e <= 2 && c > 0 && c < d - 1) && r.splice(c, 1);
          t.finalTickAmt = void 0;
        }
      }
    }
    setScale() {
      let {
          coll: t,
          stacking: e
        } = this,
        i = !1,
        s = !1;
      this.series.forEach(t => {
        i = i || t.isDirtyData || t.isDirty, s = s || t.xAxis && t.xAxis.isDirty || !1;
      }), this.setAxisSize();
      let o = this.len !== (this.old && this.old.len);
      o || i || s || this.isLinked || this.forceRedraw || this.userMin !== (this.old && this.old.userMin) || this.userMax !== (this.old && this.old.userMax) || this.alignToOthers() ? (e && "yAxis" === t && e.buildStacks(), this.forceRedraw = !1, this.userMinRange || (this.minRange = void 0), this.getSeriesExtremes(), this.setTickInterval(), e && "xAxis" === t && e.buildStacks(), this.isDirty || (this.isDirty = o || this.min !== this.old?.min || this.max !== this.old?.max)) : e && e.cleanStacks(), i && delete this.allExtremes, sP(this, "afterSetScale");
    }
    setExtremes(t, e, i = !0, s, o) {
      let r = this.chart;
      this.series.forEach(t => {
        delete t.kdTree;
      }), t = r.time.parse(t), e = r.time.parse(e), sP(this, "setExtremes", o = sA(o, {
        min: t,
        max: e
      }), t => {
        this.userMin = t.min, this.userMax = t.max, this.eventArgs = t, i && r.redraw(s);
      });
    }
    setAxisSize() {
      let t = this.chart,
        e = this.options,
        i = e.offsets || [0, 0, 0, 0],
        s = this.horiz,
        o = this.width = Math.round(sW(sR(e.width, t.plotWidth - i[3] + i[1]), t.plotWidth)),
        r = this.height = Math.round(sW(sR(e.height, t.plotHeight - i[0] + i[2]), t.plotHeight)),
        a = this.top = Math.round(sW(sR(e.top, t.plotTop + i[0]), t.plotHeight, t.plotTop)),
        n = this.left = Math.round(sW(sR(e.left, t.plotLeft + i[3]), t.plotWidth, t.plotLeft));
      this.bottom = t.chartHeight - r - a, this.right = t.chartWidth - o - n, this.len = Math.max(s ? o : r, 0), this.pos = s ? n : a;
    }
    getExtremes() {
      let t = this.logarithmic;
      return {
        min: t ? sw(t.lin2log(this.min)) : this.min,
        max: t ? sw(t.lin2log(this.max)) : this.max,
        dataMin: this.dataMin,
        dataMax: this.dataMax,
        userMin: this.userMin,
        userMax: this.userMax
      };
    }
    getThreshold(t) {
      let e = this.logarithmic,
        i = e ? e.lin2log(this.min) : this.min,
        s = e ? e.lin2log(this.max) : this.max;
      return null === t || t === -1 / 0 ? t = i : t === 1 / 0 ? t = s : i > t ? t = i : s < t && (t = s), this.translate(t, 0, 1, 0, 1);
    }
    autoLabelAlign(t) {
      let e = (sR(t, 0) - 90 * this.side + 720) % 360,
        i = {
          align: "center"
        };
      return sP(this, "autoLabelAlign", i, function (t) {
        e > 15 && e < 165 ? t.align = "right" : e > 195 && e < 345 && (t.align = "left");
      }), i.align;
    }
    tickSize(t) {
      let e = this.options,
        i = sR(e["tick" === t ? "tickWidth" : "minorTickWidth"], "tick" === t && this.isXAxis && !this.categories ? 1 : 0),
        s = e["tick" === t ? "tickLength" : "minorTickLength"],
        o;
      i && s && ("inside" === e[t + "Position"] && (s = -s), o = [s, i]);
      let r = {
        tickSize: o
      };
      return sP(this, "afterTickSize", r), r.tickSize;
    }
    labelMetrics() {
      let t = this.chart.renderer,
        e = this.ticks,
        i = e[Object.keys(e)[0]] || {};
      return this.chart.renderer.fontMetrics(i.label || i.movedLabel || t.box);
    }
    unsquish() {
      let t = this.options.labels,
        e = t.padding || 0,
        i = this.horiz,
        s = this.tickInterval,
        o = this.len / (((this.categories ? 1 : 0) + this.max - this.min) / s),
        r = t.rotation,
        a = sw(.8 * this.labelMetrics().h),
        n = Math.max(this.max - this.min, 0),
        l = function (t) {
          let i = (t + 2 * e) / (o || 1);
          return (i = i > 1 ? Math.ceil(i) : 1) * s > n && t !== 1 / 0 && o !== 1 / 0 && n && (i = Math.ceil(n / s)), sw(i * s);
        },
        h = s,
        d,
        c = Number.MAX_VALUE,
        p;
      if (i) {
        if (!t.staggerLines && (sI(r) ? p = [r] : o < t.autoRotationLimit && (p = t.autoRotation)), p) {
          let t, e;
          for (let i of p) (i === r || i && i >= -90 && i <= 90) && (e = (t = l(Math.abs(a / Math.sin(sy * i)))) + Math.abs(i / 360)) < c && (c = e, d = i, h = t);
        }
      } else h = l(.75 * a);
      return this.autoRotation = p, this.labelRotation = sR(d, sI(r) ? r : 0), t.step ? s : h;
    }
    getSlotWidth(t) {
      let e = this.chart,
        i = this.horiz,
        s = this.options.labels,
        o = Math.max(this.tickPositions.length - (this.categories ? 0 : 1), 1),
        r = e.margin[3];
      if (t && sI(t.slotWidth)) return t.slotWidth;
      if (i && s.step < 2 && !this.isRadial) return s.rotation ? 0 : (this.staggerLines || 1) * this.len / o;
      if (!i) {
        let t = s.style.width;
        if (void 0 !== t) return parseInt(String(t), 10);
        if (r) return r - e.spacing[3];
      }
      return .33 * e.chartWidth;
    }
    renderUnsquish() {
      let t = this.chart,
        e = t.renderer,
        i = this.tickPositions,
        s = this.ticks,
        o = this.options.labels,
        r = o.style,
        a = this.horiz,
        n = this.getSlotWidth(),
        l = Math.max(1, Math.round(n - (a ? 2 * (o.padding || 0) : o.distance || 0))),
        h = {},
        d = this.labelMetrics(),
        c = r.lineClamp,
        p,
        u = c ?? (Math.floor(this.len / (i.length * d.h)) || 1),
        g = 0;
      sD(o.rotation) || (h.rotation = o.rotation || 0), i.forEach(function (t) {
        let e = s[t];
        e.movedLabel && e.replaceMovedLabel();
        let i = e.label?.textPxLength || 0;
        i > g && (g = i);
      }), this.maxLabelLength = g, this.autoRotation ? g > l && g > d.h ? h.rotation = this.labelRotation : this.labelRotation = 0 : n && (p = l), h.rotation && (p = g > .5 * t.chartHeight ? .33 * t.chartHeight : g, c || (u = 1)), this.labelAlign = o.align || this.autoLabelAlign(this.labelRotation), this.labelAlign && (h.align = this.labelAlign), i.forEach(function (t) {
        let e = s[t],
          i = e && e.label,
          o = r.width,
          a = {};
        i && (i.attr(h), e.shortenLabel ? e.shortenLabel() : p && !o && "nowrap" !== r.whiteSpace && (p < (i.textPxLength || 0) || "SPAN" === i.element.tagName) ? i.css(sA(a, {
          width: `${p}px`,
          lineClamp: u
        })) : !i.styles.width || a.width || o || i.css({
          width: "auto"
        }), e.rotation = h.rotation);
      }, this), this.tickRotCorr = e.rotCorr(d.b, this.labelRotation || 0, 0 !== this.side);
    }
    hasData() {
      return this.series.some(function (t) {
        return t.hasData();
      }) || this.options.showEmpty && sk(this.min) && sk(this.max);
    }
    addTitle(t) {
      let e;
      let i = this.chart.renderer,
        s = this.horiz,
        o = this.opposite,
        r = this.options.title,
        a = this.chart.styledMode;
      this.axisTitle || ((e = r.textAlign) || (e = (s ? {
        low: "left",
        middle: "center",
        high: "right"
      } : {
        low: o ? "right" : "left",
        middle: "center",
        high: o ? "left" : "right"
      })[r.align]), this.axisTitle = i.text(r.text || "", 0, 0, r.useHTML).attr({
        zIndex: 7,
        rotation: r.rotation || 0,
        align: e
      }).addClass("highcharts-axis-title"), a || this.axisTitle.css(sB(r.style)), this.axisTitle.add(this.axisGroup), this.axisTitle.isNew = !0), a || r.style.width || this.isRadial || this.axisTitle.css({
        width: this.len + "px"
      }), this.axisTitle[t ? "show" : "hide"](t);
    }
    generateTick(t) {
      let e = this.ticks;
      e[t] ? e[t].addLabel() : e[t] = new sp(this, t);
    }
    createGroups() {
      let {
          axisParent: t,
          chart: e,
          coll: i,
          options: s
        } = this,
        o = e.renderer,
        r = (e, r, a) => o.g(e).attr({
          zIndex: a
        }).addClass(`highcharts-${i.toLowerCase()}${r} ` + (this.isRadial ? `highcharts-radial-axis${r} ` : "") + (s.className || "")).add(t);
      this.axisGroup || (this.gridGroup = r("grid", "-grid", s.gridZIndex), this.axisGroup = r("axis", "", s.zIndex), this.labelGroup = r("axis-labels", "-labels", s.labels.zIndex));
    }
    getOffset() {
      let t = this,
        {
          chart: e,
          horiz: i,
          options: s,
          side: o,
          ticks: r,
          tickPositions: a,
          coll: n
        } = t,
        l = e.inverted && !t.isZAxis ? [1, 0, 3, 2][o] : o,
        h = t.hasData(),
        d = s.title,
        c = s.labels,
        p = sI(s.crossing),
        u = e.axisOffset,
        g = e.clipOffset,
        m = [-1, 1, 1, -1][o],
        f,
        x = 0,
        y,
        b = 0,
        v = 0,
        M,
        w;
      if (t.showAxis = f = h || s.showEmpty, t.staggerLines = t.horiz && c.staggerLines || void 0, t.createGroups(), h || t.isLinked ? (a.forEach(function (e) {
        t.generateTick(e);
      }), t.renderUnsquish(), t.reserveSpaceDefault = 0 === o || 2 === o || {
        1: "left",
        3: "right"
      }[o] === t.labelAlign, sR(c.reserveSpace, !p && null, "center" === t.labelAlign || null, t.reserveSpaceDefault) && a.forEach(function (t) {
        v = Math.max(r[t].getLabelSize(), v);
      }), t.staggerLines && (v *= t.staggerLines), t.labelOffset = v * (t.opposite ? -1 : 1)) : sN(r, function (t, e) {
        t.destroy(), delete r[e];
      }), d?.text && !1 !== d.enabled && (t.addTitle(f), f && !p && !1 !== d.reserveSpace && (t.titleOffset = x = t.axisTitle.getBBox()[i ? "height" : "width"], b = sk(y = d.offset) ? 0 : sR(d.margin, i ? 5 : 10))), t.renderLine(), t.offset = m * sR(s.offset, u[o] ? u[o] + (s.margin || 0) : 0), t.tickRotCorr = t.tickRotCorr || {
        x: 0,
        y: 0
      }, w = 0 === o ? -t.labelMetrics().h : 2 === o ? t.tickRotCorr.y : 0, M = Math.abs(v) + b, v && (M -= w, M += m * (i ? sR(c.y, t.tickRotCorr.y + m * c.distance) : sR(c.x, m * c.distance))), t.axisTitleMargin = sR(y, M), t.getMaxLabelDimensions && (t.maxLabelDimensions = t.getMaxLabelDimensions(r, a)), "colorAxis" !== n && g) {
        let e = this.tickSize("tick");
        u[o] = Math.max(u[o], (t.axisTitleMargin || 0) + x + m * t.offset, M, a && a.length && e ? e[0] + m * t.offset : 0);
        let i = !t.axisLine || s.offset ? 0 : t.axisLine.strokeWidth() / 2;
        g[l] = Math.max(g[l], i);
      }
      sP(this, "afterGetOffset");
    }
    getLinePath(t) {
      let e = this.chart,
        i = this.opposite,
        s = this.offset,
        o = this.horiz,
        r = this.left + (i ? this.width : 0) + s,
        a = e.chartHeight - this.bottom - (i ? this.height : 0) + s;
      return i && (t *= -1), e.renderer.crispLine([["M", o ? this.left : r, o ? a : this.top], ["L", o ? e.chartWidth - this.right : r, o ? a : e.chartHeight - this.bottom]], t);
    }
    renderLine() {
      this.axisLine || (this.axisLine = this.chart.renderer.path().addClass("highcharts-axis-line").add(this.axisGroup), this.chart.styledMode || this.axisLine.attr({
        stroke: this.options.lineColor,
        "stroke-width": this.options.lineWidth,
        zIndex: 7
      }));
    }
    getTitlePosition(t) {
      let e = this.horiz,
        i = this.left,
        s = this.top,
        o = this.len,
        r = this.options.title,
        a = e ? i : s,
        n = this.opposite,
        l = this.offset,
        h = r.x,
        d = r.y,
        c = this.chart.renderer.fontMetrics(t),
        p = t ? Math.max(t.getBBox(!1, 0).height - c.h - 1, 0) : 0,
        u = {
          low: a + (e ? 0 : o),
          middle: a + o / 2,
          high: a + (e ? o : 0)
        }[r.align],
        g = (e ? s + this.height : i) + (e ? 1 : -1) * (n ? -1 : 1) * (this.axisTitleMargin || 0) + [-p, p, c.f, -p][this.side],
        m = {
          x: e ? u + h : g + (n ? this.width : 0) + l + h,
          y: e ? g + d - (n ? this.height : 0) + l : u + d
        };
      return sP(this, "afterGetTitlePosition", {
        titlePosition: m
      }), m;
    }
    renderMinorTick(t, e) {
      let i = this.minorTicks;
      i[t] || (i[t] = new sp(this, t, "minor")), e && i[t].isNew && i[t].render(null, !0), i[t].render(null, !1, 1);
    }
    renderTick(t, e, i) {
      let s = this.isLinked,
        o = this.ticks;
      (!s || t >= this.min && t <= this.max || this.grid && this.grid.isColumn) && (o[t] || (o[t] = new sp(this, t)), i && o[t].isNew && o[t].render(e, !0, -1), o[t].render(e));
    }
    render() {
      let t, e;
      let i = this,
        s = i.chart,
        o = i.logarithmic,
        r = s.renderer,
        a = i.options,
        n = i.isLinked,
        l = i.tickPositions,
        h = i.axisTitle,
        d = i.ticks,
        c = i.minorTicks,
        p = i.alternateBands,
        u = a.stackLabels,
        g = a.alternateGridColor,
        m = a.crossing,
        f = i.tickmarkOffset,
        x = i.axisLine,
        y = i.showAxis,
        b = su(r.globalAnimation);
      if (i.labelEdge.length = 0, i.overlap = !1, [d, c, p].forEach(function (t) {
        sN(t, function (t) {
          t.isActive = !1;
        });
      }), sI(m)) {
        let t = this.isXAxis ? s.yAxis[0] : s.xAxis[0],
          e = [1, -1, -1, 1][this.side];
        if (t) {
          let s = t.toPixels(m, !0);
          i.horiz && (s = t.len - s), i.offset = e * s;
        }
      }
      if (i.hasData() || n) {
        let r = i.chart.hasRendered && i.old && sI(i.old.min);
        i.minorTickInterval && !i.categories && i.getMinorTickPositions().forEach(function (t) {
          i.renderMinorTick(t, r);
        }), l.length && (l.forEach(function (t, e) {
          i.renderTick(t, e, r);
        }), f && (0 === i.min || i.single) && (d[-1] || (d[-1] = new sp(i, -1, null, !0)), d[-1].render(-1))), g && l.forEach(function (r, a) {
          e = void 0 !== l[a + 1] ? l[a + 1] + f : i.max - f, a % 2 == 0 && r < i.max && e <= i.max + (s.polar ? -f : f) && (p[r] || (p[r] = new A.PlotLineOrBand(i, {})), t = r + f, p[r].options = {
            from: o ? o.lin2log(t) : t,
            to: o ? o.lin2log(e) : e,
            color: g,
            className: "highcharts-alternate-grid"
          }, p[r].render(), p[r].isActive = !0);
        }), i._addedPlotLB || (i._addedPlotLB = !0, (a.plotLines || []).concat(a.plotBands || []).forEach(function (t) {
          i.addPlotBandOrLine(t);
        }));
      }
      [d, c, p].forEach(function (t) {
        let e = [],
          i = b.duration;
        sN(t, function (t, i) {
          t.isActive || (t.render(i, !1, 0), t.isActive = !1, e.push(i));
        }), sG(function () {
          let i = e.length;
          for (; i--;) t[e[i]] && !t[e[i]].isActive && (t[e[i]].destroy(), delete t[e[i]]);
        }, t !== p && s.hasRendered && i ? i : 0);
      }), x && (x[x.isPlaced ? "animate" : "attr"]({
        d: this.getLinePath(x.strokeWidth())
      }), x.isPlaced = !0, x[y ? "show" : "hide"](y)), h && y && (h[h.isNew ? "attr" : "animate"](i.getTitlePosition(h)), h.isNew = !1), u && u.enabled && i.stacking && i.stacking.renderStackTotals(), i.old = {
        len: i.len,
        max: i.max,
        min: i.min,
        transA: i.transA,
        userMax: i.userMax,
        userMin: i.userMin
      }, i.isDirty = !1, sP(this, "afterRender");
    }
    redraw() {
      this.visible && (this.render(), this.plotLinesAndBands.forEach(function (t) {
        t.render();
      })), this.series.forEach(function (t) {
        t.isDirty = !0;
      });
    }
    getKeepProps() {
      return this.keepProps || sH.keepProps;
    }
    destroy(t) {
      let e = this,
        i = e.plotLinesAndBands,
        s = this.eventOptions;
      if (sP(this, "destroy", {
        keepEvents: t
      }), t || sj(e), [e.ticks, e.minorTicks, e.alternateBands].forEach(function (t) {
        sS(t);
      }), i) {
        let t = i.length;
        for (; t--;) i[t].destroy();
      }
      for (let t in ["axisLine", "axisTitle", "axisGroup", "gridGroup", "labelGroup", "cross", "scrollbar"].forEach(function (t) {
        e[t] && (e[t] = e[t].destroy());
      }), e.plotLinesAndBandsGroups) e.plotLinesAndBandsGroups[t] = e.plotLinesAndBandsGroups[t].destroy();
      sN(e, function (t, i) {
        -1 === e.getKeepProps().indexOf(i) && delete e[i];
      }), this.eventOptions = s;
    }
    drawCrosshair(t, e) {
      let i = this.crosshair,
        s = sR(i && i.snap, !0),
        o = this.chart,
        r,
        a,
        n,
        l = this.cross,
        h;
      if (sP(this, "drawCrosshair", {
        e: t,
        point: e
      }), t || (t = this.cross && this.cross.e), i && !1 !== (sk(e) || !s)) {
        if (s ? sk(e) && (a = sR("colorAxis" !== this.coll ? e.crosshairPos : null, this.isXAxis ? e.plotX : this.len - e.plotY)) : a = t && (this.horiz ? t.chartX - this.pos : this.len - t.chartY + this.pos), sk(a) && (h = {
          value: e && (this.isXAxis ? e.x : sR(e.stackY, e.y)),
          translatedValue: a
        }, o.polar && sA(h, {
          isCrosshair: !0,
          chartX: t && t.chartX,
          chartY: t && t.chartY,
          point: e
        }), r = this.getPlotLinePath(h) || null), !sk(r)) {
          this.hideCrosshair();
          return;
        }
        n = this.categories && !this.isRadial, l || (this.cross = l = o.renderer.path().addClass("highcharts-crosshair highcharts-crosshair-" + (n ? "category " : "thin ") + (i.className || "")).attr({
          zIndex: sR(i.zIndex, 2)
        }).add(), !o.styledMode && (l.attr({
          stroke: i.color || (n ? tA.parse("#ccd3ff").setOpacity(.25).get() : "#cccccc"),
          "stroke-width": sR(i.width, 1)
        }).css({
          "pointer-events": "none"
        }), i.dashStyle && l.attr({
          dashstyle: i.dashStyle
        }))), l.show().attr({
          d: r
        }), n && !i.width && l.attr({
          "stroke-width": this.transA
        }), this.cross.e = t;
      } else this.hideCrosshair();
      sP(this, "afterDrawCrosshair", {
        e: t,
        point: e
      });
    }
    hideCrosshair() {
      this.cross && this.cross.hide(), sP(this, "afterHideCrosshair");
    }
    update(t, e) {
      let i = this.chart;
      t = sB(this.userOptions, t), this.destroy(!0), this.init(i, t), i.isDirtyBox = !0, sR(e, !0) && i.redraw();
    }
    remove(t) {
      let e = this.chart,
        i = this.coll,
        s = this.series,
        o = s.length;
      for (; o--;) s[o] && s[o].remove(!1);
      sT(e.axes, this), sT(e[i] || [], this), e.orderItems(i), this.destroy(), e.isDirtyBox = !0, sR(t, !0) && e.redraw();
    }
    setTitle(t, e) {
      this.update({
        title: t
      }, e);
    }
    setCategories(t, e) {
      this.update({
        categories: t
      }, e);
    }
  }
  sH.keepProps = ["coll", "extKey", "hcEvents", "len", "names", "series", "userMax", "userMin"];
  let sY = sH,
    {
      addEvent: sV,
      getMagnitude: sU,
      normalizeTickInterval: sZ,
      timeUnits: s$
    } = J;
  !function (t) {
    function e() {
      return this.chart.time.getTimeTicks.apply(this.chart.time, arguments);
    }
    function i() {
      if ("datetime" !== this.type) {
        this.dateTime = void 0;
        return;
      }
      this.dateTime || (this.dateTime = new s(this));
    }
    t.compose = function (t) {
      return t.keepProps.includes("dateTime") || (t.keepProps.push("dateTime"), t.prototype.getTimeTicks = e, sV(t, "afterSetType", i)), t;
    };
    class s {
      constructor(t) {
        this.axis = t;
      }
      normalizeTimeTickInterval(t, e) {
        let i = e || [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2]], ["week", [1, 2]], ["month", [1, 2, 3, 4, 6]], ["year", null]],
          s = i[i.length - 1],
          o = s$[s[0]],
          r = s[1],
          a;
        for (a = 0; a < i.length && (o = s$[(s = i[a])[0]], r = s[1], !i[a + 1] || !(t <= (o * r[r.length - 1] + s$[i[a + 1][0]]) / 2)); a++);
        o === s$.year && t < 5 * o && (r = [1, 2, 5]);
        let n = sZ(t / o, r, "year" === s[0] ? Math.max(sU(t / o), 1) : 1);
        return {
          unitRange: o,
          count: n,
          unitName: s[0]
        };
      }
      getXDateFormat(t, e) {
        let {
            axis: i
          } = this,
          s = i.chart.time;
        return i.closestPointRange ? s.getDateFormat(i.closestPointRange, t, i.options.startOfWeek, e) || s.resolveDTLFormat(e.year).main : s.resolveDTLFormat(e.day).main;
      }
    }
    t.Additions = s;
  }(l || (l = {}));
  let s_ = l,
    {
      addEvent: sq,
      normalizeTickInterval: sK,
      pick: sJ
    } = J;
  !function (t) {
    function e() {
      "logarithmic" !== this.type ? this.logarithmic = void 0 : this.logarithmic ?? (this.logarithmic = new s(this));
    }
    function i() {
      let t = this.logarithmic;
      t && (this.lin2val = function (e) {
        return t.lin2log(e);
      }, this.val2lin = function (e) {
        return t.log2lin(e);
      });
    }
    t.compose = function (t) {
      return t.keepProps.includes("logarithmic") || (t.keepProps.push("logarithmic"), sq(t, "afterSetType", e), sq(t, "afterInit", i)), t;
    };
    class s {
      constructor(t) {
        this.axis = t;
      }
      getLogTickPositions(t, e, i, s) {
        let o = this.axis,
          r = o.len,
          a = o.options,
          n = [];
        if (s || (this.minorAutoInterval = void 0), t >= .5) t = Math.round(t), n = o.getLinearTickPositions(t, e, i);else if (t >= .08) {
          let o, r, a, l, h, d, c;
          let p = Math.floor(e);
          for (o = t > .3 ? [1, 2, 4] : t > .15 ? [1, 2, 4, 6, 8] : [1, 2, 3, 4, 5, 6, 7, 8, 9], r = p; r < i + 1 && !c; r++) for (a = 0, l = o.length; a < l && !c; a++) (h = this.log2lin(this.lin2log(r) * o[a])) > e && (!s || d <= i) && void 0 !== d && n.push(d), d > i && (c = !0), d = h;
        } else {
          let l = this.lin2log(e),
            h = this.lin2log(i),
            d = s ? o.getMinorTickInterval() : a.tickInterval,
            c = a.tickPixelInterval / (s ? 5 : 1),
            p = s ? r / o.tickPositions.length : r;
          t = sK(t = sJ("auto" === d ? null : d, this.minorAutoInterval, (h - l) * c / (p || 1))), n = o.getLinearTickPositions(t, l, h).map(this.log2lin), s || (this.minorAutoInterval = t / 5);
        }
        return s || (o.tickInterval = t), n;
      }
      lin2log(t) {
        return Math.pow(10, t);
      }
      log2lin(t) {
        return Math.log(t) / Math.LN10;
      }
    }
    t.Additions = s;
  }(h || (h = {}));
  let sQ = h,
    {
      erase: s0,
      extend: s1,
      isNumber: s2
    } = J;
  !function (t) {
    let e;
    function i(t) {
      return this.addPlotBandOrLine(t, "plotBands");
    }
    function s(t, i) {
      let s = this.userOptions,
        o = new e(this, t);
      if (this.visible && (o = o.render()), o) {
        if (this._addedPlotLB || (this._addedPlotLB = !0, (s.plotLines || []).concat(s.plotBands || []).forEach(t => {
          this.addPlotBandOrLine(t);
        })), i) {
          let e = s[i] || [];
          e.push(t), s[i] = e;
        }
        this.plotLinesAndBands.push(o);
      }
      return o;
    }
    function o(t) {
      return this.addPlotBandOrLine(t, "plotLines");
    }
    function r(t, e, i) {
      i = i || this.options;
      let s = this.getPlotLinePath({
          value: e,
          force: !0,
          acrossPanes: i.acrossPanes
        }),
        o = [],
        r = this.horiz,
        a = !s2(this.min) || !s2(this.max) || t < this.min && e < this.min || t > this.max && e > this.max,
        n = this.getPlotLinePath({
          value: t,
          force: !0,
          acrossPanes: i.acrossPanes
        }),
        l,
        h = 1,
        d;
      if (n && s) for (a && (d = n.toString() === s.toString(), h = 0), l = 0; l < n.length; l += 2) {
        let t = n[l],
          e = n[l + 1],
          i = s[l],
          a = s[l + 1];
        ("M" === t[0] || "L" === t[0]) && ("M" === e[0] || "L" === e[0]) && ("M" === i[0] || "L" === i[0]) && ("M" === a[0] || "L" === a[0]) && (r && i[1] === t[1] ? (i[1] += h, a[1] += h) : r || i[2] !== t[2] || (i[2] += h, a[2] += h), o.push(["M", t[1], t[2]], ["L", e[1], e[2]], ["L", a[1], a[2]], ["L", i[1], i[2]], ["Z"])), o.isFlat = d;
      }
      return o;
    }
    function a(t) {
      this.removePlotBandOrLine(t);
    }
    function n(t) {
      let e = this.plotLinesAndBands,
        i = this.options,
        s = this.userOptions;
      if (e) {
        let o = e.length;
        for (; o--;) e[o].id === t && e[o].destroy();
        [i.plotLines || [], s.plotLines || [], i.plotBands || [], s.plotBands || []].forEach(function (e) {
          for (o = e.length; o--;) (e[o] || {}).id === t && s0(e, e[o]);
        });
      }
    }
    function l(t) {
      this.removePlotBandOrLine(t);
    }
    t.compose = function (t, h) {
      let d = h.prototype;
      return d.addPlotBand || (e = t, s1(d, {
        addPlotBand: i,
        addPlotLine: o,
        addPlotBandOrLine: s,
        getPlotBandPath: r,
        removePlotBand: a,
        removePlotLine: l,
        removePlotBandOrLine: n
      })), h;
    };
  }(d || (d = {}));
  let s3 = d,
    {
      addEvent: s6,
      arrayMax: s5,
      arrayMin: s9,
      defined: s8,
      destroyObjectProperties: s4,
      erase: s7,
      fireEvent: ot,
      merge: oe,
      objectEach: oi,
      pick: os
    } = J;
  class oo {
    static compose(t, e) {
      return s6(t, "afterInit", function () {
        this.labelCollectors.push(() => {
          let t = [];
          for (let e of this.axes) for (let {
            label: i,
            options: s
          } of e.plotLinesAndBands) i && !s?.label?.allowOverlap && t.push(i);
          return t;
        });
      }), s3.compose(oo, e);
    }
    constructor(t, e) {
      this.axis = t, this.options = e, this.id = e.id;
    }
    render() {
      ot(this, "render");
      let {
          axis: t,
          options: e
        } = this,
        {
          horiz: i,
          logarithmic: s
        } = t,
        {
          color: o,
          events: r,
          zIndex: a = 0
        } = e,
        {
          renderer: n,
          time: l
        } = t.chart,
        h = {},
        d = l.parse(e.to),
        c = l.parse(e.from),
        p = l.parse(e.value),
        u = e.borderWidth,
        g = e.label,
        {
          label: m,
          svgElem: f
        } = this,
        x = [],
        y,
        b = s8(c) && s8(d),
        v = s8(p),
        M = !f,
        w = {
          class: "highcharts-plot-" + (b ? "band " : "line ") + (e.className || "")
        },
        k = b ? "bands" : "lines";
      if (!t.chart.styledMode && (v ? (w.stroke = o || "#999999", w["stroke-width"] = os(e.width, 1), e.dashStyle && (w.dashstyle = e.dashStyle)) : b && (w.fill = o || "#e6e9ff", u && (w.stroke = e.borderColor, w["stroke-width"] = u))), h.zIndex = a, k += "-" + a, (y = t.plotLinesAndBandsGroups[k]) || (t.plotLinesAndBandsGroups[k] = y = n.g("plot-" + k).attr(h).add()), f || (this.svgElem = f = n.path().attr(w).add(y)), s8(p)) x = t.getPlotLinePath({
        value: s?.log2lin(p) ?? p,
        lineWidth: f.strokeWidth(),
        acrossPanes: e.acrossPanes
      });else {
        if (!(s8(c) && s8(d))) return;
        x = t.getPlotBandPath(s?.log2lin(c) ?? c, s?.log2lin(d) ?? d, e);
      }
      return !this.eventsAdded && r && (oi(r, (t, e) => {
        f?.on(e, t => {
          r[e].apply(this, [t]);
        });
      }), this.eventsAdded = !0), (M || !f.d) && x?.length ? f.attr({
        d: x
      }) : f && (x ? (f.show(), f.animate({
        d: x
      })) : f.d && (f.hide(), m && (this.label = m = m.destroy()))), g && (s8(g.text) || s8(g.formatter)) && x?.length && t.width > 0 && t.height > 0 && !x.isFlat ? (g = oe({
        align: i && b ? "center" : void 0,
        x: i ? !b && 4 : 10,
        verticalAlign: !i && b ? "middle" : void 0,
        y: i ? b ? 16 : 10 : b ? 6 : -4,
        rotation: i && !b ? 90 : 0,
        ...(b ? {
          inside: !0
        } : {})
      }, g), this.renderLabel(g, x, b, a)) : m && m.hide(), this;
    }
    renderLabel(t, e, i, s) {
      let o = this.axis,
        r = o.chart.renderer,
        a = t.inside,
        n = this.label;
      n || (this.label = n = r.text(this.getLabelText(t), 0, 0, t.useHTML).attr({
        align: t.textAlign || t.align,
        rotation: t.rotation,
        class: "highcharts-plot-" + (i ? "band" : "line") + "-label " + (t.className || ""),
        zIndex: s
      }), o.chart.styledMode || n.css(oe({
        fontSize: "0.8em",
        textOverflow: i && !a ? "" : "ellipsis"
      }, t.style)), n.add());
      let l = e.xBounds || [e[0][1], e[1][1], i ? e[2][1] : e[0][1]],
        h = e.yBounds || [e[0][2], e[1][2], i ? e[2][2] : e[0][2]],
        d = s9(l),
        c = s9(h),
        p = s5(l) - d;
      n.align(t, !1, {
        x: d,
        y: c,
        width: p,
        height: s5(h) - c
      }), (!n.alignValue || "left" === n.alignValue || s8(a)) && n.css({
        width: (t.style?.width || (i && a ? p : 90 === n.rotation ? o.height - (n.alignAttr.y - o.top) : (t.clip ? o.width : o.chart.chartWidth) - (n.alignAttr.x - o.left))) + "px"
      }), n.show(!0);
    }
    getLabelText(t) {
      return s8(t.formatter) ? t.formatter.call(this) : t.text;
    }
    destroy() {
      s7(this.axis.plotLinesAndBands, this), delete this.axis, s4(this);
    }
  }
  let {
      animObject: or
    } = tH,
    {
      format: oa
    } = ed,
    {
      composed: on,
      dateFormats: ol,
      doc: oh,
      isSafari: od
    } = A,
    {
      distribute: oc
    } = ef,
    {
      addEvent: op,
      clamp: ou,
      css: og,
      discardElement: om,
      extend: of,
      fireEvent: ox,
      isArray: oy,
      isNumber: ob,
      isObject: ov,
      isString: oM,
      merge: ow,
      pick: ok,
      pushUnique: oS,
      splat: oT,
      syncTimeout: oC
    } = J;
  class oA {
    constructor(t, e, i) {
      this.allowShared = !0, this.crosshairs = [], this.distance = 0, this.isHidden = !0, this.isSticky = !1, this.options = {}, this.outside = !1, this.chart = t, this.init(t, e), this.pointer = i;
    }
    bodyFormatter(t) {
      return t.map(t => {
        let e = t.series.tooltipOptions,
          i = t.formatPrefix || "point";
        return (e[i + "Formatter"] || t.tooltipFormatter).call(t, e[i + "Format"] || "");
      });
    }
    cleanSplit(t) {
      this.chart.series.forEach(function (e) {
        let i = e && e.tt;
        i && (!i.isActive || t ? e.tt = i.destroy() : i.isActive = !1);
      });
    }
    defaultFormatter(t) {
      let e;
      let i = this.points || oT(this);
      return (e = (e = [t.headerFooterFormatter(i[0])]).concat(t.bodyFormatter(i))).push(t.headerFooterFormatter(i[0], !0)), e;
    }
    destroy() {
      this.label && (this.label = this.label.destroy()), this.split && (this.cleanSplit(!0), this.tt && (this.tt = this.tt.destroy())), this.renderer && (this.renderer = this.renderer.destroy(), om(this.container)), J.clearTimeout(this.hideTimer);
    }
    getAnchor(t, e) {
      let i;
      let {
          chart: s,
          pointer: o
        } = this,
        r = s.inverted,
        a = s.plotTop,
        n = s.plotLeft;
      if ((t = oT(t))[0].series && t[0].series.yAxis && !t[0].series.yAxis.options.reversedStacks && (t = t.slice().reverse()), this.followPointer && e) void 0 === e.chartX && (e = o.normalize(e)), i = [e.chartX - n, e.chartY - a];else if (t[0].tooltipPos) i = t[0].tooltipPos;else {
        let s = 0,
          o = 0;
        t.forEach(function (t) {
          let e = t.pos(!0);
          e && (s += e[0], o += e[1]);
        }), s /= t.length, o /= t.length, this.shared && t.length > 1 && e && (r ? s = e.chartX : o = e.chartY), i = [s - n, o - a];
      }
      return i.map(Math.round);
    }
    getClassName(t, e, i) {
      let s = this.options,
        o = t.series,
        r = o.options;
      return [s.className, "highcharts-label", i && "highcharts-tooltip-header", e ? "highcharts-tooltip-box" : "highcharts-tooltip", !i && "highcharts-color-" + ok(t.colorIndex, o.colorIndex), r && r.className].filter(oM).join(" ");
    }
    getLabel({
      anchorX: t,
      anchorY: e
    } = {
      anchorX: 0,
      anchorY: 0
    }) {
      let i = this,
        s = this.chart.styledMode,
        o = this.options,
        r = this.split && this.allowShared,
        a = this.container,
        n = this.chart.renderer;
      if (this.label) {
        let t = !this.label.hasClass("highcharts-label");
        (!r && t || r && !t) && this.destroy();
      }
      if (!this.label) {
        if (this.outside) {
          let t = this.chart,
            e = t.options.chart.style,
            i = ec.getRendererType();
          this.container = a = A.doc.createElement("div"), a.className = "highcharts-tooltip-container " + (t.renderTo.className.match(/(highcharts[a-zA-Z0-9-]+)\s?/gm) || ""), og(a, {
            position: "absolute",
            top: "1px",
            pointerEvents: "none",
            zIndex: Math.max(this.options.style.zIndex || 0, (e && e.zIndex || 0) + 3)
          }), this.renderer = n = new i(a, 0, 0, e, void 0, void 0, n.styledMode);
        }
        if (r ? this.label = n.g("tooltip") : (this.label = n.label("", t, e, o.shape, void 0, void 0, o.useHTML, void 0, "tooltip").attr({
          padding: o.padding,
          r: o.borderRadius
        }), s || this.label.attr({
          fill: o.backgroundColor,
          "stroke-width": o.borderWidth || 0
        }).css(o.style).css({
          pointerEvents: o.style.pointerEvents || (this.shouldStickOnContact() ? "auto" : "none")
        })), i.outside) {
          let t = this.label;
          [t.xSetter, t.ySetter].forEach((e, s) => {
            t[s ? "ySetter" : "xSetter"] = o => {
              e.call(t, i.distance), t[s ? "y" : "x"] = o, a && (a.style[s ? "top" : "left"] = `${o}px`);
            };
          });
        }
        this.label.attr({
          zIndex: 8
        }).shadow(o.shadow).add();
      }
      return a && !a.parentElement && A.doc.body.appendChild(a), this.label;
    }
    getPlayingField() {
      let {
          body: t,
          documentElement: e
        } = oh,
        {
          chart: i,
          distance: s,
          outside: o
        } = this;
      return {
        width: o ? Math.max(t.scrollWidth, e.scrollWidth, t.offsetWidth, e.offsetWidth, e.clientWidth) - 2 * s - 2 : i.chartWidth,
        height: o ? Math.max(t.scrollHeight, e.scrollHeight, t.offsetHeight, e.offsetHeight, e.clientHeight) : i.chartHeight
      };
    }
    getPosition(t, e, i) {
      let {
          distance: s,
          chart: o,
          outside: r,
          pointer: a
        } = this,
        {
          inverted: n,
          plotLeft: l,
          plotTop: h,
          polar: d
        } = o,
        {
          plotX: c = 0,
          plotY: p = 0
        } = i,
        u = {},
        g = n && i.h || 0,
        {
          height: m,
          width: f
        } = this.getPlayingField(),
        x = a.getChartPosition(),
        y = t => t * x.scaleX,
        b = t => t * x.scaleY,
        v = i => {
          let a = "x" === i;
          return [i, a ? f : m, a ? t : e].concat(r ? [a ? y(t) : b(e), a ? x.left - s + y(c + l) : x.top - s + b(p + h), 0, a ? f : m] : [a ? t : e, a ? c + l : p + h, a ? l : h, a ? l + o.plotWidth : h + o.plotHeight]);
        },
        M = v("y"),
        w = v("x"),
        k,
        S = !!i.negative;
      !d && o.hoverSeries?.yAxis?.reversed && (S = !S);
      let T = !this.followPointer && ok(i.ttBelow, !d && !n === S),
        C = function (t, e, i, o, a, n, l) {
          let h = r ? "y" === t ? b(s) : y(s) : s,
            d = (i - o) / 2,
            c = o < a - s,
            p = a + s + o < e,
            m = a - h - i + d,
            f = a + h - d;
          if (T && p) u[t] = f;else if (!T && c) u[t] = m;else if (c) u[t] = Math.min(l - o, m - g < 0 ? m : m - g);else {
            if (!p) return !1;
            u[t] = Math.max(n, f + g + i > e ? f : f + g);
          }
        },
        A = function (t, e, i, o, r) {
          if (r < s || r > e - s) return !1;
          r < i / 2 ? u[t] = 1 : r > e - o / 2 ? u[t] = e - o - 2 : u[t] = r - i / 2;
        },
        P = function (t) {
          [M, w] = [w, M], k = t;
        },
        L = () => {
          !1 !== C.apply(0, M) ? !1 !== A.apply(0, w) || k || (P(!0), L()) : k ? u.x = u.y = 0 : (P(!0), L());
        };
      return (n && !d || this.len > 1) && P(), L(), u;
    }
    hide(t) {
      let e = this;
      J.clearTimeout(this.hideTimer), t = ok(t, this.options.hideDelay), this.isHidden || (this.hideTimer = oC(function () {
        let i = e.getLabel();
        e.getLabel().animate({
          opacity: 0
        }, {
          duration: t ? 150 : t,
          complete: () => {
            i.hide(), e.container && e.container.remove();
          }
        }), e.isHidden = !0;
      }, t));
    }
    init(t, e) {
      this.chart = t, this.options = e, this.crosshairs = [], this.isHidden = !0, this.split = e.split && !t.inverted && !t.polar, this.shared = e.shared || this.split, this.outside = ok(e.outside, !!(t.scrollablePixelsX || t.scrollablePixelsY));
    }
    shouldStickOnContact(t) {
      return !!(!this.followPointer && this.options.stickOnContact && (!t || this.pointer.inClass(t.target, "highcharts-tooltip")));
    }
    move(t, e, i, s) {
      let o = this,
        r = or(!o.isHidden && o.options.animation),
        a = o.followPointer || (o.len || 0) > 1,
        n = {
          x: t,
          y: e
        };
      a || (n.anchorX = i, n.anchorY = s), r.step = () => o.drawTracker(), o.getLabel().animate(n, r);
    }
    refresh(t, e) {
      let {
          chart: i,
          options: s,
          pointer: o,
          shared: r
        } = this,
        a = oT(t),
        n = a[0],
        l = s.format,
        h = s.formatter || this.defaultFormatter,
        d = i.styledMode,
        c = this.allowShared;
      if (!s.enabled || !n.series) return;
      J.clearTimeout(this.hideTimer), this.allowShared = !(!oy(t) && t.series && t.series.noSharedTooltip), c = c && !this.allowShared, this.followPointer = !this.split && n.series.tooltipOptions.followPointer;
      let p = this.getAnchor(t, e),
        u = p[0],
        g = p[1];
      r && this.allowShared && (o.applyInactiveState(a), a.forEach(t => t.setState("hover")), n.points = a), this.len = a.length;
      let m = oM(l) ? oa(l, n, i) : h.call(n, this);
      n.points = void 0;
      let f = n.series;
      if (this.distance = ok(f.tooltipOptions.distance, 16), !1 === m) this.hide();else {
        if (this.split && this.allowShared) this.renderSplit(m, a);else {
          let t = u,
            r = g;
          if (e && o.isDirectTouch && (t = e.chartX - i.plotLeft, r = e.chartY - i.plotTop), i.polar || !1 === f.options.clip || a.some(e => o.isDirectTouch || e.series.shouldShowTooltip(t, r))) {
            let t = this.getLabel(c && this.tt || {});
            (!s.style.width || d) && t.css({
              width: (this.outside ? this.getPlayingField() : i.spacingBox).width + "px"
            }), t.attr({
              class: this.getClassName(n),
              text: m && m.join ? m.join("") : m
            }), this.outside && t.attr({
              x: ou(t.x || 0, 0, this.getPlayingField().width - (t.width || 0) - 1)
            }), d || t.attr({
              stroke: s.borderColor || n.color || f.color || "#666666"
            }), this.updatePosition({
              plotX: u,
              plotY: g,
              negative: n.negative,
              ttBelow: n.ttBelow,
              h: p[2] || 0
            });
          } else {
            this.hide();
            return;
          }
        }
        this.isHidden && this.label && this.label.attr({
          opacity: 1
        }).show(), this.isHidden = !1;
      }
      ox(this, "refresh");
    }
    renderSplit(t, e) {
      let i = this,
        {
          chart: s,
          chart: {
            chartWidth: o,
            chartHeight: r,
            plotHeight: a,
            plotLeft: n,
            plotTop: l,
            scrollablePixelsY: h = 0,
            scrollablePixelsX: d,
            styledMode: c
          },
          distance: p,
          options: u,
          options: {
            positioner: g
          },
          pointer: m
        } = i,
        {
          scrollLeft: f = 0,
          scrollTop: x = 0
        } = s.scrollablePlotArea?.scrollingContainer || {},
        y = i.outside && "number" != typeof d ? oh.documentElement.getBoundingClientRect() : {
          left: f,
          right: f + o,
          top: x,
          bottom: x + r
        },
        b = i.getLabel(),
        v = this.renderer || s.renderer,
        M = !!(s.xAxis[0] && s.xAxis[0].opposite),
        {
          left: w,
          top: k
        } = m.getChartPosition(),
        S = l + x,
        T = 0,
        C = a - h;
      function A(t, e, s, o, r = !0) {
        let a, n;
        return s ? (a = M ? 0 : C, n = ou(t - o / 2, y.left, y.right - o - (i.outside ? w : 0))) : (a = e - S, n = ou(n = r ? t - o - p : t + p, r ? n : y.left, y.right)), {
          x: n,
          y: a
        };
      }
      oM(t) && (t = [!1, t]);
      let P = t.slice(0, e.length + 1).reduce(function (t, s, o) {
        if (!1 !== s && "" !== s) {
          let r = e[o - 1] || {
              isHeader: !0,
              plotX: e[0].plotX,
              plotY: a,
              series: {}
            },
            h = r.isHeader,
            d = h ? i : r.series,
            m = d.tt = function (t, e, s) {
              let o = t,
                {
                  isHeader: r,
                  series: a
                } = e;
              if (!o) {
                let t = {
                  padding: u.padding,
                  r: u.borderRadius
                };
                c || (t.fill = u.backgroundColor, t["stroke-width"] = u.borderWidth ?? 1), o = v.label("", 0, 0, u[r ? "headerShape" : "shape"], void 0, void 0, u.useHTML).addClass(i.getClassName(e, !0, r)).attr(t).add(b);
              }
              return o.isActive = !0, o.attr({
                text: s
              }), c || o.css(u.style).attr({
                stroke: u.borderColor || e.color || a.color || "#333333"
              }), o;
            }(d.tt, r, s.toString()),
            f = m.getBBox(),
            x = f.width + m.strokeWidth();
          h && (T = f.height, C += T, M && (S -= T));
          let {
            anchorX: w,
            anchorY: k
          } = function (t) {
            let e, i;
            let {
              isHeader: s,
              plotX: o = 0,
              plotY: r = 0,
              series: h
            } = t;
            if (s) e = Math.max(n + o, n), i = l + a / 2;else {
              let {
                xAxis: t,
                yAxis: s
              } = h;
              e = t.pos + ou(o, -p, t.len + p), h.shouldShowTooltip(0, s.pos - l + r, {
                ignoreX: !0
              }) && (i = s.pos + r);
            }
            return {
              anchorX: e = ou(e, y.left - p, y.right + p),
              anchorY: i
            };
          }(r);
          if ("number" == typeof k) {
            let e = f.height + 1,
              s = g ? g.call(i, x, e, r) : A(w, k, h, x);
            t.push({
              align: g ? 0 : void 0,
              anchorX: w,
              anchorY: k,
              boxWidth: x,
              point: r,
              rank: ok(s.rank, h ? 1 : 0),
              size: e,
              target: s.y,
              tt: m,
              x: s.x
            });
          } else m.isActive = !1;
        }
        return t;
      }, []);
      !g && P.some(t => {
        let {
            outside: e
          } = i,
          s = (e ? w : 0) + t.anchorX;
        return s < y.left && s + t.boxWidth < y.right || s < w - y.left + t.boxWidth && y.right - s > s;
      }) && (P = P.map(t => {
        let {
          x: e,
          y: i
        } = A(t.anchorX, t.anchorY, t.point.isHeader, t.boxWidth, !1);
        return of(t, {
          target: i,
          x: e
        });
      })), i.cleanSplit(), oc(P, C);
      let L = {
        left: w,
        right: w
      };
      P.forEach(function (t) {
        let {
          x: e,
          boxWidth: s,
          isHeader: o
        } = t;
        !o && (i.outside && w + e < L.left && (L.left = w + e), !o && i.outside && L.left + s > L.right && (L.right = w + e));
      }), P.forEach(function (t) {
        let {
            x: e,
            anchorX: s,
            anchorY: o,
            pos: r,
            point: {
              isHeader: a
            }
          } = t,
          n = {
            visibility: void 0 === r ? "hidden" : "inherit",
            x: e,
            y: (r || 0) + S,
            anchorX: s,
            anchorY: o
          };
        if (i.outside && e < s) {
          let t = w - L.left;
          t > 0 && (a || (n.x = e + t, n.anchorX = s + t), a && (n.x = (L.right - L.left) / 2, n.anchorX = s + t));
        }
        t.tt.attr(n);
      });
      let {
        container: O,
        outside: E,
        renderer: I
      } = i;
      if (E && O && I) {
        let {
          width: t,
          height: e,
          x: i,
          y: s
        } = b.getBBox();
        I.setSize(t + i, e + s, !1), O.style.left = L.left + "px", O.style.top = k + "px";
      }
      od && b.attr({
        opacity: 1 === b.opacity ? .999 : 1
      });
    }
    drawTracker() {
      if (!this.shouldStickOnContact()) {
        this.tracker && (this.tracker = this.tracker.destroy());
        return;
      }
      let t = this.chart,
        e = this.label,
        i = this.shared ? t.hoverPoints : t.hoverPoint;
      if (!e || !i) return;
      let s = {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        o = this.getAnchor(i),
        r = e.getBBox();
      o[0] += t.plotLeft - (e.translateX || 0), o[1] += t.plotTop - (e.translateY || 0), s.x = Math.min(0, o[0]), s.y = Math.min(0, o[1]), s.width = o[0] < 0 ? Math.max(Math.abs(o[0]), r.width - o[0]) : Math.max(Math.abs(o[0]), r.width), s.height = o[1] < 0 ? Math.max(Math.abs(o[1]), r.height - Math.abs(o[1])) : Math.max(Math.abs(o[1]), r.height), this.tracker ? this.tracker.attr(s) : (this.tracker = e.renderer.rect(s).addClass("highcharts-tracker").add(e), t.styledMode || this.tracker.attr({
        fill: "rgba(0,0,0,0)"
      }));
    }
    styledModeFormat(t) {
      return t.replace('style="font-size: 0.8em"', 'class="highcharts-header"').replace(/style="color:{(point|series)\.color}"/g, 'class="highcharts-color-{$1.colorIndex} {series.options.className} {point.options.className}"');
    }
    headerFooterFormatter(t, e) {
      let i = t.series,
        s = i.tooltipOptions,
        o = i.xAxis,
        r = o && o.dateTime,
        a = {
          isFooter: e,
          point: t
        },
        n = s.xDateFormat || "",
        l = s[e ? "footerFormat" : "headerFormat"];
      return ox(this, "headerFormatter", a, function (e) {
        if (r && !n && ob(t.key) && (n = r.getXDateFormat(t.key, s.dateTimeLabelFormats)), r && n) {
          if (ov(n)) {
            let t = n;
            ol[0] = e => i.chart.time.dateFormat(t, e), n = "%0";
          }
          (t.tooltipDateKeys || ["key"]).forEach(t => {
            l = l.replace(RegExp("point\\." + t + "([ \\)}])", ""), `(point.${t}:${n})$1`);
          });
        }
        i.chart.styledMode && (l = this.styledModeFormat(l)), e.text = oa(l, t, this.chart);
      }), a.text || "";
    }
    update(t) {
      this.destroy(), this.init(this.chart, ow(!0, this.options, t));
    }
    updatePosition(t) {
      let {
          chart: e,
          container: i,
          distance: s,
          options: o,
          pointer: r,
          renderer: a
        } = this,
        {
          height: n = 0,
          width: l = 0
        } = this.getLabel(),
        {
          left: h,
          top: d,
          scaleX: c,
          scaleY: p
        } = r.getChartPosition(),
        u = (o.positioner || this.getPosition).call(this, l, n, t),
        g = A.doc,
        m = (t.plotX || 0) + e.plotLeft,
        f = (t.plotY || 0) + e.plotTop,
        x;
      a && i && (o.positioner && (u.x += h - s, u.y += d - s), x = (o.borderWidth || 0) + 2 * s + 2, a.setSize(ou(l + x, 0, g.documentElement.clientWidth) - 1, n + x, !1), (1 !== c || 1 !== p) && (og(i, {
        transform: `scale(${c}, ${p})`
      }), m *= c, f *= p), m += h - u.x, f += d - u.y), this.move(Math.round(u.x), Math.round(u.y || 0), m, f);
    }
  }
  !function (t) {
    t.compose = function (e) {
      oS(on, "Core.Tooltip") && op(e, "afterInit", function () {
        let e = this.chart;
        e.options.tooltip && (e.tooltip = new t(e, e.options.tooltip, this));
      });
    };
  }(oA || (oA = {}));
  let oP = oA,
    {
      animObject: oL
    } = tH,
    {
      defaultOptions: oO
    } = tw,
    {
      format: oE
    } = ed,
    {
      addEvent: oI,
      crisp: oD,
      erase: oB,
      extend: oz,
      fireEvent: oN,
      getNestedProperty: oR,
      isArray: oW,
      isFunction: oj,
      isNumber: oX,
      isObject: oG,
      merge: oF,
      pick: oH,
      syncTimeout: oY,
      removeEvent: oV,
      uniqueKey: oU
    } = J;
  class oZ {
    animateBeforeDestroy() {
      let t = this,
        e = {
          x: t.startXPos,
          opacity: 0
        },
        i = t.getGraphicalProps();
      i.singular.forEach(function (i) {
        t[i] = t[i].animate("dataLabel" === i ? {
          x: t[i].startXPos,
          y: t[i].startYPos,
          opacity: 0
        } : e);
      }), i.plural.forEach(function (e) {
        t[e].forEach(function (e) {
          e.element && e.animate(oz({
            x: t.startXPos
          }, e.startYPos ? {
            x: e.startXPos,
            y: e.startYPos
          } : {}));
        });
      });
    }
    applyOptions(t, e) {
      let i = this.series,
        s = i.options.pointValKey || i.pointValKey;
      return oz(this, t = oZ.prototype.optionsToObject.call(this, t)), this.options = this.options ? oz(this.options, t) : t, t.group && delete this.group, t.dataLabels && delete this.dataLabels, s && (this.y = oZ.prototype.getNestedProperty.call(this, s)), this.selected && (this.state = "select"), "name" in this && void 0 === e && i.xAxis && i.xAxis.hasNames && (this.x = i.xAxis.nameToX(this)), void 0 === this.x && i ? this.x = e ?? i.autoIncrement() : oX(t.x) && i.options.relativeXValue ? this.x = i.autoIncrement(t.x) : "string" == typeof this.x && (e ?? (e = i.chart.time.parse(this.x)), oX(e) && (this.x = e)), this.isNull = this.isValid && !this.isValid(), this.formatPrefix = this.isNull ? "null" : "point", this;
    }
    destroy() {
      if (!this.destroyed) {
        let t = this,
          e = t.series,
          i = e.chart,
          s = e.options.dataSorting,
          o = i.hoverPoints,
          r = oL(t.series.chart.renderer.globalAnimation),
          a = () => {
            for (let e in (t.graphic || t.graphics || t.dataLabel || t.dataLabels) && (oV(t), t.destroyElements()), t) delete t[e];
          };
        t.legendItem && i.legend.destroyItem(t), o && (t.setState(), oB(o, t), o.length || (i.hoverPoints = null)), t === i.hoverPoint && t.onMouseOut(), s && s.enabled ? (this.animateBeforeDestroy(), oY(a, r.duration)) : a(), i.pointCount--;
      }
      this.destroyed = !0;
    }
    destroyElements(t) {
      let e = this,
        i = e.getGraphicalProps(t);
      i.singular.forEach(function (t) {
        e[t] = e[t].destroy();
      }), i.plural.forEach(function (t) {
        e[t].forEach(function (t) {
          t && t.element && t.destroy();
        }), delete e[t];
      });
    }
    firePointEvent(t, e, i) {
      let s = this,
        o = this.series.options;
      s.manageEvent(t), "click" === t && o.allowPointSelect && (i = function (t) {
        !s.destroyed && s.select && s.select(null, t.ctrlKey || t.metaKey || t.shiftKey);
      }), oN(s, t, e, i);
    }
    getClassName() {
      return "highcharts-point" + (this.selected ? " highcharts-point-select" : "") + (this.negative ? " highcharts-negative" : "") + (this.isNull ? " highcharts-null-point" : "") + (void 0 !== this.colorIndex ? " highcharts-color-" + this.colorIndex : "") + (this.options.className ? " " + this.options.className : "") + (this.zone && this.zone.className ? " " + this.zone.className.replace("highcharts-negative", "") : "");
    }
    getGraphicalProps(t) {
      let e, i;
      let s = this,
        o = [],
        r = {
          singular: [],
          plural: []
        };
      for ((t = t || {
        graphic: 1,
        dataLabel: 1
      }).graphic && o.push("graphic", "connector"), t.dataLabel && o.push("dataLabel", "dataLabelPath", "dataLabelUpper"), i = o.length; i--;) s[e = o[i]] && r.singular.push(e);
      return ["graphic", "dataLabel"].forEach(function (e) {
        let i = e + "s";
        t[e] && s[i] && r.plural.push(i);
      }), r;
    }
    getNestedProperty(t) {
      return t ? 0 === t.indexOf("custom.") ? oR(t, this.options) : this[t] : void 0;
    }
    getZone() {
      let t = this.series,
        e = t.zones,
        i = t.zoneAxis || "y",
        s,
        o = 0;
      for (s = e[0]; this[i] >= s.value;) s = e[++o];
      return this.nonZonedColor || (this.nonZonedColor = this.color), s && s.color && !this.options.color ? this.color = s.color : this.color = this.nonZonedColor, s;
    }
    hasNewShapeType() {
      return (this.graphic && (this.graphic.symbolName || this.graphic.element.nodeName)) !== this.shapeType;
    }
    constructor(t, e, i) {
      this.formatPrefix = "point", this.visible = !0, this.point = this, this.series = t, this.applyOptions(e, i), this.id ?? (this.id = oU()), this.resolveColor(), t.chart.pointCount++, oN(this, "afterInit");
    }
    isValid() {
      return (oX(this.x) || this.x instanceof Date) && oX(this.y);
    }
    optionsToObject(t) {
      let e = this.series,
        i = e.options.keys,
        s = i || e.pointArrayMap || ["y"],
        o = s.length,
        r = {},
        a,
        n = 0,
        l = 0;
      if (oX(t) || null === t) r[s[0]] = t;else if (oW(t)) for (!i && t.length > o && ("string" == (a = typeof t[0]) ? e.xAxis?.dateTime ? r.x = e.chart.time.parse(t[0]) : r.name = t[0] : "number" === a && (r.x = t[0]), n++); l < o;) i && void 0 === t[n] || (s[l].indexOf(".") > 0 ? oZ.prototype.setNestedProperty(r, t[n], s[l]) : r[s[l]] = t[n]), n++, l++;else "object" == typeof t && (r = t, t.dataLabels && (e.hasDataLabels = () => !0), t.marker && (e._hasPointMarkers = !0));
      return r;
    }
    pos(t, e = this.plotY) {
      if (!this.destroyed) {
        let {
            plotX: i,
            series: s
          } = this,
          {
            chart: o,
            xAxis: r,
            yAxis: a
          } = s,
          n = 0,
          l = 0;
        if (oX(i) && oX(e)) return t && (n = r ? r.pos : o.plotLeft, l = a ? a.pos : o.plotTop), o.inverted && r && a ? [a.len - e + l, r.len - i + n] : [i + n, e + l];
      }
    }
    resolveColor() {
      let t = this.series,
        e = t.chart.options.chart,
        i = t.chart.styledMode,
        s,
        o,
        r = e.colorCount,
        a;
      delete this.nonZonedColor, t.options.colorByPoint ? (i || (s = (o = t.options.colors || t.chart.options.colors)[t.colorCounter], r = o.length), a = t.colorCounter, t.colorCounter++, t.colorCounter === r && (t.colorCounter = 0)) : (i || (s = t.color), a = t.colorIndex), this.colorIndex = oH(this.options.colorIndex, a), this.color = oH(this.options.color, s);
    }
    setNestedProperty(t, e, i) {
      return i.split(".").reduce(function (t, i, s, o) {
        let r = o.length - 1 === s;
        return t[i] = r ? e : oG(t[i], !0) ? t[i] : {}, t[i];
      }, t), t;
    }
    shouldDraw() {
      return !this.isNull;
    }
    tooltipFormatter(t) {
      let {
          chart: e,
          pointArrayMap: i = ["y"],
          tooltipOptions: s
        } = this.series,
        {
          valueDecimals: o = "",
          valuePrefix: r = "",
          valueSuffix: a = ""
        } = s;
      return e.styledMode && (t = e.tooltip?.styledModeFormat(t) || t), i.forEach(e => {
        e = "{point." + e, (r || a) && (t = t.replace(RegExp(e + "}", "g"), r + e + "}" + a)), t = t.replace(RegExp(e + "}", "g"), e + ":,." + o + "f}");
      }), oE(t, this, e);
    }
    update(t, e, i, s) {
      let o;
      let r = this,
        a = r.series,
        n = r.graphic,
        l = a.chart,
        h = a.options;
      function d() {
        r.applyOptions(t);
        let s = n && r.hasMockGraphic,
          d = null === r.y ? !s : s;
        n && d && (r.graphic = n.destroy(), delete r.hasMockGraphic), oG(t, !0) && (n && n.element && t && t.marker && void 0 !== t.marker.symbol && (r.graphic = n.destroy()), t?.dataLabels && r.dataLabel && (r.dataLabel = r.dataLabel.destroy())), o = r.index;
        let c = {};
        for (let t of a.dataColumnKeys()) c[t] = r[t];
        a.dataTable.setRow(c, o), h.data[o] = oG(h.data[o], !0) || oG(t, !0) ? r.options : oH(t, h.data[o]), a.isDirty = a.isDirtyData = !0, !a.fixedBox && a.hasCartesianSeries && (l.isDirtyBox = !0), "point" === h.legendType && (l.isDirtyLegend = !0), e && l.redraw(i);
      }
      e = oH(e, !0), !1 === s ? d() : r.firePointEvent("update", {
        options: t
      }, d);
    }
    remove(t, e) {
      this.series.removePoint(this.series.data.indexOf(this), t, e);
    }
    select(t, e) {
      let i = this,
        s = i.series,
        o = s.chart;
      t = oH(t, !i.selected), this.selectedStaging = t, i.firePointEvent(t ? "select" : "unselect", {
        accumulate: e
      }, function () {
        i.selected = i.options.selected = t, s.options.data[s.data.indexOf(i)] = i.options, i.setState(t && "select"), e || o.getSelectedPoints().forEach(function (t) {
          let e = t.series;
          t.selected && t !== i && (t.selected = t.options.selected = !1, e.options.data[e.data.indexOf(t)] = t.options, t.setState(o.hoverPoints && e.options.inactiveOtherPoints ? "inactive" : ""), t.firePointEvent("unselect"));
        });
      }), delete this.selectedStaging;
    }
    onMouseOver(t) {
      let {
        inverted: e,
        pointer: i
      } = this.series.chart;
      i && (t = t ? i.normalize(t) : i.getChartCoordinatesFromPoint(this, e), i.runPointActions(t, this));
    }
    onMouseOut() {
      let t = this.series.chart;
      this.firePointEvent("mouseOut"), this.series.options.inactiveOtherPoints || (t.hoverPoints || []).forEach(function (t) {
        t.setState();
      }), t.hoverPoints = t.hoverPoint = null;
    }
    manageEvent(t) {
      let e = oF(this.series.options.point, this.options),
        i = e.events?.[t];
      oj(i) && (!this.hcEvents?.[t] || this.hcEvents?.[t]?.map(t => t.fn).indexOf(i) === -1) ? (this.importedUserEvent?.(), this.importedUserEvent = oI(this, t, i), this.hcEvents && (this.hcEvents[t].userEvent = !0)) : this.importedUserEvent && !i && this.hcEvents?.[t] && this.hcEvents?.[t].userEvent && (oV(this, t), delete this.hcEvents[t], Object.keys(this.hcEvents) || delete this.importedUserEvent);
    }
    setState(t, e) {
      let i = this.series,
        s = this.state,
        o = i.options.states[t || "normal"] || {},
        r = oO.plotOptions[i.type].marker && i.options.marker,
        a = r && !1 === r.enabled,
        n = r && r.states && r.states[t || "normal"] || {},
        l = !1 === n.enabled,
        h = this.marker || {},
        d = i.chart,
        c = r && i.markerAttribs,
        p = i.halo,
        u,
        g,
        m,
        f = i.stateMarkerGraphic,
        x;
      if ((t = t || "") === this.state && !e || this.selected && "select" !== t || !1 === o.enabled || t && (l || a && !1 === n.enabled) || t && h.states && h.states[t] && !1 === h.states[t].enabled) return;
      if (this.state = t, c && (u = i.markerAttribs(this, t)), this.graphic && !this.hasMockGraphic) {
        if (s && this.graphic.removeClass("highcharts-point-" + s), t && this.graphic.addClass("highcharts-point-" + t), !d.styledMode) {
          g = i.pointAttribs(this, t), m = oH(d.options.chart.animation, o.animation);
          let e = g.opacity;
          i.options.inactiveOtherPoints && oX(e) && (this.dataLabels || []).forEach(function (t) {
            t && !t.hasClass("highcharts-data-label-hidden") && (t.animate({
              opacity: e
            }, m), t.connector && t.connector.animate({
              opacity: e
            }, m));
          }), this.graphic.animate(g, m);
        }
        u && this.graphic.animate(u, oH(d.options.chart.animation, n.animation, r.animation)), f && f.hide();
      } else t && n && (x = h.symbol || i.symbol, f && f.currentSymbol !== x && (f = f.destroy()), u && (f ? f[e ? "animate" : "attr"]({
        x: u.x,
        y: u.y
      }) : x && (i.stateMarkerGraphic = f = d.renderer.symbol(x, u.x, u.y, u.width, u.height, oF(r, n)).add(i.markerGroup), f.currentSymbol = x)), !d.styledMode && f && "inactive" !== this.state && f.attr(i.pointAttribs(this, t))), f && (f[t && this.isInside ? "show" : "hide"](), f.element.point = this, f.addClass(this.getClassName(), !0));
      let y = o.halo,
        b = this.graphic || f,
        v = b && b.visibility || "inherit";
      y && y.size && b && "hidden" !== v && !this.isCluster ? (p || (i.halo = p = d.renderer.path().add(b.parentGroup)), p.show()[e ? "animate" : "attr"]({
        d: this.haloPath(y.size)
      }), p.attr({
        class: "highcharts-halo highcharts-color-" + oH(this.colorIndex, i.colorIndex) + (this.className ? " " + this.className : ""),
        visibility: v,
        zIndex: -1
      }), p.point = this, d.styledMode || p.attr(oz({
        fill: this.color || i.color,
        "fill-opacity": y.opacity
      }, t3.filterUserAttributes(y.attributes || {})))) : p?.point?.haloPath && !p.point.destroyed && p.animate({
        d: p.point.haloPath(0)
      }, null, p.hide), oN(this, "afterSetState", {
        state: t
      });
    }
    haloPath(t) {
      let e = this.pos();
      return e ? this.series.chart.renderer.symbols.circle(oD(e[0], 1) - t, e[1] - t, 2 * t, 2 * t) : [];
    }
  }
  let o$ = oZ,
    {
      parse: o_
    } = tA,
    {
      charts: oq,
      composed: oK,
      isTouchDevice: oJ
    } = A,
    {
      addEvent: oQ,
      attr: o0,
      css: o1,
      extend: o2,
      find: o3,
      fireEvent: o6,
      isNumber: o5,
      isObject: o9,
      objectEach: o8,
      offset: o4,
      pick: o7,
      pushUnique: rt,
      splat: re
    } = J;
  class ri {
    applyInactiveState(t) {
      let e = [],
        i;
      (t || []).forEach(function (t) {
        i = t.series, e.push(i), i.linkedParent && e.push(i.linkedParent), i.linkedSeries && (e = e.concat(i.linkedSeries)), i.navigatorSeries && e.push(i.navigatorSeries);
      }), this.chart.series.forEach(function (t) {
        -1 === e.indexOf(t) ? t.setState("inactive", !0) : t.options.inactiveOtherPoints && t.setAllPointsToState("inactive");
      });
    }
    destroy() {
      let t = this;
      this.eventsToUnbind.forEach(t => t()), this.eventsToUnbind = [], !A.chartCount && (ri.unbindDocumentMouseUp.forEach(t => t.unbind()), ri.unbindDocumentMouseUp.length = 0, ri.unbindDocumentTouchEnd && (ri.unbindDocumentTouchEnd = ri.unbindDocumentTouchEnd())), clearInterval(t.tooltipTimeout), o8(t, function (e, i) {
        t[i] = void 0;
      });
    }
    getSelectionMarkerAttrs(t, e) {
      let i = {
        args: {
          chartX: t,
          chartY: e
        },
        attrs: {},
        shapeType: "rect"
      };
      return o6(this, "getSelectionMarkerAttrs", i, i => {
        let s;
        let {
            chart: o,
            zoomHor: r,
            zoomVert: a
          } = this,
          {
            mouseDownX: n = 0,
            mouseDownY: l = 0
          } = o,
          h = i.attrs;
        h.x = o.plotLeft, h.y = o.plotTop, h.width = r ? 1 : o.plotWidth, h.height = a ? 1 : o.plotHeight, r && (s = t - n, h.width = Math.max(1, Math.abs(s)), h.x = (s > 0 ? 0 : s) + n), a && (s = e - l, h.height = Math.max(1, Math.abs(s)), h.y = (s > 0 ? 0 : s) + l);
      }), i;
    }
    drag(t) {
      let {
          chart: e
        } = this,
        {
          mouseDownX: i = 0,
          mouseDownY: s = 0
        } = e,
        {
          panning: o,
          panKey: r,
          selectionMarkerFill: a
        } = e.options.chart,
        n = e.plotLeft,
        l = e.plotTop,
        h = e.plotWidth,
        d = e.plotHeight,
        c = o9(o) ? o.enabled : o,
        p = r && t[`${r}Key`],
        u = t.chartX,
        g = t.chartY,
        m,
        f = this.selectionMarker;
      if ((!f || !f.touch) && (u < n ? u = n : u > n + h && (u = n + h), g < l ? g = l : g > l + d && (g = l + d), this.hasDragged = Math.sqrt(Math.pow(i - u, 2) + Math.pow(s - g, 2)), this.hasDragged > 10)) {
        m = e.isInsidePlot(i - n, s - l, {
          visiblePlotOnly: !0
        });
        let {
          shapeType: r,
          attrs: h
        } = this.getSelectionMarkerAttrs(u, g);
        (e.hasCartesianSeries || e.mapView) && this.hasZoom && m && !p && !f && (this.selectionMarker = f = e.renderer[r](), f.attr({
          class: "highcharts-selection-marker",
          zIndex: 7
        }).add(), e.styledMode || f.attr({
          fill: a || o_("#334eff").setOpacity(.25).get()
        })), f && f.attr(h), m && !f && c && e.pan(t, o);
      }
    }
    dragStart(t) {
      let e = this.chart;
      e.mouseIsDown = t.type, e.cancelClick = !1, e.mouseDownX = t.chartX, e.mouseDownY = t.chartY;
    }
    getSelectionBox(t) {
      let e = {
        args: {
          marker: t
        },
        result: t.getBBox()
      };
      return o6(this, "getSelectionBox", e), e.result;
    }
    drop(t) {
      let e;
      let {
        chart: i,
        selectionMarker: s
      } = this;
      for (let t of i.axes) t.isPanning && (t.isPanning = !1, (t.options.startOnTick || t.options.endOnTick || t.series.some(t => t.boosted)) && (t.forceRedraw = !0, t.setExtremes(t.userMin, t.userMax, !1), e = !0));
      if (e && i.redraw(), s && t) {
        if (this.hasDragged) {
          let e = this.getSelectionBox(s);
          i.transform({
            axes: i.axes.filter(t => t.zoomEnabled && ("xAxis" === t.coll && this.zoomX || "yAxis" === t.coll && this.zoomY)),
            selection: {
              originalEvent: t,
              xAxis: [],
              yAxis: [],
              ...e
            },
            from: e
          });
        }
        o5(i.index) && (this.selectionMarker = s.destroy());
      }
      i && o5(i.index) && (o1(i.container, {
        cursor: i._cursor
      }), i.cancelClick = this.hasDragged > 10, i.mouseIsDown = !1, this.hasDragged = 0, this.pinchDown = []);
    }
    findNearestKDPoint(t, e, i) {
      let s;
      return t.forEach(function (t) {
        let o = !(t.noSharedTooltip && e) && 0 > t.options.findNearestPointBy.indexOf("y"),
          r = t.searchPoint(i, o);
        o9(r, !0) && r.series && (!o9(s, !0) || function (t, i) {
          let s = t.distX - i.distX,
            o = t.dist - i.dist,
            r = i.series.group?.zIndex - t.series.group?.zIndex;
          return 0 !== s && e ? s : 0 !== o ? o : 0 !== r ? r : t.series.index > i.series.index ? -1 : 1;
        }(s, r) > 0) && (s = r);
      }), s;
    }
    getChartCoordinatesFromPoint(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = t.series,
        o = t.shapeArgs;
      if (i && s) {
        let r = t.clientX ?? t.plotX ?? 0,
          a = t.plotY || 0;
        return t.isNode && o && o5(o.x) && o5(o.y) && (r = o.x, a = o.y), e ? {
          chartX: s.len + s.pos - a,
          chartY: i.len + i.pos - r
        } : {
          chartX: r + i.pos,
          chartY: a + s.pos
        };
      }
      if (o && o.x && o.y) return {
        chartX: o.x,
        chartY: o.y
      };
    }
    getChartPosition() {
      if (this.chartPosition) return this.chartPosition;
      let {
          container: t
        } = this.chart,
        e = o4(t);
      this.chartPosition = {
        left: e.left,
        top: e.top,
        scaleX: 1,
        scaleY: 1
      };
      let {
        offsetHeight: i,
        offsetWidth: s
      } = t;
      return s > 2 && i > 2 && (this.chartPosition.scaleX = e.width / s, this.chartPosition.scaleY = e.height / i), this.chartPosition;
    }
    getCoordinates(t) {
      let e = {
        xAxis: [],
        yAxis: []
      };
      for (let i of this.chart.axes) e[i.isXAxis ? "xAxis" : "yAxis"].push({
        axis: i,
        value: i.toValue(t[i.horiz ? "chartX" : "chartY"])
      });
      return e;
    }
    getHoverData(t, e, i, s, o, r) {
      let a = [],
        n = function (t) {
          return t.visible && !(!o && t.directTouch) && o7(t.options.enableMouseTracking, !0);
        },
        l = e,
        h,
        d = {
          chartX: r ? r.chartX : void 0,
          chartY: r ? r.chartY : void 0,
          shared: o
        };
      o6(this, "beforeGetHoverData", d), h = l && !l.stickyTracking ? [l] : i.filter(t => t.stickyTracking && (d.filter || n)(t));
      let c = s && t || !r ? t : this.findNearestKDPoint(h, o, r);
      return l = c && c.series, c && (o && !l.noSharedTooltip ? (h = i.filter(function (t) {
        return d.filter ? d.filter(t) : n(t) && !t.noSharedTooltip;
      })).forEach(function (t) {
        let e = o3(t.points, function (t) {
          return t.x === c.x && !t.isNull;
        });
        o9(e) && (t.boosted && t.boost && (e = t.boost.getPoint(e)), a.push(e));
      }) : a.push(c)), o6(this, "afterGetHoverData", d = {
        hoverPoint: c
      }), {
        hoverPoint: d.hoverPoint,
        hoverSeries: l,
        hoverPoints: a
      };
    }
    getPointFromEvent(t) {
      let e = t.target,
        i;
      for (; e && !i;) i = e.point, e = e.parentNode;
      return i;
    }
    onTrackerMouseOut(t) {
      let e = this.chart,
        i = t.relatedTarget,
        s = e.hoverSeries;
      this.isDirectTouch = !1, !s || !i || s.stickyTracking || this.inClass(i, "highcharts-tooltip") || this.inClass(i, "highcharts-series-" + s.index) && this.inClass(i, "highcharts-tracker") || s.onMouseOut();
    }
    inClass(t, e) {
      let i = t,
        s;
      for (; i;) {
        if (s = o0(i, "class")) {
          if (-1 !== s.indexOf(e)) return !0;
          if (-1 !== s.indexOf("highcharts-container")) return !1;
        }
        i = i.parentElement;
      }
    }
    constructor(t, e) {
      this.hasDragged = 0, this.pointerCaptureEventsToUnbind = [], this.eventsToUnbind = [], this.options = e, this.chart = t, this.runChartClick = !!e.chart.events?.click, this.pinchDown = [], this.setDOMEvents(), o6(this, "afterInit");
    }
    normalize(t, e) {
      let i = t.touches,
        s = i ? i.length ? i.item(0) : o7(i.changedTouches, t.changedTouches)[0] : t;
      e || (e = this.getChartPosition());
      let o = s.pageX - e.left,
        r = s.pageY - e.top;
      return o2(t, {
        chartX: Math.round(o /= e.scaleX),
        chartY: Math.round(r /= e.scaleY)
      });
    }
    onContainerClick(t) {
      let e = this.chart,
        i = e.hoverPoint,
        s = this.normalize(t),
        o = e.plotLeft,
        r = e.plotTop;
      !e.cancelClick && (i && this.inClass(s.target, "highcharts-tracker") ? (o6(i.series, "click", o2(s, {
        point: i
      })), e.hoverPoint && i.firePointEvent("click", s)) : (o2(s, this.getCoordinates(s)), e.isInsidePlot(s.chartX - o, s.chartY - r, {
        visiblePlotOnly: !0
      }) && o6(e, "click", s)));
    }
    onContainerMouseDown(t) {
      let e = (1 & (t.buttons || t.button)) == 1;
      t = this.normalize(t), A.isFirefox && 0 !== t.button && this.onContainerMouseMove(t), (void 0 === t.button || e) && (this.zoomOption(t), e && t.preventDefault?.(), this.dragStart(t));
    }
    onContainerMouseLeave(t) {
      let {
        pointer: e
      } = oq[o7(ri.hoverChartIndex, -1)] || {};
      t = this.normalize(t), this.onContainerMouseMove(t), e && !this.inClass(t.relatedTarget, "highcharts-tooltip") && (e.reset(), e.chartPosition = void 0);
    }
    onContainerMouseEnter() {
      delete this.chartPosition;
    }
    onContainerMouseMove(t) {
      let e = this.chart,
        i = e.tooltip,
        s = this.normalize(t);
      this.setHoverChartIndex(t), ("mousedown" === e.mouseIsDown || this.touchSelect(s)) && this.drag(s), !e.openMenu && (this.inClass(s.target, "highcharts-tracker") || e.isInsidePlot(s.chartX - e.plotLeft, s.chartY - e.plotTop, {
        visiblePlotOnly: !0
      })) && !(i && i.shouldStickOnContact(s)) && (this.inClass(s.target, "highcharts-no-tooltip") ? this.reset(!1, 0) : this.runPointActions(s));
    }
    onDocumentTouchEnd(t) {
      this.onDocumentMouseUp(t);
    }
    onContainerTouchMove(t) {
      this.touchSelect(t) ? this.onContainerMouseMove(t) : this.touch(t);
    }
    onContainerTouchStart(t) {
      this.touchSelect(t) ? this.onContainerMouseDown(t) : (this.zoomOption(t), this.touch(t, !0));
    }
    onDocumentMouseMove(t) {
      let e = this.chart,
        i = e.tooltip,
        s = this.chartPosition,
        o = this.normalize(t, s);
      !s || e.isInsidePlot(o.chartX - e.plotLeft, o.chartY - e.plotTop, {
        visiblePlotOnly: !0
      }) || i && i.shouldStickOnContact(o) || o.target !== e.container.ownerDocument && this.inClass(o.target, "highcharts-tracker") || this.reset();
    }
    onDocumentMouseUp(t) {
      oq[o7(ri.hoverChartIndex, -1)]?.pointer?.drop(t);
    }
    pinch(t) {
      let e = this,
        {
          chart: i,
          hasZoom: s,
          lastTouches: o
        } = e,
        r = [].map.call(t.touches || [], t => e.normalize(t)),
        a = r.length,
        n = 1 === a && (e.inClass(t.target, "highcharts-tracker") && i.runTrackerClick || e.runChartClick),
        l = i.tooltip,
        h = 1 === a && o7(l?.options.followTouchMove, !0);
      a > 1 ? e.initiated = !0 : h && (e.initiated = !1), s && e.initiated && !n && !1 !== t.cancelable && t.preventDefault(), "touchstart" === t.type ? (e.pinchDown = r, e.res = !0, i.mouseDownX = t.chartX) : h ? this.runPointActions(e.normalize(t)) : o && (o6(i, "touchpan", {
        originalEvent: t,
        touches: r
      }, () => {
        let e = t => {
          let e = t[0],
            i = t[1] || e;
          return {
            x: e.chartX,
            y: e.chartY,
            width: i.chartX - e.chartX,
            height: i.chartY - e.chartY
          };
        };
        i.transform({
          axes: i.axes.filter(t => t.zoomEnabled && (this.zoomHor && t.horiz || this.zoomVert && !t.horiz)),
          to: e(r),
          from: e(o),
          trigger: t.type
        });
      }), e.res && (e.res = !1, this.reset(!1, 0))), e.lastTouches = r;
    }
    reset(t, e) {
      let i = this.chart,
        s = i.hoverSeries,
        o = i.hoverPoint,
        r = i.hoverPoints,
        a = i.tooltip,
        n = a && a.shared ? r : o;
      t && n && re(n).forEach(function (e) {
        e.series.isCartesian && void 0 === e.plotX && (t = !1);
      }), t ? a && n && re(n).length && (a.refresh(n), a.shared && r ? r.forEach(function (t) {
        t.setState(t.state, !0), t.series.isCartesian && (t.series.xAxis.crosshair && t.series.xAxis.drawCrosshair(null, t), t.series.yAxis.crosshair && t.series.yAxis.drawCrosshair(null, t));
      }) : o && (o.setState(o.state, !0), i.axes.forEach(function (t) {
        t.crosshair && o.series[t.coll] === t && t.drawCrosshair(null, o);
      }))) : (o && o.onMouseOut(), r && r.forEach(function (t) {
        t.setState();
      }), s && s.onMouseOut(), a && a.hide(e), this.unDocMouseMove && (this.unDocMouseMove = this.unDocMouseMove()), i.axes.forEach(function (t) {
        t.hideCrosshair();
      }), i.hoverPoints = i.hoverPoint = void 0);
    }
    runPointActions(t, e, i) {
      let s = this.chart,
        o = s.series,
        r = s.tooltip && s.tooltip.options.enabled ? s.tooltip : void 0,
        a = !!r && r.shared,
        n = e || s.hoverPoint,
        l = n && n.series || s.hoverSeries,
        h = (!t || "touchmove" !== t.type) && (!!e || l && l.directTouch && this.isDirectTouch),
        d = this.getHoverData(n, l, o, h, a, t);
      n = d.hoverPoint, l = d.hoverSeries;
      let c = d.hoverPoints,
        p = l && l.tooltipOptions.followPointer && !l.tooltipOptions.split,
        u = a && l && !l.noSharedTooltip;
      if (n && (i || n !== s.hoverPoint || r && r.isHidden)) {
        if ((s.hoverPoints || []).forEach(function (t) {
          -1 === c.indexOf(t) && t.setState();
        }), s.hoverSeries !== l && l.onMouseOver(), this.applyInactiveState(c), (c || []).forEach(function (t) {
          t.setState("hover");
        }), s.hoverPoint && s.hoverPoint.firePointEvent("mouseOut"), !n.series) return;
        s.hoverPoints = c, s.hoverPoint = n, n.firePointEvent("mouseOver", void 0, () => {
          r && n && r.refresh(u ? c : n, t);
        });
      } else if (p && r && !r.isHidden) {
        let e = r.getAnchor([{}], t);
        s.isInsidePlot(e[0], e[1], {
          visiblePlotOnly: !0
        }) && r.updatePosition({
          plotX: e[0],
          plotY: e[1]
        });
      }
      this.unDocMouseMove || (this.unDocMouseMove = oQ(s.container.ownerDocument, "mousemove", t => oq[ri.hoverChartIndex ?? -1]?.pointer?.onDocumentMouseMove(t)), this.eventsToUnbind.push(this.unDocMouseMove)), s.axes.forEach(function (e) {
        let i;
        let o = o7((e.crosshair || {}).snap, !0);
        !o || (i = s.hoverPoint) && i.series[e.coll] === e || (i = o3(c, t => t.series && t.series[e.coll] === e)), i || !o ? e.drawCrosshair(t, i) : e.hideCrosshair();
      });
    }
    setDOMEvents() {
      let t = this.chart.container,
        e = t.ownerDocument;
      t.onmousedown = this.onContainerMouseDown.bind(this), t.onmousemove = this.onContainerMouseMove.bind(this), t.onclick = this.onContainerClick.bind(this), this.eventsToUnbind.push(oQ(t, "mouseenter", this.onContainerMouseEnter.bind(this)), oQ(t, "mouseleave", this.onContainerMouseLeave.bind(this))), ri.unbindDocumentMouseUp.some(t => t.doc === e) || ri.unbindDocumentMouseUp.push({
        doc: e,
        unbind: oQ(e, "mouseup", this.onDocumentMouseUp.bind(this))
      });
      let i = this.chart.renderTo.parentElement;
      for (; i && "BODY" !== i.tagName;) this.eventsToUnbind.push(oQ(i, "scroll", () => {
        delete this.chartPosition;
      })), i = i.parentElement;
      this.eventsToUnbind.push(oQ(t, "touchstart", this.onContainerTouchStart.bind(this), {
        passive: !1
      }), oQ(t, "touchmove", this.onContainerTouchMove.bind(this), {
        passive: !1
      })), ri.unbindDocumentTouchEnd || (ri.unbindDocumentTouchEnd = oQ(e, "touchend", this.onDocumentTouchEnd.bind(this), {
        passive: !1
      })), this.setPointerCapture(), oQ(this.chart, "redraw", this.setPointerCapture.bind(this));
    }
    setPointerCapture() {
      if (!oJ) return;
      let t = this.pointerCaptureEventsToUnbind,
        e = this.chart,
        i = e.container,
        s = o7(e.options.tooltip?.followTouchMove, !0) && e.series.some(t => t.options.findNearestPointBy.indexOf("y") > -1);
      !this.hasPointerCapture && s ? (t.push(oQ(i, "pointerdown", t => {
        t.target?.hasPointerCapture(t.pointerId) && t.target?.releasePointerCapture(t.pointerId);
      }), oQ(i, "pointermove", t => {
        e.pointer?.getPointFromEvent(t)?.onMouseOver(t);
      })), e.styledMode || o1(i, {
        "touch-action": "none"
      }), i.className += " highcharts-no-touch-action", this.hasPointerCapture = !0) : this.hasPointerCapture && !s && (t.forEach(t => t()), t.length = 0, e.styledMode || o1(i, {
        "touch-action": o7(e.options.chart.style?.["touch-action"], "manipulation")
      }), i.className = i.className.replace(" highcharts-no-touch-action", ""), this.hasPointerCapture = !1);
    }
    setHoverChartIndex(t) {
      let e = this.chart,
        i = A.charts[o7(ri.hoverChartIndex, -1)];
      if (i && i !== e) {
        let s = {
          relatedTarget: e.container
        };
        t && !t?.relatedTarget && Object.assign({}, t, s), i.pointer?.onContainerMouseLeave(t || s);
      }
      i && i.mouseIsDown || (ri.hoverChartIndex = e.index);
    }
    touch(t, e) {
      let i;
      let {
        chart: s,
        pinchDown: o = []
      } = this;
      this.setHoverChartIndex(), 1 === (t = this.normalize(t)).touches.length ? s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop, {
        visiblePlotOnly: !0
      }) && !s.openMenu ? (e && this.runPointActions(t), "touchmove" === t.type && (i = !!o[0] && Math.pow(o[0].chartX - t.chartX, 2) + Math.pow(o[0].chartY - t.chartY, 2) >= 16), o7(i, !0) && this.pinch(t)) : e && this.reset() : 2 === t.touches.length && this.pinch(t);
    }
    touchSelect(t) {
      return !!(this.chart.zooming.singleTouch && t.touches && 1 === t.touches.length);
    }
    zoomOption(t) {
      let e = this.chart,
        i = e.inverted,
        s = e.zooming.type || "",
        o,
        r;
      /touch/.test(t.type) && (s = o7(e.zooming.pinchType, s)), this.zoomX = o = /x/.test(s), this.zoomY = r = /y/.test(s), this.zoomHor = o && !i || r && i, this.zoomVert = r && !i || o && i, this.hasZoom = o || r;
    }
  }
  ri.unbindDocumentMouseUp = [], function (t) {
    t.compose = function (e) {
      rt(oK, "Core.Pointer") && oQ(e, "beforeRender", function () {
        this.pointer = new t(this, this.options);
      });
    };
  }(ri || (ri = {}));
  let rs = ri,
    {
      fireEvent: ro,
      isArray: rr,
      objectEach: ra,
      uniqueKey: rn
    } = J,
    rl = class {
      constructor(t = {}) {
        this.autoId = !t.id, this.columns = {}, this.id = t.id || rn(), this.modified = this, this.rowCount = 0, this.versionTag = rn();
        let e = 0;
        ra(t.columns || {}, (t, i) => {
          this.columns[i] = t.slice(), e = Math.max(e, t.length);
        }), this.applyRowCount(e);
      }
      applyRowCount(t) {
        this.rowCount = t, ra(this.columns, e => {
          rr(e) && (e.length = t);
        });
      }
      getColumn(t, e) {
        return this.columns[t];
      }
      getColumns(t, e) {
        return (t || Object.keys(this.columns)).reduce((t, e) => (t[e] = this.columns[e], t), {});
      }
      getRow(t, e) {
        return (e || Object.keys(this.columns)).map(e => this.columns[e]?.[t]);
      }
      setColumn(t, e = [], i = 0, s) {
        this.setColumns({
          [t]: e
        }, i, s);
      }
      setColumns(t, e, i) {
        let s = this.rowCount;
        ra(t, (t, e) => {
          this.columns[e] = t.slice(), s = t.length;
        }), this.applyRowCount(s), i?.silent || (ro(this, "afterSetColumns"), this.versionTag = rn());
      }
      setRow(t, e = this.rowCount, i, s) {
        let {
            columns: o
          } = this,
          r = i ? this.rowCount + 1 : e + 1;
        ra(t, (t, a) => {
          let n = o[a] || s?.addColumns !== !1 && Array(r);
          n && (i ? n.splice(e, 0, t) : n[e] = t, o[a] = n);
        }), r > this.rowCount && this.applyRowCount(r), s?.silent || (ro(this, "afterSetRows"), this.versionTag = rn());
      }
    },
    {
      extend: rh,
      merge: rd,
      pick: rc
    } = J;
  !function (t) {
    function e(t, e, i) {
      let s = this.legendItem = this.legendItem || {},
        {
          chart: o,
          options: r
        } = this,
        {
          baseline: a = 0,
          symbolWidth: n,
          symbolHeight: l
        } = t,
        h = this.symbol || "circle",
        d = l / 2,
        c = o.renderer,
        p = s.group,
        u = a - Math.round((t.fontMetrics?.b || l) * (i ? .4 : .3)),
        g = {},
        m,
        f = r.marker,
        x = 0;
      if (o.styledMode || (g["stroke-width"] = Math.min(r.lineWidth || 0, 24), r.dashStyle ? g.dashstyle = r.dashStyle : "square" === r.linecap || (g["stroke-linecap"] = "round")), s.line = c.path().addClass("highcharts-graph").attr(g).add(p), i && (s.area = c.path().addClass("highcharts-area").add(p)), g["stroke-linecap"] && (x = Math.min(s.line.strokeWidth(), n) / 2), n) {
        let t = [["M", x, u], ["L", n - x, u]];
        s.line.attr({
          d: t
        }), s.area?.attr({
          d: [...t, ["L", n - x, a], ["L", x, a]]
        });
      }
      if (f && !1 !== f.enabled && n) {
        let t = Math.min(rc(f.radius, d), d);
        0 === h.indexOf("url") && (f = rd(f, {
          width: l,
          height: l
        }), t = 0), s.symbol = m = c.symbol(h, n / 2 - t, u - t, 2 * t, 2 * t, rh({
          context: "legend"
        }, f)).addClass("highcharts-point").add(p), m.isMarker = !0;
      }
    }
    t.areaMarker = function (t, i) {
      e.call(this, t, i, !0);
    }, t.lineMarker = e, t.rectangle = function (t, e) {
      let i = e.legendItem || {},
        s = t.options,
        o = t.symbolHeight,
        r = s.squareSymbol,
        a = r ? o : t.symbolWidth;
      i.symbol = this.chart.renderer.rect(r ? (t.symbolWidth - o) / 2 : 0, t.baseline - o + 1, a, o, rc(t.options.symbolRadius, o / 2)).addClass("highcharts-point").attr({
        zIndex: 3
      }).add(i.group);
    };
  }(c || (c = {}));
  let rp = c,
    {
      defaultOptions: ru
    } = tw,
    {
      extend: rg,
      extendClass: rm,
      merge: rf
    } = J;
  !function (t) {
    function e(e, i) {
      let s = ru.plotOptions || {},
        o = i.defaultOptions,
        r = i.prototype;
      return r.type = e, r.pointClass || (r.pointClass = o$), !t.seriesTypes[e] && (o && (s[e] = o), t.seriesTypes[e] = i, !0);
    }
    t.seriesTypes = A.seriesTypes, t.registerSeriesType = e, t.seriesType = function (i, s, o, r, a) {
      let n = ru.plotOptions || {};
      if (s = s || "", n[i] = rf(n[s], o), delete t.seriesTypes[i], e(i, rm(t.seriesTypes[s] || function () {}, r)), t.seriesTypes[i].prototype.type = i, a) {
        class e extends o$ {}
        rg(e.prototype, a), t.seriesTypes[i].prototype.pointClass = e;
      }
      return t.seriesTypes[i];
    };
  }(p || (p = {}));
  let rx = p,
    {
      animObject: ry,
      setAnimation: rb
    } = tH,
    {
      defaultOptions: rv
    } = tw,
    {
      registerEventOptions: rM
    } = i7,
    {
      svg: rw,
      win: rk
    } = A,
    {
      seriesTypes: rS
    } = rx,
    {
      arrayMax: rT,
      arrayMin: rC,
      clamp: rA,
      correctFloat: rP,
      crisp: rL,
      defined: rO,
      destroyObjectProperties: rE,
      diffObjects: rI,
      erase: rD,
      error: rB,
      extend: rz,
      find: rN,
      fireEvent: rR,
      getClosestDistance: rW,
      getNestedProperty: rj,
      insertItem: rX,
      isArray: rG,
      isNumber: rF,
      isString: rH,
      merge: rY,
      objectEach: rV,
      pick: rU,
      removeEvent: rZ,
      syncTimeout: r$
    } = J;
  class r_ {
    constructor() {
      this.zoneAxis = "y";
    }
    init(t, e) {
      let i;
      rR(this, "init", {
        options: e
      }), this.dataTable ?? (this.dataTable = new rl());
      let s = t.series;
      this.eventsToUnbind = [], this.chart = t, this.options = this.setOptions(e);
      let o = this.options,
        r = !1 !== o.visible;
      this.linkedSeries = [], this.bindAxes(), rz(this, {
        name: o.name,
        state: "",
        visible: r,
        selected: !0 === o.selected
      }), rM(this, o);
      let a = o.events;
      (a && a.click || o.point && o.point.events && o.point.events.click || o.allowPointSelect) && (t.runTrackerClick = !0), this.getColor(), this.getSymbol(), this.isCartesian && (t.hasCartesianSeries = !0), s.length && (i = s[s.length - 1]), this._i = rU(i && i._i, -1) + 1, this.opacity = this.options.opacity, t.orderItems("series", rX(this, s)), o.dataSorting && o.dataSorting.enabled ? this.setDataSortingOptions() : this.points || this.data || this.setData(o.data, !1), rR(this, "afterInit");
    }
    is(t) {
      return rS[t] && this instanceof rS[t];
    }
    bindAxes() {
      let t;
      let e = this,
        i = e.options,
        s = e.chart;
      rR(this, "bindAxes", null, function () {
        (e.axisTypes || []).forEach(function (o) {
          (s[o] || []).forEach(function (s) {
            t = s.options, (rU(i[o], 0) === s.index || void 0 !== i[o] && i[o] === t.id) && (rX(e, s.series), e[o] = s, s.isDirty = !0);
          }), e[o] || e.optionalAxis === o || rB(18, !0, s);
        });
      }), rR(this, "afterBindAxes");
    }
    hasData() {
      return this.visible && void 0 !== this.dataMax && void 0 !== this.dataMin || this.visible && this.dataTable.rowCount > 0;
    }
    hasMarkerChanged(t, e) {
      let i = t.marker,
        s = e.marker || {};
      return i && (s.enabled && !i.enabled || s.symbol !== i.symbol || s.height !== i.height || s.width !== i.width);
    }
    autoIncrement(t) {
      let e;
      let i = this.options,
        {
          pointIntervalUnit: s,
          relativeXValue: o
        } = this.options,
        r = this.chart.time,
        a = this.xIncrement ?? r.parse(i.pointStart) ?? 0;
      if (this.pointInterval = e = rU(this.pointInterval, i.pointInterval, 1), o && rF(t) && (e *= t), s) {
        let t = r.toParts(a);
        "day" === s ? t[2] += e : "month" === s ? t[1] += e : "year" === s && (t[0] += e), e = r.makeTime.apply(r, t) - a;
      }
      return o && rF(t) ? a + e : (this.xIncrement = a + e, a);
    }
    setDataSortingOptions() {
      let t = this.options;
      rz(this, {
        requireSorting: !1,
        sorted: !1,
        enabledDataSorting: !0,
        allowDG: !1
      }), rO(t.pointRange) || (t.pointRange = 1);
    }
    setOptions(t) {
      let e;
      let i = this.chart,
        s = i.options.plotOptions,
        o = i.userOptions || {},
        r = rY(t),
        a = i.styledMode,
        n = {
          plotOptions: s,
          userOptions: r
        };
      rR(this, "setOptions", n);
      let l = n.plotOptions[this.type],
        h = o.plotOptions || {},
        d = h.series || {},
        c = rv.plotOptions[this.type] || {},
        p = h[this.type] || {};
      this.userOptions = n.userOptions;
      let u = rY(l, s.series, p, r);
      this.tooltipOptions = rY(rv.tooltip, rv.plotOptions.series?.tooltip, c?.tooltip, i.userOptions.tooltip, h.series?.tooltip, p.tooltip, r.tooltip), this.stickyTracking = rU(r.stickyTracking, p.stickyTracking, d.stickyTracking, !!this.tooltipOptions.shared && !this.noSharedTooltip || u.stickyTracking), null === l.marker && delete u.marker, this.zoneAxis = u.zoneAxis || "y";
      let g = this.zones = (u.zones || []).map(t => ({
        ...t
      }));
      return (u.negativeColor || u.negativeFillColor) && !u.zones && (e = {
        value: u[this.zoneAxis + "Threshold"] || u.threshold || 0,
        className: "highcharts-negative"
      }, a || (e.color = u.negativeColor, e.fillColor = u.negativeFillColor), g.push(e)), g.length && rO(g[g.length - 1].value) && g.push(a ? {} : {
        color: this.color,
        fillColor: this.fillColor
      }), rR(this, "afterSetOptions", {
        options: u
      }), u;
    }
    getName() {
      return rU(this.options.name, "Series " + (this.index + 1));
    }
    getCyclic(t, e, i) {
      let s, o;
      let r = this.chart,
        a = `${t}Index`,
        n = `${t}Counter`,
        l = i?.length || r.options.chart.colorCount;
      !e && (rO(o = rU("color" === t ? this.options.colorIndex : void 0, this[a])) ? s = o : (r.series.length || (r[n] = 0), s = r[n] % l, r[n] += 1), i && (e = i[s])), void 0 !== s && (this[a] = s), this[t] = e;
    }
    getColor() {
      this.chart.styledMode ? this.getCyclic("color") : this.options.colorByPoint ? this.color = "#cccccc" : this.getCyclic("color", this.options.color || rv.plotOptions[this.type].color, this.chart.options.colors);
    }
    getPointsCollection() {
      return (this.hasGroupedData ? this.points : this.data) || [];
    }
    getSymbol() {
      let t = this.options.marker;
      this.getCyclic("symbol", t.symbol, this.chart.options.symbols);
    }
    getColumn(t, e) {
      return (e ? this.dataTable.modified : this.dataTable).getColumn(t, !0) || [];
    }
    findPointIndex(t, e) {
      let i, s, o;
      let r = t.id,
        a = t.x,
        n = this.points,
        l = this.options.dataSorting;
      if (r) {
        let t = this.chart.get(r);
        t instanceof o$ && (i = t);
      } else if (this.linkedParent || this.enabledDataSorting || this.options.relativeXValue) {
        let e = e => !e.touched && e.index === t.index;
        if (l && l.matchByName ? e = e => !e.touched && e.name === t.name : this.options.relativeXValue && (e = e => !e.touched && e.options.x === t.x), !(i = rN(n, e))) return;
      }
      return i && void 0 !== (o = i && i.index) && (s = !0), void 0 === o && rF(a) && (o = this.getColumn("x").indexOf(a, e)), -1 !== o && void 0 !== o && this.cropped && (o = o >= this.cropStart ? o - this.cropStart : o), !s && rF(o) && n[o] && n[o].touched && (o = void 0), o;
    }
    updateData(t, e) {
      let i = this.options,
        s = i.dataSorting,
        o = this.points,
        r = [],
        a = this.requireSorting,
        n = t.length === o.length,
        l,
        h,
        d,
        c,
        p = !0;
      if (this.xIncrement = null, t.forEach(function (t, e) {
        let h;
        let d = rO(t) && this.pointClass.prototype.optionsToObject.call({
            series: this
          }, t) || {},
          p = d.x;
        d.id || rF(p) ? (-1 === (h = this.findPointIndex(d, c)) || void 0 === h ? r.push(t) : o[h] && t !== i.data[h] ? (o[h].update(t, !1, null, !1), o[h].touched = !0, a && (c = h + 1)) : o[h] && (o[h].touched = !0), (!n || e !== h || s && s.enabled || this.hasDerivedData) && (l = !0)) : r.push(t);
      }, this), l) for (h = o.length; h--;) (d = o[h]) && !d.touched && d.remove && d.remove(!1, e);else !n || s && s.enabled ? p = !1 : (t.forEach(function (t, e) {
        t === o[e].y || o[e].destroyed || o[e].update(t, !1, null, !1);
      }), r.length = 0);
      if (o.forEach(function (t) {
        t && (t.touched = !1);
      }), !p) return !1;
      r.forEach(function (t) {
        this.addPoint(t, !1, null, null, !1);
      }, this);
      let u = this.getColumn("x");
      return null === this.xIncrement && u.length && (this.xIncrement = rT(u), this.autoIncrement()), !0;
    }
    dataColumnKeys() {
      return ["x", ...(this.pointArrayMap || ["y"])];
    }
    setData(t, e = !0, i, s) {
      let o = this.points,
        r = o && o.length || 0,
        a = this.options,
        n = this.chart,
        l = a.dataSorting,
        h = this.xAxis,
        d = a.turboThreshold,
        c = this.dataTable,
        p = this.dataColumnKeys(),
        u = this.pointValKey || "y",
        g = (this.pointArrayMap || []).length,
        m = a.keys,
        f,
        x,
        y = 0,
        b = 1,
        v;
      n.options.chart.allowMutatingData || (a.data && delete this.options.data, this.userOptions.data && delete this.userOptions.data, v = rY(!0, t));
      let M = (t = v || t || []).length;
      if (l && l.enabled && (t = this.sortData(t)), n.options.chart.allowMutatingData && !1 !== s && M && r && !this.cropped && !this.hasGroupedData && this.visible && !this.boosted && (x = this.updateData(t, i)), !x) {
        this.xIncrement = null, this.colorCounter = 0;
        let e = d && M > d;
        if (e) {
          let i = this.getFirstValidPoint(t),
            s = this.getFirstValidPoint(t, M - 1, -1),
            o = t => !!(rG(t) && (m || rF(t[0])));
          if (rF(i) && rF(s)) {
            let e = [],
              i = [];
            for (let s of t) e.push(this.autoIncrement()), i.push(s);
            c.setColumns({
              x: e,
              [u]: i
            });
          } else if (o(i) && o(s)) {
            if (g) {
              let e = i.length === g ? 1 : 0,
                s = Array(p.length).fill(0).map(() => []);
              for (let i of t) {
                e && s[0].push(this.autoIncrement());
                for (let t = e; t <= g; t++) s[t]?.push(i[t - e]);
              }
              c.setColumns(p.reduce((t, e, i) => (t[e] = s[i], t), {}));
            } else {
              m && (y = m.indexOf("x"), b = m.indexOf("y"), y = y >= 0 ? y : 0, b = b >= 0 ? b : 1), 1 === i.length && (b = 0);
              let e = [],
                s = [];
              if (y === b) for (let i of t) e.push(this.autoIncrement()), s.push(i[b]);else for (let i of t) e.push(i[y]), s.push(i[b]);
              c.setColumns({
                x: e,
                [u]: s
              });
            }
          } else e = !1;
        }
        if (!e) {
          let e = p.reduce((t, e) => (t[e] = [], t), {});
          for (f = 0; f < M; f++) {
            let i = this.pointClass.prototype.applyOptions.apply({
              series: this
            }, [t[f]]);
            for (let t of p) e[t][f] = i[t];
          }
          c.setColumns(e);
        }
        for (rH(this.getColumn("y")[0]) && rB(14, !0, n), this.data = [], this.options.data = this.userOptions.data = t, f = r; f--;) o[f]?.destroy();
        h && (h.minRange = h.userMinRange), this.isDirty = n.isDirtyBox = !0, this.isDirtyData = !!o, i = !1;
      }
      "point" === a.legendType && (this.processData(), this.generatePoints()), e && n.redraw(i);
    }
    sortData(t) {
      let e = this,
        i = e.options.dataSorting.sortKey || "y",
        s = function (t, e) {
          return rO(e) && t.pointClass.prototype.optionsToObject.call({
            series: t
          }, e) || {};
        };
      return t.forEach(function (i, o) {
        t[o] = s(e, i), t[o].index = o;
      }, this), t.concat().sort((t, e) => {
        let s = rj(i, t),
          o = rj(i, e);
        return o < s ? -1 : o > s ? 1 : 0;
      }).forEach(function (t, e) {
        t.x = e;
      }, this), e.linkedSeries && e.linkedSeries.forEach(function (e) {
        let i = e.options,
          o = i.data;
        i.dataSorting && i.dataSorting.enabled || !o || (o.forEach(function (i, r) {
          o[r] = s(e, i), t[r] && (o[r].x = t[r].x, o[r].index = r);
        }), e.setData(o, !1));
      }), t;
    }
    getProcessedData(t) {
      let e = this,
        {
          dataTable: i,
          isCartesian: s,
          options: o,
          xAxis: r
        } = e,
        a = o.cropThreshold,
        n = t || e.getExtremesFromAll,
        l = r?.logarithmic,
        h = i.rowCount,
        d,
        c,
        p = 0,
        u,
        g,
        m,
        f = e.getColumn("x"),
        x = i,
        y = !1;
      return r && (g = (u = r.getExtremes()).min, m = u.max, y = !!(r.categories && !r.names.length), s && e.sorted && !n && (!a || h > a || e.forceCrop) && (f[h - 1] < g || f[0] > m ? x = new rl() : e.getColumn(e.pointValKey || "y").length && (f[0] < g || f[h - 1] > m) && (x = (d = this.cropData(i, g, m)).modified, p = d.start, c = !0))), f = x.getColumn("x") || [], {
        modified: x,
        cropped: c,
        cropStart: p,
        closestPointRange: rW([l ? f.map(l.log2lin) : f], () => e.requireSorting && !y && rB(15, !1, e.chart))
      };
    }
    processData(t) {
      let e = this.xAxis,
        i = this.dataTable;
      if (this.isCartesian && !this.isDirty && !e.isDirty && !this.yAxis.isDirty && !t) return !1;
      let s = this.getProcessedData();
      i.modified = s.modified, this.cropped = s.cropped, this.cropStart = s.cropStart, this.closestPointRange = this.basePointRange = s.closestPointRange, rR(this, "afterProcessData");
    }
    cropData(t, e, i) {
      let s = t.getColumn("x", !0) || [],
        o = s.length,
        r = {},
        a,
        n,
        l = 0,
        h = o;
      for (a = 0; a < o; a++) if (s[a] >= e) {
        l = Math.max(0, a - 1);
        break;
      }
      for (n = a; n < o; n++) if (s[n] > i) {
        h = n + 1;
        break;
      }
      for (let e of this.dataColumnKeys()) {
        let i = t.getColumn(e, !0);
        i && (r[e] = i.slice(l, h));
      }
      return {
        modified: new rl({
          columns: r
        }),
        start: l,
        end: h
      };
    }
    generatePoints() {
      let t = this.options,
        e = this.processedData || t.data,
        i = this.dataTable.modified,
        s = this.getColumn("x", !0),
        o = this.pointClass,
        r = i.rowCount,
        a = this.cropStart || 0,
        n = this.hasGroupedData,
        l = t.keys,
        h = [],
        d = t.dataGrouping && t.dataGrouping.groupAll ? a : 0,
        c = this.xAxis?.categories,
        p = this.pointArrayMap || ["y"],
        u = this.dataColumnKeys(),
        g,
        m,
        f,
        x,
        y = this.data,
        b;
      if (!y && !n) {
        let t = [];
        t.length = e?.length || 0, y = this.data = t;
      }
      for (l && n && (this.options.keys = !1), x = 0; x < r; x++) m = a + x, n ? ((f = new o(this, i.getRow(x, u) || [])).dataGroup = this.groupMap[d + x], f.dataGroup?.options && (f.options = f.dataGroup.options, rz(f, f.dataGroup.options), delete f.dataLabels)) : (f = y[m], b = e ? e[m] : i.getRow(x, p), f || void 0 === b || (y[m] = f = new o(this, b, s[x]))), f && (f.index = n ? d + x : m, h[x] = f, f.category = c?.[f.x] ?? f.x, f.key = f.name ?? f.category);
      if (this.options.keys = l, y && (r !== (g = y.length) || n)) for (x = 0; x < g; x++) x !== a || n || (x += r), y[x] && (y[x].destroyElements(), y[x].plotX = void 0);
      this.data = y, this.points = h, rR(this, "afterGeneratePoints");
    }
    getXExtremes(t) {
      return {
        min: rC(t),
        max: rT(t)
      };
    }
    getExtremes(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = this,
        o = e || this.getExtremesFromAll || this.options.getExtremesFromAll,
        r = o && this.cropped ? this.dataTable : this.dataTable.modified,
        a = r.rowCount,
        n = t || this.stackedYData,
        l = n ? [n] : (this.keysAffectYAxis || this.pointArrayMap || ["y"])?.map(t => r.getColumn(t, !0) || []) || [],
        h = this.getColumn("x", !0),
        d = [],
        c = this.requireSorting && !this.is("column") ? 1 : 0,
        p = !!s && s.positiveValuesOnly,
        u = o || this.cropped || !i,
        g,
        m,
        f,
        x = 0,
        y = 0;
      for (i && (x = (g = i.getExtremes()).min, y = g.max), f = 0; f < a; f++) if (m = h[f], u || (h[f + c] || m) >= x && (h[f - c] || m) <= y) for (let t of l) {
        let e = t[f];
        rF(e) && (e > 0 || !p) && d.push(e);
      }
      let b = {
        activeYData: d,
        dataMin: rC(d),
        dataMax: rT(d)
      };
      return rR(this, "afterGetExtremes", {
        dataExtremes: b
      }), b;
    }
    applyExtremes() {
      let t = this.getExtremes();
      return this.dataMin = t.dataMin, this.dataMax = t.dataMax, t;
    }
    getFirstValidPoint(t, e = 0, i = 1) {
      let s = t.length,
        o = e;
      for (; o >= 0 && o < s;) {
        if (rO(t[o])) return t[o];
        o += i;
      }
    }
    translate() {
      this.generatePoints();
      let t = this.options,
        e = t.stacking,
        i = this.xAxis,
        s = this.enabledDataSorting,
        o = this.yAxis,
        r = this.points,
        a = r.length,
        n = this.pointPlacementToXValue(),
        l = !!n,
        h = t.threshold,
        d = t.startFromThreshold ? h : 0,
        c,
        p,
        u,
        g,
        m = Number.MAX_VALUE;
      function f(t) {
        return rA(t, -1e9, 1e9);
      }
      for (c = 0; c < a; c++) {
        let t;
        let a = r[c],
          x = a.x,
          y,
          b,
          v = a.y,
          M = a.low,
          w = e && o.stacking?.stacks[(this.negStacks && v < (d ? 0 : h) ? "-" : "") + this.stackKey];
        p = i.translate(x, !1, !1, !1, !0, n), a.plotX = rF(p) ? rP(f(p)) : void 0, e && this.visible && w && w[x] && (g = this.getStackIndicator(g, x, this.index), !a.isNull && g.key && (b = (y = w[x]).points[g.key]), y && rG(b) && (M = b[0], v = b[1], M === d && g.key === w[x].base && (M = rU(rF(h) ? h : o.min)), o.positiveValuesOnly && rO(M) && M <= 0 && (M = void 0), a.total = a.stackTotal = rU(y.total), a.percentage = rO(a.y) && y.total ? a.y / y.total * 100 : void 0, a.stackY = v, this.irregularWidths || y.setOffset(this.pointXOffset || 0, this.barW || 0, void 0, void 0, void 0, this.xAxis))), a.yBottom = rO(M) ? f(o.translate(M, !1, !0, !1, !0)) : void 0, this.dataModify && (v = this.dataModify.modifyValue(v, c)), rF(v) && void 0 !== a.plotX && (t = rF(t = o.translate(v, !1, !0, !1, !0)) ? f(t) : void 0), a.plotY = t, a.isInside = this.isPointInside(a), a.clientX = l ? rP(i.translate(x, !1, !1, !1, !0, n)) : p, a.negative = (a.y || 0) < (h || 0), a.isNull || !1 === a.visible || (void 0 !== u && (m = Math.min(m, Math.abs(p - u))), u = p), a.zone = this.zones.length ? a.getZone() : void 0, !a.graphic && this.group && s && (a.isNew = !0);
      }
      this.closestPointRangePx = m, rR(this, "afterTranslate");
    }
    getValidPoints(t, e, i) {
      let s = this.chart;
      return (t || this.points || []).filter(function (t) {
        let {
          plotX: o,
          plotY: r
        } = t;
        return !!((i || !t.isNull && rF(r)) && (!e || s.isInsidePlot(o, r, {
          inverted: s.inverted
        }))) && !1 !== t.visible;
      });
    }
    getClipBox() {
      let {
          chart: t,
          xAxis: e,
          yAxis: i
        } = this,
        {
          x: s,
          y: o,
          width: r,
          height: a
        } = rY(t.clipBox);
      return e && e.len !== t.plotSizeX && (r = e.len), i && i.len !== t.plotSizeY && (a = i.len), t.inverted && !this.invertible && ([r, a] = [a, r]), {
        x: s,
        y: o,
        width: r,
        height: a
      };
    }
    getSharedClipKey() {
      return this.sharedClipKey = (this.options.xAxis || 0) + "," + (this.options.yAxis || 0), this.sharedClipKey;
    }
    setClip() {
      let {
          chart: t,
          group: e,
          markerGroup: i
        } = this,
        s = t.sharedClips,
        o = t.renderer,
        r = this.getClipBox(),
        a = this.getSharedClipKey(),
        n = s[a];
      n ? n.animate(r) : s[a] = n = o.clipRect(r), e && e.clip(!1 === this.options.clip ? void 0 : n), i && i.clip();
    }
    animate(t) {
      let {
          chart: e,
          group: i,
          markerGroup: s
        } = this,
        o = e.inverted,
        r = ry(this.options.animation),
        a = [this.getSharedClipKey(), r.duration, r.easing, r.defer].join(","),
        n = e.sharedClips[a],
        l = e.sharedClips[a + "m"];
      if (t && i) {
        let t = this.getClipBox();
        if (n) n.attr("height", t.height);else {
          t.width = 0, o && (t.x = e.plotHeight), n = e.renderer.clipRect(t), e.sharedClips[a] = n;
          let i = {
            x: -99,
            y: -99,
            width: o ? e.plotWidth + 199 : 99,
            height: o ? 99 : e.plotHeight + 199
          };
          l = e.renderer.clipRect(i), e.sharedClips[a + "m"] = l;
        }
        i.clip(n), s?.clip(l);
      } else if (n && !n.hasClass("highcharts-animating")) {
        let t = this.getClipBox(),
          i = r.step;
        (s?.element.childNodes.length || e.series.length > 1) && (r.step = function (t, e) {
          i && i.apply(e, arguments), "width" === e.prop && l?.element && l.attr(o ? "height" : "width", t + 99);
        }), n.addClass("highcharts-animating").animate(t, r);
      }
    }
    afterAnimate() {
      this.setClip(), rV(this.chart.sharedClips, (t, e, i) => {
        t && !this.chart.container.querySelector(`[clip-path="url(#${t.id})"]`) && (t.destroy(), delete i[e]);
      }), this.finishedAnimating = !0, rR(this, "afterAnimate");
    }
    drawPoints(t = this.points) {
      let e, i, s, o, r, a, n;
      let l = this.chart,
        h = l.styledMode,
        {
          colorAxis: d,
          options: c
        } = this,
        p = c.marker,
        u = this[this.specialGroup || "markerGroup"],
        g = this.xAxis,
        m = rU(p.enabled, !g || !!g.isRadial || null, this.closestPointRangePx >= p.enabledThreshold * p.radius);
      if (!1 !== p.enabled || this._hasPointMarkers) for (e = 0; e < t.length; e++) if (o = (s = (i = t[e]).graphic) ? "animate" : "attr", r = i.marker || {}, a = !!i.marker, (m && void 0 === r.enabled || r.enabled) && !i.isNull && !1 !== i.visible) {
        let t = rU(r.symbol, this.symbol, "rect");
        n = this.markerAttribs(i, i.selected && "select"), this.enabledDataSorting && (i.startXPos = g.reversed ? -(n.width || 0) : g.width);
        let e = !1 !== i.isInside;
        if (!s && e && ((n.width || 0) > 0 || i.hasImage) && (i.graphic = s = l.renderer.symbol(t, n.x, n.y, n.width, n.height, a ? r : p).add(u), this.enabledDataSorting && l.hasRendered && (s.attr({
          x: i.startXPos
        }), o = "animate")), s && "animate" === o && s[e ? "show" : "hide"](e).animate(n), s) {
          let t = this.pointAttribs(i, h || !i.selected ? void 0 : "select");
          h ? d && s.css({
            fill: t.fill
          }) : s[o](t);
        }
        s && s.addClass(i.getClassName(), !0);
      } else s && (i.graphic = s.destroy());
    }
    markerAttribs(t, e) {
      let i = this.options,
        s = i.marker,
        o = t.marker || {},
        r = o.symbol || s.symbol,
        a = {},
        n,
        l,
        h = rU(o.radius, s && s.radius);
      e && (n = s.states[e], h = rU((l = o.states && o.states[e]) && l.radius, n && n.radius, h && h + (n && n.radiusPlus || 0))), t.hasImage = r && 0 === r.indexOf("url"), t.hasImage && (h = 0);
      let d = t.pos();
      return rF(h) && d && (i.crisp && (d[0] = rL(d[0], t.hasImage ? 0 : "rect" === r ? s?.lineWidth || 0 : 1)), a.x = d[0] - h, a.y = d[1] - h), h && (a.width = a.height = 2 * h), a;
    }
    pointAttribs(t, e) {
      let i = this.options.marker,
        s = t && t.options,
        o = s && s.marker || {},
        r = s && s.color,
        a = t && t.color,
        n = t && t.zone && t.zone.color,
        l,
        h,
        d = this.color,
        c,
        p,
        u = rU(o.lineWidth, i.lineWidth),
        g = 1;
      return d = r || n || a || d, c = o.fillColor || i.fillColor || d, p = o.lineColor || i.lineColor || d, e = e || "normal", l = i.states[e] || {}, u = rU((h = o.states && o.states[e] || {}).lineWidth, l.lineWidth, u + rU(h.lineWidthPlus, l.lineWidthPlus, 0)), c = h.fillColor || l.fillColor || c, {
        stroke: p = h.lineColor || l.lineColor || p,
        "stroke-width": u,
        fill: c,
        opacity: g = rU(h.opacity, l.opacity, g)
      };
    }
    destroy(t) {
      let e, i, s;
      let o = this,
        r = o.chart,
        a = /AppleWebKit\/533/.test(rk.navigator.userAgent),
        n = o.data || [];
      for (rR(o, "destroy", {
        keepEventsForUpdate: t
      }), this.removeEvents(t), (o.axisTypes || []).forEach(function (t) {
        (s = o[t]) && s.series && (rD(s.series, o), s.isDirty = s.forceRedraw = !0);
      }), o.legendItem && o.chart.legend.destroyItem(o), e = n.length; e--;) (i = n[e]) && i.destroy && i.destroy();
      for (let t of o.zones) rE(t, void 0, !0);
      J.clearTimeout(o.animationTimeout), rV(o, function (t, e) {
        t instanceof eq && !t.survive && t[a && "group" === e ? "hide" : "destroy"]();
      }), r.hoverSeries === o && (r.hoverSeries = void 0), rD(r.series, o), r.orderItems("series"), rV(o, function (e, i) {
        t && "hcEvents" === i || delete o[i];
      });
    }
    applyZones() {
      let {
          area: t,
          chart: e,
          graph: i,
          zones: s,
          points: o,
          xAxis: r,
          yAxis: a,
          zoneAxis: n
        } = this,
        {
          inverted: l,
          renderer: h
        } = e,
        d = this[`${n}Axis`],
        {
          isXAxis: c,
          len: p = 0,
          minPointOffset: u = 0
        } = d || {},
        g = (i?.strokeWidth() || 0) / 2 + 1,
        m = (t, e = 0, i = 0) => {
          l && (i = p - i);
          let {
              translated: s = 0,
              lineClip: o
            } = t,
            r = i - s;
          o?.push(["L", e, Math.abs(r) < g ? i - g * (r <= 0 ? -1 : 1) : s]);
        };
      if (s.length && (i || t) && d && rF(d.min)) {
        let e = d.getExtremes().max + u,
          g = t => {
            t.forEach((e, i) => {
              ("M" === e[0] || "L" === e[0]) && (t[i] = [e[0], c ? p - e[1] : e[1], c ? e[2] : p - e[2]]);
            });
          };
        if (s.forEach(t => {
          t.lineClip = [], t.translated = rA(d.toPixels(rU(t.value, e), !0) || 0, 0, p);
        }), i && !this.showLine && i.hide(), t && t.hide(), "y" === n && o.length < r.len) for (let t of o) {
          let {
              plotX: e,
              plotY: i,
              zone: o
            } = t,
            r = o && s[s.indexOf(o) - 1];
          o && m(o, e, i), r && m(r, e, i);
        }
        let f = [],
          x = d.toPixels(d.getExtremes().min - u, !0);
        s.forEach(e => {
          let s = e.lineClip || [],
            o = Math.round(e.translated || 0);
          r.reversed && s.reverse();
          let {
              clip: n,
              simpleClip: d
            } = e,
            p = 0,
            u = 0,
            m = r.len,
            y = a.len;
          c ? (p = o, m = x) : (u = o, y = x);
          let b = [["M", p, u], ["L", m, u], ["L", m, y], ["L", p, y], ["Z"]],
            v = [b[0], ...s, b[1], b[2], ...f, b[3], b[4]];
          f = s.reverse(), x = o, l && (g(v), t && g(b)), n ? (n.animate({
            d: v
          }), d?.animate({
            d: b
          })) : (n = e.clip = h.path(v), t && (d = e.simpleClip = h.path(b))), i && e.graph?.clip(n), t && e.area?.clip(d);
        });
      } else this.visible && (i && i.show(), t && t.show());
    }
    plotGroup(t, e, i, s, o) {
      let r = this[t],
        a = !r,
        n = {
          visibility: i,
          zIndex: s || .1
        };
      return rO(this.opacity) && !this.chart.styledMode && "inactive" !== this.state && (n.opacity = this.opacity), r || (this[t] = r = this.chart.renderer.g().add(o)), r.addClass("highcharts-" + e + " highcharts-series-" + this.index + " highcharts-" + this.type + "-series " + (rO(this.colorIndex) ? "highcharts-color-" + this.colorIndex + " " : "") + (this.options.className || "") + (r.hasClass("highcharts-tracker") ? " highcharts-tracker" : ""), !0), r.attr(n)[a ? "attr" : "animate"](this.getPlotBox(e)), r;
    }
    getPlotBox(t) {
      let e = this.xAxis,
        i = this.yAxis,
        s = this.chart,
        o = s.inverted && !s.polar && e && this.invertible && "series" === t;
      return s.inverted && (e = i, i = this.xAxis), {
        translateX: e ? e.left : s.plotLeft,
        translateY: i ? i.top : s.plotTop,
        rotation: o ? 90 : 0,
        rotationOriginX: o ? (e.len - i.len) / 2 : 0,
        rotationOriginY: o ? (e.len + i.len) / 2 : 0,
        scaleX: o ? -1 : 1,
        scaleY: 1
      };
    }
    removeEvents(t) {
      let {
        eventsToUnbind: e
      } = this;
      t || rZ(this), e.length && (e.forEach(t => {
        t();
      }), e.length = 0);
    }
    render() {
      let t = this,
        {
          chart: e,
          options: i,
          hasRendered: s
        } = t,
        o = ry(i.animation),
        r = t.visible ? "inherit" : "hidden",
        a = i.zIndex,
        n = e.seriesGroup,
        l = t.finishedAnimating ? 0 : o.duration;
      rR(this, "render"), t.plotGroup("group", "series", r, a, n), t.markerGroup = t.plotGroup("markerGroup", "markers", r, a, n), !1 !== i.clip && t.setClip(), l && t.animate?.(!0), t.drawGraph && (t.drawGraph(), t.applyZones()), t.visible && t.drawPoints(), t.drawDataLabels?.(), t.redrawPoints?.(), i.enableMouseTracking && t.drawTracker?.(), l && t.animate?.(), s || (l && o.defer && (l += o.defer), t.animationTimeout = r$(() => {
        t.afterAnimate();
      }, l || 0)), t.isDirty = !1, t.hasRendered = !0, rR(t, "afterRender");
    }
    redraw() {
      let t = this.isDirty || this.isDirtyData;
      this.translate(), this.render(), t && delete this.kdTree;
    }
    reserveSpace() {
      return this.visible || !this.chart.options.chart.ignoreHiddenSeries;
    }
    searchPoint(t, e) {
      let {
          xAxis: i,
          yAxis: s
        } = this,
        o = this.chart.inverted;
      return this.searchKDTree({
        clientX: o ? i.len - t.chartY + i.pos : t.chartX - i.pos,
        plotY: o ? s.len - t.chartX + s.pos : t.chartY - s.pos
      }, e, t);
    }
    buildKDTree(t) {
      this.buildingKdTree = !0;
      let e = this,
        i = e.options.findNearestPointBy.indexOf("y") > -1 ? 2 : 1;
      delete e.kdTree, r$(function () {
        e.kdTree = function t(i, s, o) {
          let r, a;
          let n = i?.length;
          if (n) return r = e.kdAxisArray[s % o], i.sort((t, e) => (t[r] || 0) - (e[r] || 0)), {
            point: i[a = Math.floor(n / 2)],
            left: t(i.slice(0, a), s + 1, o),
            right: t(i.slice(a + 1), s + 1, o)
          };
        }(e.getValidPoints(void 0, !e.directTouch), i, i), e.buildingKdTree = !1;
      }, e.options.kdNow || t?.type === "touchstart" ? 0 : 1);
    }
    searchKDTree(t, e, i, s, o) {
      let r = this,
        [a, n] = this.kdAxisArray,
        l = e ? "distX" : "dist",
        h = (r.options.findNearestPointBy || "").indexOf("y") > -1 ? 2 : 1,
        d = !!r.isBubble,
        c = s || ((t, e, i) => [(t[i] || 0) < (e[i] || 0) ? t : e, !1]),
        p = o || ((t, e) => t < e);
      if (this.kdTree || this.buildingKdTree || this.buildKDTree(i), this.kdTree) return function t(e, i, s, o) {
        let h = i.point,
          u = r.kdAxisArray[s % o],
          g = h,
          m = !1;
        !function (t, e) {
          let i = t[a],
            s = e[a],
            o = rO(i) && rO(s) ? i - s : null,
            r = t[n],
            l = e[n],
            h = rO(r) && rO(l) ? r - l : 0,
            c = d && e.marker?.radius || 0;
          e.dist = Math.sqrt((o && o * o || 0) + h * h) - c, e.distX = rO(o) ? Math.abs(o) - c : Number.MAX_VALUE;
        }(e, h);
        let f = (e[u] || 0) - (h[u] || 0) + (d && h.marker?.radius || 0),
          x = f < 0 ? "left" : "right",
          y = f < 0 ? "right" : "left";
        return i[x] && ([g, m] = c(h, t(e, i[x], s + 1, o), l)), i[y] && p(Math.sqrt(f * f), g[l], m) && (g = c(g, t(e, i[y], s + 1, o), l)[0]), g;
      }(t, this.kdTree, h, h);
    }
    pointPlacementToXValue() {
      let {
          options: t,
          xAxis: e
        } = this,
        i = t.pointPlacement;
      return "between" === i && (i = e.reversed ? -.5 : .5), rF(i) ? i * (t.pointRange || e.pointRange) : 0;
    }
    isPointInside(t) {
      let {
          chart: e,
          xAxis: i,
          yAxis: s
        } = this,
        {
          plotX: o = -1,
          plotY: r = -1
        } = t;
      return r >= 0 && r <= (s ? s.len : e.plotHeight) && o >= 0 && o <= (i ? i.len : e.plotWidth);
    }
    drawTracker() {
      let t = this,
        e = t.options,
        i = e.trackByArea,
        s = [].concat((i ? t.areaPath : t.graphPath) || []),
        o = t.chart,
        r = o.pointer,
        a = o.renderer,
        n = o.options.tooltip?.snap || 0,
        l = () => {
          e.enableMouseTracking && o.hoverSeries !== t && t.onMouseOver();
        },
        h = "rgba(192,192,192," + (rw ? 1e-4 : .002) + ")",
        d = t.tracker;
      d ? d.attr({
        d: s
      }) : t.graph && (t.tracker = d = a.path(s).attr({
        visibility: t.visible ? "inherit" : "hidden",
        zIndex: 2
      }).addClass(i ? "highcharts-tracker-area" : "highcharts-tracker-line").add(t.group), o.styledMode || d.attr({
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        stroke: h,
        fill: i ? h : "none",
        "stroke-width": t.graph.strokeWidth() + (i ? 0 : 2 * n)
      }), [t.tracker, t.markerGroup, t.dataLabelsGroup].forEach(t => {
        t && (t.addClass("highcharts-tracker").on("mouseover", l).on("mouseout", t => {
          r?.onTrackerMouseOut(t);
        }), e.cursor && !o.styledMode && t.css({
          cursor: e.cursor
        }), t.on("touchstart", l));
      })), rR(this, "afterDrawTracker");
    }
    addPoint(t, e, i, s, o) {
      let r, a;
      let n = this.options,
        {
          chart: l,
          data: h,
          dataTable: d,
          xAxis: c
        } = this,
        p = c && c.hasNames && c.names,
        u = n.data,
        g = this.getColumn("x");
      e = rU(e, !0);
      let m = {
        series: this
      };
      this.pointClass.prototype.applyOptions.apply(m, [t]);
      let f = m.x;
      if (a = g.length, this.requireSorting && f < g[a - 1]) for (r = !0; a && g[a - 1] > f;) a--;
      d.setRow(m, a, !0, {
        addColumns: !1
      }), p && m.name && (p[f] = m.name), u?.splice(a, 0, t), (r || this.processedData) && (this.data.splice(a, 0, null), this.processData()), "point" === n.legendType && this.generatePoints(), i && (h[0] && h[0].remove ? h[0].remove(!1) : ([h, u, ...Object.values(d.getColumns())].filter(rO).forEach(t => {
        t.shift();
      }), d.rowCount -= 1, rR(d, "afterDeleteRows"))), !1 !== o && rR(this, "addPoint", {
        point: m
      }), this.isDirty = !0, this.isDirtyData = !0, e && l.redraw(s);
    }
    removePoint(t, e, i) {
      let s = this,
        {
          chart: o,
          data: r,
          points: a,
          dataTable: n
        } = s,
        l = r[t],
        h = function () {
          [a?.length === r.length ? a : void 0, r, s.options.data, ...Object.values(n.getColumns())].filter(rO).forEach(e => {
            e.splice(t, 1);
          }), n.rowCount -= 1, rR(n, "afterDeleteRows"), l?.destroy(), s.isDirty = !0, s.isDirtyData = !0, e && o.redraw();
        };
      rb(i, o), e = rU(e, !0), l ? l.firePointEvent("remove", null, h) : h();
    }
    remove(t, e, i, s) {
      let o = this,
        r = o.chart;
      function a() {
        o.destroy(s), r.isDirtyLegend = r.isDirtyBox = !0, r.linkSeries(s), rU(t, !0) && r.redraw(e);
      }
      !1 !== i ? rR(o, "remove", null, a) : a();
    }
    update(t, e) {
      rR(this, "update", {
        options: t = rI(t, this.userOptions)
      });
      let i = this,
        s = i.chart,
        o = i.userOptions,
        r = i.initialType || i.type,
        a = s.options.plotOptions,
        n = rS[r].prototype,
        l = i.finishedAnimating && {
          animation: !1
        },
        h = {},
        d,
        c,
        p = ["colorIndex", "eventOptions", "navigatorSeries", "symbolIndex", "baseSeries"],
        u = t.type || o.type || s.options.chart.type,
        g = !(this.hasDerivedData || u && u !== this.type || void 0 !== t.keys || void 0 !== t.pointStart || void 0 !== t.pointInterval || void 0 !== t.relativeXValue || t.joinBy || t.mapData || ["dataGrouping", "pointStart", "pointInterval", "pointIntervalUnit", "keys"].some(t => i.hasOptionChanged(t)));
      u = u || r, g ? (p.push("data", "isDirtyData", "isDirtyCanvas", "points", "dataTable", "processedData", "xIncrement", "cropped", "_hasPointMarkers", "hasDataLabels", "nodes", "layout", "level", "mapMap", "mapData", "minY", "maxY", "minX", "maxX", "transformGroups"), !1 !== t.visible && p.push("area", "graph"), i.parallelArrays.forEach(function (t) {
        p.push(t + "Data");
      }), t.data && (t.dataSorting && rz(i.options.dataSorting, t.dataSorting), this.setData(t.data, !1))) : this.dataTable.modified = this.dataTable, t = rY(o, {
        index: void 0 === o.index ? i.index : o.index,
        pointStart: a?.series?.pointStart ?? o.pointStart ?? i.getColumn("x")[0]
      }, !g && {
        data: i.options.data
      }, t, l), g && t.data && (t.data = i.options.data), (p = ["group", "markerGroup", "dataLabelsGroup", "transformGroup"].concat(p)).forEach(function (t) {
        p[t] = i[t], delete i[t];
      });
      let m = !1;
      if (rS[u]) {
        if (m = u !== i.type, i.remove(!1, !1, !1, !0), m) {
          if (s.propFromSeries(), Object.setPrototypeOf) Object.setPrototypeOf(i, rS[u].prototype);else {
            let t = Object.hasOwnProperty.call(i, "hcEvents") && i.hcEvents;
            for (c in n) i[c] = void 0;
            rz(i, rS[u].prototype), t ? i.hcEvents = t : delete i.hcEvents;
          }
        }
      } else rB(17, !0, s, {
        missingModuleFor: u
      });
      if (p.forEach(function (t) {
        i[t] = p[t];
      }), i.init(s, t), g && this.points) for (let t of (!1 === (d = i.options).visible ? (h.graphic = 1, h.dataLabel = 1) : (this.hasMarkerChanged(d, o) && (h.graphic = 1), i.hasDataLabels?.() || (h.dataLabel = 1)), this.points)) t && t.series && (t.resolveColor(), Object.keys(h).length && t.destroyElements(h), !1 === d.showInLegend && t.legendItem && s.legend.destroyItem(t));
      i.initialType = r, s.linkSeries(), s.setSortedData(), m && i.linkedSeries.length && (i.isDirtyData = !0), rR(this, "afterUpdate"), rU(e, !0) && s.redraw(!!g && void 0);
    }
    setName(t) {
      this.name = this.options.name = this.userOptions.name = t, this.chart.isDirtyLegend = !0;
    }
    hasOptionChanged(t) {
      let e = this.chart,
        i = this.options[t],
        s = e.options.plotOptions,
        o = this.userOptions[t],
        r = rU(s?.[this.type]?.[t], s?.series?.[t]);
      return o && !rO(r) ? i !== o : i !== rU(r, i);
    }
    onMouseOver() {
      let t = this.chart,
        e = t.hoverSeries,
        i = t.pointer;
      i?.setHoverChartIndex(), e && e !== this && e.onMouseOut(), this.options.events.mouseOver && rR(this, "mouseOver"), this.setState("hover"), t.hoverSeries = this;
    }
    onMouseOut() {
      let t = this.options,
        e = this.chart,
        i = e.tooltip,
        s = e.hoverPoint;
      e.hoverSeries = null, s && s.onMouseOut(), this && t.events.mouseOut && rR(this, "mouseOut"), i && !this.stickyTracking && (!i.shared || this.noSharedTooltip) && i.hide(), e.series.forEach(function (t) {
        t.setState("", !0);
      });
    }
    setState(t, e) {
      let i = this,
        s = i.options,
        o = i.graph,
        r = s.inactiveOtherPoints,
        a = s.states,
        n = rU(a[t || "normal"] && a[t || "normal"].animation, i.chart.options.chart.animation),
        l = s.lineWidth,
        h = s.opacity;
      if (t = t || "", i.state !== t && ([i.group, i.markerGroup, i.dataLabelsGroup].forEach(function (e) {
        e && (i.state && e.removeClass("highcharts-series-" + i.state), t && e.addClass("highcharts-series-" + t));
      }), i.state = t, !i.chart.styledMode)) {
        if (a[t] && !1 === a[t].enabled) return;
        if (t && (l = a[t].lineWidth || l + (a[t].lineWidthPlus || 0), h = rU(a[t].opacity, h)), o && !o.dashstyle && rF(l)) for (let t of [o, ...this.zones.map(t => t.graph)]) t?.animate({
          "stroke-width": l
        }, n);
        r || [i.group, i.markerGroup, i.dataLabelsGroup, i.labelBySeries].forEach(function (t) {
          t && t.animate({
            opacity: h
          }, n);
        });
      }
      e && r && i.points && i.setAllPointsToState(t || void 0);
    }
    setAllPointsToState(t) {
      this.points.forEach(function (e) {
        e.setState && e.setState(t);
      });
    }
    setVisible(t, e) {
      let i = this,
        s = i.chart,
        o = s.options.chart.ignoreHiddenSeries,
        r = i.visible;
      i.visible = t = i.options.visible = i.userOptions.visible = void 0 === t ? !r : t;
      let a = t ? "show" : "hide";
      ["group", "dataLabelsGroup", "markerGroup", "tracker", "tt"].forEach(t => {
        i[t]?.[a]();
      }), (s.hoverSeries === i || s.hoverPoint?.series === i) && i.onMouseOut(), i.legendItem && s.legend.colorizeItem(i, t), i.isDirty = !0, i.options.stacking && s.series.forEach(t => {
        t.options.stacking && t.visible && (t.isDirty = !0);
      }), i.linkedSeries.forEach(e => {
        e.setVisible(t, !1);
      }), o && (s.isDirtyBox = !0), rR(i, a), !1 !== e && s.redraw();
    }
    show() {
      this.setVisible(!0);
    }
    hide() {
      this.setVisible(!1);
    }
    select(t) {
      this.selected = t = this.options.selected = void 0 === t ? !this.selected : t, this.checkbox && (this.checkbox.checked = t), rR(this, t ? "select" : "unselect");
    }
    shouldShowTooltip(t, e, i = {}) {
      return i.series = this, i.visiblePlotOnly = !0, this.chart.isInsidePlot(t, e, i);
    }
    drawLegendSymbol(t, e) {
      rp[this.options.legendSymbol || "rectangle"]?.call(this, t, e);
    }
  }
  r_.defaultOptions = {
    lineWidth: 2,
    allowPointSelect: !1,
    crisp: !0,
    showCheckbox: !1,
    animation: {
      duration: 1e3
    },
    enableMouseTracking: !0,
    events: {},
    marker: {
      enabledThreshold: 2,
      lineColor: "#ffffff",
      lineWidth: 0,
      radius: 4,
      states: {
        normal: {
          animation: !0
        },
        hover: {
          animation: {
            duration: 150
          },
          enabled: !0,
          radiusPlus: 2,
          lineWidthPlus: 1
        },
        select: {
          fillColor: "#cccccc",
          lineColor: "#000000",
          lineWidth: 2
        }
      }
    },
    point: {
      events: {}
    },
    dataLabels: {
      animation: {},
      align: "center",
      borderWidth: 0,
      defer: !0,
      formatter: function () {
        let {
          numberFormatter: t
        } = this.series.chart;
        return "number" != typeof this.y ? "" : t(this.y, -1);
      },
      padding: 5,
      style: {
        fontSize: "0.7em",
        fontWeight: "bold",
        color: "contrast",
        textOutline: "1px contrast"
      },
      verticalAlign: "bottom",
      x: 0,
      y: 0
    },
    cropThreshold: 300,
    opacity: 1,
    pointRange: 0,
    softThreshold: !0,
    states: {
      normal: {
        animation: !0
      },
      hover: {
        animation: {
          duration: 150
        },
        lineWidthPlus: 1,
        marker: {},
        halo: {
          size: 10,
          opacity: .25
        }
      },
      select: {
        animation: {
          duration: 0
        }
      },
      inactive: {
        animation: {
          duration: 150
        },
        opacity: .2
      }
    },
    stickyTracking: !0,
    turboThreshold: 1e3,
    findNearestPointBy: "x"
  }, r_.types = rx.seriesTypes, r_.registerType = rx.registerSeriesType, rz(r_.prototype, {
    axisTypes: ["xAxis", "yAxis"],
    coll: "series",
    colorCounter: 0,
    directTouch: !1,
    invertible: !0,
    isCartesian: !0,
    kdAxisArray: ["clientX", "plotY"],
    parallelArrays: ["x", "y"],
    pointClass: o$,
    requireSorting: !0,
    sorted: !0
  }), rx.series = r_;
  let rq = r_,
    {
      animObject: rK,
      setAnimation: rJ
    } = tH,
    {
      registerEventOptions: rQ
    } = i7,
    {
      composed: r0,
      marginNames: r1
    } = A,
    {
      distribute: r2
    } = ef,
    {
      format: r3
    } = ed,
    {
      addEvent: r6,
      createElement: r5,
      css: r9,
      defined: r8,
      discardElement: r4,
      find: r7,
      fireEvent: at,
      isNumber: ae,
      merge: ai,
      pick: as,
      pushUnique: ao,
      relativeLength: ar,
      stableSort: aa,
      syncTimeout: an
    } = J;
  class al {
    constructor(t, e) {
      this.allItems = [], this.initialItemY = 0, this.itemHeight = 0, this.itemMarginBottom = 0, this.itemMarginTop = 0, this.itemX = 0, this.itemY = 0, this.lastItemY = 0, this.lastLineHeight = 0, this.legendHeight = 0, this.legendWidth = 0, this.maxItemWidth = 0, this.maxLegendWidth = 0, this.offsetWidth = 0, this.padding = 0, this.pages = [], this.symbolHeight = 0, this.symbolWidth = 0, this.titleHeight = 0, this.totalItemWidth = 0, this.widthOption = 0, this.chart = t, this.setOptions(e), e.enabled && (this.render(), rQ(this, e), r6(this.chart, "endResize", function () {
        this.legend.positionCheckboxes();
      })), r6(this.chart, "render", () => {
        this.options.enabled && this.proximate && (this.proximatePositions(), this.positionItems());
      });
    }
    setOptions(t) {
      let e = as(t.padding, 8);
      this.options = t, this.chart.styledMode || (this.itemStyle = t.itemStyle, this.itemHiddenStyle = ai(this.itemStyle, t.itemHiddenStyle)), this.itemMarginTop = t.itemMarginTop, this.itemMarginBottom = t.itemMarginBottom, this.padding = e, this.initialItemY = e - 5, this.symbolWidth = as(t.symbolWidth, 16), this.pages = [], this.proximate = "proximate" === t.layout && !this.chart.inverted, this.baseline = void 0;
    }
    update(t, e) {
      let i = this.chart;
      this.setOptions(ai(!0, this.options, t)), "events" in this.options && rQ(this, this.options), this.destroy(), i.isDirtyLegend = i.isDirtyBox = !0, as(e, !0) && i.redraw(), at(this, "afterUpdate", {
        redraw: e
      });
    }
    colorizeItem(t, e) {
      let i = t.color,
        {
          area: s,
          group: o,
          label: r,
          line: a,
          symbol: n
        } = t.legendItem || {};
      if ((t instanceof rq || t instanceof o$) && (t.color = t.options?.legendSymbolColor || i), o?.[e ? "removeClass" : "addClass"]("highcharts-legend-item-hidden"), !this.chart.styledMode) {
        let {
            itemHiddenStyle: i = {}
          } = this,
          o = i.color,
          {
            fillColor: l,
            fillOpacity: h,
            lineColor: d,
            marker: c
          } = t.options,
          p = t => (!e && (t.fill && (t.fill = o), t.stroke && (t.stroke = o)), t);
        r?.css(ai(e ? this.itemStyle : i)), a?.attr(p({
          stroke: d || t.color
        })), n && n.attr(p(c && n.isMarker ? t.pointAttribs() : {
          fill: t.color
        })), s?.attr(p({
          fill: l || t.color,
          "fill-opacity": l ? 1 : h ?? .75
        }));
      }
      t.color = i, at(this, "afterColorizeItem", {
        item: t,
        visible: e
      });
    }
    positionItems() {
      this.allItems.forEach(this.positionItem, this), this.chart.isResizing || this.positionCheckboxes();
    }
    positionItem(t) {
      let {
          group: e,
          x: i = 0,
          y: s = 0
        } = t.legendItem || {},
        o = this.options,
        r = o.symbolPadding,
        a = !o.rtl,
        n = t.checkbox;
      if (e && e.element) {
        let o = {
          translateX: a ? i : this.legendWidth - i - 2 * r - 4,
          translateY: s
        };
        e[r8(e.translateY) ? "animate" : "attr"](o, void 0, () => {
          at(this, "afterPositionItem", {
            item: t
          });
        });
      }
      n && (n.x = i, n.y = s);
    }
    destroyItem(t) {
      let e = t.checkbox,
        i = t.legendItem || {};
      for (let t of ["group", "label", "line", "symbol"]) i[t] && (i[t] = i[t].destroy());
      e && r4(e), t.legendItem = void 0;
    }
    destroy() {
      for (let t of this.getAllItems()) this.destroyItem(t);
      for (let t of ["clipRect", "up", "down", "pager", "nav", "box", "title", "group"]) this[t] && (this[t] = this[t].destroy());
      this.display = null;
    }
    positionCheckboxes() {
      let t;
      let e = this.group && this.group.alignAttr,
        i = this.clipHeight || this.legendHeight,
        s = this.titleHeight;
      e && (t = e.translateY, this.allItems.forEach(function (o) {
        let r;
        let a = o.checkbox;
        a && (r = t + s + a.y + (this.scrollOffset || 0) + 3, r9(a, {
          left: e.translateX + o.checkboxOffset + a.x - 20 + "px",
          top: r + "px",
          display: this.proximate || r > t - 6 && r < t + i - 6 ? "" : "none"
        }));
      }, this));
    }
    renderTitle() {
      let t = this.options,
        e = this.padding,
        i = t.title,
        s,
        o = 0;
      i.text && (this.title || (this.title = this.chart.renderer.label(i.text, e - 3, e - 4, void 0, void 0, void 0, t.useHTML, void 0, "legend-title").attr({
        zIndex: 1
      }), this.chart.styledMode || this.title.css(i.style), this.title.add(this.group)), i.width || this.title.css({
        width: this.maxLegendWidth + "px"
      }), o = (s = this.title.getBBox()).height, this.offsetWidth = s.width, this.contentGroup.attr({
        translateY: o
      })), this.titleHeight = o;
    }
    setText(t) {
      let e = this.options;
      t.legendItem.label.attr({
        text: e.labelFormat ? r3(e.labelFormat, t, this.chart) : e.labelFormatter.call(t)
      });
    }
    renderItem(t) {
      let e = t.legendItem = t.legendItem || {},
        i = this.chart,
        s = i.renderer,
        o = this.options,
        r = "horizontal" === o.layout,
        a = this.symbolWidth,
        n = o.symbolPadding || 0,
        l = this.itemStyle,
        h = this.itemHiddenStyle,
        d = r ? as(o.itemDistance, 20) : 0,
        c = !o.rtl,
        p = !t.series,
        u = !p && t.series.drawLegendSymbol ? t.series : t,
        g = u.options,
        m = !!this.createCheckboxForItem && g && g.showCheckbox,
        f = o.useHTML,
        x = t.options.className,
        y = e.label,
        b = a + n + d + (m ? 20 : 0);
      !y && (e.group = s.g("legend-item").addClass("highcharts-" + u.type + "-series highcharts-color-" + t.colorIndex + (x ? " " + x : "") + (p ? " highcharts-series-" + t.index : "")).attr({
        zIndex: 1
      }).add(this.scrollGroup), e.label = y = s.text("", c ? a + n : -n, this.baseline || 0, f), i.styledMode || y.css(ai(t.visible ? l : h)), y.attr({
        align: c ? "left" : "right",
        zIndex: 2
      }).add(e.group), !this.baseline && (this.fontMetrics = s.fontMetrics(y), this.baseline = this.fontMetrics.f + 3 + this.itemMarginTop, y.attr("y", this.baseline), this.symbolHeight = as(o.symbolHeight, this.fontMetrics.f), o.squareSymbol && (this.symbolWidth = as(o.symbolWidth, Math.max(this.symbolHeight, 16)), b = this.symbolWidth + n + d + (m ? 20 : 0), c && y.attr("x", this.symbolWidth + n))), u.drawLegendSymbol(this, t), this.setItemEvents && this.setItemEvents(t, y, f)), m && !t.checkbox && this.createCheckboxForItem && this.createCheckboxForItem(t), this.colorizeItem(t, t.visible), (i.styledMode || !l.width) && y.css({
        width: (o.itemWidth || this.widthOption || i.spacingBox.width) - b + "px"
      }), this.setText(t);
      let v = y.getBBox(),
        M = this.fontMetrics && this.fontMetrics.h || 0;
      t.itemWidth = t.checkboxOffset = o.itemWidth || e.labelWidth || v.width + b, this.maxItemWidth = Math.max(this.maxItemWidth, t.itemWidth), this.totalItemWidth += t.itemWidth, this.itemHeight = t.itemHeight = Math.round(e.labelHeight || (v.height > 1.5 * M ? v.height : M));
    }
    layoutItem(t) {
      let e = this.options,
        i = this.padding,
        s = "horizontal" === e.layout,
        o = t.itemHeight,
        r = this.itemMarginBottom,
        a = this.itemMarginTop,
        n = s ? as(e.itemDistance, 20) : 0,
        l = this.maxLegendWidth,
        h = e.alignColumns && this.totalItemWidth > l ? this.maxItemWidth : t.itemWidth,
        d = t.legendItem || {};
      s && this.itemX - i + h > l && (this.itemX = i, this.lastLineHeight && (this.itemY += a + this.lastLineHeight + r), this.lastLineHeight = 0), this.lastItemY = a + this.itemY + r, this.lastLineHeight = Math.max(o, this.lastLineHeight), d.x = this.itemX, d.y = this.itemY, s ? this.itemX += h : (this.itemY += a + o + r, this.lastLineHeight = o), this.offsetWidth = this.widthOption || Math.max((s ? this.itemX - i - (t.checkbox ? 0 : n) : h) + i, this.offsetWidth);
    }
    getAllItems() {
      let t = [];
      return this.chart.series.forEach(function (e) {
        let i = e && e.options;
        e && as(i.showInLegend, !r8(i.linkedTo) && void 0, !0) && (t = t.concat((e.legendItem || {}).labels || ("point" === i.legendType ? e.data : e)));
      }), at(this, "afterGetAllItems", {
        allItems: t
      }), t;
    }
    getAlignment() {
      let t = this.options;
      return this.proximate ? t.align.charAt(0) + "tv" : t.floating ? "" : t.align.charAt(0) + t.verticalAlign.charAt(0) + t.layout.charAt(0);
    }
    adjustMargins(t, e) {
      let i = this.chart,
        s = this.options,
        o = this.getAlignment();
      o && [/(lth|ct|rth)/, /(rtv|rm|rbv)/, /(rbh|cb|lbh)/, /(lbv|lm|ltv)/].forEach(function (r, a) {
        r.test(o) && !r8(t[a]) && (i[r1[a]] = Math.max(i[r1[a]], i.legend[(a + 1) % 2 ? "legendHeight" : "legendWidth"] + [1, -1, -1, 1][a] * s[a % 2 ? "x" : "y"] + as(s.margin, 12) + e[a] + (i.titleOffset[a] || 0)));
      });
    }
    proximatePositions() {
      let t;
      let e = this.chart,
        i = [],
        s = "left" === this.options.align;
      for (let o of (this.allItems.forEach(function (t) {
        let o,
          r,
          a = s,
          n,
          l;
        t.yAxis && (t.xAxis.options.reversed && (a = !a), t.points && (o = r7(a ? t.points : t.points.slice(0).reverse(), function (t) {
          return ae(t.plotY);
        })), r = this.itemMarginTop + t.legendItem.label.getBBox().height + this.itemMarginBottom, l = t.yAxis.top - e.plotTop, n = t.visible ? (o ? o.plotY : t.yAxis.height) + (l - .3 * r) : l + t.yAxis.height, i.push({
          target: n,
          size: r,
          item: t
        }));
      }, this), r2(i, e.plotHeight))) t = o.item.legendItem || {}, ae(o.pos) && (t.y = e.plotTop - e.spacing[0] + o.pos);
    }
    render() {
      let t = this.chart,
        e = t.renderer,
        i = this.options,
        s = this.padding,
        o = this.getAllItems(),
        r,
        a,
        n,
        l = this.group,
        h,
        d = this.box;
      this.itemX = s, this.itemY = this.initialItemY, this.offsetWidth = 0, this.lastItemY = 0, this.widthOption = ar(i.width, t.spacingBox.width - s), h = t.spacingBox.width - 2 * s - i.x, ["rm", "lm"].indexOf(this.getAlignment().substring(0, 2)) > -1 && (h /= 2), this.maxLegendWidth = this.widthOption || h, l || (this.group = l = e.g("legend").addClass(i.className || "").attr({
        zIndex: 7
      }).add(), this.contentGroup = e.g().attr({
        zIndex: 1
      }).add(l), this.scrollGroup = e.g().add(this.contentGroup)), this.renderTitle(), aa(o, (t, e) => (t.options && t.options.legendIndex || 0) - (e.options && e.options.legendIndex || 0)), i.reversed && o.reverse(), this.allItems = o, this.display = r = !!o.length, this.lastLineHeight = 0, this.maxItemWidth = 0, this.totalItemWidth = 0, this.itemHeight = 0, o.forEach(this.renderItem, this), o.forEach(this.layoutItem, this), a = (this.widthOption || this.offsetWidth) + s, n = this.lastItemY + this.lastLineHeight + this.titleHeight, n = this.handleOverflow(n) + s, d || (this.box = d = e.rect().addClass("highcharts-legend-box").attr({
        r: i.borderRadius
      }).add(l)), t.styledMode || d.attr({
        stroke: i.borderColor,
        "stroke-width": i.borderWidth || 0,
        fill: i.backgroundColor || "none"
      }).shadow(i.shadow), a > 0 && n > 0 && d[d.placed ? "animate" : "attr"](d.crisp.call({}, {
        x: 0,
        y: 0,
        width: a,
        height: n
      }, d.strokeWidth())), l[r ? "show" : "hide"](), t.styledMode && "none" === l.getStyle("display") && (a = n = 0), this.legendWidth = a, this.legendHeight = n, r && this.align(), this.proximate || this.positionItems(), at(this, "afterRender");
    }
    align(t = this.chart.spacingBox) {
      let e = this.chart,
        i = this.options,
        s = t.y;
      /(lth|ct|rth)/.test(this.getAlignment()) && e.titleOffset[0] > 0 ? s += e.titleOffset[0] : /(lbh|cb|rbh)/.test(this.getAlignment()) && e.titleOffset[2] > 0 && (s -= e.titleOffset[2]), s !== t.y && (t = ai(t, {
        y: s
      })), e.hasRendered || (this.group.placed = !1), this.group.align(ai(i, {
        width: this.legendWidth,
        height: this.legendHeight,
        verticalAlign: this.proximate ? "top" : i.verticalAlign
      }), !0, t);
    }
    handleOverflow(t) {
      let e = this,
        i = this.chart,
        s = i.renderer,
        o = this.options,
        r = o.y,
        a = "top" === o.verticalAlign,
        n = this.padding,
        l = o.maxHeight,
        h = o.navigation,
        d = as(h.animation, !0),
        c = h.arrowSize || 12,
        p = this.pages,
        u = this.allItems,
        g = function (t) {
          "number" == typeof t ? M.attr({
            height: t
          }) : M && (e.clipRect = M.destroy(), e.contentGroup.clip()), e.contentGroup.div && (e.contentGroup.div.style.clip = t ? "rect(" + n + "px,9999px," + (n + t) + "px,0)" : "auto");
        },
        m = function (t) {
          return e[t] = s.circle(0, 0, 1.3 * c).translate(c / 2, c / 2).add(v), i.styledMode || e[t].attr("fill", "rgba(0,0,0,0.0001)"), e[t];
        },
        f,
        x,
        y,
        b = i.spacingBox.height + (a ? -r : r) - n,
        v = this.nav,
        M = this.clipRect;
      return "horizontal" !== o.layout || "middle" === o.verticalAlign || o.floating || (b /= 2), l && (b = Math.min(b, l)), p.length = 0, t && b > 0 && t > b && !1 !== h.enabled ? (this.clipHeight = f = Math.max(b - 20 - this.titleHeight - n, 0), this.currentPage = as(this.currentPage, 1), this.fullHeight = t, u.forEach((t, e) => {
        let i = (y = t.legendItem || {}).y || 0,
          s = Math.round(y.label.getBBox().height),
          o = p.length;
        (!o || i - p[o - 1] > f && (x || i) !== p[o - 1]) && (p.push(x || i), o++), y.pageIx = o - 1, x && ((u[e - 1].legendItem || {}).pageIx = o - 1), e === u.length - 1 && i + s - p[o - 1] > f && i > p[o - 1] && (p.push(i), y.pageIx = o), i !== x && (x = i);
      }), M || (M = e.clipRect = s.clipRect(0, n - 2, 9999, 0), e.contentGroup.clip(M)), g(f), v || (this.nav = v = s.g().attr({
        zIndex: 1
      }).add(this.group), this.up = s.symbol("triangle", 0, 0, c, c).add(v), m("upTracker").on("click", function () {
        e.scroll(-1, d);
      }), this.pager = s.text("", 15, 10).addClass("highcharts-legend-navigation"), !i.styledMode && h.style && this.pager.css(h.style), this.pager.add(v), this.down = s.symbol("triangle-down", 0, 0, c, c).add(v), m("downTracker").on("click", function () {
        e.scroll(1, d);
      })), e.scroll(0), t = b) : v && (g(), this.nav = v.destroy(), this.scrollGroup.attr({
        translateY: 1
      }), this.clipHeight = 0), t;
    }
    scroll(t, e) {
      let i = this.chart,
        s = this.pages,
        o = s.length,
        r = this.clipHeight,
        a = this.options.navigation,
        n = this.pager,
        l = this.padding,
        h = this.currentPage + t;
      h > o && (h = o), h > 0 && (void 0 !== e && rJ(e, i), this.nav.attr({
        translateX: l,
        translateY: r + this.padding + 7 + this.titleHeight,
        visibility: "inherit"
      }), [this.up, this.upTracker].forEach(function (t) {
        t.attr({
          class: 1 === h ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        });
      }), n.attr({
        text: h + "/" + o
      }), [this.down, this.downTracker].forEach(function (t) {
        t.attr({
          x: 18 + this.pager.getBBox().width,
          class: h === o ? "highcharts-legend-nav-inactive" : "highcharts-legend-nav-active"
        });
      }, this), i.styledMode || (this.up.attr({
        fill: 1 === h ? a.inactiveColor : a.activeColor
      }), this.upTracker.css({
        cursor: 1 === h ? "default" : "pointer"
      }), this.down.attr({
        fill: h === o ? a.inactiveColor : a.activeColor
      }), this.downTracker.css({
        cursor: h === o ? "default" : "pointer"
      })), this.scrollOffset = -s[h - 1] + this.initialItemY, this.scrollGroup.animate({
        translateY: this.scrollOffset
      }), this.currentPage = h, this.positionCheckboxes(), an(() => {
        at(this, "afterScroll", {
          currentPage: h
        });
      }, rK(as(e, i.renderer.globalAnimation, !0)).duration));
    }
    setItemEvents(t, e, i) {
      let s = this,
        o = t.legendItem || {},
        r = s.chart.renderer.boxWrapper,
        a = t instanceof o$,
        n = t instanceof rq,
        l = "highcharts-legend-" + (a ? "point" : "series") + "-active",
        h = s.chart.styledMode,
        d = i ? [e, o.symbol] : [o.group],
        c = e => {
          s.allItems.forEach(i => {
            t !== i && [i].concat(i.linkedSeries || []).forEach(t => {
              t.setState(e, !a);
            });
          });
        };
      for (let i of d) i && i.on("mouseover", function () {
        t.visible && c("inactive"), t.setState("hover"), t.visible && r.addClass(l), h || e.css(s.options.itemHoverStyle);
      }).on("mouseout", function () {
        s.chart.styledMode || e.css(ai(t.visible ? s.itemStyle : s.itemHiddenStyle)), c(""), r.removeClass(l), t.setState();
      }).on("click", function (e) {
        let i = function () {
          t.setVisible && t.setVisible(), c(t.visible ? "inactive" : "");
        };
        r.removeClass(l), at(s, "itemClick", {
          browserEvent: e,
          legendItem: t
        }, i), a ? t.firePointEvent("legendItemClick", {
          browserEvent: e
        }) : n && at(t, "legendItemClick", {
          browserEvent: e
        });
      });
    }
    createCheckboxForItem(t) {
      t.checkbox = r5("input", {
        type: "checkbox",
        className: "highcharts-legend-checkbox",
        checked: t.selected,
        defaultChecked: t.selected
      }, this.options.itemCheckboxStyle, this.chart.container), r6(t.checkbox, "click", function (e) {
        let i = e.target;
        at(t.series || t, "checkboxClick", {
          checked: i.checked,
          item: t
        }, function () {
          t.select();
        });
      });
    }
  }
  !function (t) {
    t.compose = function (e) {
      ao(r0, "Core.Legend") && r6(e, "beforeMargins", function () {
        this.legend = new t(this, this.options.legend);
      });
    };
  }(al || (al = {}));
  let ah = al,
    {
      animate: ad,
      animObject: ac,
      setAnimation: ap
    } = tH,
    {
      defaultOptions: au
    } = tw,
    {
      numberFormat: ag
    } = ed,
    {
      registerEventOptions: am
    } = i7,
    {
      charts: af,
      doc: ax,
      marginNames: ay,
      svg: ab,
      win: av
    } = A,
    {
      seriesTypes: aM
    } = rx,
    {
      addEvent: aw,
      attr: ak,
      createElement: aS,
      css: aT,
      defined: aC,
      diffObjects: aA,
      discardElement: aP,
      erase: aL,
      error: aO,
      extend: aE,
      find: aI,
      fireEvent: aD,
      getAlignFactor: aB,
      getStyle: az,
      isArray: aN,
      isNumber: aR,
      isObject: aW,
      isString: aj,
      merge: aX,
      objectEach: aG,
      pick: aF,
      pInt: aH,
      relativeLength: aY,
      removeEvent: aV,
      splat: aU,
      syncTimeout: aZ,
      uniqueKey: a$
    } = J;
  class a_ {
    static chart(t, e, i) {
      return new a_(t, e, i);
    }
    constructor(t, e, i) {
      this.sharedClips = {};
      let s = [...arguments];
      (aj(t) || t.nodeName) && (this.renderTo = s.shift()), this.init(s[0], s[1]);
    }
    setZoomOptions() {
      let t = this.options.chart,
        e = t.zooming;
      this.zooming = {
        ...e,
        type: aF(t.zoomType, e.type),
        key: aF(t.zoomKey, e.key),
        pinchType: aF(t.pinchType, e.pinchType),
        singleTouch: aF(t.zoomBySingleTouch, e.singleTouch, !1),
        resetButton: aX(e.resetButton, t.resetZoomButton)
      };
    }
    init(t, e) {
      aD(this, "init", {
        args: arguments
      }, function () {
        let i = aX(au, t),
          s = i.chart,
          o = this.renderTo || s.renderTo;
        this.userOptions = aE({}, t), (this.renderTo = aj(o) ? ax.getElementById(o) : o) || aO(13, !0, this), this.margin = [], this.spacing = [], this.labelCollectors = [], this.callback = e, this.isResizing = 0, this.options = i, this.axes = [], this.series = [], this.locale = i.lang.locale ?? this.renderTo.closest("[lang]")?.lang, this.time = new tf(aE(i.time || {}, {
          locale: this.locale
        })), i.time = this.time.options, this.numberFormatter = (s.numberFormatter || ag).bind(this), this.styledMode = s.styledMode, this.hasCartesianSeries = s.showAxes, this.index = af.length, af.push(this), A.chartCount++, am(this, s), this.xAxis = [], this.yAxis = [], this.pointCount = this.colorCounter = this.symbolCounter = 0, this.setZoomOptions(), aD(this, "afterInit"), this.firstRender();
      });
    }
    initSeries(t) {
      let e = this.options.chart,
        i = t.type || e.type,
        s = aM[i];
      s || aO(17, !0, this, {
        missingModuleFor: i
      });
      let o = new s();
      return "function" == typeof o.init && o.init(this, t), o;
    }
    setSortedData() {
      this.getSeriesOrderByLinks().forEach(function (t) {
        t.points || t.data || !t.enabledDataSorting || t.setData(t.options.data, !1);
      });
    }
    getSeriesOrderByLinks() {
      return this.series.concat().sort(function (t, e) {
        return t.linkedSeries.length || e.linkedSeries.length ? e.linkedSeries.length - t.linkedSeries.length : 0;
      });
    }
    orderItems(t, e = 0) {
      let i = this[t],
        s = this.options[t] = aU(this.options[t]).slice(),
        o = this.userOptions[t] = this.userOptions[t] ? aU(this.userOptions[t]).slice() : [];
      if (this.hasRendered && (s.splice(e), o.splice(e)), i) for (let t = e, r = i.length; t < r; ++t) {
        let e = i[t];
        e && (e.index = t, e instanceof rq && (e.name = e.getName()), e.options.isInternal || (s[t] = e.options, o[t] = e.userOptions));
      }
    }
    isInsidePlot(t, e, i = {}) {
      let {
          inverted: s,
          plotBox: o,
          plotLeft: r,
          plotTop: a,
          scrollablePlotBox: n
        } = this,
        {
          scrollLeft: l = 0,
          scrollTop: h = 0
        } = i.visiblePlotOnly && this.scrollablePlotArea?.scrollingContainer || {},
        d = i.series,
        c = i.visiblePlotOnly && n || o,
        p = i.inverted ? e : t,
        u = i.inverted ? t : e,
        g = {
          x: p,
          y: u,
          isInsidePlot: !0,
          options: i
        };
      if (!i.ignoreX) {
        let t = d && (s && !this.polar ? d.yAxis : d.xAxis) || {
            pos: r,
            len: 1 / 0
          },
          e = i.paneCoordinates ? t.pos + p : r + p;
        e >= Math.max(l + r, t.pos) && e <= Math.min(l + r + c.width, t.pos + t.len) || (g.isInsidePlot = !1);
      }
      if (!i.ignoreY && g.isInsidePlot) {
        let t = !s && i.axis && !i.axis.isXAxis && i.axis || d && (s ? d.xAxis : d.yAxis) || {
            pos: a,
            len: 1 / 0
          },
          e = i.paneCoordinates ? t.pos + u : a + u;
        e >= Math.max(h + a, t.pos) && e <= Math.min(h + a + c.height, t.pos + t.len) || (g.isInsidePlot = !1);
      }
      return aD(this, "afterIsInsidePlot", g), g.isInsidePlot;
    }
    redraw(t) {
      aD(this, "beforeRedraw");
      let e = this.hasCartesianSeries ? this.axes : this.colorAxis || [],
        i = this.series,
        s = this.pointer,
        o = this.legend,
        r = this.userOptions.legend,
        a = this.renderer,
        n = a.isHidden(),
        l = [],
        h,
        d,
        c,
        p = this.isDirtyBox,
        u = this.isDirtyLegend,
        g;
      for (a.rootFontSize = a.boxWrapper.getStyle("font-size"), this.setResponsive && this.setResponsive(!1), ap(!!this.hasRendered && t, this), n && this.temporaryDisplay(), this.layOutTitles(!1), c = i.length; c--;) if (((g = i[c]).options.stacking || g.options.centerInCategory) && (d = !0, g.isDirty)) {
        h = !0;
        break;
      }
      if (h) for (c = i.length; c--;) (g = i[c]).options.stacking && (g.isDirty = !0);
      i.forEach(function (t) {
        t.isDirty && ("point" === t.options.legendType ? ("function" == typeof t.updateTotals && t.updateTotals(), u = !0) : r && (r.labelFormatter || r.labelFormat) && (u = !0)), t.isDirtyData && aD(t, "updatedData");
      }), u && o && o.options.enabled && (o.render(), this.isDirtyLegend = !1), d && this.getStacks(), e.forEach(function (t) {
        t.updateNames(), t.setScale();
      }), this.getMargins(), e.forEach(function (t) {
        t.isDirty && (p = !0);
      }), e.forEach(function (t) {
        let e = t.min + "," + t.max;
        t.extKey !== e && (t.extKey = e, l.push(function () {
          aD(t, "afterSetExtremes", aE(t.eventArgs, t.getExtremes())), delete t.eventArgs;
        })), (p || d) && t.redraw();
      }), p && this.drawChartBox(), aD(this, "predraw"), i.forEach(function (t) {
        (p || t.isDirty) && t.visible && t.redraw(), t.isDirtyData = !1;
      }), s && s.reset(!0), a.draw(), aD(this, "redraw"), aD(this, "render"), n && this.temporaryDisplay(!0), l.forEach(function (t) {
        t.call();
      });
    }
    get(t) {
      let e = this.series;
      function i(e) {
        return e.id === t || e.options && e.options.id === t;
      }
      let s = aI(this.axes, i) || aI(this.series, i);
      for (let t = 0; !s && t < e.length; t++) s = aI(e[t].points || [], i);
      return s;
    }
    createAxes() {
      let t = this.userOptions;
      for (let e of (aD(this, "createAxes"), ["xAxis", "yAxis"])) for (let i of t[e] = aU(t[e] || {})) new sY(this, i, e);
      aD(this, "afterCreateAxes");
    }
    getSelectedPoints() {
      return this.series.reduce((t, e) => (e.getPointsCollection().forEach(e => {
        aF(e.selectedStaging, e.selected) && t.push(e);
      }), t), []);
    }
    getSelectedSeries() {
      return this.series.filter(t => t.selected);
    }
    setTitle(t, e, i) {
      this.applyDescription("title", t), this.applyDescription("subtitle", e), this.applyDescription("caption", void 0), this.layOutTitles(i);
    }
    applyDescription(t, e) {
      let i = this,
        s = this.options[t] = aX(this.options[t], e),
        o = this[t];
      o && e && (this[t] = o = o.destroy()), s && !o && ((o = this.renderer.text(s.text, 0, 0, s.useHTML).attr({
        align: s.align,
        class: "highcharts-" + t,
        zIndex: s.zIndex || 4
      }).css({
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }).add()).update = function (e, s) {
        i.applyDescription(t, e), i.layOutTitles(s);
      }, this.styledMode || o.css(aE("title" === t ? {
        fontSize: this.options.isStock ? "1em" : "1.2em"
      } : {}, s.style)), o.textPxLength = o.getBBox().width, o.css({
        whiteSpace: s.style?.whiteSpace
      }), this[t] = o);
    }
    layOutTitles(t = !0) {
      let e = [0, 0, 0],
        {
          options: i,
          renderer: s,
          spacingBox: o
        } = this;
      ["title", "subtitle", "caption"].forEach(t => {
        let i = this[t],
          r = this.options[t],
          a = aX(o),
          n = i?.textPxLength || 0;
        if (i && r) {
          aD(this, "layOutTitle", {
            alignTo: a,
            key: t,
            textPxLength: n
          });
          let o = s.fontMetrics(i),
            l = o.b,
            h = o.h,
            d = r.verticalAlign || "top",
            c = "top" === d,
            p = c && r.minScale || 1,
            u = "title" === t ? c ? -3 : 0 : c ? e[0] + 2 : 0,
            g = Math.min(a.width / n, 1),
            m = Math.max(p, g),
            f = aX({
              y: "bottom" === d ? l : u + l
            }, {
              align: "title" === t ? g < p ? "left" : "center" : this.title?.alignValue
            }, r),
            x = r.width || (g > p ? this.chartWidth : a.width) / m;
          i.alignValue !== f.align && (i.placed = !1);
          let y = Math.round(i.css({
            width: `${x}px`
          }).getBBox(r.useHTML).height);
          if (f.height = y, i.align(f, !1, a).attr({
            align: f.align,
            scaleX: m,
            scaleY: m,
            "transform-origin": `${a.x + n * m * aB(f.align)} ${h}`
          }), !r.floating) {
            let t = y * (y < 1.2 * h ? 1 : m);
            "top" === d ? e[0] = Math.ceil(e[0] + t) : "bottom" === d && (e[2] = Math.ceil(e[2] + t));
          }
        }
      }, this), e[0] && "top" === (i.title?.verticalAlign || "top") && (e[0] += i.title?.margin || 0), e[2] && i.caption?.verticalAlign === "bottom" && (e[2] += i.caption?.margin || 0);
      let r = !this.titleOffset || this.titleOffset.join(",") !== e.join(",");
      this.titleOffset = e, aD(this, "afterLayOutTitles"), !this.isDirtyBox && r && (this.isDirtyBox = this.isDirtyLegend = r, this.hasRendered && t && this.isDirtyBox && this.redraw());
    }
    getContainerBox() {
      let t = [].map.call(this.renderTo.children, t => {
          if (t !== this.container) {
            let e = t.style.display;
            return t.style.display = "none", [t, e];
          }
        }),
        e = {
          width: az(this.renderTo, "width", !0) || 0,
          height: az(this.renderTo, "height", !0) || 0
        };
      return t.filter(Boolean).forEach(([t, e]) => {
        t.style.display = e;
      }), e;
    }
    getChartSize() {
      let t = this.options.chart,
        e = t.width,
        i = t.height,
        s = this.getContainerBox(),
        o = s.height <= 1 || !this.renderTo.parentElement?.style.height && "100%" === this.renderTo.style.height;
      this.chartWidth = Math.max(0, e || s.width || 600), this.chartHeight = Math.max(0, aY(i, this.chartWidth) || (o ? 400 : s.height)), this.containerBox = s;
    }
    temporaryDisplay(t) {
      let e = this.renderTo,
        i;
      if (t) for (; e && e.style;) e.hcOrigStyle && (aT(e, e.hcOrigStyle), delete e.hcOrigStyle), e.hcOrigDetached && (ax.body.removeChild(e), e.hcOrigDetached = !1), e = e.parentNode;else for (; e && e.style && (ax.body.contains(e) || e.parentNode || (e.hcOrigDetached = !0, ax.body.appendChild(e)), ("none" === az(e, "display", !1) || e.hcOricDetached) && (e.hcOrigStyle = {
        display: e.style.display,
        height: e.style.height,
        overflow: e.style.overflow
      }, i = {
        display: "block",
        overflow: "hidden"
      }, e !== this.renderTo && (i.height = 0), aT(e, i), e.offsetWidth || e.style.setProperty("display", "block", "important")), (e = e.parentNode) !== ax.body););
    }
    setClassName(t) {
      this.container.className = "highcharts-container " + (t || "");
    }
    getContainer() {
      let t;
      let e = this.options,
        i = e.chart,
        s = "data-highcharts-chart",
        o = a$(),
        r = this.renderTo,
        a = aH(ak(r, s));
      aR(a) && af[a] && af[a].hasRendered && af[a].destroy(), ak(r, s, this.index), r.innerHTML = t3.emptyHTML, i.skipClone || r.offsetWidth || this.temporaryDisplay(), this.getChartSize();
      let n = this.chartHeight,
        l = this.chartWidth;
      aT(r, {
        overflow: "hidden"
      }), this.styledMode || (t = aE({
        position: "relative",
        overflow: "hidden",
        width: l + "px",
        height: n + "px",
        textAlign: "left",
        lineHeight: "normal",
        zIndex: 0,
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
        userSelect: "none",
        "touch-action": "manipulation",
        outline: "none",
        padding: "0px"
      }, i.style || {}));
      let h = aS("div", {
        id: o
      }, t, r);
      this.container = h, this.getChartSize(), l === this.chartWidth || (l = this.chartWidth, this.styledMode || aT(h, {
        width: aF(i.style?.width, l + "px")
      })), this.containerBox = this.getContainerBox(), this._cursor = h.style.cursor;
      let d = i.renderer || !ab ? ec.getRendererType(i.renderer) : iH;
      if (this.renderer = new d(h, l, n, void 0, i.forExport, e.exporting && e.exporting.allowHTML, this.styledMode), ap(void 0, this), this.setClassName(i.className), this.styledMode) for (let t in e.defs) this.renderer.definition(e.defs[t]);else this.renderer.setStyle(i.style);
      this.renderer.chartIndex = this.index, aD(this, "afterGetContainer");
    }
    getMargins(t) {
      let {
        spacing: e,
        margin: i,
        titleOffset: s
      } = this;
      this.resetMargins(), s[0] && !aC(i[0]) && (this.plotTop = Math.max(this.plotTop, s[0] + e[0])), s[2] && !aC(i[2]) && (this.marginBottom = Math.max(this.marginBottom, s[2] + e[2])), this.legend && this.legend.display && this.legend.adjustMargins(i, e), aD(this, "getMargins"), t || this.getAxisMargins();
    }
    getAxisMargins() {
      let t = this,
        e = t.axisOffset = [0, 0, 0, 0],
        i = t.colorAxis,
        s = t.margin,
        o = function (t) {
          t.forEach(function (t) {
            t.visible && t.getOffset();
          });
        };
      t.hasCartesianSeries ? o(t.axes) : i && i.length && o(i), ay.forEach(function (i, o) {
        aC(s[o]) || (t[i] += e[o]);
      }), t.setChartSize();
    }
    getOptions() {
      return aA(this.userOptions, au);
    }
    reflow(t) {
      let e = this,
        i = e.containerBox,
        s = e.getContainerBox();
      delete e.pointer?.chartPosition, !e.isPrinting && !e.isResizing && i && s.width && ((s.width !== i.width || s.height !== i.height) && (J.clearTimeout(e.reflowTimeout), e.reflowTimeout = aZ(function () {
        e.container && e.setSize(void 0, void 0, !1);
      }, t ? 100 : 0)), e.containerBox = s);
    }
    setReflow() {
      let t = this,
        e = e => {
          t.options?.chart.reflow && t.hasLoaded && t.reflow(e);
        };
      if ("function" == typeof ResizeObserver) new ResizeObserver(e).observe(t.renderTo);else {
        let t = aw(av, "resize", e);
        aw(this, "destroy", t);
      }
    }
    setSize(t, e, i) {
      let s = this,
        o = s.renderer;
      s.isResizing += 1, ap(i, s);
      let r = o.globalAnimation;
      s.oldChartHeight = s.chartHeight, s.oldChartWidth = s.chartWidth, void 0 !== t && (s.options.chart.width = t), void 0 !== e && (s.options.chart.height = e), s.getChartSize();
      let {
        chartWidth: a,
        chartHeight: n,
        scrollablePixelsX: l = 0,
        scrollablePixelsY: h = 0
      } = s;
      (s.isDirtyBox || a !== s.oldChartWidth || n !== s.oldChartHeight) && (s.styledMode || (r ? ad : aT)(s.container, {
        width: `${a + l}px`,
        height: `${n + h}px`
      }, r), s.setChartSize(!0), o.setSize(a, n, r), s.axes.forEach(function (t) {
        t.isDirty = !0, t.setScale();
      }), s.isDirtyLegend = !0, s.isDirtyBox = !0, s.layOutTitles(), s.getMargins(), s.redraw(r), s.oldChartHeight = void 0, aD(s, "resize"), setTimeout(() => {
        s && aD(s, "endResize");
      }, ac(r).duration)), s.isResizing -= 1;
    }
    setChartSize(t) {
      let e, i, s, o;
      let {
          chartHeight: r,
          chartWidth: a,
          inverted: n,
          spacing: l,
          renderer: h
        } = this,
        d = this.clipOffset,
        c = Math[n ? "floor" : "round"];
      this.plotLeft = e = Math.round(this.plotLeft), this.plotTop = i = Math.round(this.plotTop), this.plotWidth = s = Math.max(0, Math.round(a - e - this.marginRight)), this.plotHeight = o = Math.max(0, Math.round(r - i - this.marginBottom)), this.plotSizeX = n ? o : s, this.plotSizeY = n ? s : o, this.spacingBox = h.spacingBox = {
        x: l[3],
        y: l[0],
        width: a - l[3] - l[1],
        height: r - l[0] - l[2]
      }, this.plotBox = h.plotBox = {
        x: e,
        y: i,
        width: s,
        height: o
      }, d && (this.clipBox = {
        x: c(d[3]),
        y: c(d[0]),
        width: c(this.plotSizeX - d[1] - d[3]),
        height: c(this.plotSizeY - d[0] - d[2])
      }), t || (this.axes.forEach(function (t) {
        t.setAxisSize(), t.setAxisTranslation();
      }), h.alignElements()), aD(this, "afterSetChartSize", {
        skipAxes: t
      });
    }
    resetMargins() {
      aD(this, "resetMargins");
      let t = this,
        e = t.options.chart,
        i = e.plotBorderWidth || 0,
        s = i / 2;
      ["margin", "spacing"].forEach(function (i) {
        let s = e[i],
          o = aW(s) ? s : [s, s, s, s];
        ["Top", "Right", "Bottom", "Left"].forEach(function (s, r) {
          t[i][r] = aF(e[i + s], o[r]);
        });
      }), ay.forEach(function (e, i) {
        t[e] = aF(t.margin[i], t.spacing[i]);
      }), t.axisOffset = [0, 0, 0, 0], t.clipOffset = [s, s, s, s], t.plotBorderWidth = i;
    }
    drawChartBox() {
      let t = this.options.chart,
        e = this.renderer,
        i = this.chartWidth,
        s = this.chartHeight,
        o = this.styledMode,
        r = this.plotBGImage,
        a = t.backgroundColor,
        n = t.plotBackgroundColor,
        l = t.plotBackgroundImage,
        h = this.plotLeft,
        d = this.plotTop,
        c = this.plotWidth,
        p = this.plotHeight,
        u = this.plotBox,
        g = this.clipRect,
        m = this.clipBox,
        f = this.chartBackground,
        x = this.plotBackground,
        y = this.plotBorder,
        b,
        v,
        M,
        w = "animate";
      f || (this.chartBackground = f = e.rect().addClass("highcharts-background").add(), w = "attr"), o ? b = v = f.strokeWidth() : (v = (b = t.borderWidth || 0) + (t.shadow ? 8 : 0), M = {
        fill: a || "none"
      }, (b || f["stroke-width"]) && (M.stroke = t.borderColor, M["stroke-width"] = b), f.attr(M).shadow(t.shadow)), f[w]({
        x: v / 2,
        y: v / 2,
        width: i - v - b % 2,
        height: s - v - b % 2,
        r: t.borderRadius
      }), w = "animate", x || (w = "attr", this.plotBackground = x = e.rect().addClass("highcharts-plot-background").add()), x[w](u), !o && (x.attr({
        fill: n || "none"
      }).shadow(t.plotShadow), l && (r ? (l !== r.attr("href") && r.attr("href", l), r.animate(u)) : this.plotBGImage = e.image(l, h, d, c, p).add())), g ? g.animate({
        width: m.width,
        height: m.height
      }) : this.clipRect = e.clipRect(m), w = "animate", y || (w = "attr", this.plotBorder = y = e.rect().addClass("highcharts-plot-border").attr({
        zIndex: 1
      }).add()), o || y.attr({
        stroke: t.plotBorderColor,
        "stroke-width": t.plotBorderWidth || 0,
        fill: "none"
      }), y[w](y.crisp({
        x: h,
        y: d,
        width: c,
        height: p
      }, -y.strokeWidth())), this.isDirtyBox = !1, aD(this, "afterDrawChartBox");
    }
    propFromSeries() {
      let t, e, i;
      let s = this,
        o = s.options.chart,
        r = s.options.series;
      ["inverted", "angular", "polar"].forEach(function (a) {
        for (e = aM[o.type], i = o[a] || e && e.prototype[a], t = r && r.length; !i && t--;) (e = aM[r[t].type]) && e.prototype[a] && (i = !0);
        s[a] = i;
      });
    }
    linkSeries(t) {
      let e = this,
        i = e.series;
      i.forEach(function (t) {
        t.linkedSeries.length = 0;
      }), i.forEach(function (t) {
        let {
          linkedTo: i
        } = t.options;
        if (aj(i)) {
          let s;
          (s = ":previous" === i ? e.series[t.index - 1] : e.get(i)) && s.linkedParent !== t && (s.linkedSeries.push(t), t.linkedParent = s, s.enabledDataSorting && t.setDataSortingOptions(), t.visible = aF(t.options.visible, s.options.visible, t.visible));
        }
      }), aD(this, "afterLinkSeries", {
        isUpdating: t
      });
    }
    renderSeries() {
      this.series.forEach(function (t) {
        t.translate(), t.render();
      });
    }
    render() {
      let t = this.axes,
        e = this.colorAxis,
        i = this.renderer,
        s = this.options.chart.axisLayoutRuns || 2,
        o = t => {
          t.forEach(t => {
            t.visible && t.render();
          });
        },
        r = 0,
        a = !0,
        n,
        l = 0;
      for (let e of (this.setTitle(), aD(this, "beforeMargins"), this.getStacks?.(), this.getMargins(!0), this.setChartSize(), t)) {
        let {
            options: t
          } = e,
          {
            labels: i
          } = t;
        if (this.hasCartesianSeries && e.horiz && e.visible && i.enabled && e.series.length && "colorAxis" !== e.coll && !this.polar) {
          r = t.tickLength, e.createGroups();
          let s = new sp(e, 0, "", !0),
            o = s.createLabel("x", i);
          if (s.destroy(), o && aF(i.reserveSpace, !aR(t.crossing)) && (r = o.getBBox().height + i.distance + Math.max(t.offset || 0, 0)), r) {
            o?.destroy();
            break;
          }
        }
      }
      for (this.plotHeight = Math.max(this.plotHeight - r, 0); (a || n || s > 1) && l < s;) {
        let e = this.plotWidth,
          i = this.plotHeight;
        for (let e of t) 0 === l ? e.setScale() : (e.horiz && a || !e.horiz && n) && e.setTickInterval(!0);
        0 === l ? this.getAxisMargins() : this.getMargins(), a = e / this.plotWidth > (l ? 1 : 1.1), n = i / this.plotHeight > (l ? 1 : 1.05), l++;
      }
      this.drawChartBox(), this.hasCartesianSeries ? o(t) : e && e.length && o(e), this.seriesGroup || (this.seriesGroup = i.g("series-group").attr({
        zIndex: 3
      }).shadow(this.options.chart.seriesGroupShadow).add()), this.renderSeries(), this.addCredits(), this.setResponsive && this.setResponsive(), this.hasRendered = !0;
    }
    addCredits(t) {
      let e = this,
        i = aX(!0, this.options.credits, t);
      i.enabled && !this.credits && (this.credits = this.renderer.text(i.text + (this.mapCredits || ""), 0, 0).addClass("highcharts-credits").on("click", function () {
        i.href && (av.location.href = i.href);
      }).attr({
        align: i.position.align,
        zIndex: 8
      }), e.styledMode || this.credits.css(i.style), this.credits.add().align(i.position), this.credits.update = function (t) {
        e.credits = e.credits.destroy(), e.addCredits(t);
      });
    }
    destroy() {
      let t;
      let e = this,
        i = e.axes,
        s = e.series,
        o = e.container,
        r = o && o.parentNode;
      for (aD(e, "destroy"), e.renderer.forExport ? aL(af, e) : af[e.index] = void 0, A.chartCount--, e.renderTo.removeAttribute("data-highcharts-chart"), aV(e), t = i.length; t--;) i[t] = i[t].destroy();
      for (this.scroller && this.scroller.destroy && this.scroller.destroy(), t = s.length; t--;) s[t] = s[t].destroy();
      ["title", "subtitle", "chartBackground", "plotBackground", "plotBGImage", "plotBorder", "seriesGroup", "clipRect", "credits", "pointer", "rangeSelector", "legend", "resetZoomButton", "tooltip", "renderer"].forEach(function (t) {
        let i = e[t];
        i && i.destroy && (e[t] = i.destroy());
      }), o && (o.innerHTML = t3.emptyHTML, aV(o), r && aP(o)), aG(e, function (t, i) {
        delete e[i];
      });
    }
    firstRender() {
      let t = this,
        e = t.options;
      t.getContainer(), t.resetMargins(), t.setChartSize(), t.propFromSeries(), t.createAxes();
      let i = aN(e.series) ? e.series : [];
      e.series = [], i.forEach(function (e) {
        t.initSeries(e);
      }), t.linkSeries(), t.setSortedData(), aD(t, "beforeRender"), t.render(), t.pointer?.getChartPosition(), t.renderer.imgCount || t.hasLoaded || t.onload(), t.temporaryDisplay(!0);
    }
    onload() {
      this.callbacks.concat([this.callback]).forEach(function (t) {
        t && void 0 !== this.index && t.apply(this, [this]);
      }, this), aD(this, "load"), aD(this, "render"), aC(this.index) && this.setReflow(), this.warnIfA11yModuleNotLoaded(), this.hasLoaded = !0;
    }
    warnIfA11yModuleNotLoaded() {
      let {
        options: t,
        title: e
      } = this;
      !t || this.accessibility || (this.renderer.boxWrapper.attr({
        role: "img",
        "aria-label": (e && e.element.textContent || "").replace(/</g, "&lt;")
      }), t.accessibility && !1 === t.accessibility.enabled || aO('Highcharts warning: Consider including the "accessibility.js" module to make your chart more usable for people with disabilities. Set the "accessibility.enabled" option to false to remove this warning. See https://www.highcharts.com/docs/accessibility/accessibility-module.', !1, this));
    }
    addSeries(t, e, i) {
      let s;
      let o = this;
      return t && (e = aF(e, !0), aD(o, "addSeries", {
        options: t
      }, function () {
        s = o.initSeries(t), o.isDirtyLegend = !0, o.linkSeries(), s.enabledDataSorting && s.setData(t.data, !1), aD(o, "afterAddSeries", {
          series: s
        }), e && o.redraw(i);
      })), s;
    }
    addAxis(t, e, i, s) {
      return this.createAxis(e ? "xAxis" : "yAxis", {
        axis: t,
        redraw: i,
        animation: s
      });
    }
    addColorAxis(t, e, i) {
      return this.createAxis("colorAxis", {
        axis: t,
        redraw: e,
        animation: i
      });
    }
    createAxis(t, e) {
      let i = new sY(this, e.axis, t);
      return aF(e.redraw, !0) && this.redraw(e.animation), i;
    }
    showLoading(t) {
      let e = this,
        i = e.options,
        s = i.loading,
        o = function () {
          r && aT(r, {
            left: e.plotLeft + "px",
            top: e.plotTop + "px",
            width: e.plotWidth + "px",
            height: e.plotHeight + "px"
          });
        },
        r = e.loadingDiv,
        a = e.loadingSpan;
      r || (e.loadingDiv = r = aS("div", {
        className: "highcharts-loading highcharts-loading-hidden"
      }, null, e.container)), a || (e.loadingSpan = a = aS("span", {
        className: "highcharts-loading-inner"
      }, null, r), aw(e, "redraw", o)), r.className = "highcharts-loading", t3.setElementHTML(a, aF(t, i.lang.loading, "")), e.styledMode || (aT(r, aE(s.style, {
        zIndex: 10
      })), aT(a, s.labelStyle), e.loadingShown || (aT(r, {
        opacity: 0,
        display: ""
      }), ad(r, {
        opacity: s.style.opacity || .5
      }, {
        duration: s.showDuration || 0
      }))), e.loadingShown = !0, o();
    }
    hideLoading() {
      let t = this.options,
        e = this.loadingDiv;
      e && (e.className = "highcharts-loading highcharts-loading-hidden", this.styledMode || ad(e, {
        opacity: 0
      }, {
        duration: t.loading.hideDuration || 100,
        complete: function () {
          aT(e, {
            display: "none"
          });
        }
      })), this.loadingShown = !1;
    }
    update(t, e, i, s) {
      let o, r, a;
      let n = this,
        l = {
          credits: "addCredits",
          title: "setTitle",
          subtitle: "setSubtitle",
          caption: "setCaption"
        },
        h = t.isResponsiveOptions,
        d = [];
      aD(n, "update", {
        options: t
      }), h || n.setResponsive(!1, !0), t = aA(t, n.options), n.userOptions = aX(n.userOptions, t);
      let c = t.chart;
      c && (aX(!0, n.options.chart, c), this.setZoomOptions(), "className" in c && n.setClassName(c.className), ("inverted" in c || "polar" in c || "type" in c) && (n.propFromSeries(), o = !0), "alignTicks" in c && (o = !0), "events" in c && am(this, c), aG(c, function (t, e) {
        -1 !== n.propsRequireUpdateSeries.indexOf("chart." + e) && (r = !0), -1 !== n.propsRequireDirtyBox.indexOf(e) && (n.isDirtyBox = !0), -1 === n.propsRequireReflow.indexOf(e) || (n.isDirtyBox = !0, h || (a = !0));
      }), !n.styledMode && c.style && n.renderer.setStyle(n.options.chart.style || {})), !n.styledMode && t.colors && (this.options.colors = t.colors), aG(t, function (e, i) {
        n[i] && "function" == typeof n[i].update ? n[i].update(e, !1) : "function" == typeof n[l[i]] ? n[l[i]](e) : "colors" !== i && -1 === n.collectionsWithUpdate.indexOf(i) && aX(!0, n.options[i], t[i]), "chart" !== i && -1 !== n.propsRequireUpdateSeries.indexOf(i) && (r = !0);
      }), this.collectionsWithUpdate.forEach(function (e) {
        t[e] && (aU(t[e]).forEach(function (t, s) {
          let o;
          let r = aC(t.id);
          r && (o = n.get(t.id)), !o && n[e] && (o = n[e][aF(t.index, s)]) && (r && aC(o.options.id) || o.options.isInternal) && (o = void 0), o && o.coll === e && (o.update(t, !1), i && (o.touched = !0)), !o && i && n.collectionsWithInit[e] && (n.collectionsWithInit[e][0].apply(n, [t].concat(n.collectionsWithInit[e][1] || []).concat([!1])).touched = !0);
        }), i && n[e].forEach(function (t) {
          t.touched || t.options.isInternal ? delete t.touched : d.push(t);
        }));
      }), d.forEach(function (t) {
        t.chart && t.remove && t.remove(!1);
      }), o && n.axes.forEach(function (t) {
        t.update({}, !1);
      }), r && n.getSeriesOrderByLinks().forEach(function (t) {
        t.chart && t.update({}, !1);
      }, this);
      let p = c && c.width,
        u = c && (aj(c.height) ? aY(c.height, p || n.chartWidth) : c.height);
      a || aR(p) && p !== n.chartWidth || aR(u) && u !== n.chartHeight ? n.setSize(p, u, s) : aF(e, !0) && n.redraw(s), aD(n, "afterUpdate", {
        options: t,
        redraw: e,
        animation: s
      });
    }
    setSubtitle(t, e) {
      this.applyDescription("subtitle", t), this.layOutTitles(e);
    }
    setCaption(t, e) {
      this.applyDescription("caption", t), this.layOutTitles(e);
    }
    showResetZoom() {
      let t = this,
        e = au.lang,
        i = t.zooming.resetButton,
        s = i.theme,
        o = "chart" === i.relativeTo || "spacingBox" === i.relativeTo ? null : "plotBox";
      function r() {
        t.zoomOut();
      }
      aD(this, "beforeShowResetZoom", null, function () {
        t.resetZoomButton = t.renderer.button(e.resetZoom, null, null, r, s).attr({
          align: i.position.align,
          title: e.resetZoomTitle
        }).addClass("highcharts-reset-zoom").add().align(i.position, !1, o);
      }), aD(this, "afterShowResetZoom");
    }
    zoomOut() {
      aD(this, "selection", {
        resetSelection: !0
      }, () => this.transform({
        reset: !0,
        trigger: "zoom"
      }));
    }
    pan(t, e) {
      let i = this,
        s = "object" == typeof e ? e : {
          enabled: e,
          type: "x"
        },
        o = s.type,
        r = o && i[{
          x: "xAxis",
          xy: "axes",
          y: "yAxis"
        }[o]].filter(t => t.options.panningEnabled && !t.options.isInternal),
        a = i.options.chart;
      a?.panning && (a.panning = s), aD(this, "pan", {
        originalEvent: t
      }, () => {
        i.transform({
          axes: r,
          event: t,
          to: {
            x: t.chartX - (i.mouseDownX || 0),
            y: t.chartY - (i.mouseDownY || 0)
          },
          trigger: "pan"
        }), aT(i.container, {
          cursor: "move"
        });
      });
    }
    transform(t) {
      let {
          axes: e = this.axes,
          event: i,
          from: s = {},
          reset: o,
          selection: r,
          to: a = {},
          trigger: n
        } = t,
        {
          inverted: l,
          time: h
        } = this,
        d = !1,
        c,
        p;
      for (let t of (this.hoverPoints?.forEach(t => t.setState()), e)) {
        let {
            horiz: e,
            len: u,
            minPointOffset: g = 0,
            options: m,
            reversed: f
          } = t,
          x = e ? "width" : "height",
          y = e ? "x" : "y",
          b = aF(a[x], t.len),
          v = aF(s[x], t.len),
          M = 10 > Math.abs(b) ? 1 : b / v,
          w = (s[y] || 0) + v / 2 - t.pos,
          k = w - ((a[y] ?? t.pos) + b / 2 - t.pos) / M,
          S = f && !l || !f && l ? -1 : 1;
        if (!o && (w < 0 || w > t.len)) continue;
        let T = t.toValue(k, !0) + (r || t.isOrdinal ? 0 : g * S),
          C = t.toValue(k + u / M, !0) - (r || t.isOrdinal ? 0 : g * S || 0),
          A = t.allExtremes;
        if (T > C && ([T, C] = [C, T]), 1 === M && !o && "yAxis" === t.coll && !A) {
          for (let e of t.series) {
            let t = e.getExtremes(e.getProcessedData(!0).modified.getColumn("y") || [], !0);
            A ?? (A = {
              dataMin: Number.MAX_VALUE,
              dataMax: -Number.MAX_VALUE
            }), aR(t.dataMin) && aR(t.dataMax) && (A.dataMin = Math.min(t.dataMin, A.dataMin), A.dataMax = Math.max(t.dataMax, A.dataMax));
          }
          t.allExtremes = A;
        }
        let {
            dataMin: P,
            dataMax: L,
            min: O,
            max: E
          } = aE(t.getExtremes(), A || {}),
          I = h.parse(m.min),
          D = h.parse(m.max),
          B = P ?? I,
          z = L ?? D,
          N = C - T,
          R = t.categories ? 0 : Math.min(N, z - B),
          W = B - R * (aC(I) ? 0 : m.minPadding),
          j = z + R * (aC(D) ? 0 : m.maxPadding),
          X = t.allowZoomOutside || 1 === M || "zoom" !== n && M > 1,
          G = Math.min(I ?? W, W, X ? O : W),
          F = Math.max(D ?? j, j, X ? E : j);
        (!t.isOrdinal || t.options.overscroll || 1 !== M || o) && (T < G && (T = G, M >= 1 && (C = T + N)), C > F && (C = F, M >= 1 && (T = C - N)), (o || t.series.length && (T !== O || C !== E) && T >= G && C <= F) && (r ? r[t.coll].push({
          axis: t,
          min: T,
          max: C
        }) : (t.isPanning = "zoom" !== n, t.isPanning && (p = !0), t.setExtremes(o ? void 0 : T, o ? void 0 : C, !1, !1, {
          move: k,
          trigger: n,
          scale: M
        }), !o && (T > G || C < F) && "mousewheel" !== n && (c = !0)), d = !0), i && (this[e ? "mouseDownX" : "mouseDownY"] = i[e ? "chartX" : "chartY"]));
      }
      return d && (r ? aD(this, "selection", r, () => {
        delete t.selection, t.trigger = "zoom", this.transform(t);
      }) : (!c || p || this.resetZoomButton ? !c && this.resetZoomButton && (this.resetZoomButton = this.resetZoomButton.destroy()) : this.showResetZoom(), this.redraw("zoom" === n && (this.options.chart.animation ?? this.pointCount < 100)))), d;
    }
  }
  aE(a_.prototype, {
    callbacks: [],
    collectionsWithInit: {
      xAxis: [a_.prototype.addAxis, [!0]],
      yAxis: [a_.prototype.addAxis, [!1]],
      series: [a_.prototype.addSeries]
    },
    collectionsWithUpdate: ["xAxis", "yAxis", "series"],
    propsRequireDirtyBox: ["backgroundColor", "borderColor", "borderWidth", "borderRadius", "plotBackgroundColor", "plotBackgroundImage", "plotBorderColor", "plotBorderWidth", "plotShadow", "shadow"],
    propsRequireReflow: ["margin", "marginTop", "marginRight", "marginBottom", "marginLeft", "spacing", "spacingTop", "spacingRight", "spacingBottom", "spacingLeft"],
    propsRequireUpdateSeries: ["chart.inverted", "chart.polar", "chart.ignoreHiddenSeries", "chart.type", "colors", "plotOptions", "time", "tooltip"]
  });
  let aq = a_,
    {
      stop: aK
    } = tH,
    {
      composed: aJ
    } = A,
    {
      addEvent: aQ,
      createElement: a0,
      css: a1,
      defined: a2,
      erase: a3,
      merge: a6,
      pushUnique: a5
    } = J;
  function a9() {
    let t = this.scrollablePlotArea;
    (this.scrollablePixelsX || this.scrollablePixelsY) && !t && (this.scrollablePlotArea = t = new a4(this)), t?.applyFixed();
  }
  function a8() {
    this.chart.scrollablePlotArea && (this.chart.scrollablePlotArea.isDirty = !0);
  }
  class a4 {
    static compose(t, e, i) {
      a5(aJ, this.compose) && (aQ(t, "afterInit", a8), aQ(e, "afterSetChartSize", t => this.afterSetSize(t.target, t)), aQ(e, "render", a9), aQ(i, "show", a8));
    }
    static afterSetSize(t, e) {
      let i, s, o;
      let {
          minWidth: r,
          minHeight: a
        } = t.options.chart.scrollablePlotArea || {},
        {
          clipBox: n,
          plotBox: l,
          inverted: h,
          renderer: d
        } = t;
      if (!d.forExport && (r ? (t.scrollablePixelsX = i = Math.max(0, r - t.chartWidth), i && (t.scrollablePlotBox = a6(t.plotBox), l.width = t.plotWidth += i, n[h ? "height" : "width"] += i, o = !0)) : a && (t.scrollablePixelsY = s = Math.max(0, a - t.chartHeight), a2(s) && (t.scrollablePlotBox = a6(t.plotBox), l.height = t.plotHeight += s, n[h ? "width" : "height"] += s, o = !1)), a2(o) && !e.skipAxes)) for (let e of t.axes) (e.horiz === o || t.hasParallelCoordinates && "yAxis" === e.coll) && (e.setAxisSize(), e.setAxisTranslation());
    }
    constructor(t) {
      let e;
      let i = t.options.chart,
        s = ec.getRendererType(),
        o = i.scrollablePlotArea || {},
        r = this.moveFixedElements.bind(this),
        a = {
          WebkitOverflowScrolling: "touch",
          overflowX: "hidden",
          overflowY: "hidden"
        };
      t.scrollablePixelsX && (a.overflowX = "auto"), t.scrollablePixelsY && (a.overflowY = "auto"), this.chart = t;
      let n = this.parentDiv = a0("div", {
          className: "highcharts-scrolling-parent"
        }, {
          position: "relative"
        }, t.renderTo),
        l = this.scrollingContainer = a0("div", {
          className: "highcharts-scrolling"
        }, a, n),
        h = this.innerContainer = a0("div", {
          className: "highcharts-inner-container"
        }, void 0, l),
        d = this.fixedDiv = a0("div", {
          className: "highcharts-fixed"
        }, {
          position: "absolute",
          overflow: "hidden",
          pointerEvents: "none",
          zIndex: (i.style?.zIndex || 0) + 2,
          top: 0
        }, void 0, !0),
        c = this.fixedRenderer = new s(d, t.chartWidth, t.chartHeight, i.style);
      this.mask = c.path().attr({
        fill: i.backgroundColor || "#fff",
        "fill-opacity": o.opacity ?? .85,
        zIndex: -1
      }).addClass("highcharts-scrollable-mask").add(), l.parentNode.insertBefore(d, l), a1(t.renderTo, {
        overflow: "visible"
      }), aQ(t, "afterShowResetZoom", r), aQ(t, "afterApplyDrilldown", r), aQ(t, "afterLayOutTitles", r), aQ(l, "scroll", () => {
        let {
          pointer: i,
          hoverPoint: s
        } = t;
        i && (delete i.chartPosition, s && (e = s), i.runPointActions(void 0, e, !0));
      }), h.appendChild(t.container);
    }
    applyFixed() {
      let {
          chart: t,
          fixedRenderer: e,
          isDirty: i,
          scrollingContainer: s
        } = this,
        {
          axisOffset: o,
          chartWidth: r,
          chartHeight: a,
          container: n,
          plotHeight: l,
          plotLeft: h,
          plotTop: d,
          plotWidth: c,
          scrollablePixelsX: p = 0,
          scrollablePixelsY: u = 0
        } = t,
        {
          scrollPositionX: g = 0,
          scrollPositionY: m = 0
        } = t.options.chart.scrollablePlotArea || {},
        f = r + p,
        x = a + u;
      e.setSize(r, a), (i ?? !0) && (this.isDirty = !1, this.moveFixedElements()), aK(t.container), a1(n, {
        width: `${f}px`,
        height: `${x}px`
      }), t.renderer.boxWrapper.attr({
        width: f,
        height: x,
        viewBox: [0, 0, f, x].join(" ")
      }), t.chartBackground?.attr({
        width: f,
        height: x
      }), a1(s, {
        width: `${r}px`,
        height: `${a}px`
      }), a2(i) || (s.scrollLeft = p * g, s.scrollTop = u * m);
      let y = d - o[0] - 1,
        b = h - o[3] - 1,
        v = d + l + o[2] + 1,
        M = h + c + o[1] + 1,
        w = h + c - p,
        k = d + l - u,
        S = [["M", 0, 0]];
      p ? S = [["M", 0, y], ["L", h - 1, y], ["L", h - 1, v], ["L", 0, v], ["Z"], ["M", w, y], ["L", r, y], ["L", r, v], ["L", w, v], ["Z"]] : u && (S = [["M", b, 0], ["L", b, d - 1], ["L", M, d - 1], ["L", M, 0], ["Z"], ["M", b, k], ["L", b, a], ["L", M, a], ["L", M, k], ["Z"]]), "adjustHeight" !== t.redrawTrigger && this.mask.attr({
        d: S
      });
    }
    moveFixedElements() {
      let t;
      let {
          container: e,
          inverted: i,
          scrollablePixelsX: s,
          scrollablePixelsY: o
        } = this.chart,
        r = this.fixedRenderer,
        a = a4.fixedSelectors;
      if (s && !i ? t = ".highcharts-yaxis" : s && i ? t = ".highcharts-xaxis" : o && !i ? t = ".highcharts-xaxis" : o && i && (t = ".highcharts-yaxis"), t && !(this.chart.hasParallelCoordinates && ".highcharts-yaxis" === t)) for (let e of [`${t}:not(.highcharts-radial-axis)`, `${t}-labels:not(.highcharts-radial-axis-labels)`]) a5(a, e);else for (let t of [".highcharts-xaxis", ".highcharts-yaxis"]) for (let e of [`${t}:not(.highcharts-radial-axis)`, `${t}-labels:not(.highcharts-radial-axis-labels)`]) a3(a, e);
      for (let t of a) [].forEach.call(e.querySelectorAll(t), t => {
        (t.namespaceURI === r.SVG_NS ? r.box : r.box.parentNode).appendChild(t), t.style.pointerEvents = "auto";
      });
    }
  }
  a4.fixedSelectors = [".highcharts-breadcrumbs-group", ".highcharts-contextbutton", ".highcharts-caption", ".highcharts-credits", ".highcharts-drillup-button", ".highcharts-legend", ".highcharts-legend-checkbox", ".highcharts-navigator-series", ".highcharts-navigator-xaxis", ".highcharts-navigator-yaxis", ".highcharts-navigator", ".highcharts-range-selector-group", ".highcharts-reset-zoom", ".highcharts-scrollbar", ".highcharts-subtitle", ".highcharts-title"];
  let {
      format: a7
    } = ed,
    {
      series: nt
    } = rx,
    {
      destroyObjectProperties: ne,
      fireEvent: ni,
      getAlignFactor: ns,
      isNumber: no,
      pick: nr
    } = J,
    na = class {
      constructor(t, e, i, s, o) {
        let r = t.chart.inverted,
          a = t.reversed;
        this.axis = t;
        let n = this.isNegative = !!i != !!a;
        this.options = e = e || {}, this.x = s, this.total = null, this.cumulative = null, this.points = {}, this.hasValidPoints = !1, this.stack = o, this.leftCliff = 0, this.rightCliff = 0, this.alignOptions = {
          align: e.align || (r ? n ? "left" : "right" : "center"),
          verticalAlign: e.verticalAlign || (r ? "middle" : n ? "bottom" : "top"),
          y: e.y,
          x: e.x
        }, this.textAlign = e.textAlign || (r ? n ? "right" : "left" : "center");
      }
      destroy() {
        ne(this, this.axis);
      }
      render(t) {
        let e = this.axis.chart,
          i = this.options,
          s = i.format,
          o = s ? a7(s, this, e) : i.formatter.call(this);
        if (this.label) this.label.attr({
          text: o,
          visibility: "hidden"
        });else {
          this.label = e.renderer.label(o, null, void 0, i.shape, void 0, void 0, i.useHTML, !1, "stack-labels");
          let s = {
            r: i.borderRadius || 0,
            text: o,
            padding: nr(i.padding, 5),
            visibility: "hidden"
          };
          e.styledMode || (s.fill = i.backgroundColor, s.stroke = i.borderColor, s["stroke-width"] = i.borderWidth, this.label.css(i.style || {})), this.label.attr(s), this.label.added || this.label.add(t);
        }
        this.label.labelrank = e.plotSizeY, ni(this, "afterRender");
      }
      setOffset(t, e, i, s, o, r) {
        let {
            alignOptions: a,
            axis: n,
            label: l,
            options: h,
            textAlign: d
          } = this,
          c = n.chart,
          p = this.getStackBox({
            xOffset: t,
            width: e,
            boxBottom: i,
            boxTop: s,
            defaultX: o,
            xAxis: r
          }),
          {
            verticalAlign: u
          } = a;
        if (l && p) {
          let t = l.getBBox(void 0, 0),
            e = l.padding,
            i = "justify" === nr(h.overflow, "justify"),
            s;
          a.x = h.x || 0, a.y = h.y || 0;
          let {
            x: o,
            y: r
          } = this.adjustStackPosition({
            labelBox: t,
            verticalAlign: u,
            textAlign: d
          });
          p.x -= o, p.y -= r, l.align(a, !1, p), (s = c.isInsidePlot(l.alignAttr.x + a.x + o, l.alignAttr.y + a.y + r)) || (i = !1), i && nt.prototype.justifyDataLabel.call(n, l, a, l.alignAttr, t, p), l.attr({
            x: l.alignAttr.x,
            y: l.alignAttr.y,
            rotation: h.rotation,
            rotationOriginX: t.width * ns(h.textAlign || "center"),
            rotationOriginY: t.height / 2
          }), nr(!i && h.crop, !0) && (s = no(l.x) && no(l.y) && c.isInsidePlot(l.x - e + (l.width || 0), l.y) && c.isInsidePlot(l.x + e, l.y)), l[s ? "show" : "hide"]();
        }
        ni(this, "afterSetOffset", {
          xOffset: t,
          width: e
        });
      }
      adjustStackPosition({
        labelBox: t,
        verticalAlign: e,
        textAlign: i
      }) {
        return {
          x: t.width / 2 + t.width / 2 * (2 * ns(i) - 1),
          y: t.height / 2 * 2 * (1 - ns(e))
        };
      }
      getStackBox(t) {
        let e = this.axis,
          i = e.chart,
          {
            boxTop: s,
            defaultX: o,
            xOffset: r,
            width: a,
            boxBottom: n
          } = t,
          l = e.stacking.usePercentage ? 100 : nr(s, this.total, 0),
          h = e.toPixels(l),
          d = t.xAxis || i.xAxis[0],
          c = nr(o, d.translate(this.x)) + r,
          p = Math.abs(h - e.toPixels(n || no(e.min) && e.logarithmic && e.logarithmic.lin2log(e.min) || 0)),
          u = i.inverted,
          g = this.isNegative;
        return u ? {
          x: (g ? h : h - p) - i.plotLeft,
          y: d.height - c - a + d.top - i.plotTop,
          width: p,
          height: a
        } : {
          x: c + d.transB - i.plotLeft,
          y: (g ? h - p : h) - i.plotTop,
          width: a,
          height: p
        };
      }
    },
    {
      getDeferredAnimation: nn
    } = tH,
    {
      series: {
        prototype: nl
      }
    } = rx,
    {
      addEvent: nh,
      correctFloat: nd,
      defined: nc,
      destroyObjectProperties: np,
      fireEvent: nu,
      isNumber: ng,
      objectEach: nm,
      pick: nf
    } = J;
  function nx() {
    let t = this.inverted;
    this.axes.forEach(t => {
      t.stacking && t.stacking.stacks && t.hasVisibleSeries && (t.stacking.oldStacks = t.stacking.stacks);
    }), this.series.forEach(e => {
      let i = e.xAxis && e.xAxis.options || {};
      e.options.stacking && e.reserveSpace() && (e.stackKey = [e.type, nf(e.options.stack, ""), t ? i.top : i.left, t ? i.height : i.width].join(","));
    });
  }
  function ny() {
    let t = this.stacking;
    if (t) {
      let e = t.stacks;
      nm(e, (t, i) => {
        np(t), delete e[i];
      }), t.stackTotalGroup?.destroy();
    }
  }
  function nb() {
    this.stacking || (this.stacking = new nT(this));
  }
  function nv(t, e, i, s) {
    return !nc(t) || t.x !== e || s && t.stackKey !== s ? t = {
      x: e,
      index: 0,
      key: s,
      stackKey: s
    } : t.index++, t.key = [i, e, t.index].join(","), t;
  }
  function nM() {
    let t;
    let e = this,
      i = e.yAxis,
      s = e.stackKey || "",
      o = i.stacking.stacks,
      r = e.getColumn("x", !0),
      a = e.options.stacking,
      n = e[a + "Stacker"];
    n && [s, "-" + s].forEach(i => {
      let s = r.length,
        a,
        l,
        h;
      for (; s--;) a = r[s], t = e.getStackIndicator(t, a, e.index, i), l = o[i]?.[a], (h = l?.points[t.key || ""]) && n.call(e, h, l, s);
    });
  }
  function nw(t, e, i) {
    let s = e.total ? 100 / e.total : 0;
    t[0] = nd(t[0] * s), t[1] = nd(t[1] * s), this.stackedYData[i] = t[1];
  }
  function nk(t) {
    (this.is("column") || this.is("columnrange")) && (this.options.centerInCategory && this.chart.series.length > 1 ? nl.setStackedPoints.call(this, t, "group") : t.stacking.resetStacks());
  }
  function nS(t, e) {
    let i, s, o, r, a, n, l;
    let h = e || this.options.stacking;
    if (!h || !this.reserveSpace() || ({
      group: "xAxis"
    }[h] || "yAxis") !== t.coll) return;
    let d = this.getColumn("x", !0),
      c = this.getColumn(this.pointValKey || "y", !0),
      p = [],
      u = c.length,
      g = this.options,
      m = g.threshold || 0,
      f = g.startFromThreshold ? m : 0,
      x = g.stack,
      y = e ? `${this.type},${h}` : this.stackKey || "",
      b = "-" + y,
      v = this.negStacks,
      M = t.stacking,
      w = M.stacks,
      k = M.oldStacks;
    for (M.stacksTouched += 1, l = 0; l < u; l++) {
      let e = d[l] || 0,
        u = c[l],
        g = ng(u) && u || 0;
      n = (i = this.getStackIndicator(i, e, this.index)).key || "", w[a = (s = v && g < (f ? 0 : m)) ? b : y] || (w[a] = {}), w[a][e] || (k[a]?.[e] ? (w[a][e] = k[a][e], w[a][e].total = null) : w[a][e] = new na(t, t.options.stackLabels, !!s, e, x)), o = w[a][e], null !== u ? (o.points[n] = o.points[this.index] = [nf(o.cumulative, f)], nc(o.cumulative) || (o.base = n), o.touched = M.stacksTouched, i.index > 0 && !1 === this.singleStacks && (o.points[n][0] = o.points[this.index + "," + e + ",0"][0])) : (delete o.points[n], delete o.points[this.index]);
      let S = o.total || 0;
      "percent" === h ? (r = s ? y : b, S = v && w[r]?.[e] ? (r = w[r][e]).total = Math.max(r.total || 0, S) + Math.abs(g) : nd(S + Math.abs(g))) : "group" === h ? ng(u) && S++ : S = nd(S + g), "group" === h ? o.cumulative = (S || 1) - 1 : o.cumulative = nd(nf(o.cumulative, f) + g), o.total = S, null !== u && (o.points[n].push(o.cumulative), p[l] = o.cumulative, o.hasValidPoints = !0);
    }
    "percent" === h && (M.usePercentage = !0), "group" !== h && (this.stackedYData = p), M.oldStacks = {};
  }
  class nT {
    constructor(t) {
      this.oldStacks = {}, this.stacks = {}, this.stacksTouched = 0, this.axis = t;
    }
    buildStacks() {
      let t, e;
      let i = this.axis,
        s = i.series,
        o = "xAxis" === i.coll,
        r = i.options.reversedStacks,
        a = s.length;
      for (this.resetStacks(), this.usePercentage = !1, e = a; e--;) t = s[r ? e : a - e - 1], o && t.setGroupedPoints(i), t.setStackedPoints(i);
      if (!o) for (e = 0; e < a; e++) s[e].modifyStacks();
      nu(i, "afterBuildStacks");
    }
    cleanStacks() {
      this.oldStacks && (this.stacks = this.oldStacks, nm(this.stacks, t => {
        nm(t, t => {
          t.cumulative = t.total;
        });
      }));
    }
    resetStacks() {
      nm(this.stacks, t => {
        nm(t, (e, i) => {
          ng(e.touched) && e.touched < this.stacksTouched ? (e.destroy(), delete t[i]) : (e.total = null, e.cumulative = null);
        });
      });
    }
    renderStackTotals() {
      let t = this.axis,
        e = t.chart,
        i = e.renderer,
        s = this.stacks,
        o = nn(e, t.options.stackLabels?.animation || !1),
        r = this.stackTotalGroup = this.stackTotalGroup || i.g("stack-labels").attr({
          zIndex: 6,
          opacity: 0
        }).add();
      r.translate(e.plotLeft, e.plotTop), nm(s, t => {
        nm(t, t => {
          t.render(r);
        });
      }), r.animate({
        opacity: 1
      }, o);
    }
  }
  (u || (u = {})).compose = function (t, e, i) {
    let s = e.prototype,
      o = i.prototype;
    s.getStacks || (nh(t, "init", nb), nh(t, "destroy", ny), s.getStacks = nx, o.getStackIndicator = nv, o.modifyStacks = nM, o.percentStacker = nw, o.setGroupedPoints = nk, o.setStackedPoints = nS);
  };
  let nC = u,
    {
      defined: nA,
      merge: nP,
      isObject: nL
    } = J;
  class nO extends rq {
    drawGraph() {
      let t = this.options,
        e = (this.gappedPath || this.getGraphPath).call(this),
        i = this.chart.styledMode;
      [this, ...this.zones].forEach((s, o) => {
        let r,
          a = s.graph,
          n = a ? "animate" : "attr",
          l = s.dashStyle || t.dashStyle;
        a ? (a.endX = this.preventGraphAnimation ? null : e.xMap, a.animate({
          d: e
        })) : e.length && (s.graph = a = this.chart.renderer.path(e).addClass("highcharts-graph" + (o ? ` highcharts-zone-graph-${o - 1} ` : " ") + (o && s.className || "")).attr({
          zIndex: 1
        }).add(this.group)), a && !i && (r = {
          stroke: !o && t.lineColor || s.color || this.color || "#cccccc",
          "stroke-width": t.lineWidth || 0,
          fill: this.fillGraph && this.color || "none"
        }, l ? r.dashstyle = l : "square" !== t.linecap && (r["stroke-linecap"] = r["stroke-linejoin"] = "round"), a[n](r).shadow(t.shadow && nP({
          filterUnits: "userSpaceOnUse"
        }, nL(t.shadow) ? t.shadow : {}))), a && (a.startX = e.xMap, a.isArea = e.isArea);
      });
    }
    getGraphPath(t, e, i) {
      let s = this,
        o = s.options,
        r = [],
        a = [],
        n,
        l = o.step,
        h = (t = t || s.points).reversed;
      return h && t.reverse(), (l = {
        right: 1,
        center: 2
      }[l] || l && 3) && h && (l = 4 - l), (t = this.getValidPoints(t, !1, !(o.connectNulls && !e && !i))).forEach(function (h, d) {
        let c;
        let p = h.plotX,
          u = h.plotY,
          g = t[d - 1],
          m = h.isNull || "number" != typeof u;
        (h.leftCliff || g && g.rightCliff) && !i && (n = !0), m && !nA(e) && d > 0 ? n = !o.connectNulls : m && !e ? n = !0 : (0 === d || n ? c = [["M", h.plotX, h.plotY]] : s.getPointSpline ? c = [s.getPointSpline(t, h, d)] : l ? (c = 1 === l ? [["L", g.plotX, u]] : 2 === l ? [["L", (g.plotX + p) / 2, g.plotY], ["L", (g.plotX + p) / 2, u]] : [["L", p, g.plotY]]).push(["L", p, u]) : c = [["L", p, u]], a.push(h.x), l && (a.push(h.x), 2 === l && a.push(h.x)), r.push.apply(r, c), n = !1);
      }), r.xMap = a, s.graphPath = r, r;
    }
  }
  nO.defaultOptions = nP(rq.defaultOptions, {
    legendSymbol: "lineMarker"
  }), rx.registerSeriesType("line", nO);
  let {
      seriesTypes: {
        line: nE
      }
    } = rx,
    {
      extend: nI,
      merge: nD,
      objectEach: nB,
      pick: nz
    } = J;
  class nN extends nE {
    drawGraph() {
      this.areaPath = [], super.drawGraph.apply(this);
      let {
        areaPath: t,
        options: e
      } = this;
      [this, ...this.zones].forEach((i, s) => {
        let o = {},
          r = i.fillColor || e.fillColor,
          a = i.area,
          n = a ? "animate" : "attr";
        a ? (a.endX = this.preventGraphAnimation ? null : t.xMap, a.animate({
          d: t
        })) : (o.zIndex = 0, (a = i.area = this.chart.renderer.path(t).addClass("highcharts-area" + (s ? ` highcharts-zone-area-${s - 1} ` : " ") + (s && i.className || "")).add(this.group)).isArea = !0), this.chart.styledMode || (o.fill = r || i.color || this.color, o["fill-opacity"] = r ? 1 : e.fillOpacity ?? .75, a.css({
          pointerEvents: this.stickyTracking ? "none" : "auto"
        })), a[n](o), a.startX = t.xMap, a.shiftUnit = e.step ? 2 : 1;
      });
    }
    getGraphPath(t) {
      let e, i, s;
      let o = nE.prototype.getGraphPath,
        r = this.options,
        a = r.stacking,
        n = this.yAxis,
        l = [],
        h = [],
        d = this.index,
        c = n.stacking.stacks[this.stackKey],
        p = r.threshold,
        u = Math.round(n.getThreshold(r.threshold)),
        g = nz(r.connectNulls, "percent" === a),
        m = function (i, s, o) {
          let r = t[i],
            g = a && c[r.x].points[d],
            m = r[o + "Null"] || 0,
            f = r[o + "Cliff"] || 0,
            x,
            y,
            b = !0;
          f || m ? (x = (m ? g[0] : g[1]) + f, y = g[0] + f, b = !!m) : !a && t[s] && t[s].isNull && (x = y = p), void 0 !== x && (h.push({
            plotX: e,
            plotY: null === x ? u : n.getThreshold(x),
            isNull: b,
            isCliff: !0
          }), l.push({
            plotX: e,
            plotY: null === y ? u : n.getThreshold(y),
            doCurve: !1
          }));
        };
      t = t || this.points, a && (t = this.getStackPoints(t));
      for (let o = 0, r = t.length; o < r; ++o) a || (t[o].leftCliff = t[o].rightCliff = t[o].leftNull = t[o].rightNull = void 0), i = t[o].isNull, e = nz(t[o].rectPlotX, t[o].plotX), s = a ? nz(t[o].yBottom, u) : u, i && !g || (g || m(o, o - 1, "left"), i && !a && g || (h.push(t[o]), l.push({
        x: o,
        plotX: e,
        plotY: s
      })), g || m(o, o + 1, "right"));
      let f = o.call(this, h, !0, !0);
      l.reversed = !0;
      let x = o.call(this, l, !0, !0),
        y = x[0];
      y && "M" === y[0] && (x[0] = ["L", y[1], y[2]]);
      let b = f.concat(x);
      b.length && b.push(["Z"]);
      let v = o.call(this, h, !1, g);
      return this.chart.series.length > 1 && a && h.some(t => t.isCliff) && (b.hasStackedCliffs = v.hasStackedCliffs = !0), b.xMap = f.xMap, this.areaPath = b, v;
    }
    getStackPoints(t) {
      let e = this,
        i = [],
        s = [],
        o = this.xAxis,
        r = this.yAxis,
        a = r.stacking.stacks[this.stackKey],
        n = {},
        l = r.series,
        h = l.length,
        d = r.options.reversedStacks ? 1 : -1,
        c = l.indexOf(e);
      if (t = t || this.points, this.options.stacking) {
        for (let e = 0; e < t.length; e++) t[e].leftNull = t[e].rightNull = void 0, n[t[e].x] = t[e];
        nB(a, function (t, e) {
          null !== t.total && s.push(e);
        }), s.sort(function (t, e) {
          return t - e;
        });
        let p = l.map(t => t.visible);
        s.forEach(function (t, u) {
          let g = 0,
            m,
            f;
          if (n[t] && !n[t].isNull) i.push(n[t]), [-1, 1].forEach(function (i) {
            let o = 1 === i ? "rightNull" : "leftNull",
              r = a[s[u + i]],
              g = 0;
            if (r) {
              let i = c;
              for (; i >= 0 && i < h;) {
                let s = l[i].index;
                !(m = r.points[s]) && (s === e.index ? n[t][o] = !0 : p[i] && (f = a[t].points[s]) && (g -= f[1] - f[0])), i += d;
              }
            }
            n[t][1 === i ? "rightCliff" : "leftCliff"] = g;
          });else {
            let e = c;
            for (; e >= 0 && e < h;) {
              let i = l[e].index;
              if (m = a[t].points[i]) {
                g = m[1];
                break;
              }
              e += d;
            }
            g = nz(g, 0), g = r.translate(g, 0, 1, 0, 1), i.push({
              isNull: !0,
              plotX: o.translate(t, 0, 0, 0, 1),
              x: t,
              plotY: g,
              yBottom: g
            });
          }
        });
      }
      return i;
    }
  }
  nN.defaultOptions = nD(nE.defaultOptions, {
    threshold: 0,
    legendSymbol: "areaMarker"
  }), nI(nN.prototype, {
    singleStacks: !1
  }), rx.registerSeriesType("area", nN);
  let {
      line: nR
    } = rx.seriesTypes,
    {
      merge: nW,
      pick: nj
    } = J;
  class nX extends nR {
    getPointSpline(t, e, i) {
      let s, o, r, a;
      let n = e.plotX || 0,
        l = e.plotY || 0,
        h = t[i - 1],
        d = t[i + 1];
      function c(t) {
        return t && !t.isNull && !1 !== t.doCurve && !e.isCliff;
      }
      if (c(h) && c(d)) {
        let t = h.plotX || 0,
          i = h.plotY || 0,
          c = d.plotX || 0,
          p = d.plotY || 0,
          u = 0;
        s = (1.5 * n + t) / 2.5, o = (1.5 * l + i) / 2.5, r = (1.5 * n + c) / 2.5, a = (1.5 * l + p) / 2.5, r !== s && (u = (a - o) * (r - n) / (r - s) + l - a), o += u, a += u, o > i && o > l ? (o = Math.max(i, l), a = 2 * l - o) : o < i && o < l && (o = Math.min(i, l), a = 2 * l - o), a > p && a > l ? (a = Math.max(p, l), o = 2 * l - a) : a < p && a < l && (a = Math.min(p, l), o = 2 * l - a), e.rightContX = r, e.rightContY = a, e.controlPoints = {
          low: [s, o],
          high: [r, a]
        };
      }
      let p = ["C", nj(h.rightContX, h.plotX, 0), nj(h.rightContY, h.plotY, 0), nj(s, n, 0), nj(o, l, 0), n, l];
      return h.rightContX = h.rightContY = void 0, p;
    }
  }
  nX.defaultOptions = nW(nR.defaultOptions), rx.registerSeriesType("spline", nX);
  let nG = nX,
    {
      area: nF,
      area: {
        prototype: nH
      }
    } = rx.seriesTypes,
    {
      extend: nY,
      merge: nV
    } = J;
  class nU extends nG {}
  nU.defaultOptions = nV(nG.defaultOptions, nF.defaultOptions), nY(nU.prototype, {
    getGraphPath: nH.getGraphPath,
    getStackPoints: nH.getStackPoints,
    drawGraph: nH.drawGraph
  }), rx.registerSeriesType("areaspline", nU);
  let {
      animObject: nZ
    } = tH,
    {
      parse: n$
    } = tA,
    {
      noop: n_
    } = A,
    {
      clamp: nq,
      crisp: nK,
      defined: nJ,
      extend: nQ,
      fireEvent: n0,
      isArray: n1,
      isNumber: n2,
      merge: n3,
      pick: n6,
      objectEach: n5
    } = J;
  class n9 extends rq {
    animate(t) {
      let e, i;
      let s = this,
        o = this.yAxis,
        r = o.pos,
        a = o.reversed,
        n = s.options,
        {
          clipOffset: l,
          inverted: h
        } = this.chart,
        d = {},
        c = h ? "translateX" : "translateY";
      t && l ? (d.scaleY = .001, i = nq(o.toPixels(n.threshold || 0), r, r + o.len), h ? (i += a ? -Math.floor(l[0]) : Math.ceil(l[2]), d.translateX = i - o.len) : (i += a ? Math.ceil(l[0]) : -Math.floor(l[2]), d.translateY = i), s.clipBox && s.setClip(), s.group.attr(d)) : (e = Number(s.group.attr(c)), s.group.animate({
        scaleY: 1
      }, nQ(nZ(s.options.animation), {
        step: function (t, i) {
          s.group && (d[c] = e + i.pos * (r - e), s.group.attr(d));
        }
      })));
    }
    init(t, e) {
      super.init.apply(this, arguments);
      let i = this;
      (t = i.chart).hasRendered && t.series.forEach(function (t) {
        t.type === i.type && (t.isDirty = !0);
      });
    }
    getColumnMetrics() {
      let t = this,
        e = t.options,
        i = t.xAxis,
        s = t.yAxis,
        o = i.options.reversedStacks,
        r = i.reversed && !o || !i.reversed && o,
        a = {},
        n,
        l = 0;
      !1 === e.grouping ? l = 1 : t.chart.series.forEach(function (e) {
        let i;
        let o = e.yAxis,
          r = e.options;
        e.type === t.type && e.reserveSpace() && s.len === o.len && s.pos === o.pos && (r.stacking && "group" !== r.stacking ? (void 0 === a[n = e.stackKey] && (a[n] = l++), i = a[n]) : !1 !== r.grouping && (i = l++), e.columnIndex = i);
      });
      let h = Math.min(Math.abs(i.transA) * (!i.brokenAxis?.hasBreaks && i.ordinal?.slope || e.pointRange || i.closestPointRange || i.tickInterval || 1), i.len),
        d = h * e.groupPadding,
        c = (h - 2 * d) / (l || 1),
        p = Math.min(e.maxPointWidth || i.len, n6(e.pointWidth, c * (1 - 2 * e.pointPadding))),
        u = (t.columnIndex || 0) + (r ? 1 : 0);
      return t.columnMetrics = {
        width: p,
        offset: (c - p) / 2 + (d + u * c - h / 2) * (r ? -1 : 1),
        paddedWidth: c,
        columnCount: l
      }, t.columnMetrics;
    }
    crispCol(t, e, i, s) {
      let o = this.borderWidth,
        r = this.chart.inverted;
      return s = nK(e + s, o, r) - (e = nK(e, o, r)), this.options.crisp && (i = nK(t + i, o) - (t = nK(t, o))), {
        x: t,
        y: e,
        width: i,
        height: s
      };
    }
    adjustForMissingColumns(t, e, i, s) {
      if (!i.isNull && s.columnCount > 1) {
        let o = this.xAxis.series.filter(t => t.visible).map(t => t.index),
          r = 0,
          a = 0;
        n5(this.xAxis.stacking?.stacks, t => {
          let e = "number" == typeof i.x ? t[i.x.toString()]?.points : void 0,
            s = e?.[this.index],
            n = {};
          if (e && n1(s)) {
            let t = this.index,
              i = Object.keys(e).filter(t => !t.match(",") && e[t] && e[t].length > 1).map(parseFloat).filter(t => -1 !== o.indexOf(t)).filter(e => {
                let i = this.chart.series[e].options,
                  s = i.stacking && i.stack;
                if (nJ(s)) {
                  if (n2(n[s])) return t === e && (t = n[s]), !1;
                  n[s] = e;
                }
                return !0;
              }).sort((t, e) => e - t);
            r = i.indexOf(t), a = i.length;
          }
        }), r = this.xAxis.reversed ? a - 1 - r : r;
        let n = (a - 1) * s.paddedWidth + e;
        t = (i.plotX || 0) + n / 2 - e - r * s.paddedWidth;
      }
      return t;
    }
    translate() {
      let t = this,
        e = t.chart,
        i = t.options,
        s = t.dense = t.closestPointRange * t.xAxis.transA < 2,
        o = t.borderWidth = n6(i.borderWidth, s ? 0 : 1),
        r = t.xAxis,
        a = t.yAxis,
        n = i.threshold,
        l = n6(i.minPointLength, 5),
        h = t.getColumnMetrics(),
        d = h.width,
        c = t.pointXOffset = h.offset,
        p = t.dataMin,
        u = t.dataMax,
        g = t.translatedThreshold = a.getThreshold(n),
        m = t.barW = Math.max(d, 1 + 2 * o);
      i.pointPadding && i.crisp && (m = Math.ceil(m)), rq.prototype.translate.apply(t), t.points.forEach(function (s) {
        let o = n6(s.yBottom, g),
          f = 999 + Math.abs(o),
          x = s.plotX || 0,
          y = nq(s.plotY, -f, a.len + f),
          b,
          v = Math.min(y, o),
          M = Math.max(y, o) - v,
          w = d,
          k = x + c,
          S = m;
        l && Math.abs(M) < l && (M = l, b = !a.reversed && !s.negative || a.reversed && s.negative, n2(n) && n2(u) && s.y === n && u <= n && (a.min || 0) < n && (p !== u || (a.max || 0) <= n) && (b = !b, s.negative = !s.negative), v = Math.abs(v - g) > l ? o - l : g - (b ? l : 0)), nJ(s.options.pointWidth) && (k -= Math.round(((w = S = Math.ceil(s.options.pointWidth)) - d) / 2)), i.centerInCategory && (k = t.adjustForMissingColumns(k, w, s, h)), s.barX = k, s.pointWidth = w, s.tooltipPos = e.inverted ? [nq(a.len + a.pos - e.plotLeft - y, a.pos - e.plotLeft, a.len + a.pos - e.plotLeft), r.len + r.pos - e.plotTop - k - S / 2, M] : [r.left - e.plotLeft + k + S / 2, nq(y + a.pos - e.plotTop, a.pos - e.plotTop, a.len + a.pos - e.plotTop), M], s.shapeType = t.pointClass.prototype.shapeType || "roundedRect", s.shapeArgs = t.crispCol(k, s.isNull ? g : v, S, s.isNull ? 0 : M);
      }), n0(this, "afterColumnTranslate");
    }
    drawGraph() {
      this.group[this.dense ? "addClass" : "removeClass"]("highcharts-dense-data");
    }
    pointAttribs(t, e) {
      let i = this.options,
        s = this.pointAttrToOptions || {},
        o = s.stroke || "borderColor",
        r = s["stroke-width"] || "borderWidth",
        a,
        n,
        l,
        h = t && t.color || this.color,
        d = t && t[o] || i[o] || h,
        c = t && t.options.dashStyle || i.dashStyle,
        p = t && t[r] || i[r] || this[r] || 0,
        u = n6(t && t.opacity, i.opacity, 1);
      t && this.zones.length && (n = t.getZone(), h = t.options.color || n && (n.color || t.nonZonedColor) || this.color, n && (d = n.borderColor || d, c = n.dashStyle || c, p = n.borderWidth || p)), e && t && (l = (a = n3(i.states[e], t.options.states && t.options.states[e] || {})).brightness, h = a.color || void 0 !== l && n$(h).brighten(a.brightness).get() || h, d = a[o] || d, p = a[r] || p, c = a.dashStyle || c, u = n6(a.opacity, u));
      let g = {
        fill: h,
        stroke: d,
        "stroke-width": p,
        opacity: u
      };
      return c && (g.dashstyle = c), g;
    }
    drawPoints(t = this.points) {
      let e;
      let i = this,
        s = this.chart,
        o = i.options,
        r = s.renderer,
        a = o.animationLimit || 250;
      t.forEach(function (t) {
        let n = t.plotY,
          l = t.graphic,
          h = !!l,
          d = l && s.pointCount < a ? "animate" : "attr";
        n2(n) && null !== t.y ? (e = t.shapeArgs, l && t.hasNewShapeType() && (l = l.destroy()), i.enabledDataSorting && (t.startXPos = i.xAxis.reversed ? -(e && e.width || 0) : i.xAxis.width), !l && (t.graphic = l = r[t.shapeType](e).add(t.group || i.group), l && i.enabledDataSorting && s.hasRendered && s.pointCount < a && (l.attr({
          x: t.startXPos
        }), h = !0, d = "animate")), l && h && l[d](n3(e)), s.styledMode || l[d](i.pointAttribs(t, t.selected && "select")).shadow(!1 !== t.allowShadow && o.shadow), l && (l.addClass(t.getClassName(), !0), l.attr({
          visibility: t.visible ? "inherit" : "hidden"
        }))) : l && (t.graphic = l.destroy());
      });
    }
    drawTracker(t = this.points) {
      let e;
      let i = this,
        s = i.chart,
        o = s.pointer,
        r = function (t) {
          o?.normalize(t);
          let e = o?.getPointFromEvent(t),
            r = !s.scrollablePlotArea || s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop, {
              visiblePlotOnly: !0
            });
          o && e && i.options.enableMouseTracking && r && (o.isDirectTouch = !0, e.onMouseOver(t));
        };
      t.forEach(function (t) {
        e = n1(t.dataLabels) ? t.dataLabels : t.dataLabel ? [t.dataLabel] : [], t.graphic && (t.graphic.element.point = t), e.forEach(function (e) {
          (e.div || e.element).point = t;
        });
      }), i._hasTracking || (i.trackerGroups.forEach(function (t) {
        i[t] && (i[t].addClass("highcharts-tracker").on("mouseover", r).on("mouseout", function (t) {
          o?.onTrackerMouseOut(t);
        }).on("touchstart", r), !s.styledMode && i.options.cursor && i[t].css({
          cursor: i.options.cursor
        }));
      }), i._hasTracking = !0), n0(this, "afterDrawTracker");
    }
    remove() {
      let t = this,
        e = t.chart;
      e.hasRendered && e.series.forEach(function (e) {
        e.type === t.type && (e.isDirty = !0);
      }), rq.prototype.remove.apply(t, arguments);
    }
  }
  n9.defaultOptions = n3(rq.defaultOptions, {
    borderRadius: 3,
    centerInCategory: !1,
    groupPadding: .2,
    marker: null,
    pointPadding: .1,
    minPointLength: 0,
    cropThreshold: 50,
    pointRange: null,
    states: {
      hover: {
        halo: !1,
        brightness: .1
      },
      select: {
        color: "#cccccc",
        borderColor: "#000000"
      }
    },
    dataLabels: {
      align: void 0,
      verticalAlign: void 0,
      y: void 0
    },
    startFromThreshold: !0,
    stickyTracking: !1,
    tooltip: {
      distance: 6
    },
    threshold: 0,
    borderColor: "#ffffff"
  }), nQ(n9.prototype, {
    directTouch: !0,
    getSymbol: n_,
    negStacks: !0,
    trackerGroups: ["group", "dataLabelsGroup"]
  }), rx.registerSeriesType("column", n9);
  let n8 = n9,
    {
      getDeferredAnimation: n4
    } = tH,
    {
      format: n7
    } = ed,
    {
      defined: lt,
      extend: le,
      fireEvent: li,
      getAlignFactor: ls,
      isArray: lo,
      isString: lr,
      merge: la,
      objectEach: ln,
      pick: ll,
      pInt: lh,
      splat: ld
    } = J;
  !function (t) {
    function e() {
      return l(this).some(t => t?.enabled);
    }
    function i(t, e, i, s, o) {
      let {
          chart: r,
          enabledDataSorting: a
        } = this,
        n = this.isCartesian && r.inverted,
        l = t.plotX,
        h = t.plotY,
        d = i.rotation || 0,
        c = lt(l) && lt(h) && r.isInsidePlot(l, Math.round(h), {
          inverted: n,
          paneCoordinates: !0,
          series: this
        }),
        p = 0 === d && "justify" === ll(i.overflow, a ? "none" : "justify"),
        u = this.visible && !1 !== t.visible && lt(l) && (t.series.forceDL || a && !p || c || ll(i.inside, !!this.options.stacking) && s && r.isInsidePlot(l, n ? s.x + 1 : s.y + s.height - 1, {
          inverted: n,
          paneCoordinates: !0,
          series: this
        })),
        g = t.pos();
      if (u && g) {
        var m;
        let l = e.getBBox(),
          h = e.getBBox(void 0, 0);
        if (s = le({
          x: g[0],
          y: Math.round(g[1]),
          width: 0,
          height: 0
        }, s || {}), "plotEdges" === i.alignTo && this.isCartesian && (s[n ? "x" : "y"] = 0, s[n ? "width" : "height"] = this.yAxis?.len || 0), le(i, {
          width: l.width,
          height: l.height
        }), m = s, a && this.xAxis && !p && this.setDataLabelStartPos(t, e, o, c, m), e.align(la(i, {
          width: h.width,
          height: h.height
        }), !1, s, !1), e.alignAttr.x += ls(i.align) * (h.width - l.width), e.alignAttr.y += ls(i.verticalAlign) * (h.height - l.height), e[e.placed ? "animate" : "attr"]({
          "text-align": e.alignAttr["text-align"] || "center",
          x: e.alignAttr.x + (l.width - h.width) / 2,
          y: e.alignAttr.y + (l.height - h.height) / 2,
          rotationOriginX: (e.width || 0) / 2,
          rotationOriginY: (e.height || 0) / 2
        }), p && s.height >= 0) this.justifyDataLabel(e, i, e.alignAttr, l, s, o);else if (ll(i.crop, !0)) {
          let {
            x: t,
            y: i
          } = e.alignAttr;
          u = r.isInsidePlot(t, i, {
            paneCoordinates: !0,
            series: this
          }) && r.isInsidePlot(t + l.width - 1, i + l.height - 1, {
            paneCoordinates: !0,
            series: this
          });
        }
        i.shape && !d && e[o ? "attr" : "animate"]({
          anchorX: g[0],
          anchorY: g[1]
        });
      }
      o && a && (e.placed = !1), u || a && !p ? (e.show(), e.placed = !0) : (e.hide(), e.placed = !1);
    }
    function s() {
      return this.plotGroup("dataLabelsGroup", "data-labels", this.hasRendered ? "inherit" : "hidden", this.options.dataLabels.zIndex || 6);
    }
    function o(t) {
      let e = this.hasRendered || 0,
        i = this.initDataLabelsGroup().attr({
          opacity: +e
        });
      return !e && i && (this.visible && i.show(), this.options.animation ? i.animate({
        opacity: 1
      }, t) : i.attr({
        opacity: 1
      })), i;
    }
    function r(t) {
      let e;
      t = t || this.points;
      let i = this,
        s = i.chart,
        o = i.options,
        r = s.renderer,
        {
          backgroundColor: a,
          plotBackgroundColor: h
        } = s.options.chart,
        d = r.getContrast(lr(h) && h || lr(a) && a || "#000000"),
        c = l(i),
        {
          animation: p,
          defer: u
        } = c[0],
        g = u ? n4(s, p, i) : {
          defer: 0,
          duration: 0
        };
      li(this, "drawDataLabels"), i.hasDataLabels?.() && (e = this.initDataLabels(g), t.forEach(t => {
        let a = t.dataLabels || [];
        ld(n(c, t.dlOptions || t.options?.dataLabels)).forEach((n, l) => {
          let h = n.enabled && (t.visible || t.dataLabelOnHidden) && (!t.isNull || t.dataLabelOnNull) && function (t, e) {
              let i = e.filter;
              if (i) {
                let e = i.operator,
                  s = t[i.property],
                  o = i.value;
                return ">" === e && s > o || "<" === e && s < o || ">=" === e && s >= o || "<=" === e && s <= o || "==" === e && s == o || "===" === e && s === o || "!=" === e && s != o || "!==" === e && s !== o;
              }
              return !0;
            }(t, n),
            {
              backgroundColor: c,
              borderColor: p,
              distance: u,
              style: g = {}
            } = n,
            m,
            f,
            x,
            y = {},
            b = a[l],
            v = !b,
            M;
          h && (f = lt(m = ll(n[t.formatPrefix + "Format"], n.format)) ? n7(m, t, s) : (n[t.formatPrefix + "Formatter"] || n.formatter).call(t, n), x = n.rotation, !s.styledMode && (g.color = ll(n.color, g.color, lr(i.color) ? i.color : void 0, "#000000"), "contrast" === g.color ? ("none" !== c && (M = c), t.contrastColor = r.getContrast("auto" !== M && M || t.color || i.color), g.color = M || !lt(u) && n.inside || 0 > lh(u || 0) || o.stacking ? t.contrastColor : d) : delete t.contrastColor, o.cursor && (g.cursor = o.cursor)), y = {
            r: n.borderRadius || 0,
            rotation: x,
            padding: n.padding,
            zIndex: 1
          }, s.styledMode || (y.fill = "auto" === c ? t.color : c, y.stroke = "auto" === p ? t.color : p, y["stroke-width"] = n.borderWidth), ln(y, (t, e) => {
            void 0 === t && delete y[e];
          })), !b || h && lt(f) && !!b.div == !!n.useHTML && (b.rotation && n.rotation || b.rotation === n.rotation) || (b = void 0, v = !0), h && lt(f) && (b ? y.text = f : (b = r.label(f, 0, 0, n.shape, void 0, void 0, n.useHTML, void 0, "data-label")).addClass(" highcharts-data-label-color-" + t.colorIndex + " " + (n.className || "") + (n.useHTML ? " highcharts-tracker" : "")), b && (b.options = n, b.attr(y), s.styledMode ? g.width && b.css({
            width: g.width,
            textOverflow: g.textOverflow,
            whiteSpace: g.whiteSpace
          }) : b.css(g).shadow(n.shadow), li(b, "beforeAddingDataLabel", {
            labelOptions: n,
            point: t
          }), b.added || b.add(e), i.alignDataLabel(t, b, n, void 0, v), b.isActive = !0, a[l] && a[l] !== b && a[l].destroy(), a[l] = b));
        });
        let l = a.length;
        for (; l--;) a[l] && a[l].isActive ? a[l].isActive = !1 : (a[l]?.destroy(), a.splice(l, 1));
        t.dataLabel = a[0], t.dataLabels = a;
      })), li(this, "afterDrawDataLabels");
    }
    function a(t, e, i, s, o, r) {
      let a = this.chart,
        n = e.align,
        l = e.verticalAlign,
        h = t.box ? 0 : t.padding || 0,
        d = a.inverted ? this.yAxis : this.xAxis,
        c = d ? d.left - a.plotLeft : 0,
        p = a.inverted ? this.xAxis : this.yAxis,
        u = p ? p.top - a.plotTop : 0,
        {
          x: g = 0,
          y: m = 0
        } = e,
        f,
        x;
      return (f = (i.x || 0) + h + c) < 0 && ("right" === n && g >= 0 ? (e.align = "left", e.inside = !0) : g -= f, x = !0), (f = (i.x || 0) + s.width - h + c) > a.plotWidth && ("left" === n && g <= 0 ? (e.align = "right", e.inside = !0) : g += a.plotWidth - f, x = !0), (f = i.y + h + u) < 0 && ("bottom" === l && m >= 0 ? (e.verticalAlign = "top", e.inside = !0) : m -= f, x = !0), (f = (i.y || 0) + s.height - h + u) > a.plotHeight && ("top" === l && m <= 0 ? (e.verticalAlign = "bottom", e.inside = !0) : m += a.plotHeight - f, x = !0), x && (e.x = g, e.y = m, t.placed = !r, t.align(e, void 0, o)), x;
    }
    function n(t, e) {
      let i = [],
        s;
      if (lo(t) && !lo(e)) i = t.map(function (t) {
        return la(t, e);
      });else if (lo(e) && !lo(t)) i = e.map(function (e) {
        return la(t, e);
      });else if (lo(t) || lo(e)) {
        if (lo(t) && lo(e)) for (s = Math.max(t.length, e.length); s--;) i[s] = la(t[s], e[s]);
      } else i = la(t, e);
      return i;
    }
    function l(t) {
      let e = t.chart.options.plotOptions;
      return ld(n(n(e?.series?.dataLabels, e?.[t.type]?.dataLabels), t.options.dataLabels));
    }
    function h(t, e, i, s, o) {
      let r = this.chart,
        a = r.inverted,
        n = this.xAxis,
        l = n.reversed,
        h = ((a ? e.height : e.width) || 0) / 2,
        d = t.pointWidth,
        c = d ? d / 2 : 0;
      e.startXPos = a ? o.x : l ? -h - c : n.width - h + c, e.startYPos = a ? l ? this.yAxis.height - h + c : -h - c : o.y, s ? "hidden" === e.visibility && (e.show(), e.attr({
        opacity: 0
      }).animate({
        opacity: 1
      })) : e.attr({
        opacity: 1
      }).animate({
        opacity: 0
      }, void 0, e.hide), r.hasRendered && (i && e.attr({
        x: e.startXPos,
        y: e.startYPos
      }), e.placed = !0);
    }
    t.compose = function (t) {
      let n = t.prototype;
      n.initDataLabels || (n.initDataLabels = o, n.initDataLabelsGroup = s, n.alignDataLabel = i, n.drawDataLabels = r, n.justifyDataLabel = a, n.setDataLabelStartPos = h, n.hasDataLabels = e);
    };
  }(g || (g = {}));
  let lc = g,
    {
      composed: lp
    } = A,
    {
      series: lu
    } = rx,
    {
      merge: lg,
      pick: lm,
      pushUnique: lf
    } = J;
  !function (t) {
    function e(t, e, i, s, o) {
      let r = this.chart.inverted,
        a = t.series,
        n = (a.xAxis ? a.xAxis.len : this.chart.plotSizeX) || 0,
        l = (a.yAxis ? a.yAxis.len : this.chart.plotSizeY) || 0,
        h = t.dlBox || t.shapeArgs,
        d = lm(t.below, t.plotY > lm(this.translatedThreshold, l)),
        c = lm(i.inside, !!this.options.stacking);
      if (h) {
        if (s = lg(h), !("allow" === i.overflow && !1 === i.crop)) {
          s.y < 0 && (s.height += s.y, s.y = 0);
          let t = s.y + s.height - l;
          t > 0 && t < s.height - 1 && (s.height -= t);
        }
        r && (s = {
          x: l - s.y - s.height,
          y: n - s.x - s.width,
          width: s.height,
          height: s.width
        }), c || (r ? (s.x += d ? 0 : s.width, s.width = 0) : (s.y += d ? s.height : 0, s.height = 0));
      }
      i.align = lm(i.align, !r || c ? "center" : d ? "right" : "left"), i.verticalAlign = lm(i.verticalAlign, r || c ? "middle" : d ? "top" : "bottom"), lu.prototype.alignDataLabel.call(this, t, e, i, s, o), i.inside && t.contrastColor && e.css({
        color: t.contrastColor
      });
    }
    t.compose = function (t) {
      lc.compose(lu), lf(lp, "ColumnDataLabel") && (t.prototype.alignDataLabel = e);
    };
  }(m || (m = {}));
  let lx = m,
    {
      extend: ly,
      merge: lb
    } = J;
  class lv extends n8 {}
  lv.defaultOptions = lb(n8.defaultOptions, {}), ly(lv.prototype, {
    inverted: !0
  }), rx.registerSeriesType("bar", lv);
  let {
      column: lM,
      line: lw
    } = rx.seriesTypes,
    {
      addEvent: lk,
      extend: lS,
      merge: lT
    } = J;
  class lC extends lw {
    applyJitter() {
      let t = this,
        e = this.options.jitter,
        i = this.points.length;
      e && this.points.forEach(function (s, o) {
        ["x", "y"].forEach(function (r, a) {
          if (e[r] && !s.isNull) {
            let n = `plot${r.toUpperCase()}`,
              l = t[`${r}Axis`],
              h = e[r] * l.transA;
            if (l && !l.logarithmic) {
              let t = Math.max(0, (s[n] || 0) - h),
                e = Math.min(l.len, (s[n] || 0) + h);
              s[n] = t + (e - t) * function (t) {
                let e = 1e4 * Math.sin(t);
                return e - Math.floor(e);
              }(o + a * i), "x" === r && (s.clientX = s.plotX);
            }
          }
        });
      });
    }
    drawGraph() {
      this.options.lineWidth ? super.drawGraph() : this.graph && (this.graph = this.graph.destroy());
    }
  }
  lC.defaultOptions = lT(lw.defaultOptions, {
    lineWidth: 0,
    findNearestPointBy: "xy",
    jitter: {
      x: 0,
      y: 0
    },
    marker: {
      enabled: !0
    },
    tooltip: {
      headerFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 0.8em"> {series.name}</span><br/>',
      pointFormat: "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
    }
  }), lS(lC.prototype, {
    drawTracker: lM.prototype.drawTracker,
    sorted: !1,
    requireSorting: !1,
    noSharedTooltip: !0,
    trackerGroups: ["group", "markerGroup", "dataLabelsGroup"]
  }), lk(lC, "afterTranslate", function () {
    this.applyJitter();
  }), rx.registerSeriesType("scatter", lC);
  let {
      deg2rad: lA
    } = A,
    {
      fireEvent: lP,
      isNumber: lL,
      pick: lO,
      relativeLength: lE
    } = J;
  !function (t) {
    t.getCenter = function () {
      let t = this.options,
        e = this.chart,
        i = 2 * (t.slicedOffset || 0),
        s = e.plotWidth - 2 * i,
        o = e.plotHeight - 2 * i,
        r = t.center,
        a = Math.min(s, o),
        n = t.thickness,
        l,
        h = t.size,
        d = t.innerSize || 0,
        c,
        p;
      "string" == typeof h && (h = parseFloat(h)), "string" == typeof d && (d = parseFloat(d));
      let u = [lO(r?.[0], "50%"), lO(r?.[1], "50%"), lO(h && h < 0 ? void 0 : t.size, "100%"), lO(d && d < 0 ? void 0 : t.innerSize || 0, "0%")];
      for (!e.angular || this instanceof rq || (u[3] = 0), c = 0; c < 4; ++c) p = u[c], l = c < 2 || 2 === c && /%$/.test(p), u[c] = lE(p, [s, o, a, u[2]][c]) + (l ? i : 0);
      return u[3] > u[2] && (u[3] = u[2]), lL(n) && 2 * n < u[2] && n > 0 && (u[3] = u[2] - 2 * n), lP(this, "afterGetCenter", {
        positions: u
      }), u;
    }, t.getStartAndEndRadians = function (t, e) {
      let i = lL(t) ? t : 0,
        s = lL(e) && e > i && e - i < 360 ? e : i + 360;
      return {
        start: lA * (i + -90),
        end: lA * (s + -90)
      };
    };
  }(f || (f = {}));
  let lI = f,
    {
      setAnimation: lD
    } = tH,
    {
      addEvent: lB,
      defined: lz,
      extend: lN,
      isNumber: lR,
      pick: lW,
      relativeLength: lj
    } = J;
  class lX extends o$ {
    getConnectorPath(t) {
      let e = t.dataLabelPosition,
        i = t.options || {},
        s = i.connectorShape,
        o = this.connectorShapes[s] || s;
      return e && o.call(this, {
        ...e.computed,
        alignment: e.alignment
      }, e.connectorPosition, i) || [];
    }
    getTranslate() {
      return this.sliced && this.slicedTranslation || {
        translateX: 0,
        translateY: 0
      };
    }
    haloPath(t) {
      let e = this.shapeArgs;
      return this.sliced || !this.visible ? [] : this.series.chart.renderer.symbols.arc(e.x, e.y, e.r + t, e.r + t, {
        innerR: e.r - 1,
        start: e.start,
        end: e.end,
        borderRadius: e.borderRadius
      });
    }
    constructor(t, e, i) {
      super(t, e, i), this.half = 0, this.name ?? (this.name = "Slice");
      let s = t => {
        this.slice("select" === t.type);
      };
      lB(this, "select", s), lB(this, "unselect", s);
    }
    isValid() {
      return lR(this.y) && this.y >= 0;
    }
    setVisible(t, e = !0) {
      t !== this.visible && this.update({
        visible: t ?? !this.visible
      }, e, void 0, !1);
    }
    slice(t, e, i) {
      let s = this.series;
      lD(i, s.chart), e = lW(e, !0), this.sliced = this.options.sliced = t = lz(t) ? t : !this.sliced, s.options.data[s.data.indexOf(this)] = this.options, this.graphic && this.graphic.animate(this.getTranslate());
    }
  }
  lN(lX.prototype, {
    connectorShapes: {
      fixedOffset: function (t, e, i) {
        let s = e.breakAt,
          o = e.touchingSliceAt,
          r = i.softConnector ? ["C", t.x + ("left" === t.alignment ? -5 : 5), t.y, 2 * s.x - o.x, 2 * s.y - o.y, s.x, s.y] : ["L", s.x, s.y];
        return [["M", t.x, t.y], r, ["L", o.x, o.y]];
      },
      straight: function (t, e) {
        let i = e.touchingSliceAt;
        return [["M", t.x, t.y], ["L", i.x, i.y]];
      },
      crookedLine: function (t, e, i) {
        let {
            angle: s = this.angle || 0,
            breakAt: o,
            touchingSliceAt: r
          } = e,
          {
            series: a
          } = this,
          [n, l, h] = a.center,
          d = h / 2,
          {
            plotLeft: c,
            plotWidth: p
          } = a.chart,
          u = "left" === t.alignment,
          {
            x: g,
            y: m
          } = t,
          f = o.x;
        if (i.crookDistance) {
          let t = lj(i.crookDistance, 1);
          f = u ? n + d + (p + c - n - d) * (1 - t) : c + (n - d) * t;
        } else f = n + (l - m) * Math.tan(s - Math.PI / 2);
        let x = [["M", g, m]];
        return (u ? f <= g && f >= o.x : f >= g && f <= o.x) && x.push(["L", f, m]), x.push(["L", o.x, o.y], ["L", r.x, r.y]), x;
      }
    }
  });
  let {
      getStartAndEndRadians: lG
    } = lI,
    {
      noop: lF
    } = A,
    {
      clamp: lH,
      extend: lY,
      fireEvent: lV,
      merge: lU,
      pick: lZ
    } = J;
  class l$ extends rq {
    animate(t) {
      let e = this,
        i = e.points,
        s = e.startAngleRad;
      t || i.forEach(function (t) {
        let i = t.graphic,
          o = t.shapeArgs;
        i && o && (i.attr({
          r: lZ(t.startR, e.center && e.center[3] / 2),
          start: s,
          end: s
        }), i.animate({
          r: o.r,
          start: o.start,
          end: o.end
        }, e.options.animation));
      });
    }
    drawEmpty() {
      let t, e;
      let i = this.startAngleRad,
        s = this.endAngleRad,
        o = this.options;
      0 === this.total && this.center ? (t = this.center[0], e = this.center[1], this.graph || (this.graph = this.chart.renderer.arc(t, e, this.center[1] / 2, 0, i, s).addClass("highcharts-empty-series").add(this.group)), this.graph.attr({
        d: ie.arc(t, e, this.center[2] / 2, 0, {
          start: i,
          end: s,
          innerR: this.center[3] / 2
        })
      }), this.chart.styledMode || this.graph.attr({
        "stroke-width": o.borderWidth,
        fill: o.fillColor || "none",
        stroke: o.color || "#cccccc"
      })) : this.graph && (this.graph = this.graph.destroy());
    }
    drawPoints() {
      let t = this.chart.renderer;
      this.points.forEach(function (e) {
        e.graphic && e.hasNewShapeType() && (e.graphic = e.graphic.destroy()), e.graphic || (e.graphic = t[e.shapeType](e.shapeArgs).add(e.series.group), e.delayedRendering = !0);
      });
    }
    generatePoints() {
      super.generatePoints(), this.updateTotals();
    }
    getX(t, e, i, s) {
      let o = this.center,
        r = this.radii ? this.radii[i.index] || 0 : o[2] / 2,
        a = s.dataLabelPosition,
        n = a?.distance || 0,
        l = Math.asin(lH((t - o[1]) / (r + n), -1, 1));
      return o[0] + Math.cos(l) * (r + n) * (e ? -1 : 1) + (n > 0 ? (e ? -1 : 1) * (s.padding || 0) : 0);
    }
    hasData() {
      return !!this.dataTable.rowCount;
    }
    redrawPoints() {
      let t, e, i, s;
      let o = this,
        r = o.chart;
      this.drawEmpty(), o.group && !r.styledMode && o.group.shadow(o.options.shadow), o.points.forEach(function (a) {
        let n = {};
        e = a.graphic, !a.isNull && e ? (s = a.shapeArgs, t = a.getTranslate(), r.styledMode || (i = o.pointAttribs(a, a.selected && "select")), a.delayedRendering ? (e.setRadialReference(o.center).attr(s).attr(t), r.styledMode || e.attr(i).attr({
          "stroke-linejoin": "round"
        }), a.delayedRendering = !1) : (e.setRadialReference(o.center), r.styledMode || lU(!0, n, i), lU(!0, n, s, t), e.animate(n)), e.attr({
          visibility: a.visible ? "inherit" : "hidden"
        }), e.addClass(a.getClassName(), !0)) : e && (a.graphic = e.destroy());
      });
    }
    sortByAngle(t, e) {
      t.sort(function (t, i) {
        return void 0 !== t.angle && (i.angle - t.angle) * e;
      });
    }
    translate(t) {
      lV(this, "translate"), this.generatePoints();
      let e = this.options,
        i = e.slicedOffset,
        s = lG(e.startAngle, e.endAngle),
        o = this.startAngleRad = s.start,
        r = (this.endAngleRad = s.end) - o,
        a = this.points,
        n = e.ignoreHiddenPoint,
        l = a.length,
        h,
        d,
        c,
        p,
        u,
        g,
        m,
        f = 0;
      for (t || (this.center = t = this.getCenter()), g = 0; g < l; g++) {
        m = a[g], h = o + f * r, m.isValid() && (!n || m.visible) && (f += m.percentage / 100), d = o + f * r;
        let e = {
          x: t[0],
          y: t[1],
          r: t[2] / 2,
          innerR: t[3] / 2,
          start: Math.round(1e3 * h) / 1e3,
          end: Math.round(1e3 * d) / 1e3
        };
        m.shapeType = "arc", m.shapeArgs = e, (c = (d + h) / 2) > 1.5 * Math.PI ? c -= 2 * Math.PI : c < -Math.PI / 2 && (c += 2 * Math.PI), m.slicedTranslation = {
          translateX: Math.round(Math.cos(c) * i),
          translateY: Math.round(Math.sin(c) * i)
        }, p = Math.cos(c) * t[2] / 2, u = Math.sin(c) * t[2] / 2, m.tooltipPos = [t[0] + .7 * p, t[1] + .7 * u], m.half = c < -Math.PI / 2 || c > Math.PI / 2 ? 1 : 0, m.angle = c;
      }
      lV(this, "afterTranslate");
    }
    updateTotals() {
      let t = this.points,
        e = t.length,
        i = this.options.ignoreHiddenPoint,
        s,
        o,
        r = 0;
      for (s = 0; s < e; s++) (o = t[s]).isValid() && (!i || o.visible) && (r += o.y);
      for (s = 0, this.total = r; s < e; s++) (o = t[s]).percentage = r > 0 && (o.visible || !i) ? o.y / r * 100 : 0, o.total = r;
    }
  }
  l$.defaultOptions = lU(rq.defaultOptions, {
    borderRadius: 3,
    center: [null, null],
    clip: !1,
    colorByPoint: !0,
    dataLabels: {
      connectorPadding: 5,
      connectorShape: "crookedLine",
      crookDistance: void 0,
      distance: 30,
      enabled: !0,
      formatter: function () {
        return this.isNull ? void 0 : this.name;
      },
      softConnector: !0,
      x: 0
    },
    fillColor: void 0,
    ignoreHiddenPoint: !0,
    inactiveOtherPoints: !0,
    legendType: "point",
    marker: null,
    size: null,
    showInLegend: !1,
    slicedOffset: 10,
    stickyTracking: !1,
    tooltip: {
      followPointer: !0
    },
    borderColor: "#ffffff",
    borderWidth: 1,
    lineWidth: void 0,
    states: {
      hover: {
        brightness: .1
      }
    }
  }), lY(l$.prototype, {
    axisTypes: [],
    directTouch: !0,
    drawGraph: void 0,
    drawTracker: n8.prototype.drawTracker,
    getCenter: lI.getCenter,
    getSymbol: lF,
    invertible: !1,
    isCartesian: !1,
    noSharedTooltip: !0,
    pointAttribs: n8.prototype.pointAttribs,
    pointClass: lX,
    requireSorting: !1,
    searchPoint: lF,
    trackerGroups: ["group", "dataLabelsGroup"]
  }), rx.registerSeriesType("pie", l$);
  let {
      composed: l_,
      noop: lq
    } = A,
    {
      distribute: lK
    } = ef,
    {
      series: lJ
    } = rx,
    {
      arrayMax: lQ,
      clamp: l0,
      defined: l1,
      pick: l2,
      pushUnique: l3,
      relativeLength: l6
    } = J;
  !function (t) {
    let e = {
      radialDistributionY: function (t, e) {
        return (e.dataLabelPosition?.top || 0) + t.distributeBox.pos;
      },
      radialDistributionX: function (t, e, i, s, o) {
        let r = o.dataLabelPosition;
        return t.getX(i < (r?.top || 0) + 2 || i > (r?.bottom || 0) - 2 ? s : i, e.half, e, o);
      },
      justify: function (t, e, i, s) {
        return s[0] + (t.half ? -1 : 1) * (i + (e.dataLabelPosition?.distance || 0));
      },
      alignToPlotEdges: function (t, e, i, s) {
        let o = t.getBBox().width;
        return e ? o + s : i - o - s;
      },
      alignToConnectors: function (t, e, i, s) {
        let o = 0,
          r;
        return t.forEach(function (t) {
          (r = t.dataLabel.getBBox().width) > o && (o = r);
        }), e ? o + s : i - o - s;
      }
    };
    function i(t, e) {
      let i = Math.PI / 2,
        {
          start: s = 0,
          end: o = 0
        } = t.shapeArgs || {},
        r = t.angle || 0;
      e > 0 && s < i && o > i && r > i / 2 && r < 1.5 * i && (r = r <= i ? Math.max(i / 2, (s + i) / 2) : Math.min(1.5 * i, (i + o) / 2));
      let {
          center: a,
          options: n
        } = this,
        l = a[2] / 2,
        h = Math.cos(r),
        d = Math.sin(r),
        c = a[0] + h * l,
        p = a[1] + d * l,
        u = Math.min((n.slicedOffset || 0) + (n.borderWidth || 0), e / 5);
      return {
        natural: {
          x: c + h * e,
          y: p + d * e
        },
        computed: {},
        alignment: e < 0 ? "center" : t.half ? "right" : "left",
        connectorPosition: {
          angle: r,
          breakAt: {
            x: c + h * u,
            y: p + d * u
          },
          touchingSliceAt: {
            x: c,
            y: p
          }
        },
        distance: e
      };
    }
    function s() {
      let t = this,
        e = t.points,
        i = t.chart,
        s = i.plotWidth,
        o = i.plotHeight,
        r = i.plotLeft,
        a = Math.round(i.chartWidth / 3),
        n = t.center,
        l = n[2] / 2,
        h = n[1],
        d = [[], []],
        c = [0, 0, 0, 0],
        p = t.dataLabelPositioners,
        u,
        g,
        m,
        f = 0;
      t.visible && t.hasDataLabels?.() && (e.forEach(t => {
        (t.dataLabels || []).forEach(t => {
          t.shortened && (t.attr({
            width: "auto"
          }).css({
            width: "auto",
            textOverflow: "clip"
          }), t.shortened = !1);
        });
      }), lJ.prototype.drawDataLabels.apply(t), e.forEach(t => {
        (t.dataLabels || []).forEach((e, i) => {
          let s = n[2] / 2,
            o = e.options,
            r = l6(o?.distance || 0, s);
          0 === i && d[t.half].push(t), !l1(o?.style?.width) && e.getBBox().width > a && (e.css({
            width: Math.round(.7 * a) + "px"
          }), e.shortened = !0), e.dataLabelPosition = this.getDataLabelPosition(t, r), f = Math.max(f, r);
        });
      }), d.forEach((e, a) => {
        let d = e.length,
          u = [],
          x,
          y,
          b = 0,
          v;
        d && (t.sortByAngle(e, a - .5), f > 0 && (x = Math.max(0, h - l - f), y = Math.min(h + l + f, i.plotHeight), e.forEach(t => {
          (t.dataLabels || []).forEach(e => {
            let s = e.dataLabelPosition;
            s && s.distance > 0 && (s.top = Math.max(0, h - l - s.distance), s.bottom = Math.min(h + l + s.distance, i.plotHeight), b = e.getBBox().height || 21, e.lineHeight = i.renderer.fontMetrics(e.text || e).h + 2 * e.padding, t.distributeBox = {
              target: (e.dataLabelPosition?.natural.y || 0) - s.top + e.lineHeight / 2,
              size: b,
              rank: t.y
            }, u.push(t.distributeBox));
          });
        }), lK(u, v = y + b - x, v / 5)), e.forEach(i => {
          (i.dataLabels || []).forEach(h => {
            let d = h.options || {},
              f = i.distributeBox,
              x = h.dataLabelPosition,
              y = x?.natural.y || 0,
              b = d.connectorPadding || 0,
              v = h.lineHeight || 21,
              M = (v - h.getBBox().height) / 2,
              w = 0,
              k = y,
              S = "inherit";
            if (x) {
              if (u && l1(f) && x.distance > 0 && (void 0 === f.pos ? S = "hidden" : (m = f.size, k = p.radialDistributionY(i, h))), d.justify) w = p.justify(i, h, l, n);else switch (d.alignTo) {
                case "connectors":
                  w = p.alignToConnectors(e, a, s, r);
                  break;
                case "plotEdges":
                  w = p.alignToPlotEdges(h, a, s, r);
                  break;
                default:
                  w = p.radialDistributionX(t, i, k - M, y, h);
              }
              if (x.attribs = {
                visibility: S,
                align: x.alignment
              }, x.posAttribs = {
                x: w + (d.x || 0) + ({
                  left: b,
                  right: -b
                }[x.alignment] || 0),
                y: k + (d.y || 0) - v / 2
              }, x.computed.x = w, x.computed.y = k - M, l2(d.crop, !0)) {
                let t;
                w - (g = h.getBBox().width) < b && 1 === a ? (t = Math.round(g - w + b), c[3] = Math.max(t, c[3])) : w + g > s - b && 0 === a && (t = Math.round(w + g - s + b), c[1] = Math.max(t, c[1])), k - m / 2 < 0 ? c[0] = Math.max(Math.round(-k + m / 2), c[0]) : k + m / 2 > o && (c[2] = Math.max(Math.round(k + m / 2 - o), c[2])), x.sideOverflow = t;
              }
            }
          });
        }));
      }), (0 === lQ(c) || this.verifyDataLabelOverflow(c)) && (this.placeDataLabels(), this.points.forEach(e => {
        (e.dataLabels || []).forEach(s => {
          let {
              connectorColor: o,
              connectorWidth: r = 1
            } = s.options || {},
            a = s.dataLabelPosition;
          if (r) {
            let n;
            u = s.connector, a && a.distance > 0 ? (n = !u, u || (s.connector = u = i.renderer.path().addClass("highcharts-data-label-connector  highcharts-color-" + e.colorIndex + (e.className ? " " + e.className : "")).add(t.dataLabelsGroup)), i.styledMode || u.attr({
              "stroke-width": r,
              stroke: o || e.color || "#666666"
            }), u[n ? "attr" : "animate"]({
              d: e.getConnectorPath(s)
            }), u.attr({
              visibility: a.attribs?.visibility
            })) : u && (s.connector = u.destroy());
          }
        });
      })));
    }
    function o() {
      this.points.forEach(t => {
        (t.dataLabels || []).forEach(t => {
          let e = t.dataLabelPosition;
          e ? (e.sideOverflow && (t.css({
            width: Math.max(t.getBBox().width - e.sideOverflow, 0) + "px",
            textOverflow: (t.options?.style || {}).textOverflow || "ellipsis"
          }), t.shortened = !0), t.attr(e.attribs), t[t.moved ? "animate" : "attr"](e.posAttribs), t.moved = !0) : t && t.attr({
            y: -9999
          });
        }), delete t.distributeBox;
      }, this);
    }
    function r(t) {
      let e = this.center,
        i = this.options,
        s = i.center,
        o = i.minSize || 80,
        r = o,
        a = null !== i.size;
      return !a && (null !== s[0] ? r = Math.max(e[2] - Math.max(t[1], t[3]), o) : (r = Math.max(e[2] - t[1] - t[3], o), e[0] += (t[3] - t[1]) / 2), null !== s[1] ? r = l0(r, o, e[2] - Math.max(t[0], t[2])) : (r = l0(r, o, e[2] - t[0] - t[2]), e[1] += (t[0] - t[2]) / 2), r < e[2] ? (e[2] = r, e[3] = Math.min(i.thickness ? Math.max(0, r - 2 * i.thickness) : Math.max(0, l6(i.innerSize || 0, r)), r), this.translate(e), this.drawDataLabels && this.drawDataLabels()) : a = !0), a;
    }
    t.compose = function (t) {
      if (lc.compose(lJ), l3(l_, "PieDataLabel")) {
        let a = t.prototype;
        a.dataLabelPositioners = e, a.alignDataLabel = lq, a.drawDataLabels = s, a.getDataLabelPosition = i, a.placeDataLabels = o, a.verifyDataLabelOverflow = r;
      }
    };
  }(x || (x = {}));
  let l5 = x;
  !function (t) {
    t.getCenterOfPoints = function (t) {
      let e = t.reduce((t, e) => (t.x += e.x, t.y += e.y, t), {
        x: 0,
        y: 0
      });
      return {
        x: e.x / t.length,
        y: e.y / t.length
      };
    }, t.getDistanceBetweenPoints = function (t, e) {
      return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
    }, t.getAngleBetweenPoints = function (t, e) {
      return Math.atan2(e.x - t.x, e.y - t.y);
    }, t.pointInPolygon = function ({
      x: t,
      y: e
    }, i) {
      let s = i.length,
        o,
        r,
        a = !1;
      for (o = 0, r = s - 1; o < s; r = o++) {
        let [s, n] = i[o],
          [l, h] = i[r];
        n > e != h > e && t < (l - s) * (e - n) / (h - n) + s && (a = !a);
      }
      return a;
    };
  }(y || (y = {}));
  let l9 = y,
    {
      pointInPolygon: l8
    } = l9,
    {
      addEvent: l4,
      fireEvent: l7,
      objectEach: ht,
      pick: he
    } = J;
  function hi(t) {
    let e = t.length,
      i = (t, e) => !(e.x >= t.x + t.width || e.x + e.width <= t.x || e.y >= t.y + t.height || e.y + e.height <= t.y),
      s = (t, e) => {
        for (let i of t) if (l8({
          x: i[0],
          y: i[1]
        }, e)) return !0;
        return !1;
      },
      o,
      r,
      a,
      n,
      l,
      h = !1;
    for (let i = 0; i < e; i++) (o = t[i]) && (o.oldOpacity = o.opacity, o.newOpacity = 1, o.absoluteBox = function (t) {
      if (t && (!t.alignAttr || t.placed)) {
        let e = t.box ? 0 : t.padding || 0,
          i = t.alignAttr || {
            x: t.attr("x"),
            y: t.attr("y")
          },
          s = t.getBBox();
        return t.width = s.width, t.height = s.height, {
          x: i.x + (t.parentGroup?.translateX || 0) + e,
          y: i.y + (t.parentGroup?.translateY || 0) + e,
          width: (t.width || 0) - 2 * e,
          height: (t.height || 0) - 2 * e,
          polygon: s?.polygon
        };
      }
    }(o));
    t.sort((t, e) => (e.labelrank || 0) - (t.labelrank || 0));
    for (let o = 0; o < e; ++o) {
      n = (r = t[o]) && r.absoluteBox;
      let h = n?.polygon;
      for (let d = o + 1; d < e; ++d) {
        l = (a = t[d]) && a.absoluteBox;
        let e = !1;
        if (n && l && r !== a && 0 !== r.newOpacity && 0 !== a.newOpacity && "hidden" !== r.visibility && "hidden" !== a.visibility) {
          let t = l.polygon;
          if (h && t && h !== t ? s(h, t) && (e = !0) : i(n, l) && (e = !0), e) {
            let t = r.labelrank < a.labelrank ? r : a,
              e = t.text;
            t.newOpacity = 0, e?.element.querySelector("textPath") && e.hide();
          }
        }
      }
    }
    for (let e of t) hs(e, this) && (h = !0);
    h && l7(this, "afterHideAllOverlappingLabels");
  }
  function hs(t, e) {
    let i,
      s,
      o = !1;
    return t && (s = t.newOpacity, t.oldOpacity !== s && (t.hasClass("highcharts-data-label") ? (t[s ? "removeClass" : "addClass"]("highcharts-data-label-hidden"), i = function () {
      e.styledMode || t.css({
        pointerEvents: s ? "auto" : "none"
      });
    }, o = !0, t[t.isOld ? "animate" : "attr"]({
      opacity: s
    }, void 0, i), l7(e, "afterHideOverlappingLabel")) : t.attr({
      opacity: s
    })), t.isOld = !0), o;
  }
  function ho() {
    let t = this,
      e = [];
    for (let i of t.labelCollectors || []) e = e.concat(i());
    for (let i of t.yAxis || []) i.stacking && i.options.stackLabels && !i.options.stackLabels.allowOverlap && ht(i.stacking.stacks, t => {
      ht(t, t => {
        t.label && e.push(t.label);
      });
    });
    for (let i of t.series || []) if (i.visible && i.hasDataLabels?.()) {
      let s = i => {
        for (let s of i) s.visible && (s.dataLabels || []).forEach(i => {
          let o = i.options || {};
          i.labelrank = he(o.labelrank, s.labelrank, s.shapeArgs?.height), o.allowOverlap ?? Number(o.distance) > 0 ? (i.oldOpacity = i.opacity, i.newOpacity = 1, hs(i, t)) : e.push(i);
        });
      };
      s(i.nodes || []), s(i.points);
    }
    this.hideOverlappingLabels(e);
  }
  let hr = {
      compose: function (t) {
        let e = t.prototype;
        e.hideOverlappingLabels || (e.hideOverlappingLabels = hi, l4(t, "render", ho));
      }
    },
    {
      defaultOptions: ha
    } = tw,
    {
      noop: hn
    } = A,
    {
      addEvent: hl,
      extend: hh,
      isObject: hd,
      merge: hc,
      relativeLength: hp
    } = J,
    hu = {
      radius: 0,
      scope: "stack",
      where: void 0
    },
    hg = hn,
    hm = hn;
  function hf(t, e, i, s, o = {}) {
    let r = hg(t, e, i, s, o),
      {
        innerR: a = 0,
        r: n = i,
        start: l = 0,
        end: h = 0
      } = o;
    if (o.open || !o.borderRadius) return r;
    let d = h - l,
      c = Math.sin(d / 2),
      p = Math.max(Math.min(hp(o.borderRadius || 0, n - a), (n - a) / 2, n * c / (1 + c)), 0),
      u = Math.min(p, d / Math.PI * 2 * a),
      g = r.length - 1;
    for (; g--;) !function (t, e, i) {
      let s, o, r;
      let a = t[e],
        n = t[e + 1];
      if ("Z" === n[0] && (n = t[0]), ("M" === a[0] || "L" === a[0]) && "A" === n[0] ? (s = a, o = n, r = !0) : "A" === a[0] && ("M" === n[0] || "L" === n[0]) && (s = n, o = a), s && o && o.params) {
        let a = o[1],
          n = o[5],
          l = o.params,
          {
            start: h,
            end: d,
            cx: c,
            cy: p
          } = l,
          u = n ? a - i : a + i,
          g = u ? Math.asin(i / u) : 0,
          m = n ? g : -g,
          f = Math.cos(g) * u;
        r ? (l.start = h + m, s[1] = c + f * Math.cos(h), s[2] = p + f * Math.sin(h), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + a * Math.cos(l.start), p + a * Math.sin(l.start)])) : (l.end = d - m, o[6] = c + a * Math.cos(l.end), o[7] = p + a * Math.sin(l.end), t.splice(e + 1, 0, ["A", i, i, 0, 0, 1, c + f * Math.cos(d), p + f * Math.sin(d)])), o[4] = Math.abs(l.end - l.start) < Math.PI ? 0 : 1;
      }
    }(r, g, g > 1 ? u : p);
    return r;
  }
  function hx() {
    if (this.options.borderRadius && !(this.chart.is3d && this.chart.is3d())) {
      let {
          options: t,
          yAxis: e
        } = this,
        i = "percent" === t.stacking,
        s = ha.plotOptions?.[this.type]?.borderRadius,
        o = hy(t.borderRadius, hd(s) ? s : {}),
        r = e.options.reversed;
      for (let s of this.points) {
        let {
          shapeArgs: a
        } = s;
        if ("roundedRect" === s.shapeType && a) {
          let {
              width: n = 0,
              height: l = 0,
              y: h = 0
            } = a,
            d = h,
            c = l;
          if ("stack" === o.scope && s.stackTotal) {
            let o = e.translate(i ? 100 : s.stackTotal, !1, !0, !1, !0),
              r = e.translate(t.threshold || 0, !1, !0, !1, !0),
              a = this.crispCol(0, Math.min(o, r), 0, Math.abs(o - r));
            d = a.y, c = a.height;
          }
          let p = (s.negative ? -1 : 1) * (r ? -1 : 1) == -1,
            u = o.where;
          !u && this.is("waterfall") && Math.abs((s.yBottom || 0) - (this.translatedThreshold || 0)) > this.borderWidth && (u = "all"), u || (u = "end");
          let g = Math.min(hp(o.radius, n), n / 2, "all" === u ? l / 2 : 1 / 0) || 0;
          "end" === u && (p && (d -= g), c += g), hh(a, {
            brBoxHeight: c,
            brBoxY: d,
            r: g
          });
        }
      }
    }
  }
  function hy(t, e) {
    return hd(t) || (t = {
      radius: t || 0
    }), hc(hu, e, t);
  }
  function hb() {
    let t = hy(this.options.borderRadius);
    for (let e of this.points) {
      let i = e.shapeArgs;
      i && (i.borderRadius = hp(t.radius, (i.r || 0) - (i.innerR || 0)));
    }
  }
  function hv(t, e, i, s, o = {}) {
    let r = hm(t, e, i, s, o),
      {
        r: a = 0,
        brBoxHeight: n = s,
        brBoxY: l = e
      } = o,
      h = e - l,
      d = l + n - (e + s),
      c = h - a > -.1 ? 0 : a,
      p = d - a > -.1 ? 0 : a,
      u = Math.max(c && h, 0),
      g = Math.max(p && d, 0),
      m = [t + c, e],
      f = [t + i - c, e],
      x = [t + i, e + c],
      y = [t + i, e + s - p],
      b = [t + i - p, e + s],
      v = [t + p, e + s],
      M = [t, e + s - p],
      w = [t, e + c],
      k = (t, e) => Math.sqrt(Math.pow(t, 2) - Math.pow(e, 2));
    if (u) {
      let t = k(c, c - u);
      m[0] -= t, f[0] += t, x[1] = w[1] = e + c - u;
    }
    if (s < c - u) {
      let o = k(c, c - u - s);
      x[0] = y[0] = t + i - c + o, b[0] = Math.min(x[0], b[0]), v[0] = Math.max(y[0], v[0]), M[0] = w[0] = t + c - o, x[1] = w[1] = e + s;
    }
    if (g) {
      let t = k(p, p - g);
      b[0] += t, v[0] -= t, y[1] = M[1] = e + s - p + g;
    }
    if (s < p - g) {
      let o = k(p, p - g - s);
      x[0] = y[0] = t + i - p + o, f[0] = Math.min(x[0], f[0]), m[0] = Math.max(y[0], m[0]), M[0] = w[0] = t + p - o, y[1] = M[1] = e;
    }
    return r.length = 0, r.push(["M", ...m], ["L", ...f], ["A", c, c, 0, 0, 1, ...x], ["L", ...y], ["A", p, p, 0, 0, 1, ...b], ["L", ...v], ["A", p, p, 0, 0, 1, ...M], ["L", ...w], ["A", c, c, 0, 0, 1, ...m], ["Z"]), r;
  }
  let {
    diffObjects: hM,
    extend: hw,
    find: hk,
    merge: hS,
    pick: hT,
    uniqueKey: hC
  } = J;
  !function (t) {
    function e(t, e) {
      let i = t.condition;
      (i.callback || function () {
        return this.chartWidth <= hT(i.maxWidth, Number.MAX_VALUE) && this.chartHeight <= hT(i.maxHeight, Number.MAX_VALUE) && this.chartWidth >= hT(i.minWidth, 0) && this.chartHeight >= hT(i.minHeight, 0);
      }).call(this) && e.push(t._id);
    }
    function i(t, e) {
      let i = this.options.responsive,
        s = this.currentResponsive,
        o = [],
        r;
      !e && i && i.rules && i.rules.forEach(t => {
        void 0 === t._id && (t._id = hC()), this.matchResponsiveRule(t, o);
      }, this);
      let a = hS(...o.map(t => hk((i || {}).rules || [], e => e._id === t)).map(t => t && t.chartOptions));
      a.isResponsiveOptions = !0, o = o.toString() || void 0;
      let n = s && s.ruleIds;
      o === n || (s && (this.currentResponsive = void 0, this.updatingResponsive = !0, this.update(s.undoOptions, t, !0), this.updatingResponsive = !1), o ? ((r = hM(a, this.options, !0, this.collectionsWithUpdate)).isResponsiveOptions = !0, this.currentResponsive = {
        ruleIds: o,
        mergedOptions: a,
        undoOptions: r
      }, this.updatingResponsive || this.update(a, t, !0)) : this.currentResponsive = void 0);
    }
    t.compose = function (t) {
      let s = t.prototype;
      return s.matchResponsiveRule || hw(s, {
        matchResponsiveRule: e,
        setResponsive: i
      }), t;
    };
  }(b || (b = {}));
  let hA = b;
  A.AST = t3, A.Axis = sY, A.Chart = aq, A.Color = tA, A.DataLabel = lc, A.DataTableCore = rl, A.Fx = tI, A.HTMLElement = i2, A.Legend = ah, A.LegendSymbol = rp, A.OverlappingDataLabels = A.OverlappingDataLabels || hr, A.PlotLineOrBand = oo, A.Point = o$, A.Pointer = rs, A.RendererRegistry = ec, A.Series = rq, A.SeriesRegistry = rx, A.StackItem = na, A.SVGElement = eq, A.SVGRenderer = iH, A.Templating = ed, A.Tick = sp, A.Time = tf, A.Tooltip = oP, A.animate = tH.animate, A.animObject = tH.animObject, A.chart = aq.chart, A.color = tA.parse, A.dateFormat = ed.dateFormat, A.defaultOptions = tw.defaultOptions, A.distribute = ef.distribute, A.format = ed.format, A.getDeferredAnimation = tH.getDeferredAnimation, A.getOptions = tw.getOptions, A.numberFormat = ed.numberFormat, A.seriesType = rx.seriesType, A.setAnimation = tH.setAnimation, A.setOptions = tw.setOptions, A.stop = tH.stop, A.time = tw.defaultTime, A.timers = tI.timers, {
    compose: function (t, e, i) {
      let s = t.types.pie;
      if (!e.symbolCustomAttribs.includes("borderRadius")) {
        let o = i.prototype.symbols;
        hl(t, "afterColumnTranslate", hx, {
          order: 9
        }), hl(s, "afterTranslate", hb), e.symbolCustomAttribs.push("borderRadius", "brBoxHeight", "brBoxY"), hg = o.arc, hm = o.roundedRect, o.arc = hf, o.roundedRect = hv;
      }
    },
    optionsToObject: hy
  }.compose(A.Series, A.SVGElement, A.SVGRenderer), lx.compose(A.Series.types.column), lc.compose(A.Series), s_.compose(A.Axis), i2.compose(A.SVGRenderer), ah.compose(A.Chart), sQ.compose(A.Axis), hr.compose(A.Chart), l5.compose(A.Series.types.pie), oo.compose(A.Chart, A.Axis), rs.compose(A.Chart), hA.compose(A.Chart), a4.compose(A.Axis, A.Chart, A.Series), nC.compose(A.Axis, A.Chart, A.Series), oP.compose(A.Pointer), J.extend(A, J);
  let {
      parse: hP
    } = tA,
    {
      addEvent: hL,
      extend: hO,
      merge: hE,
      pick: hI,
      splat: hD
    } = J;
  !function (t) {
    let e;
    function i() {
      let {
        userOptions: t
      } = this;
      this.colorAxis = [], t.colorAxis && (t.colorAxis = hD(t.colorAxis), t.colorAxis.map(t => new e(this, t)));
    }
    function s(t) {
      let e = this.chart.colorAxis || [],
        i = e => {
          let i = t.allItems.indexOf(e);
          -1 !== i && (this.destroyItem(t.allItems[i]), t.allItems.splice(i, 1));
        },
        s = [],
        o,
        r;
      for (e.forEach(function (t) {
        (o = t.options) && o.showInLegend && (o.dataClasses && o.visible ? s = s.concat(t.getDataClassLegendSymbols()) : o.visible && s.push(t), t.series.forEach(function (t) {
          (!t.options.showInLegend || o.dataClasses) && ("point" === t.options.legendType ? t.points.forEach(function (t) {
            i(t);
          }) : i(t));
        }));
      }), r = s.length; r--;) t.allItems.unshift(s[r]);
    }
    function o(t) {
      t.visible && t.item.legendColor && t.item.legendItem.symbol.attr({
        fill: t.item.legendColor
      });
    }
    function r(t) {
      this.chart.colorAxis?.forEach(e => {
        e.update({}, t.redraw);
      });
    }
    function a() {
      (this.chart.colorAxis && this.chart.colorAxis.length || this.colorAttribs) && this.translateColors();
    }
    function n() {
      let t = this.axisTypes;
      t ? -1 === t.indexOf("colorAxis") && t.push("colorAxis") : this.axisTypes = ["colorAxis"];
    }
    function l(t) {
      let e = this,
        i = t ? "show" : "hide";
      e.visible = e.options.visible = !!t, ["graphic", "dataLabel"].forEach(function (t) {
        e[t] && e[t][i]();
      }), this.series.buildKDTree();
    }
    function h() {
      let t = this,
        e = this.getPointsCollection(),
        i = this.options.nullColor,
        s = this.colorAxis,
        o = this.colorKey;
      e.forEach(e => {
        let r = e.getNestedProperty(o),
          a = e.options.color || (e.isNull || null === e.value ? i : s && void 0 !== r ? s.toColor(r, e) : e.color || t.color);
        a && e.color !== a && (e.color = a, "point" === t.options.legendType && e.legendItem && e.legendItem.label && t.chart.legend.colorizeItem(e, e.visible));
      });
    }
    function d() {
      this.elem.attr("fill", hP(this.start).tweenTo(hP(this.end), this.pos), void 0, !0);
    }
    function c() {
      this.elem.attr("stroke", hP(this.start).tweenTo(hP(this.end), this.pos), void 0, !0);
    }
    t.compose = function (t, p, u, g, m) {
      let f = p.prototype,
        x = u.prototype,
        y = m.prototype;
      f.collectionsWithUpdate.includes("colorAxis") || (e = t, f.collectionsWithUpdate.push("colorAxis"), f.collectionsWithInit.colorAxis = [f.addColorAxis], hL(p, "afterCreateAxes", i), function (t) {
        let i = t.prototype.createAxis;
        t.prototype.createAxis = function (t, s) {
          if ("colorAxis" !== t) return i.apply(this, arguments);
          let o = new e(this, hE(s.axis, {
            index: this[t].length,
            isX: !1
          }));
          return this.isDirtyLegend = !0, this.axes.forEach(t => {
            t.series = [];
          }), this.series.forEach(t => {
            t.bindAxes(), t.isDirtyData = !0;
          }), hI(s.redraw, !0) && this.redraw(s.animation), o;
        };
      }(p), x.fillSetter = d, x.strokeSetter = c, hL(g, "afterGetAllItems", s), hL(g, "afterColorizeItem", o), hL(g, "afterUpdate", r), hO(y, {
        optionalAxis: "colorAxis",
        translateColors: h
      }), hO(y.pointClass.prototype, {
        setVisible: l
      }), hL(m, "afterTranslate", a, {
        order: 1
      }), hL(m, "bindAxes", n));
    }, t.pointSetVisible = l;
  }(v || (v = {}));
  let hB = v,
    {
      parse: hz
    } = tA,
    {
      merge: hN
    } = J;
  !function (t) {
    t.initDataClasses = function (t) {
      let e = this.chart,
        i = this.legendItem = this.legendItem || {},
        s = this.options,
        o = t.dataClasses || [],
        r,
        a,
        n = e.options.chart.colorCount,
        l = 0,
        h;
      this.dataClasses = a = [], i.labels = [];
      for (let t = 0, i = o.length; t < i; ++t) r = hN(r = o[t]), a.push(r), (e.styledMode || !r.color) && ("category" === s.dataClassColor ? (e.styledMode || (n = (h = e.options.colors || []).length, r.color = h[l]), r.colorIndex = l, ++l === n && (l = 0)) : r.color = hz(s.minColor).tweenTo(hz(s.maxColor), i < 2 ? .5 : t / (i - 1)));
    }, t.initStops = function () {
      let t = this.options,
        e = this.stops = t.stops || [[0, t.minColor || ""], [1, t.maxColor || ""]];
      for (let t = 0, i = e.length; t < i; ++t) e[t].color = hz(e[t][1]);
    }, t.normalizedValue = function (t) {
      let e = this.max || 0,
        i = this.min || 0;
      return this.logarithmic && (t = this.logarithmic.log2lin(t)), 1 - (e - t) / (e - i || 1);
    }, t.toColor = function (t, e) {
      let i, s, o, r, a, n;
      let l = this.dataClasses,
        h = this.stops;
      if (l) {
        for (n = l.length; n--;) if (s = (a = l[n]).from, o = a.to, (void 0 === s || t >= s) && (void 0 === o || t <= o)) {
          r = a.color, e && (e.dataClass = n, e.colorIndex = a.colorIndex);
          break;
        }
      } else {
        for (i = this.normalizedValue(t), n = h.length; n-- && !(i > h[n][0]););
        s = h[n] || h[n + 1], i = 1 - ((o = h[n + 1] || s)[0] - i) / (o[0] - s[0] || 1), r = s.color.tweenTo(o.color, i);
      }
      return r;
    };
  }(M || (M = {}));
  let hR = M,
    {
      defaultOptions: hW
    } = tw,
    {
      series: hj
    } = rx,
    {
      defined: hX,
      extend: hG,
      fireEvent: hF,
      isArray: hH,
      isNumber: hY,
      merge: hV,
      pick: hU,
      relativeLength: hZ
    } = J;
  hW.colorAxis = hV(hW.xAxis, {
    lineWidth: 0,
    minPadding: 0,
    maxPadding: 0,
    gridLineColor: "#ffffff",
    gridLineWidth: 1,
    tickPixelInterval: 72,
    startOnTick: !0,
    endOnTick: !0,
    offset: 0,
    marker: {
      animation: {
        duration: 50
      },
      width: .01,
      color: "#999999"
    },
    labels: {
      distance: 8,
      overflow: "justify",
      rotation: 0
    },
    minColor: "#e6e9ff",
    maxColor: "#0022ff",
    tickLength: 5,
    showInLegend: !0
  });
  class h$ extends sY {
    static compose(t, e, i, s) {
      hB.compose(h$, t, e, i, s);
    }
    constructor(t, e) {
      super(t, e), this.coll = "colorAxis", this.visible = !0, this.init(t, e);
    }
    init(t, e) {
      let i = t.options.legend || {},
        s = e.layout ? "vertical" !== e.layout : "vertical" !== i.layout;
      this.side = e.side || s ? 2 : 1, this.reversed = e.reversed || !s, this.opposite = !s, super.init(t, e, "colorAxis"), this.userOptions = e, hH(t.userOptions.colorAxis) && (t.userOptions.colorAxis[this.index] = e), e.dataClasses && this.initDataClasses(e), this.initStops(), this.horiz = s, this.zoomEnabled = !1;
    }
    hasData() {
      return !!(this.tickPositions || []).length;
    }
    setTickPositions() {
      if (!this.dataClasses) return super.setTickPositions();
    }
    setOptions(t) {
      let e = hV(hW.colorAxis, t, {
        showEmpty: !1,
        title: null,
        visible: this.chart.options.legend.enabled && !1 !== t.visible
      });
      super.setOptions(e), this.options.crosshair = this.options.marker;
    }
    setAxisSize() {
      let t = this.chart,
        e = this.legendItem?.symbol,
        {
          width: i,
          height: s
        } = this.getSize();
      e && (this.left = +e.attr("x"), this.top = +e.attr("y"), this.width = i = +e.attr("width"), this.height = s = +e.attr("height"), this.right = t.chartWidth - this.left - i, this.bottom = t.chartHeight - this.top - s, this.pos = this.horiz ? this.left : this.top), this.len = (this.horiz ? i : s) || h$.defaultLegendLength;
    }
    getOffset() {
      let t = this.legendItem?.group,
        e = this.chart.axisOffset[this.side];
      if (t) {
        this.axisParent = t, super.getOffset();
        let i = this.chart.legend;
        i.allItems.forEach(function (t) {
          t instanceof h$ && t.drawLegendSymbol(i, t);
        }), i.render(), this.chart.getMargins(!0), this.chart.series.some(t => t.isDrilling) || (this.isDirty = !0), this.added || (this.added = !0, this.labelLeft = 0, this.labelRight = this.width), this.chart.axisOffset[this.side] = e;
      }
    }
    setLegendColor() {
      let t = this.horiz,
        e = this.reversed,
        i = e ? 1 : 0,
        s = e ? 0 : 1,
        o = t ? [i, 0, s, 0] : [0, s, 0, i];
      this.legendColor = {
        linearGradient: {
          x1: o[0],
          y1: o[1],
          x2: o[2],
          y2: o[3]
        },
        stops: this.stops
      };
    }
    drawLegendSymbol(t, e) {
      let i = e.legendItem || {},
        s = t.padding,
        o = t.options,
        r = this.options.labels,
        a = hU(o.itemDistance, 10),
        n = this.horiz,
        {
          width: l,
          height: h
        } = this.getSize(),
        d = hU(o.labelPadding, n ? 16 : 30);
      this.setLegendColor(), i.symbol || (i.symbol = this.chart.renderer.symbol("roundedRect").attr({
        r: o.symbolRadius ?? 3,
        zIndex: 1
      }).add(i.group)), i.symbol.attr({
        x: 0,
        y: (t.baseline || 0) - 11,
        width: l,
        height: h
      }), i.labelWidth = l + s + (n ? a : hU(r.x, r.distance) + (this.maxLabelLength || 0)), i.labelHeight = h + s + (n ? d : 0);
    }
    setState(t) {
      this.series.forEach(function (e) {
        e.setState(t);
      });
    }
    setVisible() {}
    getSeriesExtremes() {
      let t = this.series,
        e,
        i,
        s,
        o,
        r = t.length;
      for (this.dataMin = 1 / 0, this.dataMax = -1 / 0; r--;) {
        for (let a of (i = (o = t[r]).colorKey = hU(o.options.colorKey, o.colorKey, o.pointValKey, o.zoneAxis, "y"), s = o[i + "Min"] && o[i + "Max"], [i, "value", "y"])) if ((e = o.getColumn(a)).length) break;
        if (s) o.minColorValue = o[i + "Min"], o.maxColorValue = o[i + "Max"];else {
          let t = hj.prototype.getExtremes.call(o, e);
          o.minColorValue = t.dataMin, o.maxColorValue = t.dataMax;
        }
        hX(o.minColorValue) && hX(o.maxColorValue) && (this.dataMin = Math.min(this.dataMin, o.minColorValue), this.dataMax = Math.max(this.dataMax, o.maxColorValue)), s || hj.prototype.applyExtremes.call(o);
      }
    }
    drawCrosshair(t, e) {
      let i;
      let s = this.legendItem || {},
        o = e && e.plotX,
        r = e && e.plotY,
        a = this.pos,
        n = this.len;
      e && ((i = this.toPixels(e.getNestedProperty(e.series.colorKey))) < a ? i = a - 2 : i > a + n && (i = a + n + 2), e.plotX = i, e.plotY = this.len - i, super.drawCrosshair(t, e), e.plotX = o, e.plotY = r, this.cross && !this.cross.addedToColorAxis && s.group && (this.cross.addClass("highcharts-coloraxis-marker").add(s.group), this.cross.addedToColorAxis = !0, this.chart.styledMode || "object" != typeof this.crosshair || this.cross.attr({
        fill: this.crosshair.color
      })));
    }
    getPlotLinePath(t) {
      let e = this.left,
        i = t.translatedValue,
        s = this.top;
      return hY(i) ? this.horiz ? [["M", i - 4, s - 6], ["L", i + 4, s - 6], ["L", i, s], ["Z"]] : [["M", e, i], ["L", e - 6, i + 6], ["L", e - 6, i - 6], ["Z"]] : super.getPlotLinePath(t);
    }
    update(t, e) {
      let i = this.chart.legend;
      this.series.forEach(t => {
        t.isDirtyData = !0;
      }), (t.dataClasses && i.allItems || this.dataClasses) && this.destroyItems(), super.update(t, e), this.legendItem && this.legendItem.label && (this.setLegendColor(), i.colorizeItem(this, !0));
    }
    destroyItems() {
      let t = this.chart,
        e = this.legendItem || {};
      if (e.label) t.legend.destroyItem(this);else if (e.labels) for (let i of e.labels) t.legend.destroyItem(i);
      t.isDirtyLegend = !0;
    }
    destroy() {
      this.chart.isDirtyLegend = !0, this.destroyItems(), super.destroy(...[].slice.call(arguments));
    }
    remove(t) {
      this.destroyItems(), super.remove(t);
    }
    getDataClassLegendSymbols() {
      let t;
      let e = this,
        i = e.chart,
        s = e.legendItem && e.legendItem.labels || [],
        o = i.options.legend,
        r = hU(o.valueDecimals, -1),
        a = hU(o.valueSuffix, ""),
        n = t => e.series.reduce((e, i) => (e.push(...i.points.filter(e => e.dataClass === t)), e), []);
      return s.length || e.dataClasses.forEach((o, l) => {
        let h = o.from,
          d = o.to,
          {
            numberFormatter: c
          } = i,
          p = !0;
        t = "", void 0 === h ? t = "< " : void 0 === d && (t = "> "), void 0 !== h && (t += c(h, r) + a), void 0 !== h && void 0 !== d && (t += " - "), void 0 !== d && (t += c(d, r) + a), s.push(hG({
          chart: i,
          name: t,
          options: {},
          drawLegendSymbol: rp.rectangle,
          visible: !0,
          isDataClass: !0,
          setState: t => {
            for (let e of n(l)) e.setState(t);
          },
          setVisible: function () {
            this.visible = p = e.visible = !p;
            let t = [];
            for (let e of n(l)) e.setVisible(p), e.hiddenInDataClass = !p, -1 === t.indexOf(e.series) && t.push(e.series);
            i.legend.colorizeItem(this, p), t.forEach(t => {
              hF(t, "afterDataClassLegendClick");
            });
          }
        }, o));
      }), s;
    }
    getSize() {
      let {
          chart: t,
          horiz: e
        } = this,
        {
          height: i,
          width: s
        } = this.options,
        {
          legend: o
        } = t.options;
      return {
        width: hU(hX(s) ? hZ(s, t.chartWidth) : void 0, o?.symbolWidth, e ? h$.defaultLegendLength : 12),
        height: hU(hX(i) ? hZ(i, t.chartHeight) : void 0, o?.symbolHeight, e ? 12 : h$.defaultLegendLength)
      };
    }
  }
  h$.defaultLegendLength = 200, h$.keepProps = ["legendItem"], hG(h$.prototype, hR), Array.prototype.push.apply(sY.keepProps, h$.keepProps), A.ColorAxis = A.ColorAxis || h$, A.ColorAxis.compose(A.Chart, A.Fx, A.Legend, A.Series);
  let h_ = {
      lang: {
        zoomIn: "Zoom in",
        zoomOut: "Zoom out"
      },
      mapNavigation: {
        buttonOptions: {
          alignTo: "plotBox",
          align: "left",
          verticalAlign: "top",
          x: 0,
          width: 18,
          height: 18,
          padding: 5,
          style: {
            color: "#666666",
            fontSize: "1em",
            fontWeight: "bold"
          },
          theme: {
            fill: "#ffffff",
            stroke: "#e6e6e6",
            "stroke-width": 1,
            "text-align": "center"
          }
        },
        buttons: {
          zoomIn: {
            onclick: function () {
              this.mapZoom(.5);
            },
            text: "+",
            y: 0
          },
          zoomOut: {
            onclick: function () {
              this.mapZoom(2);
            },
            text: "-",
            y: 28
          }
        },
        mouseWheelSensitivity: 1.1
      }
    },
    {
      defined: hq,
      extend: hK,
      pick: hJ,
      wrap: hQ
    } = J;
  !function (t) {
    let e,
      i = 0;
    function s(t) {
      let e = this.chart;
      t = this.normalize(t), e.options.mapNavigation.enableDoubleClickZoomTo ? e.pointer.inClass(t.target, "highcharts-tracker") && e.hoverPoint && e.hoverPoint.zoomTo() : e.isInsidePlot(t.chartX - e.plotLeft, t.chartY - e.plotTop) && e.mapZoom(.5, void 0, void 0, t.chartX, t.chartY);
    }
    function o(t) {
      let s = this.chart,
        o = hq((t = this.normalize(t)).wheelDelta) && -t.wheelDelta / 120 || t.deltaY || t.detail;
      Math.abs(o) >= 1 && (i += Math.abs(o), e && clearTimeout(e), e = setTimeout(() => {
        i = 0;
      }, 50)), i < 10 && s.isInsidePlot(t.chartX - s.plotLeft, t.chartY - s.plotTop) && s.mapView && s.mapView.zoomBy(-((s.options.mapNavigation.mouseWheelSensitivity - 1) * o), void 0, [t.chartX, t.chartY], !(1 > Math.abs(o)) && void 0);
    }
    function r(t, e, i) {
      let s = this.chart;
      if (e = t.call(this, e, i), s && s.mapView) {
        let t = s.mapView.pixelsToLonLat({
          x: e.chartX - s.plotLeft,
          y: e.chartY - s.plotTop
        });
        t && hK(e, t);
      }
      return e;
    }
    function a(t) {
      let e = this.chart.options.mapNavigation;
      e && hJ(e.enableTouchZoom, e.enabled) && (this.chart.zooming.pinchType = "xy"), t.apply(this, [].slice.call(arguments, 1));
    }
    t.compose = function (t) {
      let e = t.prototype;
      e.onContainerDblClick || (hK(e, {
        onContainerDblClick: s,
        onContainerMouseWheel: o
      }), hQ(e, "normalize", r), hQ(e, "zoomOption", a));
    };
  }(w || (w = {}));
  let h0 = w;
  function h1(t, e, s, o, r) {
    if (r) {
      let t = r?.r || 0;
      r.brBoxY = e - t, r.brBoxHeight = o + t;
    }
    return i.roundedRect(t, e, s, o, r);
  }
  function h2(t, e, s, o, r) {
    if (r) {
      let t = r?.r || 0;
      r.brBoxHeight = o + t;
    }
    return i.roundedRect(t, e, s, o, r);
  }
  let h3 = {
      compose: function (t) {
        (i = t.prototype.symbols).bottombutton = h1, i.topbutton = h2;
      }
    },
    {
      setOptions: h6
    } = tw,
    {
      composed: h5
    } = A,
    {
      addEvent: h9,
      extend: h8,
      merge: h4,
      objectEach: h7,
      pick: dt,
      pushUnique: de
    } = J;
  function di(t) {
    t && (t.preventDefault?.(), t.stopPropagation?.(), t.cancelBubble = !0);
  }
  class ds {
    static compose(t, e, i) {
      h0.compose(e), h3.compose(i), de(h5, "Map.Navigation") && (h9(t, "beforeRender", function () {
        this.mapNavigation = new ds(this), this.mapNavigation.update();
      }), h6(h_));
    }
    constructor(t) {
      this.chart = t, this.navButtons = [];
    }
    update(t) {
      let e = this,
        i = e.chart,
        s = e.navButtons,
        o = function (t) {
          this.handler.call(i, t), di(t);
        },
        r = i.options.mapNavigation;
      for (t && (r = i.options.mapNavigation = h4(i.options.mapNavigation, t)); s.length;) s.pop()?.destroy();
      if (!i.renderer.forExport && dt(r.enableButtons, r.enabled)) {
        e.navButtonsGroup || (e.navButtonsGroup = i.renderer.g().attr({
          zIndex: 7
        }).add()), h7(r.buttons, (t, a) => {
          let n = {
            padding: (t = h4(r.buttonOptions, t)).padding
          };
          !i.styledMode && t.theme && (h8(n, t.theme), n.style = h4(t.theme.style, t.style));
          let {
              text: l,
              width: h = 0,
              height: d = 0,
              padding: c = 0
            } = t,
            p = i.renderer.button("+" !== l && "-" !== l && l || "", 0, 0, o, n, void 0, void 0, void 0, "zoomIn" === a ? "topbutton" : "bottombutton").addClass("highcharts-map-navigation highcharts-" + {
              zoomIn: "zoom-in",
              zoomOut: "zoom-out"
            }[a]).attr({
              width: h,
              height: d,
              title: i.options.lang[a],
              zIndex: 5
            }).add(e.navButtonsGroup);
          if ("+" === l || "-" === l) {
            let e = h + 1,
              s = [["M", c + 3, c + d / 2], ["L", c + e - 3, c + d / 2]];
            "+" === l && s.push(["M", c + e / 2, c + 3], ["L", c + e / 2, c + d - 3]), i.renderer.path(s).addClass("highcharts-button-symbol").attr(i.styledMode ? {} : {
              stroke: t.style?.color,
              "stroke-width": 3,
              "stroke-linecap": "round"
            }).add(p);
          }
          if (p.handler = t.onclick, h9(p.element, "dblclick", di), s.push(p), h8(t, {
            width: p.width,
            height: 2 * (p.height || 0)
          }), i.hasLoaded) p.align(t, !1, t.alignTo);else {
            let e = h9(i, "load", () => {
              p.element && p.align(t, !1, t.alignTo), e();
            });
          }
        });
        let t = (t, e) => !(e.x >= t.x + t.width || e.x + e.width <= t.x || e.y >= t.y + t.height || e.y + e.height <= t.y);
        i.hasLoaded || h9(i, "render", function () {
          let s = i.exportingGroup?.getBBox();
          if (s) {
            let i = e.navButtonsGroup.getBBox();
            if (t(s, i)) {
              let t = -i.y - i.height + s.y - 5,
                o = s.y + s.height - i.y + 5,
                a = r.buttonOptions && r.buttonOptions.verticalAlign;
              e.navButtonsGroup.attr({
                translateY: "bottom" === a ? t : o
              });
            }
          }
        });
      }
      this.updateEvents(r);
    }
    updateEvents(t) {
      let e = this.chart;
      dt(t.enableDoubleClickZoom, t.enabled) || t.enableDoubleClickZoomTo ? this.unbindDblClick = this.unbindDblClick || h9(e.container, "dblclick", function (t) {
        e.pointer.onContainerDblClick(t);
      }) : this.unbindDblClick && (this.unbindDblClick = this.unbindDblClick()), dt(t.enableMouseWheelZoom, t.enabled) ? this.unbindMouseWheel = this.unbindMouseWheel || h9(e.container, "wheel", function (t) {
        if (!e.pointer.inClass(t.target, "highcharts-no-mousewheel")) {
          let i = e.mapView?.zoom;
          e.pointer.onContainerMouseWheel(t), i !== e.mapView?.zoom && di(t);
        }
        return !1;
      }) : this.unbindMouseWheel && (this.unbindMouseWheel = this.unbindMouseWheel());
    }
  }
  let {
      column: {
        prototype: dr
      }
    } = rx.seriesTypes,
    {
      addEvent: da,
      defined: dn
    } = J;
  !function (t) {
    function e(t) {
      let e = this.series,
        i = e.chart.renderer;
      this.moveToTopOnHover && this.graphic && (e.stateMarkerGraphic || (e.stateMarkerGraphic = new eq(i, "use").css({
        pointerEvents: "none"
      }).add(this.graphic.parentGroup)), t?.state === "hover" ? (this.graphic.attr({
        id: this.id
      }), e.stateMarkerGraphic.attr({
        href: `${i.url}#${this.id}`,
        visibility: "visible"
      })) : e.stateMarkerGraphic.attr({
        href: ""
      }));
    }
    t.pointMembers = {
      dataLabelOnNull: !0,
      moveToTopOnHover: !0,
      isValid: function () {
        return null !== this.value && this.value !== 1 / 0 && this.value !== -1 / 0 && (void 0 === this.value || !isNaN(this.value));
      }
    }, t.seriesMembers = {
      colorKey: "value",
      axisTypes: ["xAxis", "yAxis", "colorAxis"],
      parallelArrays: ["x", "y", "value"],
      pointArrayMap: ["value"],
      trackerGroups: ["group", "markerGroup", "dataLabelsGroup"],
      colorAttribs: function (t) {
        let e = {};
        return dn(t.color) && (!t.state || "normal" === t.state) && (e[this.colorProp || "fill"] = t.color), e;
      },
      pointAttribs: dr.pointAttribs
    }, t.compose = function (t) {
      return da(t.prototype.pointClass, "afterSetState", e), t;
    };
  }(k || (k = {}));
  let dl = k,
    {
      getOptions: dh
    } = tw,
    {
      isNumber: dd,
      merge: dc,
      pick: dp
    } = J;
  class du extends aq {
    init(t, e) {
      let i = dh().credits,
        s = dc({
          chart: {
            panning: {
              enabled: !0,
              type: "xy"
            },
            type: "map"
          },
          credits: {
            mapText: dp(i.mapText, ' \xa9 <a href="{geojson.copyrightUrl}">{geojson.copyrightShort}</a>'),
            mapTextFull: dp(i.mapTextFull, "{geojson.copyright}")
          },
          mapView: {},
          tooltip: {
            followTouchMove: !1
          }
        }, t);
      super.init(s, e);
    }
    mapZoom(t, e, i, s, o) {
      this.mapView && (dd(t) && (t = Math.log(t) / Math.log(.5)), this.mapView.zoomBy(t, dd(e) && dd(i) ? this.mapView.projection.inverse([e, i]) : void 0, dd(s) && dd(o) ? [s, o] : void 0));
    }
    update(t) {
      t.chart && "map" in t.chart && this.mapView?.recommendMapView(this, [t.chart.map, ...(this.options.series || []).map(t => t.mapData)], !0), super.update.apply(this, arguments);
    }
  }
  !function (t) {
    t.maps = {}, t.mapChart = function (e, i, s) {
      return new t(e, i, s);
    }, t.splitPath = function (t) {
      let e;
      return e = "string" == typeof t ? (t = t.replace(/([A-Z])/gi, " $1 ").replace(/^\s*/, "").replace(/\s*$/, "")).split(/[ ,;]+/).map(t => /[A-Z]/i.test(t) ? t : parseFloat(t)) : t, iH.prototype.pathToSegments(e);
    };
  }(du || (du = {}));
  let dg = du,
    dm = {
      boundsFromPath: function (t) {
        let e = -Number.MAX_VALUE,
          i = Number.MAX_VALUE,
          s = -Number.MAX_VALUE,
          o = Number.MAX_VALUE,
          r;
        if (t.forEach(t => {
          let a = t[t.length - 2],
            n = t[t.length - 1];
          "number" == typeof a && "number" == typeof n && (i = Math.min(i, a), e = Math.max(e, a), o = Math.min(o, n), s = Math.max(s, n), r = !0);
        }), r) return {
          x1: i,
          y1: o,
          x2: e,
          y2: s
        };
      }
    },
    {
      boundsFromPath: df
    } = dm,
    dx = rx.seriesTypes.scatter.prototype.pointClass,
    {
      extend: dy,
      isNumber: db,
      pick: dv
    } = J;
  class dM extends dx {
    static getProjectedPath(t, e) {
      return t.projectedPath || (e && t.geometry ? (e.hasCoordinates = !0, t.projectedPath = e.path(t.geometry)) : t.projectedPath = t.path), t.projectedPath || [];
    }
    applyOptions(t, e) {
      let i = this.series,
        s = super.applyOptions(t, e),
        o = i.joinBy;
      if (i.mapData && i.mapMap) {
        let t = o[1],
          e = super.getNestedProperty(t),
          r = void 0 !== e && i.mapMap[e];
        r ? dy(s, {
          ...r,
          name: s.name ?? r.name
        }) : -1 !== i.pointArrayMap.indexOf("value") && (s.value = s.value || null);
      }
      return s;
    }
    getProjectedBounds(t) {
      let e = df(dM.getProjectedPath(this, t)),
        i = this.properties,
        s = this.series.chart.mapView;
      if (e) {
        let o = i && i["hc-middle-lon"],
          r = i && i["hc-middle-lat"];
        if (s && db(o) && db(r)) {
          let i = t.forward([o, r]);
          e.midX = i[0], e.midY = i[1];
        } else {
          let t = i && i["hc-middle-x"],
            s = i && i["hc-middle-y"];
          e.midX = e.x1 + (e.x2 - e.x1) * dv(this.middleX, db(t) ? t : .5);
          let o = dv(this.middleY, db(s) ? s : .5);
          this.geometry || (o = 1 - o), e.midY = e.y2 - (e.y2 - e.y1) * o;
        }
        return e;
      }
    }
    onMouseOver(t) {
      J.clearTimeout(this.colorInterval), !this.isNull && this.visible || this.series.options.nullInteraction ? super.onMouseOver.call(this, t) : this.series.onMouseOut();
    }
    setVisible(t) {
      this.visible = this.options.visible = !!t, this.dataLabel && this.dataLabel[t ? "show" : "hide"](), this.graphic && this.graphic.attr(this.series.pointAttribs(this));
    }
    zoomTo(t) {
      let e = this.series.chart,
        i = e.mapView,
        s = this.bounds;
      if (i && s) {
        let o = db(this.insetIndex) && i.insets[this.insetIndex];
        if (o) {
          let t = o.projectedUnitsToPixels({
              x: s.x1,
              y: s.y1
            }),
            e = o.projectedUnitsToPixels({
              x: s.x2,
              y: s.y2
            }),
            r = i.pixelsToProjectedUnits({
              x: t.x,
              y: t.y
            }),
            a = i.pixelsToProjectedUnits({
              x: e.x,
              y: e.y
            });
          s = {
            x1: r.x,
            y1: r.y,
            x2: a.x,
            y2: a.y
          };
        }
        i.fitToBounds(s, void 0, !1), this.series.isDirty = !0, e.redraw(t);
      }
    }
  }
  dy(dM.prototype, {
    dataLabelOnNull: dl.pointMembers.dataLabelOnNull,
    moveToTopOnHover: dl.pointMembers.moveToTopOnHover,
    isValid: dl.pointMembers.isValid
  });
  let {
      isNumber: dw
    } = J,
    dk = {
      center: [0, 0],
      fitToGeometry: void 0,
      maxZoom: void 0,
      padding: 0,
      projection: {
        name: void 0,
        parallels: void 0,
        rotation: void 0
      },
      zoom: void 0,
      insetOptions: {
        borderColor: "#cccccc",
        borderWidth: 1,
        padding: "10%",
        relativeTo: "mapBoundingBox",
        units: "percent"
      }
    },
    {
      win: dS
    } = A,
    {
      format: dT
    } = ed,
    {
      error: dC,
      extend: dA,
      merge: dP,
      wrap: dL
    } = J;
  !function (t) {
    function e(t) {
      return this.mapView && this.mapView.lonLatToProjectedUnits(t);
    }
    function i(t) {
      return this.mapView && this.mapView.projectedUnitsToLonLat(t);
    }
    function s(t, e) {
      let i = this.options.chart.proj4 || dS.proj4;
      if (!i) {
        dC(21, !1, this);
        return;
      }
      let {
          jsonmarginX: s = 0,
          jsonmarginY: o = 0,
          jsonres: r = 1,
          scale: a = 1,
          xoffset: n = 0,
          xpan: l = 0,
          yoffset: h = 0,
          ypan: d = 0
        } = e,
        c = i(e.crs, [t.lon, t.lat]),
        p = e.cosAngle || e.rotation && Math.cos(e.rotation),
        u = e.sinAngle || e.rotation && Math.sin(e.rotation),
        g = e.rotation ? [c[0] * p + c[1] * u, -c[0] * u + c[1] * p] : c;
      return {
        x: ((g[0] - n) * a + l) * r + s,
        y: -(((h - g[1]) * a + d) * r - o)
      };
    }
    function o(t, e) {
      let i = this.options.chart.proj4 || dS.proj4;
      if (!i) {
        dC(21, !1, this);
        return;
      }
      if (null === t.y) return;
      let {
          jsonmarginX: s = 0,
          jsonmarginY: o = 0,
          jsonres: r = 1,
          scale: a = 1,
          xoffset: n = 0,
          xpan: l = 0,
          yoffset: h = 0,
          ypan: d = 0
        } = e,
        c = {
          x: ((t.x - s) / r - l) / a + n,
          y: ((t.y - o) / r + d) / a + h
        },
        p = e.cosAngle || e.rotation && Math.cos(e.rotation),
        u = e.sinAngle || e.rotation && Math.sin(e.rotation),
        g = i(e.crs, "WGS84", e.rotation ? {
          x: c.x * p + -(c.y * u),
          y: c.x * u + c.y * p
        } : c);
      return {
        lat: g.y,
        lon: g.x
      };
    }
    function r(t, e) {
      e || (e = Object.keys(t.objects)[0]);
      let i = t.objects[e];
      if (i["hc-decoded-geojson"] && i["hc-decoded-geojson"].title === t.title) return i["hc-decoded-geojson"];
      let s = t.arcs;
      if (t.transform) {
        let e, i, o;
        let r = t.arcs,
          {
            scale: a,
            translate: n
          } = t.transform;
        s = [];
        for (let t = 0, l = r.length; t < l; ++t) {
          let l = r[t];
          s.push(e = []), i = 0, o = 0;
          for (let t = 0, s = l.length; t < s; ++t) e.push([(i += l[t][0]) * a[0] + n[0], (o += l[t][1]) * a[1] + n[1]]);
        }
      }
      let o = t => "number" == typeof t[0] ? t.reduce((t, e, i) => {
          let o = e < 0 ? s[~e] : s[e];
          return e < 0 ? (o = o.slice(0, 0 === i ? o.length : o.length - 1)).reverse() : i && (o = o.slice(1)), t.concat(o);
        }, []) : t.map(o),
        r = i.geometries,
        a = [];
      for (let t = 0, e = r.length; t < e; ++t) a.push({
        type: "Feature",
        properties: r[t].properties,
        geometry: {
          type: r[t].type,
          coordinates: r[t].coordinates || o(r[t].arcs)
        }
      });
      let n = {
        type: "FeatureCollection",
        copyright: t.copyright,
        copyrightShort: t.copyrightShort,
        copyrightUrl: t.copyrightUrl,
        features: a,
        "hc-recommended-mapview": i["hc-recommended-mapview"],
        bbox: t.bbox,
        title: t.title
      };
      return i["hc-decoded-geojson"] = n, n;
    }
    function a(t, e) {
      e = dP(!0, this.options.credits, e), t.call(this, e), this.credits && this.mapCreditsFull && this.credits.attr({
        title: this.mapCreditsFull
      });
    }
    t.compose = function (t) {
      let r = t.prototype;
      r.transformFromLatLon || (r.fromLatLonToPoint = e, r.fromPointToLatLon = i, r.transformFromLatLon = s, r.transformToLatLon = o, dL(r, "addCredits", a));
    }, t.geojson = function (t, e = "map", i) {
      let s = [],
        o = "Topology" === t.type ? r(t) : t,
        a = o.features;
      for (let t = 0, i = a.length; t < i; ++t) {
        let i;
        let o = a[t],
          r = o.geometry || {},
          n = r.type,
          l = r.coordinates,
          h = o.properties;
        if (("map" === e || "mapbubble" === e) && ("Polygon" === n || "MultiPolygon" === n) ? l.length && (i = {
          geometry: {
            coordinates: l,
            type: n
          }
        }) : "mapline" === e && ("LineString" === n || "MultiLineString" === n) ? l.length && (i = {
          geometry: {
            coordinates: l,
            type: n
          }
        }) : "mappoint" === e && "Point" === n && l.length && (i = {
          geometry: {
            coordinates: l,
            type: n
          }
        }), i) {
          let t = h && (h.name || h.NAME),
            e = h && h.lon,
            o = h && h.lat;
          s.push(dA(i, {
            lat: "number" == typeof o ? o : void 0,
            lon: "number" == typeof e ? e : void 0,
            name: "string" == typeof t ? t : void 0,
            properties: h
          }));
        }
      }
      return i && o.copyrightShort && (i.chart.mapCredits = dT(i.chart.options.credits?.mapText, {
        geojson: o
      }), i.chart.mapCreditsFull = dT(i.chart.options.credits?.mapTextFull, {
        geojson: o
      })), s;
    }, t.topo2geo = r;
  }(S || (S = {}));
  let dO = S;
  function dE(t, e, i = !0) {
    let s = e[e.length - 1],
      o,
      r,
      a,
      n = t;
    for (let t = 0; t < e.length; t++) {
      let l = n;
      o = e[t], n = [], r = i ? l[l.length - 1] : l[0];
      for (let t = 0; t < l.length; t++) dI(s, o, a = l[t]) ? (dI(s, o, r) || n.push(dD(s, o, r, a)), n.push(a)) : dI(s, o, r) && n.push(dD(s, o, r, a)), r = a;
      s = o;
    }
    return n;
  }
  function dI(t, e, i) {
    return (e[0] - t[0]) * (i[1] - t[1]) > (e[1] - t[1]) * (i[0] - t[0]);
  }
  function dD(t, e, i, s) {
    let o = [t[0] - e[0], t[1] - e[1]],
      r = [i[0] - s[0], i[1] - s[1]],
      a = t[0] * e[1] - t[1] * e[0],
      n = i[0] * s[1] - i[1] * s[0],
      l = 1 / (o[0] * r[1] - o[1] * r[0]),
      h = [(a * r[0] - n * o[0]) * l, (a * r[1] - n * o[1]) * l];
    return h.isIntersection = !0, h;
  }
  let dB = Math.sign || (t => 0 === t ? 0 : t > 0 ? 1 : -1),
    dz = Math.PI / 180,
    dN = Math.PI / 2,
    dR = t => Math.tan((dN + t) / 2),
    dW = class {
      constructor(t) {
        let e = (t.parallels || []).map(t => t * dz),
          i = e[0] || 0,
          s = e[1] ?? i,
          o = Math.cos(i);
        "object" == typeof t.projectedBounds && (this.projectedBounds = t.projectedBounds);
        let r = i === s ? Math.sin(i) : Math.log(o / Math.cos(s)) / Math.log(dR(s) / dR(i));
        1e-10 > Math.abs(r) && (r = 1e-10 * (dB(r) || 1)), this.n = r, this.c = o * Math.pow(dR(i), r) / r;
      }
      forward(t) {
        let {
            c: e,
            n: i,
            projectedBounds: s
          } = this,
          o = t[0] * dz,
          r = t[1] * dz;
        e > 0 ? r < -dN + 1e-6 && (r = -dN + 1e-6) : r > dN - 1e-6 && (r = dN - 1e-6);
        let a = e / Math.pow(dR(r), i),
          n = a * Math.sin(i * o) * 63.78137,
          l = (e - a * Math.cos(i * o)) * 63.78137,
          h = [n, l];
        return s && (n < s.x1 || n > s.x2 || l < s.y1 || l > s.y2) && (h.outside = !0), h;
      }
      inverse(t) {
        let {
            c: e,
            n: i
          } = this,
          s = t[0] / 63.78137,
          o = e - t[1] / 63.78137,
          r = dB(i) * Math.sqrt(s * s + o * o),
          a = Math.atan2(s, Math.abs(o)) * dB(o);
        return o * i < 0 && (a -= Math.PI * dB(s) * dB(o)), [a / i / dz, (2 * Math.atan(Math.pow(e / r, 1 / i)) - dN) / dz];
      }
    },
    dj = Math.sqrt(3) / 2,
    dX = class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -97.52595454902263,
          y2: 97.52595454902263
        };
      }
      forward(t) {
        let e = Math.PI / 180,
          i = Math.asin(dj * Math.sin(t[1] * e)),
          s = i * i,
          o = s * s * s;
        return [t[0] * e * Math.cos(i) * 74.03120656864502 / (dj * (1.340264 + -.24331799999999998 * s + o * (.0062510000000000005 + .034164 * s))), 74.03120656864502 * i * (1.340264 + -.081106 * s + o * (893e-6 + .003796 * s))];
      }
      inverse(t) {
        let e = t[0] / 74.03120656864502,
          i = t[1] / 74.03120656864502,
          s = 180 / Math.PI,
          o = i,
          r,
          a,
          n,
          l;
        for (let t = 0; t < 12 && (a = (r = o * o) * r * r, n = o * (1.340264 + -.081106 * r + a * (893e-6 + .003796 * r)) - i, o -= l = n / (1.340264 + -.24331799999999998 * r + a * (.0062510000000000005 + .034164 * r)), !(1e-9 > Math.abs(l))); ++t);
        a = (r = o * o) * r * r;
        let h = s * dj * e * (1.340264 + -.24331799999999998 * r + a * (.0062510000000000005 + .034164 * r)) / Math.cos(o),
          d = s * Math.asin(Math.sin(o) / dj);
        return Math.abs(h) > 180 ? [NaN, NaN] : [h, d];
      }
    },
    dG = Math.PI / 4,
    dF = Math.PI / 180,
    dH = class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -146.91480769173063,
          y2: 146.91480769173063
        };
      }
      forward(t) {
        return [t[0] * dF * 63.78137, 79.7267125 * Math.log(Math.tan(dG + .4 * t[1] * dF))];
      }
      inverse(t) {
        return [t[0] / 63.78137 / dF, 2.5 * (Math.atan(Math.exp(.8 * (t[1] / 63.78137))) - dG) / dF];
      }
    },
    dY = Math.PI / 180,
    dV = class {
      constructor() {
        this.antimeridianCutting = !1, this.bounds = {
          x1: -63.78460826781007,
          x2: 63.78460826781007,
          y1: -63.78460826781007,
          y2: 63.78460826781007
        };
      }
      forward(t) {
        let e = t[0],
          i = t[1] * dY,
          s = [Math.cos(i) * Math.sin(e * dY) * 63.78460826781007, 63.78460826781007 * Math.sin(i)];
        return (e < -90 || e > 90) && (s.outside = !0), s;
      }
      inverse(t) {
        let e = t[0] / 63.78460826781007,
          i = t[1] / 63.78460826781007,
          s = Math.sqrt(e * e + i * i),
          o = Math.asin(s),
          r = Math.sin(o);
        return [Math.atan2(e * r, s * Math.cos(o)) / dY, Math.asin(s && i * r / s) / dY];
      }
    },
    dU = Math.PI / 180,
    dZ = class {
      constructor() {
        this.bounds = {
          x1: -200.37508342789243,
          x2: 200.37508342789243,
          y1: -200.3750834278071,
          y2: 200.3750834278071
        }, this.maxLatitude = 85.0511287798;
      }
      forward(t) {
        let e = Math.sin(t[1] * dU),
          i = [63.78137 * t[0] * dU, 63.78137 * Math.log((1 + e) / (1 - e)) / 2];
        return Math.abs(t[1]) > this.maxLatitude && (i.outside = !0), i;
      }
      inverse(t) {
        return [t[0] / (63.78137 * dU), (2 * Math.atan(Math.exp(t[1] / 63.78137)) - Math.PI / 2) / dU];
      }
    },
    {
      clipLineString: d$,
      clipPolygon: d_
    } = {
      clipLineString: function (t, e) {
        let i = [],
          s = dE(t, e, !1);
        for (let t = 1; t < s.length; t++) s[t].isIntersection && s[t - 1].isIntersection && (i.push(s.splice(0, t)), t = 0), t === s.length - 1 && i.push(s);
        return i;
      },
      clipPolygon: dE
    },
    {
      clamp: dq,
      erase: dK
    } = J,
    dJ = 2 * Math.PI / 360,
    dQ = t => (t < -180 && (t += 360), t > 180 && (t -= 360), t),
    d0 = t => (1 - Math.cos(t)) / 2,
    d1 = (t, e) => {
      let i = Math.cos,
        s = t[1] * dJ,
        o = t[0] * dJ,
        r = e[1] * dJ,
        a = e[0] * dJ;
      return d0(r - s) + i(s) * i(r) * d0(a - o);
    };
  class d2 {
    static add(t, e) {
      d2.registry[t] = e;
    }
    static distance(t, e) {
      let {
          atan2: i,
          sqrt: s
        } = Math,
        o = d1(t, e);
      return 6371e3 * (2 * i(s(o), s(1 - o)));
    }
    static geodesic(t, e, i, s = 5e5) {
      let {
          atan2: o,
          cos: r,
          sin: a,
          sqrt: n
        } = Math,
        l = d2.distance,
        h = t[1] * dJ,
        d = t[0] * dJ,
        c = e[1] * dJ,
        p = e[0] * dJ,
        u = r(h) * r(d),
        g = r(c) * r(p),
        m = r(h) * a(d),
        f = r(c) * a(p),
        x = a(h),
        y = a(c),
        b = l(t, e),
        v = b / 6371e3,
        M = a(v),
        w = Math.round(b / s),
        k = [];
      if (i && k.push(t), w > 1) {
        let t = 1 / w;
        for (let e = t; e < .999; e += t) {
          let t = a((1 - e) * v) / M,
            i = a(e * v) / M,
            s = t * u + i * g,
            r = t * m + i * f,
            l = o(t * x + i * y, n(s * s + r * r)),
            h = o(r, s);
          k.push([h / dJ, l / dJ]);
        }
      }
      return i && k.push(e), k;
    }
    static insertGeodesics(t) {
      let e = t.length - 1;
      for (; e--;) if (Math.max(Math.abs(t[e][0] - t[e + 1][0]), Math.abs(t[e][1] - t[e + 1][1])) > 10) {
        let i = d2.geodesic(t[e], t[e + 1]);
        i.length && t.splice(e + 1, 0, ...i);
      }
    }
    static toString(t) {
      let {
        name: e,
        rotation: i
      } = t || {};
      return [e, i && i.join(",")].join(";");
    }
    constructor(t = {}) {
      this.hasCoordinates = !1, this.hasGeoProjection = !1, this.maxLatitude = 90, this.options = t;
      let {
        name: e,
        projectedBounds: i,
        rotation: s
      } = t;
      this.rotator = s ? this.getRotator(s) : void 0;
      let o = e ? d2.registry[e] : void 0;
      o && (this.def = new o(t));
      let {
        def: r,
        rotator: a
      } = this;
      r && (this.maxLatitude = r.maxLatitude || 90, this.hasGeoProjection = !0), a && r ? (this.forward = t => r.forward(a.forward(t)), this.inverse = t => a.inverse(r.inverse(t))) : r ? (this.forward = t => r.forward(t), this.inverse = t => r.inverse(t)) : a && (this.forward = a.forward, this.inverse = a.inverse), this.bounds = "world" === i ? r && r.bounds : i;
    }
    lineIntersectsBounds(t) {
      let {
          x1: e,
          x2: i,
          y1: s,
          y2: o
        } = this.bounds || {},
        r = (t, e, i) => {
          let [s, o] = t,
            r = e ? 0 : 1;
          if ("number" == typeof i && s[e] >= i != o[e] >= i) {
            let t = (i - s[e]) / (o[e] - s[e]),
              a = s[r] + t * (o[r] - s[r]);
            return e ? [a, i] : [i, a];
          }
        },
        a,
        n = t[0];
      return (a = r(t, 0, e)) ? (n = a, t[1] = a) : (a = r(t, 0, i)) && (n = a, t[1] = a), (a = r(t, 1, s)) ? n = a : (a = r(t, 1, o)) && (n = a), n;
    }
    getRotator(t) {
      let e = t[0] * dJ,
        i = (t[1] || 0) * dJ,
        s = (t[2] || 0) * dJ,
        o = Math.cos(i),
        r = Math.sin(i),
        a = Math.cos(s),
        n = Math.sin(s);
      if (0 !== e || 0 !== i || 0 !== s) return {
        forward: t => {
          let i = t[0] * dJ + e,
            s = t[1] * dJ,
            l = Math.cos(s),
            h = Math.cos(i) * l,
            d = Math.sin(i) * l,
            c = Math.sin(s),
            p = c * o + h * r;
          return [Math.atan2(d * a - p * n, h * o - c * r) / dJ, Math.asin(p * a + d * n) / dJ];
        },
        inverse: t => {
          let i = t[0] * dJ,
            s = t[1] * dJ,
            l = Math.cos(s),
            h = Math.cos(i) * l,
            d = Math.sin(i) * l,
            c = Math.sin(s),
            p = c * a - d * n;
          return [(Math.atan2(d * a + c * n, h * o + p * r) - e) / dJ, Math.asin(p * o - h * r) / dJ];
        }
      };
    }
    forward(t) {
      return t;
    }
    inverse(t) {
      return t;
    }
    cutOnAntimeridian(t, e) {
      let i;
      let s = [],
        o = [t];
      for (let i = 0, o = t.length; i < o; ++i) {
        let o = t[i],
          r = t[i - 1];
        if (!i) {
          if (!e) continue;
          r = t[t.length - 1];
        }
        let a = r[0],
          n = o[0];
        if ((a < -90 || a > 90) && (n < -90 || n > 90) && a > 0 != n > 0) {
          let t = dq((180 - (a + 360) % 360) / ((n + 360) % 360 - (a + 360) % 360), 0, 1),
            e = r[1] + t * (o[1] - r[1]);
          s.push({
            i,
            lat: e,
            direction: a < 0 ? 1 : -1,
            previousLonLat: r,
            lonLat: o
          });
        }
      }
      if (s.length) {
        if (e) {
          s.length % 2 == 1 && (i = s.slice().sort((t, e) => Math.abs(e.lat) - Math.abs(t.lat))[0], dK(s, i));
          let e = s.length - 2;
          for (; e >= 0;) {
            let i = s[e].i,
              r = dQ(180 + 1e-6 * s[e].direction),
              a = dQ(180 - 1e-6 * s[e].direction),
              n = t.splice(i, s[e + 1].i - i, ...d2.geodesic([r, s[e].lat], [r, s[e + 1].lat], !0));
            n.push(...d2.geodesic([a, s[e + 1].lat], [a, s[e].lat], !0)), o.push(n), e -= 2;
          }
          if (i) for (let t = 0; t < o.length; t++) {
            let {
                direction: e,
                lat: s
              } = i,
              r = o[t],
              a = r.indexOf(i.lonLat);
            if (a > -1) {
              let t = (s < 0 ? -1 : 1) * this.maxLatitude,
                o = dQ(180 + 1e-6 * e),
                n = dQ(180 - 1e-6 * e),
                l = d2.geodesic([o, s], [o, t], !0);
              for (let i = o + 120 * e; i > -180 && i < 180; i += 120 * e) l.push([i, t]);
              l.push(...d2.geodesic([n, t], [n, i.lat], !0)), r.splice(a, 0, ...l);
              break;
            }
          }
        } else {
          let e = s.length;
          for (; e--;) {
            let i = s[e].i,
              r = t.splice(i, t.length, [dQ(180 + 1e-6 * s[e].direction), s[e].lat]);
            r.unshift([dQ(180 - 1e-6 * s[e].direction), s[e].lat]), o.push(r);
          }
        }
      }
      return o;
    }
    path(t) {
      let e;
      let {
          bounds: i,
          def: s,
          rotator: o
        } = this,
        r = [],
        a = "Polygon" === t.type || "MultiPolygon" === t.type,
        n = this.hasGeoProjection,
        l = !s || !1 !== s.antimeridianCutting,
        h = l ? o : void 0,
        d = l && s || this;
      i && (e = [[i.x1, i.y1], [i.x2, i.y1], [i.x2, i.y2], [i.x1, i.y2]]);
      let c = t => {
        let s = t.map(t => {
            if (l) {
              h && (t = h.forward(t));
              let e = t[0];
              1e-6 > Math.abs(e - 180) && (e = e < 180 ? 179.999999 : 180.000001), t = [e, t[1]];
            }
            return t;
          }),
          o = [s];
        n && (d2.insertGeodesics(s), l && (o = this.cutOnAntimeridian(s, a))), o.forEach(t => {
          let s, o;
          if (t.length < 2) return;
          let h = !1,
            c = !1,
            p = t => {
              h ? r.push(["L", t[0], t[1]]) : (r.push(["M", t[0], t[1]]), h = !0);
            },
            u = !1,
            g = !1,
            m = t.map(t => {
              let e = d.forward(t);
              return e.outside ? u = !0 : g = !0, e[1] === 1 / 0 ? e[1] = 1e10 : e[1] === -1 / 0 && (e[1] = -1e10), e;
            });
          if (l) {
            if (a && m.push(m[0]), u) {
              if (!g) return;
              if (e) {
                if (a) m = d_(m, e);else if (i) {
                  d$(m, e).forEach(t => {
                    h = !1, t.forEach(p);
                  });
                  return;
                }
              }
            }
            m.forEach(p);
          } else for (let e = 0; e < m.length; e++) {
            let i = t[e],
              r = m[e];
            r.outside ? c = !0 : (a && !s && (s = i, t.push(i), m.push(r)), c && o && (a && n ? d2.geodesic(o, i).forEach(t => p(d.forward(t))) : h = !1), p(r), o = i, c = !1);
          }
        });
      };
      return "LineString" === t.type ? c(t.coordinates) : "MultiLineString" === t.type ? t.coordinates.forEach(t => c(t)) : "Polygon" === t.type ? (t.coordinates.forEach(t => c(t)), r.length && r.push(["Z"])) : "MultiPolygon" === t.type && (t.coordinates.forEach(t => {
        t.forEach(t => c(t));
      }), r.length && r.push(["Z"])), r;
    }
  }
  d2.registry = {
    EqualEarth: dX,
    LambertConformalConic: dW,
    Miller: dH,
    Orthographic: dV,
    WebMercator: dZ
  };
  let {
      composed: d3
    } = A,
    {
      pointInPolygon: d6
    } = l9,
    {
      topo2geo: d5
    } = dO,
    {
      boundsFromPath: d9
    } = dm,
    {
      addEvent: d8,
      clamp: d4,
      crisp: d7,
      fireEvent: ct,
      isArray: ce,
      isNumber: ci,
      isObject: cs,
      isString: co,
      merge: cr,
      pick: ca,
      pushUnique: cn,
      relativeLength: cl
    } = J,
    ch = {};
  function cd(t, e) {
    let {
      width: i,
      height: s
    } = e;
    return Math.log(400.979322 / Math.max((t.x2 - t.x1) / (i / 256), (t.y2 - t.y1) / (s / 256))) / Math.log(2);
  }
  function cc(t) {
    t.seriesOptions.mapData && this.mapView?.recommendMapView(this, [this.options.chart.map, t.seriesOptions.mapData], this.options.drilldown?.mapZooming);
  }
  class cp {
    static compose(t) {
      cn(d3, "MapView") && (ch = t.maps, d8(t, "afterInit", function () {
        this.mapView = new cp(this, this.options.mapView);
      }, {
        order: 0
      }), d8(t, "addSeriesAsDrilldown", cc), d8(t, "afterDrillUp", cc));
    }
    static compositeBounds(t) {
      if (t.length) return t.slice(1).reduce((t, e) => (t.x1 = Math.min(t.x1, e.x1), t.y1 = Math.min(t.y1, e.y1), t.x2 = Math.max(t.x2, e.x2), t.y2 = Math.max(t.y2, e.y2), t), cr(t[0]));
    }
    static mergeInsets(t, e) {
      let i = t => {
          let e = {};
          return t.forEach((t, i) => {
            e[t && t.id || `i${i}`] = t;
          }), e;
        },
        s = cr(i(t), i(e));
      return Object.keys(s).map(t => s[t]);
    }
    constructor(t, e) {
      this.allowTransformAnimation = !0, this.eventsToUnbind = [], this.insets = [], this.padding = [0, 0, 0, 0], this.recommendedMapView = {}, this instanceof cu || this.recommendMapView(t, [t.options.chart.map, ...(t.options.series || []).map(t => t.mapData)]), this.userOptions = e || {};
      let i = cr(dk, this.recommendedMapView, e),
        s = this.recommendedMapView?.insets,
        o = e && e.insets;
      s && o && (i.insets = cp.mergeInsets(s, o)), this.chart = t, this.center = i.center, this.options = i, this.projection = new d2(i.projection), this.playingField = t.plotBox, this.zoom = i.zoom || 0, this.minZoom = i.minZoom, this.createInsets(), this.eventsToUnbind.push(d8(t, "afterSetChartSize", () => {
        this.playingField = this.getField(), (void 0 === this.minZoom || this.minZoom === this.zoom) && (this.fitToBounds(void 0, void 0, !1), !this.chart.hasRendered && ci(this.userOptions.zoom) && (this.zoom = this.userOptions.zoom), this.userOptions.center && cr(!0, this.center, this.userOptions.center));
      })), this.setUpEvents();
    }
    createInsets() {
      let t = this.options,
        e = t.insets;
      e && e.forEach(e => {
        let i = new cu(this, cr(t.insetOptions, e));
        this.insets.push(i);
      });
    }
    fitToBounds(t, e, i = !0, s) {
      let o = t || this.getProjectedBounds();
      if (o) {
        let r = ca(e, t ? 0 : this.options.padding),
          a = this.getField(!1),
          n = ce(r) ? r : [r, r, r, r];
        this.padding = [cl(n[0], a.height), cl(n[1], a.width), cl(n[2], a.height), cl(n[3], a.width)], this.playingField = this.getField();
        let l = cd(o, this.playingField);
        t || (this.minZoom = l);
        let h = this.projection.inverse([(o.x2 + o.x1) / 2, (o.y2 + o.y1) / 2]);
        this.setView(h, l, i, s);
      }
    }
    getField(t = !0) {
      let e = t ? this.padding : [0, 0, 0, 0];
      return {
        x: e[3],
        y: e[0],
        width: this.chart.plotWidth - e[1] - e[3],
        height: this.chart.plotHeight - e[0] - e[2]
      };
    }
    getGeoMap(t) {
      if (co(t)) return ch[t] && "Topology" === ch[t].type ? d5(ch[t]) : ch[t];
      if (cs(t, !0)) {
        if ("FeatureCollection" === t.type) return t;
        if ("Topology" === t.type) return d5(t);
      }
    }
    getMapBBox() {
      let t = this.getProjectedBounds(),
        e = this.getScale();
      if (t) {
        let i = this.padding,
          s = this.projectedUnitsToPixels({
            x: t.x1,
            y: t.y2
          });
        return {
          width: (t.x2 - t.x1) * e + i[1] + i[3],
          height: (t.y2 - t.y1) * e + i[0] + i[2],
          x: s.x - i[3],
          y: s.y - i[0]
        };
      }
    }
    getProjectedBounds() {
      let t = this.projection,
        e = this.chart.series.reduce((t, e) => {
          let i = e.getProjectedBounds && e.getProjectedBounds();
          return i && !1 !== e.options.affectsMapView && t.push(i), t;
        }, []),
        i = this.options.fitToGeometry;
      if (i) {
        if (!this.fitToGeometryCache) {
          if ("MultiPoint" === i.type) {
            let e = i.coordinates.map(e => t.forward(e)),
              s = e.map(t => t[0]),
              o = e.map(t => t[1]);
            this.fitToGeometryCache = {
              x1: Math.min.apply(0, s),
              x2: Math.max.apply(0, s),
              y1: Math.min.apply(0, o),
              y2: Math.max.apply(0, o)
            };
          } else this.fitToGeometryCache = d9(t.path(i));
        }
        return this.fitToGeometryCache;
      }
      return this.projection.bounds || cp.compositeBounds(e);
    }
    getScale() {
      return 256 / 400.979322 * Math.pow(2, this.zoom);
    }
    getSVGTransform() {
      let {
          x: t,
          y: e,
          width: i,
          height: s
        } = this.playingField,
        o = this.projection.forward(this.center),
        r = this.projection.hasCoordinates ? -1 : 1,
        a = this.getScale(),
        n = a * r,
        l = t + i / 2 - o[0] * a,
        h = e + s / 2 - o[1] * n;
      return {
        scaleX: a,
        scaleY: n,
        translateX: l,
        translateY: h
      };
    }
    lonLatToPixels(t) {
      let e = this.lonLatToProjectedUnits(t);
      if (e) return this.projectedUnitsToPixels(e);
    }
    lonLatToProjectedUnits(t) {
      let e = this.chart,
        i = e.mapTransforms;
      if (i) {
        for (let s in i) if (Object.hasOwnProperty.call(i, s) && i[s].hitZone) {
          let o = e.transformFromLatLon(t, i[s]);
          if (o && d6(o, i[s].hitZone.coordinates[0])) return o;
        }
        return e.transformFromLatLon(t, i.default);
      }
      for (let e of this.insets) if (e.options.geoBounds && d6({
        x: t.lon,
        y: t.lat
      }, e.options.geoBounds.coordinates[0])) {
        let i = e.projection.forward([t.lon, t.lat]),
          s = e.projectedUnitsToPixels({
            x: i[0],
            y: i[1]
          });
        return this.pixelsToProjectedUnits(s);
      }
      let s = this.projection.forward([t.lon, t.lat]);
      if (!s.outside) return {
        x: s[0],
        y: s[1]
      };
    }
    projectedUnitsToLonLat(t) {
      let e = this.chart,
        i = e.mapTransforms;
      if (i) {
        for (let s in i) if (Object.hasOwnProperty.call(i, s) && i[s].hitZone && d6(t, i[s].hitZone.coordinates[0])) return e.transformToLatLon(t, i[s]);
        return e.transformToLatLon(t, i.default);
      }
      let s = this.projectedUnitsToPixels(t);
      for (let t of this.insets) if (t.hitZone && d6(s, t.hitZone.coordinates[0])) {
        let e = t.pixelsToProjectedUnits(s),
          i = t.projection.inverse([e.x, e.y]);
        return {
          lon: i[0],
          lat: i[1]
        };
      }
      let o = this.projection.inverse([t.x, t.y]);
      return {
        lon: o[0],
        lat: o[1]
      };
    }
    recommendMapView(t, e, i = !1) {
      this.recommendedMapView = {};
      let s = e.map(t => this.getGeoMap(t)),
        o = [];
      s.forEach(t => {
        if (t && (Object.keys(this.recommendedMapView).length || (this.recommendedMapView = t["hc-recommended-mapview"] || {}), t.bbox)) {
          let [e, i, s, r] = t.bbox;
          o.push({
            x1: e,
            y1: i,
            x2: s,
            y2: r
          });
        }
      });
      let r = o.length && cp.compositeBounds(o);
      ct(this, "onRecommendMapView", {
        geoBounds: r,
        chart: t
      }, function () {
        if (r && this.recommendedMapView) {
          if (!this.recommendedMapView.projection) {
            let {
              x1: t,
              y1: e,
              x2: i,
              y2: s
            } = r;
            this.recommendedMapView.projection = i - t > 180 && s - e > 90 ? {
              name: "EqualEarth",
              parallels: [0, 0],
              rotation: [0]
            } : {
              name: "LambertConformalConic",
              parallels: [e, s],
              rotation: [-(t + i) / 2]
            };
          }
          this.recommendedMapView.insets || (this.recommendedMapView.insets = void 0);
        }
      }), this.geoMap = s[0], i && t.hasRendered && !t.userOptions.mapView?.projection && this.recommendedMapView && this.update(this.recommendedMapView);
    }
    redraw(t) {
      this.chart.series.forEach(t => {
        t.useMapGeometry && (t.isDirty = !0);
      }), this.chart.redraw(t);
    }
    setView(t, e, i = !0, s) {
      t && (this.center = t), "number" == typeof e && ("number" == typeof this.minZoom && (e = Math.max(e, this.minZoom)), "number" == typeof this.options.maxZoom && (e = Math.min(e, this.options.maxZoom)), ci(e) && (this.zoom = e));
      let o = this.getProjectedBounds();
      if (o) {
        let t = this.projection.forward(this.center),
          {
            x: e,
            y: i,
            width: s,
            height: r
          } = this.playingField,
          a = this.getScale(),
          n = this.projectedUnitsToPixels({
            x: o.x1,
            y: o.y1
          }),
          l = this.projectedUnitsToPixels({
            x: o.x2,
            y: o.y2
          }),
          h = [(o.x1 + o.x2) / 2, (o.y1 + o.y2) / 2];
        if (!this.chart.series.some(t => t.isDrilling)) {
          let o = n.x,
            d = l.y,
            c = l.x,
            p = n.y;
          c - o < s ? t[0] = h[0] : o < e && c < e + s ? t[0] += Math.max(o - e, c - s - e) / a : c > e + s && o > e && (t[0] += Math.min(c - s - e, o - e) / a), p - d < r ? t[1] = h[1] : d < i && p < i + r ? t[1] -= Math.max(d - i, p - r - i) / a : p > i + r && d > i && (t[1] -= Math.min(p - r - i, d - i) / a), this.center = this.projection.inverse(t);
        }
        this.insets.forEach(t => {
          t.options.field && (t.hitZone = t.getHitZone(), t.playingField = t.getField());
        }), this.render();
      }
      ct(this, "afterSetView"), i && this.redraw(s);
    }
    projectedUnitsToPixels(t) {
      let e = this.getScale(),
        i = this.projection.forward(this.center),
        s = this.playingField,
        o = s.x + s.width / 2,
        r = s.y + s.height / 2;
      return {
        x: o - e * (i[0] - t.x),
        y: r + e * (i[1] - t.y)
      };
    }
    pixelsToLonLat(t) {
      return this.projectedUnitsToLonLat(this.pixelsToProjectedUnits(t));
    }
    pixelsToProjectedUnits(t) {
      let {
          x: e,
          y: i
        } = t,
        s = this.getScale(),
        o = this.projection.forward(this.center),
        r = this.playingField,
        a = r.x + r.width / 2,
        n = r.y + r.height / 2;
      return {
        x: o[0] + (e - a) / s,
        y: o[1] - (i - n) / s
      };
    }
    setUpEvents() {
      let t, e, i;
      let {
          chart: s
        } = this,
        o = o => {
          let {
              lastTouches: r,
              pinchDown: a
            } = s.pointer,
            n = this.projection,
            l = o.touches,
            {
              mouseDownX: h,
              mouseDownY: d
            } = s,
            c = 0;
          if (a?.length === 1 ? (h = a[0].chartX, d = a[0].chartY) : a?.length === 2 && (h = (a[0].chartX + a[1].chartX) / 2, d = (a[0].chartY + a[1].chartY) / 2), l?.length === 2 && r && (c = Math.log(Math.sqrt(Math.pow(r[0].chartX - r[1].chartX, 2) + Math.pow(r[0].chartY - r[1].chartY, 2)) / Math.sqrt(Math.pow(l[0].chartX - l[1].chartX, 2) + Math.pow(l[0].chartY - l[1].chartY, 2))) / Math.log(.5)), ci(h) && ci(d)) {
            let r = `${h},${d}`,
              {
                chartX: a,
                chartY: p
              } = o.originalEvent;
            l?.length === 2 && (a = (l[0].chartX + l[1].chartX) / 2, p = (l[0].chartY + l[1].chartY) / 2), r !== e && (e = r, t = this.projection.forward(this.center), i = (this.projection.options.rotation || [0, 0]).slice());
            let u = n.def && n.def.bounds,
              g = u && cd(u, this.playingField) || -1 / 0;
            if ("Orthographic" === n.options.name && 2 > (l?.length || 0) && (this.minZoom || 1 / 0) < 1.3 * g) {
              let t = 440 / (this.getScale() * Math.min(s.plotWidth, s.plotHeight));
              if (i) {
                let e = (h - a) * t - i[0],
                  o = d4(-i[1] - (d - p) * t, -80, 80),
                  r = this.zoom;
                this.update({
                  projection: {
                    rotation: [-e, -o]
                  }
                }, !1), this.fitToBounds(void 0, void 0, !1), this.zoom = r, s.redraw(!1);
              }
            } else if (ci(a) && ci(p)) {
              let e = this.getScale(),
                i = this.projection.hasCoordinates ? 1 : -1,
                s = this.projection.inverse([t[0] + (h - a) / e, t[1] - (d - p) / e * i]);
              isNaN(s[0] + s[1]) || this.zoomBy(c, s, void 0, !1);
            }
            o.preventDefault();
          }
        };
      d8(s, "pan", o), d8(s, "touchpan", o), d8(s, "selection", t => {
        if (t.resetSelection) this.zoomBy();else {
          let e = t.x - s.plotLeft,
            i = t.y - s.plotTop,
            {
              y: o,
              x: r
            } = this.pixelsToProjectedUnits({
              x: e,
              y: i
            }),
            {
              y: a,
              x: n
            } = this.pixelsToProjectedUnits({
              x: e + t.width,
              y: i + t.height
            });
          this.fitToBounds({
            x1: r,
            y1: o,
            x2: n,
            y2: a
          }, void 0, !0, !t.originalEvent.touches && void 0), /^touch/.test(t.originalEvent.type) || s.showResetZoom(), t.preventDefault();
        }
      });
    }
    render() {
      this.group || (this.group = this.chart.renderer.g("map-view").attr({
        zIndex: 4
      }).add());
    }
    update(t, e = !0, i) {
      let s = t.projection,
        o = s && d2.toString(s) !== d2.toString(this.options.projection),
        r = !1;
      cr(!0, this.userOptions, t), cr(!0, this.options, t), "insets" in t && (this.insets.forEach(t => t.destroy()), this.insets.length = 0, r = !0), (o || "fitToGeometry" in t) && delete this.fitToGeometryCache, (o || r) && (this.chart.series.forEach(t => {
        let e = t.transformGroups;
        if (t.clearBounds && t.clearBounds(), t.isDirty = !0, t.isDirtyData = !0, r && e) for (; e.length > 1;) {
          let t = e.pop();
          t && t.destroy();
        }
      }), o && (this.projection = new d2(this.options.projection)), r && this.createInsets(), !t.center && Object.hasOwnProperty.call(t, "zoom") && !ci(t.zoom) && this.fitToBounds(void 0, void 0, !1)), t.center || ci(t.zoom) ? this.setView(this.options.center, t.zoom, !1) : "fitToGeometry" in t && this.fitToBounds(void 0, void 0, !1), e && this.chart.redraw(i);
    }
    zoomBy(t, e, i, s) {
      let o = this.chart,
        r = this.projection.forward(this.center);
      if ("number" == typeof t) {
        let a, n, l;
        let h = this.zoom + t;
        if (i) {
          let [t, e] = i,
            s = this.getScale(),
            a = t - o.plotLeft - o.plotWidth / 2,
            h = e - o.plotTop - o.plotHeight / 2;
          n = r[0] + a / s, l = r[1] + h / s;
        }
        if ("number" == typeof n && "number" == typeof l) {
          let t = 1 - Math.pow(2, this.zoom) / Math.pow(2, h),
            e = r[0] - n,
            i = r[1] - l;
          r[0] -= e * t, r[1] += i * t, a = this.projection.inverse(r);
        }
        this.setView(e || a, h, void 0, s);
      } else this.fitToBounds(void 0, void 0, void 0, s);
    }
  }
  class cu extends cp {
    constructor(t, e) {
      if (super(t.chart, e), this.id = e.id, this.mapView = t, this.options = cr({
        center: [0, 0]
      }, t.options.insetOptions, e), this.allBounds = [], this.options.geoBounds) {
        let e = t.projection.path(this.options.geoBounds);
        this.geoBoundsProjectedBox = d9(e), this.geoBoundsProjectedPolygon = e.map(t => [t[1] || 0, t[2] || 0]);
      }
    }
    getField(t = !0) {
      let e = this.hitZone;
      if (e) {
        let i = t ? this.padding : [0, 0, 0, 0],
          s = e.coordinates[0],
          o = s.map(t => t[0]),
          r = s.map(t => t[1]),
          a = Math.min.apply(0, o) + i[3],
          n = Math.max.apply(0, o) - i[1],
          l = Math.min.apply(0, r) + i[0],
          h = Math.max.apply(0, r) - i[2];
        if (ci(a) && ci(l)) return {
          x: a,
          y: l,
          width: n - a,
          height: h - l
        };
      }
      return super.getField.call(this, t);
    }
    getHitZone() {
      let {
          chart: t,
          mapView: e,
          options: i
        } = this,
        {
          coordinates: s
        } = i.field || {};
      if (s) {
        let o = s[0];
        if ("percent" === i.units) {
          let s = "mapBoundingBox" === i.relativeTo && e.getMapBBox() || cr(t.plotBox, {
            x: 0,
            y: 0
          });
          o = o.map(t => [cl(`${t[0]}%`, s.width, s.x), cl(`${t[1]}%`, s.height, s.y)]);
        }
        return {
          type: "Polygon",
          coordinates: [o]
        };
      }
    }
    getProjectedBounds() {
      return cp.compositeBounds(this.allBounds);
    }
    isInside(t) {
      let {
        geoBoundsProjectedBox: e,
        geoBoundsProjectedPolygon: i
      } = this;
      return !!(e && t.x >= e.x1 && t.x <= e.x2 && t.y >= e.y1 && t.y <= e.y2 && i && d6(t, i));
    }
    render() {
      let {
          chart: t,
          mapView: e,
          options: i
        } = this,
        s = i.borderPath || i.field;
      if (s && e.group) {
        let o = !0;
        this.border || (this.border = t.renderer.path().addClass("highcharts-mapview-inset-border").add(e.group), o = !1), t.styledMode || this.border.attr({
          stroke: i.borderColor,
          "stroke-width": i.borderWidth
        });
        let r = this.border.strokeWidth(),
          a = "mapBoundingBox" === i.relativeTo && e.getMapBBox() || e.playingField,
          n = (s.coordinates || []).reduce((e, s) => s.reduce((e, s, o) => {
            let [n, l] = s;
            return "percent" === i.units && (n = t.plotLeft + cl(`${n}%`, a.width, a.x), l = t.plotTop + cl(`${l}%`, a.height, a.y)), n = d7(n, r), l = d7(l, r), e.push(0 === o ? ["M", n, l] : ["L", n, l]), e;
          }, e), []);
        this.border[o ? "animate" : "attr"]({
          d: n
        });
      }
    }
    destroy() {
      this.border && (this.border = this.border.destroy()), this.eventsToUnbind.forEach(t => t());
    }
    setUpEvents() {}
  }
  let {
      animObject: cg,
      stop: cm
    } = tH,
    {
      noop: cf
    } = A,
    {
      splitPath: cx
    } = dg,
    {
      column: cy,
      scatter: cb
    } = rx.seriesTypes,
    {
      extend: cv,
      find: cM,
      fireEvent: cw,
      getNestedProperty: ck,
      isArray: cS,
      defined: cT,
      isNumber: cC,
      isObject: cA,
      merge: cP,
      objectEach: cL,
      pick: cO,
      splat: cE
    } = J;
  class cI extends cb {
    constructor() {
      super(...arguments), this.processedData = [];
    }
    animate(t) {
      let {
          chart: e,
          group: i
        } = this,
        s = cg(this.options.animation);
      t ? i.attr({
        translateX: e.plotLeft + e.plotWidth / 2,
        translateY: e.plotTop + e.plotHeight / 2,
        scaleX: .001,
        scaleY: .001
      }) : i.animate({
        translateX: e.plotLeft,
        translateY: e.plotTop,
        scaleX: 1,
        scaleY: 1
      }, s);
    }
    clearBounds() {
      this.points.forEach(t => {
        delete t.bounds, delete t.insetIndex, delete t.projectedPath;
      }), delete this.bounds;
    }
    doFullTranslate() {
      return !!(this.isDirtyData || this.chart.isResizing || !this.hasRendered);
    }
    drawMapDataLabels() {
      super.drawDataLabels(), this.dataLabelsGroup && this.dataLabelsGroup.clip(this.chart.clipRect);
    }
    drawPoints() {
      let t = this,
        {
          chart: e,
          group: i,
          transformGroups: s = []
        } = this,
        {
          mapView: o,
          renderer: r
        } = e;
      if (o) {
        this.transformGroups = s, s[0] || (s[0] = r.g().add(i));
        for (let t = 0, e = o.insets.length; t < e; ++t) s[t + 1] || s.push(r.g().add(i));
        this.doFullTranslate() && (this.points.forEach(t => {
          let {
            graphic: e
          } = t;
          t.group = s["number" == typeof t.insetIndex ? t.insetIndex + 1 : 0], e && e.parentGroup !== t.group && e.add(t.group);
        }), cy.prototype.drawPoints.apply(this), this.points.forEach(i => {
          let s = i.graphic;
          if (s) {
            let o = s.animate,
              r = "";
            i.name && (r += "highcharts-name-" + i.name.replace(/ /g, "-").toLowerCase()), i.properties && i.properties["hc-key"] && (r += " highcharts-key-" + i.properties["hc-key"].toString().toLowerCase()), r && s.addClass(r), e.styledMode && s.css(this.pointAttribs(i, i.selected && "select" || void 0)), s.attr({
              visibility: !i.visible && (i.visible || i.isNull) ? "hidden" : "inherit"
            }), s.animate = function (i, r, a) {
              let n = cC(i["stroke-width"]) && !cC(s["stroke-width"]),
                l = cC(s["stroke-width"]) && !cC(i["stroke-width"]);
              if (n || l) {
                let o = cO(t.getStrokeWidth(t.options), 1) / (e.mapView && e.mapView.getScale() || 1);
                n && (s["stroke-width"] = o), l && (i["stroke-width"] = o);
              }
              return o.call(s, i, r, l ? function () {
                s.element.removeAttribute("stroke-width"), delete s["stroke-width"], a && a.apply(this, arguments);
              } : a);
            };
          }
        })), s.forEach((i, s) => {
          let a = (0 === s ? o : o.insets[s - 1]).getSVGTransform(),
            n = cO(this.getStrokeWidth(this.options), 1),
            l = a.scaleX,
            h = a.scaleY > 0 ? 1 : -1,
            d = e => {
              (t.points || []).forEach(t => {
                let i;
                let s = t.graphic;
                s && s["stroke-width"] && (i = this.getStrokeWidth(t.options)) && s.attr({
                  "stroke-width": i / e
                });
              });
            };
          if (r.globalAnimation && e.hasRendered && o.allowTransformAnimation) {
            let t = Number(i.attr("translateX")),
              e = Number(i.attr("translateY")),
              s = Number(i.attr("scaleX")),
              o = (o, r) => {
                let c = s + (l - s) * r.pos;
                i.attr({
                  translateX: t + (a.translateX - t) * r.pos,
                  translateY: e + (a.translateY - e) * r.pos,
                  scaleX: c,
                  scaleY: c * h,
                  "stroke-width": n / c
                }), d(c);
              },
              c = cP(cg(r.globalAnimation)),
              p = c.step;
            c.step = function () {
              p && p.apply(this, arguments), o.apply(this, arguments);
            }, i.attr({
              animator: 0
            }).animate({
              animator: 1
            }, c, function () {
              "boolean" != typeof r.globalAnimation && r.globalAnimation.complete && r.globalAnimation.complete({
                applyDrilldown: !0
              }), cw(this, "mapZoomComplete");
            }.bind(this));
          } else cm(i), i.attr(cP(a, {
            "stroke-width": n / l
          })), d(l);
        }), this.isDrilling || this.drawMapDataLabels();
      }
    }
    getProjectedBounds() {
      if (!this.bounds && this.chart.mapView) {
        let {
            insets: t,
            projection: e
          } = this.chart.mapView,
          i = [];
        (this.points || []).forEach(s => {
          if (s.path || s.geometry) {
            if ("string" == typeof s.path ? s.path = cx(s.path) : cS(s.path) && "M" === s.path[0] && (s.path = this.chart.renderer.pathToSegments(s.path)), !s.bounds) {
              let i = s.getProjectedBounds(e);
              if (i) {
                s.labelrank = cO(s.labelrank, (i.x2 - i.x1) * (i.y2 - i.y1));
                let {
                  midX: e,
                  midY: o
                } = i;
                if (t && cC(e) && cC(o)) {
                  let r = cM(t, t => t.isInside({
                    x: e,
                    y: o
                  }));
                  r && (delete s.projectedPath, (i = s.getProjectedBounds(r.projection)) && r.allBounds.push(i), s.insetIndex = t.indexOf(r));
                }
                s.bounds = i;
              }
            }
            s.bounds && void 0 === s.insetIndex && i.push(s.bounds);
          }
        }), this.bounds = cp.compositeBounds(i);
      }
      return this.bounds;
    }
    getStrokeWidth(t) {
      let e = this.pointAttrToOptions;
      return t[e && e["stroke-width"] || "borderWidth"];
    }
    hasData() {
      return !!this.dataTable.rowCount;
    }
    pointAttribs(t, e) {
      let {
          mapView: i,
          styledMode: s
        } = t.series.chart,
        o = s ? this.colorAttribs(t) : cy.prototype.pointAttribs.call(this, t, e),
        r = this.getStrokeWidth(t.options);
      if (e) {
        let i = cP(this.options.states && this.options.states[e], t.options.states && t.options.states[e] || {}),
          s = this.getStrokeWidth(i);
        cT(s) && (r = s), o.stroke = i.borderColor ?? t.color;
      }
      r && i && (r /= i.getScale());
      let a = this.getStrokeWidth(this.options);
      return o.dashstyle && i && cC(a) && (r = a / i.getScale()), t.visible || (o.fill = this.options.nullColor), cT(r) ? o["stroke-width"] = r : delete o["stroke-width"], o["stroke-linecap"] = o["stroke-linejoin"] = this.options.linecap, o;
    }
    updateData() {
      return !this.processedData && super.updateData.apply(this, arguments);
    }
    setData(t, e = !0, i, s) {
      delete this.bounds, super.setData(t, !1, void 0, s), this.processData(), this.generatePoints(), e && this.chart.redraw(i);
    }
    dataColumnKeys() {
      return this.pointArrayMap;
    }
    processData() {
      let t, e, i;
      let s = this.options,
        o = s.data,
        r = this.chart,
        a = r.options.chart,
        n = this.joinBy,
        l = s.keys || this.pointArrayMap,
        h = [],
        d = {},
        c = this.chart.mapView,
        p = c && (cA(s.mapData, !0) ? c.getGeoMap(s.mapData) : c.geoMap),
        u = r.mapTransforms = a.mapTransforms || p && p["hc-transform"] || r.mapTransforms;
      u && cL(u, t => {
        t.rotation && (t.cosAngle = Math.cos(t.rotation), t.sinAngle = Math.sin(t.rotation));
      }), cS(s.mapData) ? i = s.mapData : p && "FeatureCollection" === p.type && (this.mapTitle = p.title, i = A.geojson(p, this.type, this)), this.processedData = [];
      let g = this.processedData;
      if (o) {
        let t;
        for (let e = 0, i = o.length; e < i; ++e) {
          if (cC(t = o[e])) g[e] = {
            value: t
          };else if (cS(t)) {
            let i = 0;
            g[e] = {}, !s.keys && t.length > l.length && "string" == typeof t[0] && (g[e]["hc-key"] = t[0], ++i);
            for (let s = 0; s < l.length; ++s, ++i) l[s] && void 0 !== t[i] && (l[s].indexOf(".") > 0 ? dM.prototype.setNestedProperty(g[e], t[i], l[s]) : g[e][l[s]] = t[i]);
          } else g[e] = o[e];
          n && "_i" === n[0] && (g[e]._i = e);
        }
      }
      if (i) {
        this.mapData = i, this.mapMap = {};
        for (let s = 0; s < i.length; s++) e = (t = i[s]).properties, t._i = s, n[0] && e && e[n[0]] && (t[n[0]] = e[n[0]]), d[t[n[0]]] = t;
        if (this.mapMap = d, n[1]) {
          let t = n[1];
          g.forEach(e => {
            let i = ck(t, e);
            d[i] && h.push(d[i]);
          });
        }
        if (s.allAreas) {
          if (n[1]) {
            let t = n[1];
            g.forEach(e => {
              h.push(ck(t, e));
            });
          }
          let t = "|" + h.map(function (t) {
            return t && t[n[0]];
          }).join("|") + "|";
          i.forEach(e => {
            n[0] && -1 !== t.indexOf("|" + e[n[0]] + "|") || g.push(cP(e, {
              value: null
            }));
          });
        }
      }
      this.dataTable.rowCount = g.length;
    }
    setOptions(t) {
      let e = super.setOptions(t),
        i = e.joinBy;
      return null === e.joinBy && (i = "_i"), i && (this.joinBy = cE(i), this.joinBy[1] || (this.joinBy[1] = this.joinBy[0])), e;
    }
    translate() {
      let t = this.doFullTranslate(),
        e = this.chart.mapView,
        i = e && e.projection;
      if (this.chart.hasRendered && (this.isDirtyData || !this.hasRendered) && (this.processData(), this.generatePoints(), delete this.bounds, !e || e.userOptions.center || cC(e.userOptions.zoom) || e.zoom !== e.minZoom ? this.getProjectedBounds() : e.fitToBounds(void 0, void 0, !1)), e) {
        let s = e.getSVGTransform();
        this.points.forEach(o => {
          let r = cC(o.insetIndex) && e.insets[o.insetIndex].getSVGTransform() || s;
          r && o.bounds && cC(o.bounds.midX) && cC(o.bounds.midY) && (o.plotX = o.bounds.midX * r.scaleX + r.translateX, o.plotY = o.bounds.midY * r.scaleY + r.translateY), t && (o.shapeType = "path", o.shapeArgs = {
            d: dM.getProjectedPath(o, i)
          }), o.hiddenInDataClass || (o.projectedPath && !o.projectedPath.length ? o.setVisible(!1) : o.visible || o.setVisible(!0));
        });
      }
      cw(this, "afterTranslate");
    }
    update(t) {
      t.mapData && this.chart.mapView?.recommendMapView(this.chart, [this.chart.options.chart.map, ...(this.chart.options.series || []).map((e, i) => i === this._i ? t.mapData : e.mapData)], !0), super.update.apply(this, arguments);
    }
  }
  cI.defaultOptions = cP(cb.defaultOptions, {
    affectsMapView: !0,
    animation: !1,
    dataLabels: {
      crop: !1,
      formatter: function () {
        let {
            numberFormatter: t
          } = this.series.chart,
          {
            value: e
          } = this.point;
        return dw(e) ? t(e, -1) : this.point.name || "";
      },
      inside: !0,
      overflow: !1,
      padding: 0,
      verticalAlign: "middle"
    },
    linecap: "round",
    marker: null,
    nullColor: "#f7f7f7",
    stickyTracking: !1,
    tooltip: {
      followPointer: !0,
      pointFormat: "{point.name}: {point.value}<br/>"
    },
    turboThreshold: 0,
    allAreas: !0,
    borderColor: "#e6e6e6",
    borderWidth: 1,
    joinBy: "hc-key",
    states: {
      hover: {
        halo: void 0,
        borderColor: "#666666",
        borderWidth: 2
      },
      normal: {
        animation: !0
      },
      select: {
        color: "#cccccc"
      }
    },
    legendSymbol: "rectangle"
  }), cv(cI.prototype, {
    type: "map",
    axisTypes: dl.seriesMembers.axisTypes,
    colorAttribs: dl.seriesMembers.colorAttribs,
    colorKey: dl.seriesMembers.colorKey,
    directTouch: !0,
    drawDataLabels: cf,
    drawGraph: cf,
    forceDL: !0,
    getCenter: lI.getCenter,
    getExtremesFromAll: !0,
    getSymbol: cf,
    isCartesian: !1,
    parallelArrays: dl.seriesMembers.parallelArrays,
    pointArrayMap: dl.seriesMembers.pointArrayMap,
    pointClass: dM,
    preserveAspectRatio: !0,
    searchPoint: cf,
    trackerGroups: dl.seriesMembers.trackerGroups,
    useMapGeometry: !0
  }), dl.compose(cI), rx.registerSeriesType("map", cI);
  let cD = cI,
    {
      extend: cB,
      merge: cz
    } = J;
  class cN extends cD {
    pointAttribs(t, e) {
      let i = super.pointAttribs(t, e);
      return i.fill = this.options.fillColor, i;
    }
  }
  cN.defaultOptions = cz(cD.defaultOptions, {
    lineWidth: 1,
    fillColor: "none",
    legendSymbol: "lineMarker"
  }), cB(cN.prototype, {
    type: "mapline",
    colorProp: "stroke",
    pointAttrToOptions: {
      stroke: "color",
      "stroke-width": "lineWidth"
    }
  }), rx.registerSeriesType("mapline", cN);
  let {
      scatter: cR
    } = rx.seriesTypes,
    {
      isNumber: cW
    } = J;
  class cj extends cR.prototype.pointClass {
    isValid() {
      return !!(this.options.geometry || cW(this.x) && cW(this.y) || cW(this.options.lon) && cW(this.options.lat));
    }
  }
  let {
      noop: cX
    } = A,
    {
      map: cG,
      scatter: cF
    } = rx.seriesTypes,
    {
      extend: cH,
      fireEvent: cY,
      isNumber: cV,
      merge: cU
    } = J;
  class cZ extends cF {
    constructor() {
      super(...arguments), this.clearBounds = cG.prototype.clearBounds;
    }
    drawDataLabels() {
      super.drawDataLabels(), this.dataLabelsGroup && this.dataLabelsGroup.clip(this.chart.clipRect);
    }
    projectPoint(t) {
      let e = this.chart.mapView;
      if (e) {
        let {
            geometry: i,
            lon: s,
            lat: o
          } = t,
          r = i && "Point" === i.type && i.coordinates;
        if (cV(s) && cV(o) && (r = [s, o]), r) return e.lonLatToProjectedUnits({
          lon: r[0],
          lat: r[1]
        });
      }
    }
    translate() {
      let t = this.chart.mapView;
      if (this.generatePoints(), this.getProjectedBounds && this.isDirtyData && (delete this.bounds, this.getProjectedBounds()), t) {
        let e = t.getSVGTransform(),
          {
            hasCoordinates: i
          } = t.projection;
        this.points.forEach(s => {
          let o,
            {
              x: r,
              y: a
            } = s,
            n = cV(s.insetIndex) && t.insets[s.insetIndex].getSVGTransform() || e,
            l = this.projectPoint(s.options) || s.properties && this.projectPoint(s.properties);
          if (l ? (r = l.x, a = l.y) : s.bounds && (r = s.bounds.midX, a = s.bounds.midY, n && cV(r) && cV(a) && (s.plotX = r * n.scaleX + n.translateX, s.plotY = a * n.scaleY + n.translateY, o = !0)), cV(r) && cV(a)) {
            if (!o) {
              let e = t.projectedUnitsToPixels({
                x: r,
                y: a
              });
              s.plotX = e.x, s.plotY = i ? e.y : this.chart.plotHeight - e.y;
            }
          } else s.y = s.plotX = s.plotY = void 0;
          s.isInside = this.isPointInside(s), s.zone = this.zones.length ? s.getZone() : void 0;
        });
      }
      cY(this, "afterTranslate");
    }
  }
  cZ.defaultOptions = cU(cF.defaultOptions, {
    dataLabels: {
      crop: !1,
      defer: !1,
      enabled: !0,
      formatter: function () {
        return this.point.name;
      },
      overflow: !1,
      style: {
        color: "#000000"
      }
    },
    legendSymbol: "lineMarker"
  }), iH.prototype.symbols.mapmarker = (t, e, i, s, o) => {
    let r, a;
    let n = o && "legend" === o.context;
    n ? (r = t + i / 2, a = e + s) : o && "number" == typeof o.anchorX && "number" == typeof o.anchorY ? (r = o.anchorX, a = o.anchorY) : (r = t + i / 2, a = e + s / 2, e -= s);
    let l = n ? s / 3 : s / 2;
    return [["M", r, a], ["C", r, a, r - l, e + 1.5 * l, r - l, e + l], ["A", l, l, 1, 1, 1, r + l, e + l], ["C", r + l, e + 1.5 * l, r, a, r, a], ["Z"]];
  }, cH(cZ.prototype, {
    type: "mappoint",
    axisTypes: ["colorAxis"],
    forceDL: !0,
    isCartesian: !1,
    pointClass: cj,
    searchPoint: cX,
    useMapGeometry: !0
  }), rx.registerSeriesType("mappoint", cZ);
  let c$ = {
      borderColor: void 0,
      borderWidth: 2,
      className: void 0,
      color: void 0,
      connectorClassName: void 0,
      connectorColor: void 0,
      connectorDistance: 60,
      connectorWidth: 1,
      enabled: !1,
      labels: {
        className: void 0,
        allowOverlap: !1,
        format: "",
        formatter: void 0,
        align: "right",
        style: {
          fontSize: "0.9em",
          color: "#000000"
        },
        x: 0,
        y: 0
      },
      maxSize: 60,
      minSize: 10,
      legendIndex: 0,
      ranges: {
        value: void 0,
        borderColor: void 0,
        color: void 0,
        connectorColor: void 0
      },
      sizeBy: "area",
      sizeByAbsoluteValue: !1,
      zIndex: 1,
      zThreshold: 0
    },
    {
      parse: c_
    } = tA,
    {
      noop: cq
    } = A,
    {
      arrayMax: cK,
      arrayMin: cJ,
      isNumber: cQ,
      merge: c0,
      pick: c1,
      stableSort: c2
    } = J,
    c3 = class {
      constructor(t, e) {
        this.setState = cq, this.init(t, e);
      }
      init(t, e) {
        this.options = t, this.visible = !0, this.chart = e.chart, this.legend = e;
      }
      addToLegend(t) {
        t.splice(this.options.legendIndex, 0, this);
      }
      drawLegendSymbol(t) {
        let e;
        let i = c1(t.options.itemDistance, 20),
          s = this.legendItem || {},
          o = this.options,
          r = o.ranges,
          a = o.connectorDistance;
        if (!r || !r.length || !cQ(r[0].value)) {
          t.options.bubbleLegend.autoRanges = !0;
          return;
        }
        c2(r, function (t, e) {
          return e.value - t.value;
        }), this.ranges = r, this.setOptions(), this.render();
        let n = this.getMaxLabelSize(),
          l = this.ranges[0].radius,
          h = 2 * l;
        e = (e = a - l + n.width) > 0 ? e : 0, this.maxLabel = n, this.movementX = "left" === o.labels.align ? e : 0, s.labelWidth = h + e + i, s.labelHeight = h + n.height / 2;
      }
      setOptions() {
        let t = this.ranges,
          e = this.options,
          i = this.chart.series[e.seriesIndex],
          s = this.legend.baseline,
          o = {
            zIndex: e.zIndex,
            "stroke-width": e.borderWidth
          },
          r = {
            zIndex: e.zIndex,
            "stroke-width": e.connectorWidth
          },
          a = {
            align: this.legend.options.rtl || "left" === e.labels.align ? "right" : "left",
            zIndex: e.zIndex
          },
          n = i.options.marker.fillOpacity,
          l = this.chart.styledMode;
        t.forEach(function (h, d) {
          l || (o.stroke = c1(h.borderColor, e.borderColor, i.color), o.fill = c1(h.color, e.color, 1 !== n ? c_(i.color).setOpacity(n).get("rgba") : i.color), r.stroke = c1(h.connectorColor, e.connectorColor, i.color)), t[d].radius = this.getRangeRadius(h.value), t[d] = c0(t[d], {
            center: t[0].radius - t[d].radius + s
          }), l || c0(!0, t[d], {
            bubbleAttribs: c0(o),
            connectorAttribs: c0(r),
            labelAttribs: a
          });
        }, this);
      }
      getRangeRadius(t) {
        let e = this.options,
          i = this.options.seriesIndex,
          s = this.chart.series[i],
          o = e.ranges[0].value,
          r = e.ranges[e.ranges.length - 1].value,
          a = e.minSize,
          n = e.maxSize;
        return s.getRadius.call(this, r, o, a, n, t);
      }
      render() {
        let t = this.legendItem || {},
          e = this.chart.renderer,
          i = this.options.zThreshold;
        for (let s of (this.symbols || (this.symbols = {
          connectors: [],
          bubbleItems: [],
          labels: []
        }), t.symbol = e.g("bubble-legend"), t.label = e.g("bubble-legend-item").css(this.legend.itemStyle || {}), t.symbol.translateX = 0, t.symbol.translateY = 0, t.symbol.add(t.label), t.label.add(t.group), this.ranges)) s.value >= i && this.renderRange(s);
        this.hideOverlappingLabels();
      }
      renderRange(t) {
        let e = this.ranges[0],
          i = this.legend,
          s = this.options,
          o = s.labels,
          r = this.chart,
          a = r.series[s.seriesIndex],
          n = r.renderer,
          l = this.symbols,
          h = l.labels,
          d = t.center,
          c = Math.abs(t.radius),
          p = s.connectorDistance || 0,
          u = o.align,
          g = i.options.rtl,
          m = s.borderWidth,
          f = s.connectorWidth,
          x = e.radius || 0,
          y = d - c - m / 2 + f / 2,
          b = (y % 1 ? 1 : .5) - (f % 2 ? 0 : .5),
          v = n.styledMode,
          M = g || "left" === u ? -p : p;
        "center" === u && (M = 0, s.connectorDistance = 0, t.labelAttribs.align = "center"), l.bubbleItems.push(n.circle(x, d + b, c).attr(v ? {} : t.bubbleAttribs).addClass((v ? "highcharts-color-" + a.colorIndex + " " : "") + "highcharts-bubble-legend-symbol " + (s.className || "")).add(this.legendItem.symbol)), l.connectors.push(n.path(n.crispLine([["M", x, y], ["L", x + M, y]], s.connectorWidth)).attr(v ? {} : t.connectorAttribs).addClass((v ? "highcharts-color-" + this.options.seriesIndex + " " : "") + "highcharts-bubble-legend-connectors " + (s.connectorClassName || "")).add(this.legendItem.symbol));
        let w = n.text(this.formatLabel(t)).attr(v ? {} : t.labelAttribs).css(v ? {} : o.style).addClass("highcharts-bubble-legend-labels " + (s.labels.className || "")).add(this.legendItem.symbol),
          k = {
            x: x + M + s.labels.x,
            y: y + s.labels.y + .4 * w.getBBox().height
          };
        w.attr(k), h.push(w), w.placed = !0, w.alignAttr = k;
      }
      getMaxLabelSize() {
        let t, e;
        return this.symbols.labels.forEach(function (i) {
          e = i.getBBox(!0), t = t ? e.width > t.width ? e : t : e;
        }), t || {};
      }
      formatLabel(t) {
        let e = this.options,
          i = e.labels.formatter,
          s = e.labels.format,
          {
            numberFormatter: o
          } = this.chart;
        return s ? ed.format(s, t, this.chart) : i ? i.call(t) : o(t.value, 1);
      }
      hideOverlappingLabels() {
        let t = this.chart,
          e = this.options.labels.allowOverlap,
          i = this.symbols;
        !e && i && (t.hideOverlappingLabels(i.labels), i.labels.forEach(function (t, e) {
          t.newOpacity ? t.newOpacity !== t.oldOpacity && i.connectors[e].show() : i.connectors[e].hide();
        }));
      }
      getRanges() {
        let t = this.legend.bubbleLegend,
          e = t.chart.series,
          i = t.options.ranges,
          s,
          o,
          r = Number.MAX_VALUE,
          a = -Number.MAX_VALUE;
        return e.forEach(function (t) {
          t.isBubble && !t.ignoreSeries && (o = t.getColumn("z").filter(cQ)).length && (r = c1(t.options.zMin, Math.min(r, Math.max(cJ(o), !1 === t.options.displayNegative ? t.options.zThreshold : -Number.MAX_VALUE))), a = c1(t.options.zMax, Math.max(a, cK(o))));
        }), s = r === a ? [{
          value: a
        }] : [{
          value: r
        }, {
          value: (r + a) / 2
        }, {
          value: a,
          autoRanges: !0
        }], i.length && i[0].radius && s.reverse(), s.forEach(function (t, e) {
          i && i[e] && (s[e] = c0(i[e], t));
        }), s;
      }
      predictBubbleSizes() {
        let t = this.chart,
          e = t.legend.options,
          i = e.floating,
          s = "horizontal" === e.layout,
          o = s ? t.legend.lastLineHeight : 0,
          r = t.plotSizeX,
          a = t.plotSizeY,
          n = t.series[this.options.seriesIndex],
          l = n.getPxExtremes(),
          h = Math.ceil(l.minPxSize),
          d = Math.ceil(l.maxPxSize),
          c = Math.min(a, r),
          p,
          u = n.options.maxSize;
        return i || !/%$/.test(u) ? p = d : (p = (c + o) * (u = parseFloat(u)) / 100 / (u / 100 + 1), (s && a - p >= r || !s && r - p >= a) && (p = d)), [h, Math.ceil(p)];
      }
      updateRanges(t, e) {
        let i = this.legend.options.bubbleLegend;
        i.minSize = t, i.maxSize = e, i.ranges = this.getRanges();
      }
      correctSizes() {
        let t = this.legend,
          e = this.chart.series[this.options.seriesIndex].getPxExtremes();
        Math.abs(Math.ceil(e.maxPxSize) - this.options.maxSize) > 1 && (this.updateRanges(this.options.minSize, e.maxPxSize), t.render());
      }
    },
    {
      setOptions: c6
    } = tw,
    {
      composed: c5
    } = A,
    {
      addEvent: c9,
      objectEach: c8,
      pushUnique: c4,
      wrap: c7
    } = J;
  function pt(t, e, i) {
    let s, o, r;
    let a = this.legend,
      n = pe(this) >= 0;
    a && a.options.enabled && a.bubbleLegend && a.options.bubbleLegend.autoRanges && n ? (s = a.bubbleLegend.options, o = a.bubbleLegend.predictBubbleSizes(), a.bubbleLegend.updateRanges(o[0], o[1]), s.placed || (a.group.placed = !1, a.allItems.forEach(t => {
      (r = t.legendItem || {}).group && (r.group.translateY = void 0);
    })), a.render(), s.placed || (this.getMargins(), this.axes.forEach(t => {
      t.setScale(), t.updateNames(), c8(t.ticks, function (t) {
        t.isNew = !0, t.isNewLabel = !0;
      });
    }), this.getMargins()), s.placed = !0, t.call(this, e, i), a.bubbleLegend.correctSizes(), pr(a, pi(a))) : (t.call(this, e, i), a && a.options.enabled && a.bubbleLegend && (a.render(), pr(a, pi(a))));
  }
  function pe(t) {
    let e = t.series,
      i = 0;
    for (; i < e.length;) {
      if (e[i] && e[i].isBubble && e[i].visible && e[i].dataTable.rowCount) return i;
      i++;
    }
    return -1;
  }
  function pi(t) {
    let e = t.allItems,
      i = [],
      s = e.length,
      o,
      r,
      a,
      n = 0,
      l = 0;
    for (n = 0; n < s; n++) if (r = e[n].legendItem || {}, a = (e[n + 1] || {}).legendItem || {}, r.labelHeight && (e[n].itemHeight = r.labelHeight), e[n] === e[s - 1] || r.y !== a.y) {
      for (i.push({
        height: 0
      }), o = i[i.length - 1]; l <= n; l++) e[l].itemHeight > o.height && (o.height = e[l].itemHeight);
      o.step = n;
    }
    return i;
  }
  function ps(t) {
    let e = this.bubbleLegend,
      i = this.options,
      s = i.bubbleLegend,
      o = pe(this.chart);
    e && e.ranges && e.ranges.length && (s.ranges.length && (s.autoRanges = !!s.ranges[0].autoRanges), this.destroyItem(e)), o >= 0 && i.enabled && s.enabled && (s.seriesIndex = o, this.bubbleLegend = new c3(s, this), this.bubbleLegend.addToLegend(t.allItems));
  }
  function po(t) {
    let e;
    if (t.defaultPrevented) return !1;
    let i = t.legendItem,
      s = this.chart,
      o = i.visible;
    this && this.bubbleLegend && (i.visible = !o, i.ignoreSeries = o, e = pe(s) >= 0, this.bubbleLegend.visible !== e && (this.update({
      bubbleLegend: {
        enabled: e
      }
    }), this.bubbleLegend.visible = e), i.visible = o);
  }
  function pr(t, e) {
    let i = t.allItems,
      s = t.options.rtl,
      o,
      r,
      a,
      n,
      l = 0;
    i.forEach((t, i) => {
      (n = t.legendItem || {}).group && (o = n.group.translateX || 0, r = n.y || 0, ((a = t.movementX) || s && t.ranges) && (a = s ? o - t.options.maxSize / 2 : o + a, n.group.attr({
        translateX: a
      })), i > e[l].step && l++, n.group.attr({
        translateY: Math.round(r + e[l].height / 2)
      }), n.y = r + e[l].height / 2);
    });
  }
  let pa = {
      compose: function (t, e) {
        c4(c5, "Series.BubbleLegend") && (c6({
          legend: {
            bubbleLegend: c$
          }
        }), c7(t.prototype, "drawChartBox", pt), c9(e, "afterGetAllItems", ps), c9(e, "itemClick", po));
      }
    },
    {
      seriesTypes: {
        scatter: {
          prototype: {
            pointClass: pn
          }
        }
      }
    } = rx,
    {
      extend: pl
    } = J;
  class ph extends pn {
    haloPath(t) {
      let e = (t && this.marker && this.marker.radius || 0) + t;
      if (this.series.chart.inverted) {
        let t = this.pos() || [0, 0],
          {
            xAxis: i,
            yAxis: s,
            chart: o
          } = this.series;
        return o.renderer.symbols.circle(i.len - t[1] - e, s.len - t[0] - e, 2 * e, 2 * e);
      }
      return o$.prototype.haloPath.call(this, e);
    }
  }
  pl(ph.prototype, {
    ttBelow: !1
  });
  let pd = ph,
    {
      parse: pc
    } = tA,
    {
      composed: pp,
      noop: pu
    } = A,
    {
      series: pg,
      seriesTypes: {
        column: {
          prototype: pm
        },
        scatter: pf
      }
    } = rx,
    {
      addEvent: px,
      arrayMax: py,
      arrayMin: pb,
      clamp: pv,
      extend: pM,
      isNumber: pw,
      merge: pk,
      pick: pS,
      pushUnique: pT
    } = J;
  function pC() {
    let t = this.len,
      {
        coll: e,
        isXAxis: i,
        min: s
      } = this,
      o = (this.max || 0) - (s || 0),
      r = 0,
      a = t,
      n = t / o,
      l;
    ("xAxis" === e || "yAxis" === e) && (this.series.forEach(t => {
      if (t.bubblePadding && t.reserveSpace()) {
        this.allowZoomOutside = !0, l = !0;
        let e = t.getColumn(i ? "x" : "y");
        if (i && ((t.onPoint || t).getRadii(0, 0, t), t.onPoint && (t.radii = t.onPoint.radii)), o > 0) {
          let i = e.length;
          for (; i--;) if (pw(e[i]) && this.dataMin <= e[i] && e[i] <= this.max) {
            let o = t.radii && t.radii[i] || 0;
            r = Math.min((e[i] - s) * n - o, r), a = Math.max((e[i] - s) * n + o, a);
          }
        }
      }
    }), l && o > 0 && !this.logarithmic && (a -= t, n *= (t + Math.max(0, r) - Math.min(a, t)) / t, [["min", "userMin", r], ["max", "userMax", a]].forEach(t => {
      void 0 === pS(this.options[t[0]], this[t[1]]) && (this[t[0]] += t[2] / n);
    })));
  }
  function pA() {
    let {
        ticks: t,
        tickPositions: e,
        dataMin: i = 0,
        dataMax: s = 0,
        categories: o
      } = this,
      r = this.options.type;
    if ((o?.length || "category" === r) && this.series.find(t => t.bubblePadding)) {
      let o = e.length;
      for (; o--;) {
        let r = t[e[o]],
          a = r.pos || 0;
        (a > s || a < i) && r.label?.hide();
      }
    }
  }
  class pP extends pf {
    static compose(t, e, i) {
      pa.compose(e, i), pT(pp, "Series.Bubble") && (px(t, "foundExtremes", pC), px(t, "afterRender", pA));
    }
    animate(t) {
      !t && this.points.length < this.options.animationLimit && this.points.forEach(function (t) {
        let {
          graphic: e,
          plotX: i = 0,
          plotY: s = 0
        } = t;
        e && e.width && (this.hasRendered || e.attr({
          x: i,
          y: s,
          width: 1,
          height: 1
        }), e.animate(this.markerAttribs(t), this.options.animation));
      }, this);
    }
    getRadii() {
      let t = this.getColumn("z"),
        e = this.getColumn("y"),
        i = [],
        s,
        o,
        r,
        a = this.chart.bubbleZExtremes,
        {
          minPxSize: n,
          maxPxSize: l
        } = this.getPxExtremes();
      if (!a) {
        let t,
          e = Number.MAX_VALUE,
          i = -Number.MAX_VALUE;
        this.chart.series.forEach(s => {
          if (s.bubblePadding && s.reserveSpace()) {
            let o = (s.onPoint || s).getZExtremes();
            o && (e = Math.min(pS(e, o.zMin), o.zMin), i = Math.max(pS(i, o.zMax), o.zMax), t = !0);
          }
        }), t ? (a = {
          zMin: e,
          zMax: i
        }, this.chart.bubbleZExtremes = a) : a = {
          zMin: 0,
          zMax: 0
        };
      }
      for (o = 0, s = t.length; o < s; o++) r = t[o], i.push(this.getRadius(a.zMin, a.zMax, n, l, r, e && e[o]));
      this.radii = i;
    }
    getRadius(t, e, i, s, o, r) {
      let a = this.options,
        n = "width" !== a.sizeBy,
        l = a.zThreshold,
        h = e - t,
        d = .5;
      if (null === r || null === o) return null;
      if (pw(o)) {
        if (a.sizeByAbsoluteValue && (o = Math.abs(o - l), e = h = Math.max(e - l, Math.abs(t - l)), t = 0), o < t) return i / 2 - 1;
        h > 0 && (d = (o - t) / h);
      }
      return n && d >= 0 && (d = Math.sqrt(d)), Math.ceil(i + d * (s - i)) / 2;
    }
    hasData() {
      return !!this.dataTable.rowCount;
    }
    markerAttribs(t, e) {
      let i = super.markerAttribs(t, e),
        {
          height: s = 0,
          width: o = 0
        } = i;
      return this.chart.inverted ? pM(i, {
        x: (t.plotX || 0) - o / 2,
        y: (t.plotY || 0) - s / 2
      }) : i;
    }
    pointAttribs(t, e) {
      let i = this.options.marker.fillOpacity,
        s = pg.prototype.pointAttribs.call(this, t, e);
      return 1 !== i && (s.fill = pc(s.fill).setOpacity(i).get("rgba")), s;
    }
    translate() {
      super.translate.call(this), this.getRadii(), this.translateBubble();
    }
    translateBubble() {
      let {
          data: t,
          options: e,
          radii: i
        } = this,
        {
          minPxSize: s
        } = this.getPxExtremes(),
        o = t.length;
      for (; o--;) {
        let r = t[o],
          a = i ? i[o] : 0;
        "z" === this.zoneAxis && (r.negative = (r.z || 0) < (e.zThreshold || 0)), pw(a) && a >= s / 2 ? (r.marker = pM(r.marker, {
          radius: a,
          width: 2 * a,
          height: 2 * a
        }), r.dlBox = {
          x: r.plotX - a,
          y: r.plotY - a,
          width: 2 * a,
          height: 2 * a
        }) : (r.shapeArgs = r.plotY = r.dlBox = void 0, r.isInside = !1);
      }
    }
    getPxExtremes() {
      let t = Math.min(this.chart.plotWidth, this.chart.plotHeight),
        e = e => {
          let i;
          return "string" == typeof e && (i = /%$/.test(e), e = parseInt(e, 10)), i ? t * e / 100 : e;
        },
        i = e(pS(this.options.minSize, 8)),
        s = Math.max(e(pS(this.options.maxSize, "20%")), i);
      return {
        minPxSize: i,
        maxPxSize: s
      };
    }
    getZExtremes() {
      let t = this.options,
        e = this.getColumn("z").filter(pw);
      if (e.length) {
        let i = pS(t.zMin, pv(pb(e), !1 === t.displayNegative ? t.zThreshold || 0 : -Number.MAX_VALUE, Number.MAX_VALUE)),
          s = pS(t.zMax, py(e));
        if (pw(i) && pw(s)) return {
          zMin: i,
          zMax: s
        };
      }
    }
    searchKDTree(t, e, i, s = pu, o = pu) {
      return s = (t, e, i) => {
        let s = t[i] || 0,
          o = e[i] || 0,
          r,
          a = !1;
        return s < 0 && o < 0 ? (r = s - (t.marker?.radius || 0) >= o - (e.marker?.radius || 0) ? t : e, a = !0) : r = s < o ? t : e, [r, a];
      }, o = (t, e, i) => !i && t > e || t < e, super.searchKDTree(t, e, i, s, o);
    }
  }
  pP.defaultOptions = pk(pf.defaultOptions, {
    dataLabels: {
      formatter: function () {
        let {
            numberFormatter: t
          } = this.series.chart,
          {
            z: e
          } = this.point;
        return pw(e) ? t(e, -1) : "";
      },
      inside: !0,
      verticalAlign: "middle"
    },
    animationLimit: 250,
    marker: {
      lineColor: null,
      lineWidth: 1,
      fillOpacity: .5,
      radius: null,
      states: {
        hover: {
          radiusPlus: 0
        }
      },
      symbol: "circle"
    },
    minSize: 8,
    maxSize: "20%",
    softThreshold: !1,
    states: {
      hover: {
        halo: {
          size: 5
        }
      }
    },
    tooltip: {
      pointFormat: "({point.x}, {point.y}), Size: {point.z}"
    },
    turboThreshold: 0,
    zThreshold: 0,
    zoneAxis: "z"
  }), pM(pP.prototype, {
    alignDataLabel: pm.alignDataLabel,
    applyZones: pu,
    bubblePadding: !0,
    isBubble: !0,
    keysAffectYAxis: ["y"],
    pointArrayMap: ["y", "z"],
    pointClass: pd,
    parallelArrays: ["x", "y", "z"],
    trackerGroups: ["group", "dataLabelsGroup"],
    specialGroup: "group",
    zoneAxis: "z"
  }), px(pP, "updatedData", t => {
    delete t.target.chart.bubbleZExtremes;
  }), px(pP, "remove", t => {
    delete t.target.chart.bubbleZExtremes;
  }), rx.registerSeriesType("bubble", pP);
  let pL = pP,
    {
      seriesTypes: {
        map: {
          prototype: {
            pointClass: {
              prototype: pO
            }
          }
        }
      }
    } = rx,
    {
      extend: pE
    } = J;
  class pI extends pd {
    isValid() {
      return "number" == typeof this.z;
    }
  }
  pE(pI.prototype, {
    applyOptions: pO.applyOptions,
    getProjectedBounds: pO.getProjectedBounds
  });
  let {
      seriesTypes: {
        map: {
          prototype: pD
        },
        mappoint: {
          prototype: pB
        }
      }
    } = rx,
    {
      extend: pz,
      merge: pN
    } = J;
  class pR extends pL {
    constructor() {
      super(...arguments), this.clearBounds = pD.clearBounds;
    }
    searchPoint(t, e) {
      return this.searchKDTree({
        plotX: t.chartX - this.chart.plotLeft,
        plotY: t.chartY - this.chart.plotTop
      }, e, t);
    }
    translate() {
      pB.translate.call(this), this.getRadii(), this.translateBubble();
    }
  }
  pR.defaultOptions = pN(pL.defaultOptions, {
    lineWidth: 0,
    animationLimit: 500,
    joinBy: "hc-key",
    tooltip: {
      pointFormat: "{point.name}: {point.z}"
    }
  }), pz(pR.prototype, {
    type: "mapbubble",
    axisTypes: ["colorAxis"],
    getProjectedBounds: pD.getProjectedBounds,
    isCartesian: !1,
    pointArrayMap: ["z"],
    pointClass: pI,
    processData: pD.processData,
    projectPoint: pB.projectPoint,
    kdAxisArray: ["plotX", "plotY"],
    setData: pD.setData,
    setOptions: pD.setOptions,
    updateData: pD.updateData,
    useMapGeometry: !0,
    xyFromShape: !0
  }), rx.registerSeriesType("mapbubble", pR);
  let {
      scatter: {
        prototype: {
          pointClass: pW
        }
      }
    } = rx.seriesTypes,
    {
      clamp: pj,
      defined: pX,
      extend: pG,
      pick: pF
    } = J;
  class pH extends pW {
    applyOptions(t, e) {
      return (this.isNull || null === this.value) && delete this.color, super.applyOptions(t, e), this.formatPrefix = this.isNull || null === this.value ? "null" : "point", this;
    }
    getCellAttributes() {
      let t = this.series,
        e = t.options,
        i = (e.colsize || 1) / 2,
        s = (e.rowsize || 1) / 2,
        o = t.xAxis,
        r = t.yAxis,
        a = this.options.marker || t.options.marker,
        n = t.pointPlacementToXValue(),
        l = pF(this.pointPadding, e.pointPadding, 0),
        h = {
          x1: pj(Math.round(o.len - o.translate(this.x - i, !1, !0, !1, !0, -n)), -o.len, 2 * o.len),
          x2: pj(Math.round(o.len - o.translate(this.x + i, !1, !0, !1, !0, -n)), -o.len, 2 * o.len),
          y1: pj(Math.round(r.translate(this.y - s, !1, !0, !1, !0)), -r.len, 2 * r.len),
          y2: pj(Math.round(r.translate(this.y + s, !1, !0, !1, !0)), -r.len, 2 * r.len)
        };
      for (let t of [["width", "x"], ["height", "y"]]) {
        let e = t[0],
          i = t[1],
          s = i + "1",
          n = i + "2",
          d = Math.abs(h[s] - h[n]),
          c = a && a.lineWidth || 0,
          p = Math.abs(h[s] + h[n]) / 2,
          u = a && a[e];
        if (pX(u) && u < d) {
          let t = u / 2 + c / 2;
          h[s] = p - t, h[n] = p + t;
        }
        l && (("x" === i && o.reversed || "y" === i && !r.reversed) && (s = n, n = i + "1"), h[s] += l, h[n] -= l);
      }
      return h;
    }
    haloPath(t) {
      if (!t) return [];
      let {
        x: e = 0,
        y: i = 0,
        width: s = 0,
        height: o = 0
      } = this.shapeArgs || {};
      return [["M", e - t, i - t], ["L", e - t, i + o + t], ["L", e + s + t, i + o + t], ["L", e + s + t, i - t], ["Z"]];
    }
    isValid() {
      return this.value !== 1 / 0 && this.value !== -1 / 0;
    }
  }
  pG(pH.prototype, {
    dataLabelOnNull: !0,
    moveToTopOnHover: !0,
    ttBelow: !1
  });
  let {
      isNumber: pY
    } = J,
    {
      doc: pV
    } = A,
    {
      defined: pU,
      pick: pZ
    } = J,
    {
      series: p$,
      seriesTypes: {
        column: p_,
        scatter: pq
      }
    } = rx,
    {
      prototype: {
        symbols: pK
      }
    } = iH,
    {
      addEvent: pJ,
      extend: pQ,
      fireEvent: p0,
      isNumber: p1,
      merge: p2,
      pick: p3
    } = J,
    {
      colorFromPoint: p6,
      getContext: p5
    } = {
      colorFromPoint: function (t, e) {
        let i = e.series.colorAxis;
        if (i) {
          let s = i.toColor(t || 0, e).split(")")[0].split("(")[1].split(",").map(t => pZ(parseFloat(t), parseInt(t, 10)));
          return s[3] = 255 * pZ(s[3], 1), pU(t) && e.visible || (s[3] = 0), s;
        }
        return [0, 0, 0, 0];
      },
      getContext: function (t) {
        let {
          canvas: e,
          context: i
        } = t;
        return e && i ? (i.clearRect(0, 0, e.width, e.height), i) : (t.canvas = pV.createElement("canvas"), t.context = t.canvas.getContext("2d", {
          willReadFrequently: !0
        }) || void 0, t.context);
      }
    };
  class p9 extends pq {
    constructor() {
      super(...arguments), this.valueMax = NaN, this.valueMin = NaN, this.isDirtyCanvas = !0;
    }
    drawPoints() {
      let t = this,
        e = t.options,
        i = e.interpolation,
        s = e.marker || {};
      if (i) {
        let {
            image: e,
            chart: i,
            xAxis: s,
            yAxis: o
          } = t,
          {
            reversed: r = !1,
            len: a
          } = s,
          {
            reversed: n = !1,
            len: l
          } = o,
          h = {
            width: a,
            height: l
          };
        if (!e || t.isDirtyData || t.isDirtyCanvas) {
          let a = p5(t),
            {
              canvas: l,
              options: {
                colsize: d = 1,
                rowsize: c = 1
              },
              points: p,
              points: {
                length: u
              }
            } = t,
            g = i.colorAxis && i.colorAxis[0];
          if (l && a && g) {
            let {
                min: g,
                max: m
              } = s.getExtremes(),
              {
                min: f,
                max: x
              } = o.getExtremes(),
              y = m - g,
              b = x - f,
              v = Math.round(y / d / 8 * 8),
              M = Math.round(b / c / 8 * 8),
              [w, k] = [[v, v / y, r, "ceil"], [M, M / b, !n, "floor"]].map(([t, e, i, s]) => i ? i => Math[s](t - e * i) : t => Math[s](e * t)),
              S = l.width = v + 1,
              T = S * (l.height = M + 1),
              C = (u - 1) / T,
              A = new Uint8ClampedArray(4 * T),
              P = (t, e) => 4 * Math.ceil(S * k(e - f) + w(t - g));
            t.buildKDTree();
            for (let t = 0; t < T; t++) {
              let e = p[Math.ceil(C * t)],
                {
                  x: i,
                  y: s
                } = e;
              A.set(p6(e.value, e), P(i, s));
            }
            a.putImageData(new ImageData(A, S), 0, 0), e ? e.attr({
              ...h,
              href: l.toDataURL("image/png", 1)
            }) : (t.directTouch = !1, t.image = i.renderer.image(l.toDataURL("image/png", 1)).attr(h).add(t.group));
          }
          t.isDirtyCanvas = !1;
        } else (e.width !== a || e.height !== l) && e.attr(h);
      } else (s.enabled || t._hasPointMarkers) && (p$.prototype.drawPoints.call(t), t.points.forEach(e => {
        e.graphic && (e.graphic[t.chart.styledMode ? "css" : "animate"](t.colorAttribs(e)), null === e.value && e.graphic.addClass("highcharts-null-point"));
      }));
    }
    getExtremes() {
      let {
        dataMin: t,
        dataMax: e
      } = p$.prototype.getExtremes.call(this, this.getColumn("value"));
      return p1(t) && (this.valueMin = t), p1(e) && (this.valueMax = e), p$.prototype.getExtremes.call(this);
    }
    getValidPoints(t, e) {
      return p$.prototype.getValidPoints.call(this, t, e, !0);
    }
    hasData() {
      return !!this.dataTable.rowCount;
    }
    init() {
      super.init.apply(this, arguments);
      let t = this.options;
      t.pointRange = p3(t.pointRange, t.colsize || 1), this.yAxis.axisPointRange = t.rowsize || 1, pK.ellipse = pK.circle, t.marker && p1(t.borderRadius) && (t.marker.r = t.borderRadius);
    }
    markerAttribs(t, e) {
      let i = t.shapeArgs || {};
      if (t.hasImage) return {
        x: t.plotX,
        y: t.plotY
      };
      if (e && "normal" !== e) {
        let s = t.options.marker || {},
          o = this.options.marker || {},
          r = o.states && o.states[e] || {},
          a = s.states && s.states[e] || {},
          n = (a.width || r.width || i.width || 0) + (a.widthPlus || r.widthPlus || 0),
          l = (a.height || r.height || i.height || 0) + (a.heightPlus || r.heightPlus || 0);
        return {
          x: (i.x || 0) + ((i.width || 0) - n) / 2,
          y: (i.y || 0) + ((i.height || 0) - l) / 2,
          width: n,
          height: l
        };
      }
      return i;
    }
    pointAttribs(t, e) {
      let i = p$.prototype.pointAttribs.call(this, t, e),
        s = this.options || {},
        o = this.chart.options.plotOptions || {},
        r = o.series || {},
        a = o.heatmap || {},
        n = t && t.options.borderColor || s.borderColor || a.borderColor || r.borderColor,
        l = t && t.options.borderWidth || s.borderWidth || a.borderWidth || r.borderWidth || i["stroke-width"];
      if (i.stroke = t && t.marker && t.marker.lineColor || s.marker && s.marker.lineColor || n || this.color, i["stroke-width"] = l, e && "normal" !== e) {
        let o = p2(s.states && s.states[e], s.marker && s.marker.states && s.marker.states[e], t && t.options.states && t.options.states[e] || {});
        i.fill = o.color || tA.parse(i.fill).brighten(o.brightness || 0).get(), i.stroke = o.lineColor || i.stroke;
      }
      return i;
    }
    translate() {
      let {
          borderRadius: t,
          marker: e
        } = this.options,
        i = e && e.symbol || "rect",
        s = pK[i] ? i : "rect",
        o = -1 !== ["circle", "square"].indexOf(s);
      for (let e of (this.generatePoints(), this.points)) {
        let r = e.getCellAttributes(),
          a = Math.min(r.x1, r.x2),
          n = Math.min(r.y1, r.y2),
          l = Math.max(Math.abs(r.x2 - r.x1), 0),
          h = Math.max(Math.abs(r.y2 - r.y1), 0);
        if (e.hasImage = 0 === (e.marker && e.marker.symbol || i || "").indexOf("url"), o) {
          let t = Math.abs(l - h);
          a = Math.min(r.x1, r.x2) + (l < h ? 0 : t / 2), n = Math.min(r.y1, r.y2) + (l < h ? t / 2 : 0), l = h = Math.min(l, h);
        }
        e.hasImage && (e.marker = {
          width: l,
          height: h
        }), e.plotX = e.clientX = (r.x1 + r.x2) / 2, e.plotY = (r.y1 + r.y2) / 2, e.shapeType = "path", e.shapeArgs = p2(!0, {
          x: a,
          y: n,
          width: l,
          height: h
        }, {
          d: pK[s](a, n, l, h, {
            r: p1(t) ? t : 0
          })
        });
      }
      p0(this, "afterTranslate");
    }
  }
  p9.defaultOptions = p2(pq.defaultOptions, {
    animation: !1,
    borderRadius: 0,
    borderWidth: 0,
    interpolation: !1,
    nullColor: "#f7f7f7",
    dataLabels: {
      formatter: function () {
        let {
            numberFormatter: t
          } = this.series.chart,
          {
            value: e
          } = this.point;
        return pY(e) ? t(e, -1) : "";
      },
      inside: !0,
      verticalAlign: "middle",
      crop: !1,
      overflow: "allow",
      padding: 0
    },
    marker: {
      symbol: "rect",
      radius: 0,
      lineColor: void 0,
      states: {
        hover: {
          lineWidthPlus: 0
        },
        select: {}
      }
    },
    clip: !0,
    pointRange: null,
    tooltip: {
      pointFormat: "{point.x}, {point.y}: {point.value}<br/>"
    },
    states: {
      hover: {
        halo: !1,
        brightness: .2
      }
    },
    legendSymbol: "rectangle"
  }), pJ(p9, "afterDataClassLegendClick", function () {
    this.isDirtyCanvas = !0, this.drawPoints();
  }), pQ(p9.prototype, {
    axisTypes: dl.seriesMembers.axisTypes,
    colorKey: dl.seriesMembers.colorKey,
    directTouch: !0,
    getExtremesFromAll: !0,
    keysAffectYAxis: ["y"],
    parallelArrays: dl.seriesMembers.parallelArrays,
    pointArrayMap: ["y", "value"],
    pointClass: pH,
    specialGroup: "group",
    trackerGroups: dl.seriesMembers.trackerGroups,
    alignDataLabel: p_.prototype.alignDataLabel,
    colorAttribs: dl.seriesMembers.colorAttribs,
    getSymbol: p$.prototype.getSymbol
  }), dl.compose(p9), rx.registerSeriesType("heatmap", p9), A.ColorMapComposition = dl, A.MapChart = A.MapChart || dg, A.MapNavigation = A.MapNavigation || ds, A.MapView = A.MapView || cp, A.Projection = A.Projection || d2, A.mapChart = A.Map = A.MapChart.mapChart, A.maps = A.MapChart.maps, A.geojson = dO.geojson, A.topo2geo = dO.topo2geo, dO.compose(A.Chart), pR.compose(A.Axis, A.Chart, A.Legend), ds.compose(dg, A.Pointer, A.SVGRenderer), cp.compose(dg),
  /**
  * @license Highmaps JS v12.1.2 (2024-12-21)
  * @module highcharts/highmaps
  *
  * (c) 2011-2024 Torstein Honsi
  *
  * License: www.highcharts.com/license
  */
  A.product = "Highmaps";
  let p8 = A;
  return C.default;
})());